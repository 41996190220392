/* eslint-disable react/forbid-prop-types */
import React from "react"
import Box from "@mui/material/Box"

interface EmptyStateProps {
  readonly title: string
  readonly titleStyle?: React.CSSProperties
}

const EmptyState: React.FC<EmptyStateProps> = ({ title, children, titleStyle }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: ".5rem",
        color: "rgba(0, 0, 0, 0.65)",
        padding: "0 1.25rem",
        textAlign: "center",
      }}
    >
      <Box
        style={titleStyle}
        sx={{
          fontSize: "1.625rem",
          fontWeight: 500,
          color: (theme) => theme.fielderColors.text,
        }}
      >
        {title}
      </Box>
      {children}
    </Box>
  )
}

export default EmptyState
