import React, { useState } from "react"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import FormHelperText from "@mui/material/FormHelperText"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import FielderTextField from "./FielderTextField"

function validateForm(values) {
  const errors = {
    password: {
      length: true,
      number: true,
      lowercase: true,
      uppercase: true,
      special: true,
    },
  }

  if (values.password && values.password.length >= 8) {
    delete errors.password.length
  }
  if (values.password && /.*\d/.test(values.password)) {
    delete errors.password.number
  }
  if (values.password && /.*[a-z]/.test(values.password)) {
    delete errors.password.lowercase
  }
  if (values.password && /.*[A-Z]/.test(values.password)) {
    delete errors.password.uppercase
  }
  if (values.password && /.*[@$#!%*?&]/.test(values.password)) {
    delete errors.password.special
  }

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "page.resetPassword.mismatch"
  }

  return errors
}

/* Override the `isValid` behavior of Formik. Formik's notion of validity is 
that the `errors` object returned from the validation function is empty. 
But, we're using the  errors.password field to drive UX with respect to conformance to 
our password strength rules. As such, it is not binary but ternary based on the three 
conditions:
 - the user hasn't even touched the fields yet, 
 - the user is editing the password field, and we want to let them know which rules 
   are satisfied as they type
 - the user removed focus from the password field; we want to indicate which rules 
    are satisfied and which are not, based on the boolean value of the appropriate
    errors.password field. 
That is, the lack of an errors.password field does not imply that the form is valid. 
Instead, it's the lack of child properties of errors.password that implies validity, 
()as well as making sure that the confirmPassword field matches the password field).
 */
function isValid(errors) {
  return Object.keys(errors.password).length === 0 && !errors.confirmPassword
}

interface ChangePasswordFormProps {
  readonly loading?: boolean
  readonly onCancel: () => void
  readonly onSave: (newPassword: string) => void
}

function ChangePasswordForm({ loading, onCancel, onSave }: ChangePasswordFormProps) {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Box>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validate={validateForm}
      >
        {({ values, errors, touched, handleBlur, handleChange }) => (
          <Box sx={classes.formContainer}>
            <Box>
              <Box sx={{ paddingRight: "1rem" }}>{t("page.resetPassword.prompt")}</Box>
              <ul css={classes.passwordCriteriaList}>
                {["length", "number", "lowercase", "uppercase", "special"].map((c) => (
                  <li
                    css={[
                      errors.password && !errors.password[c] && classes.criteriaMet,
                      touched.password &&
                        errors.password &&
                        errors.password[c] &&
                        classes.criteriaNotMet,
                    ]}
                    key={c}
                  >
                    <Box>
                      {errors.password && !errors.password[c] ? (
                        <span css={classes.criteriaIcon}>&#10003;</span>
                      ) : null}
                      {touched.password && errors.password && errors.password[c] ? (
                        <span css={classes.criteriaIcon}>&#10007;</span>
                      ) : null}
                      <span css={classes.criteriaLabel}>
                        {t(`page.resetPassword.criteria.${c}`)}
                      </span>
                    </Box>
                  </li>
                ))}
              </ul>
            </Box>
            <Box sx={classes.fieldContainer}>
              <FielderTextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment data-testid="togglePasswordVisibility" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => {
                          e.preventDefault()
                          setShowPassword((val) => !val)
                        }}
                        tabIndex={-1}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoComplete="off"
                autoFocus
                data-testid="password"
                fullWidth
                id="password"
                inputProps={{ maxLength: 255 }}
                label={t("page.resetPassword.newPassword")}
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                type={showPassword ? "text" : "password"}
                value={values.password}
              />
            </Box>
            <Box sx={classes.fieldContainer}>
              <FielderTextField
                autoComplete="off"
                data-testid="confirmPassword"
                fullWidth
                id="confirmPassword"
                inputProps={{ maxLength: 255 }}
                label={t("page.resetPassword.confirmPassword")}
                margin="normal"
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                type={showPassword ? "text" : "password"}
                value={values.confirmPassword}
              />
              {errors.confirmPassword && touched.confirmPassword ? (
                <FormHelperText error id="confirmPasswordError">
                  {t(errors.confirmPassword)}
                </FormHelperText>
              ) : null}
            </Box>
            <Box sx={classes.buttonContainer}>
              <Button
                color="secondary"
                data-testid="cancelButton"
                disabled={loading}
                id="cancelButton"
                onClick={onCancel}
                variant="outlined"
              >
                <span>{t("cancel")}</span>
              </Button>
              <Button
                color="primary"
                data-testid="submitButton"
                disabled={
                  !(values.password && values.confirmPassword) || !isValid(errors) || loading
                }
                id="submitButton"
                onClick={() => {
                  onSave(values.password)
                }}
                variant="contained"
              >
                {loading ? (
                  <CircularProgress size={20} thickness={6.0} />
                ) : (
                  <span>{t("changePassword")}</span>
                )}
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  )
}

const classes = {
  formContainer: {
    padding: "1rem",
  },
  fieldContainer: {
    marginBottom: "1.25rem",
  },
  value: {
    minHeight: "20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2.5rem",
    paddingBottom: "0.125rem",
  },
  passwordCriteriaList: {
    fontSize: "0.95em",
    listStyle: "none",
    paddingLeft: "0.75rem",
    marginBottom: "2rem",
  },
  criteriaMet: {
    color: "green",
  },
  criteriaNotMet: {
    color: "red",
  },
  criteriaLabel: {
    marginLeft: "0.75rem",
  },
  criteriaIcon: {
    position: "absolute",
  },
}

export default ChangePasswordForm
