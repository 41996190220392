import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@apollo/client"
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined"
import { IconButton } from "@mui/material"
import StyledBadge from "./StyledBadge"
import { GET_DRAFT_PRODUCT_ORDER } from "../queries/getDraftProductOrder"

export default function ShoppingCartButton() {
  const { t } = useTranslation("common", { useSuspense: typeof window !== "undefined" })
  const navigate = useNavigate()

  const { data: cartData } = useQuery(GET_DRAFT_PRODUCT_ORDER)

  const cartItemCount =
    cartData?.getDraftProductOrder?.lineItems?.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0) ?? 0

  return (
    <IconButton
      aria-label={t("sectionTitle.helpCenter") as string}
      onClick={() => navigate("/app/orders/cart")}
    >
      <StyledBadge invisible={cartItemCount === 0} variant="dot">
        <ShoppingCartOutlined />
      </StyledBadge>
    </IconButton>
  )
}
