import React, { useCallback, useState, useRef } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, gql } from "@apollo/client"
import { useParams } from "react-router-dom"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import FormControl from "@mui/material/FormControl"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Paper from "@mui/material/Paper"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableHead from "@mui/material/TableHead"
import Button from "@mui/material/Button"
import MainLayout from "../../../../components/MainLayout"
import PageHeader from "../../../../components/PageHeader"
import Seo from "../../../../components/Seo"
import SnackbarMessage from "../../../../components/SnackbarMessage"
import ChecklistDiagramEditor from "../../../../components/checklist/ChecklistDiagramEditor"
import ChecklistTableRow from "../../../../components/checklist/ChecklistTableRow"
import RegularReferenceTable from "../../../../components/checklist/RegularReferenceTable"
import MobileHomeReferenceTable from "../../../../components/checklist/MobileHomeReferenceTable"
import FielderTextField from "../../../../components/FielderTextField"
import FieldHelperText from "../../../../components/FieldHelperText"
import { calculateRequiredTorque } from "../../../../components/checklist/util"
import { asInt, isBlank, parseGraphQLErrorCode, SETTINGS } from "../../../../util"
import { useAuth } from "../../../../context/AuthContext"
import { usePrompt } from "../../../../hooks/usePrompt"
import {
  ChecklistTemplate,
  ChecklistTemplateFormInput,
  ChecklistTemplateLineItemFormInput,
  ChecklistType,
  Snack,
} from "../../../../types"

const GET_CHECKLIST_TEMPLATE = gql`
  query GetChecklistTemplateById($id: ID!) {
    getChecklistTemplateById(id: $id) {
      id
      type
      name
      diagram
      lineItems {
        id
        pileUsed
        helixUsed
        number
        requiredCompressionCapacity
        requiredTorque
        totalPileLength
        pileInclination
        diagramId
        cutOffElevation
      }
    }
  }
`

const EDIT_CHECKLIST_TEMPLATE = gql`
  mutation EditChecklistTemplate(
    $id: ID!
    $type: ChecklistType
    $name: String!
    $diagram: String
    $lineItems: [ChecklistTemplateLineItemInput!]
  ) {
    editChecklistTemplate(
      input: { id: $id, type: $type, name: $name, diagram: $diagram, lineItems: $lineItems }
    ) {
      checklistTemplate {
        id
        type
        name
        diagram
        lineItems {
          id
          pileUsed
          helixUsed
          number
          requiredCompressionCapacity
          requiredTorque
          totalPileLength
          pileInclination
          diagramId
          cutOffElevation
        }
      }
    }
  }
`

const CREATE_CHECKLIST_TEMPLATE = gql`
  mutation CreateChecklistTemplate(
    $name: String!
    $type: ChecklistType!
    $diagram: String
    $lineItems: [ChecklistTemplateLineItemInput!]
  ) {
    createChecklistTemplate(
      input: { name: $name, type: $type, diagram: $diagram, lineItems: $lineItems }
    ) {
      checklistTemplate {
        id
        type
        name
        diagram
        lineItems {
          id
          pileUsed
          helixUsed
          number
          requiredCompressionCapacity
          requiredTorque
          totalPileLength
          pileInclination
          diagramId
          cutOffElevation
        }
      }
    }
  }
`

function renderReferenceTable(type: ChecklistType | null): JSX.Element | null {
  if (type === ChecklistType.REGULAR) {
    return <RegularReferenceTable />
  } else if (type === ChecklistType.MOBILE_HOME) {
    return <MobileHomeReferenceTable />
  } else {
    return null
  }
}

function EditChecklistTemplate() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { id } = useParams()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [type, setType] = useState<ChecklistType | null>(() => (id ? null : ChecklistType.REGULAR))
  const [name, setName] = useState<string>("")
  const [checklistTemplate, setChecklistTemplate] = useState<ChecklistTemplateFormInput>({
    name: "",
    type: ChecklistType.REGULAR,
    lineItems: [] as ChecklistTemplateLineItemFormInput[],
  })
  const [lineItems, setLineItems] = useState<ChecklistTemplateLineItemFormInput[]>([])
  const [snack, setSnack] = useState<Snack>()
  const [errors, setErrors] = useState<{ name: string | null }>(() => ({
    name: null,
  }))
  const editorRef = useRef()

  const { loading: getChecklistTemplateLoading } = useQuery(GET_CHECKLIST_TEMPLATE, {
    variables: { id },
    skip: !id,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const checklistTemplateData = data?.getChecklistTemplateById
      if (checklistTemplateData) {
        processChecklistTemplateData(checklistTemplateData)
      }
    },
  })

  const [editChecklistTemplate, { loading: editChecklistTemplateLoading }] = useMutation(
    EDIT_CHECKLIST_TEMPLATE,
    {
      onCompleted: (data) => {
        const checklistTemplateData = data?.editChecklistTemplate?.checklistTemplate
        if (checklistTemplateData) {
          processChecklistTemplateData(checklistTemplateData)
        }
        setIsDirty(false)
        setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const [createChecklistTemplate, { loading: createChecklistTemplateLoading }] = useMutation(
    CREATE_CHECKLIST_TEMPLATE,
    {
      onCompleted: (data) => {
        const checklistTemplateData = data?.createChecklistTemplate?.checklistTemplate
        if (checklistTemplateData) {
          processChecklistTemplateData(checklistTemplateData)
        }
        setIsDirty(false)
        setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const loading =
    getChecklistTemplateLoading || editChecklistTemplateLoading || createChecklistTemplateLoading

  function processChecklistTemplateData(checklistTemplateData: ChecklistTemplate) {
    const currentPiles = checklistTemplateData.lineItems
      ?.map((li) => ({
        id: li.id,
        diagramId: li.diagramId,
        number: li.number,
        achievedCompressionCapacity: li.achievedCompressionCapacity
          ? `${li.achievedCompressionCapacity}`
          : "",
        achievedTensionCapacity: li.achievedTensionCapacity ? `${li.achievedTensionCapacity}` : "",
        achievedTorque: li.achievedTorque ? `${li.achievedTorque}` : "",
        cutOffElevation: li.cutOffElevation ? `${li.cutOffElevation}` : "",
        helixUsed: li.helixUsed ? `${li.helixUsed}` : "",
        pileInclination: li.pileInclination ? `${li.pileInclination}` : "",
        pileUsed: li.pileUsed ? `${li.pileUsed}` : "",
        requiredCompressionCapacity: li.requiredCompressionCapacity
          ? `${li.requiredCompressionCapacity}`
          : "",
        requiredTorque: li.requiredTorque ? `${li.requiredTorque}` : "",
        totalPileLength: li.totalPileLength ? `${li.totalPileLength}` : "",
      }))
      ?.sort((a, b) => a.number - b.number) as ChecklistTemplateLineItemFormInput[]

    setChecklistTemplate({
      id: checklistTemplateData.id,
      name: checklistTemplateData.name,
      type: checklistTemplateData.type ?? ChecklistType.REGULAR,
      diagram: checklistTemplateData.diagram,
      lineItems: currentPiles,
    })
    setLineItems(currentPiles ?? [])
    setType(checklistTemplateData.type ?? ChecklistType.REGULAR)
    setName(checklistTemplateData.name ?? "")

    editorRef.current?.fromJSON(checklistTemplateData.diagram)
  }

  const handleAddPile = (lineItem: ChecklistTemplateLineItemFormInput, diagramJson: any) => {
    lineItem.pileUsed = "178"
    lineItem.helixUsed = "9"
    lineItem.safetyFactor = 2 // SF; kind of a "fudge" factor to account for uncertainty of soil conditions
    if (lineItem.requiredCompressionCapacity) {
      lineItem.requiredTorque =
        calculateRequiredTorque(
          lineItem.pileUsed,
          parseInt(lineItem.requiredCompressionCapacity, 10)
        ) + ""
    }
    const updatedLineItems = [...(checklistTemplate.lineItems ?? []), lineItem]
    setChecklistTemplate({
      ...checklistTemplate,
      lineItems: updatedLineItems,
      diagram: JSON.stringify(diagramJson),
    })
    setLineItems(updatedLineItems)
    setIsDirty(true)
  }

  const handleRemovePiles = (pileDiagramIds: string[]) => {
    let jsonString = ""
    pileDiagramIds.forEach((id) => {
      jsonString = editorRef.current?.removePile(id)
    })

    setChecklistTemplate({
      ...checklistTemplate,
      diagram: jsonString,
    })

    setLineItems((prevLines: ChecklistTemplateLineItemFormInput[]) => {
      const filteredLineItems = [...prevLines]
        .filter((li) => li.diagramId && !pileDiagramIds.includes(li.diagramId))
        .sort((a, b) => a.number - b.number)

      const len = filteredLineItems.length
      for (let i = 0; i < len; i++) {
        filteredLineItems[i].number = i + 1
      }
      return filteredLineItems
    })
    setIsDirty(true)
  }

  const handleRemovePile = useCallback(
    (pileDiagramId) => {
      const jsonString = editorRef.current?.removePile(pileDiagramId)

      setChecklistTemplate({
        ...checklistTemplate,
        diagram: jsonString,
      })

      setLineItems((prevLines: ChecklistTemplateLineItemFormInput[]) => {
        const filteredLineItems = [...prevLines]
          .filter((li) => li.diagramId !== pileDiagramId)
          .sort((a, b) => a.number - b.number)

        const len = filteredLineItems.length
        for (let i = 0; i < len; i++) {
          filteredLineItems[i].number = i + 1
        }
        return filteredLineItems
      })
      setIsDirty(true)
    },
    [checklistTemplate]
  )

  const handleUpdatePile = useCallback((pile) => {
    setLineItems((prevLines) => {
      const newLineItems = [...prevLines]
      const index = newLineItems.findIndex((li) => li.diagramId === pile.diagramId)
      newLineItems[index] = pile
      return newLineItems
    })
    setIsDirty(true)
  }, [])

  /**
   * The diagram was modified in some way that doesn't affect line item data
   * (although it might be that a line item diagram object was moved around)
   */
  function handleUpdateDiagram(diagramJson: any) {
    const updatedChecklistTemplate = {
      ...checklistTemplate,
      diagram: JSON.stringify(diagramJson),
    }

    setChecklistTemplate(updatedChecklistTemplate)
    setIsDirty(true)
  }

  /**
   * This function takes a checklistTemplate object as an argument rather than just
   * working on the `checklistTemplate` state variable because in some cases you need
   * to modify the checklistTemplate and immediately save. If you just update the checklistTemplate
   * state object via setChecklistTemplate and then immediately call this function expecting
   * the `checklistTemplate` state object to be up-to-date, you're gonna have a bad time.
   * There needs to be a re-render cycle after calling setChecklistTemplate before the checklistTemplate
   * state object reflects your changes.
   */
  function handleSave(updatedChecklistTemplate: ChecklistTemplateFormInput): void {
    const piles = updatedChecklistTemplate.lineItems?.map((pile) => {
      const requiredCompressionCapacity = pile.requiredCompressionCapacity
        ? parseInt(pile.requiredCompressionCapacity, 10)
        : null
      const totalPileLength = asInt(pile.totalPileLength)
      const cutOffElevation = asInt(pile.cutOffElevation)
      const pileInclination = asInt(pile.pileInclination)
      const requiredTorque = asInt(pile.requiredTorque)

      return {
        diagramId: pile.diagramId,
        number: pile.number,
        requiredCompressionCapacity: requiredCompressionCapacity,
        requiredTorque: requiredTorque,
        pileUsed: pile.pileUsed,
        helixUsed: pile.helixUsed,
        totalPileLength: totalPileLength,
        cutOffElevation: cutOffElevation,
        pileInclination: pileInclination,
      }
    })

    const variables = {
      ...updatedChecklistTemplate,
      type,
      name,
      diagram: editorRef.current?.toJSON(),
      lineItems: piles,
    }

    setIsDirty(false)

    if (updatedChecklistTemplate?.id) {
      editChecklistTemplate({ variables })
    } else {
      createChecklistTemplate({ variables })
    }
  }

  function renderSaveButton() {
    return (
      <Button
        color="primary"
        data-testid="saveChecklistTemplateButton"
        disabled={!name || editChecklistTemplateLoading || createChecklistTemplateLoading}
        onClick={() => {
          const updatedTemplate = {
            ...checklistTemplate,
            lineItems,
          }
          handleSave(updatedTemplate)
        }}
        sx={{
          fontWeight: "bold",
          minWidth: 135,
        }}
        variant="contained"
      >
        {editChecklistTemplateLoading || createChecklistTemplateLoading ? (
          <CircularProgress color="secondary" size={20} thickness={6.0} />
        ) : (
          <span>{t("saveTemplate")}</span>
        )}
      </Button>
    )
  }

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/templates", titleKey: "templates" },
              { to: "/app/settings/templates/checklist", titleKey: "checklistTemplates" },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey={id ? "edit" : "create"}
          />
          <Box sx={classes.contentContainer}>
            <Box sx={classes.headerControlsContainer}>
              <Box sx={classes.textFieldContainer}>
                <FielderTextField
                  error={!!errors.name}
                  fullWidth
                  label={t("templateName")}
                  name="name"
                  onBlur={() => {
                    if (isBlank(name)) {
                      setErrors((prev) => ({
                        ...prev,
                        name: "page.settings.templates.common.validation.name.required",
                      }))
                    } else {
                      setErrors((prev) => ({
                        ...prev,
                        name: null,
                      }))
                    }
                  }}
                  onChange={(e: any) => {
                    const val = e.target.value
                    if (isBlank(val)) {
                      setErrors((prev) => ({
                        ...prev,
                        name: "page.settings.templates.common.validation.name.required",
                      }))
                    } else {
                      setErrors((prev) => ({
                        ...prev,
                        name: null,
                      }))
                    }
                    setName(val)
                  }}
                  onFocus={(e) => e.target.select()}
                  required
                  style={{ marginTop: 0 }}
                  value={name}
                />
                {errors.name ? (
                  <FieldHelperText error message={t(errors.name)} />
                ) : (
                  <FieldHelperText message={t("page.settings.templates.common.helperText.name")} />
                )}
              </Box>
              <Box sx={[classes.buttonContainer, { marginTop: 0 }]}>{renderSaveButton()}</Box>
            </Box>
            <Box sx={classes.diagramContainer}>
              <ChecklistDiagramEditor
                loading={loading}
                onAddPile={handleAddPile}
                onRemovePiles={handleRemovePiles}
                onUpdateDiagram={handleUpdateDiagram}
                ref={editorRef}
              />
            </Box>
            <div>
              {lineItems?.length > 0 && (
                <Paper>
                  <Table size="small" sx={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">{t("page.checklist.pileNumber")}</TableCell>
                        <TableCell align="center">{t("page.checklist.pileUsed")}</TableCell>
                        <TableCell align="center">{t("page.checklist.helixUsed")}</TableCell>
                        <TableCell align="center">
                          {t("page.checklist.requiredCompressionCapacity")}
                        </TableCell>
                        <TableCell align="center">{t("page.checklist.requiredTorque")}</TableCell>
                        <TableCell align="center" sx={classes.metaColumn}>
                          {t("page.checklist.totalPileLength")}
                        </TableCell>
                        <TableCell align="center" sx={classes.metaColumn}>
                          {t("page.checklist.cutOffElevation")}
                        </TableCell>
                        <TableCell align="center" sx={classes.metaColumn}>
                          {t("page.checklist.pileInclination")}
                        </TableCell>
                        <TableCell align="center">{t("page.checklist.achievedTorque")}</TableCell>
                        <TableCell align="center">
                          {t("page.checklist.achievedCompressionCapacity")}
                        </TableCell>
                        <TableCell align="center">
                          {t("page.checklist.achievedTensionCapacity")}
                        </TableCell>
                        <TableCell align="right" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {user
                        ? lineItems?.map((pile: ChecklistTemplateLineItemFormInput) => {
                            return (
                              <ChecklistTableRow
                                key={pile.diagramId}
                                onRemovePile={handleRemovePile}
                                onUpdatePile={handleUpdatePile}
                                pile={pile}
                                templateMode
                                user={user}
                              />
                            )
                          })
                        : null}
                    </TableBody>
                  </Table>
                </Paper>
              )}
            </div>
            {checklistTemplate?.lineItems && checklistTemplate.lineItems.length > 0 ? (
              <Box sx={classes.buttonContainer}>{renderSaveButton()}</Box>
            ) : null}
            <section css={{ marginTop: "0.5rem", width: "98%" }}>
              <FormControl component="fieldset">
                <legend css={{ fontSize: "1rem", fontWeight: "bold" }}>
                  {t("page.checklist.selectReferenceChartForTemplate")}
                </legend>
                <RadioGroup
                  aria-label="reference chart type"
                  name="row-radio-buttons-group"
                  onChange={(e: any) => {
                    setIsDirty(true)
                    setType(e.target.value)
                  }}
                  row
                  value={type}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={t("checklistTypeOptions.REGULAR") as string}
                    sx={classes.radioButtonLabel}
                    value={ChecklistType.REGULAR}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label={t("checklistTypeOptions.MOBILE_HOME") as string}
                    sx={classes.radioButtonLabel}
                    value={ChecklistType.MOBILE_HOME}
                  />
                </RadioGroup>
              </FormControl>
              {renderReferenceTable(type)}
            </section>
          </Box>
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
  },
  contentContainer: {
    paddingBottom: "25rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "1161px",
    maxWidth: "1161px",
    margin: "0 auto",
  },
  diagramContainer: {
    width: "100%",
    height: "400px",
    backgroundColor: "#fefefe",
    marginBottom: "0.625rem",
  },
  headerControlsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: "0.625rem",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "1.25rem",
  },
  textFieldContainer: {
    width: "50%",
    minWidth: "400px",
    maxWidth: "800px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "0.625rem",
    marginTop: "0.625rem",
  },
  radioButtonLabel: {
    fontSize: "0.875rem",
  },
  table: {
    "& th": {
      lineHeight: "inherit",
      fontSize: "0.8rem",
      padding: "0.25rem 1rem",
    },
  },
  metaColumn: {
    backgroundColor: "#F8DBC0",
  },
}

export default EditChecklistTemplate
