import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import { MainLayout, PageHeader, Seo } from "../components"
import JobsOverTime from "../components/reports/JobsOverTime"
import { REPORTS } from "../util"
import { useAuth } from "../context/AuthContext"

const REPORT_TYPES = {
  REVENUE_OVER_TIME: "revenue_over_time",
  REVENUE_BY_CUSTOMER: "revenue_by_customer",
  REVENUE_BY_CATEGORY: "revenue_by_category",

  JOBS_OVER_TIME: "jobs_over_time",
  JOBS_BY_CATEGORY: "jobs_by_category",
  JOBS_BY_STATUS: "jobs_by_status",
}

function Reports() {
  const { t } = useTranslation()
  const [activeReport, setActiveReport] = useState(REPORT_TYPES.JOBS_OVER_TIME)
  const { user } = useAuth()

  function renderReport() {
    if (user?.organization?.id) {
      switch (activeReport) {
        case REPORT_TYPES.JOBS_OVER_TIME:
          return <JobsOverTime orgId={user?.organization?.id} />
      }
    } else {
      return <Box>{t("You must be logged in to view reports")}</Box>
    }
  }

  return (
    <>
      <Seo title={t("sectionTitle.reports")} />
      <MainLayout activeSection={REPORTS}>
        <Box
          sx={{
            margin: "0 1.25rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PageHeader icon={REPORTS.icon} leafTitleKey={REPORTS.titleKey} />
          <Grid container spacing={2} sx={classes.gridContainer} wrap="wrap-reverse">
            <Grid item xs={2}>
              <Paper sx={classes.paper}>
                <Box sx={classes.menuSectionContainer}>
                  <Box sx={classes.menuSectionHeader}>{t("page.report.revenue")}</Box>
                  <Box sx={classes.menuOptionContainer}>
                    <Box
                      onClick={() => setActiveReport(REPORT_TYPES.REVENUE_OVER_TIME)}
                      sx={[
                        { ...classes.menuOption },
                        activeReport === REPORT_TYPES.REVENUE_OVER_TIME && classes.menuOptionActive,
                      ]}
                    >
                      {t("page.report.overTime")}
                    </Box>
                    <Box
                      onClick={() => setActiveReport(REPORT_TYPES.REVENUE_BY_CUSTOMER)}
                      sx={[
                        { ...classes.menuOption },
                        activeReport === REPORT_TYPES.REVENUE_BY_CUSTOMER &&
                          classes.menuOptionActive,
                      ]}
                    >
                      {t("page.report.byCustomer")}
                    </Box>
                    <Box
                      onClick={() => setActiveReport(REPORT_TYPES.REVENUE_BY_CATEGORY)}
                      sx={[
                        { ...classes.menuOption },
                        activeReport === REPORT_TYPES.REVENUE_BY_CATEGORY &&
                          classes.menuOptionActive,
                      ]}
                    >
                      {t("page.report.byCategory")}
                    </Box>
                  </Box>
                </Box>
                <Box sx={classes.menuSectionContainer}>
                  <Box sx={classes.menuSectionHeader}>{t("page.report.jobs")}</Box>
                  <Box sx={classes.menuOptionContainer}>
                    <Box
                      onClick={() => setActiveReport(REPORT_TYPES.JOBS_OVER_TIME)}
                      sx={[
                        { ...classes.menuOption },
                        activeReport === REPORT_TYPES.JOBS_OVER_TIME && classes.menuOptionActive,
                      ]}
                    >
                      {t("page.report.overTime")}
                    </Box>
                    <Box
                      onClick={() => setActiveReport(REPORT_TYPES.JOBS_BY_CATEGORY)}
                      sx={[
                        { ...classes.menuOption },
                        activeReport === REPORT_TYPES.JOBS_BY_CATEGORY && classes.menuOptionActive,
                      ]}
                    >
                      {t("page.report.byCategory")}
                    </Box>
                    <Box
                      onClick={() => setActiveReport(REPORT_TYPES.JOBS_BY_STATUS)}
                      sx={[
                        { ...classes.menuOption },
                        activeReport === REPORT_TYPES.JOBS_BY_STATUS && classes.menuOptionActive,
                      ]}
                    >
                      {t("page.report.byStatus")}
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={10}>
              <Paper sx={classes.paper}>{renderReport()}</Paper>
            </Grid>
          </Grid>
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  pageHeader: {
    "& svg": {
      fontSize: "1.85rem",
      position: "relative",
      top: "0.3125rem",
    },
  },
  gridContainer: {
    flexGrow: 1,
    minHeight: "600px",
    minWidth: "820px",
  },
  paper: {
    height: "100%",
  },
  menuSectionContainer: {
    paddingTop: "0.625rem",
  },
  menuSectionHeader: {
    fontWeight: "bold",
    fontSize: "1.1rem",
    paddingLeft: "1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  menuOptionContainer: {},
  menuOption: {
    paddingLeft: "2rem",
    paddingTop: "0.125rem",
    paddingBottom: "0.125rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FFC60111",
    },
  },
  menuOptionActive: {
    backgroundColor: "#FFC60133",
    "&:hover": {
      backgroundColor: "#FFC60133",
    },
  },
} as const

export default Reports
