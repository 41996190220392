import React from "react"
import { useTranslation } from "react-i18next"
import MultiSelect from "./MultiSelect"
import FieldSpinner from "./FieldSpinner"
import { useQuery } from "@apollo/client"
import { ALL_ROLES } from "../queries/allRoles"
import { Role } from "../types"

interface RoleOption {
  id: string
  name: string
}

interface RoleSelectProps {
  readonly errorMessage?: string | null | undefined
  readonly id?: string
  readonly label: string
  readonly name: string
  readonly onBlur?: () => void
  readonly onChange: (value: any) => void
  readonly required?: boolean
  readonly selectedValues: RoleOption[]
  readonly size?: "small" | "medium"
  readonly style?: React.CSSProperties
  readonly testID?: string
}

function RoleSelect({
  errorMessage,
  id,
  label,
  name,
  onBlur,
  onChange,
  required = false,
  selectedValues = [],
  size,
  style,
  testID = "roleSelector",
}: RoleSelectProps) {
  const { t } = useTranslation()

  const { loading, error, data } = useQuery(ALL_ROLES, {
    fetchPolicy: "cache-and-network",
  })

  if (loading) {
    return (
      <div style={style}>
        <FieldSpinner message={t("loadingRoles")} />
      </div>
    )
  }

  if (error) return <div>{`Error! ${error.message}`}</div>

  const options = data?.allRoles?.map((role: Role) => {
    return {
      id: role.id,
      name: role.isStandard ? t(`standardRoles.${role.name}.name`) : role.name,
    }
  })

  return (
    <MultiSelect
      error={!!errorMessage}
      id={id}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      required={required}
      selectedValues={selectedValues}
      size={size}
      style={style}
      testID={testID}
    />
  )
}

export default RoleSelect
