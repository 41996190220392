import React from "react"
import Box from "@mui/material/Box"

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={(theme) => ({
        borderTop: `1px solid ${theme.palette.primary.main}`,
        textAlign: "center",
        padding: "0.625rem 0",
        marginTop: "2.5rem",
        backgroundColor: "#f3f3f3",
        zIndex: theme.zIndex.drawer - 1,
      })}
    >
      © {new Date().getFullYear()} CodeSwyft LLC
    </Box>
  )
}

export default Footer
