import React from "react"
import type { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { FranchisorOrderCount } from "../../types/appTypes"
import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

interface Props {
  readonly counts: FranchisorOrderCount[]
  readonly startDate: Dayjs
  readonly endDate: Dayjs
}

const truncate = {
  overflow: "hidden",
  texOverflow: "ellipsis",
  whiteSpace: "nowrap",
}

export function FranchisorOrderCounts({ counts, startDate, endDate }: Props) {
  const { t } = useTranslation()

  const dateFormat = "MMM D"

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        maxWidth: "36rem",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.fielderColors.black,
          height: "4px",
        }}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "24px",
          border: (theme) => `1px solid ${theme.fielderColors.inputBorder}`,
          borderTop: "none",
          padding: "16px",
          paddingTop: "16px",
          [theme.breakpoints.up("sm")]: {
            padding: "24px",
          },
          [theme.breakpoints.up("md")]: {
            padding: "32px",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <Box sx={{ fontSize: "1.125rem", fontWeight: "600" }}>
                {t("widget.franchisorOrderCounts.title")}
              </Box>
              <Tooltip
                sx={{ width: "1rem" }}
                title="This widget shows the number of orders for each status within the specified date range. If a particular order had multiple statuses during the specified date range, only it's most recent status is counted."
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </Box>
            <Box sx={{ fontSize: "0.75rem", color: (theme) => theme.fielderColors.mutedText }}>
              {startDate.format(dateFormat)} - {endDate.format(dateFormat)}
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            {counts.map((c: FranchisorOrderCount) => (
              <Box
                key={c.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "0.875rem",
                  fontWeight: "500",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Box
                    sx={{
                      height: "16px",
                      width: "16px",
                      borderRadius: "4px",
                      backgroundColor: c.color,
                    }}
                  />
                  <Box sx={truncate}>{c.name}</Box>
                </Box>
                <Box>{c.count}</Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
