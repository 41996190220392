import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { FormLabel, RadioGroup, Radio } from "@mui/material"

import Tooltip from "~/components/Tooltip"
import BasicInfoForm from "./BasicInfoForm"
import BundleComponentsForm from "./BundleComponentsForm"
import Sidebar from "./Sidebar"
import { ItemFormContext } from "./ItemFormContext"
import { asFloat, asInt, isBlank, ItemType } from "~/util"
import SaveButton from "~/components/SaveButton"
import { RadioOption } from "~/components"

interface BundleFormProps {
  readonly currencyCode: string
  readonly loading?: boolean
  readonly onCancel: () => void
  readonly onSave: (payload: any) => void
  readonly onImageUpload?: () => void
}

function BundleForm({ currencyCode, loading, onCancel, onSave, onImageUpload }: BundleFormProps) {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(ItemFormContext)
  const [status, setStatus] = useState<string>(() => {
    return state.isActive ? "active" : "inactive"
  })

  function isValid() {
    return (
      state.code.value &&
      !state.code.error &&
      state.name.value &&
      !state.name.error &&
      state.components?.length > 0 &&
      !state.components.some((c) => c.errors?.organizationItem || c.errors?.quantity)
    )
  }

  function handleSubmit() {
    const payload = {
      id: state.id,
      code: state.code.value,
      name: state.name.value,
      description: state.description.value,
      category: state.category.value,
      isIntendedForResale: true,
      isActive: state.isActive,
      type: ItemType.BUNDLE,
      wholesalePrice: 0, // not relevant for BUNDLE item
      msrp: 0, // not relevant for BUNDLE item
      qtyOnHand: 0, // not relevant for BUNDLE item
      unitSalePrice: 0, // not relevant for BUNDLE item
      isDynamicallyPriced: false, // not relevant for BUNDLE item
      components: state.components
        .filter((c) => !isBlank(c.value.organizationItemId))
        .map((c, index: number) => ({
          id: c.value.id,
          number: c.number > 0 ? c.number : index + 1,
          organizationItemId: c.value.organizationItemId,
          quantity: asInt(c.value.quantity),
          enableUnitSalePriceOverride: c.value.enableUnitSalePriceOverride,
          unitSalePrice: c.value.enableUnitSalePriceOverride
            ? asFloat(c.value.unitSalePrice)
            : null,
        })),
    }
    onSave?.(payload)
  }

  return (
    <Box sx={{ paddingBottom: 0 }}>
      <Box
        sx={(theme) => {
          return {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flex: 1,
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
            },
          }
        }}
      >
        {state.id ? <Sidebar item={state.organizationItem} onImageUpload={onImageUpload} /> : null}
        <Box
          sx={(theme) => {
            return {
              flex: 1,
              paddingLeft: "0",
              [theme.breakpoints.up("md")]: {
                padding: "1rem 1rem 1rem 2rem",
              },
            }
          }}
        >
          <Box
            sx={{
              padding: 0,
            }}
          >
            <BasicInfoForm />
            {state.id ? (
              <Box sx={{ marginBottom: "1.25rem" }}>
                <FormLabel
                  id="status-group-label"
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 700,
                    color: (theme) => theme.fielderColors.black,
                  }}
                >
                  {t("status")}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="status-group-label"
                  defaultValue="active"
                  name="status-group"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = (event.target as HTMLInputElement).value
                    setStatus(value)
                    dispatch({
                      type: "SET_FIELD",
                      payload: {
                        fieldName: "isActive",
                        value: Boolean(value === "active"),
                      },
                    })
                  }}
                  value={status}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={
                      <RadioOption
                        description={
                          t("component.itemBasicInfoForm.status.active.description") as string
                        }
                        label={t("component.itemBasicInfoForm.status.active.label")}
                      />
                    }
                    value="active"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label={
                      <RadioOption
                        description={
                          t("component.itemBasicInfoForm.status.inactive.description") as string
                        }
                        label={t("component.itemBasicInfoForm.status.inactive.label")}
                      />
                    }
                    value="inactive"
                  />
                </RadioGroup>
              </Box>
            ) : null}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1rem",
                "& label": {
                  fontWeight: 600,
                  fontSize: "1.1em",
                },
              }}
            >
              <label>{t("component.bundleForm.productsIncluded")}</label>
            </Box>
            <BundleComponentToggle
              checked={state.enableUnitSalePriceOverrides.value}
              label={t("enableUnitSalePriceOverrides") as string}
              name="enableUnitSalePriceOverrides"
              onChange={(e) => {
                dispatch({
                  type: "SET_FIELD",
                  payload: {
                    fieldName: "enableUnitSalePriceOverrides",
                    value: e.target.checked,
                  },
                })
              }}
              tooltip={t("component.bundleForm.enableUnitSalePriceOverridesTooltip") as string}
            />
          </Box>
          <BundleComponentsForm
            components={state.components}
            currencyCode={currencyCode}
            dispatch={dispatch}
          />
        </Box>
      </Box>
      <Box
        sx={(theme) => {
          return {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: 0,
            [theme.breakpoints.up("md")]: {
              padding: 0,
            },
          }
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton disabled={!isValid()} loading={loading} onClick={handleSubmit} />
      </Box>
    </Box>
  )
}

function BundleComponentToggle({
  checked,
  label,
  name,
  onChange,
  tooltip,
}: {
  readonly checked: boolean
  readonly label: string
  readonly name: string
  readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  readonly tooltip: string
}) {
  return (
    <Box
      sx={(theme) => {
        return {
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "0.25rem",
          alignItems: "center",
          "& label > span": {
            fontSize: "0.75rem",
            [theme.breakpoints.up("sm")]: {
              fontSize: "0.85rem",
            },
            [theme.breakpoints.up("md")]: {
              fontSize: "0.95rem",
            },
          },
        }
      }}
    >
      <FormControlLabel
        control={<Checkbox checked={checked} name={name} onChange={onChange} />}
        label={label}
        sx={{ fontSize: "0.25rem" }}
      />
      <Box style={{ fontSize: "0.625rem", marginTop: "0.3125rem" }}>
        <Tooltip arrow enterDelay={300} placement="right" title={tooltip}>
          <HelpOutlineIcon fontSize="small" />
        </Tooltip>
      </Box>
    </Box>
  )
}

export default BundleForm
