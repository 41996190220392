/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { gql, useMutation, useQuery } from "@apollo/client"
import difference from "lodash/difference"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import WarningIcon from "@mui/icons-material/Warning"
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrashOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import Seo from "../../../components/Seo"
import SnackbarMessage from "../../../components/SnackbarMessage"
import SectionHeader from "../../../components/SectionHeader"
import SectionContent from "../../../components/SectionContent"
import JobWorkflowBasicInfoStatic from "./components/JobWorkflowBasicInfoStatic"
import JobWorkflowBasicInfoForm from "./components/JobWorkflowBasicInfoForm"
import JobWorkflowStepDialog from "./components/JobWorkflowStepDialog"
import { formatPersonName, NOT_SPECIFIED, parseGraphQLErrorCode, SETTINGS } from "../../../util"
import { useAuth } from "../../../context/AuthContext"
import * as Sentry from "@sentry/react"
import { Snack, JobWorkflowStep, JobWorkflow, DefaultPermission } from "../../../types"
import ArchiveButton from "../../../components/ArchiveButton"
import FielderIconButton from "../../../components/FielderIconButton"

const FullJobWorkflowStep = gql`
  fragment FullJobWorkflowStep on JobWorkflowStep {
    id
    boardPosition
    isDeletable
    isInitialStep
    jobStatus {
      id
      name
      lightColor
      mediumColor
      darkColor
      parentStatus {
        id
        name
      }
    }
    responsibleUsers {
      id
      firstName
      lastName
      email
    }
    responsibleRole {
      id
      name
    }
    events {
      id
      name
    }
    destinationTransitions {
      id
      name
      destination {
        id
        jobStatus {
          id
          name
        }
      }
    }
  }
`

const FullJobWorkflow = gql`
  fragment FullJobWorkflow on JobWorkflow {
    id
    name
    description
    isPublished
    isArchived
    organization {
      id
      level
    }
    parentWorkflow {
      id
      name
      isPublished
      isArchived
      steps {
        id
        jobStatus {
          id
          name
        }
        destinationTransitions {
          id
          destination {
            id
            jobStatus {
              id
              name
            }
          }
        }
      }
    }
    availableStatuses {
      id
      name
      parentStatus {
        id
        name
      }
    }
    steps {
      ...FullJobWorkflowStep
    }
  }
  ${FullJobWorkflowStep}
`

const GET_JOB_WORKFLOW_BY_ID = gql`
  query GetJobWorkflowById($id: ID!) {
    getJobWorkflowById(id: $id) {
      ...FullJobWorkflow
    }
  }
  ${FullJobWorkflow}
`

const EDIT_JOB_WORKFLOW = gql`
  mutation EditJobWorkflow(
    $id: ID!
    $parentWorkflowId: ID
    $name: String
    $description: String
    $isArchived: Boolean
    $isPublished: Boolean
  ) {
    editJobWorkflow(
      input: {
        id: $id
        parentWorkflowId: $parentWorkflowId
        name: $name
        description: $description
        isArchived: $isArchived
        isPublished: $isPublished
      }
    ) {
      jobWorkflow {
        ...FullJobWorkflow
      }
    }
  }
  ${FullJobWorkflow}
`

const CREATE_JOB_WORKFLOW_STEP = gql`
  mutation CreateJobWorkflowStep(
    $workflowId: ID!
    $jobStatusId: ID
    $boardPosition: BigDecimal
    $responsibleUserIds: [ID!]
    $eventIds: [ID!]
    $destinationStepIds: [ID!]
    $isInitialStep: Boolean
  ) {
    createJobWorkflowStep(
      input: {
        workflowId: $workflowId
        jobStatusId: $jobStatusId
        boardPosition: $boardPosition
        responsibleUserIds: $responsibleUserIds
        eventIds: $eventIds
        destinationStepIds: $destinationStepIds
        isInitialStep: $isInitialStep
      }
    ) {
      jobWorkflowStep {
        ...FullJobWorkflowStep
      }
    }
  }
  ${FullJobWorkflowStep}
`

const EDIT_JOB_WORKFLOW_STEP = gql`
  mutation EditJobWorkflowStep(
    $id: ID!
    $workflowId: ID!
    $jobStatusId: ID
    $boardPosition: BigDecimal
    $responsibleUserIds: [ID!]
    $eventIds: [ID!]
    $destinationStepIds: [ID!]
    $isInitialStep: Boolean
  ) {
    editJobWorkflowStep(
      input: {
        id: $id
        workflowId: $workflowId
        jobStatusId: $jobStatusId
        boardPosition: $boardPosition
        responsibleUserIds: $responsibleUserIds
        eventIds: $eventIds
        destinationStepIds: $destinationStepIds
        isInitialStep: $isInitialStep
      }
    ) {
      jobWorkflowStep {
        ...FullJobWorkflowStep
      }
    }
  }
  ${FullJobWorkflowStep}
`

const DELETE_JOB_WORKFLOW_STEP = gql`
  mutation DeleteJobWorkflowStep($id: ID!) {
    deleteJobWorkflowStep(id: $id) {
      jobWorkflow {
        ...FullJobWorkflow
      }
    }
  }
  ${FullJobWorkflow}
`

function EditJobWorkflow() {
  const { t } = useTranslation()
  const { user, hasPermissions } = useAuth()
  const { id } = useParams()
  const [snack, setSnack] = useState<Snack | undefined>()
  const [inEditWorkflowMode, setInEditWorkflowMode] = useState<boolean>(false)
  const [isEditStepDialogOpen, setIsEditStepDialogOpen] = useState<boolean>(false)
  const [isArchiveWorkflowDialogOpen, setIsArchiveWorkflowDialogOpen] = useState<boolean>(false)
  const [isUnarchiveWorkflowDialogOpen, setIsUnarchiveWorkflowDialogOpen] = useState<boolean>(false)
  const [isConfirmDeleteStepDialogOpen, setIsConfirmDeleteStepDialogOpen] = useState<boolean>(false)
  const [selectedStep, setSelectedStep] = useState<JobWorkflowStep>()
  const [workflow, setWorkflow] = useState<JobWorkflow>()
  const [steps, setSteps] = useState<JobWorkflowStep[]>([])

  const { networkStatus: getJobWorkflowByIdNetworkStatus } = useQuery(GET_JOB_WORKFLOW_BY_ID, {
    fetchPolicy: "cache-and-network",
    variables: { id },
    onCompleted: (data) => {
      const wf = data?.getJobWorkflowById
      setWorkflow(wf)
      const sortedSteps = wf?.steps
        ? [].concat(wf?.steps).sort((a, b) => a.boardPosition - b.boardPosition)
        : []
      setSteps(sortedSteps)
    },
  })

  const [editJobWorkflow, { loading: editJobWorkflowLoading }] = useMutation(EDIT_JOB_WORKFLOW, {
    onCompleted: () => {
      setInEditWorkflowMode(false)
      setIsArchiveWorkflowDialogOpen(false)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: ["GetJobWorkflowById"],
  })

  const [createJobWorkflowStep, { loading: createJobWorkflowStepLoading }] = useMutation(
    CREATE_JOB_WORKFLOW_STEP,
    {
      onCompleted: () => {
        setIsEditStepDialogOpen(false)
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
      refetchQueries: ["GetJobWorkflowById"],
    }
  )

  const [editJobWorkflowStep, { loading: editJobWorkflowStepLoading }] = useMutation(
    EDIT_JOB_WORKFLOW_STEP,
    {
      onCompleted: () => {
        setIsEditStepDialogOpen(false)
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
      refetchQueries: ["GetJobWorkflowById"],
    }
  )

  const [deleteJobWorkflowStep] = useMutation(DELETE_JOB_WORKFLOW_STEP, {
    onCompleted: () => {
      setIsConfirmDeleteStepDialogOpen(false)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: ["GetJobWorkflowById"],
  })

  const loading = getJobWorkflowByIdNetworkStatus === 1
  const isEditable = !(workflow?.organization?.level === 1 && workflow?.isPublished)

  function handleCancelEditWorkflow() {
    setInEditWorkflowMode(false)
  }

  function handleSaveWorkflow(payload) {
    editJobWorkflow(payload)
  }

  function handleRowDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const startIndex = result.source.index
    const endIndex = result.destination.index
    if (startIndex === endIndex) {
      return
    }

    const sourceStep = steps[startIndex]
    const currentStepAtDestIdx = steps[endIndex]

    let position = currentStepAtDestIdx.boardPosition ?? 0
    if (startIndex < endIndex) {
      // we're moving an item down the list (to a higher position)
      position += 1
    } else {
      // we're moving an item up the list (to a lower position)
      position -= 1
    }

    const sortedSteps = Array.from(steps)
    sortedSteps.splice(startIndex, 1)
    sortedSteps.splice(endIndex, 0, sourceStep)
    setSteps(sortedSteps)

    editJobWorkflowStep({
      variables: {
        id: sourceStep.id,
        workflowId: workflow?.id,
        boardPosition: position,
      },
    })
  }

  function getRowStyle(isDragging: boolean, draggableStyle) {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",

      // change background colour if dragging
      background: isDragging ? "#f1f8ff" : "#fff",

      // styles we need to apply on draggables
      ...draggableStyle,
    }
  }

  function getListStyle(isDraggingOver: boolean): React.CSSProperties {
    return {
      tableLayout: "fixed",
      background: isDraggingOver ? "#efefef" : "#fff",
    }
  }

  function renderBasicInfoHeaderButton() {
    if (workflow?.isArchived) {
      return (
        <Button
          color="secondary"
          onClick={() => setIsUnarchiveWorkflowDialogOpen(true)}
          variant="outlined"
        >
          <RestoreFromTrashIcon sx={{ fontSize: "1rem", marginRight: "0.625rem" }} />
          <span>{t("unarchive")}</span>
        </Button>
      )
    } else if (!inEditWorkflowMode && hasPermissions?.([DefaultPermission.UpdateJobWorkflow])) {
      return (
        <Box>
          <ArchiveButton onClick={() => setIsArchiveWorkflowDialogOpen(true)} />
          <IconButton
            aria-label="edit"
            data-testid="editJobWorkflowBtn"
            onClick={() => {
              setInEditWorkflowMode(true)
            }}
          >
            <EditIcon />
          </IconButton>
        </Box>
      )
    } else {
      return null
    }
  }

  return (
    <>
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Seo title={t(SETTINGS.titleKey)} />
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/jobworkflows/list", titleKey: "jobWorkflows" },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey="editJobWorkflow"
          />
          {loading ? (
            <Box sx={classes.spinnerContainer}>
              <CircularProgress color="secondary" size={60} thickness={6.0} />
            </Box>
          ) : null}
          {!loading && (
            <Box sx={{ marginTop: "1.25rem" }}>
              <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <Paper>
                    <SectionHeader>
                      <label>{t("basicInfo")}</label>
                      {workflow?.isArchived ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "rgba(218, 21, 5, 0.1)",
                            padding: "0.25rem 0.5rem",
                            borderRadius: "4px",
                          }}
                        >
                          <WarningIcon
                            sx={{
                              fontSize: "1.125rem",
                              color: "#DA1505",
                              marginRight: "0.625rem",
                            }}
                          />
                          <span>
                            {user?.organization?.level === 1
                              ? t("page.jobWorkflow.workflowIsArchived_level1")
                              : t("page.jobWorkflow.workflowIsArchived")}
                          </span>
                        </Box>
                      ) : null}
                      {renderBasicInfoHeaderButton()}
                    </SectionHeader>
                    <Divider />
                    {!inEditWorkflowMode && workflow ? (
                      <SectionContent>
                        <JobWorkflowBasicInfoStatic
                          onPublish={() => {
                            // Make sure that every step in the workflow is reachable before publishing.
                            // A step can be a dead-end, but every step besides the initial step must be
                            // a destination for at least one other step.
                            const initialStep = workflow?.steps?.find((s) => s.isInitialStep)
                            const destinationStepIds =
                              workflow?.steps
                                ?.flatMap((s) => s.destinationTransitions)
                                ?.map((t) => t?.destination?.id)
                                ?.filter((id) => id !== initialStep?.id) ?? []

                            const allButInitialStepIds = workflow.steps
                              ?.filter((s) => !s.isInitialStep)
                              ?.map((s) => s.id)

                            if (difference(allButInitialStepIds, destinationStepIds).length !== 0) {
                              setSnack({
                                messageKey: "error.server.job-workflow.step.unreachable",
                                variant: "error",
                              })
                            } else {
                              handleSaveWorkflow({
                                variables: {
                                  id: workflow.id,
                                  isPublished: true,
                                },
                              })
                            }
                          }}
                          workflow={workflow}
                        />
                      </SectionContent>
                    ) : null}
                    {inEditWorkflowMode && workflow ? (
                      <SectionContent
                        sx={{
                          padding: "1.5rem",
                        }}
                      >
                        <JobWorkflowBasicInfoForm
                          loading={editJobWorkflowLoading}
                          onCancel={handleCancelEditWorkflow}
                          onSave={handleSaveWorkflow}
                          workflow={workflow}
                        />
                      </SectionContent>
                    ) : null}
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ marginBottom: "8rem" }}>
                    <SectionHeader sx={{ backgroundColor: "#F9F9F9" }}>
                      <label />
                      {isEditable ? (
                        <Button
                          onClick={() => {
                            setSelectedStep(undefined)
                            setIsEditStepDialogOpen(true)
                          }}
                          sx={{
                            fontWeight: "bold",
                            "& svg": {
                              fontSize: "1.0rem",
                            },
                            "& div": {
                              marginLeft: "0.625rem",
                              marginRight: "0.625rem",
                            },
                          }}
                        >
                          <AddIcon fontSize="small" />
                          <div>{t("addStep")}</div>
                        </Button>
                      ) : null}
                    </SectionHeader>
                    <Divider />
                    <DragDropContext onDragEnd={handleRowDragEnd}>
                      <TableContainer sx={classes.tableContainer}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {isEditable ? (
                                <TableCell sx={classes.tableHeader} width="5%" />
                              ) : null}
                              <TableCell
                                sx={[
                                  classes.tableHeader,
                                  { paddingLeft: !isEditable ? "1.875rem" : 0 },
                                ]}
                                width="20%"
                              >
                                {t("status")}
                              </TableCell>
                              <TableCell sx={classes.tableHeader} width="20%">
                                {t("transitionsTo")}
                              </TableCell>
                              <TableCell sx={classes.tableHeader} width="20%">
                                {t("staffResponsible")}
                              </TableCell>
                              <TableCell sx={classes.tableHeader} width="20%">
                                {t("events")}
                              </TableCell>
                              <TableCell sx={classes.tableHeader} width="15%" />
                            </TableRow>
                          </TableHead>
                          <Droppable droppableId="droppable" isDropDisabled={!isEditable}>
                            {(provided, snapshot) => (
                              <TableBody
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                sx={getListStyle(snapshot.isDraggingOver)}
                              >
                                {steps?.map((step: JobWorkflowStep, index) => {
                                  return (
                                    <Draggable
                                      draggableId={step.id}
                                      index={index}
                                      isDragDisabled={!isEditable}
                                      key={step.id}
                                    >
                                      {(provided, snapshot) => (
                                        <TableRow
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          sx={getRowStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          {isEditable ? (
                                            <TableCell
                                              title={
                                                t("page.jobWorkflow.helperText.dragRow") as string
                                              }
                                              width="5%"
                                            >
                                              <DragHandleIcon color="action" />
                                            </TableCell>
                                          ) : null}
                                          <TableCell
                                            sx={{
                                              paddingLeft: !isEditable ? "1.875rem" : 0,
                                            }}
                                            width="20%"
                                          >
                                            <Box sx={classes.jobStatusCellContentContainer}>
                                              <Box
                                                sx={[
                                                  classes.jobStatusColorBox,
                                                  { backgroundColor: step.jobStatus.mediumColor },
                                                ]}
                                              />
                                              {step.jobStatus.name}
                                              {step.isInitialStep ? (
                                                <em style={{ marginLeft: "0.625rem" }}>
                                                  {t("page.jobWorkflow.helperText.initialStep")}
                                                </em>
                                              ) : null}
                                            </Box>
                                          </TableCell>
                                          <TableCell width="20%">
                                            <ul style={{ paddingInlineStart: "24px" }}>
                                              {step.destinationTransitions.map((t) => (
                                                <li key={t.id}>{t.destination.jobStatus.name}</li>
                                              ))}
                                            </ul>
                                          </TableCell>
                                          <TableCell width="20%">
                                            <ul>
                                              {step.responsibleUsers
                                                ? step.responsibleUsers.map((u) => (
                                                    <li key={u.id}>{formatPersonName(u)}</li>
                                                  ))
                                                : step.responsibleRole
                                                  ? step.responsibleRole.name
                                                  : NOT_SPECIFIED}
                                            </ul>
                                          </TableCell>
                                          <TableCell width="20%">
                                            <ul>
                                              {step.events
                                                ? step.events.map((e) => (
                                                    <li key={e.id}>{e.name}</li>
                                                  ))
                                                : NOT_SPECIFIED}
                                            </ul>
                                          </TableCell>
                                          <TableCell width="15%">
                                            <Box sx={classes.rowButtonContainer}>
                                              {hasPermissions?.([
                                                DefaultPermission.UpdateJobWorkflow,
                                              ]) ? (
                                                <FielderIconButton
                                                  aria-label="edit"
                                                  data-testid="editJobWorkStepflowBtn"
                                                  onClick={() => {
                                                    setSelectedStep(step)
                                                    setIsEditStepDialogOpen(true)
                                                  }}
                                                >
                                                  <EditIcon />
                                                </FielderIconButton>
                                              ) : null}
                                              {isEditable &&
                                              hasPermissions?.([
                                                DefaultPermission.UpdateJobWorkflow,
                                              ]) ? (
                                                <FielderIconButton
                                                  aria-label="delete"
                                                  data-testid="deleteJobWorkflowStepBtn"
                                                  disabled={!step.isDeletable}
                                                  onClick={() => {
                                                    setSelectedStep(step)
                                                    setIsConfirmDeleteStepDialogOpen(true)
                                                  }}
                                                  title={
                                                    !step.isDeletable
                                                      ? (t(
                                                          `page.jobWorkflow.helperText.deleteStep.${
                                                            step.isInitialStep
                                                              ? "initial"
                                                              : "disabled"
                                                          }`
                                                        ) as string)
                                                      : ""
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </FielderIconButton>
                                              ) : null}
                                            </Box>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </Draggable>
                                  )
                                })}
                                {provided.placeholder}
                              </TableBody>
                            )}
                          </Droppable>
                        </Table>
                      </TableContainer>
                    </DragDropContext>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </MainLayout>
      {isEditStepDialogOpen && workflow ? (
        <JobWorkflowStepDialog
          loading={createJobWorkflowStepLoading || editJobWorkflowStepLoading}
          onCancel={() => {
            setIsEditStepDialogOpen(false)
            setSelectedStep(undefined)
          }}
          onClose={() => {
            setIsEditStepDialogOpen(false)
            setSelectedStep(undefined)
          }}
          onSave={(updatedStep) => {
            if (selectedStep?.id) {
              let variables = { ...updatedStep }

              if (user?.organization?.level === 1 && workflow?.isPublished) {
                variables = {
                  id: selectedStep.id,
                  responsibleUserIds: updatedStep.responsibleUserIds,
                  responsibleRoleId: updatedStep.responsibleRoleId,
                  eventIds: updatedStep.eventIds,
                }
              }
              editJobWorkflowStep({ variables })
            } else {
              createJobWorkflowStep({
                variables: {
                  isArchived: false,
                  ...updatedStep,
                },
              })
            }
          }}
          open={isEditStepDialogOpen}
          step={selectedStep}
          workflow={workflow}
        />
      ) : null}
      {isConfirmDeleteStepDialogOpen && selectedStep ? (
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={() => setIsConfirmDeleteStepDialogOpen(false)}
          open={isConfirmDeleteStepDialogOpen}
        >
          <DialogTitle id="alert-dialog-title">
            {t("page.jobWorkflow.confirmDeleteStepPromptTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("page.jobWorkflow.confirmDeleteStepPromptMsg")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setIsConfirmDeleteStepDialogOpen(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                setIsConfirmDeleteStepDialogOpen(false)
                deleteJobWorkflowStep({ variables: { id: selectedStep.id } })
              }}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {isArchiveWorkflowDialogOpen ? (
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={() => setIsArchiveWorkflowDialogOpen(false)}
          open={isArchiveWorkflowDialogOpen}
        >
          <DialogTitle id="alert-dialog-title">
            {t("page.jobWorkflow.confirmArchiveWorkflowPromptTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {user?.organization?.level === 1
                ? t("page.jobWorkflow.confirmArchiveLevel1WorkflowPromptMsg")
                : t("page.jobWorkflow.confirmArchiveWorkflowPromptMsg")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setIsArchiveWorkflowDialogOpen(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                setIsArchiveWorkflowDialogOpen(false)
                editJobWorkflow({
                  variables: {
                    id: workflow?.id,
                    isArchived: true,
                  },
                })
              }}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {isUnarchiveWorkflowDialogOpen ? (
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={() => setIsUnarchiveWorkflowDialogOpen(false)}
          open={isUnarchiveWorkflowDialogOpen}
        >
          <DialogTitle id="alert-dialog-title">
            {t("page.jobWorkflow.confirmUnarchiveWorkflowPromptTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("page.jobWorkflow.confirmUnarchiveWorkflowPromptMsg")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setIsUnarchiveWorkflowDialogOpen(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                setIsUnarchiveWorkflowDialogOpen(false)
                editJobWorkflow({
                  variables: {
                    id: workflow?.id,
                    isArchived: false,
                  },
                })
              }}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
  },
  archiveButton: {
    color: "#C70039",
    borderColor: "#C70039",
    marginRight: "3rem",
    "&:hover": {
      borderColor: "#C70039",
      backgroundColor: "#C7003910",
    },
  },
  tableContainer: {
    minHeight: "200px",
  },
  tableHeader: {
    backgroundColor: "#ffffff",
  },
  rowButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "3.75rem",
    marginBottom: "2.5rem",
    height: "50vh",
    maxHeight: "400px",
    minHeight: "200px",
  },
  jobStatusCellContentContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  jobStatusColorBox: {
    width: "1.25rem",
    height: "1.25rem",
    borderRadius: "6px",
    marginRight: "0.625rem",
  },
}

export default EditJobWorkflow
