import gql from "graphql-tag"

export default gql`
  fragment TaxRateGroupDetails on TaxRateGroup {
    id
    name
    totalTaxRate
    isArchived
    isLocked
    taxRates {
      id
      name
      rate
    }
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    updatedAt
    updatedBy {
      id
      firstName
      lastName
    }
  }
`
