import React from "react"

function HowToPerformSearchesInDashboard() {
  return (
    <div>
      <h3>Performing searches in the dashboard</h3>
      <p>
        Just click in the white area at the top of the screen, where the little magnifying glass is,
        and type in the search term you want. The system will start searching as soon as you start
        typing. You can type anything you want to search across all of your Jobs, Customers,
        Estimates, Invoices, Inventory Items, Purchase Orders, and Tasks. (The results you see will
        be limited to based on your access permissions.) You can limit the scope of the search to
        single type of entity. For example, if you only want to search for customers, you can start
        your search with "customer:".
      </p>
    </div>
  )
}

export default HowToPerformSearchesInDashboard
