import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import FieldHelperText from "../../../components/FieldHelperText"
import MoneyInput from "../../../components/MoneyInput"
import { ItemFormContext } from "./ItemFormContext"
import FormLabel from "@mui/material/FormLabel"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import RadioOption from "../../../components/RadioOption"
import Radio from "@mui/material/Radio"
import Divider from "@mui/material/Divider"

interface ServiceDetailsFormProps {
  readonly currencyCode: string
}

function ServiceDetailsForm({ currencyCode }: ServiceDetailsFormProps) {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(ItemFormContext)
  const [status, setStatus] = useState<string>(() => {
    return state.isActive ? "active" : "inactive"
  })

  return (
    <>
      {state.id ? (
        <>
          <Box sx={{ marginBottom: "1.25rem" }}>
            <FormLabel
              id="status-group-label"
              sx={{
                fontSize: "0.875rem",
                fontWeight: 700,
                color: (theme) => theme.fielderColors.black,
              }}
            >
              {t("status")}
            </FormLabel>
            <RadioGroup
              aria-labelledby="status-group-label"
              defaultValue="active"
              name="status-group"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = (event.target as HTMLInputElement).value
                setStatus(value)
                dispatch({
                  type: "SET_FIELD",
                  payload: {
                    fieldName: "isActive",
                    value: Boolean(value === "active"),
                  },
                })
              }}
              value={status}
            >
              <FormControlLabel
                control={<Radio />}
                label={
                  <RadioOption
                    description={
                      t("component.itemBasicInfoForm.status.active.description") as string
                    }
                    label={t("component.itemBasicInfoForm.status.active.label")}
                  />
                }
                value="active"
              />
              <FormControlLabel
                control={<Radio />}
                label={
                  <RadioOption
                    description={
                      t("component.itemBasicInfoForm.status.inactive.description") as string
                    }
                    label={t("component.itemBasicInfoForm.status.inactive.label")}
                  />
                }
                value="inactive"
              />
            </RadioGroup>
          </Box>
          {state.organizationItem.isTopLevel ? <Divider /> : null}
        </>
      ) : null}
      <Box sx={{ marginTop: "1.25rem" }}>
        <MoneyInput
          currencyCode={currencyCode}
          data-testid="unitSalePrice-Field"
          error={!!state.unitSalePrice.error}
          fullWidth
          id="unitSalePrice"
          label={t("unitSalePrice")}
          max={9999999999}
          name="unitSalePrice"
          onChange={(e) => {
            dispatch({
              type: "SET_FIELD",
              payload: { fieldName: "unitSalePrice", value: e.target.value },
            })
          }}
          value={state.unitSalePrice.value}
        />
        {!(state.unitSalePrice.error && state.unitSalePrice.touched) ? (
          <FieldHelperText message={t("component.itemBasicInfoForm.helperText.unitSalePrice")} />
        ) : (
          <FieldHelperText error message={t(state.unitSalePrice.error)} />
        )}
      </Box>
    </>
  )
}

export default ServiceDetailsForm
