import React, { useEffect, useRef } from "react"
import FielderTextField from "./FielderTextField"
import { convertPlaceToAddress, useGoogleMaps, isBlank } from "../util"
import type { Address } from "../types/apiTypes"
import type { Coordinate } from "../types/appTypes"

interface Props {
  readonly centerCoordinate?: Coordinate
  readonly defaultValue?: string
  readonly hasError?: boolean
  readonly label?: string
  readonly name?: string
  readonly onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  readonly onChange: (value: Partial<Address> | null) => void
  readonly required?: boolean
}

function AddressAutocompleteField({
  centerCoordinate,
  defaultValue,
  hasError = false,
  label,
  name,
  onBlur,
  onChange,
  required = false,
}: Props) {
  const addressAutocompleteRef = useRef(null)
  const googleMapsApi = useGoogleMaps()

  useEffect(() => {
    if (googleMapsApi && addressAutocompleteRef.current) {
      // Create the autocomplete objects, restricting the search predictions to
      //  return only geocoding results with a precise address
      const addressField = new googleMapsApi.maps.places.Autocomplete(
        addressAutocompleteRef.current,
        {
          types: ["address"],
        }
      )

      // Use the centerCoordinate arg if given, otherwise use the center of the USA.
      const center = centerCoordinate ?? {
        lat: 39.80986,
        lng: -98.555183,
      }

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components, and restricting
      // geometric boundary of the search.
      const addressComponents = ["formatted_address", "geometry", "address_components"]
      addressField.setFields(addressComponents)
      const circle = new googleMapsApi.maps.Circle({
        center,
        radius: 321869, // radius is in meters. 321869 meters ~= 200 miles
      })

      const bounds = circle.getBounds()
      addressField.setBounds(bounds)

      addressField.addListener("place_changed", () => {
        // Get the place details from the addressAutocomplete object.
        const place = addressField.getPlace()
        const addressObj = convertPlaceToAddress(place)
        onChange?.(addressObj)
      })
    }
  }, [googleMapsApi, addressAutocompleteRef, centerCoordinate, onChange])

  return (
    <FielderTextField
      defaultValue={defaultValue}
      error={hasError}
      fullWidth
      inputProps={{ maxLength: 1000 }}
      inputRef={addressAutocompleteRef}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={(e) => {
        e.target.setAttribute("autocomplete", "new-password")
        const val = e.target.value
        if (isBlank(val)) {
          onChange(null)
        } else {
          onChange({
            addressString: val,
          })
        }
      }}
      onFocus={(e) => {
        e.target.select()
        e.target.setAttribute("autocomplete", "new-password")
      }}
      required={required}
    />
  )
}

export default React.memo(AddressAutocompleteField)
