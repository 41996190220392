import gql from "graphql-tag"

export const ALL_JOB_STATUSES = gql`
  query AllJobStatuses(
    $filter: String
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $onlyParentStatuses: Boolean
  ) {
    allJobStatuses(
      input: {
        filter: $filter
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        last: $last
        after: $after
        before: $before
      }
      jobStatusFilter: { onlyParentStatuses: $onlyParentStatuses }
    ) {
      edges {
        cursor
        node {
          id
          name
          description
          darkColor
          mediumColor
          lightColor
          isArchived
          parentStatus {
            id
            name
            darkColor
            mediumColor
            lightColor
          }
        }
      }
    }
  }
`
