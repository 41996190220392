import React from "react"

function WhatDoesFielderNotDo() {
  return (
    <div>
      <h3>
        What does Fielder <i>not</i> do?
      </h3>
      <p>
        Fielder aims to provide a lot of value to franchisees, but it can’t (and shouldn’t try to)
        do everything. Fielder’s primary focus is around managing Jobs. In order to do that, there
        is some overlap in functionality between Fielder and other types of special-purpose systems.
        For example - even though Fielder provides a way to create and send estimates and invoices
        to customers, Fielder is not an accounting system. Fielder allows you to integrate with
        either QuickBooks or Xero, two popular accounting packages, so you can send invoice and
        inventory data to those systems if you need to. Fielder is not a marketing system or a
        full-blown CRM system. We do not currently offer any integrations with marketing or CRM
        systems.
      </p>
    </div>
  )
}

export default WhatDoesFielderNotDo
