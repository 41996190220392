import React from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { ALL_JOB_CATEGORIES } from "../../../queries/allJobCategories"
import { FieldSpinner, MultiSelect } from "../../../components"
import * as Sentry from "@sentry/react"
import { SelectorOption, JobCategory } from "../../../types"

interface JobCategorySelectProps {
  readonly id?: string
  readonly name: string
  readonly label: string
  readonly onBlur?: () => void
  readonly onChange: (value: SelectorOption[]) => void
  readonly required?: boolean
  readonly selectedValues?: JobCategory[]
}

function JobCategorySelect({
  id,
  name,
  label,
  selectedValues = [],
  onBlur,
  onChange,
  required = false,
}: JobCategorySelectProps) {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(ALL_JOB_CATEGORIES, {
    fetchPolicy: "cache-and-network",
  })

  if (loading) {
    return <FieldSpinner message={t("loadingCategories")} />
  }

  if (error) {
    Sentry.captureException(error)
    return <div>{`Error! ${error.message}`}</div>
  }

  const options = data.allJobCategories.edges.map((e) => e.node)

  // Make sure that any archived categories that are currently being used
  // are included as options.
  selectedValues?.forEach((sv) => {
    if (options?.findIndex((o) => o.id === sv.id) === -1) {
      options.push(sv)
    }
  })

  return (
    <MultiSelect
      id={id}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      required={required}
      selectedValues={selectedValues}
    />
  )
}

export default React.memo(JobCategorySelect)
