import React from "react"
import { Box } from "@mui/material"

export interface CustomerFlagColorOption {
  name: string
  colorCode: string
}

const palette: CustomerFlagColorOption[] = [
  {
    name: "Slate",
    colorCode: "#64748b",
  },
  {
    name: "Stone",
    colorCode: "#78716c",
  },
  {
    name: "Red",
    colorCode: "#dc2626",
  },
  {
    name: "Rose",
    colorCode: "#f43f5e",
  },
  {
    name: "Orange",
    colorCode: "#f97316",
  },
  {
    name: "Green",
    colorCode: "#16a34a",
  },
  {
    name: "Blue",
    colorCode: "#3b82f6",
  },
  {
    name: "Sky",
    colorCode: "#0ea5e9",
  },
  {
    name: "Teal",
    colorCode: "#14b8a6",
  },
  {
    name: "Cyan",
    colorCode: "#06b6d4",
  },
  {
    name: "Purple",
    colorCode: "#a855f7",
  },
  {
    name: "Fuscia",
    colorCode: "#d946ef",
  },
  {
    name: "Yellow",
    colorCode: "#facc15",
  },
  {
    name: "Darker Yellow",
    colorCode: "#eab308",
  },
  {
    name: "Kumera",
    colorCode: "#b29e40",
  },
  {
    name: "Green Smoke",
    colorCode: "#869151",
  },
  {
    name: "Black",
    colorCode: "#000000",
  },
]

interface Props {
  readonly onChange: (colorOption: CustomerFlagColorOption) => void
}

export function CustomerFlagColorPicker({ onChange }: Props): JSX.Element {
  return (
    <Box
      sx={{
        background: "#fff",
        border: "0 solid rgba(0,0,0,0.25)",
        boxShadow: "0 1px 4px rgba(0,0,0,0.25)",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "0px",
          height: "0px",
          borderStyle: "solid",
          borderWidth: "0 9px 10px 9px",
          borderColor: "transparent transparent rgba(0,0,0,.1) transparent",
          position: "absolute",
        }}
      />
      <Box
        sx={{
          width: "0px",
          height: "0px",
          borderStyle: "solid",
          borderWidth: "0 9px 10px 9px",
          borderColor: "transparent transparent #fff transparent",
          position: "absolute",
        }}
      />
      <Box
        sx={{
          padding: "15px 9px 9px 15px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "6px",
          maxWidth: "228px",
        }}
      >
        {palette.map((c) => {
          return (
            <Box
              key={c.name}
              onClick={() => {
                onChange(c)
              }}
              sx={{
                width: "30px",
                height: "30px",
                float: "left",
                borderRadius: "4px",
                margin: "0 6px 6px 0",
                background: c.colorCode,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          )
        })}
      </Box>
    </Box>
  )
}
