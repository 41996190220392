/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from "react"
import { Navigate, useLocation, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/EditOutlined"
import Skeleton from "@mui/material/Skeleton"
import EmptyState from "../../../components/EmptyState"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import SearchField from "../../../components/SearchField"
import Seo from "../../../components/Seo"
import SnackbarMessage from "../../../components/SnackbarMessage"
import SortableTableHeader from "../../../components/SortableTableHeader"
import TablePaginationActions from "../../../components/TablePaginationActions"
import { ALL_USERS } from "../../../queries/allUsers"
import { formatPersonName, isBlank, SETTINGS, useDebounce } from "../../../util"
import { useAuth } from "../../../context/AuthContext"
import { DefaultPermission, Snack } from "../../../types"
import UserStatusPill from "./components/UserStatusPill"
import useStore, {
  includeArchivedUsersToggleSelector,
  setIncludeArchivedUsersToggleSelector,
} from "../../../store"
import { FormControlLabel, Checkbox } from "@mui/material"
import UserAvatar from "../../../components/UserAvatar"

const classes = {
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
    "& svg": {
      fontSize: "0.8rem",
    },
  },
  actionCell: {
    textAlign: "right",
  },
  linkButton: {
    textDecoration: "none",
  },
  dataRow: {
    height: "3.75rem",
  },
} as const

function UserList() {
  const { t } = useTranslation()
  const location = useLocation()
  const { hasPermissions, user } = useAuth()
  const includeArchivedUsersToggle = useStore(includeArchivedUsersToggleSelector)
  const setIncludeArchivedUsersToggle = useStore(setIncludeArchivedUsersToggleSelector)

  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [filter, setFilter] = useState<string>("")
  const [after, setAfter] = useState<number | null>()
  const [before, setBefore] = useState<number | null>()
  const [first, setFirst] = useState<number | null>(10)
  const [last, setLast] = useState<number | null>()
  const [sortBy, setSortBy] = useState<string>("lastName")
  const [sortDir, setSortDir] = useState<"DESC" | "ASC">("DESC")
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const debouncedSearchTerm = useDebounce(filter, 500)

  const { loading, error, data } = useQuery(ALL_USERS, {
    variables: {
      filter: debouncedSearchTerm,
      after,
      before,
      first,
      last,
      sortBy,
      sortDir,
      includeChildOrganizationUsers: true,
      includeArchived: Boolean(includeArchivedUsersToggle),
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: "ALL_USERS",
      debounceTimeout: 50,
    },
  })

  function onGoToFirstPage() {
    setAfter(null)
    setBefore(null)
    setFirst(rowsPerPage)
    setLast(null)
  }

  function onGoToLastPage() {
    setAfter(null)
    setBefore(null)
    setFirst(null)
    setLast(rowsPerPage)
  }

  function onGoToPrevious() {
    setAfter(null)
    setBefore(pageInfo.startCursor)
    setFirst(null)
    setLast(rowsPerPage)
  }

  function onGoToNext() {
    setAfter(pageInfo.endCursor)
    setBefore(null)
    setFirst(rowsPerPage)
    setLast(null)
  }

  function handleChangeRowsPerPage(event: any) {
    const newPageSize = +event.target.value
    setRowsPerPage(newPageSize)
    setPage(0)
    setAfter(null)
    setBefore(null)
    setFirst(newPageSize)
    setLast(null)
  }

  function sort(propertyName: string) {
    const newSortBy = propertyName
    const newSortDir = propertyName !== sortBy ? "ASC" : sortDir === "ASC" ? "DESC" : "ASC"
    setSortBy(newSortBy)
    setSortDir(newSortDir)
    setPage(0)
    setAfter(null)
    setBefore(null)
    setFirst(rowsPerPage)
    setLast(null)
  }

  const handleSearchChange = (val: string) => {
    setFilter(val)
    setPage(0)
    setAfter(null)
    setBefore(null)
    setFirst(rowsPerPage)
    setLast(null)
  }

  function handleRowClick(id: string) {
    if (hasPermissions?.([DefaultPermission.UpdateUser])) {
      setRedirectTo({
        to: `/app/settings/users/edit/${id}`,
        replace: false,
      })
    }
  }

  const isFranchisorUser = user?.organization?.level && user?.organization?.level <= 1

  const pageInfo = data?.allUsers?.pageInfo || {}
  const users = data?.allUsers?.edges

  let NUM_COLUMNS = 6
  if (isFranchisorUser) {
    NUM_COLUMNS += 1
  }
  if (hasPermissions?.([DefaultPermission.UpdateUser])) {
    NUM_COLUMNS += 1
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t("users")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                marginRight: "80px", // make sure the intercom chat bubble doesn't block the pagination controls
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="users"
          />
          <Box
            sx={(theme) => ({
              marginBottom: "1.25rem",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              [theme.breakpoints.up("sm")]: {
                marginTop: 0,
                flexDirection: "row",
                justifyContent: "space-between",
              },
            })}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <Box
                sx={(theme) => ({
                  width: "100%",
                  [theme.breakpoints.up("sm")]: {
                    width: "400px",
                  },
                })}
              >
                <SearchField
                  onChange={handleSearchChange}
                  placeholder={t("searchUsers")}
                  term={filter}
                  testID="SearchField"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(includeArchivedUsersToggle)}
                      onChange={(event, checked) => {
                        setIncludeArchivedUsersToggle(checked)
                      }}
                      value="includeArchived"
                    />
                  }
                  label={t("includeArchivedUsers") as string}
                  sx={{
                    marginRight: "1.5rem",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                marginTop: "0.625rem",
                alignContent: "center",
                display: "flex",
                width: "100%",
                [theme.breakpoints.up("sm")]: {
                  width: "auto",
                  marginTop: 0,
                },
              })}
            >
              {hasPermissions?.([DefaultPermission.CreateUser]) ? (
                <Button
                  aria-label={t("createUser") as string}
                  color="primary"
                  data-testid="createUserButton"
                  onClick={() => {
                    setRedirectTo({
                      to: "/app/settings/users/create",
                      replace: false,
                    })
                  }}
                  sx={{
                    fontWeight: "bold",
                    flex: 1,
                    "& svg": {
                      fontSize: "1.0rem",
                    },
                    "& div": {
                      marginLeft: "0.625rem",
                      marginRight: "0.625rem",
                    },
                  }}
                  variant="contained"
                >
                  <AddIcon />
                  <Box>{t("createUser")}</Box>
                </Button>
              ) : null}
            </Box>
          </Box>
          {!error && !loading && users?.length == 0 && (
            <Box>
              {isBlank(filter) && hasPermissions?.([DefaultPermission.CreateUser]) ? (
                <EmptyState title={t("page.userList.emptyState.title")}>
                  <Box>{t("page.userList.emptyState.message")}</Box>
                </EmptyState>
              ) : (
                <EmptyState title={t("page.userList.noMatchingResults.title")}>
                  <Box>{t("page.userList.noMatchingResults.message")}</Box>
                </EmptyState>
              )}
            </Box>
          )}
          {!error && (loading || users?.length > 0) ? (
            <Paper
              sx={{
                overflowX: "auto",
              }}
            >
              <TableContainer
                sx={{
                  maxHeight: "calc(75vh)",
                  height: "calc(75vh - 3.125rem)",
                }}
              >
                <Table
                  sx={[
                    {
                      width: "100%",
                    },
                    users?.length == 0 && { height: "100%" },
                  ]}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={[classes.tableHead, { width: "48px" }]} />
                      <SortableTableHeader
                        isActiveSort={sortBy === "lastName"}
                        label={t("name")}
                        onClick={() => sort("lastName")}
                        sortDir={sortDir}
                        sx={{
                          ...classes.tableHead,
                          "&.MuiTableCell-root": {
                            paddingLeft: "0",
                          },
                        }}
                        width="20%"
                      />
                      <SortableTableHeader
                        isActiveSort={sortBy === "status"}
                        label={t("status")}
                        onClick={() => sort("status")}
                        sortDir={sortDir}
                        sx={classes.tableHead}
                      />
                      <SortableTableHeader
                        isActiveSort={sortBy === "email"}
                        label={t("emailAddress")}
                        onClick={() => sort("email")}
                        sortDir={sortDir}
                        sx={classes.tableHead}
                      />
                      {isFranchisorUser ? (
                        <SortableTableHeader
                          isActiveSort={sortBy === "organization.tradeName"}
                          label={t("organization")}
                          onClick={() => sort("organization.tradeName")}
                          sortDir={sortDir}
                          sx={classes.tableHead}
                        />
                      ) : null}
                      <SortableTableHeader
                        isActiveSort={sortBy === "jobTitle"}
                        label={t("jobTitle")}
                        onClick={() => sort("jobTitle")}
                        sortDir={sortDir}
                        sx={classes.tableHead}
                      />
                      {hasPermissions?.([DefaultPermission.UpdateUser]) ? (
                        <TableCell sx={classes.tableHead} width="10%">
                          {" "}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody style={users?.length === 0 ? { height: "100%" } : {}}>
                    {!error &&
                      !loading &&
                      users?.map((edge) => {
                        const { node } = edge
                        return (
                          <TableRow
                            key={node.id}
                            onClick={() => handleRowClick(node.id)}
                            sx={[
                              classes.dataRow,
                              hasPermissions?.([DefaultPermission.UpdateUser])
                                ? {
                                    "&:hover": {
                                      cursor: "pointer",
                                      backgroundColor: "#f8f8f8",
                                    },
                                  }
                                : null,
                            ]}
                          >
                            <TableCell sx={{ padding: "0" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <UserAvatar size="2rem" user={node} />
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                wordWrap: "normal",
                                "&.MuiTableCell-root": {
                                  paddingLeft: "0",
                                },
                              }}
                            >
                              {formatPersonName(node)}
                            </TableCell>
                            <TableCell sx={{ wordWrap: "normal" }}>
                              <UserStatusPill status={node.status} />
                            </TableCell>
                            <TableCell sx={{ wordWrap: "normal" }}>{node.email}</TableCell>
                            {isFranchisorUser ? (
                              <TableCell>{node.organization.tradeName}</TableCell>
                            ) : null}
                            <TableCell sx={{ wordWrap: "normal" }}>{node.jobTitle}</TableCell>
                            {hasPermissions?.([DefaultPermission.UpdateUser]) ? (
                              <TableCell align="right" sx={classes.actionCell}>
                                <IconButton aria-label={t("editUser")}>
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                            ) : null}
                          </TableRow>
                        )
                      })}
                    {loading
                      ? [...Array(rowsPerPage).keys()].map((i) => (
                          <TableRow key={i} sx={classes.dataRow}>
                            {[...Array(NUM_COLUMNS).keys()].map((j) => (
                              <TableCell key={j}>
                                <Skeleton variant="text" />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : null}
                    {error ? (
                      <TableRow>
                        <TableCell align="center" colSpan={NUM_COLUMNS}>
                          <Box
                            style={{
                              color: "rgb(244, 67, 54)",
                              fontWeight: 500,
                              fontSize: "1.25rem",
                              lineHeight: 1.6,
                            }}
                          >
                            {t("errorLabel")}
                            {": "}
                            {error.message}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                ActionsComponent={() => (
                  <TablePaginationActions
                    hasNextPage={pageInfo.hasNextPage ?? false}
                    hasPreviousPage={pageInfo.hasPreviousPage ?? false}
                    onGoToFirstPage={onGoToFirstPage}
                    onGoToLastPage={onGoToLastPage}
                    onGoToNext={onGoToNext}
                    onGoToPrevious={onGoToPrevious}
                  />
                )}
                component="div"
                count={-1}
                labelDisplayedRows={() => ``}
                labelRowsPerPage={t("rowsPerPage") as string}
                onPageChange={() => console.info("onPageChange")}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
              />
            </Paper>
          ) : null}
        </Box>
      </MainLayout>
    </>
  )
}

export default UserList
