import React from "react"

function HowToConnectQuickbooksOrXero() {
  return (
    <div>
      <h3>How do I connect my Quickbooks Online or Xero account with Fielder?</h3>
      <p>
        You can securely integrate Fielder with your QuickBooks Online or Xero account by navigating
        to Settings and then Plugins. From there you can click the appropriate plugin to set it up.
        Fielder will immediately contact the other app to initiate an authorization workflow. The
        other app will briefly take over for a bit, and may ask for you credentials. It will ask for
        your permission to grant Fielder limited access to your account. Once you grant permission,
        the application will return control back to Fielder. From there, you should see a
        configuration screen, where you can specify how you want Fielder to interact with the other
        app.
      </p>
      <p>
        Currently, you can have Fielder only send inventory updates or you can have Fielder send
        both Inventory updates and invoices. If you want Fielder to send invoices, then we have to
        also send inventory data because invoices contain references to inventory items. Quickbooks
        and Xero need to know about those items in order to make sense of the invoice.
      </p>
      <p>
        Note that Fielder will be the system of record for your inventory/item data. That means
        Fielder will not read inventory data from QuickBooks or Xero - it will only send new items
        and item updates to QuickBooks or Xero. Fielder also will not pull Invoices from QuickBooks
        or Xero. We will only send invoices to those systems. You can then tweak the invoices in the
        other system as much as you need to before sending it to your customer from that system. If
        you use QuickBooks or Xero for invoicing, then that system will be the system-of-record for
        your Tax Rate data. Fielder will pull all your tax rates from the other system so they’re
        available to use when creating invoices initially in Fielder. Fielder will not allow you to
        create or edit Tax Rate Groups in this case.
      </p>
      <p>
        To put it another way - Fielder does not support two-way synchronization with other systems.
        It rarely works well - there are almost always conflicts that cannot be resolved easily or
        automatically. Data ends up in a confusing, inconsistent state leaving you wondering which
        system is reporting the correct information. That’s no good for anybody. We insist that one
        system be the system of record for a particular type of data. Changes to that data must only
        be done in the system of record. The other system will then be updated accordingly.
      </p>
    </div>
  )
}

export default HowToConnectQuickbooksOrXero
