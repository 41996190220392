/* eslint-disable react/iframe-missing-sandbox */
/* eslint-disable react/jsx-no-literals */
import React, { ReactNode, useState } from "react"
import { nanoid } from "nanoid"
import { useTranslation } from "react-i18next"
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import { MainLayout, PageHeader, Seo } from "../../components"
import { HELP } from "../../util"
import WhatIsFielder from "./articles/en/WhatIsFielder"
import WhatDoesFielderNotDo from "./articles/en/WhatDoesFielderNotDo"
import HowDoesFielderWork from "./articles/en/HowDoesFielderWork"
import HowDoIGetStarted from "./articles/en/HowDoIGetStarted"
import HowToConnectQuickbooksOrXero from "./articles/en/HowToConnectQuickbooksOrXero"
import HowToPerformSearchesInDashboard from "./articles/en/HowToPerformSearchesInDashboard"
import WhoCanIContactForHelp from "./articles/en/WhoCanIContactForHelp"

interface Article {
  id: string
  title: string
  lang: string
  contentComponent: ReactNode
}

const articles: Article[] = [
  {
    id: nanoid(),
    title: "What is Fielder?",
    lang: "en",
    contentComponent: <WhatIsFielder />,
  },
  {
    id: nanoid(),
    title: "What does Fielder NOT do?",
    lang: "en",
    contentComponent: <WhatDoesFielderNotDo />,
  },
  {
    id: nanoid(),
    title: "How does Fielder work?",
    lang: "en",
    contentComponent: <HowDoesFielderWork />,
  },
  {
    id: nanoid(),
    title: "How do I get started?",
    lang: "en",
    contentComponent: <HowDoIGetStarted />,
  },
  {
    id: nanoid(),
    title: "How do I connect my Quickbooks Online or Xero account with Fielder?",
    lang: "en",
    contentComponent: <HowToConnectQuickbooksOrXero />,
  },
  {
    id: nanoid(),
    title: "How can I do a global search?",
    lang: "en",
    contentComponent: <HowToPerformSearchesInDashboard />,
  },
  {
    id: nanoid(),
    title: "Who can I contact for help?",
    lang: "en",
    contentComponent: <WhoCanIContactForHelp />,
  },
]

function ArticleListItem({
  isActive,
  title,
  onClick,
}: {
  isActive: boolean
  title: string
  onClick: () => void
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0.5rem 1rem",
        cursor: "pointer",
        backgroundColor: (theme) => (isActive ? theme.fielderColors.brandPrimary : "white"),
        "&:hover": {
          backgroundColor: (theme) => theme.fielderColors.brandPrimaryFaded,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          alignItems: "flex-start",
        }}
      >
        <ArticleOutlinedIcon />
        <Box
          sx={{
            fontWeight: isActive ? "700" : "500",
          }}
        >
          {title}
        </Box>
      </Box>
    </Box>
  )
}

function HelpArticles() {
  const { t } = useTranslation()
  const [currentArticle, setCurrentArticle] = useState<Article>(articles[0])

  return (
    <>
      <Seo title={t("sectionTitle.helpCenter")} />
      <MainLayout activeSection={HELP}>
        <Box
          sx={{
            margin: "0 1.25rem",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: HELP.path, titleKey: HELP.titleKey }]}
            icon={HELP.icon}
            leafTitleKey="articles"
          />
          <Paper
            sx={{
              display: "flex",
              flexGrow: 1,
              backgroundColor: "white",
              borderRadius: "4px",
              minHeight: "80vh",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, maxWidth: 300 }}>
              <Box>
                <Box
                  sx={{
                    color: (theme) => theme.fielderColors.text,
                    fontSize: "1.25rem",
                    fontWeight: "700",
                    padding: "1rem",
                    lineHeight: "24px",
                  }}
                >
                  {t("frequentlyAskedQuestions")}
                </Box>
                {articles.map((a) => {
                  return (
                    <ArticleListItem
                      isActive={currentArticle.id === a.id}
                      key={a.id}
                      onClick={() => setCurrentArticle(a)}
                      title={a.title}
                    />
                  )
                })}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 2,
                padding: "0.5rem",
                borderLeft: "1px solid #ededed",
              }}
            >
              <Box
                sx={{
                  fontSize: "1rem",
                  marginBottom: "0.5rem",
                  marginLeft: "0.5rem",
                  maxWidth: "800px",
                  margin: "0 auto",
                }}
              >
                {currentArticle.contentComponent}
              </Box>
            </Box>
          </Paper>
        </Box>
      </MainLayout>
    </>
  )
}

export default HelpArticles
