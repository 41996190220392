/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react"
import { Navigate, useLocation, NavigateProps, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery, gql } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/EditOutlined"
import Skeleton from "@mui/material/Skeleton"
import EmptyState from "../../components/EmptyState"
import MainLayout from "../../components/MainLayout"
import PageHeader from "../../components/PageHeader"
import SearchField from "../../components/SearchField"
import Seo from "../../components/Seo"
import SnackbarMessage from "../../components/SnackbarMessage"
import SortableTableHeader from "../../components/SortableTableHeader"
import TablePaginationActions from "../../components/TablePaginationActions"
import TaskDialog from "../../components/TaskDialog"
import { ALL_TASKS } from "../../queries/allTasks"
import { formatDate, formatPersonName, isBlank, TASKS, useDebounce } from "../../util"
import { useAuth } from "../../context/AuthContext"
import { Snack, Job, Task, TaskStatus, User, DefaultPermission, SortDirection } from "../../types"
import useStore, {
  taskListSettingsSelector,
  setTaskListSettingsSelector,
  onlyShowMyTasksToggleSelector,
  setOnlyShowMyTasksToggleSelector,
  hideCompletedTasksToggleSelector,
  setHideCompletedTasksToggleSelector,
} from "../../store"

const GET_TASK_BY_ID = gql`
  query GetTaskById($id: ID!) {
    getTaskById(id: $id) {
      id
      description
      dueDate
      assignee {
        id
        firstName
        lastName
        email
      }
      status
      job {
        id
        number
        description
        customer {
          id
          name
        }
        address {
          addressString
        }
      }
      notes
      createdAt
      createdBy {
        id
        email
      }
      updatedAt
      updatedBy {
        id
        email
      }
    }
  }
`

const DEFAULT_PAGE_SIZE = 25

function TaskList() {
  const { t } = useTranslation()
  const { id } = useParams()
  const location = useLocation()
  const { user, hasPermissions } = useAuth()
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const taskListSettings = useStore(taskListSettingsSelector)
  const setTaskListSettings = useStore(setTaskListSettingsSelector)
  const onlyShowMyTasks = useStore(onlyShowMyTasksToggleSelector)
  const setOnlyShowMyTasks = useStore(setOnlyShowMyTasksToggleSelector)
  const hideCompletedTasks = useStore(hideCompletedTasksToggleSelector)
  const setHideCompletedTasks = useStore(setHideCompletedTasksToggleSelector)
  const [statuses, setStatuses] = useState<TaskStatus[]>([
    TaskStatus.NOT_STARTED,
    TaskStatus.IN_PROGRESS,
  ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [filter, setFilter] = useState<string>(taskListSettings.searchTerm)
  const [after, setAfter] = useState<number | null>()
  const [before, setBefore] = useState<number | null>()
  const [first, setFirst] = useState<number | null>(taskListSettings.rowsPerPage)
  const [last, setLast] = useState<number | null>()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [taskDialogOpen, setTaskDialogOpen] = useState<boolean>(false)
  const [taskToEdit, setTaskToEdit] = useState<Partial<Task>>()
  const [getTaskWasCalled, setGetTaskWasCalled] = useState<boolean>(false)
  const debouncedSearchTerm = useDebounce(filter, 500)

  useEffect(() => {
    if (hideCompletedTasks) {
      setStatuses([TaskStatus.NOT_STARTED, TaskStatus.IN_PROGRESS])
    } else {
      setStatuses([TaskStatus.NOT_STARTED, TaskStatus.IN_PROGRESS, TaskStatus.COMPLETED])
    }
  }, [hideCompletedTasks])

  useQuery(GET_TASK_BY_ID, {
    variables: {
      id,
    },
    skip: !id || getTaskWasCalled,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getTaskById }) => {
      if (getTaskById) {
        setTaskToEdit(getTaskById)
        setTaskDialogOpen(true)
        setGetTaskWasCalled(true)
      }
    },
  })

  const { loading, error, data } = useQuery(ALL_TASKS, {
    variables: {
      filter: debouncedSearchTerm,
      after,
      before,
      first,
      last,
      sortBy: taskListSettings.sortBy,
      sortDir: taskListSettings.sortDir,
      statuses,
      assigneeUserId: onlyShowMyTasks ? user?.id : undefined,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: "ALL_TASKS",
      debounceTimeout: 50,
    },
  })

  function onGoToFirstPage() {
    setAfter(null)
    setBefore(null)
    setFirst(taskListSettings.rowsPerPage)
    setLast(null)
  }

  function onGoToLastPage() {
    setAfter(null)
    setBefore(null)
    setFirst(null)
    setLast(taskListSettings.rowsPerPage)
  }

  function onGoToPrevious() {
    setAfter(null)
    setBefore(pageInfo.startCursor)
    setFirst(null)
    setLast(taskListSettings.rowsPerPage)
  }

  function onGoToNext() {
    setAfter(pageInfo.endCursor)
    setBefore(null)
    setFirst(taskListSettings.rowsPerPage)
    setLast(null)
  }

  function handleChangeRowsPerPage(event: any) {
    const newPageSize = +event.target.value

    setTaskListSettings({
      ...taskListSettings,
      rowsPerPage: newPageSize,
      currentPage: 0,
    })

    setAfter(null)
    setBefore(null)
    setFirst(newPageSize)
    setLast(null)
  }

  function sort(propertyName: string) {
    const newSortBy = propertyName
    const newSortDir =
      propertyName !== taskListSettings.sortBy
        ? SortDirection.ASC
        : taskListSettings.sortDir === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC

    setTaskListSettings({
      ...taskListSettings,
      sortBy: newSortBy,
      sortDir: newSortDir,
      currentPage: 0,
    })

    setAfter(null)
    setBefore(null)
    setFirst(taskListSettings.rowsPerPage)
    setLast(null)
  }

  function openJob(job: Job) {
    setRedirectTo({
      to: `/app/jobs/edit/${job.id}`,
      replace: false,
    })
  }

  function editTask(task: Task) {
    if (hasPermissions?.([DefaultPermission.UpdateTask])) {
      setTaskToEdit(task)
      setTaskDialogOpen(true)
    }
  }

  function createNewTask(): Partial<Task> {
    return {
      assignee: user as User,
      status: TaskStatus.NOT_STARTED,
      description: "",
    }
  }

  function formatJobInfo(job: Job) {
    const jobNumber = `${t("job")} #${job.number}`
    return (
      <Box
        onClick={(e) => {
          e.preventDefault()
          openJob(job)
        }}
      >
        <Box>{jobNumber}</Box>
        {job.customer?.name ? <Box>{job.customer.name}</Box> : null}
      </Box>
    )
  }

  const pageInfo = data?.allTasks?.pageInfo || {}
  const tasks = data?.allTasks?.edges

  const NUM_COLUMNS = hasPermissions?.([DefaultPermission.UpdateTask]) ? 6 : 5

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Seo title={t(TASKS.titleKey)} />
      {taskDialogOpen ? (
        <TaskDialog
          onClose={() => {
            setTaskDialogOpen(false)
          }}
          open={taskDialogOpen}
          task={taskToEdit ? taskToEdit : createNewTask()}
          user={user}
        />
      ) : null}
      <MainLayout activeSection={TASKS}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                marginRight: "80px", // make sure the intercom chat bubble doesn't block the pagination controls
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader icon={TASKS.icon} leafTitleKey={TASKS.titleKey} />
          <Box
            sx={(theme) => ({
              marginBottom: "1.25rem",
              display: "flex",
              justifyContent: "space-between",
              [theme.breakpoints.down("md")]: {
                flexWrap: "wrap",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                width: "25rem",
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
            >
              <SearchField
                onChange={(val) => {
                  setFilter(val)
                  setTaskListSettings({
                    ...taskListSettings,
                    searchTerm: val,
                  })
                }}
                placeholder={t("searchTasks")}
                term={filter}
                testID="SearchField"
              />
            </Box>
            <Box
              sx={{
                alignItems: "center",
                alignContent: "center",
                marginLeft: "1rem",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onlyShowMyTasks}
                    onChange={(event, checked) => {
                      setOnlyShowMyTasks(checked)
                    }}
                    value="onlyShowMyTasks"
                  />
                }
                label={t("onlyShowMyTasks") as string}
                sx={{
                  marginRight: "1.5rem",
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hideCompletedTasks}
                    onChange={(event, checked) => {
                      setHideCompletedTasks(checked)
                    }}
                    value="hideCompletedTasks"
                  />
                }
                label={t("hideCompletedTasks") as string}
              />
            </Box>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down("xs")]: {
                  marginTop: "0.625rem",
                  alignContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                },
                [theme.breakpoints.down("md")]: {
                  marginTop: "0.625rem",
                },
              })}
            >
              {hasPermissions?.([DefaultPermission.CreateTask]) ? (
                <Button
                  color="primary"
                  onClick={() => {
                    setTaskToEdit(createNewTask())
                    setTaskDialogOpen(true)
                  }}
                  sx={{
                    fontWeight: "bold",
                    "& svg": {
                      fontSize: "1.0rem",
                    },
                    "& div": {
                      marginLeft: "0.625rem",
                      marginRight: "0.625rem",
                    },
                  }}
                  variant="contained"
                >
                  <AddIcon />
                  <Box>{t("createTask")}</Box>
                </Button>
              ) : null}
            </Box>
          </Box>
          {!error && !loading && tasks?.length == 0 && (
            <Box style={{ paddingTop: "1.875rem", paddingBottom: "1.875rem" }}>
              {isBlank(filter) && (
                <EmptyState title={t("page.taskList.emptyState.title")}>
                  {hasPermissions?.([DefaultPermission.CreateTask]) ? (
                    <Box>{t("page.taskList.emptyState.message")}</Box>
                  ) : null}
                </EmptyState>
              )}
              {!isBlank(filter) && (
                <EmptyState title={t("page.taskList.noMatchingResults.title")}>
                  <Box>{t("page.taskList.noMatchingResults.message")}</Box>
                </EmptyState>
              )}
            </Box>
          )}
          {!error && (loading || tasks?.length > 0) ? (
            <Paper
              sx={{
                overflowX: "auto",
              }}
            >
              <TableContainer
                sx={{
                  maxHeight: "calc(75vh)",
                  height: "calc(75vh - 3.125rem)",
                }}
              >
                <Table
                  sx={[
                    {
                      width: "100%",
                      tableLayout: "fixed",
                    },
                    tasks?.length === 0 && { height: "100%" },
                  ]}
                >
                  <TableHead>
                    <TableRow>
                      <SortableTableHeader
                        isActiveSort={taskListSettings.sortBy === "dueDate"}
                        label={t("dueDate")}
                        onClick={() => sort("dueDate")}
                        sortDir={taskListSettings.sortDir}
                        sx={classes.tableHead}
                        width="10%"
                      />
                      <SortableTableHeader
                        isActiveSort={taskListSettings.sortBy === "description"}
                        label={t("taskDescription")}
                        onClick={() => sort("description")}
                        sortDir={taskListSettings.sortDir}
                        sx={classes.tableHead}
                        width="25%"
                      />
                      <SortableTableHeader
                        isActiveSort={taskListSettings.sortBy === "assignee.lastName"}
                        label={t("assignedTo")}
                        onClick={() => sort("assignee.lastName")}
                        sortDir={taskListSettings.sortDir}
                        sx={classes.tableHead}
                        width="15%"
                      />
                      <TableCell sx={classes.tableHead} width="20%">
                        {t("job")}
                      </TableCell>
                      <SortableTableHeader
                        isActiveSort={taskListSettings.sortBy === "status"}
                        label={t("status")}
                        onClick={() => sort("status")}
                        sortDir={taskListSettings.sortDir}
                        sx={classes.tableHead}
                        width="10%"
                      />
                      <TableCell sx={classes.tableHead} width="20%">
                        {" "}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={tasks?.length == 0 ? { height: "100%" } : {}}>
                    {!error &&
                      !loading &&
                      tasks
                        ?.map((edge) => edge.node)
                        ?.filter((node) =>
                          hideCompletedTasks ? node.status !== "COMPLETED" : true
                        )
                        ?.filter((node) => (onlyShowMyTasks ? node.assignee.id === user?.id : true))
                        ?.map((node) => {
                          return (
                            <TableRow
                              key={node.id}
                              onClick={() => editTask(node)}
                              sx={classes.dataRow}
                            >
                              <TableCell>
                                {formatDate(
                                  node.dueDate,
                                  t("format:dateFormat.short"),
                                  user?.organization?.timeZone,
                                  user
                                )}
                              </TableCell>
                              <TableCell>
                                <Box
                                  sx={{
                                    boxSizing: "border-box",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    maxWidth: "300px",
                                  }}
                                >
                                  {node.description}
                                </Box>
                              </TableCell>
                              <TableCell>{formatPersonName(node.assignee)}</TableCell>
                              <TableCell>{node.job ? formatJobInfo(node.job) : ""}</TableCell>
                              <TableCell>{t(`taskStatus.${node.status}`)}</TableCell>
                              <TableCell align="right">
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  {node.job ? (
                                    <Button
                                      onClick={(e) => {
                                        e.preventDefault()
                                        openJob(node.job)
                                      }}
                                      sx={{
                                        marginRight: "0.625rem",
                                      }}
                                      variant="outlined"
                                    >
                                      {t("openJob")}
                                    </Button>
                                  ) : null}
                                  <IconButton aria-label={t("editTask")}>
                                    <EditIcon />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    {loading
                      ? [...Array(taskListSettings.rowsPerPage).keys()].map((i) => (
                          <TableRow key={i} sx={classes.dataRow}>
                            {[...Array(NUM_COLUMNS).keys()].map((j) => (
                              <TableCell key={j}>
                                <Skeleton variant="text" />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : null}
                    {error ? (
                      <TableRow>
                        <TableCell align="center" colSpan={NUM_COLUMNS}>
                          <Box
                            sx={{
                              color: "rgb(244, 67, 54)",
                              fontWeight: 500,
                              fontSize: "1.25rem",
                              lineHeight: 1.6,
                            }}
                          >
                            {t("errorLabel")}
                            {": "}
                            {error.message}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                ActionsComponent={() => (
                  <TablePaginationActions
                    hasNextPage={pageInfo.hasNextPage ?? false}
                    hasPreviousPage={pageInfo.hasPreviousPage ?? false}
                    onGoToFirstPage={onGoToFirstPage}
                    onGoToLastPage={onGoToLastPage}
                    onGoToNext={onGoToNext}
                    onGoToPrevious={onGoToPrevious}
                  />
                )}
                component="div"
                count={-1}
                labelDisplayedRows={() => ``}
                labelRowsPerPage={t("rowsPerPage") as string}
                onPageChange={() => {}}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={taskListSettings.currentPage}
                rowsPerPage={taskListSettings.rowsPerPage}
                rowsPerPageOptions={[
                  DEFAULT_PAGE_SIZE,
                  DEFAULT_PAGE_SIZE * 2,
                  DEFAULT_PAGE_SIZE * 4,
                  DEFAULT_PAGE_SIZE * 8,
                ]}
              />
            </Paper>
          ) : null}
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  tableHead: {
    backgroundColor: "#F9F9F9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
    cursor: "pointer",
  },
  dataRow: {
    height: "3.75rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#F8F8F8",
    },
  },
} as const

export default TaskList
