import gql from "graphql-tag"

export const GET_JOB_LOG = gql`
  query GetJobLog(
    $id: ID!
    $filter: String
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    getJobById(id: $id) {
      id
      auditLog(
        params: {
          filter: $filter
          sortBy: $sortBy
          sortDir: $sortDir
          first: $first
          last: $last
          after: $after
          before: $before
        }
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            eventType
            eventData {
              ... on JobEvent {
                description
                number
                eventType
                addressString
                status
              }
              ... on JobNoteEvent {
                eventType
                note
              }
              ... on JobTaskEvent {
                eventType
                description
                notes
                status
                dueDate
                assigneeName
              }
              ... on JobAssignmentEvent {
                eventType
                assignees {
                  id
                  firstName
                  lastName
                  email
                }
                status
                startDate
                endDate
                isLocked
              }
              ... on ChecklistEvent {
                eventType
                totalLineItems
              }
              ... on ChecklistLineItemNoteEvent {
                eventType
                note
              }
              ... on EstimateEvent {
                eventType
                total
                status
                number
              }
              ... on EstimateSentEvent {
                eventType
                number
                recipientAddress
                channel
                total
                message
                status
                jobNumber
              }
              ... on JobAttachmentEvent {
                eventType
                attachment {
                  id
                  name
                  signedUrl
                  contentType
                  type
                  objectName
                  previews(sizes: [SMALL]) {
                    id
                    signedUrl
                    previewSize
                  }
                }
              }
              ... on InvoiceEvent {
                eventType
                number
                status
                total
              }
              ... on InvoiceSentEvent {
                eventType
                channel
                number
                message
                recipientAddress
                status
                total
              }
              ... on JobWorkflowStepEventMessageEvent {
                eventType
                subject
                message
                recipientNames
                recipientEmails
              }
              ... on WorkOrderEvent {
                eventType
                number
                status
              }
              ... on EmailEvent {
                emailId
                eventType
                messageId
                senderEmail
                recipientEmails
                subject
                body
                attachmentIds
                inReplyToMessageId
                timestamp
              }
            }
            createdBy {
              id
              firstName
              lastName
            }
            createdAt
            tags {
              id
              type
            }
          }
        }
      }
    }
  }
`
