import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/EditOutlined"
import Skeleton from "@mui/material/Skeleton"
import { ALL_TASKS } from "../../../queries/allTasks"
import { NOT_SPECIFIED, createDayJS, formatPersonName } from "../../../util"
import { useAuth } from "../../../context/AuthContext"
import NoResultsRow from "../../../components/NoResultsRow"
import SnackbarMessage from "../../../components/SnackbarMessage"
import SortableTableHeader from "../../../components/SortableTableHeader"
import TaskDialog from "../../../components/TaskDialog"
import FielderIconButton from "../../../components/FielderIconButton"
import { DefaultPermission, Job, Task, TaskStatus } from "../../../types"

interface JobTasksTabProps {
  readonly job: Job
}

function JobTasksTab({ job }: JobTasksTabProps) {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<string>("")
  const [after, setAfter] = useState<number | null>()
  const [before, setBefore] = useState<number | null>()
  const [first, setFirst] = useState<number | null>(10)
  const [last, setLast] = useState<number | null>()
  const [sortBy, setSortBy] = useState<string>("dueDate")
  const [sortDir, setSortDir] = useState<"DESC" | "ASC">("ASC")
  const [taskDialogOpen, setTaskDialogOpen] = useState(false)
  const [taskToEdit, setTaskToEdit] = useState<Partial<Task>>()
  const { user, hasPermissions } = useAuth()

  const { loading, error, data, refetch } = useQuery(ALL_TASKS, {
    variables: {
      filter,
      sortBy,
      sortDir,
      first,
      last,
      after,
      before,
      jobId: job.id,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    refetch()
  }, [filter, refetch, sortBy, sortDir])

  const sort = (propertyName: string) => {
    setSortBy(propertyName)
    setSortDir(sortDir == "ASC" ? "DESC" : "ASC")
  }

  let snack = null
  if (location?.state) {
    snack = location.state.snack
  }

  const editTask = (task: Partial<Task>): void => {
    setTaskToEdit(task)
    setTaskDialogOpen(true)
  }

  const createNewTask = (): Partial<Task> => {
    return {
      id: undefined,
      dueDate: undefined,
      assignee: user,
      job,
      status: TaskStatus.NOT_STARTED,
      description: "",
    }
  }

  const tasks = data?.allTasks?.edges

  return (
    <>
      {snack ? <SnackbarMessage snack={snack} /> : null}
      {taskDialogOpen ? (
        <TaskDialog
          hideJob
          onClose={() => setTaskDialogOpen(false)}
          open={taskDialogOpen}
          task={taskToEdit ? taskToEdit : createNewTask()}
          user={user}
        />
      ) : null}
      <Box>
        <Paper
          sx={{
            overflowX: "auto",
            marginBottom: "1.25rem",
            maxHeight: 500,
            height: 500,
          }}
        >
          <Table data-testid="taskTable" sx={[tasks?.length == 0 && { height: "100%" }]}>
            <TableHead>
              <TableRow>
                <SortableTableHeader
                  isActiveSort={sortBy === "dueDate"}
                  label={t("dueDate")}
                  onClick={() => sort("dueDate")}
                  sortDir={sortDir}
                  sx={classes.tableHead}
                />
                <SortableTableHeader
                  isActiveSort={sortBy === "description"}
                  label={t("taskDescription")}
                  onClick={() => sort("description")}
                  sortDir={sortDir}
                  sx={classes.tableHead}
                />
                <SortableTableHeader
                  isActiveSort={sortBy === "assignee"}
                  label={t("assignedTo")}
                  onClick={() => sort("assignee")}
                  sortDir={sortDir}
                  sx={classes.tableHead}
                />
                <SortableTableHeader
                  isActiveSort={sortBy === "status"}
                  label={t("status")}
                  onClick={() => sort("status")}
                  sortDir={sortDir}
                  sx={classes.tableHead}
                />
                <TableCell sx={[classes.tableHead, { textAlign: "right" }]}>
                  {hasPermissions?.([DefaultPermission.CreateTask]) ? (
                    <Button
                      color="primary"
                      data-testid="createTaskButton"
                      onClick={() => {
                        setTaskToEdit(createNewTask())
                        setTaskDialogOpen(true)
                      }}
                      sx={{
                        fontWeight: "bold",
                        "& svg": {
                          fontSize: "1.0rem",
                        },
                        "& div": {
                          marginLeft: "0.625rem",
                          marginRight: "0.625rem",
                        },
                      }}
                      variant="contained"
                    >
                      <AddIcon />
                      <Box>{t("createTask")}</Box>
                    </Button>
                  ) : null}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={tasks && tasks.length == 0 ? { height: "100%" } : {}}>
              {loading
                ? [...Array(first).keys()].map((i) => (
                    <TableRow key={i}>
                      {[...Array(6).keys()].map((j) => (
                        <TableCell key={j}>
                          <Skeleton variant="text" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : null}
              {!error && !loading && tasks.length == 0 && <NoResultsRow colSpan={6} />}
              {!loading &&
                !error &&
                tasks
                  .map((edge) => edge.node)
                  .map((node) => {
                    const dueDate = createDayJS(node.dueDate, user?.organization?.timeZone, user)
                    return (
                      <TableRow
                        key={node.id}
                        onClick={() => editTask(node)}
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                            backgroundColor: "#f8f8f8",
                          },
                        }}
                      >
                        <TableCell>
                          {dueDate
                            ? dueDate.format(t("format:dateFormat.short") as string)
                            : NOT_SPECIFIED}
                        </TableCell>
                        <TableCell sx={classes.taskDescription}>{node.description}</TableCell>
                        <TableCell>{formatPersonName(node.assignee)}</TableCell>
                        <TableCell>{t(`taskStatus.${node.status}`)}</TableCell>
                        <TableCell>
                          <Box sx={classes.rowButtonContainer}>
                            <FielderIconButton
                              aria-label={t("edit") as string}
                              onClick={() => {
                                editTask(node)
                              }}
                              title={t("edit") as string}
                            >
                              <EditIcon />
                            </FielderIconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              {error ? (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Box
                      style={{
                        color: "rgb(244, 67, 54)",
                        fontWeight: 500,
                        fontSize: "1.25rem",
                        lineHeight: 1.6,
                      }}
                    >
                      {t("errorLabel")}
                      {": "}
                      {error.message}
                    </Box>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </>
  )
}

const classes = {
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
    cursor: "pointer",
  },
  rowButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  taskDescription: {
    boxSizing: "border-box",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "300px",
  },
} as const

export default JobTasksTab
