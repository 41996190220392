import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { Navigate, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import {
  MainLayout,
  PageHeader,
  SectionContent,
  SectionHeader,
  Seo,
  SnackbarMessage,
} from "~/components"
import UserForm from "./components/UserForm"
import { CREATE_USER } from "~/queries/createUser"
import { parseGraphQLErrorCode, SETTINGS } from "~/util"
import { CreateUserResponse, Snack, CreateUserInput, UserFormInput } from "~/types"

function CreateUser() {
  const { t } = useTranslation()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [snack, setSnack] = useState<Snack>()

  const [createUser, { loading }] = useMutation<CreateUserResponse, CreateUserInput>(CREATE_USER, {
    onCompleted: (data) => {
      const email = data.createUser.user.email
      setRedirectTo({
        to: "/app/settings/users",
        replace: false,
        state: {
          snack: {
            messageKey: "messages.createUser.success",
            messageOptions: { email },
            variant: "success",
          },
        },
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Seo title={t("users")} />
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
            paddingBottom: "6.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/users/list", titleKey: "users" },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey="createUser"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 800,
            }}
          >
            <Paper>
              <SectionHeader>
                <label>{t("basicInfo")}</label>
              </SectionHeader>
              <Divider />
              <SectionContent
                sx={(theme) => {
                  return {
                    display: "flex",
                    flexDirection: "column",
                    [theme.breakpoints.up("md")]: {
                      padding: "1.25rem 2rem",
                      flexDirection: "row",
                      gap: "2rem",
                    },
                  }
                }}
              >
                <UserForm
                  loading={loading}
                  onCancel={() => setRedirectTo({ to: "/app/settings/users", replace: false })}
                  onSubmit={(newUser: UserFormInput) => {
                    createUser({
                      variables: newUser as CreateUserInput,
                    })
                  }}
                />
              </SectionContent>
            </Paper>
          </Box>
        </Box>
      </MainLayout>
    </>
  )
}

export default CreateUser
