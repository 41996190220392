import gql from "graphql-tag"
import FullProductOrder from "./fragments/FullProductOrder"

export const GET_DRAFT_PRODUCT_ORDER = gql`
  query GetDraftProductOrder {
    getDraftProductOrder {
      ...FullProductOrder
    }
  }
  ${FullProductOrder}
`
