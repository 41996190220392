import React from "react"
import { useTranslation } from "react-i18next"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"
import MenuItem from "@mui/material/MenuItem"

import SelectorField, { SelectorOption } from "./SelectorField"
import { NetTerms } from "~/types"

interface Props {
  readonly disabled?: boolean
  readonly error?: boolean
  readonly fullWidth?: boolean
  readonly label?: string
  readonly name?: string
  readonly onBlur?: () => void
  readonly onChange: (value: NetTerms) => void
  readonly required?: boolean
  readonly size?: "small" | "medium"
  readonly sx?: SxProps<Theme>
  readonly value?: NetTerms | undefined | null
}

function NetTermsSelect({
  disabled,
  error: hasError,
  fullWidth,
  label,
  name,
  onBlur,
  onChange,
  required,
  size,
  sx,
  value,
}: Props) {
  const { t } = useTranslation()

  const options: SelectorOption[] = [
    { id: NetTerms.DUE_ON_RECEIPT, name: t("netTermsOptions.DUE_ON_RECEIPT") },
    { id: NetTerms.NET_15, name: t("netTermsOptions.NET_15") },
    { id: NetTerms.NET_30, name: t("netTermsOptions.NET_30") },
    { id: NetTerms.NET_60, name: t("netTermsOptions.NET_60") },
    { id: NetTerms.NET_90, name: t("netTermsOptions.NET_90") },
    { id: NetTerms.CUSTOM, name: t("netTermsOptions.CUSTOM") },
  ]

  return (
    <SelectorField
      disabled={disabled}
      error={hasError}
      fullWidth={fullWidth}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={(val) => {
        onChange(val?.id)
      }}
      options={options}
      renderOption={(selectorOption: SelectorOption) => {
        return (
          <MenuItem key={selectorOption.id} value={selectorOption.id}>
            <div>{selectorOption.name}</div>
          </MenuItem>
        )
      }}
      renderValue={(selected) => {
        const val = options.find((o: SelectorOption) => o.id === selected)
        if (!val) {
          return <div>{t("error.invalidOptionSelected")}</div>
        } else {
          return <div>{val.name}</div>
        }
      }}
      required={required}
      size={size}
      sx={{
        backgroundColor: "#fff",
        minWidth: "8rem",
      }}
      value={value ?? ""}
    />
  )
}

export default React.memo(NetTermsSelect)
