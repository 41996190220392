import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import { PRODUCT_ORDERS_HQ } from "~/util"
import { Snack } from "~/types"
import ProductOrderDetails from "~/components/ProductOrderDetails"
import ProductOrderHistory from "~/components/ProductOrderHistory"

function FranchisorEditProductOrder() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [snack, setSnack] = useState<Snack>()

  return (
    <>
      <Seo title={t(PRODUCT_ORDERS_HQ.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={PRODUCT_ORDERS_HQ}>
        <Box
          sx={{
            margin: "0 1.25rem",
            paddingBottom: "12.5rem",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: PRODUCT_ORDERS_HQ.path, titleKey: PRODUCT_ORDERS_HQ.titleKey }]}
            icon={PRODUCT_ORDERS_HQ.icon}
            leafTitleKey="viewOrder"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              width: "100%",
              maxWidth: "1200px",
            }}
          >
            {id ? (
              <>
                <ProductOrderDetails productOrderId={id} setSnack={setSnack} />
                <ProductOrderHistory productOrderId={id} />
              </>
            ) : null}
          </Box>
        </Box>
      </MainLayout>
    </>
  )
}

export default FranchisorEditProductOrder
