import React from "react"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"
import Zoom from "@mui/material/Zoom"
import { styled } from "@mui/material/styles"

interface Props extends IconButtonProps {
  readonly title?: string
}

const FielderIconButton: React.FC<Props> = ({ children, title, ...props }) => {
  return (
    <DarkTooltip
      TransitionComponent={Zoom}
      disableInteractive={false}
      enterDelay={500}
      leaveDelay={200}
      title={title}
    >
      <IconButton
        {...props}
        sx={{
          cursor: "pointer",
          "&.Mui-disabled": {
            cursor: "pointer",
            pointerEvents: "auto",
          },
        }}
      >
        {children}
      </IconButton>
    </DarkTooltip>
  )
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 14,
    backgroundColor: theme.palette.common.black,
  },
}))

export default FielderIconButton
