import gql from "graphql-tag"
import FullProductOrder from "./fragments/FullProductOrder"

export const ALL_PRODUCT_ORDERS = gql`
  query AllProductOrders(
    $filter: String
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderOrganizationId: ID
    $statuses: [ProductOrderStatus]
    $showArchived: Boolean
    $updatedSinceDate: LocalDateTime
  ) {
    allProductOrders(
      input: {
        filter: $filter
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        last: $last
        after: $after
        before: $before
      }
      filter: { 
        orderOrganizationId: $orderOrganizationId
        statuses: $statuses
        showArchived: $showArchived 
        updatedSinceDate: $updatedSinceDate
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...FullProductOrder
        }
      }
    }
  }
  ${FullProductOrder}
`
