import React from "react"
import Box from "@mui/material/Box"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import HeaderLogo from "./HeaderLogo"

export default function PublicAppBar() {
    return (
        <AppBar
        color="default"
        elevation={0}
        position="static"
        sx={(theme) => ({
          backgroundColor: theme.fielderScreens.franchiseePricing.headerBackground,
          color: theme.palette.secondary.contrastText,
          borderBottom: `1px solid '${theme.fielderColors.mutedText}'`,
          "@media print": {
            display: "none",
          },
        })}
      >
        <Toolbar
          sx={{
            flexWrap: "wrap",
            justifyContent: "center",
            display: "flex",
            flexDirection: 'row',
            alignContent: 'center',
          }}
        >
          <Box
            sx={{
              maxWidth: "320px",
              marginTop: "0.9rem",
              justifyContent: "center",
              display: "flex",
              flexDirection: 'row',
              alignContent: 'center',
              paddingBottom: "0.5rem",
            }}
          >
            <HeaderLogo width="100%" />
          </Box>
        </Toolbar>
      </AppBar>
    )
}