import React, { FC } from "react"
import Box from "@mui/material/Box"

interface TotalRowContentProps {
  style?: React.CSSProperties
}
const TotalRowContent: FC<TotalRowContentProps> = ({ children, style, ...props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: 300,
      }}
      {...props}
      style={style}
    >
      {children}
    </Box>
  )
}

export default TotalRowContent
