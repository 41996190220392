import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Theme } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import AppBar from "@mui/material/AppBar"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MenuIcon from "@mui/icons-material/Menu"
import ArrowDropDownOutlined from "@mui/icons-material/ArrowDropDownOutlined"
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined"
import Box from "@mui/material/Box"

import { formatPersonName } from "~/util"
import { useAuth } from "~/context/AuthContext"
import { DefaultPermission, User } from "~/types"
import SearchTypeahead from "./search/SearchTypeahead"
import NotificationsButton from "./NotificationsButton"
import ShoppingCartButton from "./ShoppingCartButton"
import UserAvatar from "./UserAvatar"

const getFormattedUserName = (user: Partial<User>) => {
  return user ? formatPersonName(user) : ""
}

interface Props {
  readonly handleDrawerToggle: () => void
  readonly onLogout: () => void
}

function Header({ handleDrawerToggle, onLogout }: Props) {
  const navigate = useNavigate()
  const { isImpersonating, user, exitImpersonateMode, hasPermissions } = useAuth()
  const { t } = useTranslation("common", { useSuspense: typeof window !== "undefined" })
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<HTMLElement | null>(null)
  const openProfileMenu = Boolean(profileMenuAnchorEl)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickProfileMenu = (event: any) => {
    setProfileMenuAnchorEl(event.currentTarget)
  }

  const handleCloseProfileMenu = () => {
    setProfileMenuAnchorEl(null)
  }

  return (
    <AppBar
      elevation={0}
      sx={(theme: Theme) => {
        const drawerWidth = theme.fielderComponents.drawer.width
        return {
          background: "#FFFFFF",
          width: {
            lg: `calc(100% - ${drawerWidth}px)`,
            xs: `100%`,
          },
          marginLeft: {
            md: drawerWidth,
            xs: 0,
          },
        }
      }}
    >
      {isImpersonating ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "2rem",
            width: "100%",
            backgroundColor: (theme) => theme.fielderColors.brandPrimary,
            color: (theme) => theme.fielderColors.black,
            fontSize: "0.875rem",
            fontWeight: 600,
          }}
        >
          <Box sx={{ marginLeft: (theme) => `-${theme.fielderComponents.drawer.width}px` }}>
            {t("impersonationModeBannerMessage", {
              franchiseeName: user?.organization?.tradeName ?? "unknown",
            })}
            <Box
              component="span"
              onClick={exitImpersonateMode}
              sx={{ textDecoration: "underline", cursor: "pointer", marginLeft: "2rem" }}
            >
              {t("exitImpersonationMode")}
            </Box>
          </Box>
        </Box>
      ) : null}
      <Toolbar variant="dense">
        <IconButton
          aria-label="open drawer"
          color="inherit"
          edge="start"
          onClick={handleDrawerToggle}
          sx={(theme: Theme) => ({
            color: theme.fielderComponents.header.menuIcon,
            marginRight: 2, // equivalent to theme.spacing(2),
            display: {
              lg: "none",
              xs: "flex",
            },
          })}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <label hidden htmlFor="uberSearchField" id="uberSearchFieldLabel">
              {t("component.uberSearchBar.placeholder")}
            </label>
            <SearchTypeahead />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              px: {
                xs: "0.5rem",
                sm: "1.5rem",
              },
            }}
          >
            <Box
              sx={{
                color: "#363636",
                marginLeft: "1.5rem",
                marginRight: "0.5rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: {
                  xs: "none",
                  sm: "flex",
                },
                gap: "0.75rem",
              }}
            >
              <a href="https://help.fielderapp.com" rel="noreferrer" target="_blank">
                <IconButton aria-label={t("sectionTitle.helpCenter") as string}>
                  <HelpOutlineOutlined />
                </IconButton>
              </a>
              <NotificationsButton />
              {user?.organization?.level &&
              user.organization.level > 1 &&
              hasPermissions?.([DefaultPermission.ReadProductOrder]) ? (
                <ShoppingCartButton />
              ) : null}
            </Box>
            <Box
              sx={{
                width: "1px",
                backgroundColor: "#707070",
                height: "65%",
                display: {
                  xs: "none",
                  sm: "block",
                },
              }}
            />
            <Box
              sx={{
                color: "#363636",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "0.5rem",
                fontSize: "1rem",
              }}
            >
              <Button
                aria-haspopup="true"
                aria-label={t("editProfile") as string}
                color="inherit"
                disableRipple
                onClick={handleClickProfileMenu}
                sx={{
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontSize: "0.875rem",
                  maxWidth: {
                    xs: 100,
                    sm: 200,
                  },
                }}
                variant="text"
              >
                {user ? getFormattedUserName(user) : ""}
                <ArrowDropDownOutlined />
              </Button>
              <UserAvatar size="24px" user={user} />
              <Menu
                anchorEl={profileMenuAnchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                id="menu-appbar"
                keepMounted
                onClose={handleCloseProfileMenu}
                open={openProfileMenu}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/app/settings/profile")
                  }}
                >
                  {t("myProfile")}
                </MenuItem>
                <MenuItem onClick={onLogout}>{t("logout")}</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
