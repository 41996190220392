/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLocation, NavigateProps } from "react-router-dom"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import EditIcon from "@mui/icons-material/EditOutlined"
import Skeleton from "@mui/material/Skeleton"
import CreateJobButton from "./components/CreateJobButton"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import EmptyState from "~/components/EmptyState"
import SortableTableHeader from "~/components/SortableTableHeader"
import FielderIconButton from "~/components/FielderIconButton"
import SearchField from "~/components/SearchField"
import TablePaginationActions from "~/components/TablePaginationActions"
import { ALL_JOBS } from "~/queries/allJobs"
import { ALL_JOB_WORKFLOWS } from "~/queries/allJobWorkflows"
import {
  formatPersonName,
  formatPhoneNumber,
  isBlank,
  JOBS,
  MAX_INTEGER,
  NOT_SPECIFIED,
  truncate,
  useDebounce,
} from "~/util"
import { useAuth } from "~/context/AuthContext"
import { Snack, DefaultPermission, SortDirection as SortDirectionType } from "~/types"
import useStore from "~/store"
import { AddressDisplay } from "~/components/AddressDisplay"
import JobStatusPill from "./components/JobStatusPill"

const NUM_COLUMNS = 8
const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100]

function JobList() {
  const { t } = useTranslation()
  const { hasPermissions } = useAuth()
  const jobsScreenSettings = useStore((state) => state.jobsScreenSettings)
  const setJobsScreenSettings = useStore((state) => state.setJobsScreenSettings)
  const location = useLocation()
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [filter, setFilter] = useState<string>(jobsScreenSettings.searchTerm)
  const [after, setAfter] = useState<number | null>()
  const [before, setBefore] = useState<number | null>()
  const [first, setFirst] = useState<number | null>(jobsScreenSettings.rowsPerPage)
  const [last, setLast] = useState<number | null>()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const debouncedSearchTerm = useDebounce(filter, 500)

  useEffect(() => {
    if (debouncedSearchTerm !== jobsScreenSettings.searchTerm) {
      setJobsScreenSettings({
        ...jobsScreenSettings,
        searchTerm: debouncedSearchTerm,
      })
      setAfter(null)
      setBefore(null)
      setFirst(jobsScreenSettings.rowsPerPage)
      setLast(null)
    }
    if (jobsScreenSettings.mode === "board") {
      setRedirectTo({ to: "/app/jobs/board", replace: false })
    }
  }, [debouncedSearchTerm, jobsScreenSettings, setJobsScreenSettings])

  const { data: allJobWorkflowsData } = useQuery(ALL_JOB_WORKFLOWS, {
    variables: {
      first: MAX_INTEGER,
      sortBy: "name",
    },
    fetchPolicy: "cache-and-network",
  })

  const { loading, error, data } = useQuery(ALL_JOBS, {
    variables: {
      filter: debouncedSearchTerm,
      after,
      before,
      first,
      last,
      sortBy: jobsScreenSettings.sortBy,
      sortDir: jobsScreenSettings.sortDir,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: "ALL_JOBS",
      debounceTimeout: 50,
    },
  })

  function onGoToFirstPage() {
    setAfter(null)
    setBefore(null)
    setFirst(jobsScreenSettings.rowsPerPage)
    setLast(null)
  }

  function onGoToLastPage() {
    setAfter(null)
    setBefore(null)
    setFirst(null)
    setLast(jobsScreenSettings.rowsPerPage)
  }

  function onGoToPrevious() {
    setAfter(null)
    setBefore(pageInfo.startCursor)
    setFirst(null)
    setLast(jobsScreenSettings.rowsPerPage)
  }

  function onGoToNext() {
    setAfter(pageInfo.endCursor)
    setBefore(null)
    setFirst(jobsScreenSettings.rowsPerPage)
    setLast(null)
  }

  function handleChangeRowsPerPage(event: any) {
    const newPageSize = +event.target.value
    setJobsScreenSettings({
      ...jobsScreenSettings,
      rowsPerPage: newPageSize,
      currentPage: 0,
    })
    setAfter(null)
    setBefore(null)
    setFirst(newPageSize)
    setLast(null)
  }

  function sort(propertyName: string) {
    const newSortBy = propertyName
    const newSortDir =
      propertyName !== jobsScreenSettings.sortBy
        ? SortDirectionType.ASC
        : jobsScreenSettings.sortDir === SortDirectionType.ASC
          ? SortDirectionType.DESC
          : SortDirectionType.ASC

    setJobsScreenSettings({
      ...jobsScreenSettings,
      sortBy: newSortBy,
      sortDir: newSortDir,
      currentPage: 0,
    })
    setAfter(null)
    setBefore(null)
    setFirst(jobsScreenSettings.rowsPerPage)
    setLast(null)
  }

  const handleSearchChange = (val: string) => {
    setFilter(val)
    setAfter(null)
    setBefore(null)
    setFirst(jobsScreenSettings.rowsPerPage)
    setLast(null)
  }

  function handleRowClick(id: string) {
    setRedirectTo({ to: `/app/jobs/edit/${id}`, replace: false })
  }

  const pageInfo = data?.allJobs?.pageInfo || {}
  const jobs = data?.allJobs?.edges
  const workflows = allJobWorkflowsData?.allJobWorkflows || []

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(JOBS.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={JOBS}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                marginRight: "80px", // make sure the intercom chat bubble doesn't block the pagination controls
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader icon={JOBS.icon} leafTitleKey={JOBS.titleKey} />
          <Box
            sx={{
              marginBottom: "1.25rem",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={(theme) => ({
                width: "25rem",
                [theme.breakpoints.down("xs")]: {
                  width: "100%",
                },
              })}
            >
              <SearchField
                onChange={handleSearchChange}
                placeholder={t("searchJobs")}
                term={filter}
                testID="SearchField"
              />
            </Box>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down(840)]: {
                  marginTop: "0.625rem",
                  alignContent: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                },
                [theme.breakpoints.down(400)]: {
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                },
              })}
            >
              <Button
                onClick={() =>
                  setJobsScreenSettings({
                    ...jobsScreenSettings,
                    mode: "board",
                  })
                }
                sx={{ marginRight: "1.25rem" }}
              >
                {t("switchToBoardView")}
              </Button>
              {hasPermissions?.([DefaultPermission.CreateJob]) ? (
                <CreateJobButton disabled={!workflows || workflows?.length === 0} />
              ) : null}
            </Box>
          </Box>
          {!error && !loading && jobs?.length === 0 && (
            <Box sx={{ paddingTop: "5rem", paddingBottom: "1.875rem" }}>
              {isBlank(filter) ? (
                <EmptyState title={t("page.jobList.emptyState.title")}>
                  {hasPermissions?.([DefaultPermission.CreateJob]) ? (
                    <Box>{t("page.jobList.emptyState.message")}</Box>
                  ) : null}
                </EmptyState>
              ) : (
                <EmptyState title={t("page.jobList.noMatchingResults.title")}>
                  <Box>{t("page.jobList.noMatchingResults.message")}</Box>
                </EmptyState>
              )}
            </Box>
          )}
          {!error && (loading || jobs?.length > 0) ? (
            <Paper
              sx={{
                overflowX: "auto",
              }}
            >
              <TableContainer
                sx={{
                  maxHeight: "calc(75vh)",
                  height: "calc(75vh - 50px)",
                }}
              >
                <Table sx={[classes.table, jobs?.length == 0 && { height: "100%" }]}>
                  <TableHead>
                    <TableRow>
                      <SortableTableHeader
                        isActiveSort={jobsScreenSettings.sortBy === "number"}
                        label={t("jobNumber")}
                        onClick={() => sort("number")}
                        sortDir={jobsScreenSettings.sortDir}
                        sx={classes.tableHead}
                        width="10%"
                      />
                      <SortableTableHeader
                        isActiveSort={jobsScreenSettings.sortBy === "workflowStep.jobStatus.name"}
                        label={t("status")}
                        onClick={() => sort("workflowStep.jobStatus.name")}
                        sortDir={jobsScreenSettings.sortDir}
                        sx={classes.tableHead}
                        width="15%"
                      />
                      <SortableTableHeader
                        isActiveSort={jobsScreenSettings.sortBy === "customer.name"}
                        label={t("customer")}
                        onClick={() => sort("customer.name")}
                        sortDir={jobsScreenSettings.sortDir}
                        sx={classes.tableHead}
                      />
                      <TableCell sx={classes.tableHead} variant="head">
                        {t("jobAddress")}
                      </TableCell>
                      <TableCell sx={classes.tableHead} variant="head" width="25%">
                        {t("description")}
                      </TableCell>
                      <TableCell sx={classes.tableHead} variant="head">
                        {" "}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={jobs?.length == 0 ? { height: "100%" } : {}}>
                    {!error &&
                      !loading &&
                      jobs?.map((edge) => {
                        const { node } = edge
                        return (
                          <TableRow
                            key={node.id}
                            onClick={() => handleRowClick(node.id)}
                            sx={classes.dataRow}
                          >
                            <TableCell>{node.number}</TableCell>
                            <TableCell sx={{ width: "fit-content" }}>
                              <Box sx={{ width: "fit-content" }}>
                                <JobStatusPill jobWorkflowStep={node.workflowStep} />
                              </Box>
                            </TableCell>
                            <TableCell sx={{ width: "fit-content" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "fit-content",
                                  paddingRight: "0.5rem",
                                }}
                              >
                                <Box sx={{ fontWeight: "600" }}>{node.customer.name}</Box>
                                {node.siteContact ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "0",
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    <Box>{formatPersonName(node.siteContact)}</Box>
                                    <Box sx={{ whiteSpace: "nowrap", width: "fit-content" }}>
                                      {node.siteContact.email}
                                    </Box>
                                    {node.siteContact.phoneNumber ? (
                                      <Box sx={{ whiteSpace: "nowrap" }}>
                                        {formatPhoneNumber(node.siteContact.phoneNumber)}
                                      </Box>
                                    ) : null}
                                  </Box>
                                ) : null}
                              </Box>
                            </TableCell>
                            <TableCell sx={{ width: "min-content" }}>
                              <AddressDisplay address={node.address} format="long" />
                            </TableCell>
                            <TableCell sx={{ width: "min-content" }}>
                              <Box sx={{ minWidth: "16rem", maxWidth: "25rem" }}>
                                {node.description ? truncate(node.description, 200) : NOT_SPECIFIED}
                              </Box>
                            </TableCell>
                            <TableCell align="right">
                              <FielderIconButton
                                aria-label={t("editJob") as string}
                                title={t("editJob") as string}
                              >
                                <EditIcon />
                              </FielderIconButton>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    {loading
                      ? [...Array(jobsScreenSettings.rowsPerPage).keys()].map((i) => (
                          <TableRow key={i} sx={classes.dataRow}>
                            {[...Array(NUM_COLUMNS).keys()].map((j) => (
                              <TableCell key={j}>
                                <Skeleton variant="text" />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : null}
                    {error ? (
                      <TableRow>
                        <TableCell align="center" colSpan={NUM_COLUMNS}>
                          <Box
                            style={{
                              color: "rgb(244, 67, 54)",
                              fontWeight: 500,
                              fontSize: "1.25rem",
                              lineHeight: 1.6,
                            }}
                          >
                            {t("errorLabel")}
                            {": "}
                            {error.message}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                ActionsComponent={() => (
                  <TablePaginationActions
                    hasNextPage={pageInfo.hasNextPage ?? false}
                    hasPreviousPage={pageInfo.hasPreviousPage ?? false}
                    onGoToFirstPage={onGoToFirstPage}
                    onGoToLastPage={onGoToLastPage}
                    onGoToNext={onGoToNext}
                    onGoToPrevious={onGoToPrevious}
                  />
                )}
                component="div"
                count={-1}
                labelDisplayedRows={() => ``}
                labelRowsPerPage={t("rowsPerPage") as string}
                onPageChange={() => {}}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={jobsScreenSettings.currentPage}
                rowsPerPage={jobsScreenSettings.rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              />
            </Paper>
          ) : null}
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  table: {
    width: "100%",
    minWidth: "1200px",
    tableLayout: "fixed",
  },
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
    cursor: "pointer",
  },
  dataRow: {
    height: "3.75rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f8f8f8",
    },
  },
} as const

export default JobList
