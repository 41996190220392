import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

function PdfIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <path
        d="M45.8,0H10C7.8,0,6,1.8,6,4v56c0,2.2,1.8,4,4,4h44c2.2,0,4-1.8,4-4V11.1L45.8,0z M47,6.5l3.8,3.5H47V6.5z M10,60V4h33v10
		h11l0,46H10z"
      />
      <path
        d="M21.5,35.5c0.4,0,0.9-0.1,1.3-0.3c1-0.4,2.6-1.6,5.5-7.3c0.9-0.3,2-0.5,3.1-0.8c1.4-0.3,2.8-0.5,4.1-0.6
		c2.7,2.8,3.8,3.5,5.3,3.9c0.5,0.1,0.9,0.2,1.3,0.2c1.9,0,2.9-1.2,3.1-1.9c0.4-0.9,0.5-2.8-1.4-4c-1.3-0.8-3.6-1.3-6.2-1.3
		c-0.3,0-0.6,0-0.9,0c-0.3-0.4-0.7-0.8-1.1-1.2c-1.3-1.4-2.3-2.6-3.1-3.8c1.1-3,1.3-4.5,1.2-5.8c-0.1-3.5-2.3-4.2-3.2-4.2
		c-1.2,0-3.1,1-3.2,3.7c-0.1,2.2,0.2,4.1,1.7,6.7c-0.4,1-0.9,2.1-1.4,3.3c-0.5,1.2-1,2.2-1.5,3.2c-3.9,1.3-5.4,2.9-6.2,3.8
		c-1.9,2-1.6,3.8-1.1,4.7C19.3,34.6,20.1,35.5,21.5,35.5z M42.3,27.3c0.2,0.1,0.2,0.2,0.2,0.3c-0.1,0.1-0.5,0.1-0.9,0
		c-0.4-0.1-0.8-0.2-1.7-1C41.3,26.8,42,27.2,42.3,27.3z M30.4,12.3c0-0.2,0-0.5,0.1-0.6c0.1,0.2,0.2,0.6,0.3,1.2
		c0,0.4,0,0.9-0.2,1.7C30.4,13.8,30.4,13,30.4,12.3z M30.1,24.2c0.1-0.2,0.2-0.5,0.3-0.7c0.2-0.5,0.5-1,0.7-1.5l0,0.1l0.1-0.2
		c0.5,0.6,1,1.2,1.6,1.9l-0.1,0l0.1,0.1c-0.7,0.1-1.4,0.2-2.1,0.4c-0.3,0-0.5,0.1-0.7,0.2L30.1,24.2L30.1,24.2z M22.1,31.3
		c0.3-0.3,0.8-0.8,1.6-1.4c-1.4,2.2-2,2.5-2.1,2.5c0,0-0.1,0-0.1,0l-0.2-0.3C21.4,32.2,21.8,31.7,22.1,31.3z"
      />
      <path
        d="M25.5,42.8c-0.4-0.3-0.9-0.5-1.4-0.6C23.6,42.1,23,42,22.4,42h-4.5v12.1h2.6v-4.8h1.9c1.4,0,2.5-0.3,3.2-0.9
		c0.8-0.6,1.2-1.5,1.2-2.7c0-0.7-0.1-1.2-0.3-1.7S25.9,43.1,25.5,42.8z M24,46.4c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
		s-0.5,0.1-0.7,0.1h-1.7v-2.9h1.7c0.7,0,1.2,0.1,1.5,0.3c0.1,0.1,0.3,0.3,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.7S24.1,46.3,24,46.4z"
      />
      <path
        d="M36.3,43.7c-0.5-0.5-1-0.9-1.7-1.2C33.9,42.1,33,42,32.1,42h-4v12.1h4c1.8,0,3.2-0.5,4.1-1.6c0.5-0.5,0.8-1.2,1.1-1.9
		c0.2-0.7,0.3-1.6,0.3-2.6c0-0.9-0.1-1.8-0.3-2.5C37.1,44.8,36.8,44.2,36.3,43.7z M34.9,49.5c-0.1,0.5-0.2,0.9-0.5,1.2
		c-0.2,0.4-0.5,0.6-0.9,0.8s-0.9,0.3-1.5,0.3h-1.3v-7.7h1.4c1,0,1.8,0.3,2.2,1c0.2,0.3,0.4,0.7,0.5,1.2c0.1,0.5,0.2,1,0.2,1.7
		C35,48.6,35,49.1,34.9,49.5z"
      />
      <polygon points="46.9,44.2 46.9,42 39,42 39,54.1 41.6,54.1 41.6,48.9 45.6,48.9 45.5,46.7 41.6,46.7 41.6,44.2 	" />
    </SvgIcon>
  )
}

export default PdfIcon
