import React, { useCallback, useState, useRef } from "react"
import dayjs, { type Dayjs } from "dayjs"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import ControlPanel from "./ControlPanel"
import ResourceCalendar from "./ResourceCalendar"
import JobSelector from "./JobSelector"
import JobDragPreview from "./JobDragPreview"
import {
  INTERVAL_DURATION,
  DAY_MODE_INTERVAL_WIDTH,
  DAY_MODE_ROW_HEIGHT,
  DAY_MODE_RESOURCE_COLUMN_WIDTH,
  WEEK_MODE_CELL_WIDTH,
  WEEK_MODE_RESOURCE_COLUMN_WIDTH,
  WEEK_MODE_INTERVAL_HEIGHT,
  WEEK_MODE_HEADER_HEIGHT,
} from "./Constants"
import type { JobAssignment } from "~/types/apiTypes"
import { TimeFrameOption, DispatchResource } from "~/types/appTypes"
import MapView from "./MapView"

interface DispatchCalendarProps {
  readonly assignments: JobAssignment[]
  readonly onAddOrUpdateAssignment: (assignment: JobAssignment) => void
  readonly onChangeDate: (date: Dayjs) => void
  readonly onChangeTimeFrame: (timeFrame: TimeFrameOption) => void
  readonly onDoubleClickAssignment: (assignment: JobAssignment) => void
  readonly onReorderResources: (reorderedResources: DispatchResource[]) => void
  readonly resources: DispatchResource[]
  readonly selectedDate: Dayjs
  readonly timeFrame: TimeFrameOption
  readonly timeZone: string
}

function DispatchCalendar({
  assignments,
  onAddOrUpdateAssignment,
  onChangeDate,
  onChangeTimeFrame,
  onDoubleClickAssignment,
  onReorderResources,
  resources,
  selectedDate,
  timeFrame,
  timeZone,
}: DispatchCalendarProps) {
  const [jobDragState, setJobDragState] = useState(() => {
    return {
      isDragging: false,
      initialPosition: { x: 0, y: 0 },
      job: null,
    }
  })
  const resourceCalendarRef = useRef(null)

  const onJobDragEnd = useCallback(
    ({ clientX, clientY }) => {
      if (resourceCalendarRef?.current) {
        const resourceCalendar = resourceCalendarRef.current
        const gridCoordinateParent = resourceCalendar.querySelector(".gridCoordinateParent")
        const gridClientRect = gridCoordinateParent.getBoundingClientRect()
        const droppableAreaNode = gridCoordinateParent.parentNode
        const droppableAreaRect = droppableAreaNode.getBoundingClientRect()

        if (
          clientX < droppableAreaRect.x ||
          clientX > droppableAreaRect.x + droppableAreaRect.width ||
          clientY < droppableAreaRect.y ||
          clientY > droppableAreaRect.y + droppableAreaRect.height
        ) {
          // The drop occurred outside of the horizontal bounds of the drop area. Do not create/edit an assignment.
          // Just get out of 'dragging' mode and exit.
          setJobDragState((state) => ({
            ...state,
            job: null,
            isDragging: false,
          }))
          return
        }

        if (clientY >= gridClientRect.y && clientY <= gridClientRect.y + gridClientRect.height) {
          if (timeFrame === TimeFrameOption.DAY) {
            const dropInterval = Math.floor(
              (clientX -
                gridClientRect.x +
                gridCoordinateParent.scrollLeft -
                DAY_MODE_RESOURCE_COLUMN_WIDTH) /
                DAY_MODE_INTERVAL_WIDTH
            )
            const timeMinutes = dropInterval * INTERVAL_DURATION

            const startDate = dayjs(selectedDate).hour(0).second(0).set("minute", timeMinutes)
            const endDate = startDate.add(1, "hour")
            const resourceIndex = Math.floor((clientY - gridClientRect.y) / DAY_MODE_ROW_HEIGHT)
            const resource = resources[resourceIndex]

            const assignment = {
              id: null,
              job: jobDragState.job,
              assignees: [resource],
              status: "TENTATIVE",
              isLocked: false,
              startDate: startDate.utc().format(),
              endDate: endDate.utc().format(),
            }

            setJobDragState({
              isDragging: false,
              initialPosition: { x: 0, y: 0 },
              job: null,
            })

            onAddOrUpdateAssignment(assignment)
          } else if (timeFrame === TimeFrameOption.WEEK) {
            // Make sure the assignment's left edge snaps to the appropriate column's left edge
            // Note - adjustedX should be a number in the range [0, 6]  (i.e., a day of week)
            let adjustedX = Math.floor(
              (clientX -
                gridClientRect.x +
                gridCoordinateParent.scrollLeft -
                WEEK_MODE_RESOURCE_COLUMN_WIDTH) /
                WEEK_MODE_CELL_WIDTH
            )
            adjustedX = Math.max(0, adjustedX) // don't let it be negative
            adjustedX = Math.min(adjustedX, gridClientRect.width - WEEK_MODE_CELL_WIDTH) // don't let it break out of the right edge of the container

            // figure out the day of week the assignment was dropped on
            const dayOfWeek = dayjs(selectedDate).day(adjustedX)

            const dropInterval = Math.floor(
              (clientY -
                gridClientRect.y +
                gridCoordinateParent.scrollTop -
                WEEK_MODE_HEADER_HEIGHT) /
                WEEK_MODE_INTERVAL_HEIGHT
            )

            const timeMinutes = dropInterval * INTERVAL_DURATION

            const startDate = dayjs(dayOfWeek).hour(0).second(0).set("minute", timeMinutes)
            const endDate = startDate.add(1, "hour")

            const assignment = {
              id: null,
              job: jobDragState.job,
              status: "TENTATIVE",
              isLocked: false,
              startDate: startDate.utc().format(),
              endDate: endDate.utc().format(),
            }

            onAddOrUpdateAssignment(assignment) // user needs to choose the assignee in WEEK mode
          }
        }
        setJobDragState((state) => ({
          ...state,
          isDragging: false,
        }))
      }
    },
    [timeFrame, selectedDate, resources, jobDragState.job, onAddOrUpdateAssignment]
  )

  return (
    <Box sx={classes.root}>
      <Paper
        sx={{
          marginRight: "0.625rem",
          flexGrow: 2,
          minWidth: "600px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            borderBottom: "1px solid #ededed",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          }}
        >
          <ControlPanel
            currentDate={selectedDate}
            currentTimeFrame={timeFrame}
            onChangeDate={onChangeDate}
            onChangeTimeFrame={onChangeTimeFrame}
          />
        </Box>
        {timeFrame !== TimeFrameOption.MAP ? (
          <ResourceCalendar
            assignments={assignments}
            date={selectedDate}
            onAddOrUpdateAssignment={onAddOrUpdateAssignment}
            onDoubleClickAssignment={onDoubleClickAssignment}
            onReorderResources={onReorderResources}
            ref={resourceCalendarRef}
            resources={resources}
            timeFrame={timeFrame}
            timeZone={timeZone}
          />
        ) : (
          <MapView assignments={assignments} />
        )}
      </Paper>
      <Paper elevation={2} sx={classes.jobSelectorContainer}>
        <JobSelector
          onJobDragStart={(job, coordinates) => {
            if (timeFrame !== "MAP") {
              setJobDragState({
                isDragging: true,
                job: job,
                initialPosition: coordinates,
              })
            }
          }}
        />
      </Paper>
      {jobDragState.isDragging ? (
        <JobDragPreview
          initialPosition={jobDragState.initialPosition}
          job={jobDragState.job}
          onDragEnd={onJobDragEnd}
          timeFrame={timeFrame}
        />
      ) : null}
    </Box>
  )
}

const classes = {
  root: (theme) => {
    return {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      maxWidth: "calc(100vw - 60px)",
      [theme.breakpoints.up("lg")]: {
        maxWidth: "calc(100vw - 280px)",
      },
    }
  },
  jobSelectorContainer: {
    flexGrow: 1,
    maxWidth: "300px",
  },
} as const

export default DispatchCalendar
