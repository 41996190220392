/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react"
import isNil from "lodash/isNil"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { FormControlLabel, Checkbox } from "@mui/material"
import { gql, useQuery } from "@apollo/client"
import dayjs from "dayjs"
import SelectorField from "../SelectorField"
import GoogleMapView, { Coordinate, DEFAULT_COORDINATE } from "../GoogleMapView"
import { useAuth } from "../../context/AuthContext"
import { Address, Job, MapMarker, MapMarkerType, Organization } from "../../types"
import FranchiseeMultiSelect from "../FranchiseeMultiSelect"
import { getAddressSummary } from "../../util"

const ALL_JOBS = gql`
  query AllJobs(
    $franchiseeIds: [ID!]
    $first: Int
    $assignmentStartDate: LocalDateTime
    $assignmentEndDate: LocalDateTime
    $includeUnassigned: Boolean
  ) {
    allJobs(
      input: { first: $first }
      jobFilter: {
        franchiseeIds: $franchiseeIds
        assignmentStartDate: $assignmentStartDate
        assignmentEndDate: $assignmentEndDate
        includeUnassigned: $includeUnassigned
      }
    ) {
      edges {
        node {
          id
          number
          organization {
            id
            tradeName
            address {
              addressString
              latitude
              longitude
            }
          }
          address {
            addressString
            streetNumber
            route
            locality
            latitude
            longitude
          }
          workflowStep {
            id
            jobStatus {
              id
              name
              darkColor
              mediumColor
              lightColor
            }
            parentWorkflowStep {
              id
              jobStatus {
                id
                name
                darkColor
                mediumColor
                lightColor
              }
            }
          }
          createdAt
        }
      }
    }
  }
`

const NOW = dayjs()
const NOW_WEEK_START = NOW.startOf("week")
const NOW_WEEK_END = NOW.endOf("week")
const NOW_MONTH_START = NOW.startOf("month")
const NOW_MONTH_END = NOW.endOf("month")
const NOW_QUARTER_START = NOW.startOf("quarter")
const NOW_QUARTER_END = NOW.endOf("quarter")
const NOW_YEAR_START = NOW.startOf("year")

type TimeframeId =
  | "NEXT_6_MONTHS"
  | "NEXT_3_MONTHS"
  | "NEXT_QUARTER"
  | "NEXT_MONTH"
  | "NEXT_7_DAYS"
  | "TODAY"
  | "NEXT_WEEK"
  | "THIS_WEEK"
  | "THIS_MONTH"
  | "LAST_7_DAYS"
  | "LAST_14_DAYS"
  | "LAST_MONTH"
  | "LAST_3_MONTHS"
  | "LAST_6_MONTHS"
  | "LAST_12_MONTHS"
  | "THIS_QUARTER"
  | "LAST_QUARTER"
  | "LAST_4_QUARTERS"
  | "LAST_8_QUARTERS"
  | "THIS_YEAR"
  | "LAST_YEAR"

interface TimeframeOption {
  id: TimeframeId
  name: string
}

function JobsMap() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const userHqCoordinates: Coordinate =
    "undefined" !== typeof user?.organization?.address?.latitude &&
    "undefined" !== typeof user?.organization?.address?.latitude
      ? {
          lat: user.organization.address.latitude as number,
          lng: user.organization.address.longitude as number,
        }
      : DEFAULT_COORDINATE

  const [timeframeOptions, setTimeframeOptions] = useState<TimeframeOption[]>()
  const [selectedFranchisees, setSelectedFranchisees] = useState<Organization[]>([])
  const [timeframe, setTimeframe] = useState<TimeframeOption>()
  const [start, setStart] = useState<string>()
  const [end, setEnd] = useState<string>()
  const [includeUnassigned, setIncludeUnassigned] = useState<boolean>(true)
  const [showIncludeUnassigned, setShowIncludeUnassigned] = useState<boolean>(true)

  useEffect(() => {
    if (t) {
      const TIMEFRAME_OPTIONS: TimeframeOption[] = [
        {
          id: "NEXT_6_MONTHS",
          name: t("page.report.timeframeOptions.nextNMonths", { num: 6 }),
        },
        {
          id: "NEXT_3_MONTHS",
          name: t("page.report.timeframeOptions.nextNMonths", { num: 3 }),
        },
        {
          id: "NEXT_QUARTER",
          name: t("page.report.timeframeOptions.nextQuarter"),
        },
        {
          id: "NEXT_MONTH",
          name: t("page.report.timeframeOptions.nextMonth"),
        },
        {
          id: "NEXT_7_DAYS",
          name: t("page.report.timeframeOptions.nextNDays", { num: 7 }),
        },
        {
          id: "THIS_MONTH",
          name: t("page.report.timeframeOptions.thisMonth"),
        },
        {
          id: "NEXT_WEEK",
          name: t("page.report.timeframeOptions.nextWeek"),
        },
        {
          id: "THIS_WEEK",
          name: t("page.report.timeframeOptions.thisWeek"),
        },
        {
          id: "TODAY",
          name: t("page.report.timeframeOptions.today"),
        },
        {
          id: "LAST_7_DAYS",
          name: t("page.report.timeframeOptions.lastNDays", { num: 7 }),
        },
        {
          id: "LAST_14_DAYS",
          name: t("page.report.timeframeOptions.lastNDays", { num: 14 }),
        },
        {
          id: "LAST_MONTH",
          name: t("page.report.timeframeOptions.lastMonth"),
        },
        {
          id: "LAST_3_MONTHS",
          name: t("page.report.timeframeOptions.lastNMonths", { num: 3 }),
        },
        {
          id: "LAST_6_MONTHS",
          name: t("page.report.timeframeOptions.lastNMonths", { num: 6 }),
        },
        {
          id: "LAST_12_MONTHS",
          name: t("page.report.timeframeOptions.lastNMonths", { num: 12 }),
        },
        {
          id: "THIS_QUARTER",
          name: t("page.report.timeframeOptions.thisQuarter"),
        },
        {
          id: "LAST_QUARTER",
          name: t("page.report.timeframeOptions.lastQuarter"),
        },
        {
          id: "LAST_4_QUARTERS",
          name: t("page.report.timeframeOptions.lastNQuarters", { num: 4 }),
        },
        {
          id: "THIS_YEAR",
          name: t("page.report.timeframeOptions.thisYear"),
        },
        {
          id: "LAST_YEAR",
          name: t("page.report.timeframeOptions.lastYear"),
        },
      ]

      setTimeframeOptions(TIMEFRAME_OPTIONS)

      setTimeframe(TIMEFRAME_OPTIONS.find((o) => o.id === "TODAY"))
      setStart(NOW.startOf("day").toISOString())
      setEnd(NOW.startOf("day").add(1, "day").toISOString())
    }
  }, [t])

  useEffect(() => {
    if (dayjs(end).isBefore(NOW)) {
      setIncludeUnassigned(false)
      setShowIncludeUnassigned(false)
    } else {
      setShowIncludeUnassigned(true)
    }
  }, [end])

  const { data } = useQuery(ALL_JOBS, {
    variables: {
      first: 10000,
      franchiseeIds: selectedFranchisees?.map((f) => f.id),
      assignmentStartDate: start,
      assignmentEndDate: end,
      includeUnassigned,
    },
    skip: selectedFranchisees.length === 0,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    context: {
      debounceKey: "ALL_JOBS",
      debounceTimeout: 50,
    },
  })

  function handleChangeTimeframe(val: TimeframeOption): void {
    setTimeframe(val)
    switch (val.id) {
      case "NEXT_6_MONTHS":
        setStart(NOW_MONTH_START.toISOString())
        setEnd(NOW_MONTH_END.add(6, "months").toISOString())
        break
      case "NEXT_3_MONTHS":
        setStart(NOW_MONTH_START.toISOString())
        setEnd(NOW_MONTH_END.add(3, "months").toISOString())
        break
      case "NEXT_MONTH":
        setStart(NOW_MONTH_START.add(1, "month").toISOString())
        setEnd(NOW_MONTH_END.add(1, "month").toISOString())
        break
      case "NEXT_7_DAYS":
        setStart(NOW.toISOString())
        setEnd(NOW.add(7, "days").toISOString())
        break
      case "THIS_MONTH":
        setStart(NOW_MONTH_START.toISOString())
        setEnd(NOW_MONTH_END.toISOString())
        break
      case "NEXT_WEEK":
        setStart(NOW_WEEK_START.add(1, "week").toISOString())
        setEnd(NOW_WEEK_END.add(1, "week").toISOString())
        break
      case "THIS_WEEK":
        setStart(NOW_WEEK_START.toISOString())
        setEnd(NOW_WEEK_END.toISOString())
        break
      case "TODAY":
        setStart(NOW.startOf("day").toISOString())
        setEnd(NOW.startOf("day").add(1, "day").toISOString())
        break
      case "LAST_7_DAYS":
        setStart(NOW.subtract(7, "days").toISOString())
        setEnd(NOW.toISOString())
        break
      case "LAST_14_DAYS":
        setStart(NOW.subtract(14, "days").toISOString())
        setEnd(NOW.toISOString())
        break
      case "LAST_MONTH":
        setStart(NOW_MONTH_START.subtract(1, "month").toISOString())
        setEnd(NOW_MONTH_START.subtract(1, "day").toISOString())
        break
      case "LAST_3_MONTHS":
        setStart(NOW_MONTH_START.subtract(3, "months").toISOString())
        setEnd(NOW.toISOString())
        break
      case "LAST_6_MONTHS":
        setStart(NOW_MONTH_START.subtract(6, "months").toISOString())
        setEnd(NOW.toISOString())
        break
      case "LAST_12_MONTHS":
        setStart(NOW_MONTH_START.subtract(12, "months").toISOString())
        setEnd(NOW.toISOString())
        break
      case "THIS_QUARTER":
        setStart(NOW_QUARTER_START.toISOString())
        setEnd(NOW.toISOString())
        break
      case "LAST_QUARTER":
        setStart(NOW_QUARTER_START.subtract(1, "quarter").toISOString())
        setEnd(NOW_QUARTER_END.subtract(1, "quarter").toISOString())
        break
      case "LAST_4_QUARTERS":
        setStart(NOW_QUARTER_START.subtract(4, "quarter").toISOString())
        setEnd(NOW_QUARTER_END.toISOString())
        break
      case "LAST_8_QUARTERS":
        setStart(NOW_QUARTER_START.subtract(8, "quarter").toISOString())
        setEnd(NOW_QUARTER_END.toISOString())
        break
      case "THIS_YEAR":
        setStart(NOW_YEAR_START.toISOString())
        setEnd(NOW.toISOString())
        break
      case "LAST_YEAR":
        setStart(NOW_YEAR_START.subtract(1, "year").toISOString())
        setEnd(NOW.endOf("year").subtract(1, "year").toISOString())
        break
      default:
        setStart(NOW_YEAR_START.toISOString())
        setEnd(NOW.toISOString())
    }
  }

  function getJobInfoWindow(job: Job) {
    const addr = job.address as Address
    const formattedAddressString = getAddressSummary(addr)

    return {
      title: `${t("job")} #${job.number}`,
      content: `<div style="font-family: Source Sans Pro, Arial; display: flex; flex-direction: column; gap: 4px; max-height: 150px; min-width: 300px; overflow: scroll;">
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <div style="font-size: 1.1rem; font-weight: 600;">${t("job")} #${job.number}</div>  
            <div style="font-size: 0.875rem; font-weight: 500;">${job.organization
              ?.tradeName}</div>            
          </div>
          <div style="font-weight: 400; font-size: 0.875rem">${formattedAddressString}</div> 
          <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
              <div style="width: 12px; height: 12px; border-radius: 6px; background-color:${job
                .workflowStep?.parentWorkflowStep?.jobStatus?.mediumColor}"></div>
              <span>${job.workflowStep?.parentWorkflowStep?.jobStatus?.name}</span>
            </div>            
        </div>`,
    }
  }

  const franchiseeMarkers = (selectedFranchisees
    .filter((f: Organization) => {
      return !!(f.address?.latitude && f.address?.longitude)
    })
    .map((franchiseeOrg: Organization) => {
      return {
        lat: franchiseeOrg.address?.latitude,
        lng: franchiseeOrg.address?.longitude,
        markerType: MapMarkerType.HQ,
        icon: `/icons/mapMarker_${MapMarkerType.HQ}.png`,
        infoWindow: {
          title: franchiseeOrg.name,
          content: `<strong>${franchiseeOrg.name}</strong>`,
        },
        dataObject: franchiseeOrg,
      }
    }) ?? []) as MapMarker[]

  const jobsWithGps = data?.allJobs?.edges
    ?.map((e) => e.node)
    .filter((j) => !isNil(j.address?.latitude) && !isNil(j.address?.longitude))

  const jobMarkers = (jobsWithGps?.map((job: Job) => {
    return {
      lat: job.address?.latitude,
      lng: job.address?.longitude,
      markerType: MapMarkerType.JOB,
      icon: {
        path: window?.google ? window.google.maps.SymbolPath.CIRCLE : "",
        fillColor: job.workflowStep?.parentWorkflowStep?.jobStatus?.mediumColor ?? "black",
        fillOpacity: 1,
        scale: 8,
        strokeColor: "#fff",
        strokeWeight: 3,
      },
      infoWindow: getJobInfoWindow(job),
      dataObject: job,
    }
  }) ?? []) as MapMarker[]

  const markers = franchiseeMarkers.concat(jobMarkers)
  let mapCenter = userHqCoordinates
  if (selectedFranchisees?.length > 0) {
    const firstFranchiseeWithGPS = selectedFranchisees.find(
      (f) => !isNil(f.address?.latitude) && !isNil(f.address?.longitude)
    )

    if (firstFranchiseeWithGPS) {
      mapCenter = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        lat: firstFranchiseeWithGPS.address!.latitude as number,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        lng: firstFranchiseeWithGPS.address!.longitude as number,
      }
    }
  }

  return (
    <Box
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={classes.headerPanel}>
        <Box>
          <Box sx={classes.headerTitle}>{t("page.report.jobsMap")}</Box>
          <Box sx={classes.headerSubTitle}>
            {t("page.report.fromDateToDate", {
              start: dayjs(start).format("MMMM YYYY"),
              end: dayjs(end).format("MMMM YYYY"),
            })}
          </Box>
        </Box>
      </Box>
      <Box sx={classes.controlPanel}>
        {timeframeOptions ? (
          <Box sx={classes.selectorContainer}>
            <SelectorField
              ariaLabel={t("page.report.timeframe")}
              label={t("page.report.timeframe")}
              margin="dense"
              name="timeframe-selector"
              onChange={(val) => {
                handleChangeTimeframe(val)
              }}
              options={timeframeOptions}
              value={timeframe?.id}
              variant="filled"
            />
          </Box>
        ) : null}
        <Box sx={classes.selectorContainer}>
          <FranchiseeMultiSelect
            ariaLabel={t("page.report.franchisees")}
            fullWidth
            label={t("page.report.franchisees")}
            margin="dense"
            name="franchisees-selector"
            onChange={(selected: Organization[] | null) => {
              setSelectedFranchisees(selected ?? [])
            }}
            selectedFranchisees={selectedFranchisees}
          />
        </Box>
        {showIncludeUnassigned ? (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeUnassigned}
                  onChange={(event, checked) => {
                    setIncludeUnassigned(checked)
                  }}
                  value="includeUnassigned"
                />
              }
              label={t("page.report.includeUnassignedJobs") as string}
              sx={{
                marginRight: "1.5rem",
              }}
            />
          </Box>
        ) : null}
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirecton: "column",
          minHeight: "420px",
        }}
      >
        <GoogleMapView center={mapCenter} markers={markers} style={{ flex: 1 }} zoom={8} />
      </Box>
    </Box>
  )
}

const classes = {
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
  },
  headerPanel: {
    display: "flex",
    flexDirection: "row",
  },
  headerTitle: {
    fontWeight: "bold",
    fontSize: "1.25rem",
  },
  headerSubTitle: {
    color: "rgba(0,0,0,0.5)",
  },
  controlPanel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "1rem",
    backgroundColor: "#EFEFEF",
    padding: "0.625rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    marginTop: "1rem",
    marginBottom: "0.5rem",
    borderRadius: "2px",
  },
  selectorContainer: {
    padding: "0.5rem",
    minWidth: "200px",
  },
  tooltip: {
    backgroundColor: "#fcfcfc",
    border: "1px solid #999",
    borderRadius: "2px",
    padding: "0.25rem 0.5rem",
  },
} as const

export default JobsMap
