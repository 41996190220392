import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import orderBy from "lodash/orderBy"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Hidden from "@mui/material/Hidden"
import Button from "@mui/material/Button"
import AddCommentIcon from "@mui/icons-material/AddCommentOutlined"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import EditIcon from "@mui/icons-material/EditOutlined"

import JobNoteDialog from "./JobNoteDialog"
import JobBasicInfoForm from "./JobBasicInfoForm"
import JobBasicInfoStatic from "./JobBasicInfoStatic"
import JobNote from "./JobNote"
import GoogleMapView from "~/components/GoogleMapView"
import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import { getAddressMarker, getDefaultMarkers, getUserHqMarker } from "~/util"
import { useAuth } from "~/context/AuthContext"
import { DefaultPermission, Job } from "~/types"

interface Props {
  readonly job: Job
  readonly onEnterEditMode: () => void
  readonly onSave: (job: Job) => void
  readonly onCancel: () => void
  readonly inEditMode?: boolean
  readonly loading?: boolean
}

function JobBasicInfoTab({
  job,
  onEnterEditMode,
  onSave,
  onCancel,
  inEditMode = false,
  loading = false,
}: Props) {
  const { user, hasPermissions } = useAuth()
  const { t } = useTranslation()
  const [jobAddressMarker, setJobAddressMarker] = useState(() =>
    getAddressMarker(job?.address, "job")
  )
  const [markers, setMarkers] = useState(() => getDefaultMarkers(user, job))
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false)
  const sortedNotes = job?.notes ? orderBy(job.notes, ["createdAt"], ["desc"]) : []
  const canEdit = hasPermissions?.([DefaultPermission.UpdateJob])
  const canAccessNotes = hasPermissions?.([DefaultPermission.ReadJob])

  return (
    <>
      <Box
        sx={{
          paddingBottom: "400px",
        }}
      >
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item lg={6} xs={12}>
            <Paper>
              <SectionHeader>
                <label>{t("basicInfo")}</label>
                {!inEditMode && canEdit ? (
                  <Button
                    onClick={() => {
                      onEnterEditMode?.()
                    }}
                    size="small"
                    startIcon={<EditIcon />}
                    variant="text"
                  >
                    {t("edit")}
                  </Button>
                ) : null}
              </SectionHeader>
              <Divider />
              {!inEditMode && (
                <SectionContent>
                  <JobBasicInfoStatic job={job} user={user} />
                </SectionContent>
              )}
              {inEditMode && user ? (
                <SectionContent>
                  <JobBasicInfoForm
                    job={job}
                    loading={loading}
                    onCancel={onCancel}
                    onChangeAddress={(address) => {
                      const jobMarker = getAddressMarker(address, "estimate")
                      setJobAddressMarker(jobMarker)
                      const markers = [getUserHqMarker(user)]
                      if (jobMarker) {
                        markers.push(jobMarker)
                      }
                      setMarkers(markers)
                    }}
                    onSave={onSave}
                    user={user}
                  />
                </SectionContent>
              ) : null}
            </Paper>
          </Grid>
          <Hidden smDown>
            <Grid item sm={6}>
              <Box
                sx={{
                  height: "100%",
                  maxHeight: "500px",
                  borderRadius: "4px",
                }}
              >
                <GoogleMapView
                  center={jobAddressMarker || getUserHqMarker(user)}
                  markers={markers}
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRadius: "4px",
                    height: "100%",
                  }}
                  zoom={15}
                />
              </Box>
            </Grid>
          </Hidden>
          <Grid item lg={6} xs={12}>
            <Paper>
              <SectionHeader>
                <label>{t("notes")}</label>
                {canAccessNotes ? (
                  <Button
                    aria-label={t("addNote") as string}
                    onClick={() => setIsNoteDialogOpen(true)}
                    size="small"
                    startIcon={<AddCommentIcon />}
                    variant="text"
                  >
                    {t("addNote")}
                  </Button>
                ) : null}
              </SectionHeader>
              <Divider />
              <SectionContent
                sx={{
                  paddingLeft: "1.25rem",
                  paddingRight: "1.25rem",
                  paddingBottom: "1.25rem",
                  maxHeight: "500px",
                  overflowY: "scroll",
                }}
              >
                {sortedNotes.length === 0 ? (
                  <Box sx={classes.noNotesPrompt}>{t("page.jobBasicInfo.noNotesPrompt")}</Box>
                ) : (
                  sortedNotes.map((note, index) => (
                    <Box key={note.id}>
                      <JobNote note={note} />
                      {index !== sortedNotes.length - 1 && <Divider />}
                    </Box>
                  ))
                )}
              </SectionContent>
            </Paper>
          </Grid>
          <Grid item lg={6} xs={12} />
        </Grid>
      </Box>
      {isNoteDialogOpen ? (
        <JobNoteDialog
          jobId={job.id}
          onCancel={() => setIsNoteDialogOpen(false)}
          onSave={(status) => {
            setIsNoteDialogOpen(false)
            if (status === "success") {
              // setSnack({ messageKey: "messages.changesSaved", variant: "success" })
            }
          }}
          user={user}
        />
      ) : null}
    </>
  )
}

const classes = {
  noNotesPrompt: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "1.875rem",
    fontSize: "1rem",
    fontWeight: 500,
    color: "#747474",
  },
} as const

export default JobBasicInfoTab
