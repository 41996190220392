import React from "react"
import Box from "@mui/material/Box"
import ItemImageManager from "../../../components/ItemImageManager"
import { Item } from "../../../types"

interface SidebarProps {
  item?: Item
  onImageUpload?: () => void
}

function Sidebar({ item, onImageUpload }: SidebarProps) {
  return (
    <Box
      sx={(theme) => {
        return {
          display: item && item.attachments?.length > 0 ? "flex" : "none",
          margin: 0,
          padding: 0,
          [theme.breakpoints.up("sm")]: {},
          [theme.breakpoints.up("md")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            maxWidth: "200px",
          },
        }
      }}
    >
      <ItemImageManager item={item} onUploadComplete={onImageUpload} />
    </Box>
  )
}

export default Sidebar
