import React from "react"

function WhatIsFielder() {
  return (
    <div>
      <h3>Who can I contact for help?</h3>
      <p>
        You can send an email to <a href="mailto:support@fielderapp.com">support@fielderapp.com</a>.
        Or you can call us at <strong>+1 612-688-2176</strong>.
      </p>
    </div>
  )
}

export default WhatIsFielder
