import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { useMutation, ApolloError } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import { CREATE_TASK } from "../queries/createTask"
import { EDIT_TASK } from "../queries/editTask"
import FielderTextField from "./FielderTextField"
import ErrorMessage from "./ErrorMessage"
import JobTypeahead from "./JobTypeahead"
import FieldHelperText from "./FieldHelperText"
import SelectorField from "./SelectorField"
import UserSelect from "./UserSelect"
import { Job, Task, TaskStatus, User } from "../types"
import SaveButton from "./SaveButton"
import { createDayJS } from "../util"

const NOW = dayjs()
const MAX_DATE = NOW.add(5, "year")

interface ValidationErrors {
  dueDate: string | null
  assignee: string | null
  status: string | null
  description: string | null
}

interface TaskDialogProps {
  readonly hideJob?: boolean
  readonly open?: boolean
  readonly onClose?: () => void
  readonly task: Partial<Task>
  readonly user?: Partial<User> | null
}

function TaskDialog({ hideJob = false, open = false, onClose, task, user }: TaskDialogProps) {
  const { t } = useTranslation()
  const [dueDate, setDueDate] = useState<Dayjs | null>(() => {
    const d = task?.id ? createDayJS(task.dueDate, user?.organization?.timeZone) : null
    return d ?? NOW
  })
  const [assignee, setAssignee] = useState<User | null>(task.assignee ?? null)
  const [job, setJob] = useState<Job | null>(task.job ?? null)
  const [status, setStatus] = useState<TaskStatus | null | undefined>(task.status ?? null)
  const [description, setDescription] = useState<string>(task.description ?? "")
  const [errors, setErrors] = useState<ValidationErrors>(() => ({
    dueDate: null,
    assignee: null,
    status: null,
    description: null,
  }))

  const mutationOptions = {
    onCompleted: () => {
      onClose?.()
    },
    onError: (error: ApolloError) => {
      Sentry.captureException(error)
    },
    refetchQueries: () => {
      return ["AllTasks"]
    },
  }

  const [editTask, { loading: editTaskLoading, error: editTaskError }] = useMutation(
    EDIT_TASK,
    mutationOptions
  )
  const [createTask, { loading: createTaskLoading, error: createTaskError }] = useMutation(
    CREATE_TASK,
    mutationOptions
  )

  const error = editTaskError || createTaskError
  const loading = editTaskLoading || createTaskLoading
  const editMode = !!task.id

  const statusOptions = [
    { id: TaskStatus.NOT_STARTED, name: t(`taskStatus.${TaskStatus.NOT_STARTED}`) },
    { id: TaskStatus.IN_PROGRESS, name: t(`taskStatus.${TaskStatus.IN_PROGRESS}`) },
    { id: TaskStatus.COMPLETED, name: t(`taskStatus.${TaskStatus.COMPLETED}`) },
  ]

  const handleSave = () => {
    const validationResult = { ...errors } as ValidationErrors
    if (!description || description.length == 0) {
      validationResult.description = t("component.taskDialog.description.error.required")
    }
    if (!dueDate || !dueDate.isValid()) {
      validationResult.dueDate = t("component.taskDialog.dueDate.error.invalid")
    }
    if (!status || status.length == 0) {
      validationResult.status = t("component.taskDialog.status.error.required")
    }
    if (!assignee) {
      validationResult.assignee = t("component.taskDialog.status.error.required")
    }

    if (Object.values(validationResult).some(Boolean)) {
      setErrors(validationResult)
      return
    }

    const variables = {
      id: task.id,
      description,
      dueDate: dueDate ? dueDate.startOf("day") : null,
      assigneeUserId: assignee?.id,
      status,
      jobId: job ? job.id : null,
    }

    editMode ? editTask({ variables }) : createTask({ variables })
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      data-testid="TaskDialog"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <DialogTitle
        id="form-dialog-title"
        sx={(theme) => ({
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: theme.palette.primary.main,
        })}
      >
        {task.id ? t("editTask") : t("createTask")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            paddingBottom: 0,
            paddingTop: "1.5rem",
          }}
        >
          {error ? (
            <ErrorMessage message={t("error.general.message")} title={t("error.general.title")} />
          ) : null}
          {loading ? (
            <Box
              sx={{
                minHeight: "390px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              <p>{t("saving")} ...</p>
            </Box>
          ) : (
            <>
              <Box sx={classes.fieldContainer}>
                <FormControl fullWidth>
                  <DatePicker
                    aria-label={t("dueDate")}
                    disablePast={typeof task?.id === "undefined"}
                    format={t("format:dateFormat.short") as string}
                    label={t("dueDate")}
                    maxDate={MAX_DATE}
                    minDate={task?.id ? undefined : NOW}
                    onChange={(val) => {
                      if (!val || val?.isValid()) {
                        if (
                          val?.isBetween(NOW, MAX_DATE, "day", "[]") ||
                          (Boolean(task?.id) && val?.isBefore(MAX_DATE, "day"))
                        ) {
                          setErrors({
                            ...errors,
                            dueDate: null,
                          })
                        }
                      }
                      setDueDate(val)
                    }}
                    onError={(reason, value) => {
                      if (reason === "minDate" || reason === "disablePast") {
                        setErrors({
                          ...errors,
                          dueDate: t("component.taskDialog.dueDate.error.minDate"),
                        })
                      } else if (reason === "maxDate") {
                        setErrors({
                          ...errors,
                          dueDate: t("component.taskDialog.dueDate.error.maxDate"),
                        })
                      } else if (reason === "invalidDate") {
                        setErrors({
                          ...errors,
                          dueDate: t("component.taskDialog.dueDate.error.invalid"),
                        })
                      }
                    }}
                    slotProps={{
                      textField: {
                        InputProps: {
                          disableUnderline: true,
                        },
                        fullWidth: true,
                        required: true,
                        helperText: errors.dueDate
                          ? errors.dueDate
                          : t("component.taskDialog.dueDate.helperText"),
                      },
                    }}
                    slots={{
                      textField: FielderTextField,
                    }}
                    value={dueDate}
                  />
                </FormControl>
              </Box>
              <Box sx={classes.fieldContainer}>
                <FormControl fullWidth required>
                  <UserSelect
                    aria-label={t("assignedTo")}
                    error={!!errors.assignee}
                    label={t("assignedTo")}
                    name="assignee"
                    onChange={(a: User | null) => {
                      setAssignee(a)
                    }}
                    required
                    selectedUser={assignee}
                    variant="filled"
                  />
                  {errors.assignee ? <FieldHelperText error message={errors.assignee} /> : null}
                </FormControl>
              </Box>
              {!hideJob && (
                <Box sx={classes.fieldContainer}>
                  <FormControl fullWidth>
                    <JobTypeahead
                      currentJob={task.job}
                      label={t("job")}
                      onSelect={(j) => setJob(j)}
                    />
                  </FormControl>
                </Box>
              )}
              <Box sx={classes.fieldContainer}>
                <FormControl fullWidth>
                  <SelectorField
                    aria-label={t("status")}
                    error={!!errors.status}
                    label={t("status")}
                    name="status"
                    onChange={(s) => {
                      setStatus(s.id as TaskStatus)
                    }}
                    options={statusOptions}
                    value={status}
                  />
                  {errors.status ? <FieldHelperText error message={errors.status.message} /> : null}
                </FormControl>
              </Box>
              <Box sx={classes.fieldContainer}>
                <FormControl fullWidth>
                  <FielderTextField
                    aria-label={t("description") as string}
                    data-testid="description"
                    error={!!errors.description}
                    id="description"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    label={t("description")}
                    minRows={4}
                    multiline
                    name="description"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setErrors({
                          ...errors,
                          description: null,
                        })
                      }
                      setDescription(e.target.value)
                    }}
                    required
                    value={description}
                  />
                  {errors.description ? (
                    <FieldHelperText error message={errors.description} />
                  ) : null}
                </FormControl>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: "1.5rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onClose}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton loading={loading} onClick={handleSave} />
      </DialogActions>
    </Dialog>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
} as const

export default TaskDialog
