import gql from "graphql-tag"

export const CREATE_JOB_FROM_ESTIMATE_REQUEST = gql`
  mutation CreateJobFromEstimateRequest($id: ID!) {
    createJobFromEstimateRequest(input: { id: $id }) {
      job {
        id
        number
        attachments {
          id
          contentType
          name
          signedUrl
          isArchived
        }
        customer {
          id
          name
        }
        categories {
          id
          name
        }
        workflowStep {
          id
          jobStatus {
            id
            name
            lightColor
            mediumColor
            darkColor
          }
        }
        customer {
          id
          name
        }
        categories {
          id
          name
        }
        description
        address {
          addressString
          latitude
          longitude
        }
        siteContact {
          firstName
          lastName
          email
          phoneNumber
          address {
            addressString
            latitude
            longitude
          }
        }
      }
    }
  }
`
