import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import { useAuth } from "../context/AuthContext"
import { SxProps, Theme } from "@mui/material/styles"

interface SaveButtonProps {
  readonly disabled?: boolean
  readonly label?: string
  readonly loading?: boolean
  readonly onClick: () => void
  readonly sx?: SxProps<Theme>
}

const SaveButton = ({ disabled = false, label, loading = false, onClick, sx }: SaveButtonProps) => {
  const { t } = useTranslation()
  const { isImpersonating } = useAuth()

  return (
    <Button
      color="primary"
      data-testid="saveButton"
      disabled={disabled || loading || isImpersonating}
      onClick={onClick}
      sx={{
        minWidth: "90px",
        fontWeight: "bold",
        "& div": {
          marginLeft: "0.625rem",
          marginRight: "0.625rem",
        },
        ...sx,
      }}
      variant="contained"
    >
      {loading ? (
        <CircularProgress color="secondary" size={20} thickness={6.0} />
      ) : (
        <Box>{label ?? t("save")}</Box>
      )}
    </Button>
  )
}

export default SaveButton
