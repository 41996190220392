import React from "react"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import { Droppable } from "react-beautiful-dnd"
import { capitalize } from "../util"
import ProductOrderCard from "./ProductOrderCard"
import { ProductOrder, ProductOrderStatusColumnOption } from "../types"

interface ProductOrderStatusColumnProps {
  readonly backgroundColor: string
  readonly foregroundColor: string
  readonly status: ProductOrderStatusColumnOption
  readonly isDragDisabled?: boolean
  readonly isDropDisabled?: boolean
  readonly productOrders: ProductOrder[]
  readonly onClick?: (productOrder: ProductOrder) => void
}

function ProductOrderStatusColumn({
  backgroundColor,
  foregroundColor,
  status,
  isDragDisabled = false,
  isDropDisabled = false,
  productOrders,
  onClick,
}: ProductOrderStatusColumnProps) {
  return (
    <Droppable droppableId={status.id} isDropDisabled={isDropDisabled}>
      {(provided) => (
        <Paper
          sx={{
            color: "#121212",
            minWidth: "220px",
            maxWidth: "300px",
            flex: 1,
            height: "100%",
            minHeight: "400px",
            maxHeight: "800px",
            overflowY: "scroll",
            marginRight: "0.625rem",
            border: `1px solid ${foregroundColor}`,
            borderRadius: "4px",
            "&:last-of-type": {
              marginRight: 0,
            },
            display: "flex",
            flexDirection: "column",
            backgroundColor: backgroundColor,
          }}
        >
          <Box
            sx={{
              fontSize: "0.875rem",
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "0.625rem",
              paddingBottom: "0.625rem",
              borderBottom: `1px solid ${foregroundColor}`,
              textTransform: "uppercase",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              backgroundColor: foregroundColor,
              color: "#ffffffed",
            }}
          >
            {capitalize(status.name)}
          </Box>
          <Box
            ref={provided.innerRef}
            sx={{
              maxHeight: "100%",
              overflowY: "scroll",
              boxSizing: "border-box",
              flexGrow: 1,
            }}
            {...provided.droppableProps}
          >
            {productOrders?.map((productOrder, index) => (
              <div index={index} key={productOrder.id}>
                <ProductOrderCard
                  index={index}
                  isDragDisabled={isDragDisabled}
                  onClick={() => onClick(productOrder.id)}
                  productOrder={productOrder}
                />
              </div>
            ))}
            {provided.placeholder}
          </Box>
        </Paper>
      )}
    </Droppable>
  )
}

export default ProductOrderStatusColumn
