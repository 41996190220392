import gql from "graphql-tag"
import FullItem from "./fragments/FullItem"

export const ARCHIVE_ITEM = gql`
  mutation ArchiveItem($id: ID!) {
    archiveItem(id: $id) {
      item {
        ...FullItem
      }
    }
  }
  ${FullItem}
`
