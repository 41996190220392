import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"
import MainLayout from "../../../components/MainLayout"
import MenuItemCard from "../../../components/MenuItemCard"
import PageHeader from "../../../components/PageHeader"
import WorkOrderTemplateIcon from "../../../components/icons/WorkOrderTemplateIcon"
import RequestEstimateIcon from "../../../components/icons/RequestEstimateIcon"
import BalanceIcon from "@mui/icons-material/Balance"
import Seo from "../../../components/Seo"
import { useAuth } from "../../../context/AuthContext"
import { SETTINGS } from "../../../util"
import PERMISSION_REQUIREMENTS from "../../../util/permissionRequirements"

function TemplatesHome() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { hasPermissions } = useAuth()

  const iconFontSize = 40

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="templates"
          />
          <Grid container spacing={3} sx={{ marginTop: "1rem" }}>
            {hasPermissions?.(PERMISSION_REQUIREMENTS.settings.checklistTemplates) ? (
              <Grid item>
                <MenuItemCard
                  icon={<DoneAllIcon style={{ fontSize: iconFontSize }} />}
                  onClick={() => navigate("/app/settings/templates/checklist")}
                  testID="MenuItem-ChecklistTemplates"
                  title={t("checklistTemplates")}
                />
              </Grid>
            ) : null}
            {hasPermissions?.(PERMISSION_REQUIREMENTS.settings.estimateTemplates) ? (
              <Grid item>
                <MenuItemCard
                  icon={<RequestEstimateIcon color="#212121" size={50} />}
                  onClick={() => navigate("/app/settings/templates/estimate/list")}
                  testID="MenuItem-EstimateTemplates"
                  title={t("estimateTemplates")}
                />
              </Grid>
            ) : null}
            {hasPermissions?.(PERMISSION_REQUIREMENTS.settings.workOrderTemplates) ? (
              <Grid item>
                <MenuItemCard
                  icon={<WorkOrderTemplateIcon color="#212121" size={50} />}
                  onClick={() => navigate("/app/settings/templates/workorder/list")}
                  testID="MenuItem-WorkOrderTemplates"
                  title={t("workOrderTemplates")}
                />
              </Grid>
            ) : null}
            {hasPermissions?.(PERMISSION_REQUIREMENTS.settings.emailTemplates) ? (
              <Grid item>
                <MenuItemCard
                  icon={<EmailOutlinedIcon fontSize="inherit" style={{ fontSize: "3.125rem" }} />}
                  onClick={() => navigate("/app/settings/templates/email/list")}
                  testID="MenuItem-EmailTemplates"
                  title={t("emailTemplates")}
                />
              </Grid>
            ) : null}
            {hasPermissions?.(PERMISSION_REQUIREMENTS.settings.contractTemplates) ? (
              <Grid item>
                <MenuItemCard
                  icon={<BalanceIcon fontSize="inherit" style={{ fontSize: "3.125rem" }} />}
                  onClick={() => navigate("/app/settings/templates/contract/list")}
                  testID="MenuItem-ContractTemplates"
                  title={t("contractTemplates")}
                />
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </MainLayout>
    </>
  )
}

export default TemplatesHome
