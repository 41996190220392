import gql from "graphql-tag"
import FullWorkOrderLineItemDetails from "./fragments/FullWorkOrderLineItemDetails"

export const ALL_ASSIGNMENTS_FOR_JOB = gql`
  query AllAssignmentsForJob($jobId: ID!) {
    getJobById(id: $jobId) {
      id
      assignments {
        id
        assignees {
          id
          firstName
          lastName
        }
        status
        startDate
        endDate
        isLocked
        workOrder {
          id
          number
          currencyCode
          status
          description
          notes
          subTotal
          total
          lineItems {
            id
            number
            organizationItem {
              id
              code
              name
              type
            }
            showDetails
            lineItemDetails {
              ...FullWorkOrderLineItemDetails
            }
            quantity
            unitPrice
            total
          }
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
        }
      }
    }
  }
  ${FullWorkOrderLineItemDetails}
`
