import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Divider from "@mui/material/Divider"

import ExpandMore from "~/components/ExpandMore"
import { OrganizationDefaultInvoiceSettings } from "~/types"
import CustomerViewSettingFormField from "./CustomerViewSettingFormField"
import NetTermsSelect from "~/components/NetTermsSelect"
import useStore from "~/store"

interface Props {
  readonly defaultInvoiceSettings: OrganizationDefaultInvoiceSettings
  readonly onChange: (defaultInvoiceSettings: OrganizationDefaultInvoiceSettings) => void
  readonly showBottomDivider?: boolean
}

export default function DefaultInvoiceSettingsForm({
  defaultInvoiceSettings,
  showBottomDivider,
  onChange,
}: Props) {
  const { t } = useTranslation()
  const organizationDetailScreenSettings = useStore(
    (state) => state.organizationDetailScreenSettings
  )
  const setOrganizationDefaultSettings = useStore(
    (state) => state.setOrganizationDetailScreenSettings
  )

  return (
    <Box>
      <Box sx={{ paddingBottom: "0.5rem" }} />
      <Box
        onClick={() => {
          setOrganizationDefaultSettings({
            ...organizationDetailScreenSettings,
            isDefaultInvoiceSettingsExpanded:
              !organizationDetailScreenSettings.isDefaultInvoiceSettingsExpanded,
          })
        }}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Box
          component="label"
          sx={{
            cursor: "pointer",
            fontWeight: 700,
            marginBottom: "0.25rem",
            fontSize: "0.875rem",
          }}
        >
          {t("page.organizationDetails.defaultInvoiceSettings.label")}
        </Box>
        <ExpandMore
          aria-expanded={organizationDetailScreenSettings.isDefaultInvoiceSettingsExpanded}
          aria-label={t("page.organizationDetails.defaultInvoiceSettings.label") as string}
          expand={organizationDetailScreenSettings.isDefaultInvoiceSettingsExpanded}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>
      <Collapse in={organizationDetailScreenSettings.isDefaultInvoiceSettingsExpanded}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.5rem",
            paddingRight: "1rem",
            backgroundColor: "#f5f5f5",
            borderRadius: "4px",
            margin: "0.25rem 0rem",
          }}
        >
          <Box sx={{ fontSize: "0.875rem", fontWeight: "600" }}>
            {t("page.organizationDetails.defaultInvoiceSettings.netTerms")}
          </Box>
          <NetTermsSelect
            data-testid="invoiceDialogField_netTerms"
            fullWidth={false}
            label={undefined}
            name="netTerms"
            onChange={(selectedOption) => {
              if (selectedOption) {
                onChange({ ...defaultInvoiceSettings, netTerms: selectedOption })
              }
            }}
            size="small"
            value={defaultInvoiceSettings.netTerms}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            padding: "0.5rem",
            paddingRight: "1rem",
          }}
        >
          <Box sx={{ fontSize: "0.875rem", fontWeight: "600" }}>
            {t("page.organizationDetails.customerViewSettings.label")}
          </Box>
          <Box sx={{ fontSize: "0.875rem", color: (theme) => theme.fielderColors.mutedText }}>
            {t("page.organizationDetails.defaultInvoiceSettings.customerViewSettingsDescription")}
          </Box>
        </Box>
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showDueDate")}
          onChange={(value) => onChange({ ...defaultInvoiceSettings, showDueDate: value })}
          value={Boolean(defaultInvoiceSettings.showDueDate)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItems")}
          onChange={(value) => onChange({ ...defaultInvoiceSettings, showLineItems: value })}
          value={Boolean(defaultInvoiceSettings.showLineItems)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemCode")}
          onChange={(value) => onChange({ ...defaultInvoiceSettings, showLineItemCode: value })}
          value={Boolean(defaultInvoiceSettings.showLineItemCode)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemName")}
          onChange={(value) => onChange({ ...defaultInvoiceSettings, showLineItemName: value })}
          value={Boolean(defaultInvoiceSettings.showLineItemName)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemDescription")}
          onChange={(value) =>
            onChange({ ...defaultInvoiceSettings, showLineItemDescription: value })
          }
          value={Boolean(defaultInvoiceSettings.showLineItemDescription)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemQuantity")}
          onChange={(value) => onChange({ ...defaultInvoiceSettings, showLineItemQuantity: value })}
          value={Boolean(defaultInvoiceSettings.showLineItemQuantity)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemUnitPrice")}
          onChange={(value) =>
            onChange({ ...defaultInvoiceSettings, showLineItemUnitPrice: value })
          }
          value={Boolean(defaultInvoiceSettings.showLineItemUnitPrice)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemSubtotal")}
          onChange={(value) => onChange({ ...defaultInvoiceSettings, showLineItemSubtotal: value })}
          value={Boolean(defaultInvoiceSettings.showLineItemSubtotal)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemBundleComponents")}
          onChange={(value) =>
            onChange({ ...defaultInvoiceSettings, showLineItemBundleComponents: value })
          }
          value={Boolean(defaultInvoiceSettings.showLineItemBundleComponents)}
        />
        <CustomerViewSettingFormField
          label={t(
            "page.organizationDetails.customerViewSettings.showLineItemBundleComponentQuantity"
          )}
          onChange={(value) =>
            onChange({ ...defaultInvoiceSettings, showLineItemBundleComponentQuantity: value })
          }
          value={Boolean(defaultInvoiceSettings.showLineItemBundleComponentQuantity)}
        />
        <CustomerViewSettingFormField
          label={t(
            "page.organizationDetails.customerViewSettings.showLineItemBundleComponentUnitPrice"
          )}
          onChange={(value) =>
            onChange({ ...defaultInvoiceSettings, showLineItemBundleComponentUnitPrice: value })
          }
          value={Boolean(defaultInvoiceSettings.showLineItemBundleComponentUnitPrice)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showFooter")}
          onChange={(value) => onChange({ ...defaultInvoiceSettings, showFooter: value })}
          value={Boolean(defaultInvoiceSettings.showFooter)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showFooterSubtotal")}
          onChange={(value) => onChange({ ...defaultInvoiceSettings, showFooterSubtotal: value })}
          value={Boolean(defaultInvoiceSettings.showFooterSubtotal)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showFooterDiscount")}
          onChange={(value) => onChange({ ...defaultInvoiceSettings, showFooterDiscount: value })}
          value={Boolean(defaultInvoiceSettings.showFooterDiscount)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showFooterTaxes")}
          onChange={(value) => onChange({ ...defaultInvoiceSettings, showFooterTaxes: value })}
          value={Boolean(defaultInvoiceSettings.showFooterTaxes)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showFooterTotal")}
          onChange={(value) => onChange({ ...defaultInvoiceSettings, showFooterTotal: value })}
          value={Boolean(defaultInvoiceSettings.showFooterTotal)}
        />
        <Box sx={{ paddingBottom: "0.5rem" }} />
      </Collapse>
      <Box sx={{ paddingBottom: "0.5rem" }} />
      {showBottomDivider ? <Divider /> : null}
    </Box>
  )
}
