import React from "react"
import type { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { ItemOrderCount } from "../../types/appTypes"
import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

interface Props {
  readonly counts: ItemOrderCount[]
  readonly startDate: Dayjs
  readonly endDate: Dayjs
}

const truncate = {
  overflow: "hidden",
  texOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}

export function FranchisorTopItemsOrdered({ counts, startDate, endDate }: Props) {
  const { t } = useTranslation()

  const dateFormat = "MMM D"

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        maxWidth: "36rem",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.fielderColors.black,
          height: "4px",
        }}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "24px",
          border: (theme) => `1px solid ${theme.fielderColors.inputBorder}`,
          borderTop: "none",
          padding: "16px",
          paddingTop: "16px",
          [theme.breakpoints.up("sm")]: {
            padding: "24px",
          },
          [theme.breakpoints.up("md")]: {
            padding: "32px",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <Box sx={{ fontSize: "1.125rem", fontWeight: "600" }}>
                {t("widget.franchisorTopItemsOrdered.title")}
              </Box>
              <Tooltip
                sx={{ width: "1rem" }}
                title={t("widget.franchisorTopItemsOrdered.description")}
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </Box>
            <Box sx={{ fontSize: "0.75rem", color: (theme) => theme.fielderColors.mutedText }}>
              {startDate.format(dateFormat)} - {endDate.format(dateFormat)}
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            {counts.map((c: ItemOrderCount) => (
              <Box
                key={c.item.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    ...truncate,
                  }}
                >
                  <Box sx={{ fontSize: "0.875rem", fontWeight: "600", ...truncate }}>
                    {c.item.code}
                  </Box>
                  <Box
                    sx={{
                      fontSize: "0.75rem",
                      color: (theme) => theme.fielderColors.mutedText,
                      ...truncate,
                    }}
                  >
                    {c.item.name}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                  <Box sx={{ fontSize: "0.875rem", fontWeight: "600" }}>{c.count}</Box>
                  <Box
                    className="text-xs italic text-gray-500"
                    sx={{
                      fontSize: "0.75rem",
                      color: (theme) => theme.fielderColors.mutedText,
                      fontStyle: "italic",
                    }}
                  >
                    {t("units")}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
