import React from "react"
import { useTranslation } from "react-i18next"
import { HeaderLogo, Seo } from "../components"
import Box from "@mui/material/Box"

const styles = {
  message: {
    fontSize: "1.25rem",
    marginBottom: "1.25rem",
  },
} as const

function OrgAccountLocked() {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Seo title={t("locked")} />
      <Box component="main">
        <Box
          sx={{
            backgroundColor: "#212121",
          }}
        >
          <Box
            sx={{
              width: 266,
              padding: "1rem",
              paddingBottom: "0.5rem",
            }}
          >
            <HeaderLogo height="auto" width="100%" />
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "1.875rem",
            paddingTop: "3.125rem",

            "& h1": {
              marginBottom: "2.5rem",
              [theme.breakpoints.down("xs")]: {
                fontSize: "1.5em",
              },
            },
          })}
        >
          <h1>{t("page.orgAccountLocked.headline")}</h1>
          <Box sx={styles.message}>{t("page.orgAccountLocked.message")}</Box>
          <Box sx={styles.message}>
            <a href="mailto:support@fielderapp.com">support@fielderapp.com</a>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OrgAccountLocked
