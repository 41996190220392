import React, { Fragment, useState } from "react"
import * as Sentry from "@sentry/react"
import { Navigate, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import CircularProgress from "@mui/material/CircularProgress"
import MainLayout from "../../components/MainLayout"
import PageHeader from "../../components/PageHeader"
import Seo from "../../components/Seo"
import SnackbarMessage from "../../components/SnackbarMessage"
import EmptyState from "../../components/EmptyState"
import CartLineItem from "./components/CartLineItem"
import { EDIT_PRODUCT_ORDER } from "../../queries/editProductOrder"
import { EDIT_PRODUCT_ORDER_LINE_ITEM } from "../../queries/editProductOrderLineItem"
import { GET_DRAFT_PRODUCT_ORDER } from "../../queries/getDraftProductOrder"
import {
  asInt,
  calculateLineSubtotal,
  formatMoney,
  parseGraphQLErrorCode,
  PRODUCT_ORDERS_FRANCHISEE,
} from "../../util"
import { useAuth } from "../../context/AuthContext"
import {
  Snack,
  ProductOrder,
  ProductOrderLineItem,
  DefaultPermission,
  ProductOrderStatus,
  TransactionLineItemFormInput,
} from "../../types"
import SaveButton from "../../components/SaveButton"
import PlaceOrderConfirmationDialog from "./components/PlaceOrderConfirmationDialog"

function Cart() {
  const { t } = useTranslation()
  const { hasPermissions, user } = useAuth()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [snack, setSnack] = useState<Snack>()
  const [cart, setCart] = useState<ProductOrder | null>()
  const [lineItems, setLineItems] = useState<TransactionLineItemFormInput[]>([])
  const [isConfirmPlaceOrderDialogOpen, setIsConfirmPlaceOrderDialogOpen] = useState<boolean>(false)
  const canEdit = hasPermissions?.([DefaultPermission.UpdateProductOrder])
  const cartItemCount =
    cart?.lineItems?.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0) ?? 0

  const { loading: cartLoading, refetch } = useQuery(GET_DRAFT_PRODUCT_ORDER, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      processData(data.getDraftProductOrder)
    },
  })

  const [editProductOrder, { loading: editProductOrderLoading }] = useMutation(EDIT_PRODUCT_ORDER, {
    onCompleted: (data) => {
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      refetch()
      if (data?.editProductOrder.productOrder.status !== "DRAFT") {
        setRedirectTo({ to: "/app/orders", replace: false })
      }
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [editProductOrderLineItem] = useMutation(EDIT_PRODUCT_ORDER_LINE_ITEM, {
    onCompleted: (data) => {
      processData(data.editProductOrderLineItem.productOrder)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  function processData(productOrder: ProductOrder) {
    setCart(productOrder)
    const editableLineItems: TransactionLineItemFormInput[] = productOrder.lineItems
      ?.map((li: ProductOrderLineItem) => {
        return {
          ...li,
          key: li.id,
          organizationItem: li.organizationItem,
          organizationItemId: li.organizationItem.id,
          quantity: String(li.quantity ?? ""),
          unitPrice: String(li.unitPrice ?? ""),
          taxRateGroup: li.taxRateGroup,
          lineItemDetails: undefined,
          total: li.total,
          errors: {
            showErrors: false,
          },
          isLoading: false,
        }
      })
      .sort((a, b) => a.number - b.number)
    setLineItems(editableLineItems)
  }

  const loading = cartLoading || editProductOrderLoading
  const disableSaveButtons = loading || !lineItems || lineItems.length === 0

  function handleSave(
    placeOrder: boolean,
    updatedLineItems: TransactionLineItemFormInput[],
    containsMHI = false,
    notes?: string
  ) {
    const sanitizedLineItems = updatedLineItems.map((li: TransactionLineItemFormInput) => {
      return {
        id: li.id,
        quantity: li.quantity ? asInt(li.quantity) : 0,
      }
    })

    editProductOrder({
      variables: {
        id: cart?.id,
        containsMHI,
        notes,
        status: placeOrder ? ProductOrderStatus.SUBMITTED : ProductOrderStatus.DRAFT,
        lineItems: sanitizedLineItems,
      },
    })
  }

  function updateLineItem(lineItem: TransactionLineItemFormInput) {
    const qty = asInt(lineItem.quantity)
    const idx = lineItems.findIndex((li: TransactionLineItemFormInput) => li.id === lineItem.id)
    lineItem.total = calculateLineSubtotal(lineItem.quantity, lineItem.unitPrice)
    lineItem.isLoading = true
    lineItems.splice(idx, 1, lineItem)
    lineItem.errors = {
      ...lineItem.errors,
      quantity: !qty || qty < 1 ? "required" : null,
    }
    const updatedLineItems = lineItems.concat([])
    setLineItems(updatedLineItems)
    editProductOrderLineItem({
      variables: {
        id: lineItem.id,
        productOrderId: cart?.id,
        quantity: asInt(lineItem.quantity),
      },
    })
  }

  function removeLineItem(lineItem: TransactionLineItemFormInput) {
    const updatedLineItems = lineItems.filter((li) => li.id !== lineItem.id)
    updatedLineItems.forEach((item, idx) => {
      item.number = idx + 1
    })
    setLineItems(updatedLineItems)
    handleSave(false, updatedLineItems)
  }

  function calculateOrderTotal(): number {
    return lineItems?.reduce((acc, curr) => acc + (curr.total ?? 0), 0) || 0
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(PRODUCT_ORDERS_FRANCHISEE.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={PRODUCT_ORDERS_FRANCHISEE}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[
              { to: PRODUCT_ORDERS_FRANCHISEE.path, titleKey: PRODUCT_ORDERS_FRANCHISEE.titleKey },
            ]}
            icon={PRODUCT_ORDERS_FRANCHISEE.icon}
            leafTitleKey="viewCart"
          />
          <Box
            id="grid-parent"
            sx={(theme) => {
              return {
                maxWidth: "1280px",
                margin: "0 auto",
                marginBottom: "10rem",
                display: "grid",
                gap: "1rem",
                gridTemplateColumns: "1fr",
                [theme.breakpoints.up("md")]: {
                  gridTemplateColumns: "2fr 1fr",
                },
              }
            }}
          >
            <Paper id="item-paper">
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10%",
                  }}
                >
                  <CircularProgress color="secondary" size={40} thickness={6.0} />
                </Box>
              ) : lineItems?.length === 0 ? (
                <Box
                  sx={{
                    height: "100%",
                    paddingTop: "1.875rem",
                    paddingBottom: "1.875rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <EmptyState title={t("page.cart.emptyState.title")} />
                </Box>
              ) : (
                <Box
                  sx={{
                    padding: "1rem",
                    paddingTop: "1.5rem",
                    paddingRight: "1.5rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {lineItems?.map((li: TransactionLineItemFormInput, idx: number) => (
                    <Fragment key={li.id}>
                      <CartLineItem
                        currencyCode={
                          cart?.currencyCode ?? user?.organization?.currencyCode ?? "USD"
                        }
                        isEditable={canEdit}
                        lineItem={li}
                        onChange={updateLineItem}
                        onDelete={() => removeLineItem(li)}
                      />
                      {idx < lineItems.length - 1 ? <Divider /> : null}
                    </Fragment>
                  ))}
                </Box>
              )}
            </Paper>
            <Box
              sx={(theme) => {
                return {
                  gridRowStart: 1,
                  [theme.breakpoints.up("md")]: {
                    gridColumnStart: 2,
                  },
                }
              }}
            >
              <Paper>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    padding: "1rem 1rem 2rem 1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <Box sx={{ fontWeight: "600", fontSize: "1.25rem" }}>
                        {t("subTotal")} ({cartItemCount}{" "}
                        {cartItemCount > 1 ? t("items").toLowerCase() : t("item").toLowerCase()})
                        {":"}
                      </Box>
                      <Box sx={{ fontWeight: "600", fontSize: "1.25rem" }}>
                        {formatMoney(cart?.currencyCode, calculateOrderTotal())}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        fontSize: "0.875rem",
                        color: (theme) => theme.fielderColors.mutedText,
                        margin: "1rem 0",
                      }}
                    >
                      {t("page.cart.taxesAndShippingNotIncluded")}
                    </Box>
                  </Box>
                  <Box
                    sx={(theme) => {
                      return {
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "1rem",
                        [theme.breakpoints.up("md")]: {
                          flexDirection: "row",
                        },
                      }
                    }}
                  >
                    <SaveButton
                      disabled={disableSaveButtons}
                      label={t("placeOrder") as string}
                      onClick={() => setIsConfirmPlaceOrderDialogOpen(true)}
                      sx={{ flex: 1 }}
                    />
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Box>
      </MainLayout>
      {isConfirmPlaceOrderDialogOpen && cart && user?.organization ? (
        <PlaceOrderConfirmationDialog
          onCancel={() => setIsConfirmPlaceOrderDialogOpen(false)}
          onSave={(containsMHI, specialInstructions) =>
            handleSave(true, lineItems, containsMHI, specialInstructions)
          }
          order={cart}
          orderTotal={calculateOrderTotal()}
          organization={user?.organization}
        />
      ) : null}
    </>
  )
}
export default Cart
