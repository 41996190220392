import gql from "graphql-tag"

export const GET_USER_BY_ID = gql`
  query GetUserById($id: ID!) {
    getUserById(id: $id) {
      id
      email
      firstName
      lastName
      jobTitle
      mobilePhoneNumber
      languageCode
      status
      roles {
        id
        name
        isStandard
      }
      avatar {
        id
        signedUrl
        updatedAt
      }
      organization {
        id
        tradeName
        languageCode
      }
      lastLogin
      createdAt
      createdBy {
        id
        email
      }
      updatedAt
      updatedBy {
        id
        email
      }
    }
  }
`
