import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { Navigate, NavigateProps } from "react-router-dom"
import { useMutation } from "@apollo/client"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"

import FranchiseeForm from "./components/FranchiseeForm"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import { CREATE_ORGANIZATION } from "~/queries/createOrganization"
import { parseGraphQLErrorCode, FRANCHISEES } from "~/util"
import { CreateOrganizationInput } from "~/types"

function CreateFranchiseePage() {
  const { t } = useTranslation()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()

  const [createOrganization, { loading, error }] = useMutation(CREATE_ORGANIZATION, {
    onCompleted: (data) => {
      const id = data?.createOrganization?.organization?.id
      const path = id ? `/app/franchisees/edit/${id}` : "/app/franchisees/list"
      setRedirectTo({
        to: path,
        replace: false,
        state: {
          snack: {
            messageKey: "messages.changesSaved",
            variant: "success",
          },
        },
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
    },
  })

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(FRANCHISEES.titleKey)} />
      <MainLayout activeSection={FRANCHISEES}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: FRANCHISEES.path, titleKey: FRANCHISEES.titleKey }]}
            icon={FRANCHISEES.icon}
            leafTitleKey="createFranchisee"
          />
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            padding: "0.5rem",
            paddingBottom: "8rem",
            maxWidth: "110rem",
            gap: "1rem",
            [theme.breakpoints.up("sm")]: {
              padding: "1rem 2rem",
              paddingBottom: "8rem",
            },
          })}
        >
          <FranchiseeForm
            handleSubmit={(newFranchisee: CreateOrganizationInput) => {
              createOrganization({
                variables: newFranchisee,
              })
            }}
            loading={loading}
            onCancel={() => {
              setRedirectTo({ to: "/app/franchisees/list", replace: true })
            }}
          />
          {error ? (
            <Box sx={{ marginBottom: "1rem", maxWidth: "55%", alignSelf: "flex-start" }}>
              <Alert severity="error" variant="filled">
                {`${t("error.general.title")} ${t(parseGraphQLErrorCode(error))}`}
              </Alert>
            </Box>
          ) : null}
        </Box>
      </MainLayout>
    </>
  )
}

export default CreateFranchiseePage
