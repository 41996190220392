import React from "react"
import { useTranslation } from "react-i18next"
import StaticField from "../../../components/StaticField"
import { OrganizationItem } from "../../../types"

interface BasicInfoStaticProps {
  readonly item: OrganizationItem
  readonly showBottomDivider?: boolean
}

function BasicInfoStatic({ item, showBottomDivider }: BasicInfoStaticProps) {
  const { t } = useTranslation()

  return (
    <>
      <StaticField label={t("itemCode")} showBottomDivider value={item.code} />
      <StaticField label={t("name")} showBottomDivider value={item.name} />
      <StaticField label={t("description")} showBottomDivider value={item.description} />
      <StaticField
        label={t("category")}
        showBottomDivider={showBottomDivider}
        value={item.category}
      />
    </>
  )
}

export default BasicInfoStatic
