import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"

export const ALL_JOBS = gql`
  query AllJobs(
    $filter: String
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $workflowId: ID
    $workflowStepIds: [ID!]
    $jobStatusIds: [ID!]
    $customerId: ID
    $franchiseeIds: [ID!]
  ) {
    allJobs(
      input: {
        filter: $filter
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        last: $last
        after: $after
        before: $before
      }
      jobFilter: {
        workflowId: $workflowId
        workflowStepIds: $workflowStepIds
        jobStatusIds: $jobStatusIds
        customerId: $customerId
        franchiseeIds: $franchiseeIds
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          number
          description
          customer {
            id
            name
            flags {
              id
              name
              colorCode
             }
          }
          categories {
            id
            name
          }
          workflow {
            id
            name
          }
          workflowStep {
            id
            jobStatus {
              id
              name
              lightColor
              mediumColor
              darkColor
            }
          }          
          address {
            ...FullAddress
          }
          siteContact {
            id
            firstName
            lastName
            email
            phoneNumber
          }
          createdAt
        }
      }
    }
  }
  ${FullAddress}
`
