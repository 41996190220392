import React from "react"
import { useTranslation } from "react-i18next"
import { Draggable } from "react-beautiful-dnd"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import { ProductOrder, User } from "../types"
import { formatMoney, formatPersonName } from "../util"
import Tooltip from "@mui/material/Tooltip"
import Zoom from "@mui/material/Zoom"
import UserAvatar from "./UserAvatar"

function getUserInitials(user: User) {
  if (!user) {
    return null
  }

  let initials = ""
  if (user.firstName) {
    initials = user.firstName.charAt(0)
  }

  if (user.lastName) {
    initials = `${initials}${user.lastName.charAt(0)}`
  }

  return initials.toUpperCase()
}

interface Props {
  readonly index: number
  readonly isDragDisabled: boolean
  readonly productOrder: ProductOrder
  readonly onClick: (productOrder: ProductOrder) => void
}

function ProductOrderCard({ index, isDragDisabled, productOrder, onClick }: Props) {
  const { t } = useTranslation()

  const accountManager = productOrder.organization?.accountManager

  return (
    <Draggable draggableId={productOrder.id} index={index} isDragDisabled={isDragDisabled}>
      {(provided) => (
        <Paper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={onClick}
          ref={provided.innerRef}
          sx={{
            margin: "0.5rem",
            marginBottom: "0.75rem",
            padding: "0.5rem",
            backgroundColor: "white",
            color: "#121212",
            borderRadius: "4px",
            cursor: isDragDisabled ? "pointer" : "inherit",
          }}
        >
          <Box sx={{ fontWeight: "600", fontSize: "0.9rem" }}>
            {t("order")} #{productOrder.number}
          </Box>
          <Box sx={{ fontSize: "0.8rem" }}>{productOrder.organization.tradeName}</Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              {t("total")}: {formatMoney(productOrder.currencyCode, productOrder.total)}
            </Box>

            {accountManager ? (
              <Tooltip
                TransitionComponent={Zoom}
                disableInteractive={false}
                enterDelay={500}
                leaveDelay={200}
                title={`Account Manager: ${formatPersonName(
                  productOrder.organization?.accountManager
                )}`}
              >
                <Box
                  sx={{
                    backgroundColor: "#efefef",
                    borderRadius: "14px",
                    width: "24px",
                    height: "24px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {accountManager?.avatar ? (
                    <UserAvatar size="24px" user={accountManager} />
                  ) : (
                    <Box sx={{ fontWeight: "600", fontSize: "0.875rem" }}>
                      {getUserInitials(accountManager)}
                    </Box>
                  )}
                </Box>
              </Tooltip>
            ) : null}
          </Box>
          {productOrder.isArchived ? (
            <Box
              style={{
                fontStyle: "italic",
                fontSize: "0.6875rem",
                color: "#DA1505",
                marginTop: "0.25rem",
              }}
            >
              {t("thisOrderHasBeenArchived")}
            </Box>
          ) : null}
        </Paper>
      )}
    </Draggable>
  )
}

export default ProductOrderCard
