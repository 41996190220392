import React from "react"
import { useTranslation } from "react-i18next"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import Box from "@mui/material/Box"

export default function NoResults() {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        fontSize: "1rem",
        fontStyle: "normal",
        fontWeight: "normal",
        fontFamily: "inherit",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.25rem",
        padding: "4rem 2rem",
        color: (theme) => theme.fielderColors.mutedText,
      }}
    >
      <SearchOutlinedIcon sx={{ fontSize: "2rem" }} />
      <Box
        sx={{
          fontSize: "1rem",
          fontWeight: "600",
        }}
      >
        {t("component.search.noResults.title")}
      </Box>
      <Box
        sx={{
          fontSize: "0.8rem",
        }}
      >
        {t("component.search.noResults.description")}
      </Box>
    </Box>
  )
}
