import React, { useEffect, useRef, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import { isBlank } from "../../../util"
import { OrganizationItemList } from "../../../types"

const EDIT_ORGANIZATION_ITEM_LIST = gql`
  mutation EditOrganizationItemList($id: ID!, $name: String) {
    editOrganizationItemList(input: { id: $id, name: $name }) {
      organizationItemList {
        id
        name
      }
    }
  }
`

interface Props {
  list: OrganizationItemList
  onCancel: () => void
  onClose: () => void
  onListUpdated?: (list: OrganizationItemList) => void
  open: boolean
}

export default function EditListDialog({ open, list, onCancel, onClose, onListUpdated }: Props) {
  const { t } = useTranslation()
  const [listName, setListName] = useState<string>(() => list.name)
  const [errors, setErrors] = useState<{ name: string | null }>({ name: null })
  const inputRef = useRef(null)

  useEffect(() => {
    setListName(list.name)
  }, [list.name])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 10)

    return () => clearTimeout(timeout)
  }, [open])

  const [editOrganizationItemList, { loading }] = useMutation(EDIT_ORGANIZATION_ITEM_LIST, {
    onCompleted: (data) => {
      setListName("")
      onListUpdated?.(data.editOrganizationItemList.organizationItemList)
    },
  })

  function handleSubmit() {
    if (isBlank(listName)) {
      setErrors({
        ...errors,
        name: "component.organizationItemList.editListDialog.validation.name.required",
      })
    } else {
      editOrganizationItemList({
        variables: {
          id: list.id,
          name: listName,
        },
      })
    }
  }

  return (
    <Dialog
      aria-labelledby="edit-list-dialog-title"
      disableRestoreFocus
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <DialogTitle
        id="edit-list-dialog-title"
        sx={{
          py: "1rem",
          px: "2rem",
          backgroundColor: (theme) => theme.fielderColors.white,
        }}
      >
        {t("component.organizationItemList.editListDialog.title")}
      </DialogTitle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.875rem",
          padding: "1rem 2rem 4rem 2rem",
        }}
      >
        <Box
          sx={(theme) => {
            return {
              display: "flex",
              flexDirection: "column",
              color: errors.name ? theme.fielderColors.error : theme.fielderColors.text,
            }
          }}
        >
          <label htmlFor="listName">{t("listName")}</label>
          <TextField
            autoFocus
            error={Boolean(errors.name)}
            id="listName"
            inputRef={inputRef}
            onChange={(e) => {
              if (!isBlank(e.target.value)) {
                setErrors({ ...errors, name: null })
              } else {
                setErrors({
                  ...errors,
                  name: "component.organizationItemList.editListDialog.validation.name.required",
                })
              }
              setListName(e.target.value)
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit()
              }
            }}
            type="text"
            value={listName}
          />
          <Box
            component="div"
            sx={(theme) => {
              return {
                color: theme.fielderColors.error,
                height: "1.5rem",
              }
            }}
          >
            {errors.name ? t(errors.name) : " "}
          </Box>
        </Box>
      </Box>
      <DialogActions
        sx={{
          px: "2rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Button
            color="secondary"
            data-testid="cancelButton"
            disabled={loading}
            onClick={onCancel}
            sx={(theme) => ({
              [theme.breakpoints.down("xs")]: {
                width: "100%",
              },
            })}
            variant="outlined"
          >
            {t("cancel")}
          </Button>
          <Button
            color="primary"
            data-testid="submitButton"
            disabled={loading}
            onClick={handleSubmit}
            sx={(theme) => ({
              fontWeight: "bold",
              [theme.breakpoints.down("xs")]: {
                marginTop: "0.625rem",
                width: "100%",
              },
            })}
            variant="contained"
          >
            {loading ? (
              <CircularProgress color="secondary" size={20} thickness={6.0} />
            ) : (
              <Box>{t("editList")}</Box>
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
