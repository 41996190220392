import isNil from "lodash/isNil"
import type {
  Address,
  Job,
  JobAssignment,
  User,
} from "../types/apiTypes"
import { JobAssignmentStatus } from "../types/apiTypes"
import { type MapMarker, MapMarkerType } from '../types/appTypes'

function getUserHqMarker(user?: Partial<User> | null): MapMarker | null {
  if (user?.organization?.address) {
    const {
      organization: { address },
    } = user
    return getAddressMarker(address, MapMarkerType.HQ)
  }
  return null
}

function getDefaultMarkers(user: Partial<User>, job?: Partial<Job> | null): MapMarker[] {
  const userHqMarker = getUserHqMarker(user)
  const defaultMarkers = userHqMarker ? [userHqMarker] : []
  if (job?.address && !isNil(job.address.latitude) && !isNil(job.address.longitude)) {
    const jobMarker = getAddressMarker(job.address, MapMarkerType.JOB)
    jobMarker && defaultMarkers.push(jobMarker)
  }
  return defaultMarkers
}

function getAddressMarker(address: Partial<Address>, markerType: MapMarkerType): MapMarker | null {
  if (isNil(address?.latitude) || isNil(address?.longitude)) {
    return null
  } else {
    return {
      lat: address.latitude,
      lng: address.longitude,
      markerType,
      icon: `/icons/mapMarker_${markerType}.png`,
    }
  }
}

function getJobAssignmentMarker(jobAssignment: JobAssignment): MapMarker | null {
  if (
    !jobAssignment?.job?.address ||
    isNil(jobAssignment.job.address?.latitude) ||
    isNil(jobAssignment.job.address?.longitude)
  ) {
    return null
  } else {
    let markerType = MapMarkerType.ESTIMATE_TENTATIVE
    if (jobAssignment.status === JobAssignmentStatus.COMPLETED) {
      markerType = MapMarkerType.ESTIMATE_COMPLETED
    } else if (jobAssignment.status === JobAssignmentStatus.CONFIRMED) {
      markerType = MapMarkerType.ESTIMATE_CONFIRMED
    }
    return getAddressMarker(jobAssignment.job.address, markerType)
  }
}

export { getAddressMarker, getDefaultMarkers, getJobAssignmentMarker, getUserHqMarker }
