import gql from "graphql-tag"

export const SEND_INVOICE_TO_PLUGIN = gql`
  mutation SendInvoiceToPlugin($invoiceId: ID!) {
    sendInvoiceToPlugin(input: { invoiceId: $invoiceId }) {
      wasSent
      pluginProvider {
        id
        name
        category
      }
      providerInvoiceId
    }
  }
`
