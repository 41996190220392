/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { useReducer } from "react"
import { nanoid } from "nanoid"
import { asInt, isBlank, isNumeric } from "../../../util"
import { OrganizationItem } from "../../../types"

export interface Errors {
  organizationItem?: string | null
  organizationItemId?: string | null
  quantity?: string | null
  unitSalePrice?: string | null
  unitSalePriceMarkup?: string | null
  showErrors: boolean
}
export interface Component {
  key: string
  value: {
    organizationItemId: string
    quantity: string
    enableUnitSalePriceOverride: boolean
    unitSalePrice: string
    organizationItem: OrganizationItem | null
    number: number
  }
  errors: Errors
  touched: boolean
}

interface Payload {
  fieldName: string
  value: string | boolean
}
type Action =
  | {
      type: "SET_FIELD"
      payload: Payload
    }
  | {
      type: "ADD_COMPONENT"
      payload: Component
    }
  | {
      type: "UPDATE_COMPONENT"
      payload: Component
    }
  | {
      type: "REMOVE_COMPONENT"
      payload: Component
    }
  | {
      type: "CANCEL"
    }

interface StateField {
  value: string | boolean | number
  error: string | null
  touched: boolean
}

export type UnitSalePriceType = "dynamic" | "static"

export interface ItemFormState {
  organizationItem: OrganizationItem // just a ref to the original item; should not be mutated
  id?: string | null | undefined
  isDirty: boolean
  isCanceled: boolean
  code: StateField
  name: StateField
  description: StateField
  category: StateField
  isTracked: boolean
  qtyOnHand: StateField
  reorderPoint: StateField
  reorderQty: StateField
  isDynamicallyPriced: boolean
  unitSalePrice: StateField
  unitSalePriceMarkup: StateField
  wholesalePrice: StateField
  showComponentsOnTransactions: StateField
  enableUnitSalePriceOverrides: StateField
  components: Component[]
  isActive: boolean
}

export interface IItemFormContext {
  state: ItemFormState
  dispatch: React.Dispatch<Action>
}

export const ItemFormContext = React.createContext<IItemFormContext | null>(null)

function createBlankComponent(enableUnitSalePriceOverride: boolean, number: number): Component {
  return {
    key: nanoid(),
    value: {
      organizationItemId: "",
      quantity: "",
      enableUnitSalePriceOverride,
      unitSalePrice: "",
      organizationItem: null,
      number: number,
    },
    errors: {
      organizationItemId: null,
      quantity: null,
      unitSalePrice: null,
      organizationItem: null,
      showErrors: false,
    },
    touched: false,
  }
}

function validateField(payload: Payload): string | null {
  switch (payload.fieldName) {
    case "code":
    case "name":
      return isBlank(payload.value)
        ? `component.itemBasicInfoForm.validation.${payload.fieldName}.required`
        : null
    default:
      return null
  }
}

function reducer(state: ItemFormState, action: Action): ItemFormState {
  switch (action.type) {
    case "SET_FIELD": {
      const payload = action.payload as Payload
      switch (payload.fieldName) {
        case "isDynamicallyPriced":
          return {
            ...state,
            isDirty: true,
            isDynamicallyPriced: payload.value as boolean,
          }
          break
        case "isActive":
          return {
            ...state,
            isDirty: true,
            isActive: payload.value as boolean,
          }
        case "isTracked":
          return {
            ...state,
            isDirty: true,
            isTracked: payload.value as boolean,
          }
        case "enableUnitSalePriceOverrides": {
          const newState = {
            ...state,
            isDirty: true,
            enableUnitSalePriceOverrides: {
              value: payload.value as boolean,
              touched: true,
              error: null,
            },
            components: state.components.map((c) => ({
              ...c,
              value: {
                ...c.value,
                enableUnitSalePriceOverride: payload.value as boolean,
              },
            })),
          }
          return newState
        }
        default:
          return {
            ...state,
            isDirty: true,
            [payload.fieldName]: {
              value: payload.value,
              touched: true,
              error: validateField(payload),
            },
          }
      }
    }
    case "ADD_COMPONENT": {
      return {
        ...state,
        isDirty: true,
        components: [
          ...state.components,
          createBlankComponent(
            state.enableUnitSalePriceOverrides.value as boolean,
            state.components.length + 1
          ),
        ],
      }
    }
    case "UPDATE_COMPONENT": {
      const component = action.payload as Component
      if (!component) {
        return state
      }

      const idx = state.components.findIndex((c) => c.key == component.key)
      state.components.splice(idx, 1, component)
      const qty = component?.value?.quantity ? asInt(component.value.quantity) : null
      component.errors = {
        ...component.errors,
        organizationItem: isBlank(component.value.organizationItemId) ? "required" : null,
        quantity: !qty || qty < 0 ? "required" : null,
      }
      component.touched = true
      return {
        ...state,
        isDirty: true,
        components: state.components.concat([]),
      }
    }
    case "REMOVE_COMPONENT": {
      if (state.components.length > 1) {
        const component = action.payload as Component
        const updatedComponents = state.components.filter((c: Component) => c.key !== component.key)
        return {
          ...state,
          isDirty: true,
          components: updatedComponents,
        }
      } else {
        return state
      }
    }
    case "CANCEL": {
      return {
        ...state,
        isDirty: false, // set this to false to prevent the confirmation prompt
        isCanceled: true,
      }
    }
    default:
      throw new Error(`Unhandled action: ${action}`)
  }
}

export interface ItemFormProviderProps {
  readonly organizationItem: OrganizationItem
  readonly children: React.ReactNode
}

export function ItemFormProvider({ organizationItem, children }: ItemFormProviderProps) {
  const enableUnitSalePriceOverrides = organizationItem?.components
    ? organizationItem?.components.some((c) => c.enableUnitSalePriceOverride)
    : true

  const defaultState: ItemFormState = {
    organizationItem,
    id: organizationItem?.id,
    isDirty: false,
    isCanceled: false,
    isActive:
      typeof organizationItem?.isActive !== "undefined"
        ? Boolean(organizationItem?.isActive)
        : true,
    code: {
      value: organizationItem?.code ?? "",
      error: null,
      touched: false,
    },
    name: {
      value: organizationItem?.name ?? "",
      error: null,
      touched: false,
    },
    description: {
      value: organizationItem?.description ?? "",
      error: null,
      touched: false,
    },
    category: {
      value: organizationItem?.category ?? "",
      error: null,
      touched: false,
    },
    isTracked:
      typeof organizationItem?.isTracked !== "undefined"
        ? Boolean(organizationItem?.isTracked)
        : true,
    qtyOnHand: {
      value: isNumeric(organizationItem?.qtyOnHand) ? (organizationItem.qtyOnHand as number) : "",
      error: null,
      touched: false,
    },
    reorderPoint: {
      value: isNumeric(organizationItem?.reorderPoint)
        ? (organizationItem.reorderPoint as number)
        : "",
      error: null,
      touched: false,
    },
    reorderQty: {
      value: isNumeric(organizationItem?.reorderQty) ? (organizationItem.reorderQty as number) : "",
      error: null,
      touched: false,
    },
    unitSalePrice: {
      value: isNumeric(organizationItem?.unitSalePrice)
        ? (organizationItem.unitSalePrice as number)
        : "",
      error: null,
      touched: false,
    },
    unitSalePriceMarkup: {
      value: isNumeric(organizationItem?.unitSalePriceMarkup)
        ? (organizationItem.unitSalePriceMarkup as number) * 100
        : "",
      error: null,
      touched: false,
    },
    isDynamicallyPriced: Boolean(organizationItem?.isDynamicallyPriced),
    wholesalePrice: {
      value: isNumeric(organizationItem?.wholesalePrice)
        ? (organizationItem?.wholesalePrice as number)
        : "",
      error: null,
      touched: false,
    },
    showComponentsOnTransactions: {
      value: !!organizationItem?.showComponentsOnTransactions,
      error: null,
      touched: false,
    },
    enableUnitSalePriceOverrides: {
      value: enableUnitSalePriceOverrides,
      error: null,
      touched: false,
    },
    components: organizationItem?.components
      ? organizationItem.components.map((c) => ({
          key: nanoid(),
          value: {
            id: c.id,
            organizationItemId: c.organizationItem.id,
            organizationItem: c.organizationItem,
            quantity: `${c.quantity}`,
            enableUnitSalePriceOverride: c.enableUnitSalePriceOverride,
            unitSalePrice: `${c.unitSalePrice}`,
          },
          errors: {
            organizationItemId: null,
            quantity: null,
            unitSalePrice: null,
            organizationItem: null,
            showErrors: false,
          },
          touched: false,
        }))
      : [
          createBlankComponent(enableUnitSalePriceOverrides),
          createBlankComponent(enableUnitSalePriceOverrides),
        ],
  }

  const [state, dispatch] = useReducer(reducer, defaultState)

  return <ItemFormContext.Provider value={{ state, dispatch }}>{children}</ItemFormContext.Provider>
}
