import React from "react"

function WhatIsFielder() {
  return (
    <div>
      <h3>What is Fielder?</h3>
      <p>
        Fielder is a platform for field-service franchise businesses. It is focused on helping
        franchisees manage daily operations to keep their businesses running as smoothly as
        possible. Fielder currently provides the following main features:
      </p>
      <ul>
        <li>
          <strong>Collect Estimate Requests:</strong> We provide a form that you can integrate into
          your website to collect information from potential customers.
        </li>
        <li>
          <strong>Manage Jobs:</strong> The primary concept in Fielder is the Job, which represents
          a single billable service that you provide to your customers. Fielder can help you manage
          all the work that goes into a Job, such as: initial information gathering, quoting,
          scheduling & dispatching, and invoicing.
        </li>
        <li>
          <strong>Product List (Inventory Management):</strong> Franchisees typically acquire the
          products that they sell from their franchisor. Fielder gives the franchisor the ability to
          manage their product list so that franchisees always see up-to-date information about the
          product line. Fielder also gives each franchisee the ability to manage their own
          inventory/stock data (even for items that the franchisor doesn’t provide). Franchisees can
          place orders with the franchisor to replenish their inventory.
        </li>
        <li>
          <strong>Customer Management:</strong> Fielder provides franchisees with a basic Customer
          Relationship Management (CRM) tool. Every Job you create has an associated Customer
          record. A Customer record helps you keep track of things like the customer’s contact
          information. There are two types of Customer in Fielder: Individual and Business. The
          Individual type is intended for when you’re dealing directly with a homeowner, where there
          is a single individual who is both the job site contact and billing contact. The Business
          type is meant for when you’re dealing with a contractor company. In that case, there may
          be multiple people with different roles that you need to interact with. For example, one
          person may be the job site contact, while a different person is responsible for paying
          invoices. For Business customers, Fielder gives you the ability to add as many contacts as
          you need. Whether a customer is an Individual or a Business, Fielder gives you the history
          of Jobs for that customer over time.
        </li>
        <li>
          <strong>Mobile App for Field Staff:</strong> Fielder comes with a mobile application
          designed to help your field technicians. It makes it easy for them to see what jobs they
          have assigned to them on a particular day, where the jobs are, what materials they’ll need
          to bring along, and much more.
        </li>
      </ul>
    </div>
  )
}

export default WhatIsFielder
