import gql from "graphql-tag"
import FullItem from "./fragments/FullItem"

export const GET_ITEM_BY_ID = gql`
  query GetItemById($id: ID!) {
    getItemById(id: $id) {
      ...FullItem
    }
  }
  ${FullItem}
`
