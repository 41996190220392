import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import SelectorField from "../../../components/SelectorField"
import MenuItem from "@mui/material/MenuItem"
import { ALL_WORK_ORDERS_FOR_JOB } from "../../../queries/allWorkOrdersForJob"
import { formatMoney, getJobDocumentDisplayNumber, NOT_SPECIFIED } from "../../../util"
import { WorkOrder } from "../../../types"

interface WorkOrderOptionProps {
  readonly workOrder: WorkOrder
}

function WorkOrderOption({ workOrder }: WorkOrderOptionProps) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={classes.optionLeftDetail}>
        <Box>
          {t("component.loadFromWorkOrderDialog.workOrderNbr")}
          {getJobDocumentDisplayNumber(workOrder)}
        </Box>
      </Box>
      <Box sx={classes.optionRightDetail}>
        <Box>
          {t("total")}: {formatMoney(workOrder.currencyCode, workOrder.total ?? NOT_SPECIFIED)}
        </Box>
      </Box>
    </Box>
  )
}

interface Props {
  readonly jobId: string
  readonly onCancel: () => void
  readonly onSelectWorkOrder: (workOrder: WorkOrder) => void
  readonly variant: "ESTIMATE" | "INVOICE"
}

function LoadFromWorkOrderDialog({ jobId, onCancel, onSelectWorkOrder, variant }: Props) {
  const { t } = useTranslation()
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrder | null>(null)

  const { loading, data } = useQuery(ALL_WORK_ORDERS_FOR_JOB, {
    variables: {
      jobId,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data?.getJobById?.workOrders?.length === 1) {
        const workOrder = data.getJobById.workOrders[0]
        setSelectedWorkOrder(workOrder)
      }
    },
  })

  const workOrders = data?.getJobById?.workOrders ?? []

  function handleSelect() {
    selectedWorkOrder && onSelectWorkOrder(selectedWorkOrder)
  }

  const selectedValue = selectedWorkOrder
    ? selectedWorkOrder.id
    : workOrders && workOrders?.length === 1
      ? workOrders[0].id
      : ""

  return (
    <Dialog
      aria-labelledby="add-lines-from-workOrder-dialog"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open
    >
      <DialogTitle
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {t("component.loadFromWorkOrderDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            paddingBottom: 0,
            paddingTop: "1.5rem",
            px: "1rem",
          }}
        >
          <Box
            sx={{
              color: (theme) => theme.fielderColors.mutedText,
              marginTop: "0.625rem",
              marginBottom: "0.625rem",
            }}
          >
            {variant === "ESTIMATE"
              ? t("component.loadFromWorkOrderDialog.description.estimate")
              : t("component.loadFromWorkOrderDialog.description.invoice")}
          </Box>
          <Box sx={classes.fieldContainer}>
            {workOrders?.length === 0 ? (
              <Box
                sx={(theme) => ({
                  color: theme.fielderColors.mutedText,
                  border: `1px solid ${theme.fielderColors.mutedText}`,
                  borderRadius: "4px",
                  padding: "0.625rem",
                  backgroundColor: "#fff",
                })}
              >
                {t("component.loadFromWorkOrderDialog.noResults")}
              </Box>
            ) : (
              <SelectorField
                label={t("component.loadFromWorkOrderDialog.selectLabel") as string}
                name="workOrder"
                onChange={(selection) => {
                  const workOrder = workOrders?.find((t) => t.id === selection.id)
                  setSelectedWorkOrder(workOrder)
                }}
                options={workOrders}
                renderOption={(workOrder) => {
                  return (
                    <MenuItem key={workOrder.id} value={workOrder.id}>
                      <WorkOrderOption workOrder={workOrder} />
                    </MenuItem>
                  )
                }}
                renderValue={(workOrderId) => {
                  const workOrder = workOrders.find((q) => q.id === workOrderId)
                  return <WorkOrderOption workOrder={workOrder} />
                }}
                value={selectedValue}
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: "1.5rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          data-testid="loadButton"
          disabled={loading || !selectedWorkOrder}
          onClick={handleSelect}
          variant="contained"
        >
          {loading ? (
            <CircularProgress size={20} thickness={6.0} />
          ) : (
            <Box>{t("component.loadFromWorkOrderDialog.saveButtonLabel")}</Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const classes = {
  fieldContainer: {
    marginTop: "1.25rem",
  },
  optionLeftDetail: {
    display: "flex",
    flexDirection: "column",
  },
  optionLeftDetailStatus: {
    fontSize: "0.75rem",
  },
  optionRightDetail: {
    textAlign: "right",
    alignSelf: "center",
  },
} as const

export default LoadFromWorkOrderDialog
