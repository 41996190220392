import gql from "graphql-tag"
import FullCustomer from "./fragments/FullCustomer"

export const GET_CUSTOMER_BY_ID = gql`
  query GetCustomerById($id: ID!) {
    getCustomerById(id: $id) {
      ...FullCustomer
    }
  }
  ${FullCustomer}
`

