import React from "react"
import Box from "@mui/material/Box"
import Switch from "@mui/material/Switch"

interface Props {
  readonly label: string
  readonly onChange: (value: boolean) => void
  readonly value: boolean
}

export default function CustomerViewSettingFormField({ label, onChange, value }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0",
        paddingLeft: "0.5rem",
        paddingRight: "1rem",
        backgroundColor: "#f5f5f5",
        borderRadius: "4px",
        margin: "0.25rem 0.875rem",
      }}
    >
      <Box sx={{ fontSize: "0.875rem", fontWeight: "600" }}>{label}</Box>
      <Switch
        checked={value}
        color="success"
        onChange={(e) => {
          onChange(Boolean(e.target.checked))
        }}
      />
    </Box>
  )
}
