import React from "react"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"

interface PermissionToggleProps {
  checked: boolean
  disabled?: boolean
  description?: string
  indeterminate?: boolean
  onChange: (id: string, checked: boolean) => void
  permissionOptionId: string
  title: string
}

function PermissionToggle({
  checked,
  disabled,
  indeterminate,
  permissionOptionId,
  title,
  description,
  onChange,
}: PermissionToggleProps) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate}
          onChange={(e) => onChange(permissionOptionId, e.target.checked)}
          sx={{
            padding: "0.1875rem",
            marginLeft: "0.625rem",
            marginRight: "0.625rem",
            fontWeight: "bold",
          }}
        />
      }
      label={
        <span css={{ display: "flex", flexDirection: "column" }}>
          <span
            css={{
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            {title}
          </span>
          {description && (
            <span
              css={{
                color: "#0F07087F",
                fontSize: "0.8rem",
                fontWeight: "normal",
                lineHeight: "1rem",
              }}
            >
              {description}
            </span>
          )}
        </span>
      }
      sx={{
        fontSize: "0.9rem",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        hyphens: "auto",
      }}
    />
  )
}

export default PermissionToggle
