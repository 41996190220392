import gql from "graphql-tag"

export default gql`
  fragment FullEstimateLineItemDetails on EstimateLineItemDetail {
    id
    number
    quantity
    unitPrice
    organizationItem {
        id
        code
        name
    }
  }
`
