import gql from "graphql-tag"

export const CREATE_CUSTOMER_FLAG = gql`
  mutation CreateCustomerFlag($name: String!, $description: String, $colorCode: String) {
    createCustomerFlag(input: { name: $name, description: $description, colorCode: $colorCode }) {
      customerFlag {
        id
        name
        description
        colorCode
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`
