import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { SxProps, Theme } from "@mui/material/styles"
import { SystemStyleObject } from "@mui/system"
import { Notification } from "~/types/apiTypes"
import { createDayJS } from "~/util"

interface Props {
  readonly onClick: () => void
  readonly notification: Notification
  readonly sx?: SxProps<Theme>
}

function NotificationRow({ onClick, notification, sx }: Props) {
  const { t } = useTranslation("common")

  return (
    <Box
      onClick={onClick}
      sx={(theme) => {
        return {
          cursor: "pointer",
          flex: 1,
          padding: "0.5rem 1rem",
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          "&:hover": {
            backgroundColor: theme.fielderColors.lightGrey,
          },
          ...(typeof sx === "function" ? sx(theme) : sx ?? {}),
        } as SystemStyleObject<Theme>
      }}
    >
      <Box
        sx={{
          marginTop: "0.4rem",
          width: "0.4rem",
          height: "0.4rem",
          borderRadius: "50%",
          backgroundColor: (theme) =>
            notification.dateRead ? "transparent" : theme.fielderColors.brandPrimary,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: "0.3rem",
          marginRight: "0.4rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "end",
              gap: "0.5rem",
              flex: 1,
            }}
          >
            <Box sx={{ fontSize: "0.875rem", fontWeight: "700" }}>
              {t(`component.notificationRow.notificationType.${notification.type.name}`)}
            </Box>
            <Box
              sx={{
                fontSize: "0.675rem",
                color: (theme) => theme.fielderColors.mutedText,
                paddingBottom: "0.1rem",
              }}
            >
              {createDayJS(notification.createdAt)?.fromNow()}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.125rem" }}>
          {/* {notification.title ? <Box sx={{ fontSize: "0.875rem" }}>{notification.title}</Box> : null} */}
          <Box
            sx={{
              fontSize: "0.75rem",
              color: (theme) => theme.fielderColors.mutedText,
            }}
          >
            {notification.message}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NotificationRow
