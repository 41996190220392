import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"

import {
  Organization,
  OrganizationFormInput,
  LanguageCode,
  OrganizationDefaultEstimateSettings,
  OrganizationDefaultInvoiceSettings,
} from "~/types"
import FieldHelperText from "~/components/FieldHelperText"
import { usePrompt } from "~/hooks/usePrompt"
import SaveButton from "~/components/SaveButton"
import LanguageSelect from "~/components/LanguageSelect"
import DefaultEstimateSettingsForm from "./DefaultEstimateSettingsForm"
import DefaultInvoiceSettingsForm from "./DefaultInvoiceSettingsForm"

interface Props {
  readonly organization: Organization
  readonly loading?: boolean
  readonly onCancel: () => void
  readonly onSave: (organization: OrganizationFormInput) => void
}

function OrganizationInfoForm({ loading = false, organization, onCancel, onSave }: Props) {
  const { t } = useTranslation()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [languageCode, setLanguageCode] = useState<LanguageCode | undefined | null>(
    organization.languageCode
  )
  const [allowBundleModsOnTxns, setAllowBundleModsOnTxns] = useState<boolean>(
    organization.allowBundleModsOnTxns ?? false
  )
  const [allowJobAssignmentOverlap, setAllowJobAssignmentOverlap] = useState<boolean>(
    organization.allowJobAssignmentOverlap ?? false
  )
  const [bccJobEmailToOrganizationEmail, setBccJobEmailToOrganizationEmail] = useState<boolean>(
    organization.bccJobEmailToOrganizationEmail ?? false
  )
  const [defaultEstimateSettings, setDefaultEstimateSettings] =
    useState<OrganizationDefaultEstimateSettings>(() => {
      const { __typename, ...settings } = organization.defaultEstimateSettings
      return settings
    })
  const [defaultInvoiceSettings, setDefaultInvoiceSettings] =
    useState<OrganizationDefaultInvoiceSettings>(() => {
      const { __typename, ...settings } = organization.defaultInvoiceSettings
      return settings
    })
  const [errors, setErrors] = useState<{
    languageCode?: string | null
  }>({
    languageCode: null, // required
  })

  const handleBlurLanguageCode = useCallback(() => {
    if (!languageCode) {
      setErrors({
        ...errors,
        languageCode: t("component.organizationDetails.validation.languageCode.required"),
      })
    }
  }, [errors, languageCode, t])

  const handleChangeLanguageCode = useCallback(
    (selection?: LanguageCode | null) => {
      setIsDirty(true)
      setLanguageCode(selection)
      setErrors({
        ...errors,
        languageCode: null,
      })
    },
    [errors]
  )

  function isValid() {
    return !errors.languageCode
  }

  function handleSubmit() {
    onSave({
      id: organization.id,
      languageCode: languageCode ?? undefined,
      allowBundleModsOnTxns,
      allowJobAssignmentOverlap,
      bccJobEmailToOrganizationEmail,
      defaultEstimateSettings,
      defaultInvoiceSettings,
    })
  }

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  return (
    <Box>
      <Box sx={classes.fieldContainer}>
        <LanguageSelect
          error={Boolean(errors.languageCode)}
          label={t("language") as string}
          name="language"
          onBlur={handleBlurLanguageCode}
          onChange={handleChangeLanguageCode}
          required
          value={languageCode}
        />
        {errors.languageCode ? (
          <FieldHelperText error message={errors.languageCode} />
        ) : (
          <FieldHelperText message={t("page.organizationDetails.helperText.languageCode")} />
        )}
      </Box>
      {organization.level >= 2 ? (
        <>
          <Box sx={classes.fieldContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowJobAssignmentOverlap}
                  name="allowJobAssignmentOverlap"
                  onChange={(e) => {
                    setAllowJobAssignmentOverlap(e.target.checked)
                    setIsDirty(true)
                  }}
                />
              }
              label={t("page.organizationDetails.allowJobAssignmentOverlap")}
            />
            <FieldHelperText
              message={t("page.organizationDetails.helperText.allowJobAssignmentOverlap")}
            />
          </Box>
          <Box sx={classes.fieldContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowBundleModsOnTxns}
                  name="allowBundleModsOnTxns"
                  onChange={(e) => {
                    setAllowBundleModsOnTxns(e.target.checked)
                    setIsDirty(true)
                  }}
                />
              }
              label={t("page.organizationDetails.allowBundleModsOnTxns")}
            />
            <FieldHelperText
              message={t("page.organizationDetails.helperText.allowBundleModsOnTxns")}
            />
          </Box>
        </>
      ) : null}
      <Box sx={classes.fieldContainer}>
        <FormControlLabel
          control={
            <Checkbox
              checked={bccJobEmailToOrganizationEmail}
              name="bccJobEmailToOrganizationEmail"
              onChange={(e) => {
                setBccJobEmailToOrganizationEmail(e.target.checked)
                setIsDirty(true)
              }}
            />
          }
          label={t("page.organizationDetails.bccJobEmailToOrganizationEmail")}
        />
        <FieldHelperText
          message={t("page.organizationDetails.helperText.bccJobEmailToOrganizationEmail")}
        />
      </Box>
      <Box sx={classes.fieldContainer}>
        <DefaultEstimateSettingsForm
          defaultEstimateSettings={defaultEstimateSettings}
          onChange={setDefaultEstimateSettings}
        />
      </Box>
      <Box sx={classes.fieldContainer}>
        <DefaultInvoiceSettingsForm
          defaultInvoiceSettings={defaultInvoiceSettings}
          onChange={setDefaultInvoiceSettings}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "3rem",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton disabled={!isValid()} loading={loading} onClick={handleSubmit} />
      </Box>
    </Box>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
} as const

export default OrganizationInfoForm
