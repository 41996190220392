import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"

interface Props {
  readonly label: string
  readonly value: boolean
}

export default function CustomerViewSettingStatic({ label, value }: Props) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0.5rem",
        paddingRight: "1rem",
        backgroundColor: "#f5f5f5",
        borderRadius: "4px",
        margin: "0.25rem 0.875rem",
      }}
    >
      <Box sx={{ fontSize: "0.875rem", fontWeight: "600" }}>{label}</Box>
      <Box>{value ? t("yes") : t("no")}</Box>
    </Box>
  )
}
