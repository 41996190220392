import gql from "graphql-tag"
import FullProductOrder from "./fragments/FullProductOrder"

export const EDIT_PRODUCT_ORDER = gql`
  mutation EditProductOrder(
    $id: ID!
    $status: ProductOrderStatus
    $referenceNumber: String
    $notes: String
    $isArchived: Boolean
    $lineItems: [ProductOrderLineItemInput!]
    $containsMHI: Boolean
  ) {
    editProductOrder(
      input: {
        id: $id
        status: $status
        referenceNumber: $referenceNumber
        notes: $notes
        isArchived: $isArchived
        lineItems: $lineItems
        containsMHI: $containsMHI
      }
    ) {
      productOrder {
        ...FullProductOrder
      }
    }
  }
  ${FullProductOrder}
`
