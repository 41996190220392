/* eslint-disable react/display-name */
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TextField from "@mui/material/TextField"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import MoneyInput from "../../../components/MoneyInput"
import PositiveIntegerInput from "../../../components/PositiveIntegerInput"
import OrganizationItemSelect from "../../../components/OrganizationItemSelect"
import { OrganizationItem } from "../../../types"
import { Component } from "./ItemFormContext"
import FielderIconButton from "../../../components/FielderIconButton"
import { Box, Theme } from "@mui/material"
import { NOT_SPECIFIED, formatMoney } from "../../../util"

function getCellStyle(theme: Theme) {
  return {
    padding: 0,
    paddingBottom: "0.5rem",
    border: 0,
    [theme.breakpoints.up("sm")]: {
      paddingRight: "0.5rem",
    },
  }
}
interface BundleComponentLineFormProps {
  readonly component: Component
  readonly currencyCode: string
  readonly dispatch: any
}

const BundleComponentLineForm = React.memo(
  ({ component, currencyCode, dispatch }: BundleComponentLineFormProps) => {
    const { t } = useTranslation()
    const [selectedItem, setSelectedItem] = useState<OrganizationItem | null>(
      component.value.organizationItem
    )

    return (
      <TableRow>
        <TableCell sx={(theme) => getCellStyle(theme)}>
          <OrganizationItemSelect
            error={component.errors?.organizationItemId}
            hideBundleItems
            hideServiceItems={false}
            onChange={(value, reason) => {
              if (reason === "selectOption") {
                setSelectedItem(value)
                const updatedComp = {
                  ...component,
                  value: {
                    ...component.value,
                    organizationItemId: value?.id,
                    organizationItem: value,
                    unitSalePrice: value ? String(value.unitSalePrice) : "",
                    quantity: "1",
                  },
                }
                dispatch({ type: "UPDATE_COMPONENT", payload: updatedComp })
              } else if (reason === "clear") {
                setSelectedItem(null)
                const updatedComp = {
                  ...component,
                  value: {
                    ...component.value,
                    organizationItemId: "",
                    organizationItem: null,
                    unitSalePrice: "",
                    quantity: "",
                  },
                }
                dispatch({ type: "UPDATE_COMPONENT", payload: updatedComp })
              }
            }}
            value={selectedItem}
          />
        </TableCell>
        <TableCell sx={(theme) => getCellStyle(theme)}>
          <PositiveIntegerInput
            aria-label={t("quantity") as string}
            customInput={TextField}
            error={Boolean(component.errors?.showErrors && !!component.errors?.quantity)}
            fullWidth={false}
            inputProps={{
              min: 0,
              maxLength: 12,
            }}
            name="quantity"
            onChange={(e) => {
              const updatedComp = {
                ...component,
                value: {
                  ...component.value,
                  quantity: e.target.value,
                },
              }
              dispatch({ type: "UPDATE_COMPONENT", payload: updatedComp })
            }}
            placeholder="0"
            required
            size="small"
            value={component.value.quantity}
          />
        </TableCell>
        <TableCell sx={(theme) => getCellStyle(theme)}>
          {component.value.enableUnitSalePriceOverride ? (
            <MoneyInput
              aria-label={t("unitPrice")}
              currencyCode={currencyCode}
              customInput={TextField}
              error={Boolean(component.errors?.showErrors && !!component.errors?.unitSalePrice)}
              fullWidth={false}
              max={999999999}
              min={0}
              onChange={(e) => {
                const updatedComp = {
                  ...component,
                  value: {
                    ...component.value,
                    unitSalePrice: e.target.value,
                  },
                }
                dispatch({ type: "UPDATE_COMPONENT", payload: updatedComp })
              }}
              placeholder="0"
              required
              size="small"
              value={component.value.unitSalePrice}
            />
          ) : (
            <Box sx={{ paddingLeft: "1rem", fontSize: "0.875rem" }}>
              {component.value.organizationItem?.unitSalePrice
                ? formatMoney(currencyCode, component.value.organizationItem?.unitSalePrice ?? 0)
                : NOT_SPECIFIED}
            </Box>
          )}
        </TableCell>
        <TableCell sx={(theme) => getCellStyle(theme)}>
          <FielderIconButton
            aria-label={t("delete") as string}
            onClick={() => dispatch({ type: "REMOVE_COMPONENT", payload: component })}
            size="small"
            title={t("delete") as string}
          >
            <DeleteIcon fontSize="inherit" />
          </FielderIconButton>
        </TableCell>
      </TableRow>
    )
  }
)

export default BundleComponentLineForm
