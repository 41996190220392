/* eslint-disable react/jsx-no-literals */
import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@mui/material//Card"
import CardHeader from "@mui/material//CardHeader"
import CardContent from "@mui/material//CardContent"
import Button from "@mui/material//Button"
import numeral from "numeral"
import {
  FielderSubscriptionProduct,
  FielderSubscriptionPricePoint,
  FielderSubscriptionPricePointInterval,
} from "../types"
import { BILLING_PERIOD_OPTIONS } from "../util"

interface SubscriptionPlanCardProps {
  readonly billingPeriod: string
  readonly onClick: () => void
  readonly plan: FielderSubscriptionProduct
  readonly setupFee?: FielderSubscriptionPricePoint
}

function SubscriptionPlanCard({
  billingPeriod,
  onClick,
  plan,
  setupFee,
}: SubscriptionPlanCardProps) {
  const { t } = useTranslation()

  function renderPriceDetails() {
    const price =
      billingPeriod === BILLING_PERIOD_OPTIONS.MONTHLY.value
        ? plan.pricePoints.find((p) => p.interval === FielderSubscriptionPricePointInterval.MONTH)
        : plan.pricePoints.find((p) => p.interval === FielderSubscriptionPricePointInterval.YEAR)

    return price ? (
      <>
        <div
          css={{
            fontSize: "1.1rem",
          }}
        >
          USD{" "}
          <span
            css={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {numeral(price.unitAmount).format("$0,0")}
          </span>{" "}
          {billingPeriod === FielderSubscriptionPricePointInterval.MONTH
            ? t("page.franchiseePricing.perMonth")
            : t("page.franchiseePricing.perYear")}
        </div>
        <div
          css={{
            fontSize: "0.9rem",
          }}
        >
          (
          {billingPeriod === FielderSubscriptionPricePointInterval.MONTH
            ? t("page.franchiseePricing.paidMonthly")
            : t("page.franchiseePricing.paidAnnually")}
          )
        </div>
      </>
    ) : (
      <div>ERROR: price not found</div>
    )
  }

  return (
    <Card
      elevation={plan.isFeatured ? 6 : 1}
      sx={{
        minWidth: "200px",
        width: "17.5rem",
        margin: "0.625rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: plan.isFeatured ? 0 : "0.625rem",
      }}
    >
      <CardHeader
        disableTypography
        sx={{
          backgroundColor: plan.isFeatured ? "#055707" : "#000",
          color: "white",
          fontSize: "1.3rem",
          fontWeight: "bold",
          textAlign: "center",
          width: "100%",
        }}
        title={t(`page.franchiseePricing.products.${plan.nameKey}.title`)}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          flexGrow: 1,
          padding: "1.25rem",
          backgroundColor: plan.isFeatured ? "rgba(5,87,7,0.05)" : "inherit",
          color: plan.isFeatured ? "#055707" : "inherit",
        }}
      >
        <div
          css={{
            fontSize: "1.1em",
            fontWeight: 600,
            marginBottom: "0.625rem",
          }}
        >
          {t("page.franchiseePricing.jobLimitLabel", { numJobs: plan.monthlyJobLimit })}
        </div>
        <div
          css={{
            fontSize: "0.85rem",
            fontStyle: "italic",
            textAlign: "center",
            margin: "0.625rem",
            flexGrow: 1,
            alignSelf: "stretch",
          }}
        >
          {t(`page.franchiseePricing.products.${plan.nameKey}.description`)}
        </div>
        <div
          css={{
            padding: "0.625rem",
            margin: "0.625rem",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            border: plan.isFeatured ? "1px solid #055707" : "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "#fff",
          }}
        >
          {renderPriceDetails()}
          {setupFee ? (
            <div
              css={{
                color: "#747474",
                fontSize: "0.7rem",
                marginTop: "0.625rem",
              }}
            >
              + {numeral(setupFee.unitAmount).format("$0,0")}{" "}
              {t(`page.franchiseePricing.products.setup.title`).toLowerCase()}
            </div>
          ) : null}
        </div>
        <Button
          color="primary"
          onClick={onClick}
          sx={[
            {
              marginTop: "1.25rem",
              width: "100%",
              fontWeight: "bold",
            },
            plan.isFeatured && {
              color: "#fff",
              backgroundColor: "#055707",
              "&:hover": {
                backgroundColor: "#0C8B13",
                boxShadow: "none",
              },
            },
          ]}
          variant="contained"
        >
          {t("page.franchiseePricing.getStarted")}
        </Button>
      </CardContent>
    </Card>
  )
}

export default SubscriptionPlanCard
