import gql from "graphql-tag"
import FullProductOrder from "./fragments/FullProductOrder"

export const GET_PRODUCT_ORDER_BY_ID = gql`
  query GetProductOrderById($id: ID!) {
    getProductOrderById(id: $id) {
      ...FullProductOrder
      comments {
        id
        comment
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${FullProductOrder}
`
