import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation, ApolloError } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import EmptyState from "../../../components/EmptyState"
import JobStatusDialog from "../../../components/JobStatusDialog"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import Seo from "../../../components/Seo"
import SnackbarMessage from "../../../components/SnackbarMessage"
import SectionHeader from "../../../components/SectionHeader"
import SectionContent from "../../../components/SectionContent"
import { ALL_JOB_STATUSES } from "../../../queries/allJobStatuses"
import { CREATE_JOB_STATUS } from "../../../queries/createJobStatus"
import { EDIT_JOB_STATUS } from "../../../queries/editJobStatus"
import { parseGraphQLErrorCode, SETTINGS } from "../../../util"
import { useAuth } from "../../../context/AuthContext"
import { DefaultPermission, JobStatus, Snack } from "../../../types"

function JobStatusOptions() {
  const { t } = useTranslation()
  const [snack, setSnack] = useState<Snack>()
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [selectedStatus, setSelectedStatus] = useState<JobStatus>()
  const [isConfirmArchiveDialogOpen, setIsConfirmArchiveDialogOpen] = useState<boolean>(false)
  const { hasPermissions } = useAuth()

  const {
    loading: queryLoading,
    data,
    error,
    refetch,
  } = useQuery(ALL_JOB_STATUSES, {
    fetchPolicy: "cache-and-network",
    variables: {
      sortBy: "name",
      first: 1000,
    },
  })

  const mutationOptions = {
    onCompleted: () => {
      setInEditMode(false)
      setIsConfirmArchiveDialogOpen(false)
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      refetch()
    },
    onError: (error: ApolloError) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  }
  const [editJobStatus, { loading: editJobStatusLoading }] = useMutation(
    EDIT_JOB_STATUS,
    mutationOptions
  )
  const [createJobStatus, { loading: createJobStatusLoading }] = useMutation(
    CREATE_JOB_STATUS,
    mutationOptions
  )

  const jobStatuses = data?.allJobStatuses?.edges?.map((e: any) => e.node)

  return (
    <>
      <Seo title={t(SETTINGS.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="jobStatusOptions"
          />
          <Paper sx={classes.widgetContainer}>
            <SectionHeader>
              <label>{t("jobStatusOptions")}</label>
              {hasPermissions?.([DefaultPermission.CreateJobStatus]) ? (
                <Button
                  aria-label={t("addStatus")}
                  color="primary"
                  data-testid="createJobStatusButton"
                  onClick={() => {
                    setInEditMode(true)
                    setSelectedStatus(undefined)
                  }}
                  sx={classes.primaryActionButton}
                  variant="contained"
                >
                  <AddIcon />
                  <Box>{t("addStatus")}</Box>
                </Button>
              ) : null}
            </SectionHeader>
            <Divider />
            <SectionContent sx={{ maxHeight: 600, overflowY: "scroll" }}>
              {queryLoading ? (
                <Box sx={classes.spinnerContainer}>
                  <CircularProgress color="secondary" size={20} thickness={6.0} />
                </Box>
              ) : null}
              {!queryLoading &&
                jobStatuses
                  ?.filter((j: JobStatus) => !j.isArchived)
                  ?.map((status: JobStatus) => {
                    return (
                      <Box key={status.id} sx={classes.statusRow}>
                        <Box sx={classes.fieldContainer}>
                          <Box
                            sx={[
                              classes.colorBox,
                              { backgroundColor: status.mediumColor || "#fff" },
                            ]}
                          />
                          <Box sx={classes.textContainer}>
                            <Box sx={classes.name}>{status.name}</Box>
                            <Box sx={classes.description}>{status.description}</Box>
                          </Box>
                        </Box>
                        <Box>
                          {hasPermissions?.([DefaultPermission.UpdateJobStatus]) ? (
                            <IconButton
                              aria-label="edit"
                              data-testid="editJobStatusBtn"
                              onClick={() => {
                                setSelectedStatus(status)
                                setInEditMode(true)
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          ) : null}
                          {hasPermissions?.([DefaultPermission.UpdateJobStatus]) ? (
                            <IconButton
                              aria-label="archive"
                              data-testid="archiveJobStatusBtn"
                              onClick={() => {
                                setSelectedStatus(status)
                                setIsConfirmArchiveDialogOpen(true)
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : null}
                        </Box>
                      </Box>
                    )
                  })}
              {!error && !queryLoading && jobStatuses?.length == 0 && (
                <EmptyState
                  title={t("page.jobStatusOptions.emptyState.title")}
                  titleStyle={{
                    fontSize: "1rem",
                  }}
                >
                  <Box>{t("page.jobStatusOptions.emptyState.message")}</Box>
                </EmptyState>
              )}
            </SectionContent>
          </Paper>
        </Box>
      </MainLayout>
      {inEditMode ? (
        <JobStatusDialog
          loading={createJobStatusLoading || editJobStatusLoading}
          onCancel={() => {
            setInEditMode(false)
            setSelectedStatus(undefined)
          }}
          onSave={(updatedStatus) => {
            selectedStatus?.id
              ? editJobStatus({
                  variables: {
                    ...updatedStatus,
                  },
                })
              : createJobStatus({
                  variables: {
                    isArchived: false,
                    ...updatedStatus,
                  },
                })
          }}
          open={inEditMode}
          status={selectedStatus}
        />
      ) : null}
      {isConfirmArchiveDialogOpen && selectedStatus ? (
        <Dialog
          aria-describedby="archive-status-dialog-description"
          aria-labelledby="archive-status-dialog-title"
          onClose={() => setIsConfirmArchiveDialogOpen(false)}
          open={isConfirmArchiveDialogOpen}
        >
          <DialogTitle id="archive-status-dialog-title">
            {t("page.jobStatusOptions.archiveStatusConfirmationPromptTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-contact-dialog-description">
              {t("page.jobStatusOptions.archiveStatusConfirmationPromptMsg", {
                name: selectedStatus.name,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={editJobStatusLoading}
              onClick={() => setIsConfirmArchiveDialogOpen(false)}
            >
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={editJobStatusLoading}
              onClick={() => {
                editJobStatus({
                  variables: {
                    id: selectedStatus.id,
                    parentStatusId: selectedStatus.parentStatus?.id,
                    isArchived: true,
                  },
                })
              }}
            >
              {editJobStatusLoading ? (
                <CircularProgress color="secondary" size={20} thickness={6.0} />
              ) : (
                <Box>{t("yes")}</Box>
              )}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
  },
  spinnerContainer: {
    padding: "6.25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  linkButton: {
    textDecoration: "none",
  },
  primaryActionButton: {
    fontWeight: "bold",
    "& svg": {
      fontSize: "1.0rem",
    },
    "& div": {
      marginLeft: "0.625rem",
      marginRight: "0.625rem",
    },
  },
  widgetContainer: {
    width: "50%",
    maxWidth: "700px",
    marginBottom: "1.25rem",
    marginTop: "3.75rem",
  },
  statusRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ddd",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    "&:first-of-type": {},
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  colorBox: {
    height: "1.875rem",
    width: "1.875rem",
    borderRadius: "0.25rem",
    marginRight: "1rem",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontWeight: 600,
    fontSize: "0.875rem",
  },
  description: {
    fontWeight: 400,
    fontSize: "0.75rem",
  },
} as const

export default JobStatusOptions
