import gql from "graphql-tag"

export const EDIT_ORGANIZATION_PLUGIN = gql`
  mutation EditOrganizationPlugin(
    $id: ID!
    $defaultExpenseAccountId: ID
    $defaultRevenueAccountId: ID
    $defaultAssetAccountId: ID
  ) {
    editOrganizationPlugin(
      input: {
        id: $id
        defaultExpenseAccountId: $defaultExpenseAccountId
        defaultRevenueAccountId: $defaultRevenueAccountId
        defaultAssetAccountId: $defaultAssetAccountId
      }
    ) {
      organizationPlugin {
        id
        pluginProvider {
          id
          name
          category
        }
        defaultExpenseAccount {
          id
          code
          name
          type
        }
        defaultRevenueAccount {
          id
          code
          name
          type
        }
        defaultAssetAccount {
          id
          code
          name
          type
        }
      }
    }
  }
`
