/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import CircularProgress from "@mui/material/CircularProgress"
import { ResponsiveBar } from "@nivo/bar"
import { ResponsiveLine } from "@nivo/line"
import { useQuery } from "@apollo/client"
import { GET_ORGANIZATION_JOB_COUNTS } from "../../queries/getOrgJobCounts"
import flatten from "lodash/flatten"
import padStart from "lodash/padStart"
import range from "lodash/range"
import dayjs from "dayjs"
import SelectorField from "../SelectorField"
import EmptyState from "../EmptyState"

const API_DATE_FORMAT = "YYYY-MM-DD"
const NOW = dayjs()
const NOW_MONTH_START = NOW.startOf("month")
const NOW_QUARTER_START = NOW.startOf("quarter")
const NOW_QUARTER_END = NOW.endOf("quarter")
const NOW_YEAR_START = NOW.startOf("year")

type ChartType = "BAR" | "LINE"
type BreakdownType = "WEEK" | "MONTH" | "QUARTER" | "YEAR"
type TimeframeId =
  | "LAST_7_DAYS"
  | "LAST_14_DAYS"
  | "THIS_MONTH"
  | "THIS_MONTH"
  | "LAST_MONTH"
  | "LAST_3_MONTHS"
  | "LAST_6_MONTHS"
  | "LAST_12_MONTHS"
  | "THIS_QUARTER"
  | "LAST_QUARTER"
  | "LAST_4_QUARTERS"
  | "LAST_8_QUARTERS"
  | "THIS_YEAR"
  | "LAST_YEAR"
  | "LAST_2_YEARS"
  | "LAST_5_YEARS"

interface ChartTypeOption {
  id: ChartType
  name: string
}

interface TimeframeOption {
  id: TimeframeId
  name: string
  granularity: number
}

interface BreakdownOption {
  id: BreakdownType
  name: string
  granularity: number
  defaultTimeframeId: TimeframeId
}

interface JobsOverTimeProps {
  readonly orgId: string
}

function JobsOverTime({ orgId }: JobsOverTimeProps) {
  const { t } = useTranslation()
  const [chartTypeOptions, setChartTypeOptions] = useState<ChartTypeOption[]>()
  const [chartType, setChartType] = useState<ChartType>("BAR")
  const [breakdownOptions, setBreakdownOptions] = useState<BreakdownOption[]>()
  const [timeframeOptions, setTimeframeOptions] = useState<TimeframeOption[]>()
  const [timeframe, setTimeframe] = useState<TimeframeOption>()
  const [breakdown, setBreakdown] = useState<BreakdownOption>()
  const [start, setStart] = useState<string>()
  const [end, setEnd] = useState<string>()
  const [barChartData, setBarChartData] = useState()
  const [lineChartData, setLineChartData] = useState()
  const [yTickMarks, setYTickMarks] = useState<number[]>(range(0, 11))

  useEffect(() => {
    if (t) {
      const CHART_TYPE_OPTIONS: ChartTypeOption[] = [
        {
          id: "BAR",
          name: t("page.report.chartTypeOptions.BAR"),
        },
        {
          id: "LINE",
          name: t("page.report.chartTypeOptions.LINE"),
        },
      ]

      const BREAKDOWN_OPTIONS: BreakdownOption[] = [
        {
          id: "WEEK",
          name: t("page.report.breakdownOptions.WEEK"),
          granularity: 1,
          defaultTimeframeId: "LAST_3_MONTHS",
        },
        {
          id: "MONTH",
          name: t("page.report.breakdownOptions.MONTH"),
          granularity: 2,
          defaultTimeframeId: "LAST_12_MONTHS",
        },
        {
          id: "QUARTER",
          name: t("page.report.breakdownOptions.QUARTER"),
          granularity: 4,
          defaultTimeframeId: "LAST_4_QUARTERS",
        },
        {
          id: "YEAR",
          name: t("page.report.breakdownOptions.YEAR"),
          granularity: 8,
          defaultTimeframeId: "LAST_2_YEARS",
        },
      ]

      const TIMEFRAME_OPTIONS: TimeframeOption[] = [
        {
          id: "THIS_MONTH",
          name: t("page.report.timeframeOptions.thisMonth"),
          granularity: 1,
        },
        {
          id: "LAST_MONTH",
          name: t("page.report.timeframeOptions.lastMonth"),
          granularity: 1,
        },
        {
          id: "LAST_3_MONTHS",
          name: t("page.report.timeframeOptions.lastNMonths", { num: 3 }),
          granularity: 2,
        },
        {
          id: "LAST_6_MONTHS",
          name: t("page.report.timeframeOptions.lastNMonths", { num: 6 }),
          granularity: 2,
        },
        {
          id: "LAST_12_MONTHS",
          name: t("page.report.timeframeOptions.lastNMonths", { num: 12 }),
          granularity: 2,
        },
        {
          id: "THIS_QUARTER",
          name: t("page.report.timeframeOptions.thisQuarter"),
          granularity: 3,
        },
        {
          id: "LAST_QUARTER",
          name: t("page.report.timeframeOptions.lastQuarter"),
          granularity: 3,
        },
        {
          id: "LAST_4_QUARTERS",
          name: t("page.report.timeframeOptions.lastNQuarters", { num: 4 }),
          granularity: 4,
        },
        {
          id: "LAST_8_QUARTERS",
          name: t("page.report.timeframeOptions.lastNQuarters", { num: 8 }),
          granularity: 4,
        },
        {
          id: "THIS_YEAR",
          name: t("page.report.timeframeOptions.thisYear"),
          granularity: 6,
        },
        {
          id: "LAST_YEAR",
          name: t("page.report.timeframeOptions.lastYear"),
          granularity: 6,
        },
        {
          id: "LAST_2_YEARS",
          name: t("page.report.timeframeOptions.lastNYears", { num: 2 }),
          granularity: 8,
        },
        {
          id: "LAST_5_YEARS",
          name: t("page.report.timeframeOptions.lastNYears", { num: 5 }),
          granularity: 9,
        },
      ]

      setChartTypeOptions(CHART_TYPE_OPTIONS)
      setBreakdownOptions(BREAKDOWN_OPTIONS)
      setTimeframeOptions(TIMEFRAME_OPTIONS)

      // set the default breakdown to "MONTH" and timeframe to "LAST_12_MONTHS"
      const defaultBreakdown = BREAKDOWN_OPTIONS[1] // MONTH
      const defaultTimeframe = TIMEFRAME_OPTIONS.find(
        (o) => o.id === defaultBreakdown.defaultTimeframeId
      ) // last 12 months
      setBreakdown(defaultBreakdown)
      setTimeframe(defaultTimeframe)
      setStart(NOW.subtract(12, "month").format(API_DATE_FORMAT))
      setEnd(NOW.format(API_DATE_FORMAT))
    }
  }, [t])

  const { loading } = useQuery(GET_ORGANIZATION_JOB_COUNTS, {
    variables: {
      id: orgId,
      groupBy: "DATE",
      breakdown: breakdown?.id,
      start,
      end,
    },
    skip: !breakdown?.id || !start || !end,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (!data) return

      const jobCounts = data.getOrganizationById.jobCounts

      if (breakdown?.id === "YEAR") {
        const dataset = flatten(jobCounts.map((mg) => mg.data))
        const lineDataset = [
          {
            id: "JobCounts",
            data: dataset.map((d) => ({
              x: d.label, // year
              y: d.value, // value
            })),
          },
        ]

        setYTickMarks(range(0, Math.max(...dataset.map((d) => d.value)) + 1))
        setBarChartData(dataset)
        setLineChartData(lineDataset)
      } else if (breakdown?.id === "QUARTER") {
        const includesMultipleYears = jobCounts.length > 1
        const dateLabelFormat = includesMultipleYears ? "YYYY [Q]Q" : "[Q]Q"
        const defaults = []
        const qStartDate = dayjs(start)
        const qEndDate = dayjs(end)
        const startYear = qStartDate.year()
        const startQuarter = qStartDate.quarter()
        const endYear = qEndDate.year()
        const endQuarter = qEndDate.quarter()
        const yearsArray = range(startYear, endYear + 1)
        yearsArray.forEach((year) => {
          const a = startYear === endYear || year === startYear ? startQuarter : 1
          const z = year === endYear ? endQuarter + 1 : 5
          const quarters = range(a, z)
          quarters.forEach((quarter: number) => {
            const lbl = dayjs().year(year).quarter(quarter).format(dateLabelFormat)
            defaults.push({
              x: lbl,
              y: 0,
              year: String(year),
              quarter: String(quarter),
              key: `${year}Q${quarter}`,
              label: lbl,
              value: 0,
            })
          })
        })

        const fullDataset = defaults.map((d) => {
          const val =
            jobCounts.find((mg) => mg.label === d.year)?.data?.find((m) => m.label === d.quarter)
              ?.value || 0
          return {
            ...d,
            value: val,
            y: val,
          }
        })

        const lineDataset = [
          {
            id: "JobCountsByQuarterForLineChart",
            data: fullDataset,
          },
        ]

        setYTickMarks(range(0, Math.max(...fullDataset.map((d) => d.value)) + 1))
        setBarChartData(fullDataset)
        setLineChartData(lineDataset)
      } else if (breakdown?.id === "MONTH") {
        const includesMultipleYears = jobCounts.length > 1
        const dateLabelFormat = includesMultipleYears ? "MMM YYYY" : "MMM"
        const defaults = []
        const dayjs_start = dayjs(start)
        const dayjs_end = dayjs(end)
        const startYear = dayjs_start.year()
        const startMonth = dayjs_start.month() + 1
        const endYear = dayjs_end.year()
        const endMonth = dayjs_end.month() + 1
        const yearsArray = range(startYear, endYear + 1)
        yearsArray.forEach((year: number) => {
          const a = startYear === endYear || year === startYear ? startMonth : 1
          const z = year === endYear ? endMonth + 1 : 13
          const months = range(a, z)
          months.forEach((month: number) => {
            const lblString = `${year}-${padStart(String(month), 2, "0")}`
            const lbl = dayjs(lblString).format(dateLabelFormat)
            defaults.push({
              x: lbl,
              y: 0,
              year: String(year),
              month: String(month),
              key: `${year}-${padStart(String(month), 2, "0")}`,
              label: lbl,
              value: 0,
            })
          })
        })

        const fullDataset = defaults.map((d) => {
          const val =
            jobCounts.find((mg) => mg.label === d.year)?.data?.find((m) => m.label === d.month)
              ?.value || 0
          return {
            ...d,
            value: val,
            y: val,
          }
        })

        const lineDataset = [
          {
            id: "JobCountsByMonthForLineChart",
            data: fullDataset,
          },
        ]

        setYTickMarks(range(0, Math.max(...fullDataset.map((d) => d.value)) + 1))
        setBarChartData(fullDataset)
        setLineChartData(lineDataset)
      } else if (breakdown?.id === "WEEK") {
        if (!jobCounts || jobCounts.length === 0) {
          setYTickMarks(range(0, 10))
          setBarChartData(undefined)
          setLineChartData(undefined)
          return
        }
        const includesMultipleYears = jobCounts.length > 1
        const dateLabelFormat = includesMultipleYears ? "YYYY-[W]W" : "[W]W"
        const defaults = []
        const dayjs_start = dayjs(start)
        const dayjs_end = dayjs(end)
        const startYear = dayjs_start.year()
        const startWeek = dayjs_start.isoWeek()
        const endYear = dayjs_end.year()
        const endWeek = timeframe?.id === "LAST_YEAR" ? 52 : dayjs_end.isoWeek()
        const yearsArray = range(startYear, endYear + 1)
        yearsArray.forEach((year) => {
          const a = startYear === endYear || year === startYear ? startWeek : 1
          const z = year === endYear ? endWeek + 1 : 53
          const weeks = range(a, z)
          weeks.forEach((week: number) => {
            const weekStr = padStart(String(week), 2, "0")
            const lbl = dayjs().year(year).isoWeek(week).format(dateLabelFormat)
            defaults.push({
              x: lbl,
              y: 0,
              year: String(year),
              week: String(week),
              key: `${year}-W${weekStr}`,
              label: lbl,
              value: 0,
            })
          })
        })

        const fullDataset = defaults.map((d) => {
          const val =
            jobCounts.find((mg) => mg.label === d.year)?.data?.find((m) => m.label === d.week)
              ?.value || 0
          return {
            ...d,
            value: val,
            y: val,
          }
        })

        const lineDataset = [
          {
            id: "JobCountsByWeekForLineChart",
            data: fullDataset,
          },
        ]

        setYTickMarks(range(0, Math.max(...fullDataset.map((d) => d.value)) + 1))
        setBarChartData(fullDataset)
        setLineChartData(lineDataset)
      }
    },
  })

  function getTimeframeOptions(): TimeframeOption[] {
    return breakdown && timeframeOptions
      ? timeframeOptions.filter(
          (o) => o.granularity >= breakdown.granularity && o.granularity - breakdown.granularity < 6
        )
      : []
  }

  function getTickRotation(): number {
    if (
      (chartType === "BAR" && barChartData.length > 25) ||
      (chartType === "LINE" && lineChartData?.[0]?.data?.length > 25)
    ) {
      return -70
    }

    return 0
  }

  function handleChangeTimeframe(val: TimeframeOption): void {
    setTimeframe(val)
    switch (val.id) {
      case "LAST_7_DAYS":
        setStart(NOW.subtract(7, "days").format(API_DATE_FORMAT))
        setEnd(NOW.format(API_DATE_FORMAT))
        break
      case "LAST_14_DAYS":
        setStart(NOW.subtract(14, "days").format(API_DATE_FORMAT))
        setEnd(NOW.format(API_DATE_FORMAT))
        break
      case "THIS_MONTH":
        setStart(NOW_MONTH_START.format(API_DATE_FORMAT))
        setEnd(NOW.format(API_DATE_FORMAT))
        break
      case "LAST_MONTH":
        setStart(NOW_MONTH_START.subtract(1, "month").format(API_DATE_FORMAT))
        setEnd(NOW_MONTH_START.subtract(1, "day").format(API_DATE_FORMAT))
        break
      case "LAST_3_MONTHS":
        setStart(NOW_MONTH_START.subtract(3, "months").format(API_DATE_FORMAT))
        setEnd(NOW.format(API_DATE_FORMAT))
        break
      case "LAST_6_MONTHS":
        setStart(NOW_MONTH_START.subtract(6, "months").format(API_DATE_FORMAT))
        setEnd(NOW.format(API_DATE_FORMAT))
        break
      case "LAST_12_MONTHS":
        setStart(NOW_MONTH_START.subtract(12, "months").format(API_DATE_FORMAT))
        setEnd(NOW.format(API_DATE_FORMAT))
        break
      case "THIS_QUARTER":
        setStart(NOW_QUARTER_START.format(API_DATE_FORMAT))
        setEnd(NOW.format(API_DATE_FORMAT))
        break
      case "LAST_QUARTER":
        setStart(NOW_QUARTER_START.subtract(1, "quarter").format(API_DATE_FORMAT))
        setEnd(NOW_QUARTER_END.subtract(1, "quarter").format(API_DATE_FORMAT))
        break
      case "LAST_4_QUARTERS":
        setStart(NOW_QUARTER_START.subtract(4, "quarter").format(API_DATE_FORMAT))
        setEnd(NOW_QUARTER_END.format(API_DATE_FORMAT))
        break
      case "LAST_8_QUARTERS":
        setStart(NOW_QUARTER_START.subtract(8, "quarter").format(API_DATE_FORMAT))
        setEnd(NOW_QUARTER_END.format(API_DATE_FORMAT))
        break
      case "THIS_YEAR":
        setStart(NOW_YEAR_START.format(API_DATE_FORMAT))
        setEnd(NOW.format(API_DATE_FORMAT))
        break
      case "LAST_YEAR":
        setStart(NOW_YEAR_START.subtract(1, "year").format(API_DATE_FORMAT))
        setEnd(NOW.endOf("year").subtract(1, "year").format(API_DATE_FORMAT))
        break
      case "LAST_2_YEARS":
        setStart(NOW_YEAR_START.subtract(2, "year").format(API_DATE_FORMAT))
        setEnd(NOW.format(API_DATE_FORMAT))
        break
      case "LAST_5_YEARS":
        setStart(NOW_YEAR_START.subtract(5, "year").format(API_DATE_FORMAT))
        setEnd(NOW.format(API_DATE_FORMAT))
        break
      default:
        setStart(NOW_YEAR_START.format(API_DATE_FORMAT))
        setEnd(NOW.format(API_DATE_FORMAT))
    }
  }

  return (
    <div css={classes.root}>
      <div css={classes.headerPanel}>
        <div>
          <div css={classes.headerTitle}>{t("page.report.jobsOverTime")}</div>
          <div css={classes.headerSubTitle}>
            {t("page.report.fromDateToDate", {
              start: dayjs(start).format("MMMM YYYY"),
              end: dayjs(end).format("MMMM YYYY"),
            })}
          </div>
        </div>
      </div>
      <div css={classes.controlPanel}>
        {breakdownOptions ? (
          <div css={classes.selectorContainer}>
            <SelectorField
              ariaLabel={t("page.report.breakdown")}
              label={t("page.report.breakdown")}
              margin="dense"
              name="breakdown-selector"
              onChange={(val) => {
                setBreakdown(val)
                const tf = timeframeOptions?.find((o) => o.id === val.defaultTimeframeId) ?? {
                  id: "THIS_MONTH",
                  name: t("page.report.timeframeOptions.thisMonth"),
                  granularity: 1,
                }
                handleChangeTimeframe(tf)
              }}
              options={breakdownOptions}
              style={classes.selectorField}
              value={breakdown?.id}
              variant="filled"
            />
          </div>
        ) : null}
        {timeframeOptions ? (
          <div css={classes.selectorContainer}>
            <SelectorField
              ariaLabel={t("page.report.timeframe")}
              label={t("page.report.timeframe")}
              margin="dense"
              name="timeframe-selector"
              onChange={(val) => {
                handleChangeTimeframe(val)
              }}
              options={getTimeframeOptions()}
              style={classes.selectorField}
              value={timeframe?.id}
              variant="filled"
            />
          </div>
        ) : null}
        {chartTypeOptions ? (
          <div css={classes.selectorContainer}>
            <SelectorField
              ariaLabel={t("page.report.chartType")}
              label={t("page.report.chartType")}
              margin="dense"
              name="chartType-selector"
              onChange={(val) => {
                setChartType(val.id)
              }}
              options={chartTypeOptions}
              style={classes.selectorField}
              value={chartType}
              variant="filled"
            />
          </div>
        ) : null}
      </div>
      <div
        css={{
          height: 420,
        }}
      >
        {loading ? (
          <div css={classes.loadingIndicator}>
            <CircularProgress />
          </div>
        ) : null}
        {!loading && !barChartData && !lineChartData && (
          <EmptyState title={t("page.report.noResults.title")}>
            <div style={{ fontSize: "1.1rem" }}>{t("page.report.noResults.message")}</div>
          </EmptyState>
        )}
        {!loading && barChartData && chartType === "BAR" ? (
          <ResponsiveBar
            animate
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: getTickRotation(),
            }}
            axisLeft={{
              legend: t("page.report.numberOfJobs"),
              legendPosition: "middle",
              tickSize: 5,
              tickPadding: 5,
              tickValues: yTickMarks,
              tickRotation: 0,
              legendOffset: -40,
            }}
            axisRight={null}
            axisTop={null}
            colors="#018B1B"
            data={barChartData}
            gridYValues={yTickMarks}
            indexBy="label"
            labelSkipHeight={20}
            labelSkipWidth={12}
            labelTextColor="#ffffff"
            margin={{ top: 20, right: 20, bottom: 70, left: 60 }}
            minValue={0}
            padding={0.1}
            tooltip={({ data }) => {
              let period = data.label
              if (breakdown?.id === "MONTH") {
                period = dayjs(data.key).format("MMMM YYYY")
              }
              return (
                <div style={classes.tooltip}>
                  <div>
                    <span style={{ fontWeight: "bold" }}>{t("period")}:</span> <span>{period}</span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>{t("jobs")}:</span>{" "}
                    <span>{data.value}</span>
                  </div>
                </div>
              )
            }}
          />
        ) : null}
        {!loading && lineChartData && chartType === "LINE" ? (
          <ResponsiveLine
            animate
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: getTickRotation(),
            }}
            axisLeft={{
              legend: t("page.report.numberOfJobs"),
              legendPosition: "middle",
              tickSize: 5,
              tickPadding: 5,
              tickValues: yTickMarks,
              tickRotation: 0,
              legendOffset: -40,
            }}
            axisRight={null}
            axisTop={null}
            colors={{ scheme: "dark2" }}
            curve="monotoneX"
            data={lineChartData}
            enableArea
            enablePoints={false}
            gridYValues={yTickMarks}
            legends={
              breakdown !== "YEAR" && lineChartData.length > 1
                ? [
                    {
                      anchor: "bottom",
                      direction: "row",
                      justify: false,
                      translateY: 60,
                      itemsSpacing: 0,
                      itemDirection: "left-to-right",
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: "circle",
                      symbolBorderColor: "rgba(0, 0, 0, .5)",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemBackground: "rgba(0, 0, 0, .03)",
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]
                : []
            }
            margin={{ top: 20, right: 20, bottom: 70, left: 60 }}
            tooltip={({ point }) => (
              <div style={classes.tooltip}>
                <div>
                  <span style={{ fontWeight: "bold" }}>{t("period")}:</span>{" "}
                  <span>{point.data.x}</span>
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>{t("jobs")}:</span>{" "}
                  <span>{point.data.y}</span>
                </div>
              </div>
            )}
            useMesh
            xScale={{ type: "point" }}
            yScale={{ type: "linear", min: 0, max: "auto", stacked: false, reverse: false }}
          />
        ) : null}
      </div>
      <div />
    </div>
  )
}

const classes = {
  root: {
    padding: "1.5rem",
    display: "flex",
    flexDirection: "column",
  },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
  },
  headerPanel: {
    display: "flex",
    flexDirection: "row",
  },
  headerTitle: {
    fontWeight: "bold",
    fontSize: "1.25rem",
  },
  headerSubTitle: {
    color: "rgba(0,0,0,0.5)",
  },
  controlPanel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#EFEFEF",
    padding: "0.625rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
  selectorContainer: {
    padding: "0.5rem",
    minWidth: "200px",
  },
  selectorField: {
    backgroundColor: "#fff",
  },
  tooltip: {
    backgroundColor: "#fcfcfc",
    border: "1px solid #999",
    borderRadius: "2px",
    padding: "4px 8px",
  },
} as const

export default JobsOverTime
