import gql from "graphql-tag"
import FullWorkOrderTemplateLineItemDetails from "./FullWorkOrderTemplateLineItemDetails"

export default gql`
  fragment FullWorkOrderTemplate on WorkOrderTemplate {
    id
    name
    currencyCode
    description
    notes
    subTotal
    total
    lineItems {
      id
      number
      showDetails
      organizationItem {
        id
        code
        name
        type
      }
      description
      quantity
      unitPrice
      total
      lineItemDetails {
        ...FullWorkOrderTemplateLineItemDetails
      }
    }
  }
  ${FullWorkOrderTemplateLineItemDetails}
`
