import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as Sentry from "@sentry/react"
import { Descendant } from "slate"
import { useDropzone } from "react-dropzone"
import { useMutation, useQuery, gql } from "@apollo/client"
import { useParams, Navigate, NavigateProps } from "react-router-dom"
import Box from "@mui/material/Box"
import Switch from "@mui/material/Switch"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import CircularProgress from "@mui/material/CircularProgress"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import FielderTextField from "../../../../components/FielderTextField"
import FieldHelperText from "../../../../components/FieldHelperText"
import EmailAttachments from "../../../../components/EmailAttachments"
import RichTextComposer from "../../../../components/RichTextEditor/RichTextComposer"
import MainLayout from "../../../../components/MainLayout"
import PageHeader from "../../../../components/PageHeader"
import Seo from "../../../../components/Seo"
import SnackbarMessage from "../../../../components/SnackbarMessage"
import Tooltip from "../../../../components/Tooltip"
import { isBlank, parseGraphQLErrorCode, ONE_MEGABYTE, SETTINGS } from "../../../../util"
import {
  SAMPLE_DATA,
  toHtml,
  resolveTemplate,
  DEFAULT_INITIAL_RTE_VALUE,
} from "../../../../util/richTextTemplateUtils"
import { useAuth } from "../../../../context/AuthContext"
import { usePrompt } from "../../../../hooks/usePrompt"
import {
  AllTemplateFieldsResponse,
  Attachment,
  TemplateFieldOption,
  EmailTemplateType,
  Snack,
} from "../../../../types"
import Cookies from "js-cookie"

const ALL_TEMPLATE_FIELDS = gql`
  query AllTemplateFields {
    allTemplateFields {
      id
      key
      format
    }
  }
`

const EmailTemplateTypes = {
  JOB: "JOB",
  ESTIMATE: "ESTIMATE",
  INVOICE: "INVOICE",
}

const GET_EMAIL_TEMPLATE = gql`
  query GetEmailTemplateById($id: ID!) {
    getEmailTemplateById(id: $id) {
      id
      name
      type
      subject
      body
      attachments {
        id
        name
        signedUrl
      }
      createdAt
      createdBy {
        id
      }
      updatedAt
      updatedBy {
        id
      }
    }
  }
`

const CREATE_EMAIL_TEMPLATE = gql`
  mutation CreateEmailTemplate(
    $name: String!
    $type: EmailTemplateType!
    $subject: String!
    $body: String
    $attachmentIds: [ID!]
  ) {
    createEmailTemplate(
      input: {
        name: $name
        type: $type
        subject: $subject
        body: $body
        attachmentIds: $attachmentIds
      }
    ) {
      emailTemplate {
        id
        name
        type
        subject
        body
        attachments {
          id
          name
          signedUrl
        }
        createdAt
        createdBy {
          id
        }
        updatedAt
        updatedBy {
          id
        }
      }
    }
  }
`

const EDIT_EMAIL_TEMPLATE = gql`
  mutation EditEmailTemplate(
    $id: ID!
    $name: String
    $subject: String
    $body: String
    $attachmentIds: [ID!]
  ) {
    editEmailTemplate(
      input: { id: $id, name: $name, subject: $subject, body: $body, attachmentIds: $attachmentIds }
    ) {
      emailTemplate {
        id
        name
        type
        subject
        body
        attachments {
          id
          name
          signedUrl
        }
        createdAt
        createdBy {
          id
        }
        updatedAt
        updatedBy {
          id
        }
      }
    }
  }
`

const GET_ORGANIZATION_FILES = gql`
  query GetOrganizationFiles($id: ID!) {
    getOrganizationById(id: $id) {
      id
      attachments {
        id
        name
        type
        objectName
        contentType
        isArchived
        signedUrl
        createdAt
      }
    }
  }
`

interface TemplateTypeOptionProps {
  readonly label: string
  readonly description: string
}

function TemplateTypeOption({ label, description }: TemplateTypeOptionProps) {
  return (
    <Box sx={classes.templateTypeOptionContainer}>
      <Box sx={classes.templateTypeOptionLabel}>{label}</Box>
      <Box sx={classes.templateTypeOptionDescription}>{description}</Box>
    </Box>
  )
}

function EditEmailTemplate() {
  const { t } = useTranslation()
  const {
    getRootProps,
    getInputProps,
    open: openFileDialog,
  } = useDropzone({
    accept:
      ".jpg, .jpeg, .png, .txt, .pdf, .dwg, .dxf, image/png, image/jpeg, application/pdf, text/plain, application/acad, application/dwg, image/vnd.dwg, image/x-dwg, application/dxf, image/vnd.dwg, image/x-dwg",
    minSize: 0,
    maxSize: ONE_MEGABYTE * 25,
    multiple: true,
    noClick: true,
    onDrop: (acceptedFiles: File[]) => {
      uploadFiles(acceptedFiles)
    },
  })
  const { user } = useAuth()
  const { id: idParam } = useParams()
  const [availableFiles, setAvailableFiles] = useState<Attachment[]>([])
  const [attachmentIds, setAttachmentIds] = useState<string[]>([])
  const [uploadingNewAttachmentFiles, setUploadingNewAttachmentFiles] = useState<boolean>(false)
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [id, setId] = useState<string | null | undefined>(idParam)
  const [name, setName] = useState<string>("")
  const [type, setType] = useState<EmailTemplateType>(EmailTemplateType.JOB)
  const [subject, setSubject] = useState<string>("")
  const [body, setBody] = useState<Descendant[] | null>(null) // this needs to start off null so that we don't try to render the editor until the template content has been loaded
  const [initialBody, setInitialBody] = useState<Descendant[] | null>(
    idParam ? null : DEFAULT_INITIAL_RTE_VALUE
  )
  const [allTemplateFieldOptions, setAllTemplateFieldOptions] = useState<TemplateFieldOption[]>([])
  const [snack, setSnack] = useState<Snack>()
  const [errors, setErrors] = useState<{
    name?: string | null
    subject?: string | null
    body?: string | null
    attachments?: string | null
  }>(() => ({
    name: null,
    subject: null,
    body: null,
    attachments: null,
  }))
  const [inPreviewMode, setInPreviewMode] = useState<boolean>(false)
  const userOrgId = user?.organization?.id

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  useQuery(GET_ORGANIZATION_FILES, {
    variables: { id: userOrgId },
    onCompleted: (data) => {
      setAvailableFiles(data?.getOrganizationById?.attachments ?? [])
    },
  })

  useQuery<AllTemplateFieldsResponse>(ALL_TEMPLATE_FIELDS, {
    onCompleted: (data) => {
      const sortedOptions = data?.allTemplateFields
        ?.map((p) => ({
          id: p.id,
          key: p.key,
          format: p.format,
          displayName: t(`component.richTextEditor.templateFieldOptions.${p.key}`),
        }))
        ?.sort((a, body) => a.displayName.localeCompare(body.displayName))
      setAllTemplateFieldOptions(sortedOptions)
    },
  })

  const { loading: getEmailTemplateLoading } = useQuery(GET_EMAIL_TEMPLATE, {
    variables: { id: idParam },
    skip: !idParam,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const template = data?.getEmailTemplateById
      if (template) {
        setId(template.id)
        setName(template.name)
        setType(template.type)
        setSubject(template.subject ?? "")
        const bodyJson = JSON.parse(template.body)
        setBody(bodyJson)
        setInitialBody(bodyJson)
        setAttachmentIds(template.attachments?.map((a) => a.id) ?? [])
      }
    },
  })

  const [editEmailTemplate, { loading: editEmailTemplateLoading }] = useMutation(
    EDIT_EMAIL_TEMPLATE,
    {
      onCompleted: () => {
        setIsDirty(false)
        setRedirectTo({
          to: "/app/settings/templates/email/list",
          replace: false,
          state: {
            snack: {
              messageKey: "messages.changesSaved",
              variant: "success",
            },
          },
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const [createEmailTemplate, { loading: createEmailTemplateLoading }] = useMutation(
    CREATE_EMAIL_TEMPLATE,
    {
      onCompleted: () => {
        setIsDirty(false)
        setRedirectTo({
          to: "/app/settings/templates/email/list",
          replace: false,
          state: {
            snack: {
              messageKey: "messages.changesSaved",
              variant: "success",
            },
          },
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const uploadFile = async (file: File) => {
    const data = new FormData()
    data.append("file", file)
    return fetch(
      `${process.env.GATSBY_API_SERVER_URL}/attachment/organization/${userOrgId}/attachments`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
        },
        body: data,
      }
    ).then((response) => response.json())
  }

  const uploadFiles = async (files: File[]) => {
    if (files.length > 0) {
      const promises = files.map((file: File) => uploadFile(file))
      setUploadingNewAttachmentFiles(true)
      return await Promise.all(promises)
        .then((responseJson) => {
          const newAttachments = responseJson.map((data) => ({
            id: data.id,
            objectName: data.objectName,
            signedUrl: data.signedUrl,
            md5: data.md5,
            type: data.type,
            contentType: data.contentType,
            name: data.name,
          }))
          setAttachmentIds([...attachmentIds].concat(newAttachments.map((a) => a.id)))
          setAvailableFiles([...availableFiles].concat(newAttachments))
        })
        .catch((error) => {
          console.error("error: ", error)
        })
        .finally(() => {
          setUploadingNewAttachmentFiles(false)
        })
    } else {
      return []
    }
  }

  function handleSave() {
    setErrors({})

    const variables = {
      name: name.trim().substring(0, 255),
      type,
      subject: subject.trim().substring(0, 1000),
      body: JSON.stringify(body),
      attachmentIds,
    }

    if (idParam) {
      variables.id = idParam
      editEmailTemplate({ variables })
    } else {
      createEmailTemplate({ variables })
    }
  }

  function getTemplateFieldOptions(): TemplateFieldOption[] {
    if (type === EmailTemplateTypes.JOB) {
      return allTemplateFieldOptions.filter(
        (f) => !f.key.startsWith("estimate.") && !f.key.startsWith("invoice.")
      )
    } else if (type === EmailTemplateTypes.INVOICE) {
      return allTemplateFieldOptions.filter((f) => !f.key.startsWith("estimate."))
    } else if (type === EmailTemplateTypes.ESTIMATE) {
      return allTemplateFieldOptions.filter((f) => !f.key.startsWith("invoice."))
    } else {
      return []
    }
  }

  const toggleAttachment = (id: string) => {
    if (attachmentIds.includes(id)) {
      setAttachmentIds(attachmentIds.filter((a) => a !== id))
    } else {
      setAttachmentIds([...attachmentIds, id])
    }
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  const renderEditorContent = () => {
    // When editing an existing template, we want to be very careful to not render the editor until we have the template content loaded,
    // otherwise the editor will invoke our onChange callback and wrap the content in a parent container,
    // so the content grows unnecessarily every time the user hits save.
    if (idParam && (getEmailTemplateLoading || !initialBody)) {
      return <CircularProgress color="secondary" />
    } else if (initialBody) {
      const mutableBody = JSON.parse(JSON.stringify(body))
      return (
        <>
          {inPreviewMode ? (
            <iframe
              sandbox=""
              srcDoc={toHtml(resolveTemplate(mutableBody, SAMPLE_DATA, t))}
              style={{
                width: "100%",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "4px",
              }}
            />
          ) : null}
          <RichTextComposer
            hide={inPreviewMode}
            onChange={setBody}
            templateFieldOptions={templateFieldOptions}
            value={initialBody}
          />
        </>
      )
    }
  }

  const loading = getEmailTemplateLoading
  const saving = editEmailTemplateLoading || createEmailTemplateLoading
  const templateFieldOptions = getTemplateFieldOptions()

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/templates", titleKey: "templates" },
              { to: "/app/settings/templates/email", titleKey: "emailTemplates" },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey={id ? "edit" : "create"}
          />
          {loading ? (
            <Box sx={classes.spinnerContainer}>
              <CircularProgress color="secondary" />
              <p>{t("loading")} ...</p>
            </Box>
          ) : (
            <>
              <Paper sx={[classes.contentContainer, classes.headerControlsContainer]}>
                <Box sx={classes.nameAndTypeContainer}>
                  <Box sx={classes.nameFieldContainer}>
                    <FielderTextField
                      error={!!errors.name}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      label={t("templateName")}
                      name="name"
                      onBlur={() => {
                        if (isBlank(name)) {
                          setErrors({
                            ...errors,
                            name: "page.emailTemplate.validation.templateName.required",
                          })
                        } else {
                          setErrors({
                            ...errors,
                            name: null,
                          })
                        }
                      }}
                      onChange={(e) => {
                        const val = e.target.value
                        if (isBlank(val)) {
                          setErrors({
                            ...errors,
                            name: "page.emailTemplate.validation.templateName.required",
                          })
                        } else {
                          setErrors({
                            ...errors,
                            name: null,
                          })
                        }
                        setName(val)
                      }}
                      onFocus={(e) => e.target.select()}
                      placeholder={t("page.emailTemplate.templateNamePlaceholder")}
                      required
                      style={{ marginTop: 0 }}
                      value={name}
                    />
                    {errors.name ? (
                      <FieldHelperText error message={t(errors.name)} />
                    ) : (
                      <FieldHelperText message={t("page.emailTemplate.helperText.templateName")} />
                    )}
                  </Box>
                  {idParam ? (
                    <Box sx={classes.templateTypeContainer}>
                      {t(`page.emailTemplate.typeDescription.${type}`)}
                    </Box>
                  ) : (
                    <Box sx={classes.templateTypeContainer}>
                      <FormControl component="fieldset">
                        <Box sx={classes.templateTypeLegendContainer}>
                          <legend style={{ fontSize: "1rem", fontWeight: "bold" }}>
                            {t("page.emailTemplate.templateTypeLabel")}
                          </legend>
                          <Box
                            style={{
                              fontSize: "1.125rem",
                              marginTop: "0.125rem",
                              marginLeft: "0.5rem",
                            }}
                          >
                            <Tooltip
                              arrow
                              enterDelay={300}
                              placement="right"
                              title={t("page.emailTemplate.templateTypeTooltip") as string}
                            >
                              <InfoOutlinedIcon fontSize="inherit" />
                            </Tooltip>
                          </Box>
                        </Box>
                        <RadioGroup
                          name="template-type-group"
                          onChange={(e: any) => {
                            setIsDirty(true)
                            setType(e.target.value)
                          }}
                          value={type}
                        >
                          <FormControlLabel
                            classes={{ label: classes.radioButtonLabel }}
                            control={<Radio />}
                            label={
                              <TemplateTypeOption
                                description={t(
                                  "page.emailTemplate.templateTypeOptions.ESTIMATE.description"
                                )}
                                label={t("page.emailTemplate.templateTypeOptions.ESTIMATE.label")}
                              />
                            }
                            value={EmailTemplateTypes.ESTIMATE}
                          />
                          <FormControlLabel
                            classes={{ label: classes.radioButtonLabel }}
                            control={<Radio />}
                            label={
                              <TemplateTypeOption
                                description={t(
                                  "page.emailTemplate.templateTypeOptions.INVOICE.description"
                                )}
                                label={t("page.emailTemplate.templateTypeOptions.INVOICE.label")}
                              />
                            }
                            value={EmailTemplateTypes.INVOICE}
                          />
                          <FormControlLabel
                            classes={{ label: classes.radioButtonLabel }}
                            control={<Radio />}
                            label={
                              <TemplateTypeOption
                                description={t(
                                  "page.emailTemplate.templateTypeOptions.JOB.description"
                                )}
                                label={t("page.emailTemplate.templateTypeOptions.JOB.label")}
                              />
                            }
                            value={EmailTemplateTypes.JOB}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  )}
                </Box>
                <Box sx={classes.buttonContainer}>
                  <Button
                    color="primary"
                    data-testid="saveEmailTemplateButton"
                    disabled={!name || saving}
                    onClick={handleSave}
                    sx={{
                      fontWeight: "bold",
                      minWidth: "135px",
                    }}
                    variant="contained"
                  >
                    {saving ? (
                      <CircularProgress color="secondary" size={20} thickness={6.0} />
                    ) : (
                      <span>{t("saveTemplate")}</span>
                    )}
                  </Button>
                </Box>
              </Paper>
              <Paper sx={[classes.contentContainer, classes.mainContentContainer]}>
                <Box sx={classes.rowContainer}>
                  <Box sx={classes.rowItem}>
                    <FormControl fullWidth>
                      <FielderTextField
                        aria-label={t("subject")}
                        id="subject"
                        inputProps={{ maxLength: 1000 }}
                        label={t("subject")}
                        name="subject"
                        onChange={(e) => setSubject(e.target.value)}
                        placeholder={t("subject")}
                        style={{
                          marginTop: 0,
                        }}
                        value={subject}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box style={{ flexGrow: 1 }} sx={classes.rowContainer}>
                  <label css={classes.fieldLabel}>{t("page.emailTemplate.body")}</label>
                  <Box sx={classes.editorContainer}>{renderEditorContent()}</Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={inPreviewMode}
                        onChange={() => {
                          setInPreviewMode(!inPreviewMode)
                        }}
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "green",
                            "& + .MuiSwitch-track": {
                              backgroundColor: "green",
                            },
                          },
                        }}
                      />
                    }
                    label={t("page.emailTemplate.preview") as string}
                    labelPlacement="start"
                    style={{ alignSelf: "flex-end" }}
                  />
                </Box>
                <Box sx={classes.rowContainer}>
                  <label css={classes.fieldLabel}>{t("attachments")}</label>
                  <Box sx={classes.rowItem}>
                    <div {...getRootProps({ css: "dropzone" })}>
                      <input {...getInputProps()} />
                      <EmailAttachments
                        attachmentIds={attachmentIds}
                        availableFiles={availableFiles}
                        isUploading={uploadingNewAttachmentFiles}
                        onClickUploadFromComputer={openFileDialog}
                        onDeselectAttachment={toggleAttachment}
                        onSelectAttachment={toggleAttachment}
                      />
                    </div>
                  </Box>
                </Box>
              </Paper>
            </>
          )}
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
  },
  spinnerContainer: {
    padding: "6.25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "1161px",
    maxWidth: "1161px",
    margin: "0 auto",
    marginBottom: "1rem",
    padding: "1.25rem",
  },
  mainContentContainer: {
    minHeight: "600px",
  },
  headerControlsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "0.625rem",
    marginTop: "0.625rem",
    width: "100%",
  },
  rowItem: {
    flex: 1,
  },
  nameAndTypeContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minWidth: "400px",
    maxWidth: "600px",
  },
  nameFieldContainer: {
    flex: 1,
  },
  templateTypeContainer: {
    marginTop: "1.5rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  templateTypeLabel: {
    fontWeight: "bold",
  },
  radioButtonLabel: {
    fontSize: "0.875rem",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "0.625rem",
    marginTop: "0.625rem",
  },
  fieldLabel: {
    fontWeight: "bold",
  },
  editorContainer: {
    display: "flex",
    marginBottom: "1.25rem",
    flexGrow: 1,
    alignSelf: "stretch",
    height: "14rem",
    minHeight: "12rem !important",
    overflow: "hidden",
    overflowY: "scroll",
    overflowX: "scroll",
  },
  editor: {
    height: "87%",
  },
  templateTypeOptionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  templateTypeLegendContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  templateTypeOptionLabel: {
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
  templateTypeOptionDescription: {
    fontSize: "0.64rem",
    fontStyle: "italic",
  },
}

export default EditEmailTemplate
