import React from "react"
import { useTranslation } from "react-i18next"
import SelectorField from "./SelectorField"
import FieldSpinner from "./FieldSpinner"
import { useQuery } from "@apollo/client"
import { ALL_ORGANIZATIONS } from "../queries/allOrganizations"
import { Organization } from "../types"

interface OrgSelectProps {
  readonly ariaLabel?: string
  readonly disabled?: boolean
  readonly error?: boolean
  readonly includeParentOrg?: boolean
  readonly label?: string
  readonly margin?: "none" | "dense" | undefined
  readonly name: string
  readonly onBlur?: () => void
  readonly onChange: (value: any) => void
  readonly parentOrg?: Organization
  readonly required?: boolean
  readonly size?: "small" | "medium"
  readonly style?: React.CSSProperties
  readonly testID?: string
  readonly value?: string
  readonly variant?: "standard" | "outlined" | "filled"
}

function OrgSelect({
  ariaLabel,
  disabled = false,
  error: hasError,
  includeParentOrg = false,
  label,
  margin = "none",
  name,
  onBlur,
  onChange,
  parentOrg,
  required,
  size,
  style,
  testID = "SelectorField",
  value = "",
  variant = "filled",
}: OrgSelectProps) {
  const { t } = useTranslation()

  const {
    loading,
    error: queryError,
    data,
  } = useQuery(ALL_ORGANIZATIONS, {
    fetchPolicy: "cache-and-network",
  })

  if (loading) {
    return (
      <div style={style}>
        <FieldSpinner message={t("loadingOrganizations")} />
      </div>
    )
  }

  if (queryError) return <div>Error! {queryError.message}</div>

  const options = data.allOrganizations?.edges?.map((e) => {
    const node = e.node
    return {
      id: node.id,
      name: node.name,
    }
  })

  if (includeParentOrg && parentOrg && !options.some((o) => o.id === parentOrg.id)) {
    // assuming the parent org is the user's org.. put it on top of the list.
    options.unshift({ id: parentOrg.id, name: parentOrg.name })
  }

  return (
    <SelectorField
      aria-label={ariaLabel || label}
      disabled={disabled}
      error={hasError}
      label={label}
      margin={margin}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      required={required}
      size={size}
      style={style}
      testID={testID}
      value={value}
      variant={variant}
    />
  )
}

export default OrgSelect
