import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Box from "@mui/material/Box"
import capitalize from "capitalize"
import FielderTextField from "../../../../components/FielderTextField"
import FieldHelperText from "../../../../components/FieldHelperText"
import { isBlank } from "../../../../util"
import { CurrencyCode, LanguageCode, Region } from "../../../../types"
import SaveButton from "../../../../components/SaveButton"
import CurrencyCodeSelect from "../../../../components/CurrencyCodeSelect"
import LanguageSelect from "../../../../components/LanguageSelect"

interface RegionDialogProps {
  region?: Region
  loading?: boolean
  onCancel: () => void
  onSave: (
    name: string,
    code: string,
    currencyCode: CurrencyCode,
    languageCode?: LanguageCode | null
  ) => void
  open?: boolean
}

function RegionDialog({
  region,
  loading = false,
  onCancel,
  onSave,
  open = false,
}: RegionDialogProps) {
  const { t } = useTranslation()
  const [name, setName] = useState<string>(region?.name ?? "")
  const [code, setCode] = useState<string>(region?.code ?? "")
  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>(
    region?.currencyCode ?? CurrencyCode.CAD // default to CAD for now, cuz it'd make our only customer a little happier than seeing USD as the default
  )
  const [languageCode, setLanguageCode] = useState<LanguageCode | undefined | null>(
    region?.languageCode
  )
  const [touched, setTouched] = useState(() => ({
    name: false,
    code: false,
    currencyCode: false,
    languageCode: false,
  }))
  const [errors, setErrors] = useState(() => ({
    name: null,
    code: null,
    currencyCode: null,
    languageCode: null,
  }))

  const isInvalid = (): boolean => {
    return (
      Boolean(errors.name) ||
      Boolean(errors.code) ||
      Boolean(errors.currencyCode) ||
      isBlank(name) ||
      isBlank(code) ||
      isBlank(currencyCode) ||
      currencyCode?.length !== 3
    )
  }

  const handleBlurName = () => {
    setTouched((prev) => ({
      ...prev,
      name: true,
    }))
    if (isBlank(name)) {
      setErrors((prev) => ({
        ...prev,
        name: t("component.regionDialog.validation.name.required"),
      }))
    } else {
      setName(capitalize.words(name, true))
      setErrors((prev) => ({
        ...prev,
        name: null,
      }))
    }
  }

  const handleChangeName = useCallback((e) => {
    const value = e.target.value
    setName(value)
    setErrors((prev) => ({
      ...prev,
      name: null,
    }))
  }, [])

  const handleBlurCode = () => {
    setTouched((prev) => ({
      ...prev,
      code: true,
    }))
    if (isBlank(code)) {
      setErrors((prev) => ({
        ...prev,
        code: t("component.regionDialog.validation.code.required"),
      }))
    } else {
      setCode(code)
      setErrors((prev) => ({
        ...prev,
        code: null,
      }))
    }
  }

  const handleChangeCode = useCallback((e) => {
    const value = e.target.value?.replace(" ", "_")?.toUpperCase()
    setCode(value)
    setErrors((prev) => ({
      ...prev,
      code: null,
    }))
  }, [])

  const handleChangeCurrencyCode = useCallback((value: CurrencyCode) => {
    setCurrencyCode(value)
    setErrors((prev) => ({
      ...prev,
      currencyCode: null,
    }))
  }, [])

  const handleChangeLanguageCode = useCallback((value?: LanguageCode | null) => {
    setLanguageCode(value)
    setErrors((prev) => ({
      ...prev,
      languageCode: null,
    }))
  }, [])

  return (
    <Dialog
      aria-labelledby="edit-region-dialog-title"
      data-testid="RegionDialog"
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogTitle
        id="edit-region-dialog-title"
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {region?.id ? t("editRegion") : t("addRegion")}
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <Box sx={classes.fieldContainer}>
          <FielderTextField
            data-testid="name-Field"
            error={!!errors.name}
            fullWidth
            id="name"
            inputProps={{ maxLength: 255 }}
            label={t("name")}
            name="name"
            onBlur={handleBlurName}
            onChange={handleChangeName}
            onFocus={(e) => e.target.select()}
            required
            value={name}
          />
          {!errors.name && (
            <FieldHelperText
              data-testid="name-help"
              message={t("component.regionDialog.helperText.name")}
            />
          )}
          {errors.name && touched.name ? (
            <FieldHelperText data-testid="name-Error" error message={errors.name} />
          ) : null}
        </Box>
        <Box sx={classes.fieldContainer}>
          <FielderTextField
            data-testid="code-Field"
            error={!!errors.code}
            fullWidth
            id="code"
            inputProps={{ maxLength: 20 }}
            label={t("code")}
            name="code"
            onBlur={handleBlurCode}
            onChange={handleChangeCode}
            onFocus={(e) => e.target.select()}
            required
            value={code}
          />
          {!errors.code && (
            <FieldHelperText
              data-testid="code-help"
              message={t("component.regionDialog.helperText.code")}
            />
          )}
          {errors.code && touched.code ? (
            <FieldHelperText data-testid="code-Error" error message={errors.code} />
          ) : null}
        </Box>
        <Box sx={classes.fieldContainer}>
          <CurrencyCodeSelect
            name="currencyCode"
            onChange={handleChangeCurrencyCode}
            required
            value={currencyCode}
          />
          {!errors.currencyCode && (
            <FieldHelperText
              data-testid="currencyCode-help"
              message={t("component.regionDialog.helperText.currencyCode")}
            />
          )}
          {errors.currencyCode && touched.currencyCode ? (
            <FieldHelperText data-testid="currencyCode-Error" error message={errors.currencyCode} />
          ) : null}
        </Box>
        <Box sx={classes.fieldContainer}>
          <LanguageSelect
            label={t("language") as string}
            name="languageCode"
            onChange={handleChangeLanguageCode}
            value={languageCode}
          />
          {!errors.currencyCode && (
            <FieldHelperText
              data-testid="languageCode-help"
              message={t("component.regionDialog.helperText.languageCode")}
            />
          )}
          {errors.languageCode && touched.languageCode ? (
            <FieldHelperText data-testid="languageCode-Error" error message={errors.languageCode} />
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions sx={classes.dialogActions}>
        <Button color="secondary" disabled={loading} onClick={onCancel} variant="outlined">
          {t("cancel")}
        </Button>
        <SaveButton
          disabled={loading || isInvalid()}
          loading={loading}
          onClick={() => {
            onSave?.(name, code, currencyCode, languageCode)
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

const dialogBackgroundColor = "#FFFFFF"
const classes = {
  dialogContent: {
    backgroundColor: dialogBackgroundColor,
    paddingTop: "2rem",
  },
  dialogActions: {
    backgroundColor: dialogBackgroundColor,
    padding: "1.5625rem",
    paddingBottom: "1rem",
    paddingTop: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  fieldContainer: {
    margin: "1.25rem 0",
  },
} as const

export default RegionDialog
