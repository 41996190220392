import React from "react"

/**
 * This component is intended to be used as the `fallback` for
 * the Sentry.ErrorBoundary component, which will pass in an `error` prop.
 */
function ErrorFallback({ error }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        padding: "2.5rem",
      }}
    >
      <div>
        <h2>Something went wrong.</h2>
        <p>
          Sorry, it looks like Fielder is having some trouble right now. Our team has been notified
          and is working on a solution.
        </p>
        <details style={{ whiteSpace: "pre-wrap" }}>
          {error && error.toString()}
          <br />
          {error && error.componentStack}
        </details>
      </div>
    </div>
  )
}

export default ErrorFallback
