import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import MenuList from "@mui/material/MenuList"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined"
import { InvoiceCustomerVisibility } from "~/types"

export interface InvoiceCustomerVisibilitySelectOption {
  id: keyof InvoiceCustomerVisibility
  name: string
  childOptions?: InvoiceCustomerVisibilitySelectOption[]
}

interface Props {
  readonly onToggleOption: (option: InvoiceCustomerVisibilitySelectOption) => void
  readonly customerVisibility: InvoiceCustomerVisibility
}

export default function InvoiceCustomerVisibilitySelect({
  onToggleOption,
  customerVisibility,
}: Props) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const options: InvoiceCustomerVisibilitySelectOption[] = [
    {
      id: "showDueDate",
      name: t("component.invoiceCustomerVisibilitySelect.options.showDueDate"),
    },
    {
      id: "showLineItems",
      name: t("component.invoiceCustomerVisibilitySelect.options.showLineItems"),
      childOptions: [
        {
          id: "showLineItemCode",
          name: t("component.invoiceCustomerVisibilitySelect.options.showLineItemCode"),
        },
        {
          id: "showLineItemName",
          name: t("component.invoiceCustomerVisibilitySelect.options.showLineItemName"),
        },
        {
          id: "showLineItemDescription",
          name: t("component.invoiceCustomerVisibilitySelect.options.showLineItemDescription"),
        },
        {
          id: "showLineItemQuantity",
          name: t("component.invoiceCustomerVisibilitySelect.options.showLineItemQuantity"),
        },
        {
          id: "showLineItemUnitPrice",
          name: t("component.invoiceCustomerVisibilitySelect.options.showLineItemUnitPrice"),
        },
        {
          id: "showLineItemSubtotal",
          name: t("component.invoiceCustomerVisibilitySelect.options.showLineItemSubtotal"),
        },
        {
          id: "showLineItemBundleComponents",
          name: t("component.invoiceCustomerVisibilitySelect.options.showLineItemBundleComponents"),
          childOptions: [
            {
              id: "showLineItemBundleComponentQuantity",
              name: t(
                "component.invoiceCustomerVisibilitySelect.options.showLineItemBundleComponentQuantity"
              ),
            },
            {
              id: "showLineItemBundleComponentUnitPrice",
              name: t(
                "component.invoiceCustomerVisibilitySelect.options.showLineItemBundleComponentUnitPrice"
              ),
            },
          ],
        },
      ],
    },
    {
      id: "showFooter",
      name: t("component.invoiceCustomerVisibilitySelect.options.showFooter"),
      childOptions: [
        {
          id: "showFooterSubtotal",
          name: t("component.invoiceCustomerVisibilitySelect.options.showFooterSubtotal"),
        },
        {
          id: "showFooterDiscount",
          name: t("component.invoiceCustomerVisibilitySelect.options.showFooterDiscount"),
        },
        {
          id: "showFooterTaxes",
          name: t("component.invoiceCustomerVisibilitySelect.options.showFooterTaxes"),
        },
        {
          id: "showFooterTotal",
          name: t("component.invoiceCustomerVisibilitySelect.options.showFooterTotal"),
        },
      ],
    },
  ]

  function renderOptions(
    options: InvoiceCustomerVisibilitySelectOption[],
    parentOption?: InvoiceCustomerVisibilitySelectOption
  ) {
    return (
      <MenuList dense sx={{ padding: "0", paddingLeft: parentOption ? "2rem" : "0" }}>
        {options.map((option) => {
          return (
            <Box key={option.id}>
              <MenuItem
                onClick={() => {
                  onToggleOption?.(option)
                }}
              >
                <Checkbox checked={customerVisibility[option.id] ?? false} />
                <Box component="span" sx={{ fontSize: "0.875rem" }}>
                  {option.name}
                </Box>
              </MenuItem>
              {option.childOptions ? renderOptions(option.childOptions, option) : null}
            </Box>
          )
        })}
      </MenuList>
    )
  }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Button
          aria-controls={open ? "customer-visiblity-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          id="customer-visiblity-menu-button"
          onClick={handleClick}
          startIcon={<VisibilityOutlinedIcon />}
          variant="outlined"
        >
          <Box sx={{ whiteSpace: "nowrap" }}>
            {t("component.invoiceCustomerVisibilitySelect.label")}
          </Box>
        </Button>
      </Box>
      <Menu
        MenuListProps={{
          "aria-labelledby": "customer-visiblity-menu-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id="customer-visiblity-menu"
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            fontSize: "0.875rem",
            padding: "0.5rem 1.5rem 1rem 1.5rem",
            marginBottom: "0.5rem",
            color: (theme) => theme.fielderColors.mutedText,
            maxWidth: "24rem",
            borderBottom: "1px solid #e2e2e1",
          }}
        >
          <Trans
            components={{
              anchor: (
                <a
                  href="/app/settings/organization/edit"
                  style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    gap: "2px",
                    alignItems: "center",
                  }}
                  target="_blank"
                />
              ),
              anchor_icon: <OpenInNewOutlinedIcon sx={{ fontSize: "12px" }} />,
            }}
            i18nKey="component.invoiceCustomerVisibilitySelect.helperText"
          />
        </Box>
        {renderOptions(options)}
        <Box sx={{ paddingBottom: "2rem" }} />
      </Menu>
    </div>
  )
}
