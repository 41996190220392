import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { nanoid } from "nanoid"
import { useQuery, useMutation, gql } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Paper from "@mui/material/Paper"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/EditOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import WarningIcon from "@mui/icons-material/Warning"
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrashOutlined"
import Skeleton from "@mui/material/Skeleton"
import { ALL_WORK_ORDERS_FOR_JOB } from "~/queries/allWorkOrdersForJob"
import { EDIT_WORK_ORDER } from "~/queries/editWorkOrder"
import {
  calculateLineSubtotal,
  calculateSubtotal,
  calculateTotal,
  formatDate,
  formatPersonName,
  getJobDocumentDisplayNumber,
  isEmpty,
  NOT_SPECIFIED,
  parseGraphQLErrorCode,
  truncate,
} from "~/util"
import { useAuth } from "~/context/AuthContext"
import PdfIcon from "~/components/icons/PdfIcon"
import FielderIconButton from "~/components/FielderIconButton"
import NoResultsRow from "~/components/NoResultsRow"
import SnackbarMessage from "~/components/SnackbarMessage"
import WorkOrderDialog from "./WorkOrderDialog"
import { DefaultPermission, Job, Snack, WorkOrder } from "~/types"
import { useLocation } from "react-router-dom"

const GET_WORK_ORDER_FILE = gql`
  query GetWorkOrderFile($id: ID!) {
    getWorkOrderById(id: $id) {
      id
      number
      pdfFile
    }
  }
`

const NUM_COLUMNS = 4

interface JobWorkOrderTabProps {
  readonly job: Job
}

function openPdf(pdfString: string) {
  // window.open(url, "_blank", "width=1200,height=900")
  if (!pdfString) {
    return
  }
  const byteCharacters = atob(pdfString)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: "application/pdf;base64" })
  const fileURL = URL.createObjectURL(file)
  window.open(fileURL)
}

function JobWorkOrderTab({ job }: JobWorkOrderTabProps) {
  const { t } = useTranslation()
  const { user, hasPermissions, isImpersonating } = useAuth()
  const [workOrderDialogOpen, setWorkOrderDialogOpen] = useState<boolean>(false)
  const [workOrderToEdit, setWorkOrderToEdit] = useState<WorkOrder | null>()
  const [loadWorkOrderFileId, setLoadWorkOrderFileId] = useState<string>()
  const [snack, setSnack] = useState<Snack>()
  const [includeArchived, setIncludeArchived] = useState<boolean>(false)
  const [openArchiveDialog, setOpenArchiveDialog] = useState<boolean>(false)
  const canEdit = !isImpersonating && hasPermissions?.([DefaultPermission.UpdateWorkOrder])
  const location = useLocation()
  const [stateWorkOrderId, setStateWorkOrderId] = useState<string | undefined>(() => {
    const state = location?.state as { workOrderId?: string }
    return state?.workOrderId
  })

  const { loading: fileLoading } = useQuery(GET_WORK_ORDER_FILE, {
    variables: {
      id: loadWorkOrderFileId,
    },
    skip: !loadWorkOrderFileId,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setLoadWorkOrderFileId(undefined)
      openPdf(data?.getWorkOrderById?.pdfFile)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const { loading, error, data } = useQuery(ALL_WORK_ORDERS_FOR_JOB, {
    variables: {
      jobId: job.id,
      includeArchived,
    },
    fetchPolicy: "cache-and-network",
  })

  const [archiveWorkOrder, { loading: archiveWorkOrderLoading }] = useMutation(EDIT_WORK_ORDER, {
    onCompleted: () => {
      setWorkOrderToEdit(undefined)
    },
    onError: (error) => {
      Sentry.captureException(error)
    },
    refetchQueries: () => {
      return ["AllWorkOrdersForJob"]
    },
  })

  function handleEdit(workOrder: WorkOrder) {
    const editableLineItems = workOrder.lineItems
      .map((li) => {
        return {
          ...li,
          organizationItemId: li.organizationItem.id,
          key: li.id,
          description: li.description ?? "",
          quantity: !isEmpty(li.quantity) ? `${li.quantity}` : "",
          unitPrice: !isEmpty(li.unitPrice) ? `${li.unitPrice}` : "",
          subTotal: calculateLineSubtotal(li.quantity, li.unitPrice),
          total: li.total,
        }
      })
      .sort((a, b) => a.number - b.number)

    const editableWorkOrder = {
      id: workOrder.id,
      job: workOrder.job,
      jobAssignment: workOrder.jobAssignment,
      number: workOrder.number,
      currencyCode: workOrder.currencyCode ?? user?.organization?.currencyCode,
      subTotal: calculateSubtotal(workOrder.lineItems),
      total: calculateTotal(workOrder.lineItems, 0, null),
      createdAt: workOrder.createdAt,
      description: workOrder.description,
      notes: workOrder.notes,
      lineItems: editableLineItems,
    }

    setWorkOrderToEdit(editableWorkOrder)
    setWorkOrderDialogOpen(true)
  }

  const workOrders = [...(data?.getJobById?.workOrders || [])].sort((a, b) => b.number - a.number)

  if (stateWorkOrderId) {
    const workOrder = workOrders.find((workOrders) => workOrders.id === stateWorkOrderId)
    if (workOrder) {
      handleEdit(workOrder)
      setStateWorkOrderId(undefined)
    }
  }

  return (
    <>
      {workOrderDialogOpen && workOrderToEdit && user ? (
        <WorkOrderDialog
          job={job}
          onCancel={() => setWorkOrderDialogOpen(false)}
          onSave={(workOrder) => {
            setWorkOrderDialogOpen(false)
            if (workOrder) {
              setSnack({ messageKey: "messages.changesSaved", variant: "success" })
            } else {
              setSnack({ messageKey: "messages.error", variant: "error" })
            }
          }}
          open={workOrderDialogOpen}
          user={user}
          workOrderId={workOrderToEdit.id}
        />
      ) : null}
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Paper
        sx={{
          overflowX: "auto",
          marginBottom: "1.25rem",
          maxHeight: "500px",
          height: "500px",
        }}
      >
        <Table
          stickyHeader
          sx={[
            {
              width: "100%",
              minWidth: "72rem",
            },
            workOrders?.length === 0 && { height: "100%" },
          ]}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={classes.tableHead}>{t("workOrderNumber")}</TableCell>
              <TableCell sx={classes.tableHead}>{t("jobAssignment")}</TableCell>
              <TableCell sx={classes.tableHead}>{t("description")}</TableCell>
              <TableCell style={{ textAlign: "right" }} sx={classes.tableHead}>
                {!isImpersonating && hasPermissions?.([DefaultPermission.CreateWorkOrder]) ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: "1rem",
                    }}
                  >
                    <Box sx={{ "& label": { whiteSpace: "nowrap" } }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={includeArchived}
                            onChange={(event, checked) => {
                              setIncludeArchived(checked)
                            }}
                            value="includeArchived"
                          />
                        }
                        label={t("includeArchived") as string}
                      />
                    </Box>
                    <Button
                      aria-label={t("createWorkOrder") as string}
                      color="primary"
                      data-testid="createWorkOrderButton"
                      onClick={() => {
                        setWorkOrderToEdit({})
                        setWorkOrderDialogOpen(true)
                      }}
                      sx={{
                        fontWeight: "bold",
                        "& svg": {
                          fontSize: "1.0rem",
                        },
                        "& div": {
                          marginLeft: "0.625rem",
                          marginRight: "0.625rem",
                        },
                      }}
                      variant="contained"
                    >
                      <AddIcon />
                      <Box sx={{ whiteSpace: "nowrap" }}>{t("createWorkOrder")}</Box>
                    </Button>
                  </Box>
                ) : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={workOrders?.length === 0 ? { height: "100%" } : {}}>
            {!error && !loading && workOrders?.length === 0 && (
              <NoResultsRow colSpan={NUM_COLUMNS} />
            )}
            {!error &&
              workOrders?.map((workOrder) => {
                return (
                  <TableRow
                    key={workOrder.id}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (canEdit) {
                        handleEdit(workOrder)
                      } else {
                        setLoadWorkOrderFileId(workOrder.id) // load the PDF
                      }
                    }}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "#f8f8f8",
                      },
                    }}
                  >
                    <TableCell>{getJobDocumentDisplayNumber(workOrder)}</TableCell>
                    <TableCell>
                      {workOrder.jobAssignment ? (
                        <Box>
                          <Box sx={{ whiteSpace: "nowrap" }}>
                            {formatDate(
                              workOrder.jobAssignment?.startDate,
                              t("format:dateFormat.fullDate"),
                              user?.organization?.timeZone,
                              user
                            )}
                          </Box>
                          <Box>
                            <span>
                              {formatDate(
                                workOrder.jobAssignment?.startDate,
                                t("format:dateFormat.time"),
                                user?.organization?.timeZone,
                                user
                              )}
                            </span>
                            <span>{" - "}</span>
                            <span>
                              {formatDate(
                                workOrder.jobAssignment?.endDate,
                                t("format:dateFormat.time"),
                                user?.organization?.timeZone,
                                user
                              )}
                            </span>
                          </Box>
                          <Box>
                            {workOrder.jobAssignment?.assignees?.map((a) => (
                              <Box key={a.id}>{formatPersonName(a)}</Box>
                            ))}
                          </Box>
                        </Box>
                      ) : (
                        <Box>{NOT_SPECIFIED}</Box>
                      )}
                    </TableCell>
                    <TableCell>{truncate(workOrder.description)}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {workOrder.isArchived ? (
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "auto",
                              backgroundColor: "rgba(218, 21, 5, 0.1)",
                              padding: "4px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            <WarningIcon
                              style={{
                                fontSize: "1.125rem",
                                color: "#DA1505",
                                marginRight: "0.625rem",
                              }}
                            />
                            <span>{t("thisWorkOrderHasBeenArchived")}</span>
                          </Box>
                        ) : null}
                        {!workOrder.isArchived && (
                          <FielderIconButton
                            disabled={Boolean(
                              (archiveWorkOrderLoading && workOrderToEdit?.id === workOrder.id) ||
                                (fileLoading && loadWorkOrderFileId === workOrder.id)
                            )}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setLoadWorkOrderFileId(workOrder.id)
                            }}
                            sx={classes.rowAction}
                            title={t("viewPDF") as string}
                          >
                            {fileLoading && loadWorkOrderFileId === workOrder.id ? (
                              <CircularProgress color="secondary" size={20} thickness={6.0} />
                            ) : (
                              <PdfIcon />
                            )}
                          </FielderIconButton>
                        )}
                        {canEdit ? (
                          <FielderIconButton
                            aria-label={t("edit") as string}
                            disabled={Boolean(
                              archiveWorkOrderLoading && workOrderToEdit?.id === workOrder.id
                            )}
                            sx={classes.rowAction}
                            title={t("edit") as string}
                          >
                            <EditIcon />
                          </FielderIconButton>
                        ) : null}
                        {!isImpersonating &&
                        hasPermissions?.([DefaultPermission.UpdateWorkOrder]) ? (
                          <FielderIconButton
                            disabled={Boolean(
                              archiveWorkOrderLoading && workOrderToEdit?.id === workOrder.id
                            )}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setWorkOrderToEdit(workOrder)
                              setOpenArchiveDialog(true)
                            }}
                            sx={classes.rowAction}
                            title={(workOrder.isArchived ? t("unarchive") : t("archive")) as string}
                          >
                            {archiveWorkOrderLoading && workOrderToEdit?.id === workOrder.id ? (
                              <CircularProgress size={20} thickness={6.0} />
                            ) : workOrder.isArchived ? (
                              <RestoreFromTrashIcon />
                            ) : (
                              <DeleteIcon />
                            )}
                          </FielderIconButton>
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            {loading
              ? [...Array(NUM_COLUMNS).keys()].map((i) => (
                  <TableRow key={i}>
                    {[...Array(NUM_COLUMNS).keys()].map((j) => (
                      <TableCell key={j}>
                        <Skeleton variant="text" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : null}
            {error ? (
              <TableRow>
                <TableCell align="center" colSpan={NUM_COLUMNS}>
                  <Typography color="error" variant="h6">
                    {t("errorLabel")}
                    {": "}
                    {error.message}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Paper>
      {openArchiveDialog && workOrderToEdit ? (
        <Dialog
          aria-describedby="archive-dialog-description"
          aria-labelledby="archive-dialog-title"
          onClose={() => setOpenArchiveDialog(false)}
          open={openArchiveDialog}
        >
          <DialogTitle id="archive-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-dialog-description">
              {workOrderToEdit.isArchived
                ? t("component.unarchiveWorkOrderDialog.confirmationPrompt", {
                    workOrderNumber: getJobDocumentDisplayNumber(workOrderToEdit),
                  })
                : workOrderToEdit.jobAssignment?.id
                  ? t("component.archiveWorkOrderDialog.withJobAssignmentConfirmationPrompt", {
                      workOrderNumber: getJobDocumentDisplayNumber(workOrderToEdit),
                    })
                  : t("component.archiveWorkOrderDialog.confirmationPrompt", {
                      workOrderNumber: getJobDocumentDisplayNumber(workOrderToEdit),
                    })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpenArchiveDialog(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                archiveWorkOrder({
                  variables: {
                    id: workOrderToEdit.id,
                    jobId: job.id,
                    isArchived: !workOrderToEdit.isArchived,
                  },
                })
                setOpenArchiveDialog(false)
              }}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

const classes = {
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
  },
  rowAction: {
    marginLeft: "0.5rem",
  },
} as const

export default JobWorkOrderTab
