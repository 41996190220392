import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import ChevronRight from "@mui/icons-material/ChevronRight"
import { Link } from "react-router-dom"

interface Breadcrumb {
  to: string
  titleKey: string
}
interface Props {
  readonly icon: React.ReactNode
  readonly breadcrumbs?: Breadcrumb[]
  readonly leafTitleKey?: string
  readonly leafTitle?: string
}

function PageHeader({ breadcrumbs, icon, leafTitleKey, leafTitle }: Props) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "block",
        fontSize: "1.5rem",
        marginBlockStart: "0.83rem",
        marginBlockEnd: "0.83rem",
        marginInlineStart: 0,
        marginInlineEnd: 0,
        marginBottom: "1.5rem",
        fontWeight: "bold",
        "& svg": {
          fontSize: "1.75rem",
          position: "relative",
          top: "0.375rem",
        },
      }}
    >
      {icon}{" "}
      {breadcrumbs?.map((b) => (
        <React.Fragment key={b.to}>
          <Link css={classes.breadcrumbLink} to={b.to}>
            {t(b.titleKey)}
          </Link>
          <ChevronRight />
        </React.Fragment>
      ))}
      {leafTitle ? leafTitle : leafTitleKey ? t(leafTitleKey) : null}
    </Box>
  )
}

const classes = {
  breadcrumbLink: {
    color: "#000",
    "&:visited": {
      color: "#000",
    },
    textDecoration: "underline",
  },
} as const

export default PageHeader
