import React, { FC } from "react"
import Box from "@mui/material/Box"

interface TotalRowProps {
  style?: React.CSSProperties
}
const TotalRow: FC<TotalRowProps> = ({ children, style, ...props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        marginTop: "0.3125rem",
      }}
      {...props}
      style={style}
    >
      {children}
    </Box>
  )
}

export default TotalRow
