import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import CircularProgress from "@mui/material/CircularProgress"
import { WorkOrder } from "../../../types"
import { getJobDocumentDisplayNumber } from "../../../util"
import { useNavigate } from "react-router-dom"

interface Props {
  readonly isLoading: boolean
  readonly error?: string | null
  readonly workOrder?: WorkOrder | null
  readonly onClose: () => void
}

function GenerateWorkOrderDialog({ isLoading, error, workOrder, onClose }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Dialog
      aria-labelledby="generate-workOrder-dialog"
      data-testid="GenerateWorkOrderDialog"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open
    >
      <DialogContent>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            py: "1.5rem",
            px: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <CircularProgress color="secondary" size={20} thickness={6.0} />
              <span>{t("component.generateWorkOrderDialog.loading")} ...</span>
            </Box>
          ) : workOrder ? (
            <Box>
              {t("component.generateWorkOrderDialog.success", {
                workOrderNumber: `${getJobDocumentDisplayNumber(workOrder)}`,
              })}
            </Box>
          ) : error ? (
            <Box>{error}</Box>
          ) : (
            <Box>{t("component.generateWorkOrderDialog.failure")}</Box>
          )}
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "1rem" }}
        >
          <Button
            color="primary"
            data-testid="loadButton"
            disabled={isLoading}
            onClick={onClose}
            variant="outlined"
          >
            <Box>{t("OK")}</Box>
          </Button>
          {workOrder?.job?.id ? (
            <Button
              color="primary"
              disabled={isLoading}
              onClick={() => {
                navigate(`/app/jobs/edit/${workOrder.job.id}/work_orders`, {
                  state: { workOrderId: workOrder.id },
                })
              }}
              variant="outlined"
            >
              <Box>{t("viewWorkOrder")}</Box>
            </Button>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default GenerateWorkOrderDialog
