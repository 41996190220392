/**
 * These are timezone IDs we know about. There should be keys in the translation files for each of them.
 * Don't bother with indicating the GMT offset, just give a label and be done with it.
 */
const TIMEZONES = [
  "Pacific/Niue",
  "Pacific/Pago_Pago",
  "Pacific/Rarotonga",
  "Pacific/Honolulu",
  "Pacific/Marquesas",
  "America/Adak",
  "America/Anchorage",
  "America/Juneau",
  "America/Dawson_Creek",
  "America/Los_Angeles",
  "Pacific/Vancouver",
  "America/Tijuana",
  "America/Boise",
  "America/Cambridge_Bay",
  "America/Denver",
  "America/Edmonton",
  "America/Phoenix",
  "America/Mazatlan",
  "America/Chicago",
  "America/Mexico_City",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Regina",
  "America/Guatemala",
  "America/Bogota",
  "America/Detroit",
  "America/New_York",
  "America/Nassau",
  "America/Port-au-Prince",
  "America/Thunder_Bay",
  "America/Toronto",
  "America/Lima",
  "America/Caracas",
  "Atlantic/Bermuda",
  "America/Glace_Bay",
  "America/Goose_Bay",
  "America/Halifax",
  "America/Guyana",
  "America/Argentina/Buenos_Aires",
  "America/Godthab",
  "America/Montevideo",
  "America/St_Johns",
  "America/Santiago",
  "America/Sao_Paulo",
  "Atlantic/South_Georgia",
  "Atlantic/Azores",
  "Atlantic/Cape_Verde",
  "UTC",
  "Etc/GMT",
  "Africa/Casablanca",
  "Europe/Dublin",
  "Europe/Lisbon",
  "Europe/London",
  "Africa/Monrovia",
  "Africa/Algiers",
  "Europe/Amsterdam",
  "Europe/Berlin",
  "Europe/Brussels",
  "Europe/Budapest",
  "Europe/Belgrade",
  "Europe/Prague",
  "Europe/Copenhagen",
  "Europe/Madrid",
  "Europe/Paris",
  "Europe/Rome",
  "Europe/Stockholm",
  "Europe/Vienna",
  "Europe/Warsaw",
  "Europe/Athens",
  "Europe/Bucharest",
  "Europe/Helsinki",
  "Africa/Cairo",
  "Asia/Jerusalem",
  "Africa/Johannesburg",
  "Europe/Kiev",
  "Europe/Riga",
  "Europe/Sofia",
  "Europe/Tallinn",
  "Europe/Vilnius",
  "Europe/Istanbul",
  "Asia/Baghdad",
  "Africa/Nairobi",
  "Europe/Minsk",
  "Europe/Moscow",
  "Asia/Riyadh",
  "Asia/Tehran",
  "Asia/Baku",
  "Asia/Tbilisi",
  "Asia/Yerevan",
  "Asia/Tashkent",
  "Asia/Colombo",
  "Asia/Almaty",
  "Asia/Dhaka",
  "Asia/Rangoon",
  "Asia/Bangkok",
  "Asia/Jakarta",
  "Asia/Shanghai",
  "Asia/Hong_Kong",
  "Asia/Kuala_Lumpur",
  "Asia/Irkutsk",
  "Asia/Singapore",
  "Asia/Taipei",
  "Asia/Ulaanbaatar",
  "Australia/Perth",
  "Asia/Yakutsk",
  "Australia/Eucla",
  "Asia/Seoul",
  "Asia/Tokyo",
  "Australia/Darwin",
  "Australia/Brisbane",
  "Pacific/Guam",
  "Asia/Magadan",
  "Pacific/Port_Moresby",
  "Australia/Adelaide",
  "Australia/Broken_Hill",
  "Australia/Hobart",
  "Australia/Sydney",
  "Pacific/Guadalcanal",
  "Pacific/Noumea",
  "Pacific/Majuro",
  "Asia/Kamchatka",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "Pacific/Apia",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
]

export default TIMEZONES
