import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FielderTextField from "../../../components/FielderTextField"
import ErrorMessage from "../../../components/ErrorMessage"
import * as Sentry from "@sentry/react"
import { CREATE_ESTIMATE_TEMPLATE } from "../../../queries/createEstimateTemplate"
import { asFloat, asInt } from "../../../util"
import { DiscountType, EstimateLineItem, EstimateLineItemDetail } from "../../../types"
import SaveButton from "../../../components/SaveButton"

interface SaveAsEstimateTemplateDialogProps {
  readonly description?: string
  readonly discountType?: DiscountType
  readonly discountValue?: string
  readonly lineItems?: EstimateLineItem[]
  readonly notes?: string
  readonly onCancel: () => void
  readonly onSave: (success: boolean, message: string, data?: any) => void
}

function SaveAsEstimateTemplateDialog({
  description,
  discountType,
  discountValue,
  lineItems,
  notes,
  onCancel,
  onSave,
}: SaveAsEstimateTemplateDialogProps) {
  const { t } = useTranslation()
  const [name, setName] = useState<string>("")

  const [createEstimateTemplate, { loading, error }] = useMutation(CREATE_ESTIMATE_TEMPLATE, {
    onCompleted: () => {
      onSave(true, "component.saveEstimateAsTemplateDialog.successMessage", { name })
    },
    onError: (error) => {
      Sentry.captureException(error)
      onSave(false, t("error.general.message"))
    },
  })

  function handleSave() {
    const sanitizedLineItems =
      lineItems?.map((li: EstimateLineItem) => {
        const lineItem = {
          number: li.number,
          organizationItemId: li.organizationItem?.id,
          description: li.description,
          quantity: asInt(li.quantity ?? ""),
          taxRateGroupId: li.taxRateGroup?.id,
          showDetails: li.showDetails,
          lineItemDetails: li.lineItemDetails?.map((d: EstimateLineItemDetail, index: number) => ({
            id: d.id,
            organizationItemId: d.organizationItemId ?? d.organizationItem?.id,
            quantity: d.quantity,
            number: d.number > 0 ? d.number : index + 1,
          })),
        }
        if (li.id) {
          lineItem.id = li.id
        }
        return lineItem
      }) ?? []

    const variables = {
      name: name.trim().substring(0, 255),
      description: description?.trim().substring(0, 1000),
      notes: notes?.trim().substring(0, 1000),
      discount: {
        value: asFloat(discountValue ?? "0") / (discountType === "PERCENTAGE" ? 100 : 1.0),
        type: discountType,
      },
      lineItems: sanitizedLineItems,
    }

    createEstimateTemplate({ variables })
  }

  return (
    <Dialog
      aria-labelledby="save-as-template-dialog"
      data-testid="Estimate"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open
    >
      <DialogTitle
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {t("component.saveEstimateAsTemplateDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: dialogBackgroundColor,
            paddingBottom: 0,
            paddingTop: "1.5rem",
          }}
        >
          {error ? (
            <ErrorMessage message={t("error.general.message")} title={t("error.general.title")} />
          ) : null}
          <Box
            sx={{
              color: (theme) => theme.fielderColors.mutedText,
              marginTop: "0.625rem",
              marginBottom: "0.625rem",
            }}
          >
            {t("component.saveEstimateAsTemplateDialog.description")}
          </Box>
          <Box
            sx={{
              marginTop: "1.25rem",
            }}
          >
            <FielderTextField
              autoComplete="none"
              data-testid="name"
              fullWidth
              id="name"
              inputProps={{ maxLength: 255 }}
              label={t("templateName")}
              name="name"
              onChange={(e) => setName(e.target.value)}
              required
              value={name}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          marginTop: "1rem",
          px: "1.5rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton loading={loading} onClick={handleSave} />
      </DialogActions>
    </Dialog>
  )
}

const dialogBackgroundColor = "#FFFFFF"

export default SaveAsEstimateTemplateDialog
