import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { Navigate, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import MainLayout from "../../components/MainLayout"
import PageHeader from "../../components/PageHeader"
import Seo from "../../components/Seo"
import SnackbarMessage from "../../components/SnackbarMessage"
import SectionHeader from "../../components/SectionHeader"
import SectionContent from "../../components/SectionContent"
import CatalogItemBasicInfoForm from "./components/CatalogItemBasicInfoForm"
import { CREATE_ITEM } from "../../queries/createItem"
import { CATALOG, parseGraphQLErrorCode } from "../../util"
import { Snack } from "../../types"

function CreateCatalogItem() {
  const { t } = useTranslation()
  const [snack, setSnack] = useState<Snack>()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()

  const [createItem, { loading }] = useMutation(CREATE_ITEM, {
    onCompleted: (data) => {
      const id = data.createItem.item.id
      setRedirectTo({
        to: `/app/catalog/edit/${id}`,
        replace: false, // let the user easily navigate back to the Create screen for convenience. The form will be cleared out.
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  function handleSave(payload) {
    createItem(payload)
  }

  function handleCancel() {
    setRedirectTo({ to: "/app/catalog/list", replace: true })
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(CATALOG.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={CATALOG}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: CATALOG.path, titleKey: CATALOG.titleKey }]}
            icon={CATALOG.icon}
            leafTitleKey="itemDetail"
          />
          <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Grid item md={6} sm={12}>
              <Box>
                <Paper>
                  <SectionHeader>
                    <label>{t("basicInfo")}</label>
                  </SectionHeader>
                  <Divider />
                  <SectionContent>
                    <CatalogItemBasicInfoForm
                      loading={loading}
                      onCancel={handleCancel}
                      onSave={handleSave}
                    />
                  </SectionContent>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </MainLayout>
    </>
  )
}

export default CreateCatalogItem
