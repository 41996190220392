const ONE_MEGABYTE = 1048576
const MAX_INTEGER = 2147483647

const ItemType = Object.freeze({
  PRODUCT: "PRODUCT",
  SERVICE: "SERVICE",
  BUNDLE: "BUNDLE",
})

export { MAX_INTEGER, ONE_MEGABYTE, ItemType }
