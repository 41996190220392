import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import Chip from "@mui/material/Chip"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { ALL_USERS } from "../queries/allUsers"
import { formatPersonName } from "../util/stringUtils"
import FielderBaseInput from "./FielderBaseInput"
import FieldSpinner from "./FieldSpinner"
import { type User, UserStatus } from "../types/apiTypes"

const defaultId = "MultiUserSelect" + new Date().getTime()

type MultiUserSelectOption = {
  id: string
  name: string
  email: string
}

interface MultiUserSelectProps {
  readonly id?: string
  readonly disabled?: boolean
  readonly error?: boolean
  readonly includeChildOrganizationUsers?: boolean
  readonly label: string
  readonly name: string
  readonly onBlur?: () => void
  readonly onChange: (selectedUsers: User[] | null) => void
  readonly organizationId?: string | null | undefined
  readonly renderOptions?: (option: MultiUserSelectOption[]) => React.ReactNode
  readonly renderValue?: (value: string[]) => React.ReactNode
  readonly required?: boolean
  readonly roleNames?: string[] | null | undefined
  readonly selectedUsers: User[]
  readonly size?: "small" | "medium"
}

function MultiUserSelect({
  id = defaultId,
  disabled,
  error: hasError,
  includeChildOrganizationUsers,
  label,
  name,
  onBlur,
  onChange,
  organizationId,
  renderOptions,
  renderValue,
  required,
  roleNames,
  selectedUsers,
  size = "medium",
}: MultiUserSelectProps) {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const { loading, error, data } = useQuery(ALL_USERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      roleNames,
      includeChildOrganizationUsers,
      includeArchived: false,
      sortBy: "lastName",
      statuses: [UserStatus.ACTIVE, UserStatus.LOCKED, UserStatus.LOCKED_NEEDS_PASSWORD_CHANGE],
    },
  })

  if (loading) {
    return <FieldSpinner message={t("loadingUsers")} />
  }

  if (error) {
    return <div>{`Error! ${error.message}`}</div>
  }

  const users =
    data?.allUsers?.edges
      ?.map((e: { node: User }) => e.node)
      ?.filter((u: User) => (organizationId ? u.organization.id === organizationId : true)) || []

  selectedUsers.forEach((u: User) => {
    if (!users.find((o) => o.id === u.id)) {
      users.push(u)
    }
  })

  const options: MultiUserSelectOption[] = users?.map((u: User) => ({
    id: u.id,
    name: formatPersonName(u),
    email: u.email,
  }))

  return (
    <FormControl
      error={Boolean(error) || hasError}
      fullWidth
      required={required}
      size={size}
      sx={{
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: "#fff",
        "& label.Mui-focused": {
          color: (theme) => theme.fielderColors.black,
          transform: "translate(14px, 7px) scale(0.75)",
        },
        "& label.Mui-error": {
          color: (theme) => theme.fielderColors.error,
        },
        "& label.MuiInputLabel-shrink": {
          transform: "translate(14px, 7px) scale(0.75)",
        },
      }}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        disabled={disabled}
        id={id}
        input={<FielderBaseInput />}
        inputProps={{ name: name }}
        label={label}
        multiple
        onBlur={onBlur}
        onChange={(event) => {
          const currentSelections = event.target.value
          const selectedOptions = users.filter((u: User) => currentSelections.indexOf(u.id) > -1)
          onChange(selectedOptions)
        }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        renderValue={(selectedValues) => {
          return renderValue ? (
            renderValue(selectedValues)
          ) : (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {selectedValues.map((value) => {
                const option = options.find((o) => o.id === value)
                return (
                  <Chip
                    key={value}
                    label={option?.name}
                    sx={{
                      margin: "0.125rem",
                    }}
                  />
                )
              })}
            </Box>
          )
        }}
        required={required}
        size={size}
        sx={{
          lineHeight: "2.25rem",
        }}
        value={selectedUsers.map((s) => s.id)}
      >
        {renderOptions
          ? renderOptions(options)
          : options.map((v) => (
              <MenuItem key={v.id} value={v.id}>
                <Checkbox checked={selectedUsers.map((s) => s.id).indexOf(v.id) > -1} />
                <ListItemText primary={v.name} />
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  )
}

export default MultiUserSelect
