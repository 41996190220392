import React from "react"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"
import FielderBaseInput from "./FielderBaseInput"

export type SelectorOption = {
  id: string
  name: string
}

interface Props {
  readonly ariaLabel?: string | undefined
  readonly disabled?: boolean
  readonly emptyOptionLabel?: string
  readonly error?: boolean
  readonly fullWidth?: boolean
  readonly label?: string | React.ReactNode
  readonly margin?: "none" | "dense" | undefined
  readonly name?: string | undefined
  readonly onBlur?: () => void
  readonly onChange?: (value: any) => void
  readonly onFocus?: () => void
  readonly options: SelectorOption[]
  readonly renderOption?: (option: SelectorOption) => JSX.Element
  readonly renderValue?: (value: any) => JSX.Element
  readonly required?: boolean
  readonly size?: "small" | "medium"
  readonly style?: React.CSSProperties
  readonly sx?: SxProps<Theme>
  readonly testID?: string | null | undefined
  readonly value?: string | null | undefined
  readonly variant?: "standard" | "outlined" | "filled"
}

function SelectorField({
  ariaLabel = "",
  disabled = false,
  emptyOptionLabel,
  error = false,
  fullWidth = true,
  label,
  margin,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  renderOption,
  renderValue,
  required,
  size = "medium",
  style,
  sx,
  testID,
  value,
  variant = "outlined",
}: Props) {
  return (
    <FormControl
      aria-label={ariaLabel}
      data-testid={testID}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      margin={margin ?? "normal"}
      required={required}
      size={size}
      style={style}
      sx={{
        marginTop: 0,
        marginBottom: 0,
        "& .MuiSelect-select.MuiInputBase-input": {
          paddingBottom: "8px",
        },
        "& label.Mui-focused": {
          color: (theme) => theme.fielderColors.black,
          transform: "translate(14px, 7px) scale(0.75)",
        },
        "& label.Mui-error": {
          color: (theme) => theme.fielderColors.error,
        },
        "& label.MuiInputLabel-shrink": {
          transform: "translate(14px, 7px) scale(0.75)",
        },
        "& div.Mui-error > .MuiSelect-outlined": {
          borderColor: (theme) => theme.fielderColors.error,
        },
        ...(sx ?? {}),
      }}
      variant={variant}
    >
      {label ? <InputLabel id={`${name}-label`}>{label}</InputLabel> : null}
      <Select
        fullWidth={fullWidth}
        id={name}
        input={label ? <FielderBaseInput /> : undefined}
        inputProps={{ name: name }}
        label={label}
        margin={margin}
        onBlur={onBlur}
        onChange={(event) => {
          const currentSelectionId = event.target.value
          const selectedOption =
            currentSelectionId === "NONE" ? null : options.find((o) => o.id === currentSelectionId)
          onChange?.(selectedOption)
        }}
        onFocus={onFocus}
        renderValue={(selected) => {
          if (renderValue) {
            return renderValue(selected)
          } else {
            return options.find((o) => o.id === selected)?.name
          }
        }}
        required={required}
        size={size}
        style={style}
        value={value}
        variant={variant}
      >
        {options.length === 0 && emptyOptionLabel ? (
          <MenuItem disabled key="empty" value={emptyOptionLabel}>
            {emptyOptionLabel}
          </MenuItem>
        ) : (
          options.map((o) => {
            if (renderOption) {
              return renderOption(o)
            } else {
              return (
                <MenuItem key={o.id} value={o.id}>
                  {o.name}
                </MenuItem>
              )
            }
          })
        )}
      </Select>
    </FormControl>
  )
}

export default React.memo(SelectorField)
