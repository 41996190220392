import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/EditOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import MailIcon from "@mui/icons-material/MailOutlined"
import PhoneIcon from "@mui/icons-material/PhoneOutlined"
import AttachMoneyIcon from "@mui/icons-material/AttachMoneyOutlined"
import BusinessOutlined from "@mui/icons-material/BusinessOutlined"
import HardhatIcon from "../../../components/icons/HardhatIcon"
import { formatAddress, formatPersonName, formatPhoneNumber } from "../../../util"
import { Contact, Customer, DefaultPermission } from "../../../types"
import { useAuth } from "../../../context/AuthContext"

interface Props {
  readonly contact: Contact
  readonly customer: Customer
  readonly onClickEdit: () => void
  readonly onClickArchive: () => void
}

function ContactInfoStatic({ contact, customer, onClickEdit, onClickArchive }: Props) {
  const { t } = useTranslation()
  const { hasPermissions } = useAuth()

  return (
    <Box sx={classes.root}>
      <Box sx={[classes.item, { justifyContent: "space-between", alignItems: "center" }]}>
        <Box sx={classes.nameAndTitleContainer}>
          <Box sx={classes.name}>{formatPersonName(contact)}</Box>
          {contact.jobTitle ? <Box sx={classes.title}>{contact.jobTitle}</Box> : null}
        </Box>
        {!customer.isArchived && hasPermissions?.([DefaultPermission.UpdateCustomer]) ? (
          <Box sx={classes.iconButtonContainer}>
            <IconButton aria-label="delete" data-testid="deleteButton" onClick={onClickArchive}>
              <DeleteIcon />
            </IconButton>
            <IconButton aria-label="edit" data-testid="editButton" onClick={onClickEdit}>
              <EditIcon />
            </IconButton>
          </Box>
        ) : null}
      </Box>
      {contact.email ? (
        <Box sx={classes.item}>
          <Box sx={classes.iconContainer}>
            <MailIcon fontSize="inherit" />
          </Box>
          <Box sx={classes.value}>{contact.email}</Box>
        </Box>
      ) : null}
      {contact.phoneNumber ? (
        <Box sx={classes.item}>
          <Box sx={classes.iconContainer}>
            <PhoneIcon fontSize="inherit" />
          </Box>
          <Box sx={classes.value}>{formatPhoneNumber(contact.phoneNumber, true)}</Box>
        </Box>
      ) : null}
      {contact.address?.addressString ? (
        <Box sx={classes.item}>
          <Box sx={classes.iconContainer}>
            <BusinessOutlined fontSize="inherit" />
          </Box>
          <Box sx={classes.value}>{formatAddress(contact.address.addressString)}</Box>
        </Box>
      ) : null}
      {contact.canBeBillingContact ? (
        <Box sx={classes.item}>
          <Box sx={classes.iconContainer}>
            <AttachMoneyIcon fontSize="inherit" />
          </Box>
          <Box sx={classes.contactTypeLabel}>{t("canBeBillingContact")}</Box>
        </Box>
      ) : null}
      {contact.canBeJobSiteContact ? (
        <Box sx={classes.item}>
          <Box sx={classes.iconContainer}>
            <HardhatIcon height={18} width={18} />
          </Box>
          <Box sx={classes.contactTypeLabel}>{t("canBeJobSiteContact")}</Box>
        </Box>
      ) : null}
    </Box>
  )
}

const classes = {
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "0.625rem",
    paddingTop: "0.625rem",
    paddingLeft: "1.25rem",
    paddingRight: 0,
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "0.5rem",
  },
  nameAndTitleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 1,
  },
  value: {
    wordBreak: "break-word",
  },
  name: {
    fontWeight: "bold",
    wordBreak: "break-word",
  },
  title: {
    fontStyle: "italic",
    wordBreak: "break-word",
  },
  iconButtonContainer: {
    display: "flex",
    maxHeight: "3rem",
  },
  iconContainer: {
    fontSize: "1.125rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: "0.625rem",
  },
  contactTypeLabel: {
    fontStyle: "italic",
  },
}

export default ContactInfoStatic
