/* eslint-disable react/jsx-no-literals */
import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { Navigate, useLocation, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { gql, useQuery, useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import CircularProgress from "@mui/material/CircularProgress"
import AddIcon from "@mui/icons-material/Add"
import WarningIcon from "@mui/icons-material/Warning"
import EditIcon from "@mui/icons-material/EditOutlined"
import EmptyState from "../../../components/EmptyState"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import Seo from "../../../components/Seo"
import SnackbarMessage from "../../../components/SnackbarMessage"
import SectionHeader from "../../../components/SectionHeader"
import SectionContent from "../../../components/SectionContent"
import { SETTINGS, parseGraphQLErrorCode } from "../../../util"
import { useAuth } from "../../../context/AuthContext"
import JobWorkflowDialog from "./components/JobWorkflowDialog"
import { DefaultPermission, JobWorkflow, Snack } from "../../../types"

const ALL_JOB_WORKFLOWS = gql`
  query AllJobWorkflows {
    allJobWorkflows {
      id
      name
      description
      isArchived
      isPublished
      parentWorkflow {
        id
        name
        description
        isArchived
        isPublished
      }
    }
  }
`

const CREATE_JOB_WORKFLOW = gql`
  mutation CreateJobWorkflow($parentWorkflowId: ID!, $name: String!, $description: String) {
    createJobWorkflow(
      input: { parentWorkflowId: $parentWorkflowId, name: $name, description: $description }
    ) {
      jobWorkflow {
        id
        name
        description
        isPublished
        parentWorkflow {
          id
          name
          isPublished
          steps {
            id
            jobStatus {
              id
              name
            }
            destinationTransitions {
              id
              destination {
                id
                jobStatus {
                  id
                  name
                }
              }
            }
          }
        }
        availableStatuses {
          id
          name
          parentStatus {
            id
            name
          }
        }
        steps {
          id
          boardPosition
          jobStatus {
            id
            name
            lightColor
            mediumColor
            darkColor
            parentStatus {
              id
              name
            }
          }
          responsibleUsers {
            id
            firstName
            lastName
            email
          }
          responsibleRole {
            id
            name
          }
          events {
            id
            name
          }
          destinationTransitions {
            id
            name
            destination {
              id
              jobStatus {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

function JobWorkflowsList() {
  const { t } = useTranslation()
  const location = useLocation()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const { hasPermissions, user } = useAuth()
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [createWorkflowDialogOpen, setCreateWorkflowDialogOpen] = useState<boolean>(false)
  const userOrgLevel = user?.organization?.level ?? 1000

  const { loading, error, data } = useQuery(ALL_JOB_WORKFLOWS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  })

  const [createJobWorkflow, { loading: createJobWorkflowLoading }] = useMutation(
    CREATE_JOB_WORKFLOW,
    {
      onCompleted: (data) => {
        const workflowId = data?.createJobWorkflow?.jobWorkflow?.id
        setCreateWorkflowDialogOpen(false)
        setRedirectTo({
          to: `/app/settings/jobworkflows/edit/${workflowId}`,
          replace: false,
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const workflows = data?.allJobWorkflows

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t("jobWorkflows")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="jobWorkflows"
          />
          <Paper sx={classes.widgetContainer}>
            <SectionHeader>
              <label>{t("jobWorkflows")}</label>
              {hasPermissions?.([DefaultPermission.CreateJobWorkflow]) ? (
                <Button
                  aria-label={t("createJobWorkflow")}
                  color="primary"
                  data-testid="createJobWorkflowButton"
                  onClick={() => {
                    setCreateWorkflowDialogOpen(true)
                  }}
                  sx={classes.primaryActionButton}
                  variant="contained"
                >
                  <AddIcon />
                  <Box>{t("createJobWorkflow")}</Box>
                </Button>
              ) : null}
            </SectionHeader>
            <Divider />
            <SectionContent
              sx={{
                maxHeight: 600,
                overflowY: "scroll",
              }}
            >
              {loading ? (
                <Box sx={classes.spinnerContainer}>
                  <CircularProgress color="secondary" size={20} thickness={6.0} />
                </Box>
              ) : null}
              {!loading &&
                workflows?.map((workflow: JobWorkflow) => {
                  return (
                    <Box key={workflow.id} sx={classes.workflowRow}>
                      <Box sx={classes.fieldContainer}>
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                          <Box sx={classes.textContainer}>
                            <Box sx={classes.name}>{workflow.name}</Box>
                            {userOrgLevel < 2 && (
                              <Box sx={classes.status}>
                                {t("status")}: {workflow.isPublished ? t("published") : t("draft")}
                              </Box>
                            )}
                          </Box>
                          {workflow.isArchived ? (
                            <Box sx={classes.archivedIndicatorContainer}>
                              <Box sx={classes.archivedIndicator}>
                                <WarningIcon
                                  style={{
                                    fontSize: "0.875rem",
                                    color: "#DA1505",
                                    marginRight: "0.625rem",
                                  }}
                                />
                                <span>{t("archived")}</span>
                              </Box>
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                      <Box sx={classes.rowButtonContainer}>
                        {hasPermissions?.([DefaultPermission.UpdateJobWorkflow]) ? (
                          <IconButton
                            aria-label="edit"
                            data-testid="editJobWorkflowBtn"
                            onClick={() => {
                              setRedirectTo({
                                to: `/app/settings/jobworkflows/edit/${workflow.id}`,
                                replace: false,
                              })
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                    </Box>
                  )
                })}
              {!error && !loading && workflows?.length == 0 && (
                <EmptyState
                  title={t("page.jobWorkflowList.emptyState.title")}
                  titleStyle={{
                    fontSize: "1rem",
                  }}
                >
                  <Box>{t("page.jobWorkflowList.emptyState.message")}</Box>
                </EmptyState>
              )}
            </SectionContent>
          </Paper>
        </Box>
      </MainLayout>
      {createWorkflowDialogOpen ? (
        <JobWorkflowDialog
          isSaving={createJobWorkflowLoading}
          onCancel={() => setCreateWorkflowDialogOpen(false)}
          onSave={(payload) => {
            createJobWorkflow({ variables: payload })
          }}
          open={createWorkflowDialogOpen}
        />
      ) : null}
    </>
  )
}
const classes = {
  root: {
    margin: "0 1.25rem",
  },
  spinnerContainer: {
    padding: "6.25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  primaryActionButton: {
    fontWeight: "bold",
    "& svg": {
      fontSize: "1.0rem",
    },
    "& div": {
      marginLeft: "0.625rem",
      marginRight: "0.625rem",
    },
  },
  widgetContainer: {
    width: "50%",
    maxWidth: "700px",
    marginBottom: "1.25rem",
    marginTop: "3.75rem",
  },
  workflowRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ddd",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    "&:first-of-type": {},
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  rowButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontWeight: 600,
    fontSize: "0.875rem",
  },
  description: {
    fontWeight: 400,
    fontSize: "0.75rem",
  },
  status: {
    fontStyle: "italic",
    fontSize: "0.75rem",
  },
  archivedIndicatorContainer: {
    display: "flex",
    marginTop: "0.5rem",
  },
  archivedIndicator: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(218, 21, 5, 0.1)",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: "4px",
  },
} as const

export default JobWorkflowsList
