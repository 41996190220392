import React from "react"
import { useTranslation } from "react-i18next"
import { HeaderLogo, Seo } from "../components"

function OrgAccountPending() {
  const { t } = useTranslation()

  return (
    <div css={classes.root}>
      <Seo title={t("locked")} />
      <main>
        <div css={classes.header}>
          <div css={classes.logoContainer}>
            <HeaderLogo height="auto" width="100%" />
          </div>
        </div>
        <div css={classes.content}>
          <h1>{t("page.orgAccountPending.headline")}</h1>
          <div css={classes.message}>{t("page.orgAccountPending.message")}</div>
          <div css={classes.message}>
            <a href="mailto:support@fielderapp.com">support@fielderapp.com</a>
          </div>
        </div>
      </main>
    </div>
  )
}

const classes = {
  root: {
    height: "100%",
  },
  header: {
    backgroundColor: "#212121",
  },
  logoContainer: {
    width: 266,
    padding: "1rem",
    paddingBottom: "0.5rem",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "1.875rem",
    paddingTop: "3.125rem",

    "& h1": {
      marginBottom: "2.5rem",
    },
  },
  message: {
    fontSize: "1.25rem",
    marginBottom: "1.25rem",
  },
} as const

export default OrgAccountPending
