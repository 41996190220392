import gql from "graphql-tag"

export const EDIT_USER = gql`
  mutation EditUser(
    $id: ID!
    $email: String
    $firstName: String
    $lastName: String
    $jobTitle: String
    $mobilePhoneNumber: String
    $roleIds: [ID!]
    $organizationId: ID
    $status: UserStatus
    $languageCode: LanguageCode
  ) {
    editUser(
      input: {
        id: $id
        email: $email
        firstName: $firstName
        lastName: $lastName
        jobTitle: $jobTitle
        mobilePhoneNumber: $mobilePhoneNumber
        roleIds: $roleIds
        organizationId: $organizationId
        status: $status
        languageCode: $languageCode
      }
    ) {
      user {
        id
        email
        firstName
        lastName
        jobTitle
        mobilePhoneNumber
        status
        isArchived
        languageCode
        roles {
          id
          isStandard
          name
        }
        avatar {
          id
          signedUrl
          updatedAt
        }
        organization {
          id
          tradeName
        }
        lastLogin
        createdAt
        createdBy {
          id
          email
        }
        updatedAt
        updatedBy {
          id
          email
        }
      }
    }
  }
`
