import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { Navigate, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import SnackbarMessage from "~/components/SnackbarMessage"
import CustomerBasicInfoForm from "./components/CustomerBasicInfoForm"
import { CREATE_CUSTOMER } from "~/queries/createCustomer"
import { parseGraphQLErrorCode, CUSTOMERS } from "~/util"
import { CreateCustomerInput, Snack } from "~/types"

function CreateCustomer() {
  const { t } = useTranslation()
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()

  const [createCustomer, { loading }] = useMutation(CREATE_CUSTOMER, {
    onCompleted: (data) => {
      const id = data.createCustomer.customer.id
      setRedirectTo({ to: `/app/customers/edit/${id}`, replace: false })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  function handleSave(customerInput: CreateCustomerInput) {
    createCustomer({
      variables: customerInput,
    })
  }

  function handleCancel() {
    setRedirectTo({ to: "/app/customers/list", replace: true })
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(CUSTOMERS.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={CUSTOMERS}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: CUSTOMERS.path, titleKey: CUSTOMERS.titleKey }]}
            icon={CUSTOMERS.icon}
            leafTitleKey="createCustomer"
          />
          <Grid container direction="row" justifyContent="flex-start" spacing={1}>
            <Grid item md={6} sm={12}>
              <Paper>
                <SectionHeader>
                  <label>{t("basicInfo")}</label>
                </SectionHeader>
                <Divider />
                <SectionContent>
                  <CustomerBasicInfoForm
                    loading={loading}
                    onCancel={handleCancel}
                    onSave={handleSave}
                  />
                </SectionContent>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </MainLayout>
    </>
  )
}

export default CreateCustomer
