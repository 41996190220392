import React from "react"
import CircularProgress from "@mui/material/CircularProgress"

interface FieldSpinnerProps {
  message?: string | null
  style?: React.CSSProperties
}

function FieldSpinner({ message = "loading...", style }: FieldSpinnerProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        paddingTop: "1.125rem",
        paddingBottom: "1.125rem",
        paddingLeft: "0.875rem",
        marginTop: "1rem",
        alignItems: "center",
        "& span": {
          marginLeft: "0.5rem",
          color: "rgba(0, 0, 0, 0.54)",
          fontSize: "0.875rem",
          fontFamily: "Source Sans Pro, Arial, sans-serif",
        },
        ...style,
      }}
    >
      <CircularProgress size={14} />
      <span>{message}</span>
    </div>
  )
}

export default FieldSpinner
