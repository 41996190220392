import isPlainObject from "lodash/isPlainObject"

/**
 * Flatten an object such that it doesn't have any nested objects.
 * That is, if you pass in an object like this: { 'a': { 'b': 1, 'c': {'d': 4 } } }
 * Then it will return an object like this: { 'a.b': 1, 'a.c.d': 4 }
 * @param {*} obj
 * @param {*} prefix
 * @param {*} skiplist
 * @returns an object with no nested objects.
 */
function flattenObject(obj: any, prefix = "", skiplist = []): any {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + "." : ""
    if (!skiplist.includes(k)) {
      if (!!obj[k] && typeof obj[k] === "object") {
        Object.assign(acc, flattenObject(obj[k], pre + k, skiplist))
      } else {
        acc[pre + k] = obj[k]
      }
    }
    return acc
  }, {})
}

/**
 * Remove all specified keys from an object, no matter how deep they are.
 * The removal is done in place, so run it on a copy if you don't want to modify the original object.
 * This function has no limit so circular objects will probably crash the browser
 *
 * @param obj The object from where you want to remove the keys
 * @param keys An array of property names (strings) to remove
 */
function removeKeys(obj: any, keys: string[]) {
  for (const prop in obj) {
    // important check that this is object's own property
    // not from prototype prop inherited

    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(prop) && keys.indexOf(prop) > -1) {
      delete obj[prop]
    } else if (isPlainObject(obj[prop])) {
      removeKeys(obj[prop], keys)
    }
  }
}

function isEmpty(value: any): boolean {
  return value == null || value.length === 0
}

export { flattenObject, removeKeys, isEmpty }
