import useInterval from './useInterval'
import Cookies from 'js-cookie'
import { useNavigate } from "react-router-dom"
import { useAuth } from '../context/AuthContext'

function useSessionChecker() {
    const navigate = useNavigate()
    const { logout } = useAuth()

    useInterval(() => {
        const authToken = Cookies.get("authToken")
        if (!authToken) {
            logout?.()
            navigate("/app/login", { replace: true })
        }
    }, 5000)
}

export default useSessionChecker