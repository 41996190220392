import { ProductOrderStatus } from "../types"

function getStatusBackgroundColor(status: ProductOrderStatus): string {
  switch (status) {
    case ProductOrderStatus.DRAFT:
      return "hsl(45.6,89.29%,89.02%)"
    case ProductOrderStatus.SUBMITTED:
      return "hsl(210.77,68.42%,88.82%)"
    case ProductOrderStatus.IN_PROGRESS:
      return "hsl(105,30.77%,89.8%)"
    case ProductOrderStatus.SHIPPING:
      return "hsl(292.63,82.61%,94%)"
    case ProductOrderStatus.INVOICING:
      return "hsl(27.91,100%,94%)"
    case ProductOrderStatus.COMPLETED:
      return "hsl(240,15.15%,93.53%)"
    case ProductOrderStatus.CANCELED:
      return "hsla(0, 97%, 96%, 1)"
    default:
      return "white"
  }
}

function getStatusForegroundColor(status: ProductOrderStatus): string {
  switch (status) {
    case ProductOrderStatus.DRAFT:
      return "hsla(53, 74%, 53%, 1)"
    case ProductOrderStatus.SUBMITTED:
      return "hsl(203.85,100%,31.57%)"
    case ProductOrderStatus.IN_PROGRESS:
      return "hsl(120.8,100%,14.71%)"
    case ProductOrderStatus.SHIPPING:
      return "hsl(285.44,100%,25%)"
    case ProductOrderStatus.INVOICING:
      return "hsla(34, 95%, 38%, 1)"
    case ProductOrderStatus.COMPLETED:
      return "hsl(219.68,100%,12.16%)"
    case ProductOrderStatus.CANCELED:
      return "hsla(0, 100%, 45%, 1)"
    default:
      return "white"
  }
}

export { getStatusBackgroundColor, getStatusForegroundColor }
