import gql from "graphql-tag"
import FullItem from "./fragments/FullItem"

export const UNARCHIVE_ITEM = gql`
  mutation UnarchiveItem($id: ID!) {
    unarchiveItem(id: $id) {
      item {
        ...FullItem
      }
    }
  }
  ${FullItem}
`
