import React from "react"
import { useTranslation } from "react-i18next"
import ThumbUpOutlined from "@mui/icons-material/ThumbUpOutlined"
import Box from "@mui/material/Box"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"

interface NoResultsRowProps {
  colSpan?: number
}

function NoResultsRow({ colSpan = 0 }: NoResultsRowProps) {
  const { t } = useTranslation()

  return (
    <TableRow style={{ height: "100%", verticalAlign: "top" }}>
      <TableCell align="center" colSpan={colSpan} style={{ height: "100%" }}>
        <Box
          sx={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            color: (theme) => theme.palette.grey[700],
            marginTop: "6.25rem",
          }}
        >
          <p>{t("messages.noResults")}</p>
          <ThumbUpOutlined />
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default NoResultsRow
