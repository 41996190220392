import React from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"

import { ALL_USERS } from "~/queries/allUsers"
import { formatPersonName } from "~/util"
import SelectorField, { SelectorOption } from "./SelectorField"
import FieldSpinner from "./FieldSpinner"
import { type User, UserStatus } from "~/types/apiTypes"

type UserSelectOption = {
  id: string
  name: string
  email: string
}

interface UserSelectProps {
  readonly disabled?: boolean
  readonly error?: boolean
  readonly includeChildOrganizationUsers?: boolean
  readonly label: string
  readonly name: string
  readonly onBlur?: () => void
  readonly onChange: (selectedUser: User | null) => void
  readonly organizationId?: string | null | undefined
  readonly required?: boolean
  readonly roleNames?: string[] | null | undefined
  readonly selectedUser?: User | null | undefined
  readonly variant?: "outlined" | "filled"
}

function UserSelect({
  disabled,
  error: hasError,
  includeChildOrganizationUsers,
  label,
  name,
  onBlur,
  onChange,
  organizationId,
  required,
  roleNames,
  selectedUser,
  variant = "filled",
}: UserSelectProps) {
  const { t } = useTranslation()

  const { loading, error, data } = useQuery(ALL_USERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      roleNames,
      includeChildOrganizationUsers,
      includeArchived: false,
      sortBy: "lastName",
      statuses: [UserStatus.ACTIVE, UserStatus.LOCKED, UserStatus.LOCKED_NEEDS_PASSWORD_CHANGE],
    },
  })

  if (error) return <div>{`Error! ${error.message}`}</div>

  const users =
    data?.allUsers?.edges
      ?.map((e: { node: User }) => e.node)
      ?.filter((u: User) => (organizationId ? u.organization.id === organizationId : true)) || []

  const options: UserSelectOption[] = users?.map((u: User) => ({
    id: u.id,
    name: formatPersonName(u),
    email: u.email,
  }))

  if (!required) {
    options.unshift({ id: "", name: "", email: "" })
  }

  return (
    <SelectorField
      disabled={disabled || loading}
      error={hasError}
      label={
        loading ? (
          <FieldSpinner
            message={t("loadingUsers") as string}
            style={{ border: "none", margin: 0, padding: 0 }}
          />
        ) : (
          label
        )
      }
      name={name}
      onBlur={onBlur}
      onChange={(val) => {
        if (val) {
          const user = users.find((u: User) => u.id === val.id)
          onChange(user)
        } else {
          onChange(null)
        }
      }}
      options={options}
      renderOption={(selectorOption: SelectorOption) => {
        const userSelectOption = selectorOption as UserSelectOption
        return (
          <MenuItem key={userSelectOption.id} value={userSelectOption.id}>
            <Box sx={{ display: "flex", flexDirection: "column", minHeight: "42px" }}>
              <Box>{userSelectOption.name}</Box>
              <Box sx={{ fontSize: "0.75rem", color: "#666" }}>{userSelectOption.email}</Box>
            </Box>
          </MenuItem>
        )
      }}
      renderValue={(selected) => {
        const val = selected && options.find((o: UserSelectOption) => o.id === selected)
        if (!val) {
          return <Box>{t("error.invalidOptionSelected")}</Box>
        }

        if (variant === "filled") {
          return <Box>{val.name}</Box>
        } else {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "-0.5625rem",
                marginBottom: "-0.625rem",
              }}
            >
              <Box>{val.name}</Box>
              <Box sx={{ fontSize: "0.75rem", color: "#666" }}>{val.email}</Box>
            </Box>
          )
        }
      }}
      required={required}
      value={selectedUser ? selectedUser.id : ""}
      variant={variant}
    />
  )
}

export default React.memo(UserSelect)
