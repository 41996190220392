import { parsePhoneNumberFromString } from "libphonenumber-js"

/**
 * Try to parse the given phone number and format it into an appropriate format
 * @param {string} phoneNumber - a phone number string.
 * @param {boolean} useInternationalFormat - indicates that you want the international format.
 *      If false, it'll provide a national format
 */
function formatPhoneNumber(phoneNumber: string, useInternationalFormat = true): string {
  if (!phoneNumber) {
    return phoneNumber
  }

  const parsed = parsePhoneNumberFromString(phoneNumber)
  if (parsed) {
    return useInternationalFormat ? parsed.formatInternational() : parsed.formatNational()
  } else {
    return phoneNumber
  }
}

export default formatPhoneNumber
