import React from "react"
import { useTranslation } from "react-i18next"
import Divider from "@mui/material/Divider"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import MapIcon from "@mui/icons-material/Map"
import { TimeFrameOption } from "../../types"

interface TimeframeSelectionPanelProps {
  readonly currentSelection: TimeFrameOption
  readonly onChange: (value: TimeFrameOption) => void
}

function TimeframeSelectionPanel({ currentSelection, onChange }: TimeframeSelectionPanelProps) {
  const { t } = useTranslation()

  return (
    <Select
      defaultValue={currentSelection}
      id="timeframe"
      onChange={(e) => {
        onChange(e.target.value as TimeFrameOption)
      }}
      renderValue={(value) => (
        <span>{t(`component.dispatchCalendar.TIMEFRAME_OPTIONS.${value}`)}</span>
      )}
      size="small"
      sx={{
        minWidth: "150px",
      }}
      value={currentSelection}
      variant="outlined"
    >
      <MenuItem value={TimeFrameOption.DAY}>
        {t("component.dispatchCalendar.TIMEFRAME_OPTIONS.DAY")}
      </MenuItem>
      <MenuItem value={TimeFrameOption.WEEK}>
        {t("component.dispatchCalendar.TIMEFRAME_OPTIONS.WEEK")}
      </MenuItem>
      <Divider />
      <MenuItem value="MAP">
        <div style={{ display: "flex", alignItems: "center" }}>
          <MapIcon sx={{ marginRight: "0.625rem" }} />
          <span>{t("component.dispatchCalendar.TIMEFRAME_OPTIONS.MAP")}</span>
        </div>
      </MenuItem>
    </Select>
  )
}

export default TimeframeSelectionPanel
