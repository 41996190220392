import React from "react"
import { useTranslation } from "react-i18next"
import { formatMoney } from "../../../util"
import TopLevelProductDetailsStatic from "./TopLevelProductDetailsStatic"
import StaticField from "../../../components/StaticField"
import { OrganizationItem } from "../../../types"
import Box from "@mui/material/Box"
import numeral from "numeral"
import HelperTextContainer from "./HelperTextContainer"

interface ProductDetailsStackProps {
  readonly currencyCode: string
  readonly item: OrganizationItem
}

function ProductDetailsStatic({ currencyCode, item }: ProductDetailsStackProps) {
  const { t } = useTranslation()

  return (
    <>
      <TopLevelProductDetailsStatic currencyCode={currencyCode} item={item} showBottomDivider />
      <Box>
        <StaticField
          label={t("status")}
          renderHelpText={() => {
            return (
              <HelperTextContainer>
                <Box>{t(`itemStatus.helperText`)}</Box>
              </HelperTextContainer>
            )
          }}
          showBottomDivider
          value={t(`itemStatus.label.${item.isActive ? "ACTIVE" : "INACTIVE"}`)}
        />
      </Box>
      <Box>
        <StaticField
          label={t("unitSalePrice")}
          renderHelpText={() => {
            return (
              <HelperTextContainer>
                <>
                  <Box sx={{ fontStyle: "italic" }}>
                    {t(`unitSalePriceTypeLabel.${item.isDynamicallyPriced ? "dynamic" : "static"}`)}
                  </Box>
                  {item.isDynamicallyPriced ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        fontSize: "0.7rem",
                      }}
                    >
                      <Box sx={{ fontWeight: "500" }}>{t("markup")}:</Box>
                      <span>{numeral(item.unitSalePriceMarkup).format("0%")}</span>
                    </Box>
                  ) : null}
                </>
              </HelperTextContainer>
            )
          }}
          showBottomDivider
          value={formatMoney(currencyCode, item.unitSalePrice)}
        />
      </Box>
      {item.isTracked ? (
        <>
          <StaticField label={t("quantityOnHand")} showBottomDivider value={item.qtyOnHand} />
          <StaticField label={t("reorderPoint")} showBottomDivider value={item.reorderPoint} />
        </>
      ) : null}
      <StaticField label={t("reorderQty")} showBottomDivider value={item.reorderQty} />
      <StaticField label={t("quantityOnOrder")} showBottomDivider value={item.orderedQty} />
      <StaticField
        label={t("quantityCommitted")}
        showBottomDivider={false}
        value={item.committedQty}
      />
    </>
  )
}

export default ProductDetailsStatic
