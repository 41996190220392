/* eslint-disable react/iframe-missing-sandbox */
/* eslint-disable react/jsx-no-literals */
import React, { useState } from "react"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import { useTranslation } from "react-i18next"
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined"
import { MainLayout, PageHeader, Seo } from "../../components"
import { HELP } from "../../util"

interface Video {
  title: string
  lang: string
  url: string
  playTime: string
}

const videos: Video[] = [
  {
    title: "Welcome to Fielder!",
    lang: "en",
    url: "https://player.vimeo.com/video/688591290?h=c62a2744a1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    playTime: "3:30",
  },
  {
    title: "Franchisee Account Setup",
    lang: "en",
    url: "https://player.vimeo.com/video/688594532?h=b21153bf70&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    playTime: "2:11",
  },
  {
    title: "Roles and Permissions",
    lang: "en",
    url: "https://player.vimeo.com/video/747891480?h=ea392f3f9b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    playTime: "4:00",
  },
  {
    title: "Manage Users",
    lang: "en",
    url: "https://player.vimeo.com/video/749804661?h=fe80a00894&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    playTime: "3:13",
  },
  {
    title: "Job Workflow Overview",
    lang: "en",
    url: "https://player.vimeo.com/video/760522697?h=3e9d55b02d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    playTime: "4:05",
  },
]

function VideoListItem({
  isActive,
  title,
  playTime,
  onClick,
}: {
  isActive: boolean
  title: string
  playTime: string
  onClick: () => void
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0.5rem",
        cursor: "pointer",
        backgroundColor: (theme) => (isActive ? theme.fielderColors.brandPrimary : "white"),
        "&:hover": {
          backgroundColor: (theme) => theme.fielderColors.brandPrimaryFaded,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          alignItems: "center",
        }}
      >
        <PlayCircleOutlinedIcon />
        <Box
          sx={{
            fontWeight: isActive ? "700" : "500",
          }}
        >
          {title}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid #747474",
          borderRadius: "4px",
          padding: "0.125rem 0.375rem 0",
          fontSize: "0.625rem",
          fontWeight: isActive ? "700" : "500",
          lineHeight: "18px",
          backgroundColor: "white",
        }}
      >
        {playTime}
      </Box>
    </Box>
  )
}

function HelpVideos() {
  const { t } = useTranslation()
  const [currentVideo, setCurrentVideo] = useState<Video>(videos[0])

  return (
    <>
      <Seo title={t("sectionTitle.helpCenter")} />
      <MainLayout activeSection={HELP}>
        <Box
          sx={{
            margin: "0 1.25rem",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: HELP.path, titleKey: HELP.titleKey }]}
            icon={HELP.icon}
            leafTitleKey="videos"
          />
          <Paper
            sx={{
              display: "flex",
              flexGrow: 1,
              backgroundColor: "white",
              borderRadius: "4px",
              marginBottom: "5rem",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, maxWidth: 300 }}>
              <Box>
                <Box
                  sx={{
                    color: (theme) => theme.fielderColors.text,
                    fontSize: "1.25rem",
                    fontWeight: "700",
                    padding: "0.5rem",
                    lineHeight: "24px",
                  }}
                >
                  Getting Started
                </Box>
                {videos.map((v) => {
                  return (
                    <VideoListItem
                      isActive={currentVideo.url === v.url}
                      key={v.title}
                      onClick={() => setCurrentVideo(v)}
                      playTime={v.playTime}
                      title={v.title}
                    />
                  )
                })}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 2,
                padding: "0.5rem",
                borderLeft: "1px solid #ededed",
              }}
            >
              <Box
                sx={{
                  fontSize: "1.75rem",
                  fontWeight: "700",
                  marginBottom: "0.5rem",
                  marginLeft: "0.5rem",
                }}
              >
                {currentVideo.title}
              </Box>
              <Box sx={{ padding: "50% 0 0 0", position: "relative" }}>
                <iframe
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  frameBorder="0"
                  height="480"
                  scrolling="no"
                  src={currentVideo.url}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  title={currentVideo.title}
                  width="854"
                />
              </Box>
              <script src="https://player.vimeo.com/api/player.js" />
            </Box>
          </Paper>
        </Box>
      </MainLayout>
    </>
  )
}

export default HelpVideos
