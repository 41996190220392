import React from "react"
import { NumericFormat } from "react-number-format"
import FielderTextField from "./FielderTextField"

interface DecimalInputProps {
  readonly onChange: (event: { target: { name: string; value: string } }) => void
  readonly onFocus?: (event: any) => void
  readonly name: string
  readonly scale: number
  readonly allowNegative: boolean
  readonly defaultValue?: number | string
  readonly value?: number | string
  readonly customInput?: React.ComponentType
}

function DecimalInput(props: DecimalInputProps) {
  const {
    allowNegative,
    scale,
    name,
    defaultValue,
    onChange,
    onFocus,
    customInput,
    value,
    ...other
  } = props
  return (
    <NumericFormat
      {...other}
      allowNegative={allowNegative}
      autoComplete="off"
      customInput={customInput ?? FielderTextField}
      decimalScale={scale}
      defaultValue={defaultValue}
      name={name}
      onFocus={(e) => {
        if (onFocus) {
          onFocus(e)
        } else {
          e.target.select()
        }
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      value={value}
      valueIsNumericString
    />
  )
}

export default DecimalInput
