import gql from "graphql-tag"

export const ALL_INVOICES = gql`
  query AllInvoices(
    $jobId: ID
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: String
    $invoiceStatuses: [InvoiceStatus]
  ) {
    allInvoices(
      input: {
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        after: $after
        last: $last
        before: $before
        filter: $filter
      }
      invoiceFilter: { jobId: $jobId, statuses: $invoiceStatuses }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          number
          job {
            id
            number
            customer {
              id
              name
              type
            }
          }
          status
          issuedDate
          netTerms
          dueDate
          total
          balanceDue
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
