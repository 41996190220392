import React from "react"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined"
import { t } from "i18next"
import { Trans } from "react-i18next"

import { SectionContent } from "~/components"

interface Props {
  readonly messageKey: string
}

export default function QuickTip({ messageKey }: Props) {
  return (
    <Paper sx={{ maxWidth: "32rem" }}>
      <SectionContent sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <Box
          sx={{
            fontSize: "0.875rem",
            fontWeight: "600",
            alignContent: "center",
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
          }}
        >
          <BoltOutlinedIcon fontSize="small" />
          {t("component.franchiseeForm.quickTip.title")}
        </Box>
        <Box sx={{ padding: "0.625rem", fontSize: "0.875rem" }}>
          <Trans i18nKey={messageKey} />
        </Box>
      </SectionContent>
    </Paper>
  )
}
