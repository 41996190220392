import { Editor, Transforms, Element as SlateElement, Range, Node } from "slate"
import { TemplateFieldOption, FontOption, TextSizeOption } from "../../types"
import FontOptions from "./FontOptions"

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
}

const FORMATS = [
  {
    name: "bold",
    hotkey: "mod+b",
  },
  {
    name: "italic",
    hotkey: "mod+i",
  },
  {
    name: "underline",
    hotkey: "mod+u",
  },
]

const LIST_TYPES = ["numbered-list", "bulleted-list"]
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"]

const RichTextEditor = {
  toggleBlock(editor: Editor, format: string) {
    const isActive = RichTextEditor.isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? "alignment" : "type"
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
      split: true,
    })

    const newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    }

    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  },

  setBlockAlignment(editor: Editor, format: string) {
    const isActive = RichTextEditor.isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? "alignment" : "type"
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    })
    let newProperties: Partial<SlateElement>
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        alignment: isActive ? undefined : format,
      }
    } else {
      newProperties = {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
      }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  },

  setFont(editor: Editor, font: FontOption) {
    Editor.addMark(editor, "font", font)
  },

  setTextColor(editor: Editor, textColor: string) {
    Editor.addMark(editor, "textColor", textColor)
  },

  setTextSize(editor: Editor, textSize: TextSizeOption) {
    Editor.addMark(editor, "textSize", textSize)
  },

  getActiveFont(editor: Editor): FontOption {
    const marks = Editor.marks(editor)
    return marks?.font ?? FontOptions[0]
  },

  getTextColor(editor: Editor): string {
    const marks = Editor.marks(editor)
    return marks?.textColor ?? "#000000"
  },

  /**
   * Add/remove a "mark" from the selection.
   * A "mark" is whatever we define it to be, such as "bold", "italic", etc.
   * Multiple marks may be added to a selection. See the `Leaf` component to
   * see how marks are applied to text.
   */
  toggleMark(editor: Editor, format: string): void {
    const isActive = RichTextEditor.isMarkActive(editor, format)

    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  },

  isAlignmentActive(editor: Editor, alignment: string): boolean {
    const { selection } = editor
    if (!selection) {
      return false
    }

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.alignment === alignment,
      })
    )

    return !!match
  },

  isBlockActive(editor: Editor, format: string, blockType = "type"): boolean {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
      })
    )

    return !!match
  },

  isMarkActive(editor: Editor, format: string): boolean {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  },

  insertTemplateField(editor: Editor, templateField: TemplateFieldOption) {
    const templateFieldNode = {
      type: "templateField",
      templateField,
      children: [{ text: "" }],
    } as unknown as Node
    Transforms.insertNodes(editor, templateFieldNode)
    Transforms.move(editor)
  },

  insertLink(editor: Editor, url: string) {
    if (editor.selection) {
      RichTextEditor.wrapLink(editor, url)
    }
  },

  isLinkActive(editor: Editor): boolean {
    const [link] = Array.from(
      Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
      })
    )

    return !!link
  },

  unwrapLink(editor: Editor) {
    Transforms.unwrapNodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    })
  },

  wrapLink(editor: Editor, url: string) {
    if (RichTextEditor.isLinkActive(editor)) {
      RichTextEditor.unwrapLink(editor)
    }

    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
      type: "link",
      url,
      children: isCollapsed ? [{ text: url }] : [],
    }

    if (isCollapsed) {
      Transforms.insertNodes(editor, link)
    } else {
      Transforms.wrapNodes(editor, link, { split: true })
      Transforms.collapse(editor, { edge: "end" })
    }
  },
}

export { HOTKEYS, FORMATS, LIST_TYPES, RichTextEditor }
