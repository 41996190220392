import React from "react"
import Box from "@mui/material/Box"

interface Props {
  children?: JSX.Element
}

function HelperTextContainer({ children }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        fontSize: "0.7rem",
        color: (theme) => theme.fielderColors.mutedText,
      }}
    >
      {children}
    </Box>
  )
}

export default HelperTextContainer
