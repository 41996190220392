import gql from "graphql-tag"
import { ApolloError, ApolloQueryResult, useQuery } from "@apollo/client"

import { CustomerFlag } from "~/types"

export const ALL_CUSTOMER_FLAGS_FOR_ORGANIZATION = gql`
query AllCustomerFlagsForOrganization($id: ID!) {
  getOrganizationById(id: $id) {
    id
    customerFlags {
      id
      name
      description
      colorCode
    }
  } 
}
`

export interface AllCustomerFlagsForOrganizationQueryResponse {
  customerFlags: CustomerFlag[]
  loading: boolean
  error?: ApolloError
  refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>
}

export default function useGetUserOrganizationPreferences(organizationId?: string): AllCustomerFlagsForOrganizationQueryResponse {
  const { data, loading, error, refetch } = useQuery(ALL_CUSTOMER_FLAGS_FOR_ORGANIZATION, {
    variables: {
      id: organizationId,
    },
    fetchPolicy: "cache-and-network",
    skip: !organizationId,
  })
  const customerFlags: CustomerFlag[] = (data?.getOrganizationById?.customerFlags ?? []) as CustomerFlag[]

  return {
    customerFlags,
    loading,
    error,
    refetch
  }
}
