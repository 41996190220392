import { BillingPeriodOption, FielderSubscriptionPricePointInterval } from "../types"

const BILLING_PERIOD_OPTIONS = {
  ANNUALLY: {
    value: FielderSubscriptionPricePointInterval.YEAR, // should match a Stripe interval value: https://stripe.com/docs/api/prices/object
    titleKey: "page.franchiseePricing.billingPeriodOptions.year.title",
    subTitleKey: "page.franchiseePricing.billingPeriodOptions.year.subTitle",
  } as BillingPeriodOption,
  MONTHLY: {
    value: FielderSubscriptionPricePointInterval.MONTH, // should match a Stripe interval value: https://stripe.com/docs/api/prices/object
    titleKey: "page.franchiseePricing.billingPeriodOptions.month.title",
    subTitleKey: "page.franchiseePricing.billingPeriodOptions.month.subTitle",
  } as BillingPeriodOption,
}

export default BILLING_PERIOD_OPTIONS
