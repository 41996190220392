import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Popover from "@mui/material/Popover"
import { isBlank } from "../util"
import { useAuth } from "../context/AuthContext"
import JobStatusSelect from "./JobStatusSelect"
import { JobStatus } from "../types"
import FielderTextField from "./FielderTextField"
import FieldHelperText from "./FieldHelperText"
import SaveButton from "./SaveButton"
import { type JobStatusColorOption, JobStatusColorPicker } from "./JobStatusColorPicker"

interface Props {
  readonly loading?: boolean
  readonly onCancel?: () => void
  readonly onSave?: (status: JobStatus) => void
  readonly open?: boolean
  readonly status?: JobStatus
}

function JobStatusDialog({ loading, onCancel, onSave, open = false, status }: Props) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [name, setName] = useState<string>(() => status?.name || "")
  const [description, setDescription] = useState<string>(() => status?.description || "")
  const [parent, setParent] = useState<JobStatus | undefined>(() => status?.parentStatus)
  const [darkColor, setDarkColor] = useState<string>(status?.darkColor)
  const [mediumColor, setMediumColor] = useState<string>(status?.mediumColor)
  const [lightColor, setLightColor] = useState<string>(status?.lightColor)
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = useState(null)
  const [errors, setErrors] = useState(() => ({
    parent: null,
    name: null,
    description: null,
  }))

  const showColorPicker = Boolean(colorPickerAnchorEl)

  return (
    <Dialog
      aria-labelledby="edit-status-dialog-title"
      data-testid="StatusDialog"
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogTitle
        id="edit-status-dialog-title"
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {status?.id ? t("editStatus") : t("addStatus")}
      </DialogTitle>
      <DialogContent>
        <Box sx={classes.dialogContent}>
          <Box sx={classes.withHelpMessage}>
            <JobStatusSelect
              error={!!errors.parent}
              label={t("parentStatus") as string}
              name="parentStatus"
              onBlur={() => {
                if (!parent) {
                  setErrors({
                    ...errors,
                    parent: t("component.jobStatusDialog.validation.parent.required"),
                  })
                } else {
                  setErrors({ ...errors, parent: null })
                }
              }}
              onChange={(v) => {
                setParent(v)
                setErrors({
                  ...errors,
                  parent: null,
                })
              }}
              onlyParents
              required
              selectedOption={parent}
              testID="jobStatus"
              variant="filled"
            />
            {!errors.parent ? (
              <FieldHelperText
                message={t(
                  `component.jobStatusDialog.helperText.parent.level.${user?.organization?.level}`
                )}
              />
            ) : (
              <FieldHelperText error message={errors.parent} />
            )}
          </Box>
          <Box sx={classes.withHelpMessage}>
            <FielderTextField
              data-testid="name-Field"
              error={!!errors.name}
              fullWidth
              id="name"
              inputProps={{ maxLength: 255 }}
              label={t("statusName")}
              name="name"
              onBlur={() => {
                if (isBlank(name)) {
                  setErrors((prev) => ({
                    ...prev,
                    name: t("component.jobStatusDialog.validation.name.required"),
                  }))
                } else {
                  setErrors({ ...errors, name: null })
                }
              }}
              onChange={(e) => {
                setName(e.target.value)
                if (isBlank(e.target.value)) {
                  setErrors((prev) => ({
                    ...prev,
                    name: t("component.jobStatusDialog.validation.name.required"),
                  }))
                } else {
                  setErrors({ ...errors, name: null })
                }
              }}
              onFocus={(e: any) => e.target.select()}
              required
              value={name}
            />
            {!errors.name ? (
              <FieldHelperText message={t(`component.jobStatusDialog.helperText.name`)} />
            ) : (
              <FieldHelperText error message={errors.name} />
            )}
          </Box>
          <Box sx={classes.withHelpMessage}>
            <FielderTextField
              fullWidth
              id="description"
              inputProps={{ maxLength: 500, "data-testid": "description" }}
              label={t("description")}
              maxRows="2"
              minRows="2"
              multiline
              name="description"
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              value={description}
            />
            {!errors.description ? (
              <FieldHelperText />
            ) : (
              <FieldHelperText error message={errors.description} />
            )}
          </Box>
          <Box sx={[classes.withHelpMessage, { marginTop: "1.5rem" }]}>
            <Box
              onClick={(event: any) => {
                setColorPickerAnchorEl(event.currentTarget)
              }}
              sx={[
                classes.colorBox,
                {
                  backgroundColor: mediumColor,
                  borderColor: (theme) => theme.fielderColors.inputBorder,
                },
              ]}
            />
            <Popover
              PaperProps={{
                style: {
                  background: "transparent",
                  borderRadius: "8px",
                },
              }}
              anchorEl={colorPickerAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => {
                setColorPickerAnchorEl(null)
              }}
              open={showColorPicker}
            >
              <JobStatusColorPicker
                onChange={(colorOption: JobStatusColorOption) => {
                  setDarkColor(colorOption.dark)
                  setMediumColor(colorOption.medium)
                  setLightColor(colorOption.light)
                  setColorPickerAnchorEl(null)
                }}
              />
            </Popover>
            <FieldHelperText
              message={t("component.jobStatusDialog.helperText.color")}
              style={{ marginLeft: 0 }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={classes.dialogActions}>
        <Button
          disabled={loading}
          onClick={() => {
            onCancel?.()
          }}
          sx={{ border: "1px solid #ccc", minWidth: "80px" }}
        >
          {t("cancel")}
        </Button>
        <SaveButton
          disabled={loading || isBlank(name) || !parent}
          loading={loading}
          onClick={() => {
            onSave?.({
              parentStatusId: parent?.id,
              id: status?.id,
              name,
              description,
              lightColor,
              mediumColor,
              darkColor,
              isArchived: false, //cuz you shouldn't even see this status to begin with if it is archived
            })
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

const classes = {
  dialogContent: {
    backgroundColor: "#FFFFFF",
    paddingBottom: 0,
    paddingTop: "1rem",
  },
  dialogActions: {
    px: "1.5rem",
    paddingBottom: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #ccc",
  },
  colorBox: {
    height: "2.875rem",
    width: "6.25rem",
    marginBottom: "0.75rem",
    borderRadius: "4px",
    border: "1px solid #00ff00",
    cursor: "pointer",
  },
  withHelpMessage: {
    marginBottom: "0.75rem",
  },
} as const

export default JobStatusDialog
