import React from "react"
import MuiTooltip, { TooltipProps } from "@mui/material/Tooltip"

const classes = {
  arrow: {
    color: "#000000",
  },
  tooltip: {
    border: "1px solid #747474",
    backgroundColor: "#FDFDFD",
    color: "#000",
    fontSize: "1rem",
    opacity: 1,
    fontFamily: "Source Sans Pro, Arial, sans-serif",
    fontWeight: 500,
  },
}

function Tooltip(props: TooltipProps) {
  return <MuiTooltip arrow sx={classes} {...props} />
}

export default Tooltip
