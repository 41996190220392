import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"

import { NOT_SPECIFIED, createDayJS, formatPersonName } from "~/util"
import { useAuth } from "~/context/AuthContext"
import { JobNote as IJobNote } from "~/types"

interface Props {
  readonly note: IJobNote
}

function JobNote({ note }: Props) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const createdAt = createDayJS(note.createdAt, user?.organization?.timeZone)

  return (
    <Box
      key={note.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "1.25rem",
        gap: "0.625rem",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            fontWeight: "bold",
          }}
        >
          {formatPersonName(note.createdBy)}
        </Box>
        <Box
          sx={{
            fontSize: "0.75rem",
            color: (theme) => theme.fielderColors.mutedText,
          }}
        >
          {createdAt
            ? createdAt.format(t("format:dateFormat.longWithTime") as string)
            : NOT_SPECIFIED}
        </Box>
      </Box>
      <Box
        sx={{
          whiteSpace: "pre-wrap",
        }}
      >
        {note.text}
      </Box>
    </Box>
  )
}

export default JobNote
