/* eslint-disable react/jsx-no-literals */
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation, NetworkStatus } from "@apollo/client"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined"
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCartOutlined"
import WarningIcon from "@mui/icons-material/WarningOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import CircularProgress from "@mui/material/CircularProgress"
import MainLayout from "../../components/MainLayout"
import PageHeader from "../../components/PageHeader"
import Seo from "../../components/Seo"
import SnackbarMessage from "../../components/SnackbarMessage"
import SectionHeader from "../../components/SectionHeader"
import SectionContent from "../../components/SectionContent"
import { ADD_TO_CART } from "../../queries/addToCart"
import { GET_ORGANIZATION_ITEM_BY_ID } from "../../queries/getOrganizationItemById"
import { EDIT_ORGANIZATION_ITEM } from "../../queries/editOrganizationItem"
import { SYNC_ORGANIZATION_ITEM } from "../../queries/syncOrganizationItem"
import { parseGraphQLErrorCode, INVENTORY } from "../../util"
import { useAuth } from "../../context/AuthContext"
import AddToCartDialog from "./components/AddToCartDialog"
import BundleStatic from "./components/BundleStatic"
import ProductStatic from "./components/ProductStatic"
import ServiceStatic from "./components/ServiceStatic"
import { DefaultPermission, ItemType, OrganizationItem, Snack } from "../../types"
import ItemForm from "./components/ItemForm"
import useGetAccountingPlugin from "../../hooks/useGetAccountingPlugin"

interface StaticViewProps {
  readonly currencyCode: string
  readonly item: OrganizationItem
  readonly onImageUpload: () => void
}

function StaticView({ currencyCode, item, onImageUpload }: StaticViewProps) {
  switch (item?.type) {
    case ItemType.BUNDLE:
      return <BundleStatic currencyCode={currencyCode} item={item} onImageUpload={onImageUpload} />
    case ItemType.PRODUCT:
      return <ProductStatic currencyCode={currencyCode} item={item} onImageUpload={onImageUpload} />
    case ItemType.SERVICE:
      return <ServiceStatic currencyCode={currencyCode} item={item} onImageUpload={onImageUpload} />
    default:
      return <Box>Invalid item type: {item?.type}</Box>
  }
}

function EditOrganizationItem() {
  const { t } = useTranslation()
  const { id } = useParams()
  const { user, hasPermissions } = useAuth()
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [snack, setSnack] = useState<Snack>()
  const [organizationItem, setOrganizationItem] = useState<OrganizationItem>()
  const [isAddToCartDialogOpen, setIsAddToCartDialogOpen] = useState<boolean>(false)
  const currencyCode = user?.organization?.currencyCode ?? "USD"
  const { accountingPlugin } = useGetAccountingPlugin(user?.organization?.id)

  const [syncOrganizationItem, { loading: syncLoading }] = useMutation(SYNC_ORGANIZATION_ITEM, {
    onCompleted: () => {
      setSnack({
        messageKey: "page.inventoryItem.sync.success",
        messageOptions: { pluginName: accountingPlugin?.pluginProvider?.name },
        variant: "success",
      })
      refetchItem()
    },
    onError: (error) => {
      const errorCode = parseGraphQLErrorCode(error)

      if (errorCode.includes("organization-plugin.accounting-plugin")) {
        setSnack({ messageKey: errorCode, variant: "error" })
      } else {
        Sentry.captureException(error)
        setSnack({
          messageKey: "page.inventoryItem.sync.error",
          messageOptions: { pluginName: accountingPlugin?.pluginProvider?.name },
          variant: "error",
        })
      }
    },
  })

  const [editOrganizationItem] = useMutation(EDIT_ORGANIZATION_ITEM, {
    onCompleted: (data) => {
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      setInEditMode(false)
      setOrganizationItem(data.editOrganizationItem.organizationItem)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const {
    refetch: refetchItem,
    error,
    networkStatus: getOrganizationItemByIdNetworkStatus,
  } = useQuery(GET_ORGANIZATION_ITEM_BY_ID, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setOrganizationItem(data.getOrganizationItemById)
    },
  })

  const [addToCart, { loading: addToCartLoading }] = useMutation(ADD_TO_CART, {
    onCompleted: () => {
      setIsAddToCartDialogOpen(false)
      setSnack({
        messageKey: "messages.addToCart.success",
        variant: "success",
      })
    },
    onError: (error) => {
      setIsAddToCartDialogOpen(false)
      Sentry.captureException(error)
      setSnack({ messageKey: "messages.addToCart.error", variant: "error" })
    },
  })

  const loading =
    getOrganizationItemByIdNetworkStatus === NetworkStatus.loading || !organizationItem

  function handleCancel() {
    refetchItem()
    setInEditMode(false)
  }

  function handleSave(payload) {
    editOrganizationItem({ variables: payload })
  }

  function isOrderable(organizationItem: OrganizationItem): boolean {
    return (
      Boolean(organizationItem.isTopLevel) &&
      Boolean(!organizationItem.isArchived) &&
      Boolean(organizationItem.isAvailable) &&
      Boolean(hasPermissions?.([DefaultPermission.UpdateProductOrder]))
    )
  }

  return (
    <>
      <Seo title={t(INVENTORY.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={INVENTORY}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: INVENTORY.path, titleKey: INVENTORY.titleKey }]}
            icon={INVENTORY.icon}
            leafTitleKey="itemDetail"
          />
          {error ? (
            <Alert severity="error">
              {`${t("error.general.title")} ${t("error.general.message")}`}
            </Alert>
          ) : null}
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container direction="row" justifyContent="center">
              <Grid item lg={10} md={12} sx={{ flex: 1 }} xl={8}>
                <Paper>
                  <SectionHeader>
                    <label>{t("basicInfo")}</label>
                    {organizationItem?.isArchived && organizationItem.isTopLevel ? (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "rgba(218, 21, 5, 0.1)",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          fontSize: "0.875rem",
                          color: "#DA1505",
                          gap: "0.5rem",
                        }}
                      >
                        <WarningIcon
                          style={{
                            fontSize: "1.125rem",
                          }}
                        />
                        <span>{t("itemDiscontinued")}</span>
                      </Box>
                    ) : null}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      {isOrderable(organizationItem) ? (
                        <Button
                          disabled={syncLoading}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setIsAddToCartDialogOpen(true)
                          }}
                          size="small"
                          startIcon={<AddShoppingCartIcon />}
                          sx={(theme) => ({
                            [theme.breakpoints.down("xs")]: {
                              marginLeft: 0,
                            },
                          })}
                          variant="text"
                        >
                          {t("addToCart")}
                        </Button>
                      ) : null}
                      {!inEditMode &&
                      hasPermissions?.([DefaultPermission.UpdateOrganizationItem]) ? (
                        <>
                          {accountingPlugin ? (
                            !syncLoading ? (
                              <Button
                                disabled={syncLoading}
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  syncOrganizationItem({ variables: { id: organizationItem.id } })
                                }}
                                size="small"
                                startIcon={<SyncOutlinedIcon />}
                                sx={(theme) => ({
                                  [theme.breakpoints.down("xs")]: {
                                    marginLeft: 0,
                                  },
                                })}
                                variant="text"
                              >
                                {t(`syncWithPlugin.${accountingPlugin.pluginProvider.id}`)}
                              </Button>
                            ) : (
                              <Box
                                sx={{ display: "flex", alignItems: "center", padding: "0 10px" }}
                              >
                                <CircularProgress size={20} />
                              </Box>
                            )
                          ) : null}
                          <Button
                            onClick={() => {
                              setInEditMode(true)
                            }}
                            size="small"
                            startIcon={<EditIcon />}
                            sx={(theme) => ({
                              [theme.breakpoints.down("xs")]: {
                                marginLeft: 0,
                              },
                            })}
                            variant="text"
                          >
                            {t("edit")}
                          </Button>
                        </>
                      ) : null}
                    </Box>
                  </SectionHeader>
                  <Divider />
                  <SectionContent>
                    {!inEditMode ? (
                      <StaticView
                        currencyCode={currencyCode}
                        item={organizationItem}
                        onImageUpload={refetchItem}
                      />
                    ) : (
                      <ItemForm
                        accountingPlugin={accountingPlugin}
                        currencyCode={currencyCode}
                        isLoading={loading}
                        item={organizationItem}
                        onCancel={handleCancel}
                        onImageUpload={refetchItem}
                        onSave={handleSave}
                      />
                    )}
                  </SectionContent>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Box>
      </MainLayout>
      {isAddToCartDialogOpen && organizationItem ? (
        <AddToCartDialog
          currencyCode={currencyCode}
          loading={addToCartLoading}
          onClose={() => {
            setIsAddToCartDialogOpen(false)
          }}
          onSubmit={({ organizationItemId, quantity }) => {
            addToCart({
              variables: {
                organizationItemId,
                quantity,
              },
            })
          }}
          open
          organizationItem={organizationItem}
        />
      ) : null}
    </>
  )
}

export default EditOrganizationItem
