import React from "react"
import { useTranslation } from "react-i18next"
import AppVersion from "./AppVersion"
import Box from "@mui/material/Box"

interface PoweredByFielderProps {
  readonly fontSize?: number | string
  readonly fontWeight?: number | string
  readonly color?: string
}

function PoweredByFielder({
  fontSize = "0.875rem",
  fontWeight = "normal",
  color = "#DDD",
}: PoweredByFielderProps) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize,
        fontWeight,
        color,
      }}
    >
      <Box
        sx={{
          marginTop: "1.25rem",
          color: "#ededed",
          fontWeight: "bold",
          textAlign: "center",
          "& a": {
            color: "#ddd",
          },
        }}
      >
        {t("poweredBy")}{" "}
        <a href="https://www.fielderapp.com" target="__blank">
          Fielder
        </a>{" "}
        &#8482;
      </Box>
      <AppVersion />
    </Box>
  )
}

export default PoweredByFielder
