import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import { HeaderLogo, Seo } from "../components"

function NotFoundPage() {
  const { t } = useTranslation("common", { useSuspense: typeof window !== "undefined" })

  return (
    <div>
      <Seo title="404" />
      <main>
        <div
          css={{
            backgroundColor: "#212121",
          }}
        >
          <div
            css={{
              width: 266,
              padding: "1rem",
              paddingBottom: "0.5rem",
            }}
          >
            <Link to="/">
              <HeaderLogo height="auto" width="100%" />
            </Link>
          </div>
        </div>
        <div css={{ margin: "1.25rem" }}>
          <h1>{t("error.routeNotFound.title", "Not Found")}</h1>
          <p>
            {t(
              "error.routeNotFound.message",
              "You just tried to access a page that doesn&#39;t exist. Please make sure you've entered the correct URL in your browser's address bar"
            )}
          </p>
        </div>
      </main>
    </div>
  )
}

export default NotFoundPage
