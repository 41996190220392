import React, { useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import RestoreIcon from "@mui/icons-material/RestoreFromTrashOutlined"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import capitalize from "capitalize"
import SectionHeader from "../../../../components/SectionHeader"
import FieldHelperText from "../../../../components/FieldHelperText"
import FielderTextField from "../../../../components/FielderTextField"
import PhoneNumberInput from "../../../../components/PhoneNumberInput"
import RoleSelect from "../../../../components/RoleSelect"
import UserStatusSelect from "../../../../components/UserStatusSelect"
import { createDayJS, isBlank, isValidEmail } from "../../../../util"
import { useAuth } from "../../../../context/AuthContext"
import { usePrompt } from "../../../../hooks/usePrompt"
import {
  User,
  UserStatus,
  DefaultPermission,
  UserFormInput,
  LanguageCode,
  Attachment,
} from "../../../../types"
import SaveButton from "../../../../components/SaveButton"
import LanguageSelect from "../../../../components/LanguageSelect"

interface Props {
  readonly loading?: boolean
  readonly onCancel?: () => void
  readonly onDeactivate?: () => void
  readonly onReactivate?: () => void
  readonly onSubmit: (user: UserFormInput) => void
  readonly user?: User
  readonly onResendActivationCode?: () => void
}

function UserForm({
  loading,
  onDeactivate,
  onReactivate,
  onCancel,
  onSubmit,
  user,
  onResendActivationCode,
}: Props) {
  const { t } = useTranslation()
  const { hasPermissions, user: currentUser } = useAuth()
  const [email, setEmail] = useState(user?.email ?? "")
  const [firstName, setFirstName] = useState<string>(user?.firstName ?? "")
  const [lastName, setLastName] = useState<string>(user?.lastName ?? "")
  const [jobTitle, setJobTitle] = useState<string>(user?.jobTitle ?? "")
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string>(user?.mobilePhoneNumber ?? "")
  const [languageCode, setLanguageCode] = useState<LanguageCode | undefined | null>(
    user?.languageCode
  )
  const [roles, setRoles] = useState(user?.roles ?? [])
  const [status, setStatus] = useState<keyof typeof UserStatus | "">(user?.status ?? "")
  const [avatar, setAvatar] = useState<Attachment | null>(user?.avatar ?? null)
  const [errors, setErrors] = useState<{
    email?: string | null
    firstName?: string | null
    lastName?: string | null
    jobTitle?: string | null
    mobilePhoneNumber?: string | null
    roles?: string | null
    status?: string | null
    languageCode?: string | null
  }>({
    email: null,
    firstName: null,
    lastName: null,
    jobTitle: null,
    mobilePhoneNumber: null,
    roles: null,
    status: null,
    languageCode: null,
  })
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const id = user?.id // the id of the User record being edited
  const isEditMode = !!id

  const handleChangeLanguageCode = useCallback(
    (selection?: LanguageCode | null) => {
      setIsDirty(true)
      setLanguageCode(selection)
      setErrors({
        ...errors,
        languageCode: null,
      })
    },
    [errors]
  )

  function isValid(): boolean {
    const hasErrors = !Object.values(errors).every((o) => o === null)
    if (hasErrors) {
      return false
    }

    return (
      isValidEmail(email) &&
      !isBlank(firstName) &&
      !isBlank(lastName) &&
      roles?.length > 0 &&
      (isEditMode ? !!status : true)
    )
  }

  function handleSubmit(): void {
    let isValid = true
    const updatedErrors = { ...errors }
    if (isBlank(email)) {
      updatedErrors.email = t("messages.fieldIsRequired")
      isValid = false
    } else if (!isValidEmail(email)) {
      updatedErrors.email = t("component.userForm.validation.email")
      isValid = false
    }
    if (isBlank(firstName)) {
      updatedErrors.firstName = t("messages.fieldIsRequired")
      isValid = false
    }
    if (isBlank(lastName)) {
      updatedErrors.lastName = t("messages.fieldIsRequired")
      isValid = false
    }
    if (roles.length === 0) {
      updatedErrors.roles = t("messages.fieldIsRequired")
      isValid = false
    }

    setErrors(updatedErrors)

    if (Object.values(updatedErrors).filter((v) => !!v).length > 0 || !isValid) {
      // Note that this check won't catch the errors set directly above
      // because that call to setErrors basically
      // just *schedules* an update to the errors object.
      return
    }

    onSubmit({
      id,
      email,
      firstName,
      lastName,
      jobTitle,
      mobilePhoneNumber,
      roleIds: roles.map((r) => r.id),
      status: status !== "" ? status : undefined,
      languageCode,
    })
  }

  const lastLogin = user?.lastLogin
    ? createDayJS(user.lastLogin, currentUser?.organization?.timeZone, currentUser)
    : null

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  return (
    <form css={classes.form}>
      <Box sx={classes.sectionWrapper}>
        {isEditMode && status !== "UNVERIFIED" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "1.25rem",
            }}
          >
            <Box sx={classes.staticFieldContainer}>
              <Box sx={classes.label}>{t("email")}</Box>
              <Box>{email}</Box>
            </Box>
            <Box sx={classes.staticFieldContainer} style={{ textAlign: "right", marginRight: 16 }}>
              <Box sx={classes.label}>{t("lastLogin")}</Box>
              <Box>{lastLogin?.format("llll")}</Box>
            </Box>
          </Box>
        ) : null}
        {(!isEditMode || status === "UNVERIFIED") && (
          <Box sx={classes.fieldContainer}>
            <FielderTextField
              error={!!errors.email}
              fullWidth
              inputProps={{ maxLength: 100 }}
              label={t("emailAddress")}
              margin="normal"
              name="email"
              onBlur={() => {
                if (!isValidEmail(email)) {
                  setErrors({ ...errors, email: t("component.userForm.validation.email") })
                }
              }}
              onChange={(e) => {
                setIsDirty(true)
                setEmail(e.target.value)
                setErrors({ ...errors, email: null })
              }}
              onFocus={(e) => e.target.select()}
              required
              type="email"
              value={email}
            />
            {errors.email ? <FieldHelperText error message={errors.email} /> : null}
          </Box>
        )}
        <Box sx={classes.fieldContainer}>
          <FielderTextField
            error={!!errors.firstName}
            fullWidth
            inputProps={{ maxLength: 255 }}
            label={t("firstName")}
            margin="normal"
            name="firstName"
            onBlur={() => {
              if (isBlank(firstName)) {
                setErrors({ ...errors, firstName: t("messages.fieldIsRequired") })
              } else {
                setFirstName(capitalize.words(firstName, true))
              }
            }}
            onChange={(e) => {
              setIsDirty(true)
              setFirstName(e.target.value)
              setErrors({ ...errors, firstName: null })
            }}
            onFocus={(e) => e.target.select()}
            required
            value={firstName}
          />
          {errors.firstName ? <FieldHelperText error message={errors.firstName} /> : null}
        </Box>
        <Box sx={classes.fieldContainer}>
          <FielderTextField
            error={!!errors.lastName}
            fullWidth
            inputProps={{ maxLength: 255 }}
            label={t("lastName")}
            margin="normal"
            name="lastName"
            onBlur={() => {
              if (isBlank(lastName)) {
                setErrors({ ...errors, lastName: t("messages.fieldIsRequired") })
              } else {
                setLastName(capitalize.words(lastName, true))
              }
            }}
            onChange={(e) => {
              setIsDirty(true)
              setLastName(e.target.value)
              setErrors({ ...errors, lastName: null })
            }}
            onFocus={(e) => e.target.select()}
            required
            value={lastName}
          />
          {errors.lastName ? <FieldHelperText error message={errors.lastName} /> : null}
        </Box>
        <Box sx={classes.fieldContainer}>
          <RoleSelect
            aria-label={t("securityRoles")}
            errorMessage={errors.roles}
            label={t("securityRoles")}
            name="role"
            onBlur={() => {
              if (roles.length === 0) {
                setErrors({ ...errors, roles: t("messages.fieldIsRequired") })
              }
            }}
            onChange={(values) => {
              setIsDirty(true)
              setRoles(values)
              setErrors({ ...errors, roles: null })
            }}
            required
            selectedValues={roles}
          />
        </Box>
        {isEditMode && user?.status !== "UNVERIFIED" && user?.status !== "DEACTIVATED" ? (
          <Box sx={classes.fieldContainer}>
            <UserStatusSelect
              defaultValue={status}
              label={t("accountStatus")}
              name="status"
              onChange={(val) => {
                setIsDirty(true)
                setStatus(val.id)
              }}
              required
            />
          </Box>
        ) : null}
        <Box sx={classes.fieldContainer}>
          <FielderTextField
            fullWidth
            inputProps={{ maxLength: 255 }}
            label={t("jobTitle")}
            margin="normal"
            name="jobTitle"
            onBlur={() => {
              if (jobTitle) {
                setJobTitle(capitalize.words(jobTitle, true))
              }
            }}
            onChange={(e) => {
              setIsDirty(true)
              setJobTitle(e.target.value)
              setErrors({ ...errors, jobTitle: null })
            }}
            onFocus={(e) => e.target.select()}
            required={false}
            value={jobTitle}
          />
          {errors.jobTitle ? <FieldHelperText error message={errors.jobTitle} /> : null}
        </Box>
        <Box sx={classes.fieldContainer}>
          <PhoneNumberInput
            label={t("mobilePhone")}
            margin="normal"
            onChange={(val) => {
              setIsDirty(true)
              setMobilePhoneNumber(val ?? "")
              setErrors({ ...errors, mobilePhoneNumber: null })
            }}
            value={mobilePhoneNumber}
          />
          {errors.mobilePhoneNumber ? (
            <FieldHelperText error message={errors.mobilePhoneNumber} />
          ) : null}
        </Box>
        <Box sx={classes.fieldContainer}>
          <LanguageSelect
            error={Boolean(errors.languageCode)}
            label={t("language") as string}
            name="language"
            onChange={handleChangeLanguageCode}
            required={false}
            value={languageCode ?? user?.organization?.languageCode}
          />
          {errors.languageCode ? (
            <FieldHelperText error message={errors.languageCode} />
          ) : (
            <FieldHelperText message={t("component.userForm.languageCode.helperText")} />
          )}
        </Box>
        {isEditMode && user?.status === "UNVERIFIED" ? (
          <Box sx={classes.staticFieldContainer}>
            <Box sx={classes.label}>{t("accountStatus")}</Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>{t(`userStatus.UNVERIFIED`)}</Box>
              <Box>
                <Button disabled={loading} onClick={onResendActivationCode} variant="outlined">
                  {loading ? (
                    <CircularProgress color="secondary" size={20} thickness={6.0} />
                  ) : (
                    <Box>{t("resendActivationCode")}</Box>
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box sx={classes.buttonWrapper}>
        <Button color="secondary" disabled={loading} onClick={onCancel} variant="outlined">
          {t("cancel")}
        </Button>
        <SaveButton
          disabled={loading || !isValid()}
          loading={loading}
          onClick={handleSubmit}
          sx={{
            minWidth: "140px",
          }}
        />
      </Box>
    </form>
  )
}

const classes = {
  form: {
    paddingBottom: "1.25rem",
    paddingTop: "0",
    flex: "1",
  },
  sectionWrapper: {
    marginBottom: "1.875rem",
  },
  buttonWrapper: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  fieldContainer: {
    marginBottom: "1.25rem",
  },
  staticFieldContainer: {
    marginTop: "1rem",
    marginBottom: "0.5rem",
    fontSize: "1.0rem",
  },
  label: {
    fontWeight: "bold",
  },
} as const

export default UserForm
