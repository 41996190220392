import gql from "graphql-tag"

export const CREATE_ROLE = gql`
  mutation CreateRole($name: String!, $description: String, $permissionIds: [ID!]!) {
    createRole(input: { name: $name, description: $description, permissionIds: $permissionIds }) {
      role {
        id
        name
        description
        isStandard
        permissions {
          id
          group
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
        }
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`
