import React from "react"
import { HexColorPicker } from "react-colorful"

interface ColorPickerProps {
  readonly color: string
  readonly onChange: (colorCode: string) => void
}

function ColorPicker({ color, onChange }: ColorPickerProps): JSX.Element {
  return <HexColorPicker color={color} onChange={onChange} />
}

export default ColorPicker
