import React, { useState } from "react"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import Chip from "@mui/material/Chip"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import FielderBaseInput from "./FielderBaseInput"
import { SelectorOption } from "../types"

const defaultId = "MultiSelect" + new Date().getTime()

interface MultiSelectProps {
  readonly disabled?: boolean
  readonly error?: boolean
  readonly id?: string
  readonly label?: string
  readonly name?: string
  readonly onBlur?: () => void
  readonly onChange: (value: SelectorOption[]) => void
  readonly options: SelectorOption[]
  readonly renderOptions?: (option: SelectorOption[]) => React.ReactNode
  readonly renderValue?: (value: string[]) => React.ReactNode
  readonly required?: boolean
  readonly selectedValues: SelectorOption[]
  readonly size?: "small" | "medium"
  readonly style?: React.CSSProperties
  readonly testID?: string
}

function MultiSelect({
  disabled,
  error = false,
  id = defaultId,
  label,
  name,
  onBlur,
  onChange,
  options,
  renderOptions,
  renderValue,
  required,
  selectedValues,
  size = "medium",
  style,
  testID,
}: MultiSelectProps) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <FormControl
      data-testid={testID}
      error={error}
      fullWidth
      required={required}
      size={size}
      style={style}
      sx={{
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: "#fff",
        "& label.Mui-focused": {
          color: (theme) => theme.fielderColors.black,
          transform: "translate(14px, 7px) scale(0.75)",
        },
        "& label.Mui-error": {
          color: (theme) => theme.fielderColors.error,
        },
        "& label.MuiInputLabel-shrink": {
          transform: "translate(14px, 7px) scale(0.75)",
        },
      }}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        disabled={disabled}
        id={id}
        input={<FielderBaseInput />}
        inputProps={{ name: name }}
        label={label}
        multiple
        onBlur={onBlur}
        onChange={(event) => {
          const currentSelections = event.target.value
          const selectedOptions = options.filter((o) => currentSelections.indexOf(o.id) > -1)
          onChange(selectedOptions)
        }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        renderValue={(selectedValues) => {
          return renderValue ? (
            renderValue(selectedValues)
          ) : (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {selectedValues.map((value) => {
                const option = options.find((o) => o.id === value)
                return (
                  <Chip
                    key={value}
                    label={option?.name}
                    sx={{
                      margin: "0.125rem",
                    }}
                  />
                )
              })}
            </Box>
          )
        }}
        required={required}
        size={size}
        value={selectedValues.map((s) => s.id)}
      >
        {renderOptions
          ? renderOptions(options)
          : options.map((v) => (
              <MenuItem key={v.id} value={v.id}>
                <Checkbox checked={selectedValues.map((s) => s.id).indexOf(v.id) > -1} />
                <ListItemText primary={v.name} />
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  )
}

export default MultiSelect
