import React from "react"
import { alpha, styled } from "@mui/material/styles"
import InputBase from "@mui/material/InputBase"

/**
 * This was created to serve as a base input for custom Fielder dropdowns
 * See SelectorField and MultiSelect for usage examples.
 **/
const FielderBaseInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "#ffffff",
    display: "block",
    paddingTop: "25px",
    paddingRight: "12px",
    paddingBottom: "4px",
    paddingLeft: "12px",
    border: "1px solid #e2e2e1",
    borderColor: theme.fielderColors.inputBorder,
    overflow: "hidden",
    borderRadius: "4px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}))

export default FielderBaseInput
