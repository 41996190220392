/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from "react"

interface RequestEstimateIconProps {
  color?: string
  size?: number
}

const RequestEstimateIcon: FC<RequestEstimateIconProps> = ({
  color = "#000",
  size = 24,
  ...rest
}) => {
  return (
    <svg
      fill={color}
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v4h5v12H6zm5-1h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1h-3v-1h4v-2h-2V9h-2v1h-1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1H9v2h2v1z" />
    </svg>
  )
}

export default RequestEstimateIcon
