import gql from "graphql-tag"
import FullEstimateTemplate from "./fragments/estimateTemplate"

export const CREATE_ESTIMATE_TEMPLATE = gql`
  mutation CreateEstimateTemplate(
    $name: String!
    $description: String
    $notes: String
    $discount: DiscountInput
    $lineItems: [EstimateTemplateLineItemInput!]
    $footerTitle: String
    $footerBody: String
    $contractJson: String
  ) {
    createEstimateTemplate(
      input: {
        name: $name
        description: $description
        notes: $notes
        discount: $discount
        lineItems: $lineItems
        footerTitle: $footerTitle
        footerBody: $footerBody
        contractJson: $contractJson
      }
    ) {
      estimateTemplate {
        ...FullEstimateTemplate
      }
    }
  }
  ${FullEstimateTemplate}
`
