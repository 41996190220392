import gql from "graphql-tag"

export const CREATE_JOB_STATUS = gql`
  mutation CreateJobStatus(
    $parentStatusId: ID!
    $name: String!
    $description: String
    $lightColor: String
    $mediumColor: String
    $darkColor: String
  ) {
    createJobStatus(
      input: {
        parentStatusId: $parentStatusId
        name: $name
        description: $description
        lightColor: $lightColor
        mediumColor: $mediumColor
        darkColor: $darkColor
      }
    ) {
      jobStatus {
        id
        name
        description
        lightColor
        mediumColor
        darkColor
        isArchived
        parentStatus {
          id
          name
        }
      }
    }
  }
`
