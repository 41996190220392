import React from "react"
import Box from "@mui/material/Box"
import BasicInfoStatic from "./BasicInfoStatic"
import BundleComponentsStatic from "./BundleComponentsStatic"
import Sidebar from "./Sidebar"
import StaticField from "../../../components/StaticField"
import { formatMoney, isNumeric } from "../../../util"
import { useTranslation } from "react-i18next"
import { OrganizationItem } from "../../../types"
import HelperTextContainer from "./HelperTextContainer"

interface BundleStaticProps {
  readonly currencyCode: string
  readonly item: OrganizationItem
  readonly onImageUpload?: () => void
}

function BundleStatic({ currencyCode, item, onImageUpload }: BundleStaticProps) {
  const { t } = useTranslation()

  return (
    <Box
      sx={(theme) => {
        return {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          flex: 1,
          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
          },
        }
      }}
    >
      <Sidebar item={item} onImageUpload={onImageUpload} />
      <Box
        sx={(theme) => {
          return {
            flex: 1,
            paddingLeft: "0",
            marginTop: "0.5rem",
            [theme.breakpoints.up("md")]: {
              padding: "1rem 1rem 1rem 2rem",
            },
          }
        }}
      >
        <BasicInfoStatic item={item} showBottomDivider />
        <Box>
          <StaticField
            label={t("status")}
            renderHelpText={() => {
              return (
                <HelperTextContainer>
                  <Box>{t(`itemStatus.helperText`)}</Box>
                </HelperTextContainer>
              )
            }}
            showBottomDivider
            value={t(`itemStatus.label.${item.isActive ? "ACTIVE" : "INACTIVE"}`)}
          />
        </Box>
        <StaticField
          label={t("unitSalePrice")}
          showBottomDivider={false}
          value={formatMoney(
            currencyCode,
            isNumeric(item.unitSalePrice) ? (item.unitSalePrice as number) : ""
          )}
        />
        <BundleComponentsStatic currencyCode={currencyCode} item={item} />
      </Box>
    </Box>
  )
}

export default BundleStatic
