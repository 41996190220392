import gql from "graphql-tag"

export const CREATE_JOB_CATEGORY = gql`
  mutation CreateJobCategory($name: String!, $isArchived: Boolean) {
    createJobCategory(input: { name: $name, isArchived: $isArchived }) {
      jobCategory {
        id
        name
        isArchived
      }
    }
  }
`
