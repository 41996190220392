import gql from "graphql-tag"

export const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $firstName: String
    $lastName: String
    $jobTitle: String
    $mobilePhoneNumber: String
    $roleIds: [ID!]!
    $organizationId: ID
    $languageCode: LanguageCode
  ) {
    createUser(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        jobTitle: $jobTitle
        mobilePhoneNumber: $mobilePhoneNumber
        roleIds: $roleIds
        organizationId: $organizationId
        languageCode: $languageCode
      }
    ) {
      user {
        id
        email
        firstName
        lastName
        jobTitle
        mobilePhoneNumber
        status
        languageCode
        roles {
          id
          name
        }
        avatar {
          id
          signedUrl
          updatedAt
        }
        lastLogin
        createdAt
        updatedAt
        organization {
          id
          tradeName
        }
      }
    }
  }
`

