import React from "react"
import Box from "@mui/material/Box"

const classes = {
  errorContainer: {
    backgroundColor: "#FF000022",
    padding: "1.25rem",
    border: "1px solid red",
    borderRadius: "4px",
    marginBottom: "1.5rem",
  },
  errorTitle: {
    color: "#990000",
  },
  errorMessage: {
    color: "#550000",
  },
}

interface ErrorMessageProps {
  title: string
  message: string
}

function ErrorMessage({ title, message }: ErrorMessageProps) {
  return (
    <Box data-testid="error-msg-container" sx={classes.errorContainer}>
      <Box data-testid="error-msg-title" sx={classes.errorTitle}>
        {title}
      </Box>
      <Box data-testid="error-msg-text" sx={classes.errorMessage}>
        {message}
      </Box>
    </Box>
  )
}

export default ErrorMessage
