import React, { useState } from "react"
import capitalize from "capitalize"
import { gql, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { isBlank } from "../../../../util"
import { useAuth } from "../../../../context/AuthContext"
import SelectorField from "../../../../components/SelectorField"
import FieldHelperText from "../../../../components/FieldHelperText"
import FielderTextField from "../../../../components/FielderTextField"
import { JobWorkflow } from "../../../../types"
import SaveButton from "../../../../components/SaveButton"

const ALL_PARENT_JOB_WORKFLOWS = gql`
  query AllParentJobWorkflows {
    allJobWorkflows(jobWorkflowFilter: { onlyParentWorkflows: true }) {
      id
      name
      description
      isArchived
    }
  }
`

interface JobWorkflowDialogFormInput {
  parentWorkflowId?: string
  name: string
  description?: string
}

interface JobWorkflowDialogProps {
  isSaving?: boolean
  onCancel: () => void
  onSave: (value: JobWorkflowDialogFormInput) => void
  open?: boolean
}

function JobWorkflowDialog({
  isSaving = false,
  onCancel,
  onSave,
  open = false,
}: JobWorkflowDialogProps) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [name, setName] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [parent, setParent] = useState<JobWorkflow | null>(() =>
    user?.organization?.level === 1 ? { id: "DEFAULT_WORKFLOW" } : null
  )
  const [errors, setErrors] = useState(() => ({
    name: null,
    parent: null,
    description: null,
  }))

  const { loading, data } = useQuery(ALL_PARENT_JOB_WORKFLOWS, {
    fetchPolicy: "cache-and-network",
  })

  const handleBlurParent = () => {
    if (!parent) {
      setErrors((prev) => ({
        ...prev,
        parent: t("page.jobWorkflow.validation.parent.required"),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        parent: null,
      }))
    }
  }

  const handleBlurName = () => {
    if (isBlank(name)) {
      setErrors((prev) => ({
        ...prev,
        name: t("page.jobWorkflow.validation.name.required"),
      }))
    } else {
      setName(capitalize.words(name, true))
      setErrors((prev) => ({
        ...prev,
        name: null,
      }))
    }
  }

  const handleChangeName = (e: any) => {
    const value = e.target.value
    setName(value)
    setErrors((prev) => ({
      ...prev,
      name: null,
    }))
  }

  const handleBlurDescription = () => {
    setErrors((prev) => ({
      ...prev,
      description: null,
    }))
  }

  const handleChangeDescription = (e: any) => {
    const value = e.target.value
    setDescription(value)
    setErrors((prev) => ({
      ...prev,
      description: null,
    }))
  }

  function isValid() {
    return !errors.name && !isBlank(name) && !!parent
  }

  const parentWorkflowOptions = data?.allJobWorkflows || []

  return (
    <Dialog
      aria-labelledby="edit-workflow-dialog-title"
      data-testid="WorkflowDialog"
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogTitle
        id="edit-workflow-dialog-title"
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {t("createJobWorkflow")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            paddingBottom: 0,
            paddingTop: "1rem",
          }}
        >
          {user?.organization?.level && user.organization.level > 1 ? (
            <Box sx={classes.withHelpMessage}>
              <SelectorField
                error={!!errors.parent}
                label={t("parentWorkflow")}
                name="parentWorkflow"
                onBlur={handleBlurParent}
                onChange={(v) => {
                  setParent(v)
                  setErrors((prev) => ({
                    ...prev,
                    parent: null,
                  }))
                }}
                options={parentWorkflowOptions}
                required
                value={parent?.id}
                variant="filled"
              />
              {!errors.parent ? (
                <FieldHelperText
                  data-testid="parent-help"
                  message={t("page.jobWorkflow.helperText.parent")}
                />
              ) : (
                <FieldHelperText data-testid="name-Error" error message={errors.parent} />
              )}
            </Box>
          ) : null}
          <Box sx={classes.withHelpMessage}>
            <FielderTextField
              data-testid="name-Field"
              error={!!errors.name}
              fullWidth
              id="name"
              inputProps={{ maxLength: 255 }}
              label={t("workflowName")}
              name="name"
              onBlur={handleBlurName}
              onChange={handleChangeName}
              onFocus={(e) => e.target.select()}
              required
              value={name}
            />
            {!errors.name ? (
              <FieldHelperText message={t("page.jobWorkflow.helperText.name")} />
            ) : (
              <FieldHelperText data-testid="name-Error" error message={errors.name} />
            )}
          </Box>
          <Box sx={classes.withHelpMessage}>
            <FielderTextField
              data-testid="description-Field"
              error={!!errors.description}
              fullWidth
              id="description"
              inputProps={{ maxLength: 255 }}
              label={t("description")}
              name="description"
              onBlur={handleBlurDescription}
              onChange={handleChangeDescription}
              value={description}
            />
            {!errors.description ? (
              <FieldHelperText
                data-testid="description-help"
                message={t("page.jobWorkflow.helperText.description")}
              />
            ) : (
              <FieldHelperText data-testid="description-Error" error message={errors.description} />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={classes.dialogActions}>
        <Button
          disabled={loading || isSaving}
          onClick={() => {
            onCancel?.()
          }}
          sx={{ border: "1px solid #ccc", minWidth: "80px" }}
        >
          {t("cancel")}
        </Button>
        <SaveButton
          disabled={loading || isSaving || !isValid()}
          loading={loading}
          onClick={() => {
            onSave?.({
              parentWorkflowId: parent?.id,
              name,
              description,
            })
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

const classes = {
  dialogActions: {
    px: "1.5rem",
    paddingBottom: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  withHelpMessage: {
    marginBottom: "0.75rem",
  },
} as const

export default JobWorkflowDialog
