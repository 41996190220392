import gql from "graphql-tag"
import FullCustomer from "./fragments/FullCustomer"

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer(
    $name: String!
    $email: String
    $phoneNumber: String
    $type: CustomerType!
    $address: AddressInput
    $languageCode: LanguageCode
  ) {
    createCustomer(
      input: {
        name: $name
        email: $email
        phoneNumber: $phoneNumber
        type: $type
        address: $address
        languageCode: $languageCode
      }
    ) {
      customer {
        ...FullCustomer
      }
    }
  }
  ${FullCustomer}
`
