import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import FlagIcon from "@mui/icons-material/Flag"
import Box from "@mui/material/Box"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { Job } from "~/types"

function getAddress(job: Job): string {
  if (job?.address?.addressString) {
    const parts = job.address.addressString.split(",").map((p) => p.trim())
    const streetAddr = parts[0]
    const city = parts[1]
    return `${streetAddr}, ${city}`
  } else {
    return "--"
  }
}

interface JobSelectorResult {
  readonly job: Job
}

function JobSelectorResult({ job }: JobSelectorResult) {
  const { t } = useTranslation()
  const backgroundColor = job.workflowStep?.jobStatus?.lightColor
    ? `${job.workflowStep.jobStatus.lightColor}`
    : `#90C0FF19`

  return (
    <Box
      sx={{
        flex: 1,
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0.625rem",
        paddingLeft: "0.625rem",
        color: (theme) => theme.fielderColors.mutedText,
        height: "86px",
        overflow: "hidden",
        maxWidth: "100%",
        width: "100%",
        backgroundColor: backgroundColor,
        fontSize: "0.875rem",
        flexWrap: "nowrap",
      }}
    >
      <Box
        sx={{
          marginRight: "0.625rem",
          fontSize: "large",
          cursor: "move",
          userSelect: "none",
          width: "1.5rem",
        }}
      >
        <DragIndicatorIcon />
      </Box>
      <Box
        sx={{
          boxSizing: "border-box",
          flex: 1,
          userSelect: "none",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          overflow: "hidden",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            flex: 1,
          }}
        >
          <Box
            sx={{
              gap: "0.5rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="span"
              sx={{
                display: "inline",
                width: "9.5rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Box
                sx={{
                  fontSize: "1.1em",
                  boxSizing: "border-box",
                  fontWeight: "600",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {job.customer?.name}
              </Box>
            </Box>
            {job.customer.flags?.length > 0 ? (
              <FlagIcon
                style={{
                  color: job.customer.flags[0].colorCode,
                  fontSize: "14px",
                  marginTop: "2px",
                }}
              />
            ) : null}
          </Box>
          <Box sx={{ whiteSpace: "nowrap" }}>
            <Link
              onMouseDown={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              to={`/app/jobs/edit/${job.id}`}
            >
              <Box
                component="span"
                sx={{
                  fontWeight: "600",
                  textDecoration: "underline",
                  "&:hover": {
                    color: (theme) => theme.fielderColors.text,
                    textDecoration: "underline",
                  },
                }}
              >
                {t("job")} #{job.number}
              </Box>
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: "0.875rem",
            boxSizing: "border-box",
          }}
        >
          {getAddress(job)}
        </Box>
        <Box
          component="span"
          sx={{
            boxSizing: "border-box",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            flex: 1,
            width: "15rem",
          }}
        >
          {job.description}
        </Box>
      </Box>
    </Box>
  )
}

export default JobSelectorResult
