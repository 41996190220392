/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from "react"
import { Navigate, useLocation, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/EditOutlined"
import VisibilityIcon from "@mui/icons-material/Visibility"
import Skeleton from "@mui/material/Skeleton"
import EmptyState from "../../../components/EmptyState"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import SearchField from "../../../components/SearchField"
import Seo from "../../../components/Seo"
import SnackbarMessage from "../../../components/SnackbarMessage"
import SortableTableHeader from "../../../components/SortableTableHeader"
import { ALL_ROLES } from "../../../queries/allRoles"
import { isBlank, SETTINGS, useDebounce } from "../../../util"
import { useAuth } from "../../../context/AuthContext"
import { DefaultPermission, Role, Snack, SortDirection } from "../../../types"

const NUM_COLUMNS = 3

function RolesListPage() {
  const { t } = useTranslation()
  const location = useLocation()
  const { hasPermissions } = useAuth()
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [filter, setFilter] = useState<string>("")
  const [sortDir, setSortDir] = useState<SortDirection>(SortDirection.ASC)
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const debouncedSearchTerm = useDebounce(filter, 500)

  const { loading, error, data } = useQuery(ALL_ROLES, {
    variables: {
      filter: debouncedSearchTerm,
      sortDir,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: "ALL_ROLES",
      debounceTimeout: 50,
    },
  })

  function handleRowClick(id: string) {
    if (hasPermissions?.([DefaultPermission.UpdateRole, DefaultPermission.ReadPermission])) {
      setRedirectTo({
        to: `/app/settings/roles/edit/${id}`,
        replace: false,
      })
    }
  }

  const roles = data?.allRoles

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t("securityRoles")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="securityRoles"
          />
          <Box
            sx={{
              marginBottom: "1.25rem",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={(theme) => ({
                width: "400px",
                [theme.breakpoints.down("xs")]: {
                  width: "100%",
                },
              })}
            >
              <SearchField
                onChange={setFilter}
                placeholder={t("searchRoles")}
                term={filter}
                testID="SearchField"
              />
            </Box>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down("xs")]: {
                  marginTop: "0.625rem",
                  alignContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                },
              })}
            >
              {hasPermissions?.([
                DefaultPermission.CreateRole,
                DefaultPermission.ReadPermission,
              ]) ? (
                <Button
                  aria-label={t("createRole") as string}
                  color="primary"
                  data-testid="createRoleButton"
                  onClick={() => {
                    setRedirectTo({
                      to: "/app/settings/roles/create",
                      replace: false,
                    })
                  }}
                  sx={{
                    fontWeight: "bold",
                    "& svg": {
                      fontSize: "1.0rem",
                    },
                    "& div": {
                      marginLeft: "0.625rem",
                      marginRight: "0.625rem",
                    },
                  }}
                  variant="contained"
                >
                  <AddIcon />
                  <Box>{t("createRole")}</Box>
                </Button>
              ) : null}
            </Box>
          </Box>
          {!error && !loading && roles?.length == 0 && (
            <>
              {isBlank(filter) && (
                <EmptyState title={t("page.roleList.emptyState.title")}>
                  {hasPermissions?.([DefaultPermission.CreateRole]) ? (
                    <Box>{t("page.roleList.emptyState.message")}</Box>
                  ) : null}
                </EmptyState>
              )}
              {!isBlank(filter) && (
                <EmptyState title={t("page.roleList.noMatchingResults.title")}>
                  <Box>{t("page.roleList.noMatchingResults.message")}</Box>
                </EmptyState>
              )}
            </>
          )}
          {!error && (loading || roles?.length > 0) ? (
            <Paper
              sx={{
                overflowX: "auto",
              }}
            >
              <TableContainer
                sx={{
                  maxHeight: "calc(75vh)",
                  height: "calc(75vh - 40px)",
                }}
              >
                <Table
                  style={roles?.length == 0 ? { height: "100%" } : {}}
                  sx={{
                    width: "100%",
                    tableLayout: "fixed",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <SortableTableHeader
                        isActiveSort
                        label={t("name")}
                        onClick={() =>
                          setSortDir((prev) =>
                            prev === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
                          )
                        }
                        sortDir={sortDir}
                      />
                      <TableCell sx={classes.headerCell}>{t("description")}</TableCell>
                      <TableCell sx={classes.headerCell}> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={roles?.length == 0 ? { height: "100%" } : {}}>
                    {!error &&
                      !loading &&
                      roles?.map((role: Role) => {
                        return (
                          <TableRow
                            key={role.id}
                            onClick={() => handleRowClick(role.id)}
                            sx={classes.dataRow}
                          >
                            <TableCell>
                              {role.isStandard ? t(`standardRoles.${role.name}.name`) : role.name}
                            </TableCell>
                            <TableCell>
                              {role.isStandard
                                ? t(`standardRoles.${role.name}.description`)
                                : role.description}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "right",
                              }}
                            >
                              {!role.isStandard && (
                                <IconButton aria-label={t("editRole") as string}>
                                  <EditIcon />
                                </IconButton>
                              )}
                              {role.isStandard ? (
                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    style={{
                                      border: "1px solid #F8F1CA",
                                      color: "#707070",
                                      backgroundColor: "#FEFDEE",
                                      borderRadius: "4px",
                                      padding: "0.5rem",
                                      textAlign: "center",
                                    }}
                                  >
                                    {t("standardRoles.label")}
                                  </Box>
                                  <IconButton aria-label={t("viewRole") as string}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Box>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    {loading
                      ? [...Array(10).keys()].map((i: number) => (
                          <TableRow key={i} sx={classes.dataRow}>
                            {[...Array(NUM_COLUMNS).keys()].map((j: number) => (
                              <TableCell key={j}>
                                <Skeleton variant="text" />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : null}
                    {error ? (
                      <TableRow>
                        <TableCell align="center" colSpan={NUM_COLUMNS}>
                          <Box
                            style={{
                              color: "rgb(244, 67, 54)",
                              fontWeight: 500,
                              fontSize: "1.25rem",
                              lineHeight: 1.6,
                            }}
                          >
                            {t("errorLabel")}
                            {": "}
                            {error.message}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : null}
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  headerCell: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
    "& svg": {
      fontSize: "0.8rem",
    },
  },
  dataRow: {
    height: "3.75rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#F8F8F8",
    },
  },
}

export default RolesListPage
