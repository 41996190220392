import React, { useEffect, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import Checkbox from "@mui/material/Checkbox"
import Chip from "@mui/material/Chip"
import ListItemText from "@mui/material/ListItemText"
import Dialog from "@mui/material/Dialog"
import MenuItem from "@mui/material/MenuItem"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { formatPersonName } from "../../../../util"
import { useAuth } from "../../../../context/AuthContext"
import SelectorField from "../../../../components/SelectorField"
import MultiSelect from "../../../../components/MultiSelect"
import FieldHelperText from "../../../../components/FieldHelperText"
import { JobWorkflow, JobWorkflowStep } from "../../../../types"
import SaveButton from "../../../../components/SaveButton"

const ALL_USERS = gql`
  query AllUsers(
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $includeChildOrganizationUsers: Boolean
    $includeArchived: Boolean
  ) {
    allUsers(
      input: { sortBy: $sortBy, sortDir: $sortDir, first: $first }
      userFilter: {
        includeChildOrganizationUsers: $includeChildOrganizationUsers
        includeArchived: $includeArchived
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`

const ALL_WORKFLOW_STEP_EVENTS = gql`
  query AllJobWorkflowStepEvents {
    allJobWorkflowStepEvents {
      id
      displayKey
      name
      description
      isArchived
      createdAt
      updatedAt
    }
  }
`

interface JobWorkflowStepDialogFormInput {
  responsibleUserIds: string[]
  responsibleRoleId: string[]
  eventIds: string[]
  name: string
  description?: string
}

interface Props {
  readonly loading?: boolean
  readonly onCancel: () => void
  readonly onClose: () => void
  readonly onSave: (value: JobWorkflowStepDialogFormInput) => void
  readonly open?: boolean
  readonly workflow: JobWorkflow
  readonly step?: JobWorkflowStep
}

function JobWorkflowStepDialog({
  loading = false,
  onCancel,
  onClose,
  onSave,
  open = false,
  workflow,
  step,
}: Props) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [isInitialStep, setIsInitialStep] = useState(() =>
    step?.isInitialStep || !workflow.steps || workflow.steps.length === 0 ? "yes" : "no"
  )
  const [status, setStatus] = useState(() => step?.jobStatus)
  const [responsibleUsers, setResponsibleUsers] = useState(() => step?.responsibleUsers || [])
  const [events, setEvents] = useState(() => step?.events || [])
  const [destinationSteps, setDestinationSteps] = useState(
    () => step?.destinationTransitions?.map((t) => ({ ...t.destination })) || []
  )
  const [allowedDestinationSteps, setAllowedDestinationSteps] = useState<JobWorkflowStep[]>([])
  const [errors, setErrors] = useState(() => ({
    status: null,
    events: null,
  }))

  useEffect(() => {
    if (status) {
      if (status.id !== step?.jobStatus?.id) {
        setDestinationSteps([])
      }

      if (user?.organization?.level && user.organization.level <= 1) {
        const allowedDestinations = workflow.steps
          ? workflow.steps.filter((s) => s.id !== step?.id)
          : []
        setAllowedDestinationSteps(allowedDestinations)
      } else {
        const parentStatus = status.parentStatus

        // find the parentWorkflow step for the parentStatus
        const parentWorkflowStep = workflow.parentWorkflow.steps.find(
          (s) => s.jobStatus.id === parentStatus.id
        )

        // Now we have to find the child statuses that inherit from the parent
        // workflow step's destinationTransition statuses

        const destinationParentStatusIds = parentWorkflowStep.destinationTransitions
          .map((dt) => dt.destination)
          .map((d) => d.jobStatus.id)

        const allowedChildStatusDestinationIds = workflow.availableStatuses
          .filter((a) => destinationParentStatusIds.includes(a.parentStatus.id))
          ?.map((s) => s.id)

        // ... but we also want to include any steps in this workflow whose
        // statuses are "sibling" statuses (that is, they extend the same parent
        // status as this step's status)
        const destinations = workflow.steps.filter((s) => {
          return (
            s.id !== step?.id &&
            (allowedChildStatusDestinationIds.includes(s.jobStatus.id) ||
              (s.jobStatus.parentStatus.id === status.parentStatus.id &&
                s.jobStatus.id !== status.id))
          )
        })

        setAllowedDestinationSteps(destinations)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const { data: allUsersData } = useQuery(ALL_USERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      first: 1000,
      sortBy: "lastName",
      sortDir: "ASC",
      includeChildOrganizationUsers: false,
      includeArchived: false,
    },
  })

  const { data: allWorkflowStepEventsData } = useQuery(ALL_WORKFLOW_STEP_EVENTS, {
    fetchPolicy: "cache-and-network",
  })

  const statusOptions = workflow.availableStatuses?.filter((s) => {
    const usedStatuses = workflow.steps?.map((step) => step.jobStatus.id) || []
    return !usedStatuses.includes(s.id) || s.id === status?.id
  })

  const allUsers =
    allUsersData?.allUsers?.edges
      ?.map((e) => e.node)
      ?.map((n) => ({ id: n.id, name: formatPersonName(n), email: n.email })) || []

  const EVENT_OPTIONS =
    allWorkflowStepEventsData?.allJobWorkflowStepEvents?.map((e) => ({
      id: e.id,
      name: t(`WORKFLOW_STEP_EVENT_OPTIONS.${e.displayKey}`),
    })) || []

  const handleBlurStatus = () => {
    if (!status) {
      setErrors((prev) => ({
        ...prev,
        status: t("component.jobWorkflowStepDialog.validation.status.required"),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        status: null,
      }))
    }
  }

  const handleChangeStatus = (value) => {
    setStatus(value)
    setErrors((prev) => ({
      ...prev,
      status: null,
    }))
  }

  return (
    <Dialog
      aria-labelledby="edit-step-dialog-title"
      data-testid="StepDialog"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <DialogTitle
        id="edit-step-dialog-title"
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {step?.id ? t("editWorkflowStep") : t("addWorkflowStep")}
      </DialogTitle>
      <DialogContent>
        <Box sx={classes.dialogContent}>
          <Box sx={{ marginTop: "0.625rem", marginBottom: "1rem" }}>
            <FormControl component="fieldset" disabled={workflow.isPublished}>
              <FormLabel
                component="legend"
                sx={(theme) => ({
                  color: theme.fielderColors.black,
                  "&.Mui-focused": {
                    color: theme.fielderColors.black,
                  },
                })}
              >
                {t("component.jobWorkflowStepDialog.initialStepLabel")}
              </FormLabel>
              <RadioGroup
                aria-label="isInitialStep"
                name="isInitialStep"
                onChange={(event) => setIsInitialStep(event.target.value)}
                row
                sx={{
                  "& > .Mui-checked": {
                    color: (theme) => theme.fielderColors.black,
                  },
                }}
                value={isInitialStep}
              >
                <FormControlLabel
                  control={<Radio />}
                  disabled={workflow.isPublished}
                  label={t("yes") as string}
                  value="yes"
                />
                <FormControlLabel
                  control={<Radio />}
                  disabled={workflow.isPublished}
                  label={t("no") as string}
                  value="no"
                />
              </RadioGroup>
            </FormControl>
            <FieldHelperText
              message={t("component.jobWorkflowStepDialog.helperText.isInitialStep")}
              style={{ marginTop: 0 }}
            />
          </Box>
          <Box sx={classes.withHelpMessage}>
            <SelectorField
              disabled={Boolean(workflow.isPublished && workflow.organization?.level === 1)}
              error={!!errors.status}
              label={t("status")}
              name="status"
              onBlur={handleBlurStatus}
              onChange={handleChangeStatus}
              options={statusOptions}
              renderOption={(o) => (
                <MenuItem
                  key={o.id}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  value={o.id}
                >
                  <span>{o.name}</span>
                  <Box
                    sx={[
                      classes.colorBox,
                      {
                        backgroundColor: o.mediumColor,
                      },
                    ]}
                  />
                </MenuItem>
              )}
              renderValue={(value) => {
                const selected = statusOptions?.find((o) => o.id === value)
                return (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>{selected?.name}</Box>
                    {selected?.mediumColor ? (
                      <Box
                        sx={[
                          classes.colorBox,
                          {
                            marginTop: "-1rem",
                            backgroundColor: selected.mediumColor,
                          },
                        ]}
                      />
                    ) : null}
                  </Box>
                )
              }}
              required
              value={status?.id}
              variant="filled"
            />
            {!errors.status ? (
              <FieldHelperText message={t("component.jobWorkflowStepDialog.helperText.status")} />
            ) : (
              <FieldHelperText error message={errors.status} />
            )}
          </Box>
          <Box sx={classes.withHelpMessage}>
            <MultiSelect
              id="responsibleUsers"
              label={t("staffResponsible")}
              name="responsibleUsers"
              onChange={(selection) => setResponsibleUsers(selection)}
              options={allUsers}
              renderValue={(selectedUserIds) => {
                const users = allUsers.filter((u) => selectedUserIds.includes(u.id))
                return (
                  <Box sx={classes.chips}>
                    {users.map((u) => {
                      return <Chip key={u.id} label={u.name} sx={classes.chip} />
                    })}
                  </Box>
                )
              }}
              selectedValues={responsibleUsers}
            />
            {!errors.responsibleUsers ? (
              <FieldHelperText
                message={t("component.jobWorkflowStepDialog.helperText.responsibleUsers")}
              />
            ) : (
              <FieldHelperText error message={errors.responsibleUsers} />
            )}
          </Box>
          <Box sx={classes.withHelpMessage}>
            <MultiSelect
              id="events"
              label={t("events") as string}
              name="events"
              onChange={(selection) => setEvents(selection)}
              options={EVENT_OPTIONS}
              selectedValues={events}
            />
            {!errors.events ? (
              <FieldHelperText message={t("component.jobWorkflowStepDialog.helperText.events")} />
            ) : (
              <FieldHelperText error message={errors.events} />
            )}
          </Box>
          <Box>
            <MultiSelect
              disabled={
                (workflow.isPublished && workflow.organization.level === 1) ||
                !status ||
                !allowedDestinationSteps ||
                allowedDestinationSteps.length === 0
              }
              id="destinationSteps"
              label={t("transitionsTo") as string}
              name="destinationSteps"
              onChange={(selection) => setDestinationSteps(selection)}
              options={allowedDestinationSteps}
              renderOptions={(options) => {
                return options.map((v) => (
                  <MenuItem key={v.id} value={v.id}>
                    <Checkbox checked={destinationSteps.map((s) => s.id).indexOf(v.id) > -1} />
                    <ListItemText primary={v.jobStatus.name} />
                  </MenuItem>
                ))
              }}
              renderValue={(selectedValues) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedValues.map((value) => {
                      const option = destinationSteps.find((o) => o.id === value)
                      return (
                        <Chip
                          key={value}
                          label={option.jobStatus.name}
                          sx={{
                            margin: "0.125rem",
                          }}
                        />
                      )
                    })}
                  </Box>
                )
              }}
              selectedValues={destinationSteps}
            />
            {!errors.destinationSteps ? (
              <FieldHelperText
                message={t("component.jobWorkflowStepDialog.helperText.destinationTransitions")}
              />
            ) : (
              <FieldHelperText error message={errors.destinationSteps} />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={classes.dialogActions}>
        <Button
          disabled={loading}
          onClick={onCancel}
          sx={{ border: "1px solid #ccc", minWidth: "80px" }}
        >
          {t("cancel")}
        </Button>
        <SaveButton
          disabled={loading || !status}
          loading={loading}
          onClick={() => {
            onSave?.({
              id: step?.id,
              workflowId: workflow.id,
              jobStatusId: status.id,
              responsibleUserIds: responsibleUsers.map((s) => s.id),
              responsibleRoleId: null,
              eventIds: events.map((e) => e.id),
              destinationStepIds: destinationSteps.map((d) => d.id),
              isInitialStep: isInitialStep === "yes",
            })
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

const classes = {
  dialogContent: {
    backgroundColor: "#FFFFFF",
    paddingBottom: 0,
    paddingTop: "1rem",
  },
  dialogActions: {
    px: "1.5rem",
    paddingBottom: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  withHelpMessage: {
    marginBottom: "0.75rem",
  },
  colorBox: {
    width: "1.875rem",
    height: "1.875rem",
    borderRadius: "6px",
  },
} as const

export default JobWorkflowStepDialog
