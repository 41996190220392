import React from "react"
import logoImg from "../images/header_logo.png"

interface HeaderLogoProps {
  height?: number | string
  width?: number | string
}

function HeaderLogo({ height = "auto", width = "auto" }: HeaderLogoProps): JSX.Element {
  return <img height={height} src={logoImg} width={width} />
}

export default HeaderLogo
