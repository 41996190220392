import { gql } from "@apollo/client"

/**
 * This query is intended be used to get options
 * for the TaxRateGroupSelect component
 */

export const ALL_TAX_RATE_GROUP_OPTIONS = gql`
  query AllTaxRateGroups {
    allTaxRateGroups {
      edges {
        node {
          id
          name
          isLocked
          isArchived
          totalTaxRate
          taxRates {
            id
            name
            rate
          }
        }
      }
    }
  }
`
