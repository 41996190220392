import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation, ApolloError } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import Seo from "../../../components/Seo"
import SnackbarMessage from "../../../components/SnackbarMessage"
import SectionHeader from "../../../components/SectionHeader"
import SectionContent from "../../../components/SectionContent"
import JobCategoryDialog from "./components/JobCategoryDialog"
import { ALL_JOB_CATEGORIES } from "../../../queries/allJobCategories"
import { CREATE_JOB_CATEGORY } from "../../../queries/createJobCategory"
import { EDIT_JOB_CATEGORY } from "../../../queries/editJobCategory"
import { ARCHIVE_JOB_CATEGORY } from "../../../queries/archiveJobCategory"
import { parseGraphQLErrorCode, SETTINGS } from "../../../util"
import { useAuth } from "../../../context/AuthContext"
import { DefaultPermission, JobCategory, Snack } from "../../../types"

function JobCategoryOptions() {
  const { t } = useTranslation()
  const [snack, setSnack] = useState<Snack>()
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [selectedCategory, setSelectedCategory] = useState<JobCategory>()
  const [isConfirmArchiveDialogOpen, setIsConfirmArchiveDialogOpen] = useState<boolean>(false)
  const { hasPermissions } = useAuth()

  const {
    loading: queryLoading,
    data,
    refetch,
  } = useQuery(ALL_JOB_CATEGORIES, {
    fetchPolicy: "cache-and-network",
  })

  const mutationOptions = {
    onCompleted: () => {
      setInEditMode(false)
      setIsConfirmArchiveDialogOpen(false)
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      refetch()
    },
    onError: (error: ApolloError) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  }
  const [editJobCategory, { loading: editJobCategoryLoading }] = useMutation(
    EDIT_JOB_CATEGORY,
    mutationOptions
  )
  const [createJobCategory, { loading: createJobCategoryLoading }] = useMutation(
    CREATE_JOB_CATEGORY,
    mutationOptions
  )
  const [archiveJobCategory, { loading: archiveJobCategoryLoading }] = useMutation(
    ARCHIVE_JOB_CATEGORY,
    mutationOptions
  )

  const jobCategories = data?.allJobCategories?.edges?.map((e: any) => e.node) || []

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="jobCategoryOptions"
          />
          <Paper sx={classes.widgetContainer}>
            <SectionHeader>
              <label>{t("jobCategoryOptions")}</label>
              {hasPermissions?.([DefaultPermission.CreateJobCategory]) ? (
                <Button
                  aria-label={t("addCategory")}
                  color="primary"
                  data-testid="createJobCategoryButton"
                  onClick={() => {
                    setInEditMode(true)
                    setSelectedCategory(undefined)
                  }}
                  sx={classes.primaryActionButton}
                  variant="contained"
                >
                  <AddIcon />
                  <Box>{t("addCategory")}</Box>
                </Button>
              ) : null}
            </SectionHeader>
            <Divider />
            <SectionContent sx={{ maxHeight: 600, overflowY: "scroll" }}>
              {queryLoading ? (
                <Box sx={classes.spinnerContainer}>
                  <CircularProgress color="secondary" size={20} thickness={6.0} />
                </Box>
              ) : null}
              {!queryLoading && jobCategories.length === 0 && (
                <Box sx={classes.categoryRow}>
                  <Box
                    sx={{
                      color: (theme) => theme.fielderColors.mutedText,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      fontSize: "1.125rem",
                      weight: "bold",
                      padding: "2.5rem",
                    }}
                  >
                    {t("page.settings.jobCategories.zeroState")}
                  </Box>
                </Box>
              )}
              {!queryLoading &&
                jobCategories?.map((category: JobCategory) => {
                  return (
                    <Box key={category.id} sx={classes.categoryRow}>
                      <Box>{category.name}</Box>
                      <Box sx={classes.rowButtonContainer}>
                        {hasPermissions?.([DefaultPermission.UpdateJobCategory]) ? (
                          <IconButton
                            aria-label="edit"
                            data-testid="editJobCategoryBtn"
                            onClick={() => {
                              setSelectedCategory(category)
                              setInEditMode(true)
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        ) : null}
                        {hasPermissions?.([DefaultPermission.UpdateJobCategory]) ? (
                          <IconButton
                            aria-label="archive"
                            data-testid="archiveJobCategoryBtn"
                            onClick={() => {
                              setSelectedCategory(category)
                              setIsConfirmArchiveDialogOpen(true)
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                    </Box>
                  )
                })}
            </SectionContent>
          </Paper>
        </Box>
      </MainLayout>
      {inEditMode ? (
        <JobCategoryDialog
          category={selectedCategory}
          loading={createJobCategoryLoading || editJobCategoryLoading}
          onCancel={() => {
            setInEditMode(false)
            setSelectedCategory(undefined)
          }}
          onSave={(name) => {
            selectedCategory?.id
              ? editJobCategory({
                  variables: {
                    id: selectedCategory.id,
                    isArchived: selectedCategory.isArchived,
                    name,
                  },
                })
              : createJobCategory({
                  variables: {
                    isArchived: false,
                    name,
                  },
                })
          }}
          open={inEditMode}
        />
      ) : null}
      {isConfirmArchiveDialogOpen && selectedCategory ? (
        <Dialog
          aria-describedby="archive-category-dialog-description"
          aria-labelledby="archive-category-dialog-title"
          onClose={() => setIsConfirmArchiveDialogOpen(false)}
          open={isConfirmArchiveDialogOpen}
        >
          <DialogTitle id="archive-category-dialog-title">
            {t("page.jobCategoryOptions.archiveCategoryConfirmationPromptTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-contact-dialog-description">
              {t("page.jobCategoryOptions.archiveCategoryConfirmationPromptMsg", {
                name: selectedCategory.name,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={archiveJobCategoryLoading}
              onClick={() => setIsConfirmArchiveDialogOpen(false)}
            >
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={archiveJobCategoryLoading}
              onClick={() => {
                archiveJobCategory({
                  variables: {
                    id: selectedCategory.id,
                  },
                })
              }}
            >
              {archiveJobCategoryLoading ? (
                <CircularProgress color="secondary" size={20} thickness={6.0} />
              ) : (
                <Box>{t("yes")}</Box>
              )}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
  },
  spinnerContainer: {
    padding: "6.25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  linkButton: {
    textDecoration: "none",
  },
  primaryActionButton: {
    fontWeight: "bold",
    "& svg": {
      fontSize: "1.0rem",
    },
    "& div": {
      marginLeft: "0.625rem",
      marginRight: "0.625rem",
    },
  },
  widgetContainer: {
    maxWidth: "700px",
    marginBottom: "1.25rem",
    marginTop: "3.75rem",
  },
  categoryRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ddd",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    "&:first-of-type": {},
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  rowButtonContainer: {
    display: "flex",
  },
} as const

export default JobCategoryOptions
