import React from "react"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import ButtonBase from "@mui/material/ButtonBase"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { useTranslation } from "react-i18next"

interface PluginCardProps {
  logo: string // the relative URL path to the logo
  title: string
  description: string
  enabled: boolean
  onClick: () => void
  testID?: string
}

function PluginCard({ logo, title, description, enabled, onClick, testID }: PluginCardProps) {
  const { t } = useTranslation()

  return (
    <ButtonBase data-testid={testID} focusRipple onClick={onClick}>
      <Paper sx={classes.paper}>
        {enabled ? (
          <Box sx={classes.enabledIndicator}>
            <CheckCircleIcon />
          </Box>
        ) : null}
        <Box sx={classes.container}>
          <Box sx={classes.logoAndTextContainer}>
            <img aria-label={`Logo for ${title}`} css={classes.logo} src={logo} />
            <Box sx={classes.title}>{title}</Box>
            <Box sx={classes.description}>{description}</Box>
          </Box>
          {!enabled ? (
            <Box sx={classes.callToAction}>{t("getStarted")}</Box>
          ) : (
            <Box sx={[classes.callToAction, classes.changeSettingsButton]}>
              {t("changeSettings")}
            </Box>
          )}
        </Box>
      </Paper>
    </ButtonBase>
  )
}

const enabledColor = "#0A8B0A"
const classes = {
  paper: {
    height: "18.75rem",
    width: "16.25rem",
    border: "1px solid #d1d1d1",
    color: "#707070",
    padding: "1.25rem",
    paddingTop: "1.5625rem",
    paddingBottom: "1.5625rem",
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  logoAndTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    width: "4.0625rem",
    height: "4.0625rem",
    marginBottom: "1.25rem",
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "0.9375rem",
    marginBottom: "1.25rem",
  },
  description: {
    textAlign: "left",
    fontWeight: "regular",
    fontSize: "0.75rem",
  },
  callToAction: {
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    paddingRight: "1.25rem",
    paddingLeft: "1.25rem",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "0.875rem",
    color: "#212121",
    textTransform: "uppercase",
  },
  changeSettingsButton: {
    color: enabledColor,
    border: `1px solid ${enabledColor}`,
    borderRadius: "4px",
  },
  enabledIndicator: {
    position: "absolute",
    right: "0.625rem",
    top: "0.625rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& svg": {
      color: enabledColor,
    },
    "& div": {
      fontWeight: "bold",
      fontSize: "0.5rem",
    },
  },
}

export default PluginCard
