import gql from "graphql-tag"

export const GET_LEVEL1_ORGANIZATION_DASHBOARD_METRICS = gql`
  query GetLevel1OrgMetrics($id: ID!, $start: LocalDateTime!, $end: LocalDateTime!) {
    getOrganizationById(id: $id) {
      id
      productOrderCounts(start:$start, end:$end) {
        status
        count
      }
      itemOrderCounts(top: 5, start: $start, end: $end) {
        item {
          id
          code
          name
          description
          region {
            id
            code
            name
          }
        }
        count
      }
      salesTotalCounts(start:$start, end:$end, top: 5) {
        organization {
          id
          tradeName
          legalName
          email
          currencyCode
        }
        total
      }
    }
  }
`
