/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-literals */
import React, { useState } from "react"
import Cookies from "js-cookie"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import CircularProgress from "@mui/material/CircularProgress"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import Seo from "../../../components/Seo"
import { PLUGINS, SETTINGS } from "../../../util"
import { useAuth } from "../../../context/AuthContext"
import { DefaultPermission } from "../../../types"

function PluginDetails() {
  const { t } = useTranslation()
  const { id } = useParams()
  const { hasPermissions } = useAuth()
  const [loading, setLoading] = useState(false)

  const pluginData = PLUGINS.find((p) => p.id.toLowerCase() === id?.toLowerCase())

  const connect = async () => {
    if (!pluginData) {
      Sentry.captureMessage(`invalid plugin - could not find data for plugin with id: ${id}`)
    } else if (hasPermissions?.([DefaultPermission.UpdateOrganizationPlugin])) {
      setLoading(true)
      const response = await fetch(`${process.env.GATSBY_API_SERVER_URL}${pluginData.connectUri}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
        },
      })

      const oAuthUrl = await response.text()
      typeof window !== "undefined" && window.location.assign(oAuthUrl)
    }
  }

  if (!pluginData) {
    return <Box>Error - cannot find plugin data</Box>
  }

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/plugins", titleKey: t("plugins") },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey={pluginData.name}
          />
          <Paper data-testid="contentRoot" sx={classes.contentRoot}>
            <Box sx={classes.logoTitleContainer}>
              <img
                aria-label={`Logo for ${pluginData.name}`}
                css={classes.logo}
                src={pluginData.logoPath}
              />
              <Box sx={classes.title}>{pluginData.name}</Box>
            </Box>
            <p>{t(pluginData.longDescriptionKey)}</p>
            <Box>
              {hasPermissions?.([DefaultPermission.UpdateOrganizationPlugin]) ? (
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={connect}
                  sx={classes.connectButton}
                  variant="contained"
                >
                  {loading ? (
                    <CircularProgress color="secondary" size={18} />
                  ) : (
                    t(pluginData.connectButtonLabelKey)
                  )}
                </Button>
              ) : null}
            </Box>
          </Paper>
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
  },
  contentRoot: {
    maxWidth: "800px",
    padding: "2.5rem",
    display: "flex",
    flexDirection: "column",
    color: "#747474",
  },
  logoTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "1.25rem",
  },
  logo: {
    width: "5rem",
    height: "5rem",
  },
  title: {
    color: "#747474",
    fontWeight: "bold",
    fontSize: "1.25rem",
    marginLeft: "1.25rem",
  },
  connectButton: {
    fontWeight: "bold",
    marginTop: "1.25rem",
    minWidth: "150px",
  },
}

export default PluginDetails
