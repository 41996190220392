import React, { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import numeral from "numeral"
import { TFunction, useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AddIcon from "@mui/icons-material/AddOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined"
import Skeleton from "@mui/material/Skeleton"
import MainLayout from "../../components/MainLayout"
import PageHeader from "../../components/PageHeader"
import NoResultsRow from "../../components/NoResultsRow"
import SearchField from "../../components/SearchField"
import Seo from "../../components/Seo"
import SnackbarMessage from "../../components/SnackbarMessage"
import SortableTableHeader from "../../components/SortableTableHeader"
import { ALL_INVOICES } from "../../queries/allInvoices"
import { capitalize, createDayJS, getJobDocumentDisplayNumber, INVOICES } from "../../util"
import useDebounce from "../../util/useDebounce"
import { useAuth } from "../../context/AuthContext"
import { DefaultPermission, Job } from "../../types"

const handleRowClick = (id: string) => {
  console.log("looks like you want to view invoice ", id)
}

function formatJobInfo(job: Job, t: TFunction<"translation", undefined>) {
  const jobNumber = `${t("job")}: ${job.number}`
  return (
    <Box>
      <Box>{jobNumber}</Box>
      {job.customer?.name ? <Box>{job.customer.name}</Box> : null}
    </Box>
  )
}

const NUM_COLUMNS = 7

function InvoiceList() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { hasPermissions } = useAuth()
  const [filter, setFilter] = useState<string>("")
  const [sortBy, setSortBy] = useState<string>("name")
  const [sortDir, setSortDir] = useState<"DESC" | "ASC">("ASC")
  const [first] = useState<number | null>(20)
  const debouncedSearchTerm = useDebounce(filter, 500)

  const { loading, error, data, fetchMore } = useQuery(ALL_INVOICES, {
    variables: {
      filter: debouncedSearchTerm,
      first,
      sortBy,
      sortDir,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: "ALL_INVOICES",
      debounceTimeout: 50,
    },
  })

  function sort(propertyName: string) {
    setSortBy(propertyName)
    setSortDir(sortDir == "ASC" ? "DESC" : "ASC")
  }

  const snack = location?.state?.snack
  const pageInfo = data?.allInvoices?.pageInfo || {}
  const invoices = data?.allInvoices?.edges

  return (
    <>
      <Seo title={t("sectionTitle.invoices")} />
      {snack ? <SnackbarMessage snack={snack} /> : null}
      <MainLayout activeSection={INVOICES}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader icon={INVOICES.icon} leafTitleKey={INVOICES.titleKey} />
          <Box sx={classes.tableHeaderControls}>
            <SearchField
              onChange={setFilter}
              placeholder={t("searchInvoices")}
              term={filter}
              testID="SearchField"
            />
            {hasPermissions?.([DefaultPermission.CreateInvoice]) ? (
              <Button
                aria-label={t("createInvoice") as string}
                color="primary"
                data-testid="createInvoiceButton"
                onClick={() => {
                  navigate(`/app/invoice/create`)
                }}
                sx={classes.primaryActionButton}
                variant="contained"
              >
                <AddIcon />
                <Box>{t("createInvoice")}</Box>
              </Button>
            ) : null}
          </Box>
          <Paper sx={classes.tableWrapper}>
            <Table sx={[invoices?.length == 0 && { height: "100%" }]}>
              <TableHead>
                <TableRow>
                  <SortableTableHeader
                    isActiveSort={sortBy === "number"}
                    label={t("invoice")}
                    onClick={() => sort("number")}
                    sortDir={sortDir}
                    sx={classes.tableHead}
                  />
                  <SortableTableHeader
                    isActiveSort={sortBy === "job.number"}
                    label={t("job")}
                    onClick={() => sort("job.number")}
                    sortDir={sortDir}
                    sx={classes.tableHead}
                  />
                  <SortableTableHeader
                    isActiveSort={sortBy === "status"}
                    label={t("status")}
                    onClick={() => sort("status")}
                    sortDir={sortDir}
                    sx={classes.tableHead}
                  />
                  <SortableTableHeader
                    isActiveSort={sortBy === "dueDate"}
                    label={t("dueDate")}
                    onClick={() => sort("dueDate")}
                    sortDir={sortDir}
                    sx={classes.tableHead}
                  />
                  <SortableTableHeader
                    align="right"
                    isActiveSort={sortBy === "total"}
                    label={t("total")}
                    onClick={() => sort("total")}
                    sortDir={sortDir}
                    sx={classes.tableHead}
                  />
                  <SortableTableHeader
                    align="right"
                    isActiveSort={sortBy === "balance"}
                    label={t("balance")}
                    onClick={() => sort("balance")}
                    sortDir={sortDir}
                    sx={classes.tableHead}
                  />
                  <TableCell sx={classes.tableHead}> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!error && !loading && invoices?.length == 0 && (
                  <NoResultsRow colSpan={NUM_COLUMNS} />
                )}
                {!error &&
                  invoices?.map((edge) => {
                    const { node } = edge
                    const dueDate = node.dueDate ? createDayJS(node.dueDate) : null
                    return (
                      <TableRow
                        key={node.id}
                        onClick={() => handleRowClick(node.id)}
                        sx={classes.dataRow}
                      >
                        <TableCell>{getJobDocumentDisplayNumber(node)}</TableCell>
                        <TableCell>{formatJobInfo(node.job, t)}</TableCell>
                        <TableCell>{capitalize(node.status)}</TableCell>
                        <TableCell>
                          {dueDate ? dueDate.format(t("format:dateFormat.long") as string) : null}
                        </TableCell>
                        <TableCell align="right">
                          {numeral(node.total).format(t("format:currency.long") as string)}
                        </TableCell>
                        <TableCell align="right">
                          {numeral(node.balanceDue).format(t("format:currency.long") as string)}
                        </TableCell>
                        <TableCell align="right">
                          {hasPermissions?.([DefaultPermission.ReadInvoice]) ? (
                            <Button
                              aria-label={t("viewInvoice") as string}
                              style={{ marginRight: "0.3125rem" }}
                              variant="outlined"
                            >
                              <VisibilityIcon />
                            </Button>
                          ) : null}
                          {hasPermissions?.([
                            DefaultPermission.ReadInvoice,
                            DefaultPermission.UpdateInvoice,
                          ]) ? (
                            <Button
                              aria-label={
                                t("editInvoice", {
                                  number: getJobDocumentDisplayNumber(node),
                                }) as string
                              }
                              variant="outlined"
                            >
                              <EditIcon />
                            </Button>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {loading
                  ? [...Array(first).keys()].map((i) => (
                      <TableRow key={i}>
                        {[...Array(NUM_COLUMNS).keys()].map((j) => (
                          <TableCell key={j}>
                            <Skeleton variant="text" />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  : null}
                {!error && !loading && pageInfo.hasNextPage ? (
                  <tr>
                    <td colSpan={NUM_COLUMNS} style={{ textAlign: "center", padding: 20 }}>
                      <Button
                        onClick={() => {
                          const cursor =
                            data.allInvoices.edges[data.allInvoices.edges.length - 1].cursor
                          return fetchMore({
                            variables: {
                              filter,
                              sortBy,
                              sortDir,
                              first,
                              after: cursor,
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                              const newEdges = fetchMoreResult.allInvoices.edges

                              return newEdges.length
                                ? {
                                    // Put the new InventoryItems at the end of the list and update `pageInfo`
                                    // so we have the new `endCursor` and `hasNextPage` values
                                    allInvoices: {
                                      __typename: previousResult.allInvoices.__typename,
                                      edges: [...previousResult.allInvoices.edges, ...newEdges],
                                      pageInfo: fetchMoreResult.allInvoices.pageInfo,
                                    },
                                  }
                                : previousResult
                            },
                          })
                        }}
                      >
                        {t("loadMore")}
                      </Button>
                    </td>
                  </tr>
                ) : null}
                {error ? (
                  <TableRow>
                    <TableCell align="center" colSpan={NUM_COLUMNS}>
                      <Box
                        style={{
                          color: "rgb(244, 67, 54)",
                          fontWeight: 500,
                          fontSize: "1.25rem",
                          lineHeight: 1.6,
                        }}
                      >
                        {t("errorLabel")}
                        {": "}
                        {error.message}
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  tableWrapper: {
    overflowX: "auto",
    marginBottom: "1.25rem",
    maxHeight: "calc(100vh - 220px)",
    height: "600px",
  },
  tableHeaderControls: {
    marginBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
  },
  dataRow: {
    height: "3.75rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f8f8f8",
    },
  },
  primaryActionButton: {
    fontWeight: "bold",
    "& svg": {
      fontSize: "1.0rem",
    },
    "& div": {
      marginLeft: "0.625rem",
      marginRight: "0.625rem",
    },
  },
} as const

export default InvoiceList
