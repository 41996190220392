import dayjs, { Dayjs } from "dayjs"
import drop from "lodash/drop"
import pullAt from "lodash/pullAt"
import clone from "lodash/clone"
import {
  INTERVAL_DURATION,
  DAY_MODE_INTERVAL_WIDTH,
  DAY_MODE_ROW_HEIGHT,
  WEEK_MODE_ROW_HEIGHT,
  WEEK_MODE_CELL_WIDTH,
  WEEK_MODE_RESOURCE_COLUMN_WIDTH,
  DAY_MODE_RESOURCE_COLUMN_WIDTH,
  DAY_MODE_HEADER_HEIGHT,
  WEEK_MODE_HEADER_HEIGHT,
} from "./Constants"
import type { JobAssignment, User } from "../../types/apiTypes"
import type { DispatchResource, JobAssignmentFrame, JobAssignmentUserBlock } from "../../types/appTypes"
import { TimeFrameOption } from "../../types"
import { createDayJS } from "../../util/dateUtils"

// Calculate the size and position of the JobAssignment view
function getAssignmentFrame(
  date: Dayjs,
  assignment: JobAssignment,
  assignee: User,
  resources: DispatchResource[],
  timeZone: string
): JobAssignmentFrame {
  const start = createDayJS(assignment.startDate, timeZone) ?? dayjs()
  const end = createDayJS(assignment.endDate, timeZone) ?? dayjs()
  const startsOnDifferentDayEndsSame = start.isBefore(date, "day") && end.isSame(date, "day")
  const startHour = parseInt(start.format("H"), 10)
  const startMinute = parseInt(start.format("mm"), 10)
  const totalMinutes = startsOnDifferentDayEndsSame
    ? end.get("hour") * 60 + end.get("minute")
    : end.diff(start, "minutes")

  const rowNum = resources.find(r => r.id === assignee.id)?.position ?? 0
  const yPos = rowNum * DAY_MODE_ROW_HEIGHT + DAY_MODE_HEADER_HEIGHT

  const xPos = start.isBefore(date, "day")
    ? DAY_MODE_RESOURCE_COLUMN_WIDTH
    : ((startHour * 60 + startMinute) / INTERVAL_DURATION) * DAY_MODE_INTERVAL_WIDTH +
    DAY_MODE_RESOURCE_COLUMN_WIDTH
  let width = (totalMinutes / INTERVAL_DURATION) * DAY_MODE_INTERVAL_WIDTH
  const maxXPos =
    (1440 / INTERVAL_DURATION) * DAY_MODE_INTERVAL_WIDTH + DAY_MODE_RESOURCE_COLUMN_WIDTH

  if (xPos + width > maxXPos) {
    width = width - (xPos + width - maxXPos)
  }

  return {
    left: xPos,
    top: yPos,
    width,
    minWidth: width,
    maxWidth: width,
    height: DAY_MODE_ROW_HEIGHT - 2,
    minHeight: DAY_MODE_ROW_HEIGHT,
    maxHeight: DAY_MODE_ROW_HEIGHT,
  }
}

//Group assignments that have any overlapping time-of-day.
function groupAssignments(assignments: JobAssignment[], timeZone: string) {
  if (!assignments || assignments.length === 0) {
    return []
  }

  const assignmentGroups = []
  let remaining = clone(assignments)

  while (remaining.length > 0) {
    const group = {
      entries: [remaining[0]],
      start: createDayJS(remaining[0].startDate, timeZone) ?? dayjs(),
      end: createDayJS(remaining[0].endDate, timeZone) ?? dayjs(),
    }
    assignmentGroups.push(group)
    remaining = drop(remaining) //remove the first element from the array

    const removedIndices = [] as number[]
    remaining.forEach((a, idx) => {
      const start = createDayJS(a.startDate, timeZone) ?? dayjs()
      if (start.isBetween(group.start, group.end, null, "[)")) {
        group.entries.push(a)
        const end = createDayJS(a.endDate, timeZone) ?? dayjs()
        if (end.isAfter(group.end)) {
          group.end = createDayJS(a.endDate, timeZone) ?? dayjs()
        }
        removedIndices.push(idx)
      }
    })
    pullAt(remaining, removedIndices)
  }

  return assignmentGroups
}

export function calculateAssignmentFrames(
  date: Dayjs,
  assignments: JobAssignment[],
  resources: DispatchResource[],
  timeFrame: TimeFrameOption,
  timeZone: string
): JobAssignmentUserBlock[] {
  if (timeFrame === TimeFrameOption.WEEK) {
    //Group assignments that have any overlapping time-of-day.
    const assignmentGroups = groupAssignments(assignments, timeZone)

    const blocks = [] as JobAssignmentUserBlock[]

    // Then (assuming WEEKLY timeframe) figure out the xPos & height of each assignment
    // in each group. The width should be 1/Nth the width.
    assignmentGroups.forEach((g) => {
      g.entries.forEach((assignment, index) => {
        const start = createDayJS(assignment.startDate, timeZone) ?? dayjs()
        const startHour = parseInt(start.format("H"), 10)
        const startMinute = parseInt(start.format("mm"), 10)
        const end = createDayJS(assignment.endDate, timeZone) ?? dayjs()
        const isMultiDay = start.isBefore(end, "day")
        const endHour = isMultiDay ? 24 : parseInt(end.format("H"), 10)
        const endMinute = isMultiDay ? 0 : parseInt(end.format("mm"), 10)
        const totalMinutes = (endHour - startHour) * 60 + (endMinute - startMinute)
        const pixelHeightPerMinute = WEEK_MODE_ROW_HEIGHT / 60
        const rowNum = startHour
        const yPos =
          rowNum * WEEK_MODE_ROW_HEIGHT +
          startMinute * pixelHeightPerMinute +
          WEEK_MODE_HEADER_HEIGHT
        const xPos = start.day() * WEEK_MODE_CELL_WIDTH + WEEK_MODE_RESOURCE_COLUMN_WIDTH
        const itemWidth = WEEK_MODE_CELL_WIDTH / g.entries.length
        const height = totalMinutes * pixelHeightPerMinute

        blocks.push({
          key: `assignment:${assignment.id}`,
          assignment,
          assignee: assignment.assignees[0],
          frame: {
            left: xPos + index * itemWidth,
            top: yPos,
            width: itemWidth,
            minWidth: itemWidth,
            maxWidth: itemWidth,
            height,
            minHeight: WEEK_MODE_ROW_HEIGHT,
            maxHeight: WEEK_MODE_ROW_HEIGHT,
          },
        })
      })
    })

    return blocks
  } else if (timeFrame === TimeFrameOption.DAY) {
    const assignmentsPerAssignee = assignments.flatMap((assignment) => {
      return assignment.assignees.map((assignee) => ({
        ...assignment,
        assignee,
      }))
    })

    const blocks = assignmentsPerAssignee.map((userAssignment) => ({
      assignment: userAssignment,
      assignee: userAssignment.assignee,
      frame: getAssignmentFrame(
        date,
        userAssignment,
        userAssignment.assignee,
        resources,
        timeZone
      ),
      key: `assignment:${userAssignment.id}-user:${userAssignment.assignee.id}`,
    }))

    return blocks
  } else {
    return []
  }
}
