import React, { useState } from "react"
import Cookies from "js-cookie"
import { Navigate, NavigateProps } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import TreeView from "@mui/lab/TreeView"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import TreeItem from "@mui/lab/TreeItem"
import Confetti from "react-confetti"
import MainLayout from "../../components/MainLayout"
import PageHeader from "../../components/PageHeader"
import Seo from "../../components/Seo"
import SnackbarMessage from "../../components/SnackbarMessage"
import SectionHeader from "../../components/SectionHeader"
import SectionContent from "../../components/SectionContent"
import FileSelector, { ZIP } from "../../components/FileSelector"
import FielderIconButton from "../../components/FielderIconButton"
import { CATALOG } from "../../util"
import { Snack } from "../../types"

const COLUMNS = [
  "ItemCode",
  "Region",
  "Name",
  "Category",
  "WholesalePrice",
  "WholesalePriceExpirationDate",
  "MSRP",
  "Description",
  "LeadTimeDays",
  "PalletQty",
  "PackSize",
  "IsPackBreakable",
  "ItemType",
  "StockType",
  "IntendedForResale",
  "Cancelable",
  "Archived",
  "ImageFileName",
]

function ImportCatalogItems() {
  const { t } = useTranslation()
  const [snack, setSnack] = useState<Snack>()
  const [file, setFile] = useState<File | null>(null)
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false)
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()

  const uploadFile = async () => {
    if (!file || isFileUploading) {
      return
    }
    setIsFileUploading(true)
    const data = new FormData()
    data.append("file", file)
    return fetch(`${process.env.GATSBY_API_SERVER_URL}/item`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
      body: data,
    })
      .then((response) => {
        if (response.ok) {
          setUploadSuccess?.(true)
        } else {
          console.error("Non successful response received: ", response.status)
          setUploadSuccess(false)
          setSnack({ messageKey: "page.catalogBulkImport.messages.uploadFailed", variant: "error" })
        }
      })
      .catch((err) => {
        console.error("ERROR: ", err)
        setUploadSuccess(false)
        setSnack({ messageKey: "page.catalogBulkImport.messages.uploadFailed", variant: "error" })
      })
      .finally(() => {
        setIsFileUploading(false)
      })
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(CATALOG.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={CATALOG}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: CATALOG.path, titleKey: CATALOG.titleKey }]}
            icon={CATALOG.icon}
            leafTitleKey="bulkImport"
          />
          {uploadSuccess ? <Confetti numberOfPieces={500} recycle={false} /> : null}
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item md={6} sm={12}>
              <Paper sx={{ marginBottom: "10rem" }}>
                <SectionHeader>
                  <label>{t("bulkImport")}</label>
                </SectionHeader>
                <Divider />
                {uploadSuccess ? (
                  <SectionContent>
                    <Box sx={classes.stepHeader}>{t("page.catalogBulkImport.success.title")}</Box>
                    <p>{t("page.catalogBulkImport.success.paragraph1")}</p>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        margin: "2rem",
                      }}
                    >
                      <Button
                        onClick={() => setRedirectTo({ to: "/app/catalog/list", replace: true })}
                        variant="outlined"
                      >
                        {t("page.catalogBulkImport.backToCatalogList")}
                      </Button>
                    </Box>
                  </SectionContent>
                ) : (
                  <SectionContent>
                    <Box>{t("page.catalogBulkImport.intro")}</Box>
                    <Box sx={classes.stepContainer}>
                      <Box sx={classes.stepHeader}>
                        1. {t("page.catalogBulkImport.step1.title")}
                      </Box>
                      <p>
                        <Trans
                          components={{ strong: <strong /> }}
                          i18nKey="page.catalogBulkImport.step1.paragraph1"
                        />
                      </p>
                      <Box css={{ paddingLeft: "2rem" }}>
                        {COLUMNS.map((c) => (
                          <Box key={c}>
                            <strong>{c}</strong>
                          </Box>
                        ))}
                      </Box>
                      <p>
                        <Trans
                          components={{ anchor: <a href="/resources/catalog.xlsx" /> }}
                          i18nKey="page.catalogBulkImport.step1.paragraph2"
                        />
                      </p>
                      <p>
                        <Trans i18nKey="page.catalogBulkImport.step1.paragraph3" />
                      </p>
                      <p>
                        <Trans
                          components={{ list: <ul />, li: <li />, strong: <strong /> }}
                          i18nKey="page.catalogBulkImport.step1.paragraph4"
                        />
                      </p>
                      <p>
                        <Trans
                          components={{ strong: <strong />, i: <em /> }}
                          i18nKey="page.catalogBulkImport.step1.paragraph5"
                        />
                      </p>
                    </Box>
                    <Box sx={classes.stepContainer}>
                      <Box sx={classes.stepHeader}>
                        2. {t("page.catalogBulkImport.step2.title")}
                      </Box>
                      <p>
                        <Trans
                          components={{ strong: <strong /> }}
                          i18nKey="page.catalogBulkImport.step2.paragraph1"
                        />
                      </p>
                      <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        defaultExpanded={["1", "3"]}
                        sx={{ lexGrow: 1, overflowY: "auto" }}
                      >
                        <TreeItem label="item-catalog" nodeId="1">
                          <TreeItem label="catalog.xlsx" nodeId="2" />
                          <TreeItem label="images" nodeId="3">
                            <TreeItem label="primary-product-01.png" nodeId="4" />
                            <TreeItem label="primary-product-02.png" nodeId="5" />
                          </TreeItem>
                        </TreeItem>
                      </TreeView>
                      <p>
                        <Trans
                          components={{ strong: <strong /> }}
                          i18nKey="page.catalogBulkImport.step2.paragraph2"
                        />
                      </p>
                    </Box>
                    <Box sx={classes.stepContainer}>
                      <Box sx={classes.stepHeader}>
                        3. {t("page.catalogBulkImport.step3.title")}
                      </Box>
                      <p>
                        <Trans
                          components={{ strong: <strong /> }}
                          i18nKey="page.catalogBulkImport.step3.paragraph1"
                        />
                      </p>
                      <Box sx={{ marginBottom: "1.5rem" }}>
                        {!file ? (
                          <FileSelector
                            acceptedTypes={[ZIP]}
                            dropZoneLabel={t("page.catalogBulkImport.fileUploadLabel")}
                            onFilesAdded={(files: File[]) => {
                              setFile(files?.[0])
                            }}
                            showFileList={false}
                            showFilePolicyMessage={false}
                            showThumbs={false}
                          />
                        ) : (
                          <Box
                            sx={{
                              border: "1px solid #747474",
                              borderRadius: "4px",
                              padding: "1.3125rem 1rem 1.3125rem 1.25rem",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ fontWeight: "bold" }}>{file.name}</Box>
                            <FielderIconButton
                              aria-label={t("delete")}
                              disabled={isFileUploading}
                              onClick={() => setFile(null)}
                              title={t("delete")}
                            >
                              <DeleteIcon />
                            </FielderIconButton>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        color="primary"
                        data-testid="importButton"
                        disabled={isFileUploading || !file}
                        onClick={uploadFile}
                        sx={{
                          fontWeight: "bold",
                          minWidth: 112,
                        }}
                        variant="contained"
                      >
                        {isFileUploading ? (
                          <CircularProgress color="secondary" size={20} thickness={6.0} />
                        ) : (
                          <span>{t("import")}</span>
                        )}
                      </Button>
                    </Box>
                  </SectionContent>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  stepContainer: {
    marginTop: "1rem",
    "& p": {
      marginLeft: "0.25rem",
    },
  },
  stepHeader: {
    fontWeight: "bold",
  },
} as const

export default ImportCatalogItems
