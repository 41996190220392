import * as React from "react"

const EmptyInbox = (props) => (
  <svg viewBox="0 0 359.68 410.45" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse
      cx={179.84}
      cy={401.29}
      rx={179.84}
      ry={9.16}
      style={{
        fill: "#cdd9dd",
      }}
    />
    <path
      d="M17.01 192.16h317.12v200.23a5.74 5.74 0 0 1-5.74 5.74H22.75a5.74 5.74 0 0 1-5.74-5.74V192.16Z"
      style={{
        fill: "#eceef1",
        stroke: "#231f20",
        strokeMiterlimit: 10,
        strokeWidth: 3,
      }}
    />
    <path
      d="M331.29 395.83c-.26.27-.77.7-1.32.82l-308.05.13c-1.12 0-3.05-1.73-3.39-3.74l.02-2.67c-.02-1.16-.15-1.3 1.21-1.21l311-.02c2.15-.03 1.83.2 1.9 2.05l-.02 1.36c-.13.84-.11 2.03-1.34 3.29Z"
      style={{
        fill: "#bcbec0",
      }}
    />
    <path
      d="m334.16 186.52.06 10.91-148.93 73.13a22.606 22.606 0 0 1-19.91 0L16.99 197.61l-.06-10.97a8.456 8.456 0 0 1 4.93-7.73l21.86-10.04 122.42-56.23a22.566 22.566 0 0 1 18.85 0l122.43 56.23 21.82 10.02a8.455 8.455 0 0 1 4.93 7.64Z"
      style={{
        fill: "#ced9dd",
        stroke: "#231f20",
        strokeMiterlimit: 10,
        strokeWidth: 3,
      }}
    />
    <path
      d="m19.96 195.93 148.68 73.17c4.08 2.04 8.88 2.06 12.98.05l149.6-73.64"
      style={{
        fill: "none",
        stroke: "#bfc5c7",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        strokeWidth: 3,
      }}
    />
    <circle
      cx={127.97}
      cy={327.11}
      r={7.36}
      style={{
        fill: "#405b66",
      }}
    />
    <circle
      cx={234.08}
      cy={327.11}
      r={7.36}
      style={{
        fill: "#405b66",
      }}
    />
    <path
      d="M168.21 347.54c.18 6.93 5.85 12.49 12.82 12.49s12.64-5.56 12.82-12.49h-25.64Z"
      style={{
        fill: "#405b66",
      }}
    />
    <rect
      height={5.89}
      rx={2.59}
      ry={2.59}
      style={{
        fill: "#d1dbdf",
        opacity: 0.92,
      }}
      width={20.05}
      x={230.48}
      y={331.38}
    />
    <rect
      height={5.89}
      rx={2.59}
      ry={2.59}
      style={{
        fill: "#d1dbdf",
        opacity: 0.92,
      }}
      width={20.05}
      x={125.45}
      y={331.11}
    />
    <circle
      cx={320.87}
      cy={6.72}
      r={5.72}
      style={{
        fill: "#fff",
        stroke: "#797c7d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
    <path
      d="M182.74 266.63c2.1-1.68 4.02-3.53 5.77-5.53"
      style={{
        fill: "none",
        stroke: "#797c7d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 3,
      }}
    />
    <path
      d="M196.46 249.52c8.34-15.36 12.41-34.48 20.16-49.89 10.98-21.83 22.13-44.21 25.07-68.47s-3.89-51.17-23.24-66.08c-18.09-13.94-42.83-14.79-65.63-13.56-8.76.47-16.63 4.96-23.2 10.78-7.21 6.4-14.23 14.15-15.53 23.7-2.55 18.72-4.39 48.82 8.17 62.93 8.24 9.25 25.35 18.63 37.6 20.43 21.83 3.22 47.4-6.54 66.2-19.62 11.43-7.95 21.9-17.7 32.7-26.49 19.19-15.62 34.15-26.99 47.39-47.88 10.14-16 16.83-24.7 18.24-38.93"
      style={{
        fill: "none",
        stroke: "#797c7d",
        strokeDasharray: "0 0 16.08 14.07",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 3,
      }}
    />
    <path
      d="M324.66 29.41c-.04-2.47-.21-5.12-.52-7.98"
      style={{
        fill: "none",
        stroke: "#797c7d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 3,
      }}
    />
    <circle
      cx={191.02}
      cy={194.33}
      r={5.72}
      style={{
        fill: "#fff",
        stroke: "#797c7d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
    <circle
      cx={168.4}
      cy={132.34}
      r={5.72}
      style={{
        fill: "#fff",
        stroke: "#797c7d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
    <circle
      cx={308.81}
      cy={149.62}
      r={5.72}
      style={{
        fill: "#fff",
        stroke: "#797c7d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
    <circle
      cx={90.62}
      cy={63.44}
      r={5.72}
      style={{
        fill: "#fff",
        stroke: "#797c7d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
    <path
      d="m197.28 22.6 6.67 6.84M203.86 22.53l-6.93 6.9M89.22 181.52l6.67 6.84M95.8 181.45l-6.93 6.9M272.6 174.86l6.67 6.84M279.18 174.79l-6.94 6.9"
      style={{
        fill: "none",
        stroke: "#797c7d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
  </svg>
)

export default EmptyInbox
