import React from "react"
import { t } from "i18next"
import { formatPhoneNumber } from "react-phone-number-input"
import { Paper, Box, Divider } from "@mui/material"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import PhoneIcon from "@mui/icons-material/Phone"

import { useAuth } from "~/context/AuthContext"
import UserStatusPill from "~/app/settings/users/components/UserStatusPill"
import { SectionHeader, SectionContent } from "~/components"
import StaticField from "~/components/StaticField"
import { OrganizationStatus, Organization } from "~/types"
import { NOT_SPECIFIED, formatPersonName, formatDate } from "~/util"
import ChangeOwnerNotice from "./ChangeOwnerNotice"

interface Props {
  readonly franchisee: Organization
}

export default function OwnerInfoStatic({ franchisee }: Props) {
  const { user } = useAuth()

  return (
    <Paper>
      <SectionHeader>
        <label>{t("ownerInfo")}</label>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Box>
            {franchisee.status !== OrganizationStatus.DRAFT && franchisee.owner?.status ? (
              <UserStatusPill status={franchisee.owner.status} />
            ) : null}
          </Box>
        </Box>
      </SectionHeader>
      <Divider />
      <SectionContent
        sx={(theme) => {
          return {
            [theme.breakpoints.up("md")]: {
              padding: "1.25rem 2rem",
            },
          }
        }}
      >
        <StaticField
          label={t("emailAddress")}
          showBottomDivider
          value={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <MailOutlineIcon fontSize="inherit" style={{ marginRight: "0.625rem" }} />{" "}
                <Box
                  component="span"
                  sx={{
                    wordBreak: "break-word",
                  }}
                >
                  {franchisee.owner?.email ?? NOT_SPECIFIED}
                </Box>
              </Box>
            </Box>
          }
        />
        <StaticField
          label={t("name")}
          showBottomDivider
          value={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                {franchisee.owner?.firstName || franchisee.owner?.lastName
                  ? formatPersonName(franchisee.owner)
                  : NOT_SPECIFIED}
              </Box>
            </Box>
          }
        />
        <StaticField
          label={t("mobilePhone")}
          showBottomDivider={Boolean(franchisee.owner)}
          value={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <PhoneIcon fontSize="inherit" style={{ marginRight: "0.625rem" }} />{" "}
              {franchisee?.owner?.mobilePhoneNumber
                ? formatPhoneNumber(franchisee.owner?.mobilePhoneNumber)
                : NOT_SPECIFIED}
            </Box>
          }
        />
        {franchisee.owner ? (
          <StaticField
            label={t("lastLogin")}
            showBottomDivider={false}
            value={
              franchisee.owner?.lastLogin
                ? formatDate(franchisee.owner.lastLogin, "lll", user?.organization?.timeZone)
                : NOT_SPECIFIED
            }
          />
        ) : null}
        {franchisee.status !== OrganizationStatus.DRAFT &&
        franchisee.status !== OrganizationStatus.PENDING ? (
          <ChangeOwnerNotice />
        ) : null}
      </SectionContent>
    </Paper>
  )
}
