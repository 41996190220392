import gql from "graphql-tag"

export const ARCHIVE_ESTIMATE_REQUEST = gql`
  mutation ArchiveEstimateRequest($id: ID!) {
    archiveEstimateRequest(input: { id: $id }) {
      archived
    }
  }
`

