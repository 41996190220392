import gql from "graphql-tag"

export const ARCHIVE_USER = gql`
  mutation ArchiveUser(
    $id: ID!
    $reassignToUserId: ID
  ) {
    archiveUser(
      input: {
        id: $id
        reassignToUserId: $reassignToUserId
      }
    ) {
      user {
        id
        email
        firstName
        lastName
        jobTitle
        mobilePhoneNumber
        status
        isArchived
        languageCode
        roles {
          id
          isStandard
          name
        }
        organization {
          id
          tradeName
        }
        avatar {
          id
          signedUrl
          updatedAt
        }
        lastLogin
        createdAt
        createdBy {
          id
          email
        }
        updatedAt
        updatedBy {
          id
          email
        }
      }
    }
  }
`
