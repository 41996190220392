import * as React from "react"
import { FC, useCallback, useRef } from "react"
import { ContextMenuComponentProps, ContextMenuItem } from "react-datasheet-grid"
import { useDocumentEventListener } from "../../../hooks/useDocumentEventListener"
import { useTranslation } from "react-i18next"

export const defaultRenderItem = (item: ContextMenuItem, t: any) => {
  if (item.type === "DELETE_ROW") {
    return <>{t("page.checklist.deleteRow")}</>
  }

  if (item.type === "DELETE_ROWS") {
    return <>{t("page.checklist.deleteRows", { fromRow: item.fromRow, toRow: item.toRow })}</>
  }

  return item.type
}

export const createContextMenuComponent =
  (
    renderItem: (item: ContextMenuItem) => JSX.Element = defaultRenderItem
  ): FC<ContextMenuComponentProps> =>
  // eslint-disable-next-line react/display-name
  ({ clientX, clientY, items, close }) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const { t } = useTranslation()

    const supportedItems = items.filter((i) => ["DELETE_ROW", "DELETE_ROWS"].includes(i.type))

    const onClickOutside = useCallback(
      (event: MouseEvent) => {
        const clickInside = containerRef.current?.contains(event.target as Node)

        if (!clickInside) {
          close()
        }
      },
      [close]
    )
    useDocumentEventListener("mousedown", onClickOutside)

    return (
      <div
        className="dsg-context-menu"
        style={{ left: clientX + "px", top: clientY + "px" }}
        ref={containerRef}
      >
        {supportedItems.map((item) => (
          <div key={item.type} onClick={item.action} className="dsg-context-menu-item">
            {renderItem(item, t)}
          </div>
        ))}
      </div>
    )
  }

export const InstallationWorksheetContextMenu = createContextMenuComponent(defaultRenderItem)

InstallationWorksheetContextMenu.displayName = "InstallationWorksheetContextMenu"
