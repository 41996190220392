import React, { useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import isNil from "lodash/isNil"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import SectionHeader from "./SectionHeader"
import SectionContent from "./SectionContent"
import WhoWhen from "./WhoWhen"
import {
  ChangeLogEvent,
  ChangeLogEventType,
  ChangeLogFieldDetail,
  ProductOrderStatus,
  User,
} from "../types"
import { formatMoney, NOT_SPECIFIED } from "../util"
import { useAuth } from "../context/AuthContext"

const GET_PRODUCT_ORDER_HISTORY = gql`
  query GetProductOrderHistory($id: ID!) {
    getProductOrderById(id: $id) {
      id
      changeLog {
        eventDate
        user {
          id
          firstName
          lastName
          avatar {
            id
            signedUrl
            updatedAt
          }
        }
        eventType
        fieldDetails {
          fieldName
          oldValue
          newValue
        }
        lineItemChangeLogEvents {
          transactionLineItemId
          number
          eventType
          fieldDetails {
            fieldName
            oldValue
            newValue
          }
        }
      }
    }
  }
`

interface Props {
  productOrderId: string
}

function ProductOrderHistory({ productOrderId }: Props) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [loadChangeLog, setLoadChangeLog] = useState<boolean>(false)
  const [changeLog, setChangeLog] = useState([])

  const { loading } = useQuery(GET_PRODUCT_ORDER_HISTORY, {
    variables: {
      id: productOrderId,
    },
    skip: isNil(productOrderId) || !loadChangeLog,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const productOrder = data?.getProductOrderById
      setChangeLog(productOrder.changeLog)
    },
  })

  return (
    <Paper>
      <SectionHeader sx={{ justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
        <label>{t("component.productOrderHistory.sectionTitle")}</label>
      </SectionHeader>
      <SectionContent sx={{ maxHeight: "70vh", overflowY: "scroll" }}>
        {!loadChangeLog ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "1rem",
              paddingBottom: "2rem",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button onClick={() => setLoadChangeLog(true)}>
                {t("component.productOrderHistory.showHistoryButtonLabel")}
              </Button>
            )}
          </Box>
        ) : (
          changeLog.map((entry: ChangeLogEvent) => {
            return (
              <Box key={`${entry.eventDate}_${entry.eventType}`} sx={{ marginBottom: "2rem" }}>
                <Box sx={{ fontWeight: "600", fontSize: "0.9rem" }}>
                  {getProductOrderHistoryTitle(entry, t)}
                </Box>
                <WhoWhen
                  date={entry.eventDate}
                  emphasizeUser={false}
                  timeZone={user?.organization?.timeZone}
                  user={entry.user}
                />
                {entry.fieldDetails?.length ? (
                  <table
                    css={{
                      marginTop: "0.75rem",
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      width: "100%",
                      "& th, td": {
                        border: "1px solid black",
                        padding: "0.2rem 0.5rem",
                      },
                    }}
                  >
                    <thead>
                      <tr>
                        <th align="left" width="20%">
                          {t("component.productOrderHistory.field")}
                        </th>
                        <th align="left" width="40%">
                          {t("component.productOrderHistory.oldValue")}
                        </th>
                        <th align="left" width="40%">
                          {t("component.productOrderHistory.newValue")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {entry.fieldDetails
                        ?.filter(
                          (fd) =>
                            ![
                              "isArchived",
                              "currencyCode",
                              "orderNumber",
                              "totalTax",
                              "totalDiscount",
                            ].includes(fd.fieldName)
                        )
                        ?.map((fd) => {
                          const formattedValues = getFormattedValues(fd, entry.user, t)
                          return (
                            <tr key={`${entry.eventDate}_${fd.fieldName}`}>
                              <td>{t(`component.productOrderHistory.fields.${fd.fieldName}`)}</td>
                              <td align="left">
                                <ProductOrderChangeDetail value={formattedValues.oldValue} />
                              </td>
                              <td align="left">
                                <ProductOrderChangeDetail value={formattedValues.newValue} />
                              </td>
                            </tr>
                          )
                        })}
                      {entry.lineItemChangeLogEvents
                        ?.filter((fd) => !["lineItemNumber"].includes(fd.fieldName))
                        ?.map((cle) => {
                          return (
                            <tr key={`${cle.transactionLineItemId}`}>
                              <td>
                                {t(
                                  `component.productOrderHistory.fields.lineItemWas${cle.eventType}`,
                                  { number: cle.number }
                                )}
                              </td>
                              <td align="center">
                                {cle.fieldDetails.map((fd: ChangeLogFieldDetail) => {
                                  return (
                                    <LineItemChangeDetail
                                      fieldName={t(
                                        `component.productOrderHistory.fields.${fd.fieldName}`
                                      )}
                                      key={fd.fieldName}
                                      value={fd.oldValue}
                                    />
                                  )
                                })}
                              </td>
                              <td align="center">
                                {cle.fieldDetails.map((fd: ChangeLogFieldDetail) => {
                                  return (
                                    <LineItemChangeDetail
                                      fieldName={t(
                                        `component.productOrderHistory.fields.${fd.fieldName}`
                                      )}
                                      key={fd.fieldName}
                                      value={fd.newValue}
                                    />
                                  )
                                })}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                ) : null}
              </Box>
            )
          })
        )}
      </SectionContent>
    </Paper>
  )
}

function ProductOrderChangeDetail({ value }: { value: string }) {
  return <Box sx={{ fontSize: "0.8rem" }}>{value}</Box>
}

function LineItemChangeDetail({ fieldName, value }: { fieldName: string; value: string }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
        justifyContent: "flex-start",
        fontSize: "0.8rem",
        lineHeight: "1.2",
      }}
    >
      <Box sx={{ fontWeight: "600" }}>{fieldName}:</Box>
      <Box>{value ?? NOT_SPECIFIED}</Box>
    </Box>
  )
}

function getFormattedValues(
  fieldDetail: ChangeLogFieldDetail,
  eventUser: Partial<User>,
  t: (key: string) => string
) {
  const financialFields = ["subTotal", "total", "discount"]
  if (financialFields.includes(fieldDetail.fieldName)) {
    const oldValue = !isNil(fieldDetail.oldValue)
      ? formatMoney(
          eventUser?.organization?.currencyCode,
          fieldDetail.oldValue,
          t("format:currency.long")
        )
      : NOT_SPECIFIED
    const newValue = formatMoney(
      eventUser?.organization?.currencyCode,
      fieldDetail.newValue,
      t("format:currency.long")
    )
    return {
      oldValue,
      newValue,
    }
  } else if (fieldDetail.fieldName === "status") {
    return {
      oldValue: fieldDetail.oldValue
        ? t(`productOrderStatus.${fieldDetail.oldValue}`)
        : NOT_SPECIFIED,
      newValue: t(`productOrderStatus.${fieldDetail.newValue}`),
    }
  } else {
    return {
      oldValue: fieldDetail.oldValue,
      newValue: fieldDetail.newValue,
    }
  }
}

function getProductOrderHistoryTitle(entry: ChangeLogEvent, t: (key: string) => string): string {
  if (entry.eventType === ChangeLogEventType.CREATED) {
    return t(`component.productOrderHistory.eventTitle.orderCreated`)
  } else if (entry.eventType === ChangeLogEventType.UPDATED) {
    const submittedFieldDetail = entry.fieldDetails.find(
      (fd) => fd.fieldName === "status" && fd.newValue === ProductOrderStatus.SUBMITTED
    )
    if (submittedFieldDetail) {
      return t(`component.productOrderHistory.eventTitle.orderSubmitted`)
    } else {
      return t(`component.productOrderHistory.eventTitle.orderUpdated`)
    }
  } else {
    return ""
  }
}

export default ProductOrderHistory
