import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import FlagIcon from "@mui/icons-material/Flag"
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag"

import { CustomerFlag, Customer } from "~/types"
import { useAuth } from "~/context/AuthContext"
import PERMISSION_REQUIREMENTS from "~/util/permissionRequirements"
import useGetAllCustomerFlags from "~/hooks/useGetAllCustomerFlags"

interface Props {
  readonly customer: Customer
  readonly onSelectFlag: (flag: CustomerFlag) => void
  readonly onRemoveFlag: (flag: CustomerFlag) => void
}

export default function CustomerFlagMenu({ customer, onSelectFlag, onRemoveFlag }: Props) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { user, hasPermissions } = useAuth()
  const { customerFlags } = useGetAllCustomerFlags(user?.organization?.id)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const currentFlag = customer.flags?.[0]
  const flagOptions = customerFlags

  return (
    <Box>
      <Button
        aria-controls={open ? "flags-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        disabled={!hasPermissions?.(PERMISSION_REQUIREMENTS.customers.edit)}
        id="flags-button"
        onClick={handleClick}
      >
        {currentFlag ? (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0.5rem" }}>
            <FlagIcon
              style={{ color: currentFlag.colorCode, fontSize: "1.5rem", marginTop: "2px" }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0", alignItems: "start" }}>
              <Box
                component="span"
                sx={{ color: (theme) => theme.fielderColors.black, fontWeight: "600" }}
              >
                {currentFlag.name}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              fontSize: "0.8125rem",
              fontWeight: "500",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "0.25rem",
            }}
          >
            <OutlinedFlagIcon sx={{ fontSize: "1rem" }} />
            {t("component.customerFlagMenu.addFlag")}
          </Box>
        )}
      </Button>
      {currentFlag && hasPermissions?.(PERMISSION_REQUIREMENTS.customers.edit) ? (
        <Button
          onClick={() => {
            onRemoveFlag(currentFlag)
          }}
          sx={{ textDecoration: "underline", fontSize: "0.8125rem" }}
          variant="text"
        >
          {t("component.customerFlagMenu.removeFlag")}
        </Button>
      ) : null}
      <Menu
        MenuListProps={{
          "aria-labelledby": "flags-button",
        }}
        anchorEl={anchorEl}
        id="flags-menu"
        onClose={handleClose}
        open={open}
      >
        {!flagOptions || flagOptions.length === 0 ? (
          <Box
            sx={{
              padding: "1rem",
            }}
          >
            <Trans
              components={{
                SettingsLink: <Link to="/app/settings/customerflags" />,
              }}
              i18nKey="component.customerFlagMenu.noFlags"
            />
          </Box>
        ) : (
          flagOptions?.map((flag: any) => (
            <MenuItem
              key={flag.id}
              onClick={() => {
                onSelectFlag(flag)
                handleClose()
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "row", gap: "0.5rem", alignItems: "start" }}
              >
                <FlagIcon style={{ color: flag.colorCode, fontSize: "28px", marginTop: "2px" }} />
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0" }}>
                  <Box
                    component="span"
                    sx={{ color: (theme) => theme.fielderColors.black, fontWeight: "600" }}
                  >
                    {flag.name}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      color: (theme) => theme.fielderColors.mutedText,
                      lineHeight: "1",
                      fontSize: "0.875rem",
                    }}
                  >
                    {flag.description}
                  </Box>
                </Box>
              </Box>
            </MenuItem>
          ))
        )}
      </Menu>
    </Box>
  )
}
