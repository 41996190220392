import gql from "graphql-tag"
import FullOrganization from "./fragments/fullOrganization"

export const LOGIN_AS_FRANCHISEE = gql`
  mutation ImpersonateFranchisee($organizationId: ID!) {
    impersonateFranchisee(input: { organizationId: $organizationId }) {
      authToken
      user {
        id
        firstName
        lastName
        languageCode
        avatar {
          id
          signedUrl
          updatedAt
        }
      }
      organization {
        ...FullOrganization
      }
    }
  }
  ${FullOrganization}
`
