import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import gql from "graphql-tag"
import { Navigate, useParams, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation, NetworkStatus } from "@apollo/client"
import orderBy from "lodash/orderBy"
import WarningIcon from "@mui/icons-material/WarningOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import SyncIcon from "@mui/icons-material/SyncOutlined"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import AddCommentIcon from "@mui/icons-material/AddCommentOutlined"
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined"

import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import { ARCHIVE_CONTACT } from "~/queries/archiveContact"
import { ARCHIVE_CUSTOMER } from "~/queries/archiveCustomer"
import { GET_CUSTOMER_BY_ID } from "~/queries/getCustomerById"
import { EDIT_CUSTOMER } from "~/queries/editCustomer"
import { CREATE_CONTACT } from "~/queries/createContact"
import { EDIT_CONTACT } from "~/queries/editContact"
import { UNARCHIVE_CUSTOMER } from "~/queries/unarchiveCustomer"
import { SYNC_CUSTOMER } from "~/queries/syncCustomer"
import {
  capitalize,
  formatDate,
  formatMoney,
  formatPersonName,
  formatAddress,
  parseGraphQLErrorCode,
  CUSTOMERS,
} from "~/util"
import { useAuth } from "~/context/AuthContext"
import CustomerBasicInfoForm from "./components/CustomerBasicInfoForm"
import CustomerBasicInfoStatic from "./components/CustomerBasicInfoStatic"
import ContactInfoStatic from "./components/ContactInfoStatic"
import ContactInfoForm from "./components/ContactInfoForm"
import CustomerFlagMenu from "./components/CustomerFlagMenu"
import CustomerNote from "./components/CustomerNote"
import {
  Customer,
  Contact,
  Snack,
  Job,
  CustomerType,
  DefaultPermission,
  EditCustomerInput,
} from "~/types"
import FielderIconButton from "~/components/FielderIconButton"
import ArchiveButton from "~/components/ArchiveButton"
import UnarchiveButton from "~/components/UnarchiveButton"
import useGetAccountingPlugin from "~/hooks/useGetAccountingPlugin"
import CustomerNoteDialog from "./components/CustomerNoteDialog"
import JobStatusPill from "~/app/jobs/components/JobStatusPill"

const SET_CUSTOMER_FLAG = gql`
  mutation SetCustomerFlag($customerId: ID!, $customerFlagId: ID!) {
    editCustomer(input: { id: $customerId, customerFlagIds: [$customerFlagId] }) {
      customer {
        id
        name
        flags {
          id
          name
          description
          colorCode
        }
      }
    }
  }
`

const REMOVE_CUSTOMER_FLAG = gql`
  mutation RemoveCustomerFlag($customerId: ID!) {
    editCustomer(input: { id: $customerId, customerFlagIds: [] }) {
      customer {
        id
        name
        flags {
          id
          name
          description
          colorCode
        }
      }
    }
  }
`

function sortContacts(contacts: Contact[]) {
  return contacts?.sort((a, b) => {
    return `${a.firstName}${a.lastName}`.localeCompare(`${b.firstName}${b.lastName}`)
  })
}

/**
 * This component is used for both creating new and editing existing Customers,
 * since the design is similar enough in either case.
 */
function EditCustomer() {
  const { t } = useTranslation()
  const { id } = useParams()
  const { user, hasPermissions } = useAuth()
  const [snack, setSnack] = useState<Snack>()
  const [inEditCustomerMode, setInEditCustomerMode] = useState<boolean>(false)
  const [inEditContactMode, setInEditContactMode] = useState<boolean>(false)
  const [isArchiveContactDialogOpen, setIsArchiveContactDialogOpen] = useState<boolean>(false)
  const [customer, setCustomer] = useState<Customer>()
  const [contacts, setContacts] = useState<Contact[]>([])
  const [selectedContact, setSelectedContact] = useState<Contact | null>()
  const [openArchiveCustomerDialog, setOpenArchiveCustomerDialog] = useState<boolean>(false)
  const [openUnarchiveCustomerDialog, setOpenUnarchiveCustomerDialog] = useState<boolean>(false)
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const canEditCustomer = hasPermissions?.([DefaultPermission.UpdateCustomer])
  const { accountingPlugin } = useGetAccountingPlugin(user?.organization?.id)
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false)

  const [setCustomerFlag, { loading: setCustomerFlagLoading }] = useMutation(SET_CUSTOMER_FLAG, {
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [removeCustomerFlag, { loading: removeCustomerFlagLoading }] = useMutation(
    REMOVE_CUSTOMER_FLAG,
    {
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const [syncCustomer, { loading: syncLoading }] = useMutation(SYNC_CUSTOMER, {
    onCompleted: () => {
      setSnack({
        messageKey: "page.customer.sync.success",
        messageOptions: { pluginName: accountingPlugin?.pluginProvider?.name },
        variant: "success",
      })
      refetchCustomer()
    },
    onError: (error) => {
      const errorCode = parseGraphQLErrorCode(error)

      if (errorCode.includes("organization-plugin.accounting-plugin")) {
        setSnack({ messageKey: errorCode, variant: "error" })
      } else {
        Sentry.captureException(error)
        setSnack({
          messageKey: "page.customer.sync.error",
          messageOptions: { pluginName: accountingPlugin?.pluginProvider?.name },
          variant: "error",
        })
      }
    },
  })

  const [editCustomer, { loading: editCustomerLoading }] = useMutation(EDIT_CUSTOMER, {
    onCompleted: () => {
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      setInEditCustomerMode(false)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: ["GetCustomerById"],
  })

  const [archiveCustomer] = useMutation(ARCHIVE_CUSTOMER, {
    onCompleted: () => {
      setRedirectTo({
        to: "/app/customers",
        replace: false,
        state: {
          snack: {
            messageKey: "messages.archiveCustomer.success",
            messageOptions: { customer: customer?.name },
            variant: "success",
          },
        },
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [unarchiveCustomer] = useMutation(UNARCHIVE_CUSTOMER, {
    onCompleted: () => {
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      setOpenUnarchiveCustomerDialog(false)
      setInEditCustomerMode(false)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [createContact, { loading: loadingCreateContact }] = useMutation(CREATE_CONTACT, {
    onCompleted: (data) => {
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      setInEditContactMode(false)
      const newContact = data.createContact.contact
      const contactList = [...contacts, newContact]
      setContacts(sortContacts(contactList))
      setSelectedContact(null)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: ["GetCustomerById"],
  })

  const [editContact, { loading: loadingEditContact }] = useMutation(EDIT_CONTACT, {
    onCompleted: (data) => {
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      setInEditContactMode(false)
      const updatedContact = data.editContact.contact
      const updatedContacts = [...contacts]
      const idx = contacts.findIndex((i) => i.id === updatedContact.id)
      if (idx !== -1) {
        updatedContacts[idx] = updatedContact
      }
      setContacts(sortContacts(updatedContacts))
      setSelectedContact(null)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: ["GetCustomerById"],
  })

  const [archiveContact, { loading: archiveContactLoading }] = useMutation(ARCHIVE_CONTACT, {
    onCompleted: () => {
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      setInEditContactMode(false)
      setIsArchiveContactDialogOpen(false)
      const idx = selectedContact ? contacts.findIndex((i) => i.id === selectedContact.id) : -1
      if (idx !== -1) {
        const updatedContacts = [...contacts]
        updatedContacts.splice(idx, 1)
        setContacts(updatedContacts)
        setSelectedContact(null)
      }
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: ["GetCustomerById"],
  })

  const {
    error,
    refetch: refetchCustomer,
    networkStatus: getCustomerNetworkStatus,
  } = useQuery(GET_CUSTOMER_BY_ID, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      // Note - this will get called automatically by Apollo whenever the underlying cached data is updated,
      // such as calls to setCustomerFlag or removeCustomerFlag
      setCustomer(data.getCustomerById)
      const sortedContacts = sortContacts(
        data.getCustomerById.contacts.filter((c: Customer) => !c.isArchived)
      )
      setContacts(sortedContacts)
    },
  })

  function handleArchiveContact() {
    if (!archiveContactLoading && selectedContact) {
      archiveContact({ variables: { id: selectedContact.id, customerId: id } })
    }
  }

  function handleCancelEditCustomer() {
    setInEditCustomerMode(false)
  }

  function handleSaveCustomer(customerInput: EditCustomerInput) {
    editCustomer({
      variables: customerInput,
    })
  }

  function handleCancelEditContact() {
    setInEditContactMode(false)
  }

  function handleSaveContact(payload) {
    payload.customerId = id
    payload.id ? editContact({ variables: payload }) : createContact({ variables: payload })
  }

  function renderBasicInfoHeaderButton() {
    if (!customer) {
      return null
    }

    if (customer.isArchived) {
      return (
        <UnarchiveButton disabled={false} onClick={() => setOpenUnarchiveCustomerDialog(true)} />
      )
    }

    if (!inEditCustomerMode && !customer.isArchived && canEditCustomer) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          {accountingPlugin ? (
            !syncLoading ? (
              <FielderIconButton
                aria-label={t(`syncWithPlugin.${accountingPlugin.pluginProvider.id}`) as string}
                data-testid="syncWithPluginButton"
                disabled={syncLoading}
                onClick={() => {
                  syncCustomer({ variables: { id: customer.id } })
                }}
                sx={(theme) => ({
                  [theme.breakpoints.down("xs")]: {
                    marginLeft: 0,
                  },
                })}
                title={t(`syncWithPlugin.${accountingPlugin.pluginProvider.id}`) as string}
              >
                <SyncIcon />
              </FielderIconButton>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    [theme.breakpoints.down("xs")]: {
                      marginLeft: 0,
                    },
                  })}
                />
              </Box>
            )
          ) : null}

          <Button
            onClick={() => {
              setInEditCustomerMode(true)
            }}
            size="small"
            startIcon={<EditIcon />}
            sx={(theme) => ({
              [theme.breakpoints.down("xs")]: {
                marginLeft: 0,
              },
            })}
            variant="text"
          >
            {t("edit")}
          </Button>
        </Box>
      )
    }
    if (
      inEditCustomerMode &&
      !customer.isArchived &&
      hasPermissions?.([DefaultPermission.UpdateCustomer])
    ) {
      return (
        <ArchiveButton disabled={syncLoading} onClick={() => setOpenArchiveCustomerDialog(true)} />
      )
    }
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  const loading = getCustomerNetworkStatus === NetworkStatus.loading
  const sortedNotes = customer?.notes ? orderBy(customer.notes, ["createdAt"], ["desc"]) : []

  return (
    <>
      <Seo title={t(CUSTOMERS.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={CUSTOMERS}>
        <Box
          sx={{
            margin: "0 1.25rem",
            paddingBottom: "12.5rem",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: CUSTOMERS.path, titleKey: CUSTOMERS.titleKey }]}
            icon={CUSTOMERS.icon}
            leafTitleKey="editCustomer"
          />
          {error ? (
            <Alert severity="error">
              {`${t("error.general.title")} ${t("error.general.message")}`}
            </Alert>
          ) : null}
          {loading ? (
            <CircularProgress />
          ) : (
            <Box
              sx={(theme) => ({
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "1rem",
                [theme.breakpoints.up("sm")]: {
                  gridTemplateColumns: "repeat(2, 1fr)",
                },
              })}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <Paper>
                  <SectionHeader sx={{ gap: "2rem" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2rem",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <label>{t("basicInfo")}</label>
                      {customer ? (
                        <CustomerFlagMenu
                          customer={customer}
                          onRemoveFlag={() => {
                            // optimistically update the UI
                            setCustomer((prev) => {
                              return {
                                ...prev,
                                flags: [],
                              }
                            })
                            removeCustomerFlag({
                              variables: {
                                customerId: customer.id,
                              },
                            })
                          }}
                          onSelectFlag={(flag) => {
                            // optimistically update the UI
                            setCustomer((prev) => {
                              return {
                                ...prev,
                                flags: [flag],
                              }
                            })
                            setCustomerFlag({
                              variables: {
                                customerId: customer.id,
                                customerFlagId: flag.id,
                              },
                            })
                          }}
                        />
                      ) : null}
                    </Box>
                    {customer?.isArchived ? (
                      <Box
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                          backgroundColor: "rgba(218, 21, 5, 0.1)",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          fontSize: "0.875rem",
                          color: "#DA1505",
                        }}
                      >
                        <WarningIcon
                          style={{
                            fontSize: "1.125rem",
                          }}
                        />
                        <span>{t("thisCustomerHasBeenArchived")}</span>
                      </Box>
                    ) : null}
                    {renderBasicInfoHeaderButton()}
                  </SectionHeader>
                  <Divider />
                  <SectionContent>
                    {!inEditCustomerMode && customer ? (
                      <CustomerBasicInfoStatic customer={customer} />
                    ) : null}
                    {inEditCustomerMode && customer && user ? (
                      <Box sx={classes.editView}>
                        <CustomerBasicInfoForm
                          customer={customer}
                          loading={editCustomerLoading}
                          onCancel={handleCancelEditCustomer}
                          onSave={handleSaveCustomer}
                        />
                      </Box>
                    ) : null}
                  </SectionContent>
                </Paper>
                {customer?.type === CustomerType.BUSINESS && (
                  <Paper>
                    <SectionHeader>
                      <label>{t("contacts")}</label>
                      {!inEditContactMode && !customer.isArchived && canEditCustomer ? (
                        <Button
                          aria-label={t("addContact") as string}
                          disabled={syncLoading}
                          onClick={() => {
                            setSelectedContact(null)
                            setInEditContactMode(true)
                          }}
                          size="small"
                          startIcon={<PersonAddOutlinedIcon />}
                          variant="text"
                        >
                          {t("addContact")}
                        </Button>
                      ) : null}
                    </SectionHeader>
                    <Divider />
                    {customer ? (
                      <Box>
                        {inEditContactMode ? (
                          <ContactInfoForm
                            contact={selectedContact}
                            loading={loadingEditContact || loadingCreateContact}
                            onCancel={handleCancelEditContact}
                            onSave={handleSaveContact}
                          />
                        ) : (
                          <Box>
                            {contacts?.length === 0 ? (
                              <Box sx={classes.zeroStatePrompt}>
                                {t("page.customer.noContactsPrompt")}
                              </Box>
                            ) : (
                              <Box
                                sx={(theme) => ({
                                  maxHeight: "700px",
                                  overflowY: "scroll",
                                  [theme.breakpoints.down("md")]: {
                                    maxHeight: "400px",
                                  },
                                })}
                              >
                                {contacts.map((c) => (
                                  <Box
                                    key={c.id}
                                    sx={{ paddingLeft: "0.25rem", paddingRight: "0.25rem" }}
                                  >
                                    <ContactInfoStatic
                                      contact={c}
                                      customer={customer}
                                      onClickArchive={() => {
                                        if (!customer.isArchived) {
                                          setSelectedContact(c)
                                          setIsArchiveContactDialogOpen(true)
                                        }
                                      }}
                                      onClickEdit={() => {
                                        if (!customer.isArchived) {
                                          setSelectedContact(c)
                                          setInEditContactMode(true)
                                        }
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        borderBottom: "1px solid #ededed",
                                        margin: "0 auto",
                                      }}
                                    />
                                  </Box>
                                ))}
                              </Box>
                            )}
                          </Box>
                        )}
                      </Box>
                    ) : null}
                  </Paper>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <Paper>
                  <Box
                    sx={(theme) => ({
                      padding: "1.25rem",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      [theme.breakpoints.down("xs")]: {
                        width: "100%",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                    })}
                  >
                    <Box sx={classes.metric}>
                      <Box sx={classes.metricLabel}>{t("numberOfJobs")}</Box>
                      <Box sx={classes.metricValue}>{customer?.numberOfJobs || 0}</Box>
                    </Box>
                    <Box
                      sx={(theme) => ({
                        borderRight: "1px solid #212121",
                        width: "1px",
                        [theme.breakpoints.down("xs")]: {
                          width: "100%",
                          height: "1px",
                          borderBottom: "1px solid #212121",
                          borderRight: "none",
                          marginBottom: "0.625rem",
                        },
                      })}
                    />
                    <Box sx={classes.metric}>
                      <Box sx={classes.metricLabel}>{t("totalSales")} </Box>
                      <Box sx={classes.metricValue}>
                        {formatMoney(
                          user?.organization?.currencyCode,
                          customer?.totalSales,
                          t("format:currency.short")
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Paper>
                <Paper sx={{ paddingBottom: "1rem" }}>
                  <SectionHeader>
                    <label>{t("jobs")}</label>
                  </SectionHeader>
                  <Divider />
                  <Box>
                    {customer?.jobs?.length == 0 ? (
                      <Box sx={classes.zeroStatePrompt}>{t("page.customer.noJobsPrompt")}</Box>
                    ) : (
                      <>
                        <TableContainer
                          sx={(theme) => ({
                            background: "#ffffff",
                            minHeight: "200px",
                            maxHeight: "600px",
                            display: "none",
                            [theme.breakpoints.up("md")]: {
                              display: "table",
                              maxHeight: "666px",
                            },
                          })}
                        >
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={classes.tableHead}>{t("jobNumber")}</TableCell>
                                <TableCell sx={classes.tableHead}>{t("dateCreated")}</TableCell>
                                <TableCell sx={classes.tableHead}>{t("address")}</TableCell>
                                <TableCell sx={classes.tableHead}>{t("status")}</TableCell>
                                <TableCell sx={classes.tableHead}> </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody style={{ maxHeight: "800px" }}>
                              {customer?.jobs?.map((job: Job) => {
                                return (
                                  <TableRow key={job.id}>
                                    <TableCell>{job.number}</TableCell>
                                    <TableCell>
                                      {formatDate(
                                        job.createdAt,
                                        t("format:dateFormat.long"),
                                        user?.organization?.timeZone,
                                        user
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {formatAddress(job.address?.addressString)}
                                    </TableCell>
                                    <TableCell>
                                      <JobStatusPill jobWorkflowStep={job.workflowStep} />
                                    </TableCell>
                                    <TableCell align="right" style={{ paddingRight: "1rem" }}>
                                      <Button
                                        color="secondary"
                                        data-testid="editButton"
                                        disabled={loading}
                                        onClick={() => {
                                          setRedirectTo({
                                            to: `/app/jobs/edit/${job.id}`,
                                            replace: false,
                                          })
                                        }}
                                        variant="outlined"
                                      >
                                        {t("open")}
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Box
                          sx={(theme) => ({
                            maxHeight: "500px",
                            overflowY: "scroll",
                            [theme.breakpoints.up("md")]: {
                              // for screens wider than 600px or less we won't display the cards (we display the table instead).
                              display: "none",
                            },
                          })}
                        >
                          {customer?.jobs?.map((job) => {
                            return (
                              <Box
                                key={job.id}
                                onClick={() => {
                                  setRedirectTo({
                                    to: `/app/jobs/edit/${job.id}`,
                                    replace: false,
                                  })
                                }}
                                sx={classes.jobCard}
                              >
                                <Box sx={classes.jobCardFieldContainer}>
                                  <Box sx={classes.jobCardFieldLabel}>{t("jobNumber")}</Box>
                                  <Box>{job.number}</Box>
                                </Box>
                                <Box sx={classes.jobCardFieldContainer}>
                                  <Box sx={classes.jobCardFieldLabel}>{t("dateCreated")}</Box>
                                  <Box>
                                    {formatDate(
                                      job.createdAt,
                                      t("format:dateFormat.long"),
                                      user?.organization?.timeZone,
                                      user
                                    )}
                                  </Box>
                                </Box>
                                <Box sx={classes.jobCardFieldContainer}>
                                  <Box sx={classes.jobCardFieldLabel}>{t("status")}</Box>
                                  <Box>{capitalize(job.workflowStep.jobStatus.name)}</Box>
                                </Box>
                                <Box sx={classes.jobCardFieldContainer}>
                                  <Box sx={classes.jobCardFieldLabel}>{t("address")}</Box>
                                  <Box style={{ textAlign: "right" }}>
                                    {formatAddress(job.address?.addressString)}
                                  </Box>
                                </Box>
                              </Box>
                            )
                          })}
                        </Box>
                      </>
                    )}
                  </Box>
                </Paper>
                <Paper>
                  <SectionHeader>
                    <label>{t("notes")}</label>
                    <Button
                      aria-label={t("addNote") as string}
                      onClick={() => setIsNoteDialogOpen(true)}
                      size="small"
                      startIcon={<AddCommentIcon />}
                      variant="text"
                    >
                      {t("addNote")}
                    </Button>
                  </SectionHeader>
                  <Divider />
                  <SectionContent
                    sx={{
                      maxHeight: "500px",
                      overflowY: "scroll",
                    }}
                  >
                    {sortedNotes.length === 0 ? (
                      <Box sx={classes.zeroStatePrompt}>{t("page.customer.noNotesPrompt")}</Box>
                    ) : (
                      sortedNotes.map((note, index) => (
                        <Box key={note.id}>
                          <CustomerNote note={note} />
                          {index !== sortedNotes.length - 1 && <Divider />}
                        </Box>
                      ))
                    )}
                  </SectionContent>
                </Paper>
              </Box>
            </Box>
          )}
        </Box>
      </MainLayout>
      {isArchiveContactDialogOpen ? (
        <Dialog
          aria-describedby="archive-contact-dialog-description"
          aria-labelledby="archive-contact-dialog-title"
          onClose={() => setIsArchiveContactDialogOpen(false)}
          open={isArchiveContactDialogOpen}
        >
          <DialogTitle id="archive-contact-dialog-title">
            {t("page.customer.archiveContactConfirmationPromptTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-contact-dialog-description">
              {t("page.customer.archiveContactConfirmationPromptMsg", {
                name: `${formatPersonName(selectedContact)}`,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={archiveContactLoading}
              onClick={() => setIsArchiveContactDialogOpen(false)}
            >
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={archiveContactLoading}
              onClick={handleArchiveContact}
            >
              {archiveContactLoading ? (
                <CircularProgress color="secondary" size={20} thickness={6.0} />
              ) : (
                <Box>{t("yes")}</Box>
              )}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {openArchiveCustomerDialog ? (
        <Dialog
          aria-describedby="archive-customer-dialog-description"
          aria-labelledby="archive-customer-dialog-title"
          onClose={() => setOpenArchiveCustomerDialog(false)}
          open={openArchiveCustomerDialog}
        >
          <DialogTitle id="archive-customer-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-customer-dialog-description">
              {t("component.archiveCustomerDialog.confirmationPrompt", {
                name: customer?.name,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpenArchiveCustomerDialog(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => archiveCustomer({ variables: { id: customer?.id } })}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {openUnarchiveCustomerDialog ? (
        <Dialog
          aria-describedby="unarchive-customer-dialog-description"
          aria-labelledby="unarchive-customer-dialog-title"
          onClose={() => setOpenUnarchiveCustomerDialog(false)}
          open={openUnarchiveCustomerDialog}
        >
          <DialogTitle id="unarchive-customer-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="unarchive-customer-dialog-description">
              {t("component.unarchiveCustomerDialog.confirmationPrompt", {
                name: customer?.name,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpenUnarchiveCustomerDialog(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => unarchiveCustomer({ variables: { id: customer?.id } })}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {isNoteDialogOpen ? (
        <CustomerNoteDialog
          customerId={customer.id}
          onCancel={() => setIsNoteDialogOpen(false)}
          onSave={(status) => {
            setIsNoteDialogOpen(false)
          }}
          user={user}
        />
      ) : null}
    </>
  )
}

const classes = {
  editView: {
    paddingTop: "0.125rem",
  },
  staticView: {
    paddingTop: "0.125rem",
    paddingBottom: "3.125rem",
  },
  metric: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  metricLabel: {
    fontSize: "1.1rem",
    fontWeight: 600,
  },
  metricValue: {
    fontSize: "2.5rem",
    fontWeight: "bold",
  },
  zeroStatePrompt: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "3.125rem",
    fontSize: "1rem",
    fontWeight: 500,
    color: "#747474",
  },
  tableHead: {
    backgroundColor: "#FFFFFF",
  },
  jobCard: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid rgba(0,0,0,0.12)",
    padding: "0.625rem 1.25rem",
    "&:hover": {
      backgroundColor: "#FEFAEA",
    },
  },
  jobCardFieldContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "0.125rem",
    marginBottom: "0.125rem",
    fontSize: "0.875rem",
  },
  jobCardFieldLabel: {
    fontWeight: "bold",
  },
} as const

export default EditCustomer
