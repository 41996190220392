import gql from "graphql-tag"

export const CREATE_ESTIMATE_REQUEST = gql`
  mutation CreateEstimateRequest(
    $organizationId: ID
    $gRecaptchaResponse: String
    $address: AddressInput
    $customerType: CustomerType
    $businessName: String
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $description: String
    $desiredCompletionDate: LocalDateTime
  ) {
    createEstimateRequest(
      input: {
        organizationId: $organizationId
        gRecaptchaResponse: $gRecaptchaResponse
        address: $address
        customerType: $customerType
        businessName: $businessName
        firstName: $firstName
        lastName: $lastName
        email: $email
        phoneNumber: $phoneNumber
        description: $description
        desiredCompletionDate: $desiredCompletionDate
      }
    ) {
      estimateRequest {
        id
      }
    }
  }
`

