export interface PasswordError {
  length?: boolean
  number?: boolean
  lowercase?: boolean
  uppercase?: boolean
  special?: boolean
}

export interface Errors {
  password: PasswordError
  confirmPassword?: string
}

export function validateForm(values: { password: string; confirmPassword: string }): Errors {
  const errors: Errors = {
    password: {
      length: true,
      number: true,
      lowercase: true,
      uppercase: true,
      special: true,
    },
  }

  if (values.password && values.password.length >= 8) {
    delete errors.password.length
  }
  if (values.password && /.*\d/.test(values.password)) {
    delete errors.password.number
  }
  if (values.password && /.*[a-z]/.test(values.password)) {
    delete errors.password.lowercase
  }
  if (values.password && /.*[A-Z]/.test(values.password)) {
    delete errors.password.uppercase
  }
  if (values.password && /.*[@$#!%*?&]/.test(values.password)) {
    delete errors.password.special
  }

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "page.resetPassword.mismatch"
  }

  return errors
}

/* Override the `isValid` behavior of Formik. Formik's notion of validity is 
that the `errors` object returned from the validation function is empty. 
But, we're using the  errors.password field to drive UX with respect to conformance to 
our password strength rules. As such, it is not binary but ternary based on the three 
conditions:
 - the user hasn't even touched the fields yet, 
 - the user is editing the password field, and we want to let them know which rules 
   are satisfied as they type
 - the user removed focus from the password field; we want to indicate which rules 
    are satisfied and which are not, based on the boolean value of the appropriate
    errors.password field. 
That is, the lack of an errors.password field does not imply that the form is valid. 
Instead, it's the lack of child properties of errors.password that implies validity, 
()as well as making sure that the confirmPassword field matches the password field).
 */
export function isValid(errors: Errors): boolean {
  return Object.keys(errors.password).length === 0 && !errors.confirmPassword
}
