import gql from "graphql-tag"

export const GET_ORGANIZATION_SUBSCRIPTION = gql`
  query GetOrganizationById($id: ID!) {
    getOrganizationById(id: $id) {
      id
      fielderSubscription {
        id
        status
      }
    }
  }
`