/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { useTranslation } from "react-i18next"
import Downshift from "downshift"
import { useQuery } from "@apollo/client"
import { ALL_JOBS } from "../queries/allJobs"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import { TextFieldProps } from "@mui/material/TextField"
import Paper from "@mui/material/Paper"
import MenuItem from "@mui/material/MenuItem"
import FielderTextField from "./FielderTextField"
import { Job } from "../types"

function renderInput(props: TextFieldProps) {
  return (
    <FielderTextField {...props} InputProps={{ ...props.InputProps, disableUnderline: true }} />
  )
}

interface SuggestionsProps {
  readonly getItemProps: (item: any) => any
  readonly inputValue: string
}

function Suggestions({ getItemProps, inputValue }: SuggestionsProps) {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(ALL_JOBS, {
    variables: {
      filter: inputValue,
      sortBy: "number",
      sortDir: "ASC",
      first: 5,
    },
    context: {
      debounceKey: "JobTypeahead",
      debounceTimeout: 500,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  })

  if (loading) {
    return (
      <Box sx={{ marginTop: "0.5rem" }}>
        <CircularProgress size={20} thickness={6.0} /> {t("loadingJobs")}
      </Box>
    )
  }

  if (error) return <div>Error! ${error.message}</div>

  const options: Job[] = data.allJobs.edges.map((e: { node: Job }) => e.node)

  return (
    <Paper square>
      {options.map((option: Job) => {
        return (
          <MenuItem
            dense
            key={option.id}
            sx={{
              borderBottom: "1px solid #ededed",
            }}
            {...getItemProps({ item: option })}
          >
            <Box
              sx={{
                flexGrow: 1,
                lineHeight: "normal",
              }}
            >
              <span>{`${t("job")} #${option.number}`}</span>
              {option.customer ? <span> - {option.customer.name}</span> : null}
              {option.address ? <span> - {option.address.addressString}</span> : null}
            </Box>
          </MenuItem>
        )
      })}
    </Paper>
  )
}

interface JobTypeaheadProps {
  readonly currentJob?: Job
  readonly label: string
  readonly onSelect: (job: Job | null) => void
}

function JobTypeahead({ currentJob, label, onSelect }: JobTypeaheadProps) {
  const { t } = useTranslation()
  return (
    <Downshift
      itemToString={(item: Job | null) =>
        item ? `#${item.number} - ${item.customer?.name} - ${item.address?.addressString}` : ""
      }
      onChange={(item: Job | null) => onSelect(item)}
      selectedItem={currentJob}
    >
      {({ getInputProps, getItemProps, getLabelProps, inputValue = "", isOpen }) => {
        const { onBlur, onFocus, ...inputProps } = getInputProps()

        // Hey! We have to use <div> here instead of <Box> because Downshift expects a DOM element.
        return (
          <div>
            {renderInput({
              fullWidth: true,
              label: label,
              InputLabelProps: getLabelProps(),
              InputProps: { onBlur, onFocus, placeholder: t("searchJobs") },
              inputProps,
            })}
            {isOpen && inputValue && inputValue.length >= 2 ? (
              <Suggestions getItemProps={getItemProps} inputValue={inputValue} />
            ) : null}
          </div>
        )
      }}
    </Downshift>
  )
}

export default JobTypeahead
