/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-literals */
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import { HeaderLogo, Seo } from "../components"

function PrivacyPage() {
  const { t } = useTranslation("common", { useSuspense: typeof window !== "undefined" })

  return (
    <div css={classes.root}>
      <Seo title={t("privacyPolicy", "Privacy Policy")} />
      <main>
        <div css={classes.header}>
          <div css={classes.logoContainer}>
            <Link to="/">
              <HeaderLogo height="auto" width="100%" />
            </Link>
          </div>
        </div>
        <div css={classes.contentContainer}>
          <div css={classes.content}>
            <h1>Privacy Policy</h1>
            <p>Last updated: December 13, 2020</p>
            <section>
              <h2>Table of Contents</h2>
              <ul css={classes.tableOfContents}>
                <li>
                  <a href="#section1">1. Introduction</a>
                </li>
                <li>
                  <a href="#section2">2. Information we collect</a>
                </li>
                <li>
                  <a href="#section3">3. How we use the information we collect</a>
                </li>
                <li>
                  <a href="#section4">4. Information you share</a>
                </li>
                <li>
                  <a href="#section5">5. Information we share</a>
                </li>
                <li>
                  <a href="#section6">6. Security</a>
                </li>
                <li>
                  <a href="#section7">7. Data Retention</a>
                </li>
                <li>
                  <a href="#section8">8. International Data Transfers</a>
                </li>
                <li>
                  <a href="#section9">9. Changes to our Privacy Policy</a>
                </li>
                <li>
                  <a href="#section10">10. Safety of Minors</a>
                </li>
                <li>
                  <a href="#section11">11. Who is my data controller?</a>
                </li>
                <li>
                  <a href="#section12">12. Your rights</a>
                </li>
              </ul>
            </section>
            <section id="section1">
              <h2>1. INTRODUCTION</h2>
              <p>
                Fielder is a Software-as-a-Service (SaaS) system ("Service") for field-service
                franchise businesses, both franchisors and their franchisees. Fielder is owned and
                operated by CodeSwyft, LLC ("CodeSwyft", "our", "we", or "us"). Our goal is to
                streamline operations and communications between franchisors and franchisees and
                help franchisees operate their businesses as efficiently as possible.
              </p>
              <p>
                At CodeSwyft, we view privacy as a key part of the value that we deliver to our
                customers. This policy describes how CodeSwyft may collect, use, process, disclose,
                and safeguard information we obtain through the Service and in connection with our
                customer, partner, and vendor relationships. It also informs you about rights and
                responsibilities you have regarding your personal information, in association with
                your use of the Service.
              </p>
            </section>
            <section id="section2">
              <h2>2. Information we collect</h2>
              <p>
                When you visit our websites or use our Service, we may collect the following
                personal data about you:
              </p>
              <p>
                <ul>
                  <li>
                    <div css={classes.listTerm}>Contact information</div>
                    You might provide us with your contact information, whether through use of our
                    Service, a form on our website, or an interaction with our sales or customer
                    support team. Your Fielder username is your email address. We ask that you
                    optionally provide other information about yourself, such your name, cell phone
                    number, and job title.
                  </li>
                  <li>
                    <div css={classes.listTerm}>Usage information</div>
                    We record when you log in to the Service and may keep an audit log of data that
                    you create or modify. For example, one helpful feature in Fielder is the Job
                    Log, which shows a history of all the actions taken to service a Job. This helps
                    your team see who did what and when.
                  </li>
                  <li>
                    <div css={classes.listTerm}>Device and browser data</div>
                    We may collect information about the device and application you use to access
                    the Service. Device data mainly means your IP address, operating system version,
                    device type, device ID/MAC address, system and performance information, and
                    browser type. If you are on a mobile device we also collect the UUID for that
                    device, and we may collect your device's physical location with your consent in
                    order to provide our dispatching feature.
                  </li>
                  <li>
                    <div css={classes.listTerm}>Information from page tags</div>
                    We may use Google Analytics or other third party tools to help analyze how
                    people people use the Service. These tools typically use cookies and similar
                    technologies to collect and analyze information about use of the website and
                    report on activities and trends. You can learn about Google’s practices by going
                    to https://www.google.com/policies/privacy/partners/, and opt out of them by
                    downloading the Google Analytics opt-out browser add-on, available at
                    https://tools.google.com/dlpage/gaoptout.
                  </li>
                </ul>
              </p>
            </section>
            <section id="section3">
              <h2>3. How we use the information we collect</h2>
              <p>
                We process personal data about you where:
                <ul>
                  <li>You have consented</li>
                  <li>
                    We need to fulfill our contractual responsibility to deliver the Service to you;
                    or
                  </li>
                  <li>
                    We have a legitimate interest to improve the Service experience or develop new
                    products and Service features
                  </li>
                </ul>
              </p>
              <p>
                We may use the personal data we have about you to
                <ul>
                  <li>
                    <div css={classes.listTerm}>Communicate with you</div>
                    We may provide you with information you've requested from us or information we
                    are required to send you. We may send you operational communications, such as
                    changes to our Service, security updates, or assistance with using the Service.
                    We may send you marketing communications about Fielder or another CodeSwyft
                    product or service we think you might be interested in, in accordance with your
                    marketing preferences. We may as you for feedback or to take part in research we
                    are conducting (which we may engage a third party to assist with).
                  </li>
                  <li>
                    <div css={classes.listTerm}>Support you</div>
                    We may need to assist you with the resolution of technical support issues
                    relating to the Service, whether by email, phone, in-app support, or otherwise.
                  </li>
                  <li>
                    <div css={classes.listTerm}>Enhance our Service and develop new features</div>
                    As mentioned above, we may use analysis tools to help identify parts of the
                    Service that people aren't using quite like we expected (for example, perhaps a
                    feature turns out not to be as useful as we thought). We might use these tools
                    to help identify ways to improve the Service to make it work better for you.
                  </li>
                  <li>
                    <div css={classes.listTerm}>Market to you</div>
                    In addition to sending you marketing communications, we may also use your
                    personal data to display targeted advertising to you online – through our own
                    websites and services or through third party websites and their platforms.
                  </li>
                  <li>
                    <div css={classes.listTerm}>Analyze, Aggregate, and Report</div>
                    We may use the personal data we collect about you and other users of our
                    websites and services (whether obtained directly or from third parties) to
                    produce aggregated and anonymised analytics and reports, which we may share
                    publicly or with third parties.
                  </li>
                </ul>
              </p>
              <p>
                A special mention about the Fielder mobile app: part of the Service's "Dispatch"
                feature is to help dispatchers route field technicians to nearby job sites. It helps
                the dispatcher to be able to see where all the field technicians are in real time.
                The Fielder mobile app will ask your permission to track your location while you are
                using the app. If you consent, your location will be sent to the Service at regular
                intervals and made available to a dispatcher - again, only while you are using the
                app. We do not use your location information for any other purpose, and we will not
                retain your location history for any longer than 24 hours.
              </p>
            </section>
            <section id="section4">
              <h2>4. Information you share</h2>
              <p>
                The Service facilitates communication between you and other members of your
                organization. You may also use the service to communicate with outside parties such
                as your customers or suppliers. Your name, email address, or cell phone number may
                be disclosed to those outside parties.
              </p>
            </section>
            <section id="section5">
              <h2>5. Information we share</h2>
              <p>
                We do not share your information or data with third parties outside Fielder except
                in the following limited circumstances:
                <ul>
                  <li>
                    Your organization's administrators will be able to view your account data,
                    suspend or terminate your account, or restrict your access privileges.
                  </li>
                  <li>
                    To help us provide certain aspects of our services we use our affiliates and
                    trusted key partners – in particular, we engage third parties to:
                    <ul>
                      <li>
                        Facilitate email communication between you and your customers and
                        associates. For email delivery, we use{" "}
                        <a href="https://www.mailgun.com/">Mailgun</a>.
                      </li>
                      <li>
                        Facilitate text message (SMS) communication between you and your customers
                        and associates. For text/SMS delivery, we use{" "}
                        <a href="https://www.twilio.com/">Twilio</a>.
                      </li>
                      <li>
                        Process subscription payments. We use{" "}
                        <a href="https://www.stripe.com/">Stripe</a> to process payments. Note that
                        when you submit your name and credit card information to pay a Service
                        subscription, that information goes directly to Stripe. We do not store your
                        credit card information in our own databases.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
              <p>
                Note that no one (not even CodeSwyft administrators) will be able to view your
                account password. All passwords are stored using a secure one-way hash function. In
                other words, they're encrypted and they can't be unencrypted.
              </p>
              <p>
                In no case do we sell, share or rent out your information to third parties, nor use
                it for any purpose other than those set forth in this policy.
              </p>
              <p>
                In certain situations, we may be required to disclose personal data in response to
                lawful requests by public authorities or regulatory bodies, including to meet law
                enforcement requirements, in the case of a court order, a summons to appear in court
                or any other similar requisition from a government or the judiciary, or to establish
                or defend a legal application. Additionally, we will provide information to a third
                party in the event of any reorganization, merger, sale, joint venture, assignment,
                transfer or other disposition of all or any portion of our business, assets or stock
                (including in connection with any bankruptcy or similar proceedings).
              </p>
            </section>
            <section id="section6">
              <h2>6. Security</h2>
              <p>
                The security and integrity of your personal information is very important to us. We
                follow industry accepted standards to protect the personal information submitted to
                us, both during transmission and once it is received. We ensure the appropriate
                electronic, physical and managerial procedures are in place with a view to
                safeguarding and preserving all the data handled. Our infrastructure is located in
                top-tier data centers. Each of these locations adhere to strict physical and
                procedural controls which are frequently audited.
              </p>
            </section>
            <section id="section7">
              <h2>7. Data retention</h2>
              <p>
                The length of time we keep your personal data depends on what it is and whether we
                have an ongoing business need to retain it (for example, to provide you with a
                service you’ve requested or to comply with applicable legal requirements). We’ll
                retain your personal data for as long as we have a relationship with you and for a
                period of time afterwards where we have an ongoing business need to retain it, in
                accordance with our data retention policies and practices. Following that period,
                we’ll make sure it’s deleted or anonymised.
              </p>
            </section>
            <section id="section8">
              <h2>8. International Data Transfers</h2>
              <p>
                Our services are global and account data (including your personal and other
                information) may be stored and processed in any country where we have operations or
                where we engage service providers, and we may transfer your information to countries
                outside of your country of residence, which may have data protection rules that are
                different to those of your country. In these cases we will take measures to ensure
                that any such transfers, and your information and account data, remain protected.
              </p>
            </section>
            <section id="section9">
              <h2>9. Changes to our Privacy Policy</h2>
              <p>
                The information provided in this policy may be modified to address new issues or
                changes. If we make significant changes, we may notify you by other means (for
                instance, by email or with a banner on the website) prior to the change becoming
                effective. Any changes we make will take effect 30 days after the update date noted
                above.
              </p>
            </section>
            <section id="section10">
              <h2>10. Safety of Minors</h2>
              <p>
                Protecting the privacy of young children is especially important. Our Service is not
                directed to children under the age of 16, and we do not knowingly collect Personal
                Information from children under the age of 16 without obtaining parental consent. If
                you are under 16 years of age, please do not use or access the Service at any time
                or in any manner. If we learn that Personal Information has been collected on the
                Service from persons under 16 years of age and without verifiable parental consent,
                then we will take the appropriate steps to delete this information. If you are a
                parent or guardian and discover that your child under 16 years of age has obtained
                an account on the Service, then you may alert us at privacy@fielderapp.com and
                request that we delete that child’s Personal Information from our systems.
              </p>
            </section>
            <section id="section11">
              <h2>11. Who is my data controller?</h2>
              <p>
                When collecting information about our customers or visitors of our website, we are,
                under European Union (“EU") data protection laws, qualified as “data controller".
                This means that we are responsible for deciding how we hold and use personal data
                about you.
              </p>
            </section>
            <section id="section12">
              <h2>12. Your rights</h2>
              <p>
                It’s your personal data and you have certain rights relating to it. When it comes to
                marketing communications, you can ask us not to send you these at any time – just
                follow the unsubscribe instructions contained in the marketing communication, or
                send your request to{" "}
                <a href="mailto:privacy@fielderapp.com">privacy@fielderapp.com</a>.
              </p>
              <p>
                You also have rights to:
                <ul>
                  <li>
                    know what personal data we hold about you, and to make sure it’s correct and up
                    to date
                  </li>
                  <li>
                    request a copy of your personal data, or ask us to restrict processing your
                    personal data or delete it
                  </li>
                  <li>object to our continued processing of your personal data</li>
                </ul>
                You can exercise these rights at any time by sending an email to
                <a href="mailto:privacy@fielderapp.com">privacy@fielderapp.com</a>.
              </p>
              <p>
                If you’re not happy with how we are processing your personal data, please let us
                know by sending an email to{" "}
                <a href="mailto:privacy@fielderapp.com">privacy@fielderapp.com</a>. We will review
                and investigate your complaint, and try to get back to you within a reasonable time
                frame. You can also complain to your local data protection authority. They will be
                able to advise you how to submit a complaint.
              </p>
            </section>
          </div>
        </div>
      </main>
    </div>
  )
}

const classes = {
  root: {
    backgroundColor: "#fff",
  },
  header: {
    backgroundColor: "#212121",
  },
  logoContainer: {
    width: "16.625rem",
    padding: "1rem",
    paddingBottom: "0.5rem",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },
  content: {
    textAlign: "left",
    padding: "1.875rem",
    paddingTop: "3.125rem",
    maxWidth: 1000,
    letterSpacing: 0,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    margin: "0 0 1rem",

    "& h1": {
      marginBottom: "2.5rem",
      fontSize: "1.5rem",
    },

    "& h2": {
      marginTop: "2.5rem",
    },

    "& h3": {
      marginTop: "1.875rem",
    },

    "& dd": {
      marginBottom: "0.75rem",
    },

    "& ul": {
      "& li": {
        marginBottom: "0.5rem",
      },
    },
  },
  tableOfContents: {
    listStyle: "none",
  },
  subsectionHeader: {
    textDecoration: "underline",
  },
  definedTerm: {
    fontWeight: "bold",
  },
  listTerm: {
    fontWeight: "bold",
  },
} as const

export default PrivacyPage
