/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from "react"
import { Navigate, useLocation, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined"
import Skeleton from "@mui/material/Skeleton"

import EmptyState from "~/components/EmptyState"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import SearchField from "~/components/SearchField"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import SortableTableHeader from "~/components/SortableTableHeader"
import TablePaginationActions from "~/components/TablePaginationActions"
import {
  isBlank,
  PRODUCT_ORDERS_HQ,
  NOT_SPECIFIED,
  formatMoney,
  formatDate,
  useDebounce,
} from "~/util"
import { useAuth } from "~/context/AuthContext"
import { DefaultPermission, ProductOrder, ProductOrderStatus, Snack, User } from "~/types"
import FielderIconButton from "~/components/FielderIconButton"
import UserSelect from "~/components/UserSelect"
import useStore, {
  filterOrdersByAccountManagerIdSelector,
  setFilterOrdersByAccountManagerIdSelector,
  showArchivedProductOrdersToggleSelector,
  setShowArchivedProductOrdersToggleSelector,
} from "~/store"
import ProductOrderStatusPill from "~/components/ProductOrderStatusPill"

const ALL_PRODUCT_ORDERS = gql`
  query AllProductOrders(
    $filter: String
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderOrganizationId: ID
    $statuses: [ProductOrderStatus]
    $showArchived: Boolean
    $updatedSinceDate: LocalDateTime
  ) {
    allProductOrders(
      input: {
        filter: $filter
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        last: $last
        after: $after
        before: $before
      }
      filter: {
        orderOrganizationId: $orderOrganizationId
        statuses: $statuses
        showArchived: $showArchived
        updatedSinceDate: $updatedSinceDate
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          number
          status
          currencyCode
          dateSubmitted
          total
          organization {
            id
            tradeName
          }
        }
      }
    }
  }
`

const NUM_COLUMNS = 6

function FranchisorProductOrderListPage() {
  const { t } = useTranslation()
  const location = useLocation()
  const { hasPermissions, user } = useAuth()
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [filter, setFilter] = useState<string>("")
  const [after, setAfter] = useState<number | null>()
  const [before, setBefore] = useState<number | null>()
  const [first, setFirst] = useState<number | null>(10)
  const [last, setLast] = useState<number | null>()
  const [sortBy, setSortBy] = useState<string>("number")
  const [sortDir, setSortDir] = useState<"DESC" | "ASC">("DESC")
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const filterOrdersByAccountManagerId = useStore(filterOrdersByAccountManagerIdSelector)
  const setFilterOrdersByAccountManagerId = useStore(setFilterOrdersByAccountManagerIdSelector)
  const showArchivedProductOrdersToggle = useStore(showArchivedProductOrdersToggleSelector)
  const setShowArchivedProductOrdersToggle = useStore(setShowArchivedProductOrdersToggleSelector)
  const debouncedSearchTerm = useDebounce(filter, 500)

  const { loading, error, data } = useQuery(ALL_PRODUCT_ORDERS, {
    variables: {
      filter: debouncedSearchTerm,
      after,
      before,
      first,
      last,
      sortBy,
      sortDir,
      showArchived: showArchivedProductOrdersToggle,
      statuses: [
        ProductOrderStatus.SUBMITTED,
        ProductOrderStatus.CANCELED,
        ProductOrderStatus.IN_PROGRESS,
        ProductOrderStatus.SHIPPING,
        ProductOrderStatus.INVOICING,
        ProductOrderStatus.COMPLETED,
      ],
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: "ALL_PRODUCT_ORDERS",
      debounceTimeout: 50,
    },
  })

  function onGoToFirstPage() {
    setAfter(null)
    setBefore(null)
    setFirst(rowsPerPage)
    setLast(null)
  }

  function onGoToLastPage() {
    setAfter(null)
    setBefore(null)
    setFirst(null)
    setLast(rowsPerPage)
  }

  function onGoToPrevious() {
    setAfter(null)
    setBefore(pageInfo.startCursor)
    setFirst(null)
    setLast(rowsPerPage)
  }

  function onGoToNext() {
    setAfter(pageInfo.endCursor)
    setBefore(null)
    setFirst(rowsPerPage)
    setLast(null)
  }

  function handleChangeRowsPerPage(event: any) {
    const newPageSize = +event.target.value
    setRowsPerPage(newPageSize)
    setPage(0)
    setAfter(null)
    setBefore(null)
    setFirst(newPageSize)
    setLast(null)
  }

  function sort(propertyName: string) {
    const newSortBy = propertyName
    const newSortDir = propertyName !== sortBy ? "ASC" : sortDir === "ASC" ? "DESC" : "ASC"
    setSortBy(newSortBy)
    setSortDir(newSortDir)
    setPage(0)
    setAfter(null)
    setBefore(null)
    setFirst(rowsPerPage)
    setLast(null)
  }

  const handleSearchChange = (val: string) => {
    setFilter(val)
    setPage(0)
    setAfter(null)
    setBefore(null)
    setFirst(rowsPerPage)
    setLast(null)
  }

  function handleRowClick(id: string) {
    setRedirectTo({ to: `/app/orders/hq/edit/${id}`, replace: false })
  }

  const pageInfo = data?.allProductOrders?.pageInfo || {}
  const orders = data?.allProductOrders?.edges
    ?.map((e) => e.node)
    ?.filter((node) => {
      return filterOrdersByAccountManagerId
        ? node.organization.accountManager?.id === filterOrdersByAccountManagerId
        : true
    })

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(PRODUCT_ORDERS_HQ.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={PRODUCT_ORDERS_HQ}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                marginRight: "80px", // make sure the intercom chat bubble doesn't block the pagination controls
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader icon={PRODUCT_ORDERS_HQ.icon} leafTitleKey={PRODUCT_ORDERS_HQ.titleKey} />
          <Box
            sx={(theme) => ({
              marginBottom: "20px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              [theme.breakpoints.down(600)]: {
                width: "100%",
                flexDirection: "column",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                flex: 3,
                [theme.breakpoints.down(800)]: {
                  width: "100%",
                  flexDirection: "column",
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  flex: 1,
                  maxWidth: "1000px",
                  [theme.breakpoints.down(600)]: {
                    width: "100%",
                    flexDirection: "column",
                  },
                })}
              >
                <Box
                  sx={{
                    flex: 3,
                  }}
                >
                  <SearchField
                    onChange={handleSearchChange}
                    placeholder={t("searchOrders")}
                    sx={{ borderRadius: "4px", height: "100%" }}
                    term={filter}
                    testID="SearchField"
                  />
                </Box>
                <Box
                  sx={{
                    flex: 2,
                  }}
                >
                  <UserSelect
                    aria-label={t("accountManager") as string}
                    label={t("accountManager") as string}
                    name="accountManager"
                    onChange={(selectedUser: User | null) => {
                      setFilterOrdersByAccountManagerId(selectedUser?.id ?? null)
                    }}
                    roleNames={["ACCOUNT_MANAGER_LEVEL_1"]}
                    selectedUser={{ id: filterOrdersByAccountManagerId } as User}
                  />
                </Box>
                <Box sx={{ alignSelf: "center", marginLeft: "2rem" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(showArchivedProductOrdersToggle)}
                        onChange={(event, checked) => {
                          setShowArchivedProductOrdersToggle(checked)
                        }}
                        value="showArchived"
                      />
                    }
                    label={t("showArchivedProductOrders") as string}
                    sx={{
                      marginRight: "1.5rem",
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "center",
                justifyContent: "flex-end",
                flex: 1,
              }}
            >
              <Button
                onClick={() => setRedirectTo({ to: "/app/orders/hq/board", replace: false })}
                style={{ marginRight: "0.5rem" }}
              >
                {t("switchToBoardView")}
              </Button>
            </Box>
          </Box>
          <Paper sx={classes.tableWrapper}>
            <TableContainer sx={classes.tableContainer}>
              <Table sx={[classes.table, orders?.length == 0 && { height: "100%" }]}>
                <TableHead>
                  <TableRow>
                    <SortableTableHeader
                      isActiveSort={sortBy === "number"}
                      label={t("orderNumber")}
                      onClick={() => sort("number")}
                      sortDir={sortDir}
                      sx={classes.tableHead}
                      width="15%"
                    />
                    <SortableTableHeader
                      isActiveSort={sortBy === "status"}
                      label={t("status")}
                      onClick={() => sort("status")}
                      sortDir={sortDir}
                      sx={classes.tableHead}
                      width="15%"
                    />
                    <SortableTableHeader
                      align="left"
                      isActiveSort={sortBy === "total"}
                      label={t("total")}
                      onClick={() => sort("total")}
                      sortDir={sortDir}
                      sx={classes.tableHead}
                      width="15%"
                    />
                    <SortableTableHeader
                      isActiveSort={sortBy === "dateSubmitted"}
                      label={t("dateSubmitted")}
                      onClick={() => sort("dateSubmitted")}
                      sortDir={sortDir}
                      sx={classes.tableHead}
                      width="20%"
                    />
                    <SortableTableHeader
                      align="left"
                      isActiveSort={sortBy === "organization"}
                      label={t("organization")}
                      onClick={() => sort("organization")}
                      sortDir={sortDir}
                      sx={classes.tableHead}
                      width="25%"
                    />
                    <TableCell sx={classes.tableHead} width="10%">
                      {" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={orders?.length == 0 ? { height: "100%" } : {}}>
                  {loading ? (
                    [...Array(rowsPerPage).keys()].map((i) => (
                      <TableRow key={i} sx={classes.dataRow}>
                        {[...Array(NUM_COLUMNS).keys()].map((j) => (
                          <TableCell key={j}>
                            <Skeleton variant="text" />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : error ? (
                    <TableRow>
                      <TableCell align="center" colSpan={NUM_COLUMNS}>
                        <Box
                          style={{
                            color: "rgb(244, 67, 54)",
                            fontWeight: 500,
                            fontSize: "1.25rem",
                            lineHeight: 1.6,
                          }}
                        >
                          {t("errorLabel")}
                          {": "}
                          {error.message}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : orders?.length === 0 ? (
                    <TableRow style={{ height: "100%", verticalAlign: "top" }}>
                      <TableCell align="center" colSpan={NUM_COLUMNS} style={{ height: "100%" }}>
                        <Box style={{ display: "flex", height: "100%" }}>
                          {isBlank(filter) ? (
                            <EmptyState
                              title={t("page.productOrderList.emptyState.franchisor.title")}
                            >
                              <Box>{t("page.productOrderList.emptyState.franchisor.message")}</Box>
                            </EmptyState>
                          ) : (
                            <EmptyState title={t("page.productOrderList.noMatchingResults.title")}>
                              <Box>{t("page.productOrderList.noMatchingResults.message")}</Box>
                            </EmptyState>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    orders?.map((order: ProductOrder) => {
                      return (
                        <TableRow
                          key={order.id}
                          onClick={() => {
                            if (hasPermissions?.([DefaultPermission.UpdateProductOrder])) {
                              handleRowClick(order.id)
                            }
                          }}
                          sx={classes.dataRow}
                        >
                          <TableCell>{order.number}</TableCell>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <ProductOrderStatusPill status={order.status} />
                            </Box>
                          </TableCell>
                          <TableCell align="left">
                            {formatMoney(order.currencyCode, order.total)}
                          </TableCell>
                          <TableCell>
                            {order.dateSubmitted
                              ? formatDate(
                                  order.dateSubmitted,
                                  t("format:dateFormat.shortWithTime"),
                                  user?.organization?.timeZone,
                                  user
                                )
                              : NOT_SPECIFIED}
                          </TableCell>
                          <TableCell>{order.organization.tradeName}</TableCell>
                          <TableCell align="right">
                            {hasPermissions?.([DefaultPermission.ReadOrganizationItem]) ? (
                              <FielderIconButton
                                aria-label={t("viewOrder") as string}
                                title={t("viewOrder") as string}
                              >
                                <VisibilityIcon />
                              </FielderIconButton>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              ActionsComponent={() => (
                <TablePaginationActions
                  hasNextPage={pageInfo.hasNextPage ?? false}
                  hasPreviousPage={pageInfo.hasPreviousPage ?? false}
                  onGoToFirstPage={onGoToFirstPage}
                  onGoToLastPage={onGoToLastPage}
                  onGoToNext={onGoToNext}
                  onGoToPrevious={onGoToPrevious}
                />
              )}
              component="div"
              count={-1}
              labelDisplayedRows={() => ``}
              labelRowsPerPage={t("rowsPerPage") as string}
              onPageChange={() => {}}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
          </Paper>
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  tableWrapper: {
    overflowX: "auto",
  },
  tableContainer: {
    maxHeight: "calc(75vh)",
    height: "calc(75vh - 50px)",
  },
  table: {
    width: "100%",
    tableLayout: "fixed",
  },
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
  },
  dataRow: {
    height: "3.75rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f8f8f8",
    },
  },
}

export default FranchisorProductOrderListPage
