/* eslint-disable react/no-unstable-nested-components */
import React from "react"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { ResponsiveBar } from "@nivo/bar"
import { linearGradientDef } from "@nivo/core"
import Tooltip from "../Tooltip"
import numeral from "numeral"
import { DashboardWidgetDataEntry } from "../../types"

function CustomTick({ x, y, value }: { x: number; y: number; value: string }): JSX.Element {
  return (
    <g transform={`translate(${x},${y + 8})`}>
      <text
        dominantBaseline="middle"
        style={{
          fontSize: "0.625rem",
          fontWeight: "regular",
          color: "#43425D80",
        }}
        textAnchor="middle"
      >
        {value.charAt(0)}
      </text>
    </g>
  )
}

function EmptyTick({ x, y, value }: { x: number; y: number; value: string }): JSX.Element {
  return (
    <g transform={`translate(${x},${y + 8})`}>
      <text
        dominantBaseline="middle"
        style={{
          fontSize: "0.625rem",
          fontWeight: "regular",
          color: "#43425D80",
        }}
        textAnchor="middle"
      >
        {" "}
      </text>
    </g>
  )
}

interface MetricWidgetProps {
  chartColor?: string
  data: DashboardWidgetDataEntry[]
  helpText: string
  id?: string
  percentChange?: number
  showGraph?: boolean
  showTicks?: boolean
  summaryFigure?: string
  title: string
}

function MetricWidget({
  chartColor = "#018B1B",
  data,
  helpText,
  id,
  percentChange = 0,
  showGraph = true,
  showTicks = true,
  summaryFigure = "0",
  title,
}: MetricWidgetProps) {
  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "1rem",
    "&:last-of-type": {
      marginBottom: 0,
    },
  } as const

  return (
    <Paper
      data-testid={id}
      id={id}
      sx={{
        width: "100%",
        minWidth: 220,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0.75rem",
        }}
      >
        <Box sx={rowStyle}>
          <Box
            sx={{
              fontSize: "1.125rem",
              color: "#43425DA9",
            }}
          >
            {title}
          </Box>
          <Box>
            <Tooltip arrow enterDelay={300} placement="bottom" title={helpText}>
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </Box>
        </Box>
        <Box sx={rowStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "1.875rem",
                color: "#4D4F5C",
              }}
            >
              {summaryFigure}
            </Box>
            {Math.abs(percentChange) >= 0 && (
              <Box
                sx={{
                  fontSize: "0.9375rem",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  color: percentChange > 0 ? "#018B1B" : percentChange < 0 ? "#CB2020" : "#000",
                }}
              >
                {percentChange > 0 && <ArrowUpwardIcon fontSize="small" />}
                {percentChange < 0 && <ArrowDownwardIcon fontSize="small" />}
                <span>{numeral(percentChange).format("0,0%")}</span>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              height: 68,
              width: "50%",
              maxWidth: "9.375rem",
            }}
          >
            {data?.length > 0 && showGraph && (
              <ResponsiveBar
                animate
                axisBottom={{
                  renderTick: showTicks ? CustomTick : EmptyTick,
                }}
                axisLeft={null}
                axisRight={null}
                axisTop={null}
                colors={chartColor}
                data={data}
                defs={[
                  linearGradientDef("gradientA", [
                    { offset: -120, color: "inherit", opacity: 0.7 },
                    { offset: 120, color: "inherit", opacity: 0.3 },
                  ]),
                ]}
                enableGridX={false}
                enableGridY={false}
                enableLabel={false}
                fill={[{ match: "*", id: "gradientA" }]}
                indexBy="label"
                layout="vertical"
                margin={{ top: 0, right: 0, bottom: 12, left: 0 }}
                minValue={0}
                motionConfig="gentle"
                padding={0.1}
                tooltip={({ data }) => {
                  return (
                    <Box>
                      <span style={{ fontWeight: "bold" }}>{data.label}</span>
                      {": "}
                      <span>{data.value}</span>
                    </Box>
                  )
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default MetricWidget
