import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import InfoIcon from "@mui/icons-material/Info"
import CloseIcon from "@mui/icons-material/Close"
import green from "@mui/material/colors/green"
import amber from "@mui/material/colors/amber"
import IconButton from "@mui/material/IconButton"
import Snackbar from "@mui/material/Snackbar"
import SnackbarContent from "@mui/material/SnackbarContent"
import WarningIcon from "@mui/icons-material/Warning"
import { Snack } from "../types"

const variantIcon = {
  error: ErrorIcon,
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: WarningIcon,
}

interface Props {
  snack: Snack
  onClose?: () => void
  testID?: string
}

function SnackbarMessage({ snack, onClose, testID, ...other }: Props): JSX.Element {
  const [open, setOpen] = useState<boolean>(true)
  const { t } = useTranslation()
  const Icon = variantIcon[snack.variant]

  const handleClose = (reason?: string): void => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
    onClose?.()
  }

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      autoHideDuration={6000}
      data-testid={testID}
      onClose={() => handleClose()}
      open={open}
    >
      <SnackbarContent
        action={[
          <IconButton
            aria-label={t("close")}
            color="inherit"
            key="close"
            onClick={(e) => handleClose()}
          >
            <CloseIcon sx={{ fontSize: "1.25rem" }} />
          </IconButton>,
        ]}
        aria-describedby="client-snackbar"
        message={
          <span
            css={{
              alignItems: "center",
              display: "flex",
              maxWidth: "500px",
            }}
            id="client-snackbar"
          >
            <Icon
              sx={{
                fontSize: "1.25rem",
                marginRight: (theme) => theme.spacing(1),
                opacity: 0.9,
              }}
            />
            {t(snack.messageKey, snack.messageOptions)}
          </span>
        }
        sx={[
          snack.variant === "error" && {
            backgroundColor: (theme) => theme.palette.error.dark,
          },
          snack.variant === "info" && {
            backgroundColor: (theme) => theme.palette.primary.dark,
          },
          snack.variant === "success" && {
            backgroundColor: green[600],
          },
          snack.variant === "warning" && {
            backgroundColor: amber[700],
          },
        ]}
        {...other}
      />
    </Snackbar>
  )
}

export default SnackbarMessage
