import gql from "graphql-tag"
import FullOrganizationItem from "./fragments/FullOrganizationItem"

export const EDIT_ORGANIZATION_ITEM = gql`
  mutation EditOrganizationItem(
    $id: ID!
    $code: String
    $name: String
    $description: String
    $category: String
    $isActive: Boolean
    $isTracked: Boolean
    $isIntendedForResale: Boolean
    $stockType: StockType
    $msrp: BigDecimal
    $wholesalePrice: BigDecimal
    $leadTimeDays: Int
    $palletQty: Int
    $packSize: Int
    $isPackBreakable: Boolean
    $qtyOnHand: Int
    $reorderPoint: Int
    $reorderQty: Int
    $isDynamicallyPriced: Boolean
    $unitSalePrice: BigDecimal
    $unitSalePriceMarkup: BigDecimal
    $components: [OrganizationItemBundleComponentInput!]
    $showComponentsOnTransactions: Boolean
  ) {
    editOrganizationItem(
      input: {
        id: $id
        code: $code
        name: $name
        description: $description
        category: $category
        isActive: $isActive
        isTracked: $isTracked
        isIntendedForResale: $isIntendedForResale
        stockType: $stockType
        msrp: $msrp
        wholesalePrice: $wholesalePrice
        leadTimeDays: $leadTimeDays
        palletQty: $palletQty
        packSize: $packSize
        isPackBreakable: $isPackBreakable
        qtyOnHand: $qtyOnHand
        reorderPoint: $reorderPoint
        reorderQty: $reorderQty
        isDynamicallyPriced: $isDynamicallyPriced
        unitSalePrice: $unitSalePrice
        unitSalePriceMarkup: $unitSalePriceMarkup
        components: $components
        showComponentsOnTransactions: $showComponentsOnTransactions
      }
    ) {
      organizationItem {
        ...FullOrganizationItem
      }
    }
  }
  ${FullOrganizationItem}
`
