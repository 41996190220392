import gql from "graphql-tag"
import FullOrganizationItem from "./fragments/FullOrganizationItem"

export const GET_ORGANIZATION_ITEM_BY_ID = gql`
  query GetOrganizationItemById($id: ID!) {
    getOrganizationItemById(id: $id) {
      ...FullOrganizationItem
    }
  }
  ${FullOrganizationItem}
`