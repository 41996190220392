import React from "react"

interface FileTypeIconProps {
  readonly fileName?: string
  readonly style?: React.CSSProperties
}

export function FileTypeIcon({ fileName, ...rest }: FileTypeIconProps) {
  const extension = fileName ? fileName.substring(fileName.lastIndexOf(".") + 1).toUpperCase() : ""

  return (
    <svg
      color="#000000"
      height={24}
      viewBox="0 0 412.23 459.43"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <style>{".prefix__cls-3{fill:#231f20}"}</style>
      </defs>
      <path
        d="M361.82 452.43H48.54V7H264.47l40.71 41.17 56.64 55.76v348.5z"
        fill="#FFFFFF"
        stroke="#231F20"
        strokeMiterlimit={10}
        strokeWidth={14}
      />
      <path
        d="M405.23 356.43H7V212.18h0l40.71-34.51 1.77 30.09 312.49-1.95.37-30.14h0l42.89 38.28v142.48z"
        fill="#231F20"
        stroke="#231F20"
        strokeMiterlimit={10}
        strokeWidth={14}
      />
      <path
        className="prefix__cls-3"
        d="M318.54 36.24c.77 7.53 0 113 0 113H427z"
        transform="translate(-59.46 -29.71)"
      />
      <text
        fill="#FFFFFF"
        fontFamily="ArialMT,Arial"
        fontSize={90}
        stroke="#FFFFFF"
        strokeMiterlimit={10}
        textAnchor="middle"
        transform="matrix(1.28 0 0 1 80.75 307.71)"
        x="25%"
      >
        {extension}
      </text>
    </svg>
  )
}
