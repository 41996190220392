import React, { useState } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import Box from "@mui/material/Box"
import JobBoardStepColumn from "./JobBoardStepColumn"
import { Job, JobStatus } from "../../../types"

interface JobBoardColumn {
  jobStatus: JobStatus
  jobs: Job[]
}

interface Props {
  readonly columns: JobBoardColumn[]
  readonly onJobDragEnd: (
    jobId: string,
    startStepId: string,
    endStepId: string,
    startIndex: number,
    endIndex: number
  ) => void
}

function JobBoard({ columns, onJobDragEnd }: Props) {
  const [droppableColumnIds, setDroppableColumnIds] = useState([])

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { destination, source, draggableId } = result
        setDroppableColumnIds([])
        if (!destination) {
          return
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
          return
        }

        const startStepId = source.droppableId
        const endStepId = destination.droppableId
        const jobId = draggableId

        onJobDragEnd(jobId, startStepId, endStepId, source.index, destination.index)
      }}
      onDragStart={(dragArg) => {
        const sourceColumn = columns.find((c) => c.id === dragArg.source.droppableId)
        const dests = [sourceColumn.id].concat(sourceColumn?.destinationStepIds)
        setDroppableColumnIds(dests)
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          maxWidth: "100%",
          overflowX: "auto",
          height: "calc(100vh - 170px)",
          padding: "0.625rem",
          paddingBottom: "3.125rem",
        }}
      >
        {columns.map((step) => {
          const isDropDisabled =
            droppableColumnIds.length > 0 && !droppableColumnIds.includes(step.id)
          return <JobBoardStepColumn isDropDisabled={isDropDisabled} key={step.id} step={step} />
        })}
      </Box>
    </DragDropContext>
  )
}

export default JobBoard
