import gql from "graphql-tag"

export default gql`
  fragment FullNotification on Notification {
    id
    type {
      id
      name
      notificationCategory
    }
    channel
    title
    message
    callToActionUri
    callToActionText
    dateRead
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
  }
`
