import gql from "graphql-tag"

export const ALL_ESTIMATE_REQUESTS = gql`
  query AllEstimateRequests(
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: String
  ) {
    allEstimateRequests(
      input: {
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        after: $after
        last: $last
        before: $before
        filter: $filter
      }
    ) {
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          isArchived
          address {
            addressString
          }
          attachments {
            id
            objectName
            name
            signedUrl
          }
          businessName
          description
          email
          firstName
          lastName
          phoneNumber
          status
          customerType
          desiredCompletionDate
          createdAt
        }
      }
    }
  }
`
