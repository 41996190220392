import gql from "graphql-tag"
import FullItem from "./fragments/FullItem"

export const EDIT_ITEM = gql`
  mutation EditItem(
    $id: ID!
    $code: String!
    $name: String!
    $description: String
    $category: String
    $isArchived: Boolean!
    $isIntendedForResale: Boolean!
    $isCancelable: Boolean!
    $stockType: StockType
    $msrp: BigDecimal
    $wholesalePrice: BigDecimal
    $wholesalePriceExpirationDate: LocalDateTime
    $leadTimeDays: Int
    $palletQty: Int
    $packSize: Int
    $isPackBreakable: Boolean
    $isRestricted: Boolean
    $restrictedToOrganizationIds: [ID!]
  ) {
    editItem(
      input: {
        id: $id
        code: $code
        name: $name
        description: $description
        category: $category
        isArchived: $isArchived
        isIntendedForResale: $isIntendedForResale
        isCancelable: $isCancelable
        stockType: $stockType
        msrp: $msrp
        wholesalePrice: $wholesalePrice
        wholesalePriceExpirationDate: $wholesalePriceExpirationDate
        leadTimeDays: $leadTimeDays
        palletQty: $palletQty
        packSize: $packSize
        isPackBreakable: $isPackBreakable
        isRestricted: $isRestricted
        restrictedToOrganizationIds: $restrictedToOrganizationIds
      }
    ) {
      item {
        ...FullItem
      }
    }
  }
  ${FullItem}
`

