import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"

interface CreateJobButtonProps {
  disabled?: boolean
  onClick?: () => void
}

function CreateJobButton({ disabled = false, onClick }: CreateJobButtonProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Button
      aria-label={t("createJob")}
      color="primary"
      data-testid="createJobButton"
      disabled={disabled}
      onClick={() => {
        if (onClick) {
          onClick()
        } else {
          navigate("/app/jobs/create")
        }
      }}
      sx={{
        fontWeight: "bold",
        "& svg": {
          fontSize: "1.0rem",
        },
        "& div": {
          marginLeft: "0.625rem",
          marginRight: "0.625rem",
        },
      }}
      variant="contained"
    >
      <AddIcon fontSize="small" />
      <div>{t("createJob")}</div>
    </Button>
  )
}

export default CreateJobButton
