import gql from "graphql-tag"

export const GET_ROLE_BY_ID = gql`
  query GetRoleById($id: ID!) {
    getRoleById(id: $id) {
      id
      name
      description
      isStandard
      permissions {
        id
        group
      }
    }
  }
`
