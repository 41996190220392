import React from "react"
import Box from "@mui/material/Box"
import type { Address } from "~/types/apiTypes"
import { NOT_SPECIFIED } from "~/util"

interface Props {
  readonly address: Partial<Address> | undefined | null
  readonly format: "short" | "medium" | "long"
}

function getStreetAddress(address: Partial<Address>) {
  return `${address.streetNumber} ${address.route}`
}

export function AddressDisplay({ address, format = "long" }: Props): JSX.Element {
  if (!address) {
    return <Box>{NOT_SPECIFIED}</Box>
  }

  if (format === "short" || !address.locality) {
    // "short" is just the street number and street name
    return <Box sx={{ fontSize: "0.75rem" }}>{getStreetAddress(address)}</Box>
  } else if (format === "medium") {
    // "medium" is the street number and street name, plus the city
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.125rem", fontSize: "0.75rem" }}>
        <Box sx={{ fontSize: "0.75rem" }}>
          {address.streetNumber} {address.route}, {address.locality}
        </Box>
      </Box>
    )
  } else {
    // "long" is the street number and street name, plus the city, state/province, and zip
    const cityStateZip = `${address.locality}, ${address.administrativeAreaLevel1} ${address.postalCode}`

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.125rem", fontSize: "0.75rem" }}>
        <Box>{getStreetAddress(address)} </Box>
        <Box> {cityStateZip} </Box>
      </Box>
    )
  }
}
