import React from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { ALL_WORK_ORDERS_FOR_JOB } from "../queries/allWorkOrdersForJob"
import MenuItem from "@mui/material/MenuItem"
import SelectorField from "./SelectorField"
import FieldSpinner from "./FieldSpinner"
import { WorkOrder } from "../types"
import { getJobDocumentDisplayNumber } from "../util"

interface Props {
  readonly disabled?: boolean
  readonly error?: boolean
  readonly filterAssigned?: boolean // filter out work orders that are linked to a JobAssignment
  readonly jobId: string
  readonly label: string
  readonly margin?: "none" | "dense" | undefined
  readonly name: string
  readonly onChange: (selectedWorkOrder: WorkOrder | null) => void
  readonly required?: boolean
  readonly selectedWorkOrder?: WorkOrder | null | undefined
}

function WorkOrderSelect({
  disabled,
  error: hasError,
  filterAssigned = false,
  jobId,
  label,
  margin,
  name,
  onChange,
  required,
  selectedWorkOrder,
}: Props) {
  const { t } = useTranslation()

  const { loading, error, data } = useQuery(ALL_WORK_ORDERS_FOR_JOB, {
    fetchPolicy: "cache-and-network",
    variables: {
      jobId,
      includeArchived: true,
    },
  })

  if (loading) {
    return (
      <div>
        <FieldSpinner message={t("loadingWorkOrders")} />
      </div>
    )
  }

  if (error) return <div>Error! {error.message}</div>

  const workOrders = data?.getJobById?.workOrders as WorkOrder[]
  const workOrderOptions = [
    {
      id: "NONE",
      name: t("none"),
    },
  ].concat(
    workOrders
      .filter((wo: WorkOrder) => {
        if (wo.id === selectedWorkOrder?.id) {
          return true
        } else if (!wo.isArchived) {
          return filterAssigned ? !wo.jobAssignment : true
        }
      })
      .map((workOrder: WorkOrder) => ({
        id: workOrder.id ?? "",
        name: workOrder.description ?? "",
      }))
  )

  return (
    <SelectorField
      disabled={disabled}
      error={hasError}
      label={label}
      margin={margin}
      name={name}
      onChange={(val) => {
        if (val) {
          const selectedWorkOrder = workOrders.find((u) => u.id === val.id) as WorkOrder
          onChange(selectedWorkOrder)
        } else {
          onChange(null)
        }
      }}
      options={workOrderOptions}
      renderOption={(option) => {
        const workOrder = workOrders.find((wo) => wo.id === option.id) as WorkOrder
        const id = workOrder?.id ?? workOrderOptions[0].id
        const displayValue = workOrder
          ? `${t("workOrder")} #${getJobDocumentDisplayNumber(workOrder)}`
          : workOrderOptions[0].name
        return (
          <MenuItem key={id} value={id}>
            <div style={{ display: "flex", flexDirection: "column" }}>{displayValue}</div>
          </MenuItem>
        )
      }}
      renderValue={(selected) => {
        const v = workOrderOptions?.find((o) => o.id === selected)
        let displayValue = t("error.invalidOptionSelected")
        if (v?.id === workOrderOptions[0].id) {
          displayValue = workOrderOptions[0].name
        } else {
          const value = workOrders.find((wo) => wo.id === v.id) as WorkOrder
          displayValue = `${t("workOrder")} #${getJobDocumentDisplayNumber(value)}`
        }

        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>{displayValue}</div>
          </div>
        )
      }}
      required={required}
      value={selectedWorkOrder ? selectedWorkOrder.id : workOrderOptions[0].id}
    />
  )
}

export default React.memo(WorkOrderSelect)
