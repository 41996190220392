import gql from "graphql-tag"

export const CREATE_CONTACT = gql`
  mutation CreateContact(
    $customerId: ID!
    $firstName: String
    $lastName: String
    $jobTitle: String
    $email: String
    $phoneNumber: String
    $canBeJobSiteContact: Boolean
    $canBeBillingContact: Boolean
    $address: AddressInput
  ) {
    createContact(
      input: {
        customerId: $customerId
        firstName: $firstName
        lastName: $lastName
        jobTitle: $jobTitle
        email: $email
        phoneNumber: $phoneNumber
        canBeJobSiteContact: $canBeJobSiteContact
        canBeBillingContact: $canBeBillingContact
        address: $address
      }
    ) {
      contact {
        id
        firstName
        lastName
        jobTitle
        phoneNumber
        email
        address {
          addressString
        }
        canBeJobSiteContact
        canBeBillingContact
        createdAt
      }
    }
  }
`
