import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"
import TaxRateGroupDetails from "./fragments/taxRateGroupDetails"
import FullWorkOrderLineItemDetails from "./fragments/FullWorkOrderLineItemDetails"

export const EDIT_WORK_ORDER = gql`
  mutation EditWorkOrder(
    $id: ID!
    $jobId: ID!
    $jobAssignmentId: ID
    $description: String
    $notes: String
    $discount: DiscountInput
    $billingContact: ContactInput
    $lineItems: [WorkOrderLineItemInput!]
    $isArchived: Boolean
  ) {
    editWorkOrder(
      input: {
        id: $id
        jobId: $jobId
        jobAssignmentId: $jobAssignmentId
        description: $description
        notes: $notes
        discount: $discount
        billingContact: $billingContact
        lineItems: $lineItems
        isArchived: $isArchived
      }
    ) {
      workOrder {
        id
        number
        billingContact {
          id
          firstName
          lastName
          email
          phoneNumber
          address {
            ...FullAddress
          }
        }
        discount {
          value
          type
        }
        job {
          id
          number
          description
          customer {
            id
            name
            type
          }
          address {
            ...FullAddress
          }
        }
        jobAssignment {
          id
          startDate
          endDate
          status
          assignees {
            id
            firstName
            lastName
          }
        }
        currencyCode
        notes
        description
        subTotal
        total
        lineItems {
          id
          number
          organizationItem {
            id
            name
            code
            type
            description
          }
          description
          showDetails
          lineItemDetails {
            ...FullWorkOrderLineItemDetails
          }
          quantity
          unitPrice
          taxRateGroup {
            ...TaxRateGroupDetails
          }
          total
          createdAt
          createdBy {
            id
          }
          updatedAt
          updatedBy {
            id
          }
        }
        isArchived
        taxSummary {
          total
          taxRateAmounts {
            amount
            taxRate {
              id
              name
              rate
            }
          }
        }
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${FullAddress}
  ${TaxRateGroupDetails}
  ${FullWorkOrderLineItemDetails}
`
