import React, { useState } from "react"
import { Navigate, NavigateProps, useParams } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation, NetworkStatus } from "@apollo/client"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import WarningIcon from "@mui/icons-material/Warning"
import EditIcon from "@mui/icons-material/EditOutlined"
import CircularProgress from "@mui/material/CircularProgress"
import MainLayout from "../../components/MainLayout"
import PageHeader from "../../components/PageHeader"
import Seo from "../../components/Seo"
import SnackbarMessage from "../../components/SnackbarMessage"
import SectionHeader from "../../components/SectionHeader"
import SectionContent from "../../components/SectionContent"
import { GET_ITEM_BY_ID } from "../../queries/getItemById"
import { EDIT_ITEM } from "../../queries/editItem"
import { ARCHIVE_ITEM } from "../../queries/archiveItem"
import { UNARCHIVE_ITEM } from "../../queries/unarchiveItem"
import { parseGraphQLErrorCode, CATALOG } from "../../util"
import { useAuth } from "../../context/AuthContext"
import CatalogItemBasicInfoForm from "./components/CatalogItemBasicInfoForm"
import CatalogItemBasicInfoStatic from "./components/CatalogItemBasicInfoStatic"
import { Item, Snack, DefaultPermission } from "../../types"
import Button from "@mui/material/Button"
import ArchiveButton from "../../components/ArchiveButton"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import UnarchiveButton from "../../components/UnarchiveButton"

function EditCatalogItem() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [snack, setSnack] = useState<Snack>()
  const [item, setItem] = useState<Item>()
  const [openArchiveItemDialog, setOpenArchiveItemDialog] = useState<boolean>(false)
  const [openUnarchiveItemDialog, setOpenUnarchiveItemDialog] = useState<boolean>(false)
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const { hasPermissions, user } = useAuth()

  const [editItem, { loading: loadingEditItem }] = useMutation(EDIT_ITEM, {
    onCompleted: (data) => {
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      setInEditMode(false)
      setItem(data.editItem.item)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [archiveItem] = useMutation(ARCHIVE_ITEM, {
    onCompleted: () => {
      setRedirectTo({
        to: "/app/catalog",
        replace: false,
        state: {
          snack: {
            messageKey: "messages.archiveItem.success",
            messageOptions: { itemCode: item?.code, regionName: item?.region?.name },
            variant: "success",
          },
        },
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [unarchiveItem] = useMutation(UNARCHIVE_ITEM, {
    onCompleted: () => {
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      setOpenUnarchiveItemDialog(false)
      setInEditMode(false)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const {
    error,
    refetch: refetchItem,
    networkStatus: getItemByIdNetworkStatus,
  } = useQuery(GET_ITEM_BY_ID, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setItem(data.getItemById)
    },
  })

  const loading = getItemByIdNetworkStatus === NetworkStatus.loading || !item

  function handleCancelEditItem() {
    refetchItem()
    setInEditMode(false)
  }

  function handleSaveItem(payload) {
    delete payload.variables.regionId
    editItem(payload)
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(CATALOG.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={CATALOG}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: CATALOG.path, titleKey: CATALOG.titleKey }]}
            icon={CATALOG.icon}
            leafTitleKey="itemDetail"
          />
          {error ? (
            <Alert severity="error">
              {`${t("error.general.title")} ${t("error.general.message")}`}
            </Alert>
          ) : null}
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container direction="row" justifyContent="center">
              <Grid item lg={10} md={12} sx={{ flex: 1 }} xl={8}>
                <Paper>
                  <SectionHeader>
                    <label>{t("basicInfo")}</label>
                    {!inEditMode && hasPermissions?.([DefaultPermission.UpdateItem]) ? (
                      item.isArchived ? (
                        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "4px 8px",
                              borderRadius: "4px",
                              color: "#DA1505",
                            }}
                          >
                            <WarningIcon
                              style={{
                                fontSize: "1.125rem",
                                color: "#DA1505",
                                marginRight: "0.625rem",
                              }}
                            />
                            <span>{t("thisItemHasBeenArchived")}</span>
                          </Box>
                          <UnarchiveButton onClick={() => setOpenUnarchiveItemDialog(true)} />
                        </Box>
                      ) : (
                        <Button
                          onClick={() => {
                            setInEditMode(true)
                          }}
                          size="small"
                          startIcon={<EditIcon />}
                          variant="text"
                        >
                          {t("edit")}
                        </Button>
                      )
                    ) : null}
                    {inEditMode ? (
                      <ArchiveButton
                        disabled={loading}
                        onClick={() => setOpenArchiveItemDialog(true)}
                      />
                    ) : null}
                  </SectionHeader>
                  <Divider />
                  {!inEditMode ? (
                    <SectionContent>
                      <CatalogItemBasicInfoStatic
                        item={item}
                        onImageUpload={refetchItem}
                        timeZone={user?.organization?.timeZone ?? "Etc/UTC"}
                      />
                    </SectionContent>
                  ) : (
                    <SectionContent>
                      <CatalogItemBasicInfoForm
                        item={item}
                        loading={loadingEditItem}
                        onCancel={handleCancelEditItem}
                        onImageUpload={refetchItem}
                        onSave={handleSaveItem}
                      />
                    </SectionContent>
                  )}
                </Paper>
              </Grid>
            </Grid>
          )}
        </Box>
      </MainLayout>
      {openArchiveItemDialog ? (
        <Dialog
          aria-describedby="archive-item-dialog-description"
          aria-labelledby="archive-item-dialog-title"
          onClose={() => setOpenArchiveItemDialog(false)}
          open={openArchiveItemDialog}
        >
          <DialogTitle id="archive-item-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-item-dialog-description">
              {t("component.archiveItemDialog.confirmationPrompt", {
                itemCode: item?.code,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpenArchiveItemDialog(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => archiveItem({ variables: { id: item?.id } })}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {openUnarchiveItemDialog ? (
        <Dialog
          aria-describedby="unarchive-item-dialog-description"
          aria-labelledby="unarchive-item-dialog-title"
          onClose={() => setOpenUnarchiveItemDialog(false)}
          open={openUnarchiveItemDialog}
        >
          <DialogTitle id="unarchive-item-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="unarchive-item-dialog-description">
              {t("component.unarchiveItemDialog.confirmationPrompt", {
                itemCode: item?.code,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpenUnarchiveItemDialog(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => unarchiveItem({ variables: { id: item?.id } })}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

export default EditCatalogItem
