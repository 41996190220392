import React from "react"

function HowDoesFielderWork() {
  return (
    <div>
      <h3>How does Fielder work?</h3>
      <p>
        Fielder is a web-based system. To use it, all you need is a modern web browser and a decent
        internet connection. The primary concept in Fielder is the Job - everything else basically
        revolves around that concept. There is a lot to cover so we’ll keep it brief for now:
        Generally, you create a Job. Then you can attach customer-provided plan files to it. You can
        email the customer, add notes to the Job, create and send an estimate, and much more.
      </p>
    </div>
  )
}

export default HowDoesFielderWork
