import gql from "graphql-tag"
import ContactFragment from "./contact"

export default gql`
  fragment EstimateFragment on Estimate {
    id
    number
    currencyCode
    documentUrl
    billingContact {
      ...ContactFragment
    }
    status
    description
    expirationDate
    footerTitle
    footerBody
    contractJson
    subTotal
    discount {
      value
      type
    }
    taxSummary {
      total
    }
    total
  }
  ${ContactFragment}
`
