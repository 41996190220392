import gql from "graphql-tag"

export default gql`
  fragment FullInvoiceLineItemDetails on InvoiceLineItemDetail {
    id
    number
    quantity
    unitPrice
    organizationItem {
        id
        code
        name
    }
  }
`
