/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useRef } from "react"
import { ClickAwayListener } from "@mui/base/ClickAwayListener"
import IconButton from "@mui/material/IconButton"
import ArrowLeft from "@mui/icons-material/ArrowLeft"
import ArrowRight from "@mui/icons-material/ArrowRight"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import type { Dayjs } from "dayjs"
import { TimeFrameOption } from "../../types"
import { MAX_DATE, MIN_DATE } from "./Constants"

function getCenterButtonLabel(currentTimeFrame: TimeFrameOption, currentDate: Dayjs): string {
  if (currentTimeFrame === TimeFrameOption.WEEK) {
    const start = currentDate.startOf("week").format("l")
    const end = currentDate.endOf("week").format("l")
    return `${start} - ${end}`
  } else {
    return currentDate.format("dddd, LL")
  }
}

interface DateSelectorProps {
  readonly currentDate: Dayjs
  readonly currentTimeFrame: TimeFrameOption
  readonly onChange: (date: Dayjs) => void
  readonly onScrollPrevious: () => void
  readonly onScrollNext: () => void
}

function DateSelector({
  currentDate,
  currentTimeFrame,
  onChange,
  onScrollPrevious,
  onScrollNext,
}: DateSelectorProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const buttonEl = useRef(null)

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "310px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IconButton aria-label="See earlier dates" onClick={onScrollPrevious}>
            <ArrowLeft />
          </IconButton>
          <Button onClick={() => setIsOpen(true)} ref={buttonEl}>
            {getCenterButtonLabel(currentTimeFrame, currentDate)}
          </Button>
          <IconButton aria-label="See later dates" onClick={onScrollNext}>
            <ArrowRight />
          </IconButton>
        </Box>
        <DatePicker
          maxDate={MAX_DATE}
          minDate={MIN_DATE}
          onChange={(selected) => {
            setIsOpen(false)
            selected && onChange(selected)
          }}
          open={isOpen}
          slotProps={{
            popper: {
              anchorEl: buttonEl.current,
              placement: "bottom",
            },
          }}
          slots={{
            field: Box,
          }}
          value={currentDate}
        />
      </Box>
    </ClickAwayListener>
  )
}

export default DateSelector
