import React from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"
import FieldSpinner from "./FieldSpinner"
import SelectorField from "./SelectorField"
import { JobWorkflow } from "../types"

const ALL_JOB_WORKFLOWS = gql`
  query AllJobWorkflows {
    allJobWorkflows(jobWorkflowFilter: { onlyParentWorkflows: false }) {
      id
      name
      description
      isArchived
    }
  }
`

interface JobWorkflowSelectProps {
  readonly error?: boolean
  readonly label?: string
  readonly name?: string
  readonly onBlur?: () => void
  readonly onChange?: (value: any) => void
  readonly required?: boolean
  readonly selectedOption?: JobWorkflow | null
  readonly size?: "small" | "medium"
  readonly sx?: SxProps<Theme>
  readonly variant?: "standard" | "outlined" | "filled"
}

function JobWorkflowSelect({
  error,
  label,
  name,
  onBlur,
  onChange,
  required,
  selectedOption,
  size,
  sx,
  variant,
}: JobWorkflowSelectProps) {
  const { t } = useTranslation()
  const {
    loading,
    error: queryError,
    data,
  } = useQuery(ALL_JOB_WORKFLOWS, {
    fetchPolicy: "cache-and-network",
  })

  if (loading) {
    return <FieldSpinner message={t("loadingWorkflows")} />
  }

  if (queryError) return <div>{`Error! ${error.message}`}</div>

  const options =
    data?.allJobWorkflows.filter((o) => !o.isArchived || o.id === selectedOption?.id) || []

  return (
    <SelectorField
      error={error}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      required={required}
      size={size}
      sx={sx}
      value={selectedOption?.id}
      variant={variant}
    />
  )
}

export default React.memo(JobWorkflowSelect)
