import gql from "graphql-tag"

export const ALL_ROLES = gql`
  query AllRoles($filter: String, $sortDir: SortDirection) {
    allRoles(params: { filter: $filter, sortDir: $sortDir }) {
      id
      name
      isStandard
      description
      permissions {
        id
        group
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`
