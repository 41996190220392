import React, { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"

// don't attempt to refresh more than once every 10 seconds
const MAX_REFRESH_RATE = 10000

export default function useVersionChecker() {
    const location = useLocation()
    const lastRefreshAt = useRef<number>(Date.now())

    useEffect(() => {
        if (location.state?.from !== '/app/login') {
            const diff = Date.now() - lastRefreshAt.current
            if (diff > MAX_REFRESH_RATE) {
                fetch('/version.txt', { cache: "no-store" }).then((response) => {
                    return response.text()
                }).then(text => {
                    const latestVersion = text?.trim()
                    const currentVersion = process.env.GATSBY_APP_VERSION?.trim()

                    if (typeof currentVersion === 'string' &&
                        typeof latestVersion !== "undefined" &&
                        latestVersion !== null &&
                        currentVersion !== latestVersion
                    ) {
                        lastRefreshAt.current = Date.now()
                        window.location.reload(true)
                    }
                })
            }
        }
    }, [location.pathname, location.state?.from])

}
