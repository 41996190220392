import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"

import { formatAddress, formatPhoneNumber, NOT_SPECIFIED } from "~/util"
import { Customer } from "~/types"
import StaticField from "~/components/StaticField"

interface Props {
  readonly customer: Customer
}

function CustomerBasicInfoStatic({ customer }: Props) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        padding: "1.25rem",
        paddingTop: 0,
        paddingBottom: "1rem",
      }}
    >
      <StaticField label={t("name")} showBottomDivider value={customer.name} />
      <StaticField
        label={t("type")}
        showBottomDivider
        value={t(`customerTypeOptions.${customer.type}`)}
      />
      <StaticField
        label={t("address")}
        showBottomDivider
        value={
          customer.address?.addressString
            ? formatAddress(customer.address.addressString)
            : NOT_SPECIFIED
        }
      />
      <StaticField
        label={t("phoneNumber")}
        showBottomDivider
        value={customer.phoneNumber ? formatPhoneNumber(customer.phoneNumber, true) : NOT_SPECIFIED}
      />
      <StaticField
        label={t("emailAddress")}
        showBottomDivider
        value={customer.email ?? NOT_SPECIFIED}
      />
      <StaticField
        label={t("language")}
        showBottomDivider={false}
        value={t(`languageCodeOptions.${customer.languageCode}`)}
      />
    </Box>
  )
}

export default CustomerBasicInfoStatic
