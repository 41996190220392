/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"

interface Meta {
  name: string
  content: string
}

interface SeoProps {
  description?: string
  keywords?: string[]
  lang?: string
  meta?: Meta[]
  title: string
}

function Seo({
  description = "Fielder is a cloud-based software service for field-service franchise businesses.",
  keywords = [],
  lang = "en",
  meta = [],
  title,
}: SeoProps): JSX.Element {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "CodeSwyft, LLC",
        },
        {
          name: `twitter:title`,
          content: "Fielder",
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
      title={title}
    />
  )
}

export default Seo
