import gql from "graphql-tag"

export const ALL_JOB_WORKFLOWS = gql`
  query AllJobWorkflows {
    allJobWorkflows {
      id
      name
      isArchived
    }
  }
`
