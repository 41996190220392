import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"

export const ALL_CUSTOMERS = gql`
  query AllCustomers(
    $filter: String
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $includeArchived: Boolean!
  ) {
    allCustomers(
      input: {
        filter: $filter
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        last: $last
        after: $after
        before: $before
      }
      customerFilter: { includeArchived: $includeArchived }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
          type
          email
          phoneNumber
          isArchived
          flags {
            id
            name
            description
            colorCode
          }
          address {
            ...FullAddress
          }
        }
      }
    }
  }
  ${FullAddress}
`
