import React from "react"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import { t } from "i18next"

import { SectionHeader, SectionContent } from "~/components"
import { Organization, OrganizationStatus, FielderSubscriptionPricePointInterval } from "~/types"
import { NOT_SPECIFIED, formatMoney } from "~/util"
import OrganizationStatusPill from "./OrganizationStatusPill"

interface Props {
  readonly franchisee: Organization
}

export default function FranchiseeSubscriptionInfo({ franchisee }: Props) {
  return (
    <Paper>
      <SectionHeader>
        <label>{t("subscriptionDetails")}</label>
        <Box>
          <OrganizationStatusPill status={franchisee.status} />
        </Box>
      </SectionHeader>
      <Divider />
      <SectionContent
        sx={(theme) => {
          return {
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1.25rem 1rem",
            [theme.breakpoints.up("md")]: {
              padding: "1.25rem 2rem",
            },
          }
        }}
      >
        {franchisee.status === OrganizationStatus.DRAFT ||
        franchisee.status === OrganizationStatus.PENDING ? (
          <Box
            sx={{
              padding: "2rem 1rem",
              borderRadius: "4px",
              background: "#fafafa",
              color: (theme) => theme.fielderColors.mutedText,
              fontWeight: "500",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>{t("component.franchiseeForm.messages.subscriptionPending")}</Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ fontWeight: "600" }}>
                {franchisee.fielderSubscription?.pricePoint?.product?.name ?? NOT_SPECIFIED}
              </Box>
              <Box sx={{ fontSize: "0.875rem", color: (theme) => theme.fielderColors.mutedText }}>
                {t("page.franchiseePricing.jobLimitLabel", {
                  numJobs: franchisee.fielderSubscription?.pricePoint?.product?.monthlyJobLimit,
                })}
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
              <Box sx={{ fontWeight: "600" }}>
                {formatMoney(
                  "USD",
                  franchisee.fielderSubscription?.pricePoint?.effectiveMonthlyRate,
                  "0,0"
                )}{" "}
                USD{" "}
                <Box component="span" sx={{ fontSize: "0.675rem", fontWeight: "400" }}>
                  / {t("month").toLowerCase()}
                </Box>
              </Box>
              <Box sx={{ fontSize: "0.875rem", color: (theme) => theme.fielderColors.mutedText }}>
                {franchisee.fielderSubscription?.pricePoint?.interval ===
                FielderSubscriptionPricePointInterval.MONTH
                  ? t("page.franchiseePricing.paidMonthly")
                  : t("page.franchiseePricing.paidAnnually")}
              </Box>
            </Box>
          </Box>
        )}
      </SectionContent>
    </Paper>
  )
}
