import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"
import { Navigate, NavigateProps } from "react-router-dom"
import { useMutation, useQuery, gql } from "@apollo/client"
import { useParams } from "react-router-dom"
import FormControl from "@mui/material/FormControl"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import { CREATE_WORK_ORDER_TEMPLATE } from "../../../../queries/createWorkOrderTemplate"
import FullWorkOrderTemplate from "../../../../queries/fragments/workOrderTemplate"
import FielderTextField from "../../../../components/FielderTextField"
import FieldHelperText from "../../../../components/FieldHelperText"
import LineItem from "../../../../components/LineItem"
import MainLayout from "../../../../components/MainLayout"
import PageHeader from "../../../../components/PageHeader"
import Seo from "../../../../components/Seo"
import SnackbarMessage from "../../../../components/SnackbarMessage"
import {
  calculateLineSubtotal,
  isBlank,
  parseGraphQLErrorCode,
  sanitizeLineItems,
  updateTransactionLineItem,
  createTransactionLineItemFormInput,
  SETTINGS,
  isEmpty,
} from "../../../../util"
import { useAuth } from "../../../../context/AuthContext"
import {
  WorkOrderTemplate,
  WorkOrderTemplateLineItem,
  Snack,
  TransactionLineItemFormInput,
  WorkOrderTemplateLineItemDetail,
} from "../../../../types"
import { usePrompt } from "../../../../hooks/usePrompt"
import Spinner from "~/components/Spinner"

const GET_WORK_ORDER_TEMPLATE = gql`
  query GetWorkOrderTemplateById($id: ID!) {
    getWorkOrderTemplateById(id: $id) {
      ...FullWorkOrderTemplate
    }
  }
  ${FullWorkOrderTemplate}
`

const EDIT_WORK_ORDER_TEMPLATE = gql`
  mutation EditWorkOrderTemplate(
    $id: ID!
    $name: String!
    $description: String
    $notes: String
    $lineItems: [WorkOrderTemplateLineItemInput!]
  ) {
    editWorkOrderTemplate(
      input: {
        id: $id
        name: $name
        description: $description
        notes: $notes
        lineItems: $lineItems
      }
    ) {
      workOrderTemplate {
        ...FullWorkOrderTemplate
      }
    }
  }
  ${FullWorkOrderTemplate}
`

function EditWorkOrderTemplate() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { id: idParam } = useParams()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [id, setId] = useState<string>()
  const [currencyCode, setCurrencyCode] = useState<string>(
    () => user?.organization?.currencyCode ?? "USD"
  )
  const [name, setName] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [notes, setNotes] = useState<string>("")
  const [lineItems, setLineItems] = useState<TransactionLineItemFormInput[]>(() => {
    return [createTransactionLineItemFormInput(1)]
  })
  const [snack, setSnack] = useState<Snack>()
  const [errors, setErrors] = useState<{
    name?: string | null
    lineItems?: {
      title?: string | null
      message?: string | null
    } | null
  }>(() => ({
    name: null,
    lineItems: null,
  }))

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  const { loading: getWorkOrderTemplateLoading } = useQuery(GET_WORK_ORDER_TEMPLATE, {
    variables: { id: idParam },
    skip: !idParam,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      processWorkOrderTemplate(data?.getWorkOrderTemplateById)
    },
  })

  const [editWorkOrderTemplate, { loading: editWorkOrderTemplateLoading }] = useMutation(
    EDIT_WORK_ORDER_TEMPLATE,
    {
      onCompleted: (data) => {
        processWorkOrderTemplate(data?.editWorkOrderTemplate?.workOrderTemplate)
        setIsDirty(false)
        setRedirectTo({
          to: "/app/settings/templates/workorder/list",
          replace: false,
          state: {
            snack: {
              messageKey: "messages.changesSaved",
              variant: "success",
            },
          },
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const [createWorkOrderTemplate, { loading: createWorkOrderTemplateLoading }] = useMutation(
    CREATE_WORK_ORDER_TEMPLATE,
    {
      onCompleted: (data) => {
        processWorkOrderTemplate(data?.createWorkOrderTemplate?.workOrderTemplate)
        setIsDirty(false)
        setRedirectTo({
          to: "/app/settings/templates/workorder/list",
          replace: false,
          state: {
            snack: {
              messageKey: "messages.changesSaved",
              variant: "success",
            },
          },
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  function processWorkOrderTemplate(template: WorkOrderTemplate) {
    setId(template.id)
    setCurrencyCode(template.currencyCode)
    setName(template.name)
    setDescription(template.description ?? "")
    setNotes(template.notes ?? "")
    const editableLineItems = template.lineItems
      .map((li) => {
        return {
          ...li,
          lineItemDetails: li.lineItemDetails.map(
            (lid: WorkOrderTemplateLineItemDetail, index: number) => {
              return {
                ...lid,
                number: lid.number > 0 ? lid.number : index + 1,
                quantity: lid.quantity > 0 ? String(lid.quantity) : "",
              }
            }
          ),
          organizationItemId: li.organizationItem.id,
          description: li.description ?? "",
          key: li.id,
          quantity: !isEmpty(li.quantity) ? `${li.quantity}` : "",
          unitPrice: !isEmpty(li.unitPrice) ? `${li.unitPrice}` : "",
          subTotal: calculateLineSubtotal(li.quantity, li.unitPrice),
          total: li.total,
          errors: {
            showErrors: false,
          },
        } as TransactionLineItemFormInput
      })
      .sort((a, b) => a.number - b.number)

    setLineItems(editableLineItems)
  }

  function addLineItem() {
    setLineItems(lineItems.concat([createTransactionLineItemFormInput(lineItems.length + 1)]))
  }

  function updateLineItem(reason: string, payload: any): void {
    setLineItems(updateTransactionLineItem(reason, payload, lineItems, "WORK_ORDER"))
  }

  function removeLineItem(lineItem: WorkOrderTemplateLineItem): void {
    const updatedLineItems = lineItems.filter((li) => li.number !== lineItem.number)
    updatedLineItems.forEach((item, idx) => {
      item.number = idx + 1
    })
    setLineItems(updatedLineItems)
  }

  /**
   * Validate all line items
   * A return value of TRUE means everthing looks good.
   */
  function validateLineItems() {
    const hasInvalidItem = lineItems.some((li: WorkOrderTemplateLineItem) => {
      return isBlank(li.organizationItemId)
    })

    if (hasInvalidItem) {
      const validatedLineItems = lineItems.map((li) => {
        return {
          ...li,
          errors: {
            showErrors: true,
            organizationItemId: !li.organizationItemId ? "required" : null,
          },
        }
      })

      setLineItems(validatedLineItems)
    }

    return !hasInvalidItem
  }

  function handleSave() {
    if (!lineItems || lineItems.length === 0) {
      setErrors({
        ...errors,
        lineItems: {
          title: t("component.workOrderDialog.lineItems.required.title"),
          message: t("component.workOrderDialog.lineItems.required.message"),
        },
      })
      setSnack({
        messageKey: "component.workOrderDialog.lineItems.required.message",
        variant: "error",
      })
      return
    }

    if (!validateLineItems()) {
      setErrors({
        ...errors,
        lineItems: {
          title: t("component.workOrderDialog.lineItems.invalid.title"),
          message: t("component.workOrderDialog.lineItems.invalid.message"),
        },
      })
      setSnack({
        messageKey: "component.workOrderDialog.lineItems.invalid.message",
        variant: "error",
      })
      return
    }

    setErrors({})

    const variables = {
      name: name.trim().substring(0, 255),
      description: description.trim().substring(0, 1000),
      notes: notes.trim().substring(0, 1000),
      lineItems: sanitizeLineItems(lineItems, false, false),
    }

    if (editMode) {
      variables.id = id
      editWorkOrderTemplate({ variables })
    } else {
      createWorkOrderTemplate({ variables })
    }
  }

  function handleRowDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const startIndex = result.source.index
    const endIndex = result.destination.index
    if (startIndex === endIndex) {
      return
    }

    const sourceLineItem = lineItems[startIndex]

    let sortedLines = Array.from(lineItems)
    sortedLines.splice(startIndex, 1)
    sortedLines.splice(endIndex, 0, sourceLineItem)
    sortedLines = sortedLines.map((item, idx) => ({
      ...item,
      number: idx + 1,
    }))

    setLineItems(sortedLines)
  }

  function getRowStyle(isDragging: boolean, draggableStyle: any) {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",

      // change background colour if dragging
      background: isDragging ? "#F1F8FF" : "#FFFFFF",

      // styles we need to apply on draggables
      ...draggableStyle,
    }
  }

  function getListStyle(isDraggingOver: boolean) {
    return {
      background: isDraggingOver ? "#EFEFEF" : "#FFFFFF",
    }
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  const loading = getWorkOrderTemplateLoading
  const saving = editWorkOrderTemplateLoading || createWorkOrderTemplateLoading
  const editMode = !!id

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
            paddingBottom: "4rem",
            overflowY: "auto",
          }}
        >
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/templates", titleKey: "templates" },
              { to: "/app/settings/templates/workorder", titleKey: "workOrderTemplates" },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey={loading ? "" : id ? "edit" : "create"}
          />
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Paper sx={[classes.contentContainer, classes.headerControlsContainer]}>
                <Box sx={classes.nameAndTypeContainer}>
                  <Box sx={classes.textFieldContainer}>
                    <FielderTextField
                      error={!!errors.name}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      label={t("templateName")}
                      name="name"
                      onBlur={() => {
                        if (isBlank(name)) {
                          setErrors({
                            ...errors,
                            name: "page.settings.templates.common.validation.name.required",
                          })
                        } else {
                          setErrors({
                            ...errors,
                            name: null,
                          })
                        }
                      }}
                      onChange={(e) => {
                        const val = e.target.value
                        if (isBlank(val)) {
                          setErrors({
                            ...errors,
                            name: "page.settings.templates.common.validation.name.required",
                          })
                        } else {
                          setErrors({
                            ...errors,
                            name: null,
                          })
                        }
                        setName(val)
                      }}
                      onFocus={(e) => e.target.select()}
                      required
                      style={{ marginTop: 0 }}
                      value={name}
                    />
                    {errors.name ? (
                      <FieldHelperText error message={t(errors.name)} />
                    ) : (
                      <FieldHelperText
                        message={t("page.settings.templates.common.helperText.name")}
                      />
                    )}
                  </Box>
                </Box>
                <Box sx={[classes.buttonContainer, { marginTop: 0 }]}>
                  <Button
                    color="primary"
                    data-testid="saveTemplateButton"
                    disabled={!name || saving}
                    onClick={handleSave}
                    style={{
                      fontWeight: "bold",
                      minWidth: "135px",
                    }}
                    variant="contained"
                  >
                    {saving ? (
                      <CircularProgress color="secondary" size={20} thickness={6.0} />
                    ) : (
                      <span>{t("saveTemplate")}</span>
                    )}
                  </Button>
                </Box>
              </Paper>
              <Paper sx={classes.contentContainer}>
                <Box sx={classes.rowContainer}>
                  <Box sx={[classes.rowItem, { marginRight: "1.25rem" }]}>
                    <FormControl fullWidth>
                      <FielderTextField
                        aria-label={t("description")}
                        helperText={t("component.workOrderDialog.descriptionHelperText")}
                        id="description"
                        inputProps={{ maxLength: 1000 }}
                        label={t("description")}
                        maxRows={3}
                        minRows={3}
                        multiline
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={t("component.workOrderDialog.descriptionPlaceholder")}
                        style={{ marginTop: 0 }}
                        value={description}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={classes.rowItem}>
                    <FormControl fullWidth>
                      <FielderTextField
                        aria-label={t("notes")}
                        helperText={t("component.workOrderDialog.notesHelperText")}
                        id="notes"
                        inputProps={{ maxLength: 1000 }}
                        label={t("notes")}
                        maxRows={3}
                        minRows={3}
                        multiline
                        name="notes"
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder={t("component.workOrderDialog.notesPlaceholder")}
                        style={{ marginTop: 0 }}
                        value={notes}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={classes.rowContainer}>
                  <Box sx={classes.rowItem}>
                    <Paper elevation={0} sx={classes.lineItemTableContainer}>
                      <DragDropContext onDragEnd={handleRowDragEnd}>
                        <Box data-testid="lineItemsGrid">
                          <Box sx={[classes.lineItemGridRow, classes.lineItemHeader]}>
                            <div />
                            <label>{t("item")}</label>
                            <label>{t("description")}</label>
                            <label>{t("quantity")}</label>
                            <div />
                          </Box>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {lineItems.map((li, index) => {
                                  return (
                                    <Draggable draggableId={li.key} index={index} key={li.key}>
                                      {(provided, snapshot) => (
                                        <LineItem
                                          css={classes.lineItemGridRow}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          currencyCode={currencyCode}
                                          lineItem={li}
                                          onChange={updateLineItem}
                                          onDelete={(item) => removeLineItem(item)}
                                          style={getRowStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                          variant="WORK_ORDER"
                                        />
                                      )}
                                    </Draggable>
                                  )
                                })}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                          <Box sx={classes.addLineCell}>
                            <Button
                              data-testid="addLineItemButton"
                              fullWidth
                              onClick={addLineItem}
                              sx={classes.addLineButton}
                              variant="outlined"
                            >
                              {`+ ${t("addALine")}`}
                            </Button>
                          </Box>
                        </Box>
                      </DragDropContext>
                    </Paper>
                  </Box>
                </Box>
              </Paper>
            </>
          )}
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "1161px",
    maxWidth: "1161px",
    marginBottom: "1rem",
    padding: "1.25rem",
  },
  headerControlsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  nameAndTypeContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minWidth: "400px",
    maxWidth: "600px",
  },
  rowContainer: {
    display: "flex",
    marginBottom: "0.625rem",
    marginTop: "0.625rem",
    minWidth: "450px",
    width: "100%",
  },
  rowItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "50%",
    marginRight: "0.3125rem",
    marginLeft: "0.3125rem",
  },
  textFieldContainer: {
    width: "50%",
    minWidth: "400px",
    maxWidth: "800px",
  },
  lineItemTableContainer: {
    marginTop: "0.625rem",
    paddingBottom: "1.25rem",
  },
  lineItemGridRow: {
    display: "grid",
    gridTemplateColumns: "50px minmax(100px, 3fr) minmax(100px, 3fr) minmax(50px, 1fr) 50px",
    columnGap: "10px",
    paddingTop: "0.5rem",
    paddingBottom: "0.125rem",
    boxSizing: "border-box",
  },
  lineItemHeader: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderBottom: "1px solid #cdcdcd",
    "& label": {
      fontSize: "0.8rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "0.625rem",
    marginTop: "0.625rem",
  },
  addLineCell: {
    border: "none",
    marginTop: "1.5rem",
    paddingTop: "0.625rem",
    paddingRight: "1.25rem",
    paddingLeft: "1.25rem",
    textAlign: "center",
  },
  addLineButton: {
    border: "1px solid #288906",
    borderRadius: "4px",
    color: "#288906",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#F5FFF2",
      border: "1px solid #288906",
    },
  },
}

export default EditWorkOrderTemplate
