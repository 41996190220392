import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"
import TaxRateGroupDetails from "./fragments/taxRateGroupDetails"
import FullInvoiceLineItemDetails from "./fragments/FullInvoiceLineItemDetails"

export const EDIT_INVOICE = gql`
  mutation EditInvoice(
    $id: ID!
    $description: String
    $netTerms: NetTerms
    $dueDate: LocalDateTime
    $discount: DiscountInput
    $notes: String
    $customerNote: String
    $status: InvoiceStatus
    $billingContact: ContactInput
    $lineItems: [InvoiceLineItemInput!]
    $isArchived: Boolean
    $customerVisibility: InvoiceCustomerVisibilityInput
  ) {
    editInvoice(
      input: {
        id: $id
        description: $description
        netTerms: $netTerms
        dueDate: $dueDate
        notes: $notes
        customerNote: $customerNote
        status: $status
        billingContact: $billingContact
        discount: $discount
        lineItems: $lineItems
        isArchived: $isArchived
        customerVisibility: $customerVisibility
      }
    ) {
      invoice {
        id
        number
        job {
          id
          number
          description
        }
        customerVisibility {
          showDueDate
          showLineItems
          showLineItemQuantity
          showLineItemUnitPrice
          showLineItemSubtotal
          showLineItemCode
          showLineItemName
          showLineItemDescription
          showLineItemBundleComponents
          showLineItemBundleComponentUnitPrice
          showLineItemBundleComponentQuantity
          showFooter
          showFooterSubtotal
          showFooterDiscount
          showFooterTaxes
          showFooterTotal
        }
        status
        notes
        description
        issuedDate
        netTerms
        dueDate
        currencyCode
        customerNote
        discount {
          value
          type
        }
        subTotal
        taxSummary {
          total
          taxRateAmounts {
            amount
            taxRate {
              id
              name
              rate
            }
          }
        }
        total
        billingContact {
          id
          firstName
          lastName
          email
          phoneNumber
          address {
            ...FullAddress
          }
        }
        lineItems {
          id
          number
          organizationItem {
            id
            code
            name
            type
          }
          description
          quantity
          unitPrice
          taxRateGroup {
            ...TaxRateGroupDetails
          }
          showDetails
          lineItemDetails {
            ...FullInvoiceLineItemDetails
          }
          total
          createdAt
          createdBy {
            id
          }
          updatedAt
          updatedBy {
            id
          }
        }
        isArchived
        createdAt
        createdBy {
          id
        }
        updatedAt
        updatedBy {
          id
        }
      }
    }
  }
  ${FullAddress}
  ${TaxRateGroupDetails}
  ${FullInvoiceLineItemDetails}
`

