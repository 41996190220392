import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import AddIcon from "@mui/icons-material/AddOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import Skeleton from "@mui/material/Skeleton"
import { ALL_ASSIGNMENTS_FOR_JOB } from "../../../queries/allAssignmentsForJob"
import { CREATE_JOB_ASSIGNMENT } from "../../../queries/createJobAssignment"
import { DELETE_JOB_ASSIGNMENT } from "../../../queries/deleteJobAssignment"
import { EDIT_JOB_ASSIGNMENT } from "../../../queries/editJobAssignment"
import { NOT_SPECIFIED, capitalize, createDayJS, formatPersonName } from "../../../util"
import { useAuth } from "../../../context/AuthContext"
import EditJobAssignmentDialog from "../../../components/EditJobAssignmentDialog"
import NoResultsRow from "../../../components/NoResultsRow"
import SnackbarMessage from "../../../components/SnackbarMessage"
import {
  Job,
  JobAssignment as IJobAssignment,
  JobAssignmentStatus,
  Snack,
  DefaultPermission,
  JobAssignment,
} from "../../../types"
import FielderIconButton from "../../../components/FielderIconButton"
import { getAssignmentStatusColor } from "~/util/jobAssignmentColors"

const NUM_COLUMNS = 5

function createNewAssignment(job: Job): IJobAssignment {
  const startDate = dayjs().minute(0).second(0).add(1, "hour")
  const endDate = startDate.add(1, "hour")

  return {
    id: null,
    job: job,
    status: JobAssignmentStatus.TENTATIVE,
    isLocked: false,
    startDate: startDate.utc().format(),
    endDate: endDate.utc().format(),
    assignees: [],
  }
}

interface JobAssignmentsTabProps {
  readonly job: Job
}

function JobAssignmentsTab({ job }: JobAssignmentsTabProps) {
  const { t } = useTranslation()
  const { user, hasPermissions } = useAuth()
  const [editJobAssignmentDialogOpen, setEditJobAssignmentDialogOpen] = useState<boolean>(false)
  const [assignmentToEdit, setAssignmentToEdit] = useState<IJobAssignment | null>()
  const [errorKey, setErrorKey] = useState<string | null>()
  const [snack, setSnack] = useState<Snack>()

  const { loading, error, data } = useQuery(ALL_ASSIGNMENTS_FOR_JOB, {
    variables: {
      jobId: job.id,
    },
    fetchPolicy: "network-only",
  })

  const [createJobAssignment, { loading: createJobAssignmentLoading }] = useMutation(
    CREATE_JOB_ASSIGNMENT,
    {
      onCompleted: () => {
        setAssignmentToEdit(null)
        setEditJobAssignmentDialogOpen(false)
        setErrorKey(null)
      },
      onError: (error) => {
        const errorCode = error.graphQLErrors?.[0]?.extensions?.code?.[0]
        setErrorKey(errorCode ?? "error.server.error")
      },
      refetchQueries: () => {
        return ["AllAssignmentsForJob"]
      },
    }
  )

  // Edit an existing JobAssignment
  const [editJobAssignment, { loading: editJobAssignmentLoading }] = useMutation(
    EDIT_JOB_ASSIGNMENT,
    {
      onCompleted: () => {
        setAssignmentToEdit(null)
        setEditJobAssignmentDialogOpen(false)
        setErrorKey(null)
      },
      onError: (error) => {
        const errorCode = error.graphQLErrors?.[0]?.extensions?.code?.[0]
        setErrorKey(errorCode ?? "error.server.error")
      },
      refetchQueries: () => {
        return ["AllAssignmentsForJob"]
      },
    }
  )

  // Delete a JobAssignment
  const [deleteJobAssignment, { loading: deleteJobAssignmentLoading }] = useMutation(
    DELETE_JOB_ASSIGNMENT,
    {
      onCompleted: () => {
        setAssignmentToEdit(null)
        setEditJobAssignmentDialogOpen(false)
        setErrorKey(null)
      },
      onError: (error) => {
        Sentry.captureException(error)
      },
      refetchQueries: () => {
        return ["AllAssignmentsForJob"]
      },
    }
  )

  const openEditAssignmentDialog = (assignment: IJobAssignment) => {
    setErrorKey(null)
    setAssignmentToEdit({ ...assignment, job })
    setEditJobAssignmentDialogOpen(true)
  }

  const handleSaveAssignment = (assignment: IJobAssignment) => {
    setErrorKey(null)
    if (assignment.id) {
      editJobAssignment({
        variables: {
          id: assignment.id,
          assigneeUserIds: assignment.assignees?.map((a) => a.id),
          status: assignment.status,
          isLocked: assignment.isLocked,
          startDate: assignment.startDate,
          endDate: assignment.endDate,
          workOrderId: assignment.workOrderId,
        },
      })
    } else {
      createJobAssignment({
        variables: {
          jobId: assignment.job?.id,
          assigneeUserIds: assignment.assignees?.map((a) => a.id),
          status: assignment.status,
          isLocked: assignment.isLocked,
          startDate: assignment.startDate,
          endDate: assignment.endDate,
          workOrderId: assignment.workOrderId,
        },
      })
    }
  }

  const handleDeleteAssignment = (assignment: JobAssignment) => {
    if (assignment.id) {
      deleteJobAssignment({
        variables: {
          id: assignment.id,
        },
      })
    }
  }

  let assignments = data?.getJobById?.assignments
  if (assignments) {
    assignments = [].concat(assignments).sort((a, b) => {
      return dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? -1 : 1
    })
  }

  return (
    <>
      {editJobAssignmentDialogOpen && assignmentToEdit ? (
        <EditJobAssignmentDialog
          assignment={assignmentToEdit}
          errorKey={errorKey}
          onCancel={() => setEditJobAssignmentDialogOpen(false)}
          onDelete={(assignment) => {
            handleDeleteAssignment(assignment)
          }}
          onSave={(assignment) => {
            handleSaveAssignment(assignment)
          }}
          open={editJobAssignmentDialogOpen}
          waitingOnCreate={createJobAssignmentLoading}
          waitingOnDelete={deleteJobAssignmentLoading}
          waitingOnEdit={editJobAssignmentLoading}
        />
      ) : null}
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Paper
        sx={{
          overflowX: "auto",
          marginBottom: "1.25rem",
          maxHeight: "500px",
          height: "500px",
        }}
      >
        <Table sx={[assignments?.length === 0 && { height: "100%" }]}>
          <TableHead>
            <TableRow>
              <TableCell sx={classes.tableHead} width="15%">
                {t("dateTime")}
              </TableCell>
              <TableCell sx={classes.tableHead} width="15%">
                {t("assignedTo")}
              </TableCell>
              <TableCell sx={classes.tableHead} width="10%">
                {t("status")}
              </TableCell>
              <TableCell sx={classes.tableHead} width="20%">
                {t("workOrder")}
              </TableCell>
              <TableCell style={{ textAlign: "right" }} sx={classes.tableHead}>
                {hasPermissions?.([DefaultPermission.CreateJobAssignment]) ? (
                  <Button
                    aria-label={t("createAssignment") as string}
                    color="primary"
                    data-testid="createAssignmentButton"
                    onClick={() => {
                      openEditAssignmentDialog(createNewAssignment(job))
                    }}
                    sx={{
                      fontWeight: "bold",
                      "& svg": {
                        fontSize: "1.0rem",
                      },
                      "& div": {
                        marginLeft: "0.625rem",
                        marginRight: "0.625rem",
                      },
                    }}
                    variant="contained"
                  >
                    <AddIcon />
                    <Box>{t("createAssignment")}</Box>
                  </Button>
                ) : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={assignments?.length === 0 ? { height: "100%" } : {}}>
            {!error && !loading && assignments?.length === 0 && (
              <NoResultsRow colSpan={NUM_COLUMNS} />
            )}
            {!error &&
              assignments?.map((assignment: JobAssignment) => {
                const start = createDayJS(assignment.startDate, user?.organization?.timeZone)
                const end = createDayJS(assignment.endDate, user?.organization?.timeZone)

                return (
                  <TableRow
                    key={assignment.id}
                    onClick={() => {
                      openEditAssignmentDialog(assignment)
                    }}
                    sx={classes.dataRow}
                  >
                    <TableCell>
                      <Box>
                        <Box>
                          {start
                            ? start.format(t("format:dateFormat.fullDate") as string)
                            : NOT_SPECIFIED}
                        </Box>
                        <Box>
                          <span>
                            {start
                              ? start.format(t("format:dateFormat.time") as string)
                              : NOT_SPECIFIED}
                          </span>
                          <span>{" - "}</span>
                          <span>
                            {end
                              ? end.format(t("format:dateFormat.time") as string)
                              : NOT_SPECIFIED}
                          </span>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        {assignment.assignees?.map((a) => (
                          <Box key={a.id}>{formatPersonName(a)}</Box>
                        ))}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "1rem",
                            height: "1rem",
                            borderRadius: "15%",
                            backgroundColor: getAssignmentStatusColor(assignment.status),
                          }}
                        />
                        <Box>{capitalize(assignment.status)}</Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {assignment.workOrder ? (
                        <Box>
                          {t("workOrder")} #{assignment.workOrder.number}
                        </Box>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <FielderIconButton
                          aria-label={t("edit") as string}
                          sx={{
                            marginLeft: "0.5",
                          }}
                          title={t("edit") as string}
                        >
                          <EditIcon />
                        </FielderIconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            {loading
              ? [...Array(NUM_COLUMNS).keys()].map((i) => (
                  <TableRow key={i}>
                    {[...Array(NUM_COLUMNS).keys()].map((j) => (
                      <TableCell key={j}>
                        <Skeleton variant="text" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : null}
            {error ? (
              <TableRow>
                <TableCell align="center" colSpan={NUM_COLUMNS}>
                  <Typography color="error" variant="h6">
                    {t("errorLabel")}
                    {": "}
                    {error.message}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

const classes = {
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
  },
  dataRow: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f8f8f8",
    },
  },
} as const

export default JobAssignmentsTab
