import { DefaultPermission } from "../types"

const PERMISSION_REQUIREMENTS = {
  dashboard: [],
  dispatch: [
    DefaultPermission.CreateJobAssignment,
    DefaultPermission.ReadJobAssignment,
    DefaultPermission.UpdateJobAssignment,
    DefaultPermission.ReadUser,
    DefaultPermission.ReadJob,
  ],
  jobs: {
    list: [DefaultPermission.ReadJob, DefaultPermission.ReadJobWorkflow],
    create: [DefaultPermission.CreateJob, DefaultPermission.ReadCustomer],
    edit: [DefaultPermission.ReadJob, DefaultPermission.ReadJobWorkflow],
  },
  inventory: {
    list: [DefaultPermission.ReadOrganizationItem, DefaultPermission.UpdateOrganizationItem],
    create: [DefaultPermission.CreateOrganizationItem],
    edit: [DefaultPermission.ReadOrganizationItem, DefaultPermission.UpdateOrganizationItem], // the edit view let's user see it in read-only mode; individual controls are shown/hidden based on user permissions
  },
  catalog: {
    list: [DefaultPermission.ReadItem],
    create: [DefaultPermission.CreateItem],
    edit: [DefaultPermission.ReadItem], // the edit view let's user see it in read-only mode; individual controls are shown/hidden based on user permissions
    import: [DefaultPermission.BulkImportItems],
  },
  productOrders: [
    DefaultPermission.CreateProductOrder,
    DefaultPermission.ReadProductOrder,
    DefaultPermission.UpdateProductOrder,
  ],
  productOrdersHQ: {
    list: [DefaultPermission.ReadProductOrder],
    create: [DefaultPermission.CreateProductOrder],
    edit: [DefaultPermission.ReadProductOrder], // the edit view let's user see it in read-only mode; individual controls are shown/hidden based on user permissions
  },
  helpCenter: [],
  inbox: [],
  estimateRequests: [DefaultPermission.ReadEstimateRequest],
  reports: [
    DefaultPermission.ReadCustomer,
    DefaultPermission.ReadInvoice,
    DefaultPermission.ReadJob,
    DefaultPermission.ReadOrganization,
  ],
  reportsHQ: [
    DefaultPermission.CreateOrganization,
    DefaultPermission.ReadOrganization,
    DefaultPermission.ReadItem,
  ],
  tasks: [DefaultPermission.ReadTask],
  calendar: [DefaultPermission.ReadOrganization],
  invoices: [DefaultPermission.ReadInvoice],
  customers: {
    list: [DefaultPermission.ReadCustomer, DefaultPermission.UpdateCustomer],
    create: [DefaultPermission.CreateCustomer],
    read: [DefaultPermission.ReadCustomer],
    edit: [DefaultPermission.ReadCustomer, DefaultPermission.UpdateCustomer], // the edit view let's user see it in read-only mode; individual controls are shown/hidden based on user permissions
  },
  franchisees: {
    list: [DefaultPermission.ReadOrganization],
    create: [DefaultPermission.CreateOrganization],
    edit: [DefaultPermission.ReadOrganization], // the edit view let's user see it in read-only mode; individual controls are shown/hidden based on user permissions
  },
  settings: {
    myProfile: [],
    organization: [DefaultPermission.ReadOrganization],
    users: {
      list: [DefaultPermission.ReadUser],
      create: [DefaultPermission.CreateUser],
      edit: [DefaultPermission.ReadUser, DefaultPermission.UpdateUser],
    },
    roles: [
      DefaultPermission.CreateRole,
      DefaultPermission.ReadRole,
      DefaultPermission.UpdateRole,
      DefaultPermission.ReadPermission,
    ],
    plugins: [
      DefaultPermission.ReadOrganization,
      DefaultPermission.ReadOrganizationPlugin,
      DefaultPermission.UpdateOrganizationPlugin,
    ],
    checklistTemplates: [
      DefaultPermission.CreateChecklistTemplate,
      DefaultPermission.ReadChecklistTemplate,
      DefaultPermission.UpdateChecklistTemplate,
    ],
    contractTemplates: [
      DefaultPermission.CreateContractTemplate,
      DefaultPermission.ReadContractTemplate,
      DefaultPermission.UpdateContractTemplate,
      DefaultPermission.DeleteContractTemplate,
    ],
    emailTemplates: [
      DefaultPermission.CreateEmailTemplate,
      DefaultPermission.ReadEmailTemplate,
      DefaultPermission.UpdateEmailTemplate,
      DefaultPermission.DeleteEmailTemplate,
    ],
    estimateTemplates: [
      DefaultPermission.CreateEstimateTemplate,
      DefaultPermission.ReadEstimateTemplate,
      DefaultPermission.UpdateEstimateTemplate,
      DefaultPermission.DeleteEstimateTemplate,
    ],
    workOrderTemplates: [
      DefaultPermission.CreateWorkOrderTemplate,
      DefaultPermission.ReadWorkOrderTemplate,
      DefaultPermission.UpdateWorkOrderTemplate,
      DefaultPermission.DeleteWorkOrderTemplate,
    ],
    customerFlags: [
      DefaultPermission.CreateCustomerFlag,
      DefaultPermission.ReadCustomerFlag,
      DefaultPermission.UpdateCustomerFlag,
      DefaultPermission.DeleteCustomerFlag,
    ],
    jobCategories: [
      DefaultPermission.CreateJobCategory,
      DefaultPermission.ReadJobCategory,
      DefaultPermission.UpdateJobCategory,
    ],
    jobStatuses: [
      DefaultPermission.CreateJobStatus,
      DefaultPermission.ReadJobStatus,
      DefaultPermission.UpdateJobStatus,
    ],
    taxRateGroups: [
      DefaultPermission.CreateTaxRateGroup,
      DefaultPermission.ReadTaxRateGroup,
      DefaultPermission.UpdateTaxRateGroup,
    ],
    jobWorkflows: [
      DefaultPermission.CreateJobWorkflow,
      DefaultPermission.ReadJobWorkflow,
      DefaultPermission.UpdateJobWorkflow,
    ],
    regions: [
      DefaultPermission.CreateRegion,
      DefaultPermission.ReadRegion,
      DefaultPermission.UpdateRegion,
    ],
  },
}

export default PERMISSION_REQUIREMENTS
