/* eslint-disable react/react-in-jsx-scope */
import { useLayoutEffect, useRef } from "react"
import { CellProps, Column } from "react-datasheet-grid"
import Select, { GroupBase, SelectInstance } from "react-select"

type Option = {
  label: string
  value: string
}

type SelectOptions = {
  options: Option[]
  disabled?: boolean
}

const SelectComponent = ({
  active,
  rowData,
  setRowData,
  focus,
  stopEditing,
  columnData,
}: CellProps<string, SelectOptions>) => {
  const ref = useRef<SelectInstance<Option, false, GroupBase<Option>>>(null)

  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus()
    } else {
      ref.current?.blur()
    }
  }, [focus])

  return (
    <Select
      isDisabled={columnData.disabled}
      menuIsOpen={focus}
      menuPortalTarget={document.body}
      onChange={(choice) => {
        if (choice === null) return

        setRowData(choice.value)
        setTimeout(stopEditing, 0)
      }}
      onMenuClose={() => stopEditing({ nextRow: false })}
      options={columnData.options}
      ref={ref}
      styles={{
        container: (provided) => ({
          ...provided,
          flex: 1,
          alignSelf: "stretch",
          pointerEvents: focus ? undefined : "none",
        }),
        control: (provided) => ({
          ...provided,
          height: "100%",
          border: "none",
          boxShadow: "none",
          background: "none",
          fontSize: "1rem",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          opacity: 0,
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          opacity: active ? 1 : 0,
        }),
        placeholder: (provided) => ({
          ...provided,
          opacity: active ? 1 : 0,
        }),
      }}
      value={columnData.options.find(({ value }) => value === rowData) ?? null}
    />
  )
}

export const selectColumn = (options: SelectOptions): Column<string, SelectOptions, string> => ({
  component: SelectComponent,
  columnData: options,
  disableKeys: true,
  keepFocus: true,
  disabled: options.disabled,
  deleteValue: () => "",
  copyValue: ({ rowData }) =>
    options.options.find((option) => option.value === rowData)?.label ?? null,
  pasteValue: ({ value }) => options.options.find((option) => option.label === value)?.value ?? "",
})
