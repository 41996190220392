/* eslint-disable react/jsx-no-literals */
import Box from "@mui/material/Box"
import React from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined"
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined"
import { MainLayout, MenuItemCard, PageHeader, Seo } from "../../components"
import { HELP } from "../../util"
import Grid from "@mui/material/Grid"

const iconStyle = {
  fontSize: "2.5rem",
} as const

function Help() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <Seo title={t("sectionTitle.helpCenter")} />
      <MainLayout activeSection={HELP}>
        <Box
          sx={{
            margin: "0 1.25rem",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PageHeader icon={HELP.icon} leafTitleKey={HELP.titleKey} />
          <Grid container spacing={3}>
            <Grid item>
              <MenuItemCard
                icon={<VideoLibraryOutlinedIcon style={iconStyle} />}
                onClick={() => navigate("/app/help/videos")}
                testID="MenuItem-Videos"
                title={t("videos")}
              />
            </Grid>
            <Grid item>
              <MenuItemCard
                icon={<LibraryBooksOutlinedIcon style={iconStyle} />}
                onClick={() => navigate("/app/help/articles")}
                testID="MenuItem-Docs"
                title={t("articles")}
              />
            </Grid>
          </Grid>
        </Box>
      </MainLayout>
    </>
  )
}

const styles = {
  pageHeader: {
    "& svg": {
      fontSize: "1.85rem",
      position: "relative",
      top: "0.3125rem",
    },
  },
  questionContainer: {
    marginTop: "1.25rem",
    marginLeft: "1.25rem",
    maxWidth: 800,
    fontSize: "0.9375rem",
    "& ul li": {
      marginBottom: "0.5rem",
    },
    paddingBottom: "1rem",
    borderBottom: "1px solid #cdcdcd",
  },
} as const

export default Help
