import escapeHtml from "escape-html"
import { Descendant, Text } from "slate"
import { formatMoney } from "./stringUtils"
import { formatDate } from "./dateUtils"
import formatPhoneNumber from "./phoneNumberUtils"
import { TFunction } from "i18next"
import { TemplateData, TemplateField } from "../types"

const DEFAULT_INITIAL_RTE_VALUE: Descendant[] = [{ children: [{ text: "" }] }] as Descendant[]

const SAMPLE_DATA = {
  "job.id": "1234",
  "job.number": 2324,
  "job.description":
    "This is a sample job description. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras convallis vitae eros eget gravida. Aliquam sollicitudin in augue tristique hendrerit. Proin diam enim, tristique nec tellus non, lacinia pretium justo. Phasellus eget ante ac sem volutpat lacinia. Morbi pharetra mauris nunc. Aenean quis nulla nisi. Fusce porta tellus et odio pellentesque tristique. Proin luctus ipsum nec tellus egestas sagittis. Pellentesque vulputate rhoncus placerat. Pellentesque volutpat risus tortor, tincidunt dapibus leo rhoncus quis.",
  "job.desiredCompletionDate": Date.now().toString(),
  "job.emailAddress": "job-2324-09283jl@fielderapp.com",
  "job.address.addressString": "123 Main St, Anytown, CA 12345",
  "job.address.streetNumber": "123",
  "job.address.route": "Main St",
  "job.siteContact.id": "kjjkhasd982",
  "job.siteContact.firstName": "John",
  "job.siteContact.lastName": "Doe",
  "job.siteContact.emailAddress": "john.doe@email.com",
  "job.siteContact.phoneNumber": "+16125552244",
  "job.siteContact.jobTitle": "Project Manager",
  "job.siteContact.address.addressString": "987 Secondary St, Anytown, CA 12345 USA",
  "job.siteContact.address.route": "Secondary Street",
  "job.siteContact.address.streetNumber": "987",
  "job.customer.id": "0987hjasdf786234",
  "job.customer.name": "Acme Inc.",
  "job.customer.email": "acme@email.com",
  "job.customer.phoneNumber": "999-444-3333",
  "job.customer.type": "BUSINESS",
  "job.customer.address.addressString": "837 Central Avenue, Anytown, CA 12345",
  "estimate.id": "1234",
  "estimate.number": 1000,
  "estimate.currencyCode": "USD",
  "estimate.billingContact.id": "kjjkhasd982",
  "estimate.billingContact.firstName": "Jane",
  "estimate.billingContact.lastName": "Doe",
  "estimate.billingContact.email": "jane.doe@email.com",
  "estimate.billingContact.phoneNumber": "+19994442333",
  "estimate.billingContact.jobTitle": "Billing Manager",
  "estimate.status": "DRAFT",
  "estimate.description":
    "This is a sample Estimate. Donec eget est molestie, congue orci a, eleifend sapien. Morbi at iaculis quam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce vitae leo velit.",
  "estimate.expirationDate": "2024-11-11T00:00:00.000Z",
  "estimate.subTotal": 9999.99,
  "estimate.total": 9999.99,
  "estimate.taxSummary.total": 100.0,
  "estimate.discount.value": 100.0,
  "estimate.documentUrl": "https://example.com/estimate/1234",
  "invoice.id": "1234",
  "invoice.number": 1000,
  "invoice.currencyCode": "USD",
  "invoice.billingContact.id": "kjjkhasd982",
  "invoice.billingContact.firstName": "Jane",
  "invoice.billingContact.lastName": "Doe",
  "invoice.billingContact.emailAddress": "jane.doe@email.com",
  "invoice.billingContact.phoneNumber": "+19994442333",
  "invoice.billingContact.jobTitle": "Billing Manager",
  "invoice.status": "DRAFT",
  "invoice.description":
    "This is a sample Estimate. Donec eget est molestie, congue orci a, eleifend sapien. Morbi at iaculis quam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce vitae leo velit.",
  "invoice.issuedDate": "2022-11-11T00:00:00.000Z",
  "invoice.netTerms": "NET_30",
  "invoice.dueDate": "2022-11-11T00:00:00.000Z",
  "invoice.subTotal": 9999.99,
  "invoice.total": 9999.89,
  "invoice.taxSummary.total": 100.0,
  "invoice.discount.value": 100.0,
  "invoice.documentUrl": "https://example.com/invoice/7894",
  "organization.id": "87278hugy7686723",
  "organization.tradeName": "Example Enterprises",
  "organization.email": "exampleinc@email.com",
  "organization.address.addressString": "35323 University Ave, Anytown, CA 12345",
  "organization.phoneNumber": "+15555555555",
  "organization.websiteURL": "https://www.exampleenterprises.com",
  "organization.level": 2,
  "organization.currencyCode": "USD",
  "organization.timeZone": "America/New_York",
}

function resolveTemplate(
  templateJson: any,
  flattenedObj: TemplateData,
  t: TFunction<"translation", undefined>
): Descendant[] {
  return templateJson?.map((node: any) => resolveNode(node, flattenedObj, t))
}

function resolveNode(
  node: any,
  data: TemplateData,
  t: TFunction<"translation", undefined>
): Descendant[] {
  if (node.children) {
    node.children = node.children.map((child: any) => {
      if (child.type === "templateField") {
        // grab everything except for certain properties
        const { children, type, templateField, ...rest } = child

        if (templateField.format === "URL") {
          return {
            type: "link",
            url: data[templateField.key],
            children: [
              {
                type: "text",
                text: ["estimate.documentUrl", "invoice.documentUrl"].includes(templateField.key)
                  ? t("component.richTextEditor.viewDocument")
                  : data[templateField.key],
              },
            ],
          }
        } else {
          return {
            ...rest,
            text: formatTemplateFieldText(child.templateField, data, t),
          }
        }
      } else if (child.children) {
        return resolveNode(child, data, t)
      } else {
        return child
      }
    })
  }
  return node
}

function getCurrencyCodeFromData(key: string, flattenedData: TemplateData): string {
  const keyPrefix = key.split(".")[0]
  const defaultCurrencyCode = "USD"
  if (keyPrefix === "estimate") {
    return (
      flattenedData["estimate.currencyCode"] ??
      flattenedData["organization.currencyCode"] ??
      defaultCurrencyCode
    )
  } else if (keyPrefix === "invoice") {
    return (
      flattenedData["invoice.currencyCode"] ??
      flattenedData["organization.currencyCode"] ??
      defaultCurrencyCode
    )
  } else {
    return flattenedData["organization.currencyCode"] ?? defaultCurrencyCode
  }
}

function formatTemplateFieldText(
  templateField: TemplateField,
  data: TemplateData,
  t: TFunction<"translation", undefined>
): string {
  const key = templateField.key
  const value = data[key]

  if (value == null) {
    if (key === "job.siteContact.fullName") {
      return `${data["job.siteContact.firstName"]} ${data["job.siteContact.lastName"]}`
    } else if (key === "estimate.billingContact.fullName") {
      return `${data["estimate.billingContact.firstName"]} ${data["estimate.billingContact.lastName"]}`
    } else if (key === "invoice.billingContact.fullName") {
      return `${data["invoice.billingContact.firstName"]} ${data["invoice.billingContact.lastName"]}`
    } else if (key === "job.address.streetAddress") {
      return `${data["job.address.streetNumber"]} ${data["job.address.route"]}`
    } else {
      return `[${key}]`
    }
  }

  if (templateField.format === "CURRENCY") {
    const currencyCode = getCurrencyCodeFromData(key, data)
    return formatMoney(currencyCode, value, t("format:currency.long"))
  } else if (templateField.format === "DATE") {
    return formatDate(value, t("format:dateFormat.long"), "Etc/UTC")
  } else if (templateField.format === "PHONE") {
    return formatPhoneNumber(value, false)
  } else if (templateField.format === "ADDRESS") {
    const parts = value.split(",").map((p) => p.trim())
    const streetAddr = parts.shift()
    const cityStateZip = parts.join(", ")
    return `${streetAddr}\n${cityStateZip}`
  } else {
    return `${value}`
  }
}

function toHtml(bodyJson: any): string {
  const content = bodyJson.map((node: any) => convertNodeToHtml(node)).join("")
  const html = `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <!--[if gte mso 9
          ]><xml>
            <o:OfficeDocumentSettings>
              <o:AllowPNG />
              <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
          </xml><!
        [endif]-->
        <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
        <meta content="IE=edge" http-equiv="X-UA-Compatible"/>
        <meta content="width=device-width, initial-scale=1.0 " name="viewport"/>
        <meta content="telephone=no" name="format-detection"/>
        <style type="text/css">
            html {
              font-size: 16px;
            }
            body {
                margin: 0;
                padding: 1.5rem 1rem 1.5rem 0.5rem;
                -webkit-text-size-adjust: 100% !important;
                -ms-text-size-adjust: 100% !important;
                -webkit-font-smoothing: antialiased !important;
                font-family: arial, sans-serif;
                line-height: 1.43;
                font-size: 0.875rem;
                padding: 1rem
            }

            img {
                border: 0 !important;
                outline: none !important;
            }

            p {
                margin: 0px !important;
                padding: 0px !important;
            }
        </style>
    </head>
    <body>${content}</body>
  </html>
  `
  return html.trim()
}

function getNodeStyle(node: any): string {
  let style = ""

  if (node.font) {
    style = `font-family: ${node.font.fontFamily};`
  }

  if (node.textSize) {
    style = `${style}font-size: ${node.textSize.fontSize};`
  }

  if (node.textColor) {
    style = `${style}color: ${node.textColor};`
  }

  if (node.alignment) {
    style = `${style}text-align: ${node.alignment};`
  }

  return style
}

function convertNodeToHtml(node: any): string {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)

    if (string.length === 0) {
      string = "&#xfeff;"
    }

    if (node.bold) {
      string = `<strong>${string}</strong>`
    }

    if (node.code) {
      string = `<code>${string}</code>`
    }

    if (node.italic) {
      string = `<em>${string}</em>`
    }

    if (node.underline) {
      string = `<u>${string}</u>`
    }

    return `<span style="${getNodeStyle(node)}">${string}</span>`
  }

  const children = node.children.map((n: any) => convertNodeToHtml(n)).join("")
  const style = getNodeStyle(node)

  switch (node.type) {
    case "block-quote":
      return `<blockquote><p style="${style}">${children}</p></blockquote>`
    case "paragraph":
      return `<div style="${style}">${children}</div>`
    case "link":
      return `<a href="${escapeHtml(node.url)}" style="${style}">${children}</a>`
    case "bulleted-list":
      return `<ul style="${style}">${children}</ul>`
    case "list-item":
      return `<li style="${style}">${children}</li>`
    case "numbered-list":
      return `<ol style="${style}">${children}</ol>`
    default:
      return `<div style="${style}">${children}</div>`
  }
}

export { resolveTemplate, toHtml, SAMPLE_DATA, DEFAULT_INITIAL_RTE_VALUE }
