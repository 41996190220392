import gql from "graphql-tag"

export const GET_ORGANIZATION_PLUGIN_INFO = gql`
  query GetOrganizationPluginInfo($id: ID!) {
    getOrganizationById(id: $id) {
      id
      name
      plugins {
        id
        pluginProvider {
          id
          name
          category
        }
        supportsInventoryTracking
        tenantId
        defaultExpenseAccount {
          id
          code
          name
          type
        }
        defaultRevenueAccount {
          id
          code
          name
          type
        }
        defaultAssetAccount {
          id
          code
          name
          type
        }
      }
    }
  }
`
