import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Popover from "@mui/material/Popover"

import { isBlank } from "~/util"
import { CustomerFlag } from "~/types"
import FielderTextField from "./FielderTextField"
import FieldHelperText from "./FieldHelperText"
import SaveButton from "./SaveButton"
import { CustomerFlagColorOption, CustomerFlagColorPicker } from "./CustomerFlagColorPicker"

interface Props {
  readonly loading?: boolean
  readonly onCancel?: () => void
  readonly onSave?: (customerFlag: CustomerFlag) => void
  readonly open?: boolean
  readonly customerFlag?: CustomerFlag
}

const NAME_LENGTH_LIMIT = 20
const DESCRIPTION_LENGTH_LIMIT = 100

function CustomerFlagDialog({ loading, onCancel, onSave, open = false, customerFlag }: Props) {
  const { t } = useTranslation()
  const [name, setName] = useState<string>(() => customerFlag?.name ?? "")
  const [description, setDescription] = useState<string>(() => customerFlag?.description ?? "")
  const [colorCode, setColorCode] = useState<string>(customerFlag?.colorCode ?? "#dddddd")
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = useState(null)
  const [errors, setErrors] = useState(() => ({
    name: null,
    description: null,
    colorCode: null,
  }))

  const showColorPicker = Boolean(colorPickerAnchorEl)

  return (
    <Dialog
      aria-labelledby="edit-customerFlag-dialog-title"
      data-testid="CustomerFlagDialog"
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogTitle
        id="edit-customerFlag-dialog-title"
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {customerFlag?.id
          ? t("component.customerFlagDialog.title.edit")
          : t("component.customerFlagDialog.title.create")}
      </DialogTitle>
      <DialogContent>
        <Box sx={classes.dialogContent}>
          <Box sx={classes.withHelpMessage}>
            <FielderTextField
              data-testid="name-Field"
              error={!!errors.name}
              fullWidth
              id="name"
              inputProps={{ maxLength: NAME_LENGTH_LIMIT }}
              label={t("component.customerFlagDialog.name")}
              name="name"
              onBlur={() => {
                if (isBlank(name)) {
                  setErrors((prev) => ({
                    ...prev,
                    name: t("component.customerFlagDialog.validation.name.required"),
                  }))
                } else {
                  setErrors({ ...errors, name: null })
                }
              }}
              onChange={(e) => {
                setName(e.target.value)
                if (isBlank(e.target.value)) {
                  setErrors((prev) => ({
                    ...prev,
                    name: t("component.customerFlagDialog.validation.name.required"),
                  }))
                } else {
                  setErrors({ ...errors, name: null })
                }
              }}
              onFocus={(e: any) => e.target.select()}
              required
              value={name}
            />
            <Box sx={classes.helperTextContainer}>
              {!errors.name ? (
                <FieldHelperText message={t(`component.customerFlagDialog.helperText.name`)} />
              ) : (
                <FieldHelperText error message={errors.name} />
              )}
              <Box sx={classes.characterCounter}>
                {name?.length} / {NAME_LENGTH_LIMIT}
              </Box>
            </Box>
          </Box>
          <Box sx={classes.withHelpMessage}>
            <FielderTextField
              fullWidth
              id="description"
              inputProps={{ maxLength: DESCRIPTION_LENGTH_LIMIT, "data-testid": "description" }}
              label={t("component.customerFlagDialog.description")}
              maxRows="2"
              minRows="2"
              multiline
              name="description"
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              value={description}
            />
            <Box sx={classes.helperTextContainer}>
              {!errors.description ? (
                <FieldHelperText
                  message={t(`component.customerFlagDialog.helperText.description`)}
                />
              ) : (
                <FieldHelperText error message={errors.description} />
              )}
              <Box sx={classes.characterCounter}>
                {description?.length} / {DESCRIPTION_LENGTH_LIMIT}
              </Box>
            </Box>
          </Box>
          <Box sx={[classes.withHelpMessage, { marginTop: "1.5rem" }]}>
            <Box
              onClick={(event: any) => {
                setColorPickerAnchorEl(event.currentTarget)
              }}
              sx={[
                classes.colorBox,
                {
                  backgroundColor: colorCode,
                  borderColor: (theme) => theme.fielderColors.inputBorder,
                },
              ]}
            />
            <Popover
              PaperProps={{
                style: {
                  background: "transparent",
                  borderRadius: "8px",
                },
              }}
              anchorEl={colorPickerAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => {
                setColorPickerAnchorEl(null)
              }}
              open={showColorPicker}
            >
              <CustomerFlagColorPicker
                onChange={(colorOption: CustomerFlagColorOption) => {
                  setColorCode(colorOption.colorCode)
                  setColorPickerAnchorEl(null)
                }}
              />
            </Popover>
            <FieldHelperText
              message={t("component.customerFlagDialog.helperText.color")}
              style={{ marginLeft: 0 }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={classes.dialogActions}>
        <Button
          disabled={loading}
          onClick={() => {
            onCancel?.()
          }}
          sx={{ border: "1px solid #ccc", minWidth: "80px" }}
        >
          {t("cancel")}
        </Button>
        <SaveButton
          disabled={loading || isBlank(name) || !colorCode}
          loading={loading}
          onClick={() => {
            onSave?.({
              id: customerFlag?.id,
              name,
              description,
              colorCode,
            })
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

const classes = {
  dialogContent: {
    backgroundColor: "#FFFFFF",
    paddingBottom: 0,
    paddingTop: "1rem",
  },
  dialogActions: {
    px: "1.5rem",
    paddingBottom: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #ccc",
  },
  colorBox: {
    height: "2.875rem",
    width: "6.25rem",
    marginBottom: "0.75rem",
    borderRadius: "4px",
    border: "1px solid #00ff00",
    cursor: "pointer",
  },
  withHelpMessage: {
    marginBottom: "0.75rem",
  },
  helperTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  characterCounter: {
    fontSize: "0.75rem",
  },
} as const

export default CustomerFlagDialog
