import React, { useCallback, useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import Box from "@mui/material/Box"
import FormControlLabel from "@mui/material/FormControlLabel"
import Button from "@mui/material/Button"
import capitalize from "capitalize"
import AddressAutocompleteField from "../../../components/AddressAutocompleteField"
import GreenCheckbox from "../../../components/GreenCheckbox"
import PhoneNumberInput from "../../../components/PhoneNumberInput"
import FielderTextField from "../../../components/FielderTextField"
import FieldHelperText from "../../../components/FieldHelperText"
import HardhatIcon from "../../../components/icons/HardhatIcon"
import { convertPlaceToAddress, isBlank, isValidEmail, useGoogleMaps } from "../../../util"
import { Address, Contact } from "../../../types"
import SaveButton from "../../../components/SaveButton"

interface ContactInfoFormProps {
  readonly contact?: Contact
  readonly loading?: boolean
  readonly onCancel: () => void
  readonly onSave: (contact: Contact) => void
}

function ContactInfoForm({ contact, loading, onCancel, onSave }: ContactInfoFormProps) {
  const { t } = useTranslation()
  const googleMapsApi = useGoogleMaps()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string>(() => contact?.firstName || "")
  const [lastName, setLastName] = useState<string>(() => contact?.lastName || "")
  const [jobTitle, setJobTitle] = useState<string>(() => contact?.jobTitle || "")
  const [email, setEmail] = useState<string>(() => contact?.email || "")
  const [phoneNumber, setPhoneNumber] = useState<string>(() => contact?.phoneNumber || "")
  const [canBeJobSiteContact, setCanBeJobSiteContact] = useState<boolean>(() =>
    contact ? contact.canBeJobSiteContact : true
  )
  const [canBeBillingContact, setCanBeBillingContact] = useState<boolean>(() =>
    contact ? contact.canBeBillingContact : true
  )
  const [address, setAddress] = useState<Address>(() =>
    contact?.address
      ? {
          addressString: contact.address.addressString,
          latitude: contact.address.latitude,
          longitude: contact.address.longitude,
          streetNumber: contact.address.streetNumber,
          route: contact.address.route,
          locality: contact.address.locality,
          administrativeAreaLevel1: contact.address.administrativeAreaLevel1,
          administrativeAreaLevel2: contact.address.administrativeAreaLevel2,
          postalCode: contact.address.postalCode,
          country: contact.address.country,
        }
      : { addressString: "" }
  )
  const addressAutocompleteRef = useRef(null)
  const [touched, setTouched] = useState(() => ({
    firstName: false,
    lastName: false,
    email: false,
  }))
  const [errors, setErrors] = useState(() => ({
    firstName: null,
    lastName: null,
    email: null,
  }))

  useEffect(() => {
    if (googleMapsApi && addressAutocompleteRef.current) {
      const addressField = new googleMapsApi.maps.places.Autocomplete(
        addressAutocompleteRef.current,
        {
          types: ["address"],
        }
      )

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      const addressComponents = ["formatted_address", "geometry", "address_components"]
      addressField.setFields(addressComponents)

      addressField.addListener("place_changed", () => {
        const place = addressField.getPlace()
        const fullAddress = convertPlaceToAddress(place)
        setAddress(fullAddress)
      })
    }
  }, [googleMapsApi])

  const isInvalid = (): boolean => {
    return (
      Boolean(errors.firstName) ||
      Boolean(errors.lastName) ||
      Boolean(errors.email) ||
      isBlank(firstName) ||
      isBlank(lastName) ||
      (!isBlank(email) && !isValidEmail(email))
    )
  }

  const handleChangeFirstName = useCallback((e) => {
    const value = e.target.value
    setFirstName(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      firstName: null,
    }))
  }, [])

  const handleChangeLastName = useCallback((e) => {
    const value = e.target.value
    setLastName(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      lastName: null,
    }))
  }, [])

  const handleChangeJobTitle = useCallback((e) => {
    const value = e.target.value
    setJobTitle(value)
    setIsDirty(true)
  }, [])

  const handleBlurFirstName = () => {
    setTouched((prev) => ({
      ...prev,
      firstName: true,
    }))
    if (isBlank(firstName)) {
      setErrors((prev) => ({
        ...prev,
        firstName: t("component.contactInfoForm.validation.firstName.required"),
      }))
    } else {
      setFirstName(capitalize.words(firstName, true))
      setErrors((prev) => ({
        ...prev,
        firstName: null,
      }))
    }
  }

  const handleBlurLastName = () => {
    setTouched((prev) => ({
      ...prev,
      lastName: true,
    }))
    if (isBlank(lastName)) {
      setErrors((prev) => ({
        ...prev,
        lastName: t("component.contactInfoForm.validation.lastName.required"),
      }))
    } else {
      setLastName(capitalize.words(lastName, true))
      setErrors((prev) => ({
        ...prev,
        lastName: null,
      }))
    }
  }

  const handleBlurEmail = () => {
    setTouched({
      ...touched,
      email: true,
    })
    if (!isBlank(email) && !isValidEmail(email)) {
      setErrors({
        ...errors,
        email: t("component.contactInfoForm.validation.email.invalid"),
      })
    } else {
      setErrors({
        ...errors,
        email: null,
      })
    }
  }

  const handleChangeEmail = useCallback((e) => {
    const value = e.target.value
    setEmail(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      email: !value?.trim() || isValidEmail(value) ? null : prev.email,
    }))
  }, [])

  const handleChangePhoneNumber = useCallback((val) => {
    setIsDirty(true)
    setPhoneNumber(val)
  }, [])

  const handleChangeAddress = useCallback((val) => {
    setIsDirty(true)
    setAddress(val)
  }, [])

  const handleChangeCanBeBillingContact = useCallback((e) => {
    setIsDirty(true)
    setCanBeBillingContact(e.target.checked)
  }, [])

  const handleChangeCanBeJobSiteContact = useCallback((e) => {
    setIsDirty(true)
    setCanBeJobSiteContact(e.target.checked)
  }, [])

  function handleSubmit() {
    const variables = {
      firstName,
      lastName,
      jobTitle,
      email,
      phoneNumber,
      address,
      canBeJobSiteContact,
      canBeBillingContact,
    }
    if (contact?.id) {
      variables.id = contact.id
    }
    onSave?.(variables)
  }

  return (
    <Box
      sx={{
        padding: "1rem 1.5rem",
      }}
    >
      <Box sx={classes.fieldContainer}>
        <FielderTextField
          autoFocus
          data-testid="firstName-field"
          error={!!errors.firstName}
          fullWidth
          id="firstName"
          inputProps={{ maxLength: 255 }}
          label={t("firstName")}
          name="firstName"
          onBlur={handleBlurFirstName}
          onChange={handleChangeFirstName}
          onFocus={(e) => e.target.select()}
          required
          value={firstName}
        />
        {errors.firstName && touched.firstName ? (
          <FieldHelperText error message={errors.firstName} />
        ) : null}
      </Box>
      <Box sx={classes.fieldContainer}>
        <FielderTextField
          data-testid="lastName-field"
          error={!!errors.lastName}
          fullWidth
          id="lastName"
          inputProps={{ maxLength: 255 }}
          label={t("lastName")}
          name="lastName"
          onBlur={handleBlurLastName}
          onChange={handleChangeLastName}
          onFocus={(e) => e.target.select()}
          required
          value={lastName}
        />
        {errors.lastName && touched.lastName ? (
          <FieldHelperText error message={errors.lastName} />
        ) : null}
      </Box>
      <Box sx={classes.fieldContainer}>
        <FielderTextField
          data-testid="jobTitle-field"
          fullWidth
          id="jobTitle"
          inputProps={{ maxLength: 255 }}
          label={t("jobTitle")}
          name="jobTitle"
          onBlur={() => {
            if (jobTitle) {
              setJobTitle(capitalize.words(jobTitle, true))
            }
          }}
          onChange={handleChangeJobTitle}
          onFocus={(e) => e.target.select()}
          value={jobTitle}
        />
      </Box>
      <Box sx={classes.fieldContainer}>
        <FielderTextField
          data-testid="email-field"
          error={!!errors.email}
          fullWidth
          id="email"
          inputProps={{ maxLength: 255 }}
          label={t("email")}
          name="email"
          onBlur={handleBlurEmail}
          onChange={handleChangeEmail}
          onFocus={(e) => e.target.select()}
          value={email}
        />
        {errors.email && touched.email ? <FieldHelperText error message={errors.email} /> : null}
      </Box>
      <Box sx={classes.fieldContainer}>
        <PhoneNumberInput
          label={t("phoneNumber") as string}
          onChange={handleChangePhoneNumber}
          value={phoneNumber}
        />
      </Box>
      <Box sx={classes.fieldContainer}>
        <AddressAutocompleteField
          defaultValue={address?.addressString}
          label={t("address")}
          onChange={handleChangeAddress}
        />
        {errors.address && touched.address ? (
          <FieldHelperText error message={errors.address} />
        ) : null}
      </Box>
      <Box sx={classes.fieldContainer}>
        <Box sx={classes.contactTypeContainer}>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={canBeJobSiteContact}
                onChange={handleChangeCanBeJobSiteContact}
              />
            }
            label={t("canBeJobSiteContact") as string}
          />
          <Box sx={classes.iconContainer}>
            <HardhatIcon height={18} width={18} />
          </Box>
        </Box>
        <Box sx={classes.contactTypeContainer}>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={canBeBillingContact}
                data-testid="canBeBillingContact-field"
                onChange={handleChangeCanBeBillingContact}
              />
            }
            label={t("canBeBillingContact") as string}
          />
          <Box sx={classes.iconContainer}>
            <AttachMoneyIcon fontSize="inherit" />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "1.5rem",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton disabled={isInvalid()} loading={loading} onClick={handleSubmit} />
      </Box>
    </Box>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
  contactTypeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  iconContainer: {
    fontSize: "1.125rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: "0.625rem",
  },
} as const

export default ContactInfoForm
