import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import * as Sentry from "@sentry/react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"

import ErrorMessage from "~/components/ErrorMessage"
import { CREATE_CUSTOMER_NOTE } from "~/queries/createCustomerNote"
import { CustomerNote } from "~/types"
import SaveButton from "~/components/SaveButton"

interface Props {
  readonly existingNote?: CustomerNote
  readonly customerId: string
  readonly onCancel: () => void
  readonly onSave: (status: "success" | "error") => void
}

const dialogBackgroundColor = "#FFFFFF"

export default function CustomerNoteDialog({ existingNote, customerId, onCancel, onSave }: Props) {
  const { t } = useTranslation()
  const [note, setNote] = useState(() => existingNote?.text || "")

  const [createCustomerNote, { loading, error }] = useMutation(CREATE_CUSTOMER_NOTE, {
    onCompleted: () => {
      onSave("success")
    },
    onError: (error) => {
      Sentry.captureException(error)
      onSave("error")
    },
    refetchQueries: ["GetCustomerById"],
  })

  function handleSave() {
    createCustomerNote({
      variables: {
        customerId,
        text: note,
      },
    })
  }

  return (
    <Dialog
      aria-labelledby="customer-note-dialog"
      data-testid="CustomerNoteDialog"
      fullWidth
      maxWidth="sm"
      open
    >
      <DialogTitle
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {t("newNote")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: dialogBackgroundColor,
            paddingBottom: 0,
            paddingTop: "1.5rem",
          }}
        >
          {error ? (
            <ErrorMessage message={t("error.general.message")} title={t("error.general.title")} />
          ) : null}
          <TextField
            autoComplete="none"
            data-testid="note"
            fullWidth
            id="note"
            inputProps={{ maxLength: 1000 }}
            minRows={3}
            multiline
            name="note"
            onChange={(e) => setNote(e.target.value)}
            placeholder={t("component.customerNoteDialog.noteFieldPlaceholder") as string}
            required
            value={note}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: "1.5rem",
          paddingBottom: "0.75rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton loading={loading} onClick={handleSave} />
      </DialogActions>
    </Dialog>
  )
}
