/* eslint-disable react/jsx-no-literals */
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { NOT_SPECIFIED } from "../../../../util"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import Icon from "@mui/material/Icon"
import { useAuth } from "../../../../context/AuthContext"
import { JobWorkflow } from "../../../../types"

interface JobWorkflowBasicInfoStaticProps {
  onPublish: () => void
  workflow: JobWorkflow
}

function JobWorkflowBasicInfoStatic({ onPublish, workflow }: JobWorkflowBasicInfoStaticProps) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [publishConfirmationDialogOpen, setPublishConfirmationDialogOpen] = useState<boolean>(false)

  return (
    <>
      <Box sx={classes.staticFieldContainer}>
        <label>{t("workflowName")}</label>
        <Box sx={classes.value}>{workflow.name}</Box>
      </Box>
      <Box sx={classes.staticFieldContainer}>
        <label>{t("parentWorkflow")}</label>
        <Box sx={classes.value}>{workflow.parentWorkflow?.name}</Box>
      </Box>
      <Box sx={classes.staticFieldContainer}>
        <label>{t("description")}</label>
        <Box sx={classes.value}>{workflow.description ?? NOT_SPECIFIED}</Box>
      </Box>
      {user?.organization?.level && user.organization.level < 2 && (
        <Box sx={classes.staticFieldContainer}>
          <label>{t("status")}</label>
          <Box sx={classes.value}>
            {workflow.isPublished ? (
              t("published")
            ) : (
              <>
                <Box sx={classes.statusText}>
                  <Icon sx={{ fontSize: "1.125rem", color: "#f6c342", marginRight: "0.625rem" }}>
                    warning
                  </Icon>{" "}
                  {t("page.jobWorkflow.status.draft")}
                </Box>
                <Button
                  disabled={!workflow || !workflow.steps || workflow.steps?.length === 0}
                  onClick={() => {
                    setPublishConfirmationDialogOpen(true)
                  }}
                  variant="outlined"
                >
                  <span>{t("publish")}</span>
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      {publishConfirmationDialogOpen && !workflow.isPublished && (
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={() => setPublishConfirmationDialogOpen(false)}
          open={publishConfirmationDialogOpen}
        >
          <DialogTitle id="alert-dialog-title">
            {t("page.jobWorkflow.publishConfirmationPromptTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("page.jobWorkflow.publishConfirmationPromptMsg")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setPublishConfirmationDialogOpen(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                setPublishConfirmationDialogOpen(false)
                onPublish()
              }}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

const classes = {
  staticFieldContainer: {
    padding: "1.25rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.5rem",
    "& label": {
      fontWeight: 700,
      marginBottom: "0.25rem",
    },
    "& hr": {
      marginTop: "0.625rem",
    },
  },
  value: {
    minHeight: "20px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  statusText: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.25rem",
    marginBottom: "0.625rem",
  },
}

export default JobWorkflowBasicInfoStatic
