import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { Navigate, NavigateProps } from "react-router-dom"
import { useMutation, useQuery, gql } from "@apollo/client"
import { useParams } from "react-router-dom"
import Box from "@mui/material/Box"
import { ALL_TAX_RATE_GROUP_OPTIONS } from "../../../../queries/allTaxRateGroupOptions"
import { CREATE_ESTIMATE_TEMPLATE } from "../../../../queries/createEstimateTemplate"
import FullEstimateTemplate from "../../../../queries/fragments/estimateTemplate"
import MainLayout from "../../../../components/MainLayout"
import PageHeader from "../../../../components/PageHeader"
import Seo from "../../../../components/Seo"
import SnackbarMessage from "../../../../components/SnackbarMessage"
import { parseGraphQLErrorCode, SETTINGS } from "../../../../util"
import { useAuth } from "../../../../context/AuthContext"
import {
  CreateEstimateTemplateInput,
  CreateEstimateTemplateResponse,
  EditEstimateTemplateInput,
  EditEstimateTemplateResponse,
  EstimateTemplate,
  Snack,
  TaxRateGroup,
} from "../../../../types"
import Spinner from "../../../../components/Spinner"
import EstimateTemplateForm from "./components/EstimateTemplateForm"

const GET_ESTIMATE_TEMPLATE = gql`
  query GetEstimateTemplateById($id: ID!) {
    getEstimateTemplateById(id: $id) {
      ...FullEstimateTemplate
    }
  }
  ${FullEstimateTemplate}
`

const EDIT_ESTIMATE_TEMPLATE = gql`
  mutation EditEstimateTemplate(
    $id: ID!
    $name: String!
    $description: String
    $notes: String
    $discount: DiscountInput
    $footerTitle: String
    $footerBody: String
    $lineItems: [EstimateTemplateLineItemInput!]
  ) {
    editEstimateTemplate(
      input: {
        id: $id
        name: $name
        description: $description
        notes: $notes
        discount: $discount
        footerTitle: $footerTitle
        footerBody: $footerBody
        lineItems: $lineItems
      }
    ) {
      estimateTemplate {
        ...FullEstimateTemplate
      }
    }
  }
  ${FullEstimateTemplate}
`

function EditEstimateTemplate() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { id } = useParams()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [snack, setSnack] = useState<Snack>()
  const [estimateTemplate, setEstimateTemplate] = useState<EstimateTemplate>()
  const [taxRateGroupOptions, setTaxRateGroupOptions] = useState<TaxRateGroup[]>([])

  const { loading: taxRateGroupOptionsLoading } = useQuery(ALL_TAX_RATE_GROUP_OPTIONS, {
    variables: {
      sortBy: "name",
      sortDir: "ASC",
    },
    onCompleted: (data) => {
      const options = data?.allTaxRateGroups?.edges.map((e) => e.node)
      setTaxRateGroupOptions(options)
    },
  })

  const { loading: getEstimateTemplateLoading } = useQuery(GET_ESTIMATE_TEMPLATE, {
    variables: { id },
    skip: !id,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setEstimateTemplate(data?.getEstimateTemplateById)
    },
  })

  const [editEstimateTemplate, { loading: editEstimateTemplateLoading }] = useMutation<
    EditEstimateTemplateResponse,
    EditEstimateTemplateInput
  >(EDIT_ESTIMATE_TEMPLATE, {
    onCompleted: (data) => {
      setEstimateTemplate(data?.editEstimateTemplate?.estimateTemplate)
      setRedirectTo({
        to: "/app/settings/templates/estimate/list",
        replace: false,
        state: {
          snack: {
            messageKey: "messages.changesSaved",
            variant: "success",
          },
        },
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [createEstimateTemplate, { loading: createEstimateTemplateLoading }] = useMutation<
    CreateEstimateTemplateResponse,
    CreateEstimateTemplateInput
  >(CREATE_ESTIMATE_TEMPLATE, {
    onCompleted: (data) => {
      setEstimateTemplate(data?.createEstimateTemplate?.estimateTemplate)
      setRedirectTo({
        to: "/app/settings/templates/estimate/list",
        replace: false,
        state: {
          snack: {
            messageKey: "messages.changesSaved",
            variant: "success",
          },
        },
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const saving = editEstimateTemplateLoading || createEstimateTemplateLoading

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
            paddingBottom: "4rem",
            overflowY: "auto",
          }}
        >
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/templates", titleKey: "templates" },
              { to: "/app/settings/templates/estimate", titleKey: "estimateTemplates" },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey={id ? "edit" : "create"}
          />
          {getEstimateTemplateLoading || (id && !estimateTemplate) ? (
            <Spinner />
          ) : user ? (
            <EstimateTemplateForm
              currencyCode={user.organization?.currencyCode ?? "USD"}
              isSaving={saving}
              onSave={(payload: CreateEstimateTemplateInput) => {
                if (id) {
                  editEstimateTemplate({
                    variables: {
                      ...payload,
                      id,
                    },
                  })
                } else {
                  createEstimateTemplate({ variables: payload })
                }
              }}
              taxRateGroupOptions={taxRateGroupOptions}
              taxRateGroupOptionsLoading={taxRateGroupOptionsLoading}
              template={estimateTemplate}
            />
          ) : (
            <span>unauthorized</span>
          )}
        </Box>
      </MainLayout>
    </>
  )
}

export default EditEstimateTemplate
