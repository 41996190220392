import React from "react"
import Box from "@mui/material/Box"
import { JobWorkflowStep } from "~/types"
import { NOT_SPECIFIED } from "~/util"
import { capitalize } from "lodash"

interface Props {
  readonly jobWorkflowStep: JobWorkflowStep
}

function JobStatusPill({ jobWorkflowStep }: Props) {
  return (
    <Box
      component="span"
      sx={{
        backgroundColor: jobWorkflowStep.jobStatus?.lightColor,
        color: jobWorkflowStep.jobStatus?.darkColor,
        padding: "0.125rem 0.625rem",
        fontWeight: "600",
        fontSize: "0.875rem",
        borderRadius: "4px",
        flex: 1,
        whiteSpace: "nowrap",
      }}
    >
      {capitalize(jobWorkflowStep.jobStatus?.name) ?? NOT_SPECIFIED}
    </Box>
  )
}

export default JobStatusPill
