import gql from "graphql-tag"

export const ADD_ITEMS_TO_CART = gql`
  mutation AddItemsToCart($organizationItemIds: [ID!]!) {
    addItemsToCart(input: { organizationItemIds: $organizationItemIds }) {
      productOrder {
        id
        number
        status
        notes
        dateSubmitted
        currencyCode
        submittedBy {
          id
          firstName
          lastName
        }
        subTotal
        taxSummary {
          total
          taxRateAmounts {
            taxRate {
              id
              name
              rate
            }
            amount
          }
        }
        total
        lineItems {
          id
          number
          organizationItem {
            id
            code
            name
            description
            wholesalePrice
          }
          quantity
          unitPrice
          total
        }
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`
