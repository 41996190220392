import gql from "graphql-tag"

export const GET_ORGANIZATION_JOB_COUNTS = gql`
  query GetOrganizationJobCounts(
    $id: ID!
    $groupBy: MetricGrouping
    $breakdown: MetricBreakdown
    $start: LocalDateTime
    $end: LocalDateTime
  ) {
    getOrganizationById(id: $id) {
      id
      name
      jobCounts(groupBy: $groupBy, breakdown: $breakdown, start: $start, end: $end) {
        label
        data {
          label
          value
        }
      }
    }
  }
`
