import React, { FC } from "react"
import Box from "@mui/material/Box"

interface MasterInfoRowProps {
  breakpointSmall?: number
  breakpointLarge?: number
}

const MasterInfoRow: FC<MasterInfoRowProps> = ({
  children,
  breakpointSmall = 768,
  breakpointLarge = 924,
  ...props
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        marginBottom: "0.625rem",
        [theme.breakpoints.up(breakpointSmall)]: {
          marginBottom: "0.9375rem",
        },
        [theme.breakpoints.up(breakpointLarge)]: {
          marginBottom: "1.25rem",
        },
      })}
      {...props}
    >
      {children}
    </Box>
  )
}

export default MasterInfoRow
