/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import Box from "@mui/material/Box"
import { SxProps, Theme } from "@mui/material/styles"
import { SystemStyleObject } from "@mui/system"
interface SectionHeaderProps {
  readonly sx?: SxProps<Theme>
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ children, sx = {} }) => {
  return (
    <Box
      sx={(theme) => {
        return {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.5rem",
          paddingLeft: "0.75rem",
          color: (theme) => theme.fielderColors.black,
          minHeight: "48px",
          "& label": {
            fontWeight: 700,
            fontSize: "1.1rem",
          },
          [theme.breakpoints.up("sm")]: {
            padding: "0.125rem",
            paddingRight: "0.625rem",
            paddingLeft: "1.25rem",
          },
          ...(typeof sx === "function" ? sx(theme) : sx),
        } as SystemStyleObject<Theme>
      }}
    >
      {children}
    </Box>
  )
}

export default SectionHeader
