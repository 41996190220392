import React from "react"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import CropSquareOutlined from "@mui/icons-material/CropSquareOutlined"

interface ToolbarButtonProps {
  /**
   * Text displayed on the button tooltip. By Default it is the capitalized `format` string.
   * For instance, `bold` is displayed as `Bold`.
   */
  tooltip: string

  /**
   * Unconditionally disables the button
   *
   * Disable a button means that the button cannot be clicked (note it is not the opposite of isActive)
   */
  disabled?: boolean

  /**
   * On mouse down event is passed up to the parent with props that can be deconstructed in {editor, event, mark/block}
   */
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void

  /*
   * When a button is active it means the button is highlighted. For example, if in current position of the cursor,
   * the text is bold, the bold button should be active.
   */
  isActive?: boolean

  children?: JSX.Element | React.ReactNode
}

const ToolbarButton = React.forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  (
    {
      tooltip,
      children = <CropSquareOutlined />,
      disabled = false,
      onMouseDown,
      isActive = false,
      ...rest
    },
    ref
  ) => {
    return (
      <Tooltip placement="bottom" title={tooltip}>
        <Button
          aria-label={tooltip}
          disableRipple
          disabled={disabled}
          onMouseDown={onMouseDown}
          ref={ref}
          size="small"
          sx={[
            {
              minWidth: "36px",
              color: "#777",
              padding: "0.25rem",
              margin: "0 1px",
              "& .MuiSvgIcon-root": {
                fontSize: "1.2rem",
              },
            },
            isActive && {
              color: "#000",
              backgroundColor: "#F5F5F5",
            },
          ]}
          {...rest}
        >
          {children}
        </Button>
      </Tooltip>
    )
  }
)

ToolbarButton.displayName = "ToolbarButton"

export default ToolbarButton
