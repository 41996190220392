import React from "react"
import FormHelperText from "@mui/material/FormHelperText"

interface FieldHelperTextProps {
  readonly error?: boolean
  readonly message?: string | null
  readonly style?: React.CSSProperties
}

function FieldHelperText({ error = false, message = "", style = {} }: FieldHelperTextProps) {
  return (
    <FormHelperText
      error={error}
      sx={{
        marginTop: 0,
        marginLeft: "0.25rem",
        ...style,
      }}
    >
      {message}
    </FormHelperText>
  )
}

export default FieldHelperText
