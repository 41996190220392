import { DefaultPermission, Permission, PermissionOption } from "../../../../types"

const PERMISSION_OPTIONS_LEVEL_1: PermissionOption[] = [
  {
    id: "franchisees",
    permissions: [
      {
        id: "franchisees.view",
      },
      {
        id: "franchisees.create",
      },
      {
        id: "franchisees.update",
      },
      {
        id: "franchisees.impersonate",
      },
    ],
  },
  {
    id: "itemCatalog",
    permissions: [
      {
        id: "itemCatalog.manage", // create/update items
      },
      {
        id: "itemCatalog.import", // access the bulk import feature
      },
    ],
  },
  {
    id: "productOrders",
    permissions: [
      {
        id: "productOrders.view",
      },
      {
        id: "productOrders.process",
      },
    ],
  },
  {
    id: "manageUsersAndRoles",
  },
  {
    id: "manageJobWorkflows",
  },
  {
    id: "manageRegions",
  },
  // {
  //   id: "reports",
  //   permissions: [
  //     {
  //       id: "reports.franchiseeList",
  //     },
  //     {
  //       id: "reports.franchiseeSubscriptions",
  //     },
  //     {
  //       id: "reports.completedJobsByFranchisee",
  //     },
  //     {
  //       id: "reports.jobsMap",
  //     },
  //     {
  //       id: "reports.productOrderSummary",
  //     },
  //   ],
  // },
]

const PERMISSION_OPTIONS_LEVEL_2: PermissionOption[] = [
  {
    id: "jobs",
    permissions: [
      {
        id: "jobs.manageJobs",
      },
      {
        id: "jobs.estimates",
        permissions: [
          {
            id: "jobs.estimates.manage",
            requiredPermissionOptions: [
              {
                id: "jobs.estimates.view",
              },
            ]
          },
          {
            id: "jobs.estimates.view",
          },
          {
            id: "jobs.estimates.sendToCustomers",
            requiredPermissionOptions: [
              {
                id: "jobs.estimates.view",
              },
            ]
          },
        ],
      },
      {
        id: "jobs.manageAssignments",
      },
      {
        id: "jobs.manageWorkOrders",
      },
      {
        id: "jobs.invoices",
        permissions: [
          {
            id: "jobs.invoices.manage",
            requiredPermissionOptions: [
              {
                id: "jobs.invoices.view",
              },
            ]
          },
          {
            id: "jobs.invoices.view",
          },
          {
            id: "jobs.invoices.sendToCustomers",
            requiredPermissionOptions: [
              {
                id: "jobs.invoices.view",
              },
            ]
          },
          {
            id: "jobs.invoices.sendToPlugins",
            requiredPermissionOptions: [
              {
                id: "jobs.invoices.view",
              },
            ]
          },
        ],
      },
    ],
  },
  {
    id: "manageCustomers",
  },
  {
    id: "manageProductOrders",
  },
  {
    id: "manageInventory",
  },
  {
    id: "manageJobWorkflows",
  },
  {
    id: "manageUsersAndRoles",
  },
  {
    id: "manageEstimateTemplates",
  },
  {
    id: "manageWorkOrderTemplates",
  },
  {
    id: "manageEmailTemplates",
  },
  {
    id: "manageChecklistTemplates",
  },
  {
    id: "managePlugins",
  },
  {
    id: "manageTaxRates",
  },
  {
    id: "manageJobCategories",
  },
  {
    id: "manageOrganizationDetails",
  },
  {
    id: "manageFielderSubscription",
  },
]

const ALL_PERMISSIONS = PERMISSION_OPTIONS_LEVEL_1.concat(PERMISSION_OPTIONS_LEVEL_2)

function getPermissionOptions(orgLevel: number): PermissionOption[] {
  if (orgLevel === 1) {
    return PERMISSION_OPTIONS_LEVEL_1
  } else if (orgLevel === 2) {
    return PERMISSION_OPTIONS_LEVEL_2
  } else {
    return []
  }
}

/**
 * Uses a breadth-first search to find the given Permission by id
 * in the ALL_PERMISSIONS tree.
 * @param id
 * @returns
 */
function findPermission(id: string): PermissionOption | null {
  let current: PermissionOption[] = ALL_PERMISSIONS

  while (current) {
    const option = current.find((o) => o.id === id)
    if (option) {
      return option
    } else {
      current = current.map((o: PermissionOption): PermissionOption[] => o.permissions ?? []).flat()
    }
  }

  return null
}

function flattenPermissions(entry: PermissionOption): string[] {
  if (entry.permissions) {
    return entry.permissions.reduce((acc: string[], curr: PermissionOption) => {
      return [...acc, ...flattenPermissions(curr)]
    }, [])
  } else {
    let result = [entry.id]

    if (entry.requiredPermissionOptions) {
      const extras = entry.requiredPermissionOptions.reduce((acc: string[], curr: PermissionOption) => {
        return [...acc, ...flattenPermissions(curr)]
      }, [])
      result = result.concat(extras)
    }

    return result
  }
}

function getEnabledPermissionOptionIds(
  userOrganizationLevel: number,
  permissions: Permission[]
): string[] {
  const permIds = permissions.map((p) => p.id)
  const enabledPermIds = new Set<string>()
  if (userOrganizationLevel === 1) {
    if (permIds.includes(DefaultPermission.ReadOrganization)) {
      enabledPermIds.add("franchisees.view")
    }
    if (
      [DefaultPermission.CreateOrganization, DefaultPermission.ReadOrganization].every((v) =>
        permIds.includes(v)
      )
    ) {
      enabledPermIds.add("franchisees.create")
    }
    if (
      [DefaultPermission.UpdateOrganization, DefaultPermission.ReadOrganization].every((v) =>
        permIds.includes(v)
      )
    ) {
      enabledPermIds.add("franchisees.update")
    }
    if (
      [DefaultPermission.ImpersonateFranchisee, DefaultPermission.ReadOrganization].every((v) =>
        permIds.includes(v)
      )
    ) {
      enabledPermIds.add("franchisees.impersonate")
    }
    if (
      [
        DefaultPermission.CreateItem,
        DefaultPermission.ReadItem,
        DefaultPermission.UpdateItem,
        DefaultPermission.BulkImportItems,
      ].every((v) => permIds.includes(v))
    ) {
      enabledPermIds.add("itemCatalog.manage")
    }
    if (
      [DefaultPermission.ReadItem, DefaultPermission.BulkImportItems].every((v) =>
        permIds.includes(v)
      )
    ) {
      enabledPermIds.add("itemCatalog.import")
    }
    if ([DefaultPermission.ReadProductOrder].every((v) => permIds.includes(v))) {
      enabledPermIds.add("productOrders.view")
    }
    if (
      [DefaultPermission.ReadProductOrder, DefaultPermission.UpdateProductOrder].every((v) =>
        permIds.includes(v)
      )
    ) {
      enabledPermIds.add("productOrders.process")
    }
    if (
      [
        DefaultPermission.CreateUser,
        DefaultPermission.ReadUser,
        DefaultPermission.UpdateUser,
        DefaultPermission.SendAccountActivationCode,
        DefaultPermission.ReadPermission,
        DefaultPermission.ReadRole,
        DefaultPermission.CreateRole,
        DefaultPermission.UpdateRole,
      ].every((v) => permIds.includes(v))
    ) {
      enabledPermIds.add("manageUsersAndRoles")
    }
    if (
      [
        DefaultPermission.CreateJobWorkflow,
        DefaultPermission.ReadJobWorkflow,
        DefaultPermission.UpdateJobWorkflow,
        DefaultPermission.CreateJobStatus,
        DefaultPermission.ReadJobStatus,
        DefaultPermission.UpdateJobStatus,
      ].every((v) => permIds.includes(v))
    ) {
      enabledPermIds.add("manageJobWorkflows")
    }
    if (
      [
        DefaultPermission.CreateRegion,
        DefaultPermission.ReadRegion,
        DefaultPermission.UpdateRegion,
      ].every((v) => permIds.includes(v))
    ) {
      enabledPermIds.add("manageRegions")
    }
  } else if (userOrganizationLevel === 2) {
    permIds.forEach((id) => {
      if ([
        DefaultPermission.CreateJob,
        DefaultPermission.ReadJob,
        DefaultPermission.UpdateJob,
        DefaultPermission.ReadEstimateRequest,
        DefaultPermission.SendJobEmail,
        DefaultPermission.ReadEmailTemplate,
      ].every((v) => permIds.includes(v))) {
        enabledPermIds.add("jobs.manageJobs")
      }

      if (
        [
          DefaultPermission.CreateEstimate,
          DefaultPermission.ReadEstimate,
          DefaultPermission.UpdateEstimate,
          DefaultPermission.ReadEstimateTemplate,
          DefaultPermission.ReadContractTemplate,
          DefaultPermission.ReadTaxRateGroup,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("jobs.estimates.manage")
      }

      if (id === DefaultPermission.ReadEstimate) {
        enabledPermIds.add("jobs.estimates.view")
      }

      if ([
        DefaultPermission.SendEstimateToCustomer,
        DefaultPermission.SendJobEmail,
        DefaultPermission.ReadEmailTemplate
      ].every((v) => permIds.includes(v))) {
        enabledPermIds.add("jobs.estimates.sendToCustomers")
      }

      if (
        [
          DefaultPermission.CreateJobAssignment,
          DefaultPermission.ReadJobAssignment,
          DefaultPermission.UpdateJobAssignment,
          DefaultPermission.DeleteJobAssignment,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("jobs.manageAssignments")
      }

      if (
        [
          DefaultPermission.CreateWorkOrder,
          DefaultPermission.ReadWorkOrder,
          DefaultPermission.UpdateWorkOrder,
          DefaultPermission.ReadWorkOrderTemplate,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("jobs.manageWorkOrders")
      }

      if (
        [
          DefaultPermission.CreateInvoice,
          DefaultPermission.ReadInvoice,
          DefaultPermission.UpdateInvoice,
          DefaultPermission.ReadTaxRateGroup,
        ].every((v) => permIds.includes(v))) {
        enabledPermIds.add("jobs.invoices.manage")
      }

      if (id === DefaultPermission.ReadInvoice) {
        enabledPermIds.add("jobs.invoices.view")
      }

      if (
        [
          DefaultPermission.SendInvoiceToCustomer,
          DefaultPermission.SendJobEmail,
          DefaultPermission.ReadEmailTemplate,
        ].every((v) => permIds.includes(v))) {
        enabledPermIds.add("jobs.invoices.sendToCustomers")
      }

      if (
        [
          DefaultPermission.SendInvoiceToPlugin,
          DefaultPermission.ReadOrganizationPlugin
        ].every((v) => permIds.includes(v))) {
        enabledPermIds.add("jobs.invoices.sendToPlugins")
      }

      if (
        [
          DefaultPermission.CreateCustomer,
          DefaultPermission.ReadCustomer,
          DefaultPermission.UpdateCustomer,
          DefaultPermission.ReadOrganizationPlugin,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageCustomers")
      }

      if (
        [
          DefaultPermission.CreateProductOrder,
          DefaultPermission.ReadProductOrder,
          DefaultPermission.UpdateProductOrder
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageProductOrders")
      }

      if (
        [
          DefaultPermission.CreateOrganizationItem,
          DefaultPermission.ReadOrganizationItem,
          DefaultPermission.UpdateOrganizationItem,
          DefaultPermission.SyncOrganizationItem,
          DefaultPermission.CreateOrganizationItemList,
          DefaultPermission.ReadOrganizationItemList,
          DefaultPermission.UpdateOrganizationItemList,
          DefaultPermission.DeleteOrganizationItemList,
          DefaultPermission.ReadOrganizationPlugin
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageInventory")
      }

      if (
        [
          DefaultPermission.CreateJobWorkflow,
          DefaultPermission.ReadJobWorkflow,
          DefaultPermission.UpdateJobWorkflow,
          DefaultPermission.CreateJobStatus,
          DefaultPermission.ReadJobStatus,
          DefaultPermission.UpdateJobStatus
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageJobWorkflows")
      }

      if (
        [
          DefaultPermission.CreateUser,
          DefaultPermission.ReadUser,
          DefaultPermission.UpdateUser,
          DefaultPermission.SendAccountActivationCode,
          DefaultPermission.ReadPermission,
          DefaultPermission.CreateRole,
          DefaultPermission.ReadRole,
          DefaultPermission.UpdateRole,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageUsersAndRoles")
      }

      if (
        [
          DefaultPermission.CreateEstimateTemplate,
          DefaultPermission.ReadEstimateTemplate,
          DefaultPermission.UpdateEstimateTemplate,
          DefaultPermission.DeleteEstimateTemplate,
          DefaultPermission.ReadContractTemplate,
          DefaultPermission.ReadTaxRateGroup,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageEstimateTemplates")
      }

      if (
        [
          DefaultPermission.CreateWorkOrderTemplate,
          DefaultPermission.ReadWorkOrderTemplate,
          DefaultPermission.UpdateWorkOrderTemplate,
          DefaultPermission.DeleteWorkOrderTemplate,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageWorkOrderTemplates")
      }

      if (
        [
          DefaultPermission.CreateEmailTemplate,
          DefaultPermission.ReadEmailTemplate,
          DefaultPermission.UpdateEmailTemplate,
          DefaultPermission.DeleteEmailTemplate,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageEmailTemplates")
      }

      if (
        [
          DefaultPermission.CreateChecklistTemplate,
          DefaultPermission.ReadChecklistTemplate,
          DefaultPermission.UpdateChecklistTemplate,
          DefaultPermission.DeleteChecklistTemplate,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageChecklistTemplates")
      }

      if (
        [
          DefaultPermission.ReadOrganizationPlugin,
          DefaultPermission.UpdateOrganizationPlugin,
          DefaultPermission.DeleteOrganizationPlugin,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("managePlugins")
      }

      if (
        [
          DefaultPermission.CreateTaxRateGroup,
          DefaultPermission.ReadTaxRateGroup,
          DefaultPermission.UpdateTaxRateGroup,
          DefaultPermission.SyncTaxRateGroup,
          DefaultPermission.ReadOrganizationPlugin,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageTaxRates")
      }

      if (
        [
          DefaultPermission.CreateJobCategory,
          DefaultPermission.ReadJobCategory,
          DefaultPermission.UpdateJobCategory
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageJobCategories")
      }

      if (
        [
          DefaultPermission.ReadOrganization,
          DefaultPermission.UpdateOrganization,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageOrganizationDetails")
      }

      if (
        [
          DefaultPermission.CreateSubscription,
          DefaultPermission.SyncSubscription,
          DefaultPermission.ReadSubscriptionOption,
          DefaultPermission.UpdateOrganization,
        ].every((v) => permIds.includes(v))
      ) {
        enabledPermIds.add("manageFielderSubscription")
      }
    })
  }
  return [...enabledPermIds]
}

function getEnabledPermissions(
  userOrganizationLevel: number,
  enabledPermissionOptionIds: string[]
): DefaultPermission[] {
  const permissions = new Set<DefaultPermission>()
  if (userOrganizationLevel === 1) {
    if (enabledPermissionOptionIds.includes("franchisees.view")) {
      permissions.add(DefaultPermission.ReadOrganization)
    }
    if (enabledPermissionOptionIds.includes("franchisees.create")) {
      permissions.add(DefaultPermission.CreateOrganization)
      permissions.add(DefaultPermission.ReadOrganization)
    }
    if (enabledPermissionOptionIds.includes("franchisees.update")) {
      permissions.add(DefaultPermission.UpdateOrganization)
      permissions.add(DefaultPermission.ReadOrganization)
    }
    if (enabledPermissionOptionIds.includes("franchisees.impersonate")) {
      permissions.add(DefaultPermission.ImpersonateFranchisee)
      permissions.add(DefaultPermission.ReadOrganization)
    }
    if (enabledPermissionOptionIds.includes("itemCatalog.manage")) {
      permissions.add(DefaultPermission.CreateItem)
      permissions.add(DefaultPermission.ReadItem)
      permissions.add(DefaultPermission.UpdateItem)
      permissions.add(DefaultPermission.BulkImportItems)
    }
    if (enabledPermissionOptionIds.includes("itemCatalog.import")) {
      permissions.add(DefaultPermission.ReadItem)
      permissions.add(DefaultPermission.BulkImportItems)
    }
    if (enabledPermissionOptionIds.includes("productOrders.view")) {
      permissions.add(DefaultPermission.ReadProductOrder)
    }
    if (enabledPermissionOptionIds.includes("productOrders.process")) {
      permissions.add(DefaultPermission.ReadProductOrder)
      permissions.add(DefaultPermission.UpdateProductOrder)
    }
    if (enabledPermissionOptionIds.includes("manageUsersAndRoles")) {
      permissions.add(DefaultPermission.CreateUser)
      permissions.add(DefaultPermission.ReadUser)
      permissions.add(DefaultPermission.UpdateUser)
      permissions.add(DefaultPermission.SendAccountActivationCode)
      permissions.add(DefaultPermission.ReadPermission)
      permissions.add(DefaultPermission.ReadRole)
      permissions.add(DefaultPermission.CreateRole)
      permissions.add(DefaultPermission.UpdateRole)
    }
    if (enabledPermissionOptionIds.includes("manageJobWorkflows")) {
      permissions.add(DefaultPermission.CreateJobWorkflow)
      permissions.add(DefaultPermission.ReadJobWorkflow)
      permissions.add(DefaultPermission.UpdateJobWorkflow)
      permissions.add(DefaultPermission.CreateJobStatus)
      permissions.add(DefaultPermission.ReadJobStatus)
      permissions.add(DefaultPermission.UpdateJobStatus)
    }
    if (enabledPermissionOptionIds.includes("manageRegions")) {
      permissions.add(DefaultPermission.CreateRegion)
      permissions.add(DefaultPermission.ReadRegion)
      permissions.add(DefaultPermission.UpdateRegion)
    }
  } else if (userOrganizationLevel === 2) {
    if (enabledPermissionOptionIds.includes("jobs.manageJobs")) {
      permissions.add(DefaultPermission.CreateJob)
      permissions.add(DefaultPermission.ReadJob)
      permissions.add(DefaultPermission.UpdateJob)
      permissions.add(DefaultPermission.ReadEstimateRequest)
      permissions.add(DefaultPermission.SendJobEmail)
      permissions.add(DefaultPermission.ReadEmailTemplate)
    }
    if (enabledPermissionOptionIds.includes("jobs.estimates.manage")) {
      permissions.add(DefaultPermission.CreateEstimate)
      permissions.add(DefaultPermission.ReadEstimate)
      permissions.add(DefaultPermission.UpdateEstimate)
      permissions.add(DefaultPermission.ReadEstimateTemplate)
      permissions.add(DefaultPermission.ReadContractTemplate)
      permissions.add(DefaultPermission.ReadTaxRateGroup)
    }
    if (enabledPermissionOptionIds.includes("jobs.estimates.view")) {
      permissions.add(DefaultPermission.ReadEstimate)
    }
    if (enabledPermissionOptionIds.includes("jobs.estimates.sendToCustomers")) {
      permissions.add(DefaultPermission.SendEstimateToCustomer)
      permissions.add(DefaultPermission.SendJobEmail)
      permissions.add(DefaultPermission.ReadEmailTemplate)
    }
    if (enabledPermissionOptionIds.includes("jobs.manageAssignments")) {
      permissions.add(DefaultPermission.CreateJobAssignment)
      permissions.add(DefaultPermission.ReadJobAssignment)
      permissions.add(DefaultPermission.UpdateJobAssignment)
      permissions.add(DefaultPermission.DeleteJobAssignment)
    }
    if (enabledPermissionOptionIds.includes("jobs.manageWorkOrders")) {
      permissions.add(DefaultPermission.CreateWorkOrder)
      permissions.add(DefaultPermission.ReadWorkOrder)
      permissions.add(DefaultPermission.UpdateWorkOrder)
      permissions.add(DefaultPermission.ReadWorkOrderTemplate)
    }
    if (enabledPermissionOptionIds.includes("jobs.invoices.manage")) {
      permissions.add(DefaultPermission.CreateInvoice)
      permissions.add(DefaultPermission.ReadInvoice)
      permissions.add(DefaultPermission.UpdateInvoice)
      permissions.add(DefaultPermission.ReadTaxRateGroup)
    }
    if (enabledPermissionOptionIds.includes("jobs.invoices.view")) {
      permissions.add(DefaultPermission.ReadInvoice)
    }
    if (enabledPermissionOptionIds.includes("jobs.invoices.sendToCustomers")) {
      permissions.add(DefaultPermission.SendInvoiceToCustomer)
      permissions.add(DefaultPermission.SendJobEmail)
      permissions.add(DefaultPermission.ReadEmailTemplate)
    }
    if (enabledPermissionOptionIds.includes("jobs.invoices.sendToPlugins")) {
      permissions.add(DefaultPermission.SendInvoiceToPlugin)
      permissions.add(DefaultPermission.ReadOrganizationPlugin)
    }
    if (enabledPermissionOptionIds.includes("manageCustomers")) {
      permissions.add(DefaultPermission.CreateCustomer)
      permissions.add(DefaultPermission.ReadCustomer)
      permissions.add(DefaultPermission.UpdateCustomer)
      permissions.add(DefaultPermission.ReadOrganizationPlugin)
    }
    if (enabledPermissionOptionIds.includes("manageProductOrders")) {
      permissions.add(DefaultPermission.CreateProductOrder)
      permissions.add(DefaultPermission.ReadProductOrder)
      permissions.add(DefaultPermission.UpdateProductOrder)
    }
    if (enabledPermissionOptionIds.includes("manageInventory")) {
      permissions.add(DefaultPermission.CreateOrganizationItem)
      permissions.add(DefaultPermission.ReadOrganizationItem)
      permissions.add(DefaultPermission.UpdateOrganizationItem)
      permissions.add(DefaultPermission.SyncOrganizationItem)
      permissions.add(DefaultPermission.CreateOrganizationItemList)
      permissions.add(DefaultPermission.ReadOrganizationItemList)
      permissions.add(DefaultPermission.UpdateOrganizationItemList)
      permissions.add(DefaultPermission.DeleteOrganizationItemList)
      permissions.add(DefaultPermission.ReadOrganizationPlugin)
    }
    if (enabledPermissionOptionIds.includes("manageJobWorkflows")) {
      permissions.add(DefaultPermission.CreateJobWorkflow)
      permissions.add(DefaultPermission.ReadJobWorkflow)
      permissions.add(DefaultPermission.UpdateJobWorkflow)
      permissions.add(DefaultPermission.CreateJobStatus)
      permissions.add(DefaultPermission.ReadJobStatus)
      permissions.add(DefaultPermission.UpdateJobStatus)
    }
    if (enabledPermissionOptionIds.includes("manageUsersAndRoles")) {
      permissions.add(DefaultPermission.CreateUser)
      permissions.add(DefaultPermission.ReadUser)
      permissions.add(DefaultPermission.UpdateUser)
      permissions.add(DefaultPermission.SendAccountActivationCode)
      permissions.add(DefaultPermission.ReadPermission)
      permissions.add(DefaultPermission.CreateRole)
      permissions.add(DefaultPermission.ReadRole)
      permissions.add(DefaultPermission.UpdateRole)
    }
    if (enabledPermissionOptionIds.includes("manageEstimateTemplates")) {
      permissions.add(DefaultPermission.CreateEstimateTemplate)
      permissions.add(DefaultPermission.ReadEstimateTemplate)
      permissions.add(DefaultPermission.UpdateEstimateTemplate)
      permissions.add(DefaultPermission.DeleteEstimateTemplate)
      permissions.add(DefaultPermission.ReadContractTemplate)
      permissions.add(DefaultPermission.ReadTaxRateGroup)
    }
    if (enabledPermissionOptionIds.includes("manageWorkOrderTemplates")) {
      permissions.add(DefaultPermission.CreateWorkOrderTemplate)
      permissions.add(DefaultPermission.ReadWorkOrderTemplate)
      permissions.add(DefaultPermission.UpdateWorkOrderTemplate)
      permissions.add(DefaultPermission.DeleteWorkOrderTemplate)
    }
    if (enabledPermissionOptionIds.includes("manageEmailTemplates")) {
      permissions.add(DefaultPermission.CreateEmailTemplate)
      permissions.add(DefaultPermission.ReadEmailTemplate)
      permissions.add(DefaultPermission.UpdateEmailTemplate)
      permissions.add(DefaultPermission.DeleteEmailTemplate)
    }
    if (enabledPermissionOptionIds.includes("manageChecklistTemplates")) {
      permissions.add(DefaultPermission.CreateChecklistTemplate)
      permissions.add(DefaultPermission.ReadChecklistTemplate)
      permissions.add(DefaultPermission.UpdateChecklistTemplate)
      permissions.add(DefaultPermission.DeleteChecklistTemplate)
    }
    if (enabledPermissionOptionIds.includes("managePlugins")) {
      permissions.add(DefaultPermission.ReadOrganizationPlugin)
      permissions.add(DefaultPermission.UpdateOrganizationPlugin)
      permissions.add(DefaultPermission.DeleteOrganizationPlugin)
    }
    if (enabledPermissionOptionIds.includes("manageTaxRates")) {
      permissions.add(DefaultPermission.CreateTaxRateGroup)
      permissions.add(DefaultPermission.ReadTaxRateGroup)
      permissions.add(DefaultPermission.UpdateTaxRateGroup)
      permissions.add(DefaultPermission.SyncTaxRateGroup)
      permissions.add(DefaultPermission.ReadOrganizationPlugin)
    }
    if (enabledPermissionOptionIds.includes("manageJobCategories")) {
      permissions.add(DefaultPermission.CreateJobCategory)
      permissions.add(DefaultPermission.ReadJobCategory)
      permissions.add(DefaultPermission.UpdateJobCategory)
    }
    if (enabledPermissionOptionIds.includes("manageOrganizationDetails")) {
      permissions.add(DefaultPermission.ReadOrganization)
      permissions.add(DefaultPermission.UpdateOrganization)
    }
    if (enabledPermissionOptionIds.includes("manageFielderSubscription")) {
      permissions.add(DefaultPermission.CreateSubscription)
      permissions.add(DefaultPermission.SyncSubscription)
      permissions.add(DefaultPermission.ReadSubscriptionOption)
      permissions.add(DefaultPermission.UpdateOrganization)
    }
  }

  return [...permissions]
}

export {
  getEnabledPermissionOptionIds,
  getEnabledPermissions,
  getPermissionOptions,
  flattenPermissions,
  findPermission,
  ALL_PERMISSIONS,
  PERMISSION_OPTIONS_LEVEL_1,
  PERMISSION_OPTIONS_LEVEL_2,
}
