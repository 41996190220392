import React from "react"
import { useTranslation } from "react-i18next"
import Button from "@mui/material/Button"
import UnarchiveIcon from "@mui/icons-material/UnarchiveOutlined"
import FielderIconButton from "./FielderIconButton"

interface Props {
  onClick: () => void
  disabled?: boolean
  iconOnly?: boolean
}

function UnarchiveButton({ onClick, disabled, iconOnly = false }: Props) {
  const { t } = useTranslation()

  return iconOnly ? (
    <FielderIconButton
      aria-label={t("unarchive") as string}
      data-testid="unarchiveButton"
      disabled={disabled}
      onClick={onClick}
      title={t("unarchive") as string}
    >
      <UnarchiveIcon />
    </FielderIconButton>
  ) : (
    <Button
      disabled={disabled}
      onClick={onClick}
      size="small"
      startIcon={<UnarchiveIcon />}
      variant="text"
    >
      <span>{t("unarchive")}</span>
    </Button>
  )
}

export default UnarchiveButton
