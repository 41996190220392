import React from "react"
import { useTranslation } from "react-i18next"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"
import SelectorField from "./SelectorField"
import { CurrencyCode } from "../types"

const CURRENCY_OPTIONS = [
  { id: CurrencyCode.CAD, name: CurrencyCode.CAD },
  { id: CurrencyCode.USD, name: CurrencyCode.USD },
  { id: CurrencyCode.EUR, name: CurrencyCode.EUR },
  { id: CurrencyCode.GBP, name: CurrencyCode.GBP },
]

interface Props {
  readonly name?: string
  readonly onChange?: (value: CurrencyCode) => void
  readonly value?: CurrencyCode
  readonly style?: React.CSSProperties
  readonly sx?: SxProps<Theme>
  readonly required?: boolean
}

function CurrencyCodeSelect({ name, onChange, style, sx, value, required = false }: Props) {
  const { t } = useTranslation()

  return (
    <SelectorField
      label={t("currency") as string}
      name={name}
      onChange={(selected) => onChange?.(selected.id)}
      options={CURRENCY_OPTIONS}
      required={required}
      style={style}
      sx={sx}
      value={value}
      variant="outlined"
    />
  )
}

export default CurrencyCodeSelect
