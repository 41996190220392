import gql from "graphql-tag"

export const DEACTIVATE_ORGANIZATION_ITEMS = gql`
  mutation DeactivateOrganizationItems($organizationItemIds: [ID!]!) {
    deactivateOrganizationItems(input: { organizationItemIds: $organizationItemIds }) {
      count
    }
  }
`

