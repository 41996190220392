import gql from "graphql-tag"
import FullOrganizationItem from "./fragments/FullOrganizationItem"

export const CREATE_ORGANIZATION_ITEM = gql`
  mutation CreateOrganizationItem(
    $code: String!
    $name: String!
    $description: String
    $category: String
    $isIntendedForResale: Boolean!
    $type: ItemType
    $stockType: StockType
    $msrp: BigDecimal
    $wholesalePrice: BigDecimal
    $leadTimeDays: Int
    $palletQty: Int
    $packSize: Int
    $isPackBreakable: Boolean
    $qtyOnHand: Int!
    $reorderPoint: Int
    $reorderQty: Int
    $isDynamicallyPriced: Boolean!
    $unitSalePrice: BigDecimal
    $unitSalePriceMarkup: BigDecimal
    $components: [OrganizationItemBundleComponentInput!]
    $showComponentsOnTransactions: Boolean
  ) {
    createOrganizationItem(
      input: {
        code: $code
        name: $name
        description: $description
        category: $category
        isIntendedForResale: $isIntendedForResale
        type: $type
        stockType: $stockType
        msrp: $msrp
        wholesalePrice: $wholesalePrice
        leadTimeDays: $leadTimeDays
        palletQty: $palletQty
        packSize: $packSize
        isPackBreakable: $isPackBreakable
        qtyOnHand: $qtyOnHand
        reorderPoint: $reorderPoint
        reorderQty: $reorderQty
        isDynamicallyPriced: $isDynamicallyPriced
        unitSalePrice: $unitSalePrice
        unitSalePriceMarkup: $unitSalePriceMarkup
        components: $components
        showComponentsOnTransactions: $showComponentsOnTransactions
      }
    ) {
      organizationItem {
        ...FullOrganizationItem
      }
    }
  }
  ${FullOrganizationItem}
`
