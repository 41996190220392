import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { Navigate, NavigateProps, useLocation, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import gql from "graphql-tag"
import { useMutation, useQuery } from "@apollo/client"
import CircularProgress from "@mui/material/CircularProgress"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"

import { LOGIN_AS_FRANCHISEE } from "~/queries/loginAsFranchisee"
import FranchiseeForm from "./components/FranchiseeForm"
import ConfirmationDialog from "~/components/ConfirmationDialog"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import { parseGraphQLErrorCode, FRANCHISEES, isBlank } from "~/util"
import { EditOrganizationInput, Snack } from "~/types"
import { useAuth } from "~/context/AuthContext"
import FranchiseeStatic from "./components/FranchiseeStatic"
import FullAddress from "~/queries/fragments/fullAddress"

const OrganizationFragment = gql`
  fragment OrganizationFragment on Organization {
    id
    tradeName
    legalName
    level
    status
    email
    phoneNumber
    websiteURL
    timeZone
    languageCode
    currencyCode
    accountNumber
    accountManager {
      id
      email
      firstName
      lastName
    }
    region {
      id
      name
      code
      currencyCode
    }
    address {
      ...FullAddress
    }
    owner {
      id
      lastName
      firstName
      email
      mobilePhoneNumber
      status
      lastLogin
    }
    fielderSubscription {
      id
      status
      pricePoint {
        id
        name
        product {
          id
          name
          nameKey
          monthlyJobLimit
        }
        type
        interval
        effectiveMonthlyRate
      }
      startDate
      endDate
    }
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    updatedAt
    updatedBy {
      id
      firstName
      lastName
    }
  }
  ${FullAddress}
`

const GET_FRANCHISEE = gql`
  query GetFranchisee($id: ID!) {
    getOrganizationById(id: $id) {
      ...OrganizationFragment
    }
  }
  ${OrganizationFragment}
`

const EDIT_FRANCHISEE = gql`
  mutation EditFranchisee(
    $id: ID!
    $tradeName: String
    $legalName: String
    $phoneNumber: String
    $email: String
    $websiteURL: String
    $address: AddressInput
    $timeZone: String
    $ownerEmail: String
    $ownerFirstName: String
    $ownerLastName: String
    $ownerMobilePhoneNumber: String
    $accountManagerUserId: ID
    $accountNumber: String
    $languageCode: LanguageCode
    $shouldSendInvitation: Boolean
  ) {
    editOrganization(
      input: {
        id: $id
        tradeName: $tradeName
        legalName: $legalName
        phoneNumber: $phoneNumber
        email: $email
        websiteURL: $websiteURL
        address: $address
        timeZone: $timeZone
        ownerEmail: $ownerEmail
        ownerFirstName: $ownerFirstName
        ownerLastName: $ownerLastName
        ownerMobilePhoneNumber: $ownerMobilePhoneNumber
        accountManagerUserId: $accountManagerUserId
        accountNumber: $accountNumber
        languageCode: $languageCode
        shouldSendInvitation: $shouldSendInvitation
      }
    ) {
      organization {
        ...OrganizationFragment
      }
    }
  }
  ${OrganizationFragment}
`

const SEND_INVITATION = gql`
  mutation SendInvitation($id: ID!) {
    editOrganization(input: { id: $id, shouldSendInvitation: true }) {
      organization {
        ...OrganizationFragment
      }
    }
  }
  ${OrganizationFragment}
`

const ARCHIVE_FRANCHISEE = gql`
  mutation ArchiveFranchisee($id: ID!) {
    archiveOrganization(id: $id) {
      archived
    }
  }
`

function EditFranchiseePage() {
  const { t } = useTranslation()
  const { id } = useParams()
  const location = useLocation()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const { enterImpersonateMode } = useAuth()
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [showConfirmSendInvitationDialog, setShowConfirmSendInvitationDialog] =
    useState<boolean>(false)
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<boolean>(false)
  const [showLoginAsPrompt, setShowLoginAsPrompt] = useState<boolean>(false)
  const [snack, setSnack] = useState<Snack | null>(() => location?.state?.snack ?? null)

  const [loginAsFranchisee, { loading: loginAsFranchiseeLoading }] = useMutation(
    LOGIN_AS_FRANCHISEE,
    {
      onCompleted: (data) => {
        const { impersonateFranchisee } = data
        setShowLoginAsPrompt(false)
        enterImpersonateMode?.(impersonateFranchisee.organization, impersonateFranchisee.authToken)
      },
    }
  )

  const [editFranchisee, { loading: editFranchiseeLoading, error: editFranchiseeError }] =
    useMutation(EDIT_FRANCHISEE, {
      onCompleted: () => {
        setInEditMode(false)
        setSnack({
          messageKey: "messages.changesSaved",
          variant: "success",
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    })

  const [sendInvitation, { loading: sendInvitationLoading }] = useMutation(SEND_INVITATION, {
    onCompleted: () => {
      setInEditMode(false)
      setShowConfirmSendInvitationDialog(false)
      setSnack({
        messageKey: "component.franchiseeForm.messages.invitationSent",
        messageOptions: { email: franchisee?.owner?.email },
        variant: "success",
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [archiveFranchisee, { loading: archiveFranchiseeLoading }] = useMutation(
    ARCHIVE_FRANCHISEE,
    {
      onCompleted: () => {
        setShowConfirmDeleteDialog(false)
        setRedirectTo({
          to: "/app/franchisees/list",
          replace: false,
          state: {
            snack: {
              messageKey: "component.franchiseeForm.messages.franchiseeDeleted",
              variant: "success",
            },
          },
        })
      },
      onError: (error) => {
        setShowConfirmDeleteDialog(false)
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const {
    data,
    error: getFranchiseeError,
    loading: getFranchiseeLoading,
  } = useQuery(GET_FRANCHISEE, {
    fetchPolicy: "cache-and-network",
    variables: { id },
  })

  const franchisee = data?.getOrganizationById
  const error = getFranchiseeError || editFranchiseeError

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(FRANCHISEES.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(null)} snack={snack} /> : null}
      <MainLayout activeSection={FRANCHISEES}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: FRANCHISEES.path, titleKey: FRANCHISEES.titleKey }]}
            icon={FRANCHISEES.icon}
            leafTitleKey="editFranchisee"
          />
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            padding: "0.5rem",
            paddingBottom: "8rem",
            maxWidth: "110rem",
            [theme.breakpoints.up("sm")]: {
              padding: "1rem 2rem",
              paddingBottom: "8rem",
            },
          })}
        >
          {error ? (
            <Box sx={{ margin: "0 auto 2rem 0" }}>
              <Alert severity="error" variant="filled">
                {`${t("error.general.title")} ${t(parseGraphQLErrorCode(error))}`}
              </Alert>
            </Box>
          ) : null}
          {getFranchiseeLoading ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : inEditMode ? (
            <FranchiseeForm
              franchisee={franchisee}
              handleSubmit={(updatedFranchisee: EditOrganizationInput) => {
                const organization = {
                  id: updatedFranchisee.id,
                  tradeName: updatedFranchisee.tradeName,
                  legalName: updatedFranchisee.legalName,
                  phoneNumber: updatedFranchisee.phoneNumber,
                  email: updatedFranchisee.email,
                  websiteURL: updatedFranchisee.websiteURL,
                  languageCode: updatedFranchisee.languageCode,
                  address: {
                    addressString: updatedFranchisee.address?.addressString,
                    latitude: updatedFranchisee.address?.latitude,
                    longitude: updatedFranchisee.address?.longitude,
                    streetNumber: updatedFranchisee.address?.streetNumber,
                    route: updatedFranchisee.address?.route,
                    locality: updatedFranchisee.address?.locality,
                    administrativeAreaLevel1: updatedFranchisee.address?.administrativeAreaLevel1,
                    administrativeAreaLevel2: updatedFranchisee.address?.administrativeAreaLevel2,
                    postalCode: updatedFranchisee.address?.postalCode,
                    country: updatedFranchisee.address?.country,
                  },
                  ownerUserId: updatedFranchisee.ownerUserId ?? undefined,
                  ownerEmail:
                    updatedFranchisee.ownerEmail && updatedFranchisee.ownerEmail.trim().length > 0
                      ? updatedFranchisee.ownerEmail
                      : undefined,
                  ownerFirstName:
                    !isBlank(updatedFranchisee.ownerFirstName) || updatedFranchisee.ownerUserId
                      ? updatedFranchisee.ownerFirstName
                      : undefined,
                  ownerLastName:
                    !isBlank(updatedFranchisee.ownerLastName) || updatedFranchisee.ownerUserId
                      ? updatedFranchisee.ownerLastName
                      : undefined,
                  ownerMobilePhoneNumber:
                    !isBlank(updatedFranchisee.ownerMobilePhoneNumber) ||
                    updatedFranchisee.ownerUserId
                      ? updatedFranchisee.ownerMobilePhoneNumber
                      : undefined,
                  accountManagerUserId: updatedFranchisee.accountManagerUserId,
                  accountNumber: updatedFranchisee.accountNumber,
                  shouldSendInvitation: updatedFranchisee.shouldSendInvitation,
                }

                editFranchisee({
                  variables: organization,
                })
              }}
              loading={editFranchiseeLoading}
              onCancel={() => {
                setInEditMode(false)
              }}
            />
          ) : (
            <FranchiseeStatic
              franchisee={franchisee}
              onClickDelete={() => {
                setShowConfirmDeleteDialog(true)
              }}
              onClickEdit={() => setInEditMode(true)}
              onClickLoginAs={() => setShowLoginAsPrompt(true)}
              onClickSendInvitation={() => {
                if (franchisee?.owner?.email) {
                  setShowConfirmSendInvitationDialog(true)
                }
              }}
              sendingInvitation={sendInvitationLoading}
            />
          )}
        </Box>
      </MainLayout>
      {showConfirmSendInvitationDialog && franchisee ? (
        <ConfirmationDialog
          description={t("component.franchiseeForm.messages.confirmSendInvitation.description", {
            email: franchisee.owner.email,
          })}
          id="confirm-send-invitation"
          isLoading={editFranchiseeLoading}
          negativeButtonTitle={t("no")}
          onCancel={() => {
            setShowConfirmSendInvitationDialog(false)
          }}
          onConfirm={() => {
            if (franchisee?.owner?.email) {
              sendInvitation({
                variables: {
                  id: franchisee.id,
                },
              })
            } else {
              setShowConfirmSendInvitationDialog(false)
            }
          }}
          positiveButtonTitle={t("yes")}
          title={t("component.franchiseeForm.messages.confirmSendInvitation.title")}
          titleBackgroundColor="#ffffff"
        />
      ) : null}
      {showConfirmDeleteDialog && franchisee ? (
        <ConfirmationDialog
          description={t("component.franchiseeForm.messages.confirmDeleteFranchisee.description")}
          id="confirm-delete-franchisee"
          isLoading={archiveFranchiseeLoading}
          negativeButtonTitle={t("no")}
          onCancel={() => {
            setShowConfirmDeleteDialog(false)
          }}
          onConfirm={() => {
            archiveFranchisee({ variables: { id: franchisee.id } })
          }}
          positiveButtonTitle={t("yes")}
          title={t("component.franchiseeForm.messages.confirmDeleteFranchisee.title")}
          titleBackgroundColor="#ffffff"
        />
      ) : null}
      {showLoginAsPrompt && franchisee ? (
        <ConfirmationDialog
          description={t("component.loginAsFranchiseeConfirmation.message", {
            franchiseeName: franchisee.tradeName,
          })}
          id="login-as-franchisee-confirmation"
          isLoading={loginAsFranchiseeLoading}
          negativeButtonTitle={t("cancel")}
          onCancel={() => {
            setShowLoginAsPrompt(false)
          }}
          onConfirm={() => {
            loginAsFranchisee({ variables: { organizationId: franchisee.id } })
          }}
          positiveButtonTitle={t("proceed")}
          title={t("component.loginAsFranchiseeConfirmation.title", {
            franchiseeName: franchisee.tradeName,
          })}
          titleBackgroundColor="#ffffff"
        />
      ) : null}
    </>
  )
}

export default EditFranchiseePage
