import gql from "graphql-tag"
import FullLineItem from "./checklistLineItem"

export default gql`
  fragment ChecklistFragment on Checklist {
    id
    type
    diagram
    projectDescription
    installers {
      id
      firstName
      lastName
    }
    installationEquipment
    installationDate
    job {
      id
    }
    lineItems {
      ...FullLineItem
    }
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    updatedAt
    updatedBy {
      id
      firstName
      lastName
    }
  }
  ${FullLineItem}
`
