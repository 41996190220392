import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useParams, NavigateProps } from "react-router-dom"
import { useMutation, useQuery, ApolloError } from "@apollo/client"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import CircularProgress from "@mui/material/CircularProgress"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import Seo from "../../../components/Seo"
import SnackbarMessage from "../../../components/SnackbarMessage"
import RoleForm from "./components/RoleForm"
import { EDIT_ROLE } from "../../../queries/editRole"
import { GET_ROLE_BY_ID } from "../../../queries/getRoleById"
import { parseGraphQLErrorCode, SETTINGS } from "../../../util"
import * as Sentry from "@sentry/react"
import { Snack } from "../../../types"

function EditRole() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [snack, setSnack] = useState<Snack>()

  const { data: queryData, loading: queryLoading } = useQuery(GET_ROLE_BY_ID, {
    variables: { id },
  })

  const [editRole, { loading: editLoading }] = useMutation(EDIT_ROLE, {
    onCompleted: () => {
      setRedirectTo({
        to: "/app/settings/roles/list",
        replace: false,
        state: {
          snack: {
            messageKey: "messages.changesSaved",
            variant: "success",
          },
        },
      })
    },
    onError: (error: ApolloError) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const loading = queryLoading
  const role = queryData?.getRoleById

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Seo title={t(SETTINGS.titleKey)} />
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/roles/list", titleKey: t("securityRoles") },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey="editRole"
          />
          {loading ? <CircularProgress /> : null}
          {!loading && role ? (
            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
              <Grid item lg={7} xs={12}>
                <RoleForm
                  isSaving={editLoading}
                  onCancel={() =>
                    setRedirectTo({
                      to: "/app/settings/roles/list",
                      replace: true,
                    })
                  }
                  onSave={(role) => {
                    if (!role.isStandard) {
                      editRole({
                        variables: {
                          ...role,
                        },
                      })
                    }
                  }}
                  role={role}
                />
              </Grid>
            </Grid>
          ) : null}
        </Box>
      </MainLayout>
    </>
  )
}

export default EditRole
