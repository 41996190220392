import { range } from "../../util"
import dayjs from "dayjs"

const MAX_DATE = dayjs().endOf("year").add(5, "year")
const MIN_DATE = dayjs().startOf("year").subtract(1, "year")
const HOURS = range(24)
const DAYS = range(7)
const INTERVAL_DURATION = 15 // interval length in minutes. must be <= 60
const INTERVALS_PER_HOUR = 60 / INTERVAL_DURATION
const DAY_MODE_INTERVAL_WIDTH = 25 // interval width in pixels
const DAY_MODE_HEADER_HEIGHT = 30 // header height in pixels
const WEEK_MODE_HEADER_HEIGHT = 50 // header height in pixels
const DAY_MODE_ROW_HEIGHT = 55 // row height in pixels
const WEEK_MODE_ROW_HEIGHT = 60 // row height in pixels
const WEEK_MODE_CELL_WIDTH = 180
const WEEK_MODE_INTERVAL_HEIGHT = WEEK_MODE_ROW_HEIGHT / 4 // interval height in pixels
const WEEK_MODE_RESOURCE_COLUMN_WIDTH = 120
const DAY_MODE_RESOURCE_COLUMN_WIDTH = 200

export {
  HOURS,
  DAYS,
  INTERVAL_DURATION,
  INTERVALS_PER_HOUR,
  DAY_MODE_INTERVAL_WIDTH,
  DAY_MODE_HEADER_HEIGHT,
  WEEK_MODE_HEADER_HEIGHT,
  DAY_MODE_ROW_HEIGHT,
  WEEK_MODE_ROW_HEIGHT,
  WEEK_MODE_CELL_WIDTH,
  WEEK_MODE_INTERVAL_HEIGHT,
  WEEK_MODE_RESOURCE_COLUMN_WIDTH,
  DAY_MODE_RESOURCE_COLUMN_WIDTH,
  MAX_DATE,
  MIN_DATE,
}
