import gql from "graphql-tag"

export const UNARCHIVE_CUSTOMER = gql`
  mutation UnarchiveCustomer($id: ID!) {
    unarchiveCustomer(id: $id) {
      customer {
        id
        isArchived
      }
    }
  }
`
