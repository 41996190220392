/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import { alpha } from "@mui/material/styles"
import TextField, { type TextFieldProps } from "@mui/material/TextField"
import type { FilledInputProps } from "@mui/material/FilledInput"

export default function FielderTextField(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      InputProps={{ ...props.InputProps, disableUnderline: true } as Partial<FilledInputProps>}
      margin="normal"
      sx={(theme) => ({
        marginTop: 0,
        marginBottom: 0,
        "& label.Mui-focused": {
          color: theme.fielderColors.black,
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff",
          border: "1px solid #e2e2e1",
          borderColor: theme.fielderColors.inputBorder,
          overflow: "hidden",
          borderRadius: "4px",
          transition: theme.transitions.create(["border-color", "box-shadow"]),
          "&:hover": {
            backgroundColor: "#fff",
          },
          "&.Mui-focused": {
            backgroundColor: "#fff",
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
          },
          "& .MuiInputAdornment-root": {
            marginRight: "4px",
          },
        },
        "& .Mui-error": {
          borderColor: theme.fielderColors.error,
        },
      })}
      variant="filled"
    />
  )
}
