/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react"
import { useTranslation } from "react-i18next"
import AddIcon from "@mui/icons-material/Add"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import TableFooter from "@mui/material/TableFooter"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Button from "@mui/material/Button"
import BundleComponentLineForm from "./BundleComponentLineForm"
import { formatMoney } from "../../../util"

interface BundleComponentsFormProps {
  readonly currencyCode: string
  readonly components: any[]
  readonly dispatch: any
}

const BundleComponentsForm = React.memo(
  ({ currencyCode, components, dispatch }: BundleComponentsFormProps) => {
    const { t } = useTranslation()

    return (
      <Box
        sx={(theme) => {
          return {
            display: "flex",
            flexDirection: "column",
            padding: 0,
            [theme.breakpoints.up("sm")]: {
              paddingLeft: "0.25rem",
            },
          }
        }}
      >
        <Table
          size="small"
          sx={{
            marginTop: "1rem",
            marginBottom: "2.5rem",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={classes.cell} width="59%">
                {t("productService")}
              </TableCell>
              <TableCell
                sx={(theme) => {
                  return {
                    ...classes.cell,
                    [theme.breakpoints.up("sm")]: {
                      paddingLeft: "0.25rem",
                    },
                  }
                }}
                width="20%"
              >
                {t("qty")}
              </TableCell>
              <TableCell sx={classes.cell} width="20%">
                {t("unitPrice")}
              </TableCell>
              <TableCell sx={classes.cell} width="1%" />
            </TableRow>
          </TableHead>
          <TableBody>
            {components
              ?.toSorted((a, b) => a.number - b.number)
              ?.map((component) => (
                <BundleComponentLineForm
                  component={component}
                  currencyCode={currencyCode}
                  dispatch={dispatch}
                  key={component.key}
                />
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell sx={classes.cell}>
                <Button
                  color="secondary"
                  data-testid="addComponentButton"
                  onClick={() => dispatch({ type: "ADD_COMPONENT" })}
                  sx={{
                    fontWeight: "bold",
                    "& svg": {
                      fontSize: "1.0rem",
                    },
                    "& div": {
                      marginLeft: "0.625rem",
                      marginRight: "0.625rem",
                    },
                  }}
                >
                  <AddIcon fontSize="small" />
                  <div>{t("addItem")}</div>
                </Button>
              </TableCell>
              <TableCell
                align="right"
                colSpan={2}
                sx={(theme) => {
                  return {
                    ...classes.cell,
                    padding: 0,
                    [theme.breakpoints.up("sm")]: {
                      paddingRight: "0.75rem",
                    },
                  }
                }}
              >
                <Box
                  sx={(theme) => {
                    return {
                      fontWeight: "bold",
                      fontSize: "0.75rem",
                      [theme.breakpoints.up("sm")]: {
                        fontSize: "0.9rem",
                      },
                    }
                  }}
                >
                  {t("total")}
                  {": "}
                  {formatMoney(
                    currencyCode,
                    components?.reduce((acc, curr) => {
                      return (
                        acc +
                        curr.value.quantity *
                          (curr.value.enableUnitSalePriceOverride
                            ? curr.value.unitSalePrice
                            : curr.value.organizationItem?.unitSalePrice)
                      )
                    }, 0)
                  )}
                </Box>
              </TableCell>
              <TableCell sx={classes.cell} />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    )
  }
)

const classes = {
  cell: {
    padding: 0,
    paddingLeft: "0.25rem",
    border: "none",
    fontSize: "0.875rem",
  },
} as const

export default BundleComponentsForm
