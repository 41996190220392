/* eslint-disable no-undef */
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import HttpApi from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import dayjs from "dayjs"
import "dayjs/locale/fr"
import "dayjs/locale/fr-ca"
import "dayjs/locale/de"
import "dayjs/locale/es-us"
import "dayjs/locale/en-ca"
import "dayjs/locale/nl"
import "dayjs/locale/nl-be"
import numeral from "numeral"
import "numeral/locales/de"
import "numeral/locales/fr-ca"
import "numeral/locales/fr"
import "numeral/locales/nl-nl"
import "numeral/locales/nl-be"
import { LanguageCode } from "./types"

const common_us_currency = {
  delimiters: {
    thousands: ",",
    decimal: ".",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  currency: {
    symbol: "$",
  },
  ordinal: function (n: number) {
    const b = n % 10
    return ~~((n % 100) / 10) === 1 ? "th" : b === 1 ? "st" : b === 2 ? "nd" : b === 3 ? "rd" : "th"
  },
}

const spanish_us_currency = {
  ...common_us_currency,
  ordinal: function (n: number) {
    const b = n % 10
    return b === 1 || b === 3
      ? "er"
      : b === 2
        ? "do"
        : b === 7 || b === 0
          ? "mo"
          : b === 8
            ? "vo"
            : b === 9
              ? "no"
              : "to"
  },
}

numeral.register("locale", "es-us", spanish_us_currency)
numeral.register("locale", "en-us", common_us_currency)
numeral.register("locale", "en-ca", common_us_currency)

const options = {
  fallbackLng: "en",
  load: "currentOnly",
  // have a common namespace used around the full app
  ns: ["common", "format", "timezones"],
  defaultNS: "common",
  saveMissing: false,
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
    format: (value: string, format: string) => {
      if (format === "uppercase") return value.toUpperCase()
      return value
    },
  },
  wait: process && !process.release && typeof window !== "undefined", // try to avoid the use of React Suspense during SSR
  // detection: {
  //   order: [
  //     "querystring",
  //     "navigator",
  //     "cookie",
  //     "localStorage",
  //     "sessionStorage",
  //     "htmlTag",
  //     "path",
  //     "subdomain",
  //   ],
  // },
}

// for browser use xhr backend to load translations and browser lng detector
if (process && !process.release && typeof window !== "undefined") {
  i18n.use(HttpApi).use(initReactI18next).use(LanguageDetector)
}

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options)
}

i18n.on("languageChanged", (lng: string) => {
  lng = lng.toLowerCase()

  if (lng === "es-mx") {
    lng = "es-us" // assuming es-mx and es-us are close enough for our purposes.
  }

  if (!["de", "en-us", "en", "en-ca", "es-us", "fr", "fr-ca", "nl-nl", "nl-be"].some((l) => l === lng)) {
    lng = "en-us"
  } else if (lng === "nl") {
    lng = "nl-nl"
  }

  dayjs.locale(lng)
  numeral.locale(lng)
})

export function changeLanguage(languageCode: LanguageCode) {
  let langCode: string | null = null
  switch (languageCode) {
    case LanguageCode.ENGLISH_USA:
      langCode = "en-US"
      break
    case LanguageCode.ENGLISH_CANADA:
      langCode = "en-CA"
      break
    case LanguageCode.FRENCH_CANADA:
      langCode = "fr-CA"
      break
    case LanguageCode.FRENCH_FRANCE:
      langCode = "fr"
      break
    case LanguageCode.GERMAN_GERMANY:
      langCode = "de"
      break
    case LanguageCode.SPANISH_USA:
      langCode = "es-US"
      break
    case LanguageCode.DUTCH_NETHERLANDS:
      langCode = "nl-NL"
      break
    case LanguageCode.DUTCH_BELGIUM:
      langCode = "nl-BE"
      break
    default:
      langCode = null
  }
  if (langCode) {
    i18n.changeLanguage(langCode, (err) => {
      if (err) {
        return console.log("something went wrong loading the language: ", langCode, err)
      }
    })
  }
}

export default i18n
