import React from "react"
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox"
import { green } from "@mui/material/colors"
import { Interpolation, Theme } from "@emotion/react"
import { JSX } from "@emotion/react/jsx-runtime"

function GreenCheckbox(
  props: JSX.IntrinsicAttributes & { css?: Interpolation<Theme> } & CheckboxProps
) {
  return (
    <Checkbox
      {...props}
      sx={{
        "&.Mui-checked": {
          color: green[600],
        },
      }}
    />
  )
}

export default GreenCheckbox
