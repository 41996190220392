import gql from "graphql-tag"
import TaxRateGroupDetails from "./taxRateGroupDetails"
import FullEstimateTemplateLineItemDetails from "./FullEstimateTemplateLineItemDetails"

export default gql`
  fragment FullEstimateTemplate on EstimateTemplate {
    id
    name
    currencyCode
    description
    notes
    subTotal
    taxSummary {
      total
      taxRateAmounts {
        taxRate {
          id
          name
          rate
        }
        amount
      }
    }
    totalDiscount
    total
    discount {
      type
      value
    }
    footerTitle
    footerBody
    lineItems {
      id
      number
      organizationItem {
        id
        code
        name
        type
        unitSalePrice
      }
      description
      quantity
      unitPrice
      taxRateGroup {
        ...TaxRateGroupDetails
      }
      total
      showDetails
      lineItemDetails {
        ...FullEstimateTemplateLineItemDetails
      }
    }
  }
  ${TaxRateGroupDetails}
  ${FullEstimateTemplateLineItemDetails}
`
