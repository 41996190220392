import React from "react"
import { NumericFormat } from "react-number-format"

interface TaxRateInputProps {
  readonly onChange: (event: { target: { name: string; value: number } }) => void
  readonly onFocus?: (event: any) => void
  readonly name: string
  readonly min?: number
  readonly max?: number
  readonly defaultValue?: number | string
  readonly value?: number | string
  readonly customInput?: React.ComponentType
}

function TaxRateInput(props: TaxRateInputProps) {
  const { name, defaultValue, onChange, onFocus, min, max, customInput, value, ...other } = props
  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      autoComplete="off"
      customInput={customInput}
      decimalScale={4}
      defaultValue={defaultValue}
      max={max}
      min={min}
      name={name}
      onFocus={(e) => {
        if (onFocus) {
          onFocus(e)
        } else {
          e.target.select()
        }
      }}
      onValueChange={(values) => {
        const val = Math.min(Number.parseFloat(values.value), 100)
        onChange({
          target: {
            name: name,
            value: val,
          },
        })
      }}
      value={value}
      valueIsNumericString
    />
  )
}

export default TaxRateInput
