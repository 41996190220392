import gql from "graphql-tag"

export default gql`
  fragment FullAddress on Address {
    addressString
    latitude
    longitude
    streetNumber
    route
    locality
    administrativeAreaLevel1
    administrativeAreaLevel2
    postalCode
    country
  }
`
