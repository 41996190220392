/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

function TemplateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 390.76 334.01">
      <defs>
        <style>
          {
            ".cls-1,.cls-3{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:22px}.cls-3{stroke-linecap:round}"
          }
        </style>
      </defs>
      <path
        className="cls-1"
        d="M443 175.29V93.83a16.19 16.19 0 0 1 16.19-16.19h185.04c21.93.51 15.24 28 16.19 42.07M660.42 358c.27 13.32 2.08 30.82-16.19 31.68h-185A16.19 16.19 0 0 1 443 373.46v-46"
        transform="translate(-356.49 -66.64)"
      />
      <path className="cls-1" d="M303.93 241.67v-46.35" />
      <path
        className="cls-1"
        d="M734.31 262c-1.55-1.5-186.51 2.82-186.24-1.48-.05-34.79 0-104.16 0-139.29-.36-4.31 184.78 0 186.24-1.48 4.18-.35.09 139.31 1.48 140.77a1.5 1.5 0 0 1-1.48 1.48Z"
        transform="translate(-356.49 -66.64)"
      />
      <path
        className="cls-1"
        d="M548.07 201.41c16.84-14.14 32-36.51 57.5-21.43l88.09 82"
        transform="translate(-356.49 -66.64)"
      />
      <path
        d="M706.12 175.29c-.54 31.37-47.19 31.36-47.72 0 .54-31.36 47.19-31.35 47.72 0Z"
        style={{
          strokeWidth: 15,
          fill: "none",
          stroke: "#000",
          strokeMiterlimit: 10,
        }}
        transform="translate(-356.49 -66.64)"
      />
      <path
        className="cls-1"
        d="M517.92 327.45c-1.21-1.52-151.28 3-150.42-1.57v-149c-.86-4.54 149.21-.06 150.42-1.59 3.43.14 0 149.07 1.2 150.59-.01.87-.54 1.57-1.2 1.57Z"
        transform="translate(-356.49 -66.64)"
      />
      <path
        className="cls-3"
        d="M687.84 358h-90.25c-19-.65-15.35-21.14-15.5-34.16a15.49 15.49 0 0 1 15.5-15.5h90.25a15.5 15.5 0 0 1 15.51 15.5c-.18 13 3.5 33.46-15.51 34.16Z"
        transform="translate(-356.49 -66.64)"
      />
      <path className="cls-3" d="M55.07 143.12h61.24M86.82 149.55v77.99" />
    </SvgIcon>
  )
}

export default TemplateIcon
