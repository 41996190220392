const PLUG_INS = [
  {
    id: "quickbooks",
    name: "QuickBooks Online",
    shortDescriptionKey: "pluginData.quickBooksOnline.shortDescription",
    longDescriptionKey: "pluginData.quickBooksOnline.longDescription",
    connectButtonLabelKey: "pluginData.quickBooksOnline.connectButtonLabel",
    logoPath: "/icons/QuickBooksLogo.png",
    connectUri: "/oauth/connect/quickbooks",
  },
  {
    id: "xero",
    name: "Xero",
    shortDescriptionKey: "pluginData.xero.shortDescription",
    longDescriptionKey: "pluginData.xero.longDescription",
    connectButtonLabelKey: "pluginData.xero.connectButtonLabel",
    logoPath: "/icons/XeroLogo.png",
    connectUri: "/oauth/connect/xero",
  },
  //   {
  //     id: "sage",
  //     name: "Sage",
  //     shortDescriptionKey: "pluginData.sage.shortDescription",
  //     longDescriptionKey: "pluginData.sage.longDescription",
  //     connectButtonLabelKey: "pluginData.sage.connectButtonLabel",
  //     logoPath: "/icons/SageLogo.png",
  //     connectUri: "/oauth/connect/sage",
  //   }
]

export default PLUG_INS
