import { FontOption } from "../../types/appTypes"

const FontOptions: FontOption[] = Object.freeze([
  {
    id: "sansSerif",
    fontFamily: "arial, sans-serif",
  },
  {
    id: "serif",
    fontFamily: "times new roman, serif",
  },
  {
    id: "fixedWidth",
    fontFamily: "monospace",
  },
  {
    id: "wide",
    fontFamily: "arial black, sans-serif",
  },
  {
    id: "narrow",
    fontFamily: "arial narrow, sans-serif",
  },
  {
    id: "comicSansMS",
    fontFamily: "comic sans ms, sans-serif",
  },
  {
    id: "garamond",
    fontFamily: "garamond, times new roman, serif",
  },
  {
    id: "georgia",
    fontFamily: "georgia, serif",
  },
  {
    id: "tahoma",
    fontFamily: "tahoma, sans-serif",
  },
  {
    id: "trebuchetMS",
    fontFamily: '"trebuchet ms", sans-serif',
  },
  {
    id: "verdana",
    fontFamily: "verdana, sans-serif",
  },
]) as FontOption[]

export default FontOptions
