import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { Navigate, NavigateProps } from "react-router-dom"
import { useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import Seo from "../../../components/Seo"
import SnackbarMessage from "../../../components/SnackbarMessage"
import RoleForm from "./components/RoleForm"
import { CREATE_ROLE } from "../../../queries/createRole"
import { parseGraphQLErrorCode, SETTINGS } from "../../../util"
import { Snack } from "../../../types"

function CreateRolePage() {
  const { t } = useTranslation()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [snack, setSnack] = useState<Snack>()

  const [createRole, { loading: mutationLoading }] = useMutation(CREATE_ROLE, {
    onCompleted: () => {
      setRedirectTo({
        to: "/app/settings/roles/list",
        replace: false,
        state: {
          snack: {
            messageKey: "messages.changesSaved",
            variant: "success",
          },
        },
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Seo title={t(SETTINGS.titleKey)} />
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/roles/list", titleKey: t("securityRoles") },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey="createRole"
          />
          <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Grid item lg={7} xs={12}>
              <RoleForm
                isSaving={mutationLoading}
                onCancel={() =>
                  setRedirectTo({
                    to: "/app/settings/roles/list",
                    replace: true,
                  })
                }
                onSave={(role) => {
                  createRole({
                    variables: {
                      ...role,
                    },
                  })
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </MainLayout>
    </>
  )
}

export default CreateRolePage
