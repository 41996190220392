import React, { FC } from "react"
import Box from "@mui/material/Box"

interface MasterInfoItemProps {
  style?: React.CSSProperties
}
const MasterInfoItem: FC<MasterInfoItemProps> = ({ children, style, ...props }) => {
  return (
    <Box
      sx={{
        width: "50%",
      }}
      {...props}
      style={style}
    >
      {children}
    </Box>
  )
}

export default MasterInfoItem
