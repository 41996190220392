import React from "react"
import { useTranslation } from "react-i18next"
import WarningIcon from "@mui/icons-material/Warning"
import Box from "@mui/material/Box"

function ProductOrderItemNoRefundWarning() {
  const { t } = useTranslation()

  return (
    <Box
      sx={(theme) => {
        return {
          marginTop: "1rem",
          marginBottom: "1rem",
          [theme.breakpoints.up("sm")]: {
            marginLeft: "2.5rem",
          },
          [theme.breakpoints.up("md")]: {
            marginLeft: "4rem",
          },
        }
      }}
    >
      <Box
        sx={{
          borderRadius: "4px",
          padding: "0.125rem",
          paddingLeft: "0.5rem",
          paddingRight: "0.5rem",
          backgroundColor: "hsla(38, 97%, 96%, 1)",
          color: "hsla(38, 100%, 43%, 1)",
          fontWeight: 600,
          fontSize: "0.75rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "4px",
        }}
      >
        <WarningIcon
          sx={{
            fontSize: "0.9375rem",
            color: "hsla(38, 100%, 43%, 1)",
          }}
        />
        {t("itemOrdersNotCancelable")}
      </Box>
    </Box>
  )
}

export default ProductOrderItemNoRefundWarning
