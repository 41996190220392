import React from "react"
import { useTranslation } from "react-i18next"
import Autocomplete from "@mui/material/Autocomplete"
import FielderTextField from "./FielderTextField"
import TIMEZONES from "~/util/timezones"

interface TimeZoneSelectProps {
  readonly className?: string
  readonly disabled?: boolean
  readonly error?: boolean
  readonly name: string
  readonly onBlur?: (event: any) => void
  readonly onChange: (newValue: string | null) => void
  readonly required?: boolean
  readonly value?: string | undefined | null
}

function TimeZoneSelect({
  className,
  disabled,
  error: hasError,
  name,
  onBlur,
  onChange,
  required,
  value,
}: TimeZoneSelectProps) {
  const { t } = useTranslation()

  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      getOptionLabel={(option) => t(`timezones:${option}`)}
      id={name}
      onBlur={onBlur}
      onChange={(event, newValue) => {
        onChange(newValue)
      }}
      options={TIMEZONES}
      renderInput={(params) => (
        <FielderTextField {...params} error={hasError} label={t("timeZone")} required={required} />
      )}
      value={value && value.trim()?.length > 0 ? value : null}
    />
  )
}

export default React.memo(TimeZoneSelect)
