import { TextSizeOption } from "../../types/appTypes"

const TextSizeOptions: TextSizeOption[] = Object.freeze([
  {
    id: "small",
    fontSize: "x-small",
  },
  {
    id: "normal",
    fontSize: "small",
  },
  {
    id: "large",
    fontSize: "large",
  },
  {
    id: "huge",
    fontSize: "xx-large",
  },
]) as TextSizeOption[]

export default TextSizeOptions
