import gql from "graphql-tag"

export const CREATE_PRODUCT_ORDER_DELIVERY = gql`
  mutation CreateProductOrderDelivery(
    $productOrderId: ID!
    $lineItems: [ProductOrderDeliveryLineItemInput!]!
  ) {
    createProductOrderDelivery(input: { productOrderId: $productOrderId, lineItems: $lineItems }) {
      productOrderDelivery {
        id
        lineItems {
          id
          productOrderLineItem {
            id
            number
            organizationItem {
              id
              code
              name
            }
          }
          quantity
        }
        createdAt
        createdBy {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`
