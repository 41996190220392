import React from "react"
import { useTranslation } from "react-i18next"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"

export default function ChangeOwnerNotice() {
  const { t } = useTranslation()
  return (
    <Box
      sx={(theme) => {
        return {
          padding: 0,
          marginTop: "2.5rem",
          [theme.breakpoints.up("sm")]: {
            marginTop: "1rem",
            padding: "2rem",
          },
        }
      }}
    >
      <Alert severity="info">
        <Box>{t("component.franchiseeForm.messages.changeOwnerNotice")}</Box>
        <Box>
          <a href="mailto:support@fielderapp.com">support@fielderapp.com</a>
        </Box>
      </Alert>
    </Box>
  )
}
