import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { gql, useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import ErrorMessage from "../ErrorMessage"
import FielderTextField from "../FielderTextField"
import { Checklist, ChecklistType } from "../../types"
import SaveButton from "../SaveButton"

const CREATE_CHECKLIST_TEMPLATE = gql`
  mutation CreateChecklistTemplate(
    $name: String!
    $type: ChecklistType!
    $diagram: String
    $lineItems: [ChecklistTemplateLineItemInput!]
  ) {
    createChecklistTemplate(
      input: { name: $name, type: $type, diagram: $diagram, lineItems: $lineItems }
    ) {
      checklistTemplate {
        id
        name
      }
    }
  }
`

interface SaveAsTemplateDialogProps {
  checklist: Checklist
  onCancel: () => void
  onSave: (success: boolean, message: string, data?: any) => void
}

function SaveAsTemplateDialog({ checklist, onCancel, onSave }: SaveAsTemplateDialogProps) {
  const { t } = useTranslation()
  const [name, setName] = useState("")

  const [createChecklistTemplate, { loading, error }] = useMutation(CREATE_CHECKLIST_TEMPLATE, {
    onCompleted: () => {
      onSave?.(true, "component.saveChecklistAsTemplateDialog.successMessage", { name })
    },
    onError: (error) => {
      Sentry.captureException(error)
      onSave?.(false, t("error.general.message"))
    },
  })

  function handleSave() {
    const lineItemInputs = checklist.lineItems.map((li) => ({
      diagramId: li.diagramId,
      number: li.number,
      requiredCompressionCapacity: li.requiredCompressionCapacity,
      pileUsed: li.pileUsed,
      helixUsed: li.helixUsed,
    }))
    createChecklistTemplate({
      variables: {
        name,
        type: checklist.type ?? ChecklistType.REGULAR,
        diagram: checklist.diagram,
        lineItems: lineItemInputs,
      },
    })
  }

  return (
    <Dialog
      aria-labelledby="save-as-template-dialog"
      data-testid="SaveAsTemplateDialog"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open
    >
      <DialogTitle
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {t("component.saveChecklistAsTemplateDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            paddingBottom: 0,
            paddingTop: "0.5rem",
          }}
        >
          {error ? (
            <ErrorMessage message={t("error.general.message")} title={t("error.general.title")} />
          ) : null}
          <Box
            sx={{
              color: (theme) => theme.fielderColors.mutedText,
              marginTop: "0.625rem",
              marginBottom: "0.625rem",
            }}
          >
            {t("component.saveChecklistAsTemplateDialog.description")}
          </Box>
          <Box
            sx={{
              marginTop: "1.25rem",
            }}
          >
            <FielderTextField
              autoComplete="none"
              data-testid="name"
              fullWidth
              id="name"
              inputProps={{ maxLength: 255 }}
              label={t("templateName")}
              name="name"
              onChange={(e) => setName(e.target.value)}
              onFocus={(e) => e.target.select()}
              required
              value={name}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: "1.5rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton loading={loading} onClick={handleSave} />
      </DialogActions>
    </Dialog>
  )
}

export default SaveAsTemplateDialog
