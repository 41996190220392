import gql from "graphql-tag"
import OrganizationFragment from "./organization"

export default gql`
  fragment FullOrganization on Organization {
    ...OrganizationFragment
    plugins {
      id
      pluginProvider {
        id
        name
        category
      }
      supportsInventoryTracking
      tenantId
    }
    owner {
      id
      firstName
      lastName
      email
      languageCode
    }
    fielderSubscription {
      id
      status
    }
  }
  ${OrganizationFragment}
`
