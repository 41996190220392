import gql from "graphql-tag"
import TaxRateGroupDetails from "./fragments/taxRateGroupDetails"
import FullEstimateLineItemDetails from "./fragments/FullEstimateLineItemDetails"

export const ALL_ESTIMATES_FOR_JOB = gql`
  query AllEstimatesForJob($id: ID!, $includeArchived: Boolean) {
    getJobById(id: $id) {
      id
      number
      estimates(filter: { includeArchived: $includeArchived }) {
        id
        number
        currencyCode
        job {
          id
          number
        }
        billingContact {
          id
          firstName
          lastName
          email
          isArchived
        }
        customerVisibility {
          showExpirationDate
          showContract
          showLineItems
          showLineItemQuantity
          showLineItemUnitPrice
          showLineItemSubtotal
          showLineItemCode
          showLineItemName
          showLineItemDescription
          showLineItemBundleComponents
          showLineItemBundleComponentUnitPrice
          showLineItemBundleComponentQuantity
          showFooter
          showFooterSubtotal
          showFooterDiscount
          showFooterTaxes
          showFooterTotal
        }
        status
        description
        discount {
          value
          type
        }
        notes
        expirationDate
        footerTitle
        footerBody
        contractJson
        subTotal
        taxSummary {
          total
          taxRateAmounts {
            amount
            taxRate {
              id
              name
              rate
            }
          }
        }
        total
        auditLog(params: { first: 1000, sortDir: DESC, sortBy: "createdAt" }) {
          edges {
            cursor
            node {
              id
              createdBy {
                id
                firstName
                lastName
              }
              createdAt
              eventType
            }
          }
        }
        lineItems {
          id
          number
          organizationItem {
            id
            code
            name
            type
          }
          description
          showDetails
          lineItemDetails {
            ...FullEstimateLineItemDetails
          }
          quantity
          unitPrice
          taxRateGroup {
            ...TaxRateGroupDetails
          }
          total
        }
        isArchived
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
  ${FullEstimateLineItemDetails}
  ${TaxRateGroupDetails}
`
