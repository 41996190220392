import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import { formatMoney, NOT_SPECIFIED } from "../../../util"
import { OrganizationItem } from "../../../types"

interface BundleComponentsFormProps {
  readonly currencyCode: string
  readonly item: OrganizationItem
}

function BundleComponentsStatic({ currencyCode, item }: BundleComponentsFormProps) {
  const { t } = useTranslation()

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1.875rem",
          "& label": {
            fontWeight: 600,
            fontSize: "1.1em",
          },
        }}
      >
        <label>{t("component.bundleForm.productsIncluded")}</label>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={classes.headerCell} width="60%">
                {t("productService")}
              </TableCell>
              <TableCell align="right" sx={classes.headerCell} width="20%">
                {t("qty")}
              </TableCell>
              <TableCell align="right" sx={classes.headerCell} width="20%">
                {t("unitPrice")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {item?.components
              ?.toSorted((a, b) => a.number - b.number)
              ?.map((component) => (
                <TableRow key={component.id}>
                  <TableCell sx={classes.cell}>
                    <Box sx={{ fontWeight: 550 }}>{component.organizationItem.code}</Box>
                    <Box>{component.organizationItem.name}</Box>
                  </TableCell>
                  <TableCell align="right" sx={classes.cell}>
                    <Box>{component.quantity}</Box>
                  </TableCell>
                  <TableCell align="right" sx={classes.cell}>
                    <Box>{formatMoney(currencyCode, component.unitSalePrice) || NOT_SPECIFIED}</Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </>
  )
}

const classes = {
  headerCell: {
    paddingLeft: 0,
    paddingRight: "0.2rem",
    paddingBottom: 0,
    borderBottom: "1px solid #cdcdcd",
    fontSize: "0.875rem",
  },
  cell: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "0.5rem",
    border: "none",
    fontSize: "0.875rem",
  },
}

export default BundleComponentsStatic
