import gql from "graphql-tag"
import FullAddress from "./fullAddress"

export default gql`
  fragment ContactFragment on Contact {
    id
    firstName
    lastName
    email
    phoneNumber
    jobTitle
    address {
      ...FullAddress
    }
  }
  ${FullAddress}
`
