import gql from "graphql-tag"

export const CREATE_CUSTOMER_NOTE = gql`
  mutation CreateCustomerNote($customerId: ID!, $text: String!) {
    createCustomerNote(input: { customerId: $customerId, text: $text }) {
      customerNote {
        id
        text
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
`
