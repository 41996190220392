import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Formik } from "formik"
import * as Yup from "yup"
import { useMutation } from "@apollo/client"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import FormHelperText from "@mui/material/FormHelperText"
import Grid from "@mui/material/Grid"
import { FielderTextField, HeaderLogo, PoweredByFielder, Seo, SnackbarMessage } from "../components"
import { SEND_PASSWORD_RESET_CODE } from "../queries/sendPasswordResetCode"
import { Snack } from "../types"
import { parseGraphQLErrorCode } from "../util"

function ForgotPassword() {
  const { t } = useTranslation()
  const location = useLocation()
  const [snack, setSnack] = useState<Snack>()

  const [sendPasswordResetCode, { loading, called, data }] = useMutation(SEND_PASSWORD_RESET_CODE, {
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const FormSchema = Yup.object().shape({
    username: Yup.string()
      .email(t("page.forgotPassword.username.email"))
      .required(t("page.forgotPassword.username.required")),
  })

  return (
    <>
      <Seo title={t("page.forgotPassword.title")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Box
        component="main"
        sx={{
          height: "100vh",
          backgroundColor: (theme) => theme.fielderScreens.login.backgroundColor,
        }}
      >
        <Formik
          initialValues={{
            username: location?.state?.username ?? "",
          }}
          onSubmit={(values) => {
            sendPasswordResetCode({ variables: { username: values.username } })
          }}
          validationSchema={FormSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
            <form data-testid="forgotPasswordForm" onSubmit={handleSubmit}>
              <Grid
                container
                sx={{
                  width: "100%",
                  maxWidth: "370px",
                  minWidth: "240px",
                  margin: "0 auto",
                  paddingTop: "10%",
                }}
              >
                <Grid
                  data-testid="logo"
                  item
                  sx={{
                    margin: "auto 0.625rem",
                  }}
                  xs={12}
                >
                  <HeaderLogo width="100%" />
                </Grid>
                <Grid
                  item
                  sx={(theme) => ({
                    margin: "auto 0.625rem",
                    padding: "1.375rem",
                    paddingTop: "0.625rem",
                    backgroundColor: "#fff",
                    border: `1px solid ${theme.fielderScreens.login.backgroundColor}`,
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  })}
                  xs={12}
                >
                  <h1
                    css={{
                      margin: 0,
                    }}
                  >
                    {t("page.forgotPassword.title")}
                  </h1>
                  <Box
                    data-testid="messageBody"
                    sx={{
                      marginTop: "0.625rem",
                      marginBottom: "1.5rem",
                      flexGrow: 1,
                    }}
                  >
                    {data?.sendPasswordResetCode?.sent
                      ? t("page.forgotPassword.confirmation", {
                          email: values.username,
                        })
                      : t("page.forgotPassword.prompt")}
                  </Box>
                  {!data?.sendPasswordResetCode?.sent && (
                    <>
                      <FielderTextField
                        autoComplete="off"
                        autoFocus
                        data-testid="usernameField"
                        fullWidth
                        id="username"
                        inputProps={{ maxLength: 255 }}
                        label={t("emailAddress")}
                        margin="normal"
                        name="username"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="email"
                        value={values.username}
                      />
                      {errors.username && touched.username ? (
                        <FormHelperText error id="usernameError">
                          {errors.username}
                        </FormHelperText>
                      ) : null}
                      <Button
                        color="primary"
                        data-testid="submitButton"
                        disabled={!values.username || loading}
                        fullWidth
                        id="submitButton"
                        sx={{
                          marginTop: "1.5rem",
                          backgroundColor: (theme) => theme.palette.primary.main,
                          height: "3.125rem",
                        }}
                        type="submit"
                        variant="contained"
                      >
                        {called && loading ? (
                          <CircularProgress size={20} thickness={6.0} />
                        ) : (
                          <span>{t("page.forgotPassword.submitButton")}</span>
                        )}
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
              <PoweredByFielder />
            </form>
          )}
        </Formik>
      </Box>
    </>
  )
}

export default ForgotPassword
