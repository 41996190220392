import React from "react"
import { useQuery, gql } from "@apollo/client"
import { useTranslation } from "react-i18next"
import GoogleMapView from "../GoogleMapView"
import {
  formatDate,
  formatPersonName,
  getAddressSummary,
  getJobAssignmentMarker,
  getUserHqMarker,
} from "../../util"
import { useAuth } from "../../context/AuthContext"
import { JobAssignment, MapMarker, MapMarkerType, User } from "../../types"
import { isNil } from "lodash"

const GET_USER_LOCATIONS = gql`
  query AllUsers {
    allUsers(userFilter: { roleNames: ["FIELD_TECH"] }) {
      edges {
        node {
          id
          email
          firstName
          lastName
          lastLocation {
            id
            latitude
            longitude
            createdAt
          }
        }
      }
    }
  }
`

type MapViewProps = {
  readonly assignments: JobAssignment[]
}

export default function MapView({ assignments }: MapViewProps) {
  const { user } = useAuth()
  const { t } = useTranslation()

  const refreshUserLocationButtonTitle = t("component.mapView.refreshUserLocationButtonTitle")

  // Load all eligible Users
  const { data: userLocationsData, refetch: refetchUserLocations } = useQuery(GET_USER_LOCATIONS, {
    fetchPolicy: "network-only",
    ssr: false,
    variables: {
      first: 100000,
      roleNames: ["FIELD_TECH"],
    },
  })

  const hqMarker = user
    ? getUserHqMarker(user)
    : { lat: 48.355278, lng: 99.999167, type: MapMarkerType.HQ }
  const mapCenter = { lat: hqMarker.lat, lng: hqMarker.lng }

  // This assumes the graphQL query pulls the lat/lng. Otherwise this won't work.
  const jobMarkers = assignments.map((a) => {
    const marker = getJobAssignmentMarker(a)
    return {
      ...marker,
      infoWindow: getJobAssignmentInfoWindow(a),
    }
  })

  const userLocationMarkers: MapMarker[] =
    userLocationsData?.allUsers?.edges
      .filter(
        (e) => !isNil(e.node.lastLocation?.latitude) && !isNil(e.node.lastLocation?.longitude)
      )
      .map((u) => {
        const { node } = u
        const { latitude, longitude } = node.lastLocation
        return {
          lat: latitude,
          lng: longitude,
          markerType: "user",
          infoWindow: getStaffLocationInfoWindow(node),
        }
      }) ?? []

  function getStaffLocationInfoWindow(staffMember: User) {
    return {
      title: formatPersonName(staffMember),
      content: `<div style="display: flex; flex-direction: column; gap: 4px;">
          <div style="font-weight: 500;">
            ${staffMember.firstName} ${staffMember.lastName}
          </div>
          <div>
          ${
            staffMember.lastLocation
              ? formatDate(
                  staffMember.lastLocation.createdAt,
                  t("format:dateFormat.shortWithTime"),
                  user?.organization?.timeZone,
                  user
                )
              : ""
          }
            </div>
        </div>`,
    }
  }

  function getJobAssignmentInfoWindow(jobAssignment: JobAssignment) {
    const addr = jobAssignment.job.address
    const formattedAddressString = addr ? getAddressSummary(addr) : ""

    return {
      title: `${t("job")} #${jobAssignment.job.number}`,
      content: `<div style="font-family: Source Sans Pro, Arial; display: flex; flex-direction: column; gap: 2px; max-height: 150px; min-width: 300px; overflow: scroll;">
          <div style="display: flex; flex-direction: row; justify-content: space-between; font-size: 1.1rem; font-weight: 600; margin-bottom: 6px;">
            <div>${formatDate(
              jobAssignment.startDate,
              t("format:dateFormat.time"),
              user?.organization?.timeZone,
              user
            )}</div>
            <div><a href="/app/jobs/edit/${jobAssignment.job.id}">${t("job")} #${
              jobAssignment.job.number
            }</a></div>
          </div>
          <div style="font-weight: 500; font-size: 1rem">${formattedAddressString}</div>
          <div style="font-weight: 300; font-size: 0.95rem">${
            jobAssignment.job.customer?.name ?? ""
          }</div>
          <div style="font-size: 0.85rem; margin: 6px 0;">${t(
            "assignedTo"
          )}: ${jobAssignment.assignees.map((a) => formatPersonName(a)).join(", ")}</div>
        </div>`,
    }
  }

  return (
    <GoogleMapView
      center={mapCenter}
      customButton={{
        title: refreshUserLocationButtonTitle,
        onClick: refetchUserLocations,
      }}
      markers={[hqMarker, ...jobMarkers, ...userLocationMarkers]}
      style={{ border: "1px solid #ccc", height: "100%" }}
      zoom={8}
    />
  )
}
