import gql from "graphql-tag"
import AttachmentFragment from "./fragments/attachment"
import OrganizationFragment from "./fragments/organization"
import EstimateFragment from "./fragments/estimate"
import InvoiceFragment from "./fragments/invoice"
import ContactFragment from "./fragments/contact"

export const GET_DATA_FOR_JOB_EMAIL_TEMPLATES = gql`
  query GetDataForJobEmail($id: ID!) {
    getJobById(id: $id) {
      id
      number
      description
      desiredCompletionDate
      emailAddress
      address {
        ...FullAddress
      }
      attachments {
        ...AttachmentFragment
      }
      checklist {
        id
        installers {
          id
          firstName
          lastName
          email
        }
        installationEquipment
        installationDate
      }
      siteContact {
        ...ContactFragment
      }
      customer {
        id
        name
        email
        type
        address {
          ...FullAddress
        }
      }
      estimates {
        ...EstimateFragment
      }
      invoices {
        ...InvoiceFragment
      }
      organization {
        ...OrganizationFragment
        attachments {
          ...AttachmentFragment
        }
        emailTemplates {
          id
          type
          name
          subject
          body
          attachments {
            ...AttachmentFragment
          }
        }
      }
    }
  }
  ${AttachmentFragment}
  ${OrganizationFragment}
  ${EstimateFragment}
  ${InvoiceFragment}
  ${ContactFragment}
`
