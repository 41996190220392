import gql from "graphql-tag"

export default gql`
  fragment FullWorkOrderLineItemDetails on WorkOrderLineItemDetail {
    id
    number
    quantity
    unitPrice
    organizationItem {
        id
        code
        name
    }
  }
`
