import gql from "graphql-tag"

export const ALL_TASKS = gql`
  query AllTasks(
    $filter: String
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $jobId: ID
    $statuses: [TaskStatus]
    $assigneeUserId: ID
  ) {
    allTasks(
      input: {
        filter: $filter
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        last: $last
        after: $after
        before: $before
      }
      taskFilter: { jobId: $jobId, statuses: $statuses, assigneeUserId: $assigneeUserId }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          description
          dueDate
          assignee {
            id
            firstName
            lastName
            email
          }
          status
          job {
            id
            number
            description
            customer {
              id
              name
            }
            address {
              addressString
            }
          }
          notes
          createdAt
          createdBy {
            id
            email
          }
          updatedAt
          updatedBy {
            id
            email
          }
        }
      }
    }
  }
`
