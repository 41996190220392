import { JobAssignment, JobAssignmentStatus } from "~/types/apiTypes"

export function getAssignmentStatusColor(status: JobAssignmentStatus): string {
    if (status === JobAssignmentStatus.TENTATIVE) {
        return "#FDE047"
    } else if (status === JobAssignmentStatus.CONFIRMED) {
        return "hsl(229, 67%, 54%)"
    } else if (status === JobAssignmentStatus.COMPLETED) {
        return "#18671D"
    } else {
        return "hsl(224, 31%, 93%)"
    }
}

export function getRootBackgroundColor(
    jobAssignment: JobAssignment,
    isAssignmentSelected: boolean
): string {
    if (jobAssignment.status === JobAssignmentStatus.TENTATIVE) {
        return isAssignmentSelected ? "#FDE047" : "#FEF9C3"
    } else if (jobAssignment.status === JobAssignmentStatus.CONFIRMED) {
        return isAssignmentSelected ? "hsl(229, 67%, 54%)" : "#DBEAFE"
    } else if (jobAssignment.status === JobAssignmentStatus.COMPLETED) {
        return isAssignmentSelected ? "#18671D" : "#DFFBE0"
    } else if (jobAssignment.isLocked) {
        return "hsl(53, 100%, 80%)"
    } else {
        return "hsl(224, 31%, 93%)"
    }
}

export function getRootBorderColor(jobAssignment: JobAssignment): string {
    if (jobAssignment.status === JobAssignmentStatus.TENTATIVE) {
        return "#EAB308"
    } else if (jobAssignment.status === JobAssignmentStatus.CONFIRMED) {
        return "#1E3A8A"
    } else if (jobAssignment.status === JobAssignmentStatus.COMPLETED) {
        return "#18671D"
    } else if (jobAssignment.isLocked) {
        return "hsl(48, 67%, 54%)"
    } else {
        return "hsl(224, 31%, 93%)"
    }
}

export function getRootTextColor(jobAssignment: JobAssignment, isAssignmentSelected: boolean): string {
    if (jobAssignment.status === JobAssignmentStatus.TENTATIVE) {
        return isAssignmentSelected ? "#713F12" : "#A16207"
    } else if (jobAssignment.status === JobAssignmentStatus.CONFIRMED) {
        return isAssignmentSelected ? "#EFF6FF" : "#1E3A8A"
    } else if (jobAssignment.status === JobAssignmentStatus.COMPLETED) {
        return isAssignmentSelected ? "#F2FDF2" : "#15511A"
    } else if (jobAssignment.isLocked) {
        return "hsl(53, 100%, 53%)"
    } else {
        return "hsl(53, 100%, 53%)"
    }
}