import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { OrganizationStatus } from "~/types"

function getStatusColors(status: OrganizationStatus) {
  switch (status) {
    case OrganizationStatus.DRAFT:
      return {
        background: "#e0f2fe", // Tailwind Sky 100
        foreground: "#075985", // Tailwind Sky 800
      }
    case OrganizationStatus.PENDING:
      return {
        background: "#fef3c7", // Tailwind Amber 100
        foreground: "#b45309", // Tailwind Amber 700
      }
    case OrganizationStatus.ACTIVE:
      return {
        background: "#dcfce7", // Tailwind Green 100
        foreground: "#166534", // Tailwind Green 800
      }
    case OrganizationStatus.SUSPENDED:
      return {
        background: "#fee2e2", // Tailwind Red 100
        foreground: "#991b1b", // Tailwind Red 800
      }
    case OrganizationStatus.ARCHIVED:
      return {
        background: "#e2e8f0", // Tailwind Slate 200
        foreground: "#1e293b", // Tailwind Slate 800
      }
    default:
      return {
        background: "#ffffff",
        foreground: "#000000",
      }
  }
}

interface Props {
  readonly status: OrganizationStatus
}

function OrganizationStatusPill({ status }: Props) {
  const { t } = useTranslation()
  const colors = getStatusColors(status)

  return (
    <Box
      component="span"
      sx={{
        backgroundColor: colors.background,
        color: colors.foreground,
        padding: "0.125rem 0.625rem",
        fontWeight: "600",
        borderRadius: "4px",
        flex: 1,
        whiteSpace: "nowrap",
        fontSize: "0.875rem",
      }}
    >
      {t(`organizationStatusOption.${status}`)}
    </Box>
  )
}

export default OrganizationStatusPill
