import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"
import TaxRateGroupDetails from "./fragments/taxRateGroupDetails"
import FullInvoiceLineItemDetails from "./fragments/FullInvoiceLineItemDetails"

/**
 * Variation on AllInvoices that's intended to be used
 * to load invoices for a particular Job. In that case, since there
 * should usually be no more than a few invoices for a particular Job,
 * we'll load everything
 */
export const ALL_INVOICES_FOR_JOB = gql`
  query AllInvoicesForJob(
    $jobId: ID
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: String
    $invoiceStatuses: [InvoiceStatus]
    $includeArchived: Boolean
  ) {
    allInvoices(
      input: {
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        after: $after
        last: $last
        before: $before
        filter: $filter
      }
      invoiceFilter: {
        jobId: $jobId
        statuses: $invoiceStatuses
        includeArchived: $includeArchived
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          number
          status
          description
          notes
          discount {
            value
            type
          }
          customerNote
          currencyCode
          issuedDate
          netTerms
          dueDate
          subTotal
          total
          taxSummary {
            total
            taxRateAmounts {
              amount
              taxRate {
                id
                name
                rate
              }
            }
          }
          balanceDue
          pluginProvider {
            id
            name
            category
          }
          externalId
          dateSentToPluginProvider
          pluginProviderUrl
          dateSentToCustomer
          billingContact {
            id
            firstName
            lastName
            email
            phoneNumber
            isArchived
            address {
              ...FullAddress
            }
          }
          job {
            id
            number
            customer {
              id
              name
              type
            }
            address {
              ...FullAddress
            }
          }
          lineItems {
            id
            number
            organizationItem {
              id
              code
              name
              type
            }
            description
            showDetails
            lineItemDetails {
              ...FullInvoiceLineItemDetails
            }
            quantity
            unitPrice
            taxRateGroup {
              ...TaxRateGroupDetails
            }
            total
          }
          customerVisibility {
            showDueDate
            showLineItems
            showLineItemQuantity
            showLineItemUnitPrice
            showLineItemSubtotal
            showLineItemCode
            showLineItemName
            showLineItemDescription
            showLineItemBundleComponents
            showLineItemBundleComponentUnitPrice
            showLineItemBundleComponentQuantity
            showFooter
            showFooterSubtotal
            showFooterDiscount
            showFooterTaxes
            showFooterTotal
          }
          isArchived
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
  ${FullAddress}
  ${TaxRateGroupDetails}
  ${FullInvoiceLineItemDetails}
`
