import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useMutation, ApolloError } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined"

import EmptyState from "~/components/EmptyState"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import { parseGraphQLErrorCode, SETTINGS } from "~/util"
import { useAuth } from "~/context/AuthContext"
import { CustomerFlag, DefaultPermission, Snack } from "~/types"
import useGetAllCustomerFlags from "~/hooks/useGetAllCustomerFlags"
import CustomerFlagDialog from "~/components/CustomerFlagDialog"
import { EDIT_CUSTOMER_FLAG } from "~/queries/editCustomerFlag"
import { CREATE_CUSTOMER_FLAG } from "~/queries/createCustomerFlag"
import { DELETE_CUSTOMER_FLAG } from "~/queries/deleteCustomerFlag"

function ManageCustomerFlags() {
  const { t } = useTranslation()
  const [snack, setSnack] = useState<Snack>()
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [selectedFlag, setSelectedFlag] = useState<CustomerFlag>()
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState<boolean>(false)
  const { user, hasPermissions } = useAuth()
  const {
    customerFlags,
    loading: queryLoading,
    error,
    refetch,
  } = useGetAllCustomerFlags(user?.organization?.id)

  const mutationOptions = {
    onCompleted: () => {
      setInEditMode(false)
      setIsConfirmDeleteDialogOpen(false)
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      refetch()
    },
    onError: (error: ApolloError) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  }

  const [createCustomerFlag, { loading: createCustomerFlagLoading }] = useMutation(
    CREATE_CUSTOMER_FLAG,
    mutationOptions
  )
  const [editCustomerFlag, { loading: editCustomerFlagLoading }] = useMutation(
    EDIT_CUSTOMER_FLAG,
    mutationOptions
  )
  const [deleteCustomerFlag, { loading: deleteCustomerFlagLoading }] = useMutation(
    DELETE_CUSTOMER_FLAG,
    mutationOptions
  )

  const isMutationLoading =
    createCustomerFlagLoading || editCustomerFlagLoading || deleteCustomerFlagLoading

  const sortedCustomerFlags =
    customerFlags?.toSorted((a, b) => {
      return a.name > b.name ? 1 : -1
    }) ?? []

  return (
    <>
      <Seo title={t(SETTINGS.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="page.manageCustomerFlags.title"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginTop: "3rem",
              marginBottom: "3rem",
              maxWidth: "100%",
              flexWrap: "wrap",
            }}
          >
            <Paper
              sx={{
                flex: 1,
                flexWrap: "wrap",
                minWidth: "280px",
                maxWidth: "700px",
              }}
            >
              <SectionHeader>
                <label>{t("page.manageCustomerFlags.title")}</label>
                {hasPermissions?.([DefaultPermission.CreateCustomerFlag]) ? (
                  <Button
                    aria-label={t("page.manageCustomerFlags.addCustomerFlag") as string}
                    color="primary"
                    data-testid="createCustomerFlagButton"
                    onClick={() => {
                      setInEditMode(true)
                      setSelectedFlag(undefined)
                    }}
                    sx={{
                      fontWeight: "bold",
                      "& svg": {
                        fontSize: "1.0rem",
                      },
                      "& div": {
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                      },
                    }}
                    variant="contained"
                  >
                    <AddIcon />
                    <Box>{t("page.manageCustomerFlags.addCustomerFlag")}</Box>
                  </Button>
                ) : null}
              </SectionHeader>
              <Divider />
              <SectionContent sx={{ maxHeight: 600, overflowY: "scroll" }}>
                {queryLoading ? (
                  <Box sx={classes.spinnerContainer}>
                    <CircularProgress color="secondary" size={20} thickness={6.0} />
                  </Box>
                ) : null}
                {!queryLoading &&
                  sortedCustomerFlags?.map((customerFlag: CustomerFlag) => {
                    return (
                      <Box key={customerFlag.id} sx={classes.row}>
                        <Box sx={classes.fieldContainer}>
                          <Box
                            sx={[
                              {
                                height: "1.875rem",
                                minHeight: "1.875rem",
                                width: "1.875rem",
                                minWidth: "1.875rem",
                                borderRadius: "0.25rem",
                                marginRight: "1rem",
                                backgroundColor: customerFlag.colorCode || "#ccc",
                              },
                            ]}
                          />
                          <Box sx={classes.textContainer}>
                            <Box
                              sx={{
                                fontWeight: 600,
                                fontSize: "0.875rem",
                              }}
                            >
                              {customerFlag.name}
                            </Box>
                            <Box
                              sx={{
                                fontWeight: 400,
                                fontSize: "0.75rem",
                              }}
                            >
                              {customerFlag.description}
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {hasPermissions?.([DefaultPermission.UpdateCustomerFlag]) ? (
                            <IconButton
                              aria-label="edit"
                              data-testid="editCustomerFlagBtn"
                              onClick={() => {
                                setSelectedFlag(customerFlag)
                                setInEditMode(true)
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          ) : null}
                          {hasPermissions?.([DefaultPermission.DeleteCustomerFlag]) ? (
                            <IconButton
                              aria-label="delete"
                              data-testid="deleteCustomerFlagBtn"
                              onClick={() => {
                                setSelectedFlag(customerFlag)
                                setIsConfirmDeleteDialogOpen(true)
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : null}
                        </Box>
                      </Box>
                    )
                  })}
                {!error && !queryLoading && customerFlags?.length == 0 && (
                  <EmptyState
                    title={t("page.manageCustomerFlags.emptyState.title")}
                    titleStyle={{
                      fontSize: "1rem",
                    }}
                  >
                    <Box>{t("page.manageCustomerFlags.emptyState.message")}</Box>
                  </EmptyState>
                )}
              </SectionContent>
            </Paper>
            <Paper
              sx={{
                flex: 1,
                minWidth: "280px",
                maxWidth: "600px",
              }}
            >
              <SectionContent sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                <Box
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: "600",
                    alignContent: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                  }}
                >
                  <BoltOutlinedIcon fontSize="small" />
                  {t("page.manageCustomerFlags.quickTip.title")}
                </Box>
                <Box sx={{ fontSize: "0.875rem", padding: "0.625rem" }}>
                  {t("page.manageCustomerFlags.quickTip.message")}
                </Box>
              </SectionContent>
            </Paper>
          </Box>
        </Box>
      </MainLayout>
      {inEditMode ? (
        <CustomerFlagDialog
          customerFlag={selectedFlag}
          loading={isMutationLoading}
          onCancel={() => {
            setInEditMode(false)
            setSelectedFlag(undefined)
          }}
          onSave={(updatedFlag) => {
            selectedFlag?.id
              ? editCustomerFlag({
                  variables: {
                    ...updatedFlag,
                  },
                })
              : createCustomerFlag({
                  variables: {
                    ...updatedFlag,
                  },
                })
          }}
          open={inEditMode}
        />
      ) : null}
      {isConfirmDeleteDialogOpen && selectedFlag ? (
        <Dialog
          aria-describedby="delete-customer-flag-dialog-description"
          aria-labelledby="delete-customer-flag-dialog-title"
          onClose={() => setIsConfirmDeleteDialogOpen(false)}
          open={isConfirmDeleteDialogOpen}
        >
          <DialogTitle id="delete-customer-flag-dialog-title">
            {t("page.manageCustomerFlags.deleteFlagConfirmationPrompt.title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-customer-flag-dialog-description">
              {t("page.manageCustomerFlags.deleteFlagConfirmationPrompt.message", {
                name: selectedFlag.name,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={isMutationLoading}
              onClick={() => setIsConfirmDeleteDialogOpen(false)}
            >
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={isMutationLoading}
              onClick={() => {
                deleteCustomerFlag({
                  variables: {
                    id: selectedFlag.id,
                  },
                })
              }}
            >
              {isMutationLoading ? (
                <CircularProgress color="secondary" size={20} thickness={6.0} />
              ) : (
                <Box>{t("yes")}</Box>
              )}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

const classes = {
  spinnerContainer: {
    padding: "6.25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  linkButton: {
    textDecoration: "none",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ddd",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    "&:first-of-type": {},
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
} as const

export default ManageCustomerFlags
