import React from "react"
import { useTranslation } from "react-i18next"
import HelpIcon from "@mui/icons-material/Help"
import Box from "@mui/material/Box"

export default function SearchTipsItem() {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        backgroundColor: "#efefef",
        padding: "0.5rem",
        fontSize: "0.8rem",
        fontStyle: "normal",
        fontWeight: "600",
        fontFamily: "inherit",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          window.open("/app/help#search", "_blank")
        }}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          flexShrink: 1,
          width: "fit-content",
          gap: "0.25rem",
          color: (theme) => theme.fielderColors.mutedText,
          cursor: "pointer",
          "&:hover": {
            color: "#183CA0",
          },
        }}
      >
        <HelpIcon sx={{ fontSize: "1rem" }} />
        <span>{t("component.search.searchTips")}</span>
      </Box>
      <Box />
    </Box>
  )
}
