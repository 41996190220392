import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Fade from "@mui/material/Fade"
import Alert from "@mui/material/Alert"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import capitalize from "capitalize"
import AddressAutocompleteField from "~/components/AddressAutocompleteField"
import PhoneNumberInput from "~/components/PhoneNumberInput"
import SelectorField from "~/components/SelectorField"
import FielderTextField from "~/components/FielderTextField"
import FieldHelperText from "~/components/FieldHelperText"
import { convertPlaceToAddress, isValidEmail, isBlank, useGoogleMaps } from "~/util"
import {
  Address,
  CreateCustomerInput,
  Customer,
  CustomerType,
  EditCustomerInput,
  LanguageCode,
} from "~/types"
import { usePrompt } from "~/hooks/usePrompt"
import SaveButton from "~/components/SaveButton"
import { ALL_CUSTOMERS } from "~/queries/allCustomers"
import LanguageSelect from "~/components/LanguageSelect"
import { useAuth } from "~/context/AuthContext"

interface Props {
  readonly customer?: Customer
  readonly loading?: boolean
  readonly onCancel: () => void
  readonly onSave: (customer: CreateCustomerInput | EditCustomerInput) => void
}

function CustomerBasicInfoForm({ customer, loading, onCancel, onSave }: Props) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const googleMapsApi = useGoogleMaps()
  const [address, setAddress] = useState<Partial<Address> | null>(() =>
    customer?.address
      ? {
          addressString: customer.address.addressString,
          latitude: customer.address.latitude,
          longitude: customer.address.longitude,
          streetNumber: customer.address.streetNumber,
          route: customer.address.route,
          locality: customer.address.locality,
          administrativeAreaLevel1: customer.address.administrativeAreaLevel1,
          administrativeAreaLevel2: customer.address.administrativeAreaLevel2,
          postalCode: customer.address.postalCode,
          country: customer.address.country,
        }
      : { addressString: "" }
  )
  const addressAutocompleteRef = useRef(null)
  const [type, setType] = useState<CustomerType>(() => customer?.type ?? CustomerType.BUSINESS)
  const [name, setName] = useState<string>(() => customer?.name ?? "")
  const [email, setEmail] = useState<string>(() => customer?.email ?? "")
  const [phoneNumber, setPhoneNumber] = useState<string>(() => customer?.phoneNumber ?? "")
  const [languageCode, setLanguageCode] = useState<LanguageCode | undefined | null>(
    customer?.languageCode ?? user?.organization?.languageCode
  )
  const [checkForDuplicates, setCheckForDuplicates] = useState<boolean>(false)
  const [potentialDuplicates, setPotentialDuplicates] = useState<Customer[]>([])
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [touched, setTouched] = useState<{
    name: boolean
    email: boolean
    languageCode: boolean
  }>(() => ({
    name: false,
    email: false,
    languageCode: false,
  }))
  const [errors, setErrors] = useState<{
    name: string | null
    email: string | null
    languageCode: string | null
  }>(() => ({
    name: null,
    email: null,
    languageCode: null,
  }))

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  // Set up this query to look for possible duplicate customers by name
  useQuery(ALL_CUSTOMERS, {
    variables: {
      filter: name.toLowerCase(),
      sortBy: "name",
      sortDir: "ASC",
      first: 3,
      includeArchived: true,
    },
    context: {
      debounceKey: "CustomerTypeahead",
      debounceTimeout: 500,
    },
    fetchPolicy: "cache-and-network",
    skip: !checkForDuplicates,
    onCompleted: (data) => {
      setCheckForDuplicates(false)
      if (data.allCustomers.edges.length > 0) {
        const exactMatch = data.allCustomers.edges.find(
          (e) => e.node.name.toLowerCase() === name.toLowerCase() && e.node.id !== customer?.id
        )
        if (exactMatch) {
          setPotentialDuplicates([exactMatch.node])
        } else {
          setPotentialDuplicates([])
        }
      }
    },
  })

  const CUSTOMER_TYPE_OPTIONS = useMemo(
    () => [
      { id: CustomerType.BUSINESS, name: t(`customerTypeOptions.BUSINESS`) },
      { id: CustomerType.INDIVIDUAL, name: t(`customerTypeOptions.INDIVIDUAL`) },
    ],
    [t]
  )

  useEffect(() => {
    if (googleMapsApi && addressAutocompleteRef.current) {
      const addressField = new googleMapsApi.maps.places.Autocomplete(
        addressAutocompleteRef.current,
        {
          types: ["address"],
        }
      )

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      const addressComponents = ["formatted_address", "geometry", "address_components"]
      addressField.setFields(addressComponents)

      addressField.addListener("place_changed", () => {
        const place = addressField.getPlace()
        const fullAddress = convertPlaceToAddress(place)
        setAddress(fullAddress)
      })
    }
  }, [googleMapsApi])

  const handleChangeCustomerType = useCallback(
    (selection) => {
      const type = CUSTOMER_TYPE_OPTIONS.find((c) => c.id === selection.id)?.id
      if (type) {
        setType(type)
        setIsDirty(true)
      }
    },
    [CUSTOMER_TYPE_OPTIONS]
  )

  const handleChangeAddress = useCallback((val) => {
    setIsDirty(true)
    setAddress(val)
  }, [])

  const handleChangePhoneNumber = useCallback((val) => {
    setIsDirty(true)
    setPhoneNumber(val)
  }, [])

  const handleBlurName = () => {
    setTouched((prev) => ({
      ...prev,
      name: true,
    }))
    if (isBlank(name)) {
      setErrors((prev) => ({
        ...prev,
        name: t("component.customerBasicInfoForm.validation.name.required"),
      }))
      setCheckForDuplicates(false)
    } else {
      setName(capitalize.words(name, true))
      setErrors((prev) => ({
        ...prev,
        name: null,
      }))
      setCheckForDuplicates(name.length >= 3)
    }
  }

  const handleChangeName = useCallback((e) => {
    setCheckForDuplicates(false)
    setPotentialDuplicates([])
    const value = e.target.value
    setName(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      name: null,
    }))
  }, [])

  const handleBlurEmail = () => {
    setTouched((prev) => ({
      ...prev,
      email: true,
    }))
    if (!isBlank(email) && !isValidEmail(email)) {
      setErrors((prev) => ({
        ...prev,
        email: t("component.customerBasicInfoForm.validation.email.invalid"),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        email: null,
      }))
    }
  }

  const handleChangeEmail = useCallback((e) => {
    const value = e.target.value
    setEmail(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      email: !value?.trim() || isValidEmail(value) ? null : prev.email,
    }))
  }, [])

  const handleChangeLanguageCode = useCallback((selection?: LanguageCode | null) => {
    setIsDirty(true)
    setLanguageCode(selection)
    setErrors((prev) => ({
      ...prev,
      languageCode: null,
    }))
  }, [])

  function isValid() {
    return (
      !errors.name && !errors.email && !isBlank(name) && (isBlank(email) || isValidEmail(email))
    )
  }

  function handleSubmit() {
    const customerInput = {
      type,
      name,
      email,
      phoneNumber,
      address,
      languageCode,
    }
    if (customer?.id) {
      customerInput.id = customer.id
    }
    onSave?.(customerInput)
  }

  return (
    <Box>
      <Box sx={classes.fieldContainer}>
        <SelectorField
          label={t("customerType")}
          name="type"
          onChange={handleChangeCustomerType}
          options={CUSTOMER_TYPE_OPTIONS}
          value={type}
          variant="outlined"
        />
        <FieldHelperText
          message={t(`component.customerBasicInfoForm.helperText.customerType.${type}`)}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={classes.fieldContainer}>
          <FielderTextField
            data-testid="name-Field"
            error={!!errors.name}
            fullWidth
            id="name"
            inputProps={{ maxLength: 255 }}
            label={t("name")}
            name="name"
            onBlur={handleBlurName}
            onChange={handleChangeName}
            onFocus={(e) => {
              e.target.select()
              setCheckForDuplicates(false)
            }}
            required
            value={name}
          />
          {errors.name && touched.name ? (
            <FieldHelperText error message={errors.name} />
          ) : (
            <FieldHelperText
              message={t(`component.customerBasicInfoForm.helperText.name.${type}`)}
            />
          )}
          {potentialDuplicates.length === 1 ? (
            <Fade in>
              <Alert
                action={
                  <a
                    href={`/app/customers/edit/${potentialDuplicates[0].id}`}
                    id="viewCustomer"
                    rel="noreferrer"
                    style={{
                      fontWeight: "500",
                      textDecoration: "underline",
                      color: "inherit",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "0.25rem",
                      padding: "4px 0",
                      marginRight: "0.625rem",
                    }}
                    target="_blank"
                  >
                    <span>{t("viewCustomer")}</span>
                    <OpenInNewIcon sx={{ fontSize: "0.875rem" }} />
                  </a>
                }
                severity="warning"
                sx={{ marginTop: "0.625rem" }}
              >
                {t(`component.customerBasicInfoForm.validation.potentialDuplicate`, { name: name })}
              </Alert>
            </Fade>
          ) : potentialDuplicates.length > 1 ? (
            <div>there are many potential duplicates</div>
          ) : null}
        </Box>

        <Box sx={classes.fieldContainer}>
          <AddressAutocompleteField
            defaultValue={address?.addressString}
            label={t("address") as string}
            onChange={handleChangeAddress}
          />
          <FieldHelperText
            message={t(`component.customerBasicInfoForm.helperText.address.${type}`)}
          />
        </Box>
        <Box sx={classes.fieldContainer}>
          <PhoneNumberInput
            label={t("phoneNumber") as string}
            onChange={handleChangePhoneNumber}
            value={phoneNumber}
          />
          <FieldHelperText
            message={t(`component.customerBasicInfoForm.helperText.phoneNumber.${type}`)}
          />
        </Box>
        <Box sx={classes.fieldContainer}>
          <FielderTextField
            data-testid="email-Field"
            error={!!errors.email}
            fullWidth
            id="email"
            inputProps={{ maxLength: 255 }}
            label={t("email")}
            name="email"
            onBlur={handleBlurEmail}
            onChange={handleChangeEmail}
            onFocus={(e) => e.target.select()}
            type="email"
            value={email}
          />
          {errors.email && touched.email ? (
            <FieldHelperText error message={errors.email} />
          ) : (
            <FieldHelperText
              message={t(`component.customerBasicInfoForm.helperText.email.${type}`)}
            />
          )}
        </Box>
        <Box sx={classes.fieldContainer}>
          <LanguageSelect
            error={Boolean(errors.languageCode)}
            label={t("language") as string}
            name="language"
            onChange={handleChangeLanguageCode}
            value={languageCode}
          />
          {errors.languageCode ? (
            <FieldHelperText error message={errors.languageCode} />
          ) : (
            <FieldHelperText
              message={t("component.customerBasicInfoForm.helperText.languageCode")}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <Button
            color="secondary"
            data-testid="cancelButton"
            disabled={loading}
            onClick={onCancel}
            variant="outlined"
          >
            {t("cancel")}
          </Button>
          <SaveButton disabled={!isValid()} loading={loading} onClick={handleSubmit} />
        </Box>
      </Box>
    </Box>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
} as const

export default CustomerBasicInfoForm
