import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import TaxRateInput from "../../../../components/TaxRateInput"
import { TaxRateFormInput } from "../../../../types"

interface TaxRateGroupRowProps {
  taxRateFormInput: TaxRateFormInput
  onBlur?: () => void
  onChange: (taxRate: TaxRateFormInput) => void
  onDelete: (taxRate: TaxRateFormInput) => void
}

function TaxRateForm({ taxRateFormInput, onBlur, onChange, onDelete }: TaxRateGroupRowProps) {
  const { t } = useTranslation()

  return (
    <TableRow>
      <TableCell sx={classes.fieldCell}>
        <TextField
          data-testid="name-Field"
          error={!!taxRateFormInput.errors?.name}
          fullWidth
          id="name"
          inputProps={{ maxLength: 50 }}
          name="name"
          onBlur={onBlur}
          onChange={(e) => {
            onChange({
              ...taxRateFormInput,
              name: e.target.value,
            })
          }}
          onFocus={(e) => e.target.select()}
          placeholder={t("taxRateName")}
          required
          size="small"
          value={taxRateFormInput.name}
          variant="outlined"
        />
      </TableCell>
      <TableCell sx={classes.fieldCell}>
        <TaxRateInput
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          customInput={TextField}
          data-testid="rate-Field"
          error={!!taxRateFormInput.errors?.rate}
          fullWidth={false}
          id="rate"
          max={9999999}
          name="rate"
          onBlur={onBlur}
          onChange={(e) => {
            onChange({
              ...taxRateFormInput,
              rate: e.target.value,
            })
          }}
          placeholder={t("rate")}
          required
          size="small"
          value={taxRateFormInput.rate}
          variant="outlined"
        />
      </TableCell>
      <TableCell align="right" sx={classes.fieldCell}>
        <Box sx={classes.buttonContainer}>
          <IconButton
            aria-label="delete"
            onClick={() => onDelete(taxRateFormInput)}
            sx={classes.button}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  )
}

const classes = {
  fieldCell: {
    padding: "0.5rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    marginTop: 0,
    marginBottom: 0,
    border: "none",
  },
  buttonContainer: {
    margin: 0,
    marginTop: "0.25rem",
    marginBottom: "0.25rem",
    display: "inline-flex",
    padding: 0,
    position: "relative",
    flexDirection: "column",
    verticalAlign: "top",
    lineHeight: 1.43,
  },
  button: {
    padding: "0.25rem",
  },
}

export default TaxRateForm
