import gql from "graphql-tag"

export const UNARCHIVE_USER = gql`
  mutation UnarchiveUser(
    $id: ID!
  ) {
    unarchiveUser(id: $id) {
      user {
        id
        email
        firstName
        lastName
        jobTitle
        mobilePhoneNumber
        status
        isArchived
        languageCode
        roles {
          id
          isStandard
          name
        }
        organization {
          id
          tradeName
        }
        avatar {
          id
          signedUrl
          updatedAt
        }
        lastLogin
        createdAt
        createdBy {
          id
          email
        }
        updatedAt
        updatedBy {
          id
          email
        }
      }
    }
  }
`
