import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import MainLayout from "../../components/MainLayout"
import PageHeader from "../../components/PageHeader"
import Seo from "../../components/Seo"
import { JOBS } from "../../util"
import JobBoardContainer from "./components/JobBoardContainer"

function JobBoardPage() {
  const { t } = useTranslation()

  return (
    <>
      <Seo title={t(JOBS.titleKey)} />
      <MainLayout activeSection={JOBS}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader icon={JOBS.icon} leafTitleKey={JOBS.titleKey} />
        </Box>
        <JobBoardContainer />
      </MainLayout>
    </>
  )
}

export default JobBoardPage
