import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { OrganizationItem } from "../../../types"

interface ItemInfoAlertProps {
  readonly item: OrganizationItem
}

function ItemInfoAlert({ item }: ItemInfoAlertProps) {
  const { t } = useTranslation()

  const isNotOrderable = Boolean(item?.isArchived)
  const isNotCancelable = Boolean(!item?.isCancelable)
  const shouldDisplay = isNotCancelable

  if (!shouldDisplay) {
    return null
  } else {
    return (
      <Box
        id="item-info-alert"
        sx={{
          marginTop: "2rem",
        }}
      >
        {isNotCancelable || isNotOrderable ? (
          <Box
            sx={(theme) => ({
              borderRadius: "4px",
              padding: "0.875rem",
              backgroundColor: theme.fielderColors.warningLight,
              fontWeight: "600",
              textAlign: "center",
            })}
          >
            <Box>{isNotOrderable ? t("itemNotOrderable") : t("itemOrdersNotCancelable")}</Box>
          </Box>
        ) : null}
      </Box>
    )
  }
}

export default ItemInfoAlert
