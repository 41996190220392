import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { gql, useQuery, useMutation, ApolloError } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import CircularProgress from "@mui/material/CircularProgress"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/EditOutlined"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import SectionHeader from "../../../components/SectionHeader"
import SectionContent from "../../../components/SectionContent"
import Seo from "../../../components/Seo"
import SnackbarMessage from "../../../components/SnackbarMessage"
import RegionDialog from "./components/RegionDialog"
import { parseGraphQLErrorCode, SETTINGS } from "../../../util"
import { useAuth } from "../../../context/AuthContext"
import { Snack, Region, DefaultPermission, CurrencyCode, LanguageCode } from "../../../types"

const ALL_REGIONS = gql`
  query AllRegions {
    allRegions {
      id
      name
      code
      currencyCode
      languageCode
      createdAt
      updatedAt
    }
  }
`

const CREATE_REGION = gql`
  mutation CreateRegion(
    $name: String!
    $code: String!
    $currencyCode: CurrencyCode!
    $languageCode: LanguageCode
  ) {
    createRegion(
      input: { name: $name, code: $code, currencyCode: $currencyCode, languageCode: $languageCode }
    ) {
      region {
        id
        name
        code
        currencyCode
        languageCode
        createdAt
        createdBy {
          id
          email
        }
        updatedAt
        updatedBy {
          id
          email
        }
      }
    }
  }
`

const EDIT_REGION = gql`
  mutation EditRegion(
    $id: ID!
    $name: String!
    $code: String!
    $currencyCode: CurrencyCode!
    $languageCode: LanguageCode
  ) {
    editRegion(
      input: {
        id: $id
        name: $name
        code: $code
        currencyCode: $currencyCode
        languageCode: $languageCode
      }
    ) {
      region {
        id
        name
        code
        currencyCode
        languageCode
        createdAt
        createdBy {
          id
          email
        }
        updatedAt
        updatedBy {
          id
          email
        }
      }
    }
  }
`

function Meta({ label, value }: { label: string; value: string }) {
  return (
    <Box
      sx={{
        color: (theme) => theme.fielderColors.mutedText,
        fontSize: "0.625rem",
        label: {
          fontWeight: "700",
        },
      }}
    >
      <label>{label}</label>: {value}
    </Box>
  )
}

function Regions() {
  const { t } = useTranslation()
  const [snack, setSnack] = useState<Snack>()
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [selectedRegion, setSelectedRegion] = useState<Region>()
  const { hasPermissions } = useAuth()

  const {
    loading: queryLoading,
    data,
    refetch,
  } = useQuery(ALL_REGIONS, {
    fetchPolicy: "cache-and-network",
  })

  const mutationOptions = {
    onCompleted: () => {
      setInEditMode(false)
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      refetch()
    },
    onError: (error: ApolloError) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  }
  const [editRegion, { loading: editRegionLoading }] = useMutation(EDIT_REGION, mutationOptions)
  const [createRegion, { loading: createRegionLoading }] = useMutation(
    CREATE_REGION,
    mutationOptions
  )

  const regions = data?.allRegions

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="regions"
          />
          <Paper sx={classes.widgetContainer}>
            <SectionHeader>
              <label>{t("regions")}</label>
              {hasPermissions?.([DefaultPermission.CreateRegion]) ? (
                <Button
                  aria-label={t("addRegion") as string}
                  color="primary"
                  data-testid="createRegionButton"
                  onClick={() => {
                    setInEditMode(true)
                    setSelectedRegion(undefined)
                  }}
                  sx={classes.primaryActionButton}
                  variant="contained"
                >
                  <AddIcon />
                  <Box>{t("addRegion")}</Box>
                </Button>
              ) : null}
            </SectionHeader>
            <Divider />
            <SectionContent>
              {queryLoading ? (
                <Box sx={classes.spinnerContainer}>
                  <CircularProgress color="secondary" size={20} thickness={6.0} />
                </Box>
              ) : null}
              {!queryLoading &&
                regions?.map((region: Region) => {
                  return (
                    <Box key={region.id} sx={classes.regionRow}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.15rem" }}>
                        <Box sx={{ fontSize: "1rem" }}>{region.name}</Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.1rem" }}>
                          <Meta label={t("code")} value={region.code} />
                          <Meta label={t("currencyCode")} value={region.currencyCode} />
                          <Meta
                            label={t("language")}
                            value={t(`languageCodeOptions.${region.languageCode}`)}
                          />
                        </Box>
                      </Box>

                      <Box sx={classes.rowButtonContainer}>
                        {hasPermissions?.([DefaultPermission.UpdateRegion]) ? (
                          <IconButton
                            aria-label="edit"
                            data-testid="editRegionBtn"
                            onClick={() => {
                              setSelectedRegion(region)
                              setInEditMode(true)
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                    </Box>
                  )
                })}
            </SectionContent>
          </Paper>
        </Box>
      </MainLayout>
      {inEditMode ? (
        <RegionDialog
          loading={createRegionLoading || editRegionLoading}
          onCancel={() => {
            setInEditMode(false)
            setSelectedRegion(undefined)
          }}
          onSave={(
            name: string,
            code: string,
            currencyCode: CurrencyCode,
            languageCode?: LanguageCode | null
          ) => {
            selectedRegion?.id
              ? editRegion({
                  variables: {
                    id: selectedRegion.id,
                    isArchived: selectedRegion.isArchived,
                    name,
                    code,
                    currencyCode,
                    languageCode,
                  },
                })
              : createRegion({
                  variables: {
                    isArchived: false,
                    name,
                    code,
                    currencyCode,
                    languageCode,
                  },
                })
          }}
          open={inEditMode}
          region={selectedRegion}
        />
      ) : null}
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
  },
  spinnerContainer: {
    padding: "6.25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  primaryActionButton: {
    fontWeight: "bold",
    "& svg": {
      fontSize: "1.0rem",
    },
    "& div": {
      marginLeft: "0.625rem",
      marginRight: "0.625rem",
    },
  },
  widgetContainer: {
    maxWidth: "700px",
    marginBottom: "1.25rem",
    marginTop: "3.75rem",
  },
  regionRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ddd",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    "&:first-of-type": {},
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  rowButtonContainer: {
    display: "flex",
  },
}

export default Regions
