import React from "react"
import Paper from "@mui/material/Paper"
import ButtonBase from "@mui/material/ButtonBase"
import Box from "@mui/material/Box"

interface MenuItemCardProps {
  readonly icon?: React.ReactElement
  readonly onClick?: () => void
  readonly testID?: string
  readonly title: string
}

function MenuItemCard({ icon, onClick, testID, title }: MenuItemCardProps) {
  return (
    <ButtonBase data-testid={testID} focusRipple onClick={onClick} sx={{ width: "100%" }}>
      <Paper
        sx={{
          height: "8.75rem",
          width: "100%",
          border: "1px solid #d1d1d1",
          fontWeight: "bold",
          fontSize: "0.9375rem",
          color: "#212121",
          padding: "1.25rem",
          paddingTop: "1.5625rem",
          paddingBottom: "1.5625rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              wordWrap: "break-word",
            }}
          >
            {title}
          </Box>
          <Box
            sx={{
              marginTop: "0.625rem",
            }}
          >
            {icon}
          </Box>
        </Box>
      </Paper>
    </ButtonBase>
  )
}

export default MenuItemCard
