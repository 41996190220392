import React, { useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Accordion from "@mui/material/Accordion"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"
import SectionHeader from "./SectionHeader"
import SectionContent from "./SectionContent"
import Button from "@mui/material/Button"
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import Dialog from "@mui/material/Dialog"

interface GuideItem {
  id: string
  title: string
  description: string
  actionTitle: string
  actionUrl: string
  videoUrl: string | null
}

function GuideItemTitle({ title }: { title: string }) {
  return (
    <Box sx={{ fontWeight: "600", fontSize: "1rem", color: (theme) => theme.fielderColors.text }}>
      {title}
    </Box>
  )
}

interface Props {
  sx?: SxProps<Theme>
}

export default function GettingStartedGuide({ sx }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState<string | false>(false)
  const [videoItem, setVideoItem] = useState<GuideItem | null>(null)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const items: GuideItem[] = useMemo(() => {
    return [
      {
        id: "create-job-workflow",
        title: t("component.gettingStartedGuide.item.createJobWorkflow.title"),
        description: t("component.gettingStartedGuide.item.createJobWorkflow.description"),
        actionTitle: t("component.gettingStartedGuide.item.createJobWorkflow.actionTitle"),
        actionUrl: "/app/settings/jobworkflows",
        videoUrl: t("component.gettingStartedGuide.item.createJobWorkflow.videoUrl"),
      },
      {
        id: "invite-staff",
        title: t("component.gettingStartedGuide.item.inviteStaff.title"),
        description: t("component.gettingStartedGuide.item.inviteStaff.description"),
        actionTitle: t("component.gettingStartedGuide.item.inviteStaff.actionTitle"),
        actionUrl: "/app/settings/users/list",
        videoUrl: t("component.gettingStartedGuide.item.inviteStaff.videoUrl"),
      },
      {
        id: "review-inventory",
        title: t("component.gettingStartedGuide.item.reviewInventory.title"),
        description: t("component.gettingStartedGuide.item.reviewInventory.description"),
        actionTitle: t("component.gettingStartedGuide.item.reviewInventory.actionTitle"),
        actionUrl: "/app/inventory",
        videoUrl: t("component.gettingStartedGuide.item.reviewInventory.videoUrl"),
      },
      {
        id: "setup-accounting-package",
        title: t("component.gettingStartedGuide.item.accountingPackage.title"),
        description: t("component.gettingStartedGuide.item.accountingPackage.description"),
        actionTitle: t("component.gettingStartedGuide.item.accountingPackage.actionTitle"),
        actionUrl: "/app/settings/plugins",
        videoUrl: t("component.gettingStartedGuide.item.accountingPackage.videoUrl"),
      },
      {
        id: "setup-customers",
        title: t("component.gettingStartedGuide.item.setupCustomers.title"),
        description: t("component.gettingStartedGuide.item.setupCustomers.description"),
        actionTitle: t("component.gettingStartedGuide.item.setupCustomers.actionTitle"),
        actionUrl: "/app/customers/list",
        videoUrl: null,
      },
      {
        id: "setup-tax-rates",
        title: t("component.gettingStartedGuide.item.setupTaxRates.title"),
        description: t("component.gettingStartedGuide.item.setupTaxRates.description"),
        actionTitle: t("component.gettingStartedGuide.item.setupTaxRates.actionTitle"),
        actionUrl: "/app/settings/taxrates",
        videoUrl: null,
      },
      {
        id: "setup-email-templates",
        title: t("component.gettingStartedGuide.item.setupEmailTemplates.title"),
        description: t("component.gettingStartedGuide.item.setupEmailTemplates.description"),
        actionTitle: t("component.gettingStartedGuide.item.setupEmailTemplates.actionTitle"),
        actionUrl: "/app/settings/templates/email/list",
        videoUrl: null,
      },
      {
        id: "setup-contract-templates",
        title: t("component.gettingStartedGuide.item.setupContractTemplates.title"),
        description: t("component.gettingStartedGuide.item.setupContractTemplates.description"),
        actionTitle: t("component.gettingStartedGuide.item.setupContractTemplates.actionTitle"),
        actionUrl: "/app/settings/templates/contract/list",
        videoUrl: t("component.gettingStartedGuide.item.setupContractTemplates.videoUrl"),
      },
      {
        id: "setup-estimate-templates",
        title: t("component.gettingStartedGuide.item.setupEstimateTemplates.title"),
        description: t("component.gettingStartedGuide.item.setupEstimateTemplates.description"),
        actionTitle: t("component.gettingStartedGuide.item.setupEstimateTemplates.actionTitle"),
        actionUrl: "/app/settings/templates/estimate/list",
        videoUrl: null,
      },
      {
        id: "create-job",
        title: t("component.gettingStartedGuide.item.createJob.title"),
        description: t("component.gettingStartedGuide.item.createJob.description"),
        actionTitle: t("component.gettingStartedGuide.item.createJob.actionTitle"),
        actionUrl: "/app/jobs",
        videoUrl: null,
      },
      {
        id: "create-product-order",
        title: t("component.gettingStartedGuide.item.createProductOrder.title"),
        description: t("component.gettingStartedGuide.item.createProductOrder.description"),
        actionTitle: t("component.gettingStartedGuide.item.createProductOrder.actionTitle"),
        actionUrl: "/app/orders",
        videoUrl: null,
      },
      {
        id: "setup-notifications",
        title: t("component.gettingStartedGuide.item.setupNotifications.title"),
        description: t("component.gettingStartedGuide.item.setupNotifications.description"),
        actionTitle: t("component.gettingStartedGuide.item.setupNotifications.actionTitle"),
        actionUrl: "/app/settings/profile",
        videoUrl: null,
      },
    ]
  }, [t])

  const activeColor = "hsla(1, 10%, 98%, 1)"

  return (
    <Box sx={sx}>
      <Paper>
        <SectionHeader>
          <label>{t("component.gettingStartedGuide.title")}</label>
          {/* <IconButton
            aria-label="open guide menu"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <MoreVertIcon />
          </IconButton> */}
        </SectionHeader>
        <SectionContent>
          <Box
            sx={{
              marginBottom: "1rem",
              fontSize: "0.875rem",
              color: (theme) => theme.fielderColors.mutedText,
              padding: "0 1rem",
            }}
          >
            {t("component.gettingStartedGuide.welcomeMessage")}
          </Box>
          {items.map((item) => {
            return (
              <Accordion
                disableGutters
                expanded={expanded === item.id}
                key={item.id}
                onChange={handleChange(item.id)}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                  borderRadius: "4px",
                  boxShadow: "none",
                  backgroundColor: expanded === item.id ? activeColor : "white",
                  "&:hover": {
                    backgroundColor: activeColor,
                  },
                }}
              >
                <AccordionSummary
                  aria-controls={`${item.id}-content`}
                  expandIcon={<ExpandMoreIcon />}
                  id={`${item.id}-header`}
                >
                  <Box
                    sx={{
                      border: "none",
                      boxShadow: "none",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <GuideItemTitle title={item.title} />
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  id={`${item.id}-content`}
                  sx={{
                    paddingBottom: "1.5rem",
                    borderTop: "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={(theme) => {
                        return {
                          display: "flex",
                          flexDirection: "column",
                          gap: "1.5rem",
                          [theme.breakpoints.up("md")]: {
                            flexDirection: item.videoUrl ? "row-reverse" : "row",
                          },
                        }
                      }}
                    >
                      {item.videoUrl ? (
                        <button
                          css={{
                            border: "none",
                            background: "none",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.25rem",
                            cursor: "pointer",
                          }}
                          onClick={() => setVideoItem(item)}
                          type="button"
                        >
                          <Box
                            sx={{
                              height: "90px",
                              width: "180px",
                              borderRadius: "4px",
                              backgroundColor: "hsl(0, 0%, 10%)",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "relative",
                              overflow: "hidden",
                            }}
                          >
                            <Box
                              sx={{
                                position: "absolute",
                                backgroundImage: 'url("/images/fielder-logo-lightbg.png")',
                                backgroundSize: "100%",
                                backgroundRepeat: "no-repeat",
                                width: "120px",
                                height: "40%",
                              }}
                            />
                            <Box
                              sx={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.70)",
                                zIndex: 2,
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.50)",
                                },
                              }}
                            />
                            <PlayCircleOutlineIcon
                              fontSize="large"
                              sx={{
                                position: "absolute",
                                color: "white",
                                zIndex: 2,
                                pointerEvents: "none",
                              }}
                            />
                          </Box>
                          <Box sx={{ fontSize: "0.75rem", textDecoration: "underline" }}>
                            {t("component.gettingStartedGuide.videoCaption")}
                          </Box>
                        </button>
                      ) : null}
                      <Box sx={{ fontSize: "0.875rem", justifySelf: "flex-end" }}>
                        {item.description}
                      </Box>
                    </Box>
                    <Box>
                      <Button onClick={() => navigate(item.actionUrl)} variant="outlined">
                        {item.actionTitle}
                      </Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </SectionContent>
      </Paper>
      {videoItem?.videoUrl ? (
        <Dialog onClose={() => setVideoItem(null)} open maxWidth="854">
          <Box
            sx={{
              boxSizing: "border-box",
              width: "854px",
              height: "480px",
              backgroundColor: "black",
              padding: 0,
              margin: 0,
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          >
            <iframe
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              tabIndex="-1"
              frameBorder="0"
              scrolling="no"
              src={videoItem.videoUrl}
              title={videoItem.title}
              height="100%"
              width="100%"
            />
          </Box>
        </Dialog>
      ) : null}
      <script src="https://player.vimeo.com/api/player.js" />
    </Box>
  )
}
