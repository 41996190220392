/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import Downshift from "downshift"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import ListItemIcon from "@mui/material/ListItemIcon"
import MenuItem from "@mui/material/MenuItem"
import Paper from "@mui/material/Paper"
import BusinessOutlined from "@mui/icons-material/BusinessOutlined"
import PersonOutlined from "@mui/icons-material/PersonOutlined"
import capitalize from "capitalize"
import { ALL_CUSTOMERS } from "../queries/allCustomers"
import FielderTextField from "./FielderTextField"
import FieldSpinner from "./FieldSpinner"
import Box from "@mui/material/Box"
import FlagIcon from "@mui/icons-material/Flag"

function Suggestions({ getItemProps, inputValue, menuId }) {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(ALL_CUSTOMERS, {
    variables: {
      filter: inputValue,
      sortBy: "name",
      sortDir: "ASC",
      first: 10,
      includeArchived: false,
    },
    context: {
      debounceKey: "CustomerTypeahead",
      debounceTimeout: 500,
    },
    fetchPolicy: "cache-and-network",
  })

  if (loading) {
    return <FieldSpinner message={t("loadingCustomers")} />
  }

  if (error) return <Box>{`Error! ${error.message}`}</Box>

  const options = data.allCustomers.edges.map((e) => e.node)

  return (
    <Paper id={menuId} square>
      {options.map((option) => {
        return (
          <MenuItem
            classes={{ root: classes.root }}
            key={option.id}
            {...getItemProps({ item: option })}
            style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          >
            <Box sx={classes.customerListItem}>
              <Box sx={classes.customerName}>
                <Box component="span">{option.name}</Box>
                {option.flags?.length > 0 ? (
                  <FlagIcon
                    style={{
                      color: option.flags[0].colorCode,
                      fontSize: "18px",
                    }}
                  />
                ) : null}
              </Box>
              <Box sx={classes.customerAddress}>
                {option.address ? <span>{option.address.addressString}</span> : null}
              </Box>
            </Box>
          </MenuItem>
        )
      })}
      <MenuItem
        classes={{ root: classes.root }}
        sx={classes.newCustomerItem}
        {...getItemProps({
          item: { name: inputValue, type: "INDIVIDUAL" },
        })}
      >
        <ListItemIcon>
          <PersonOutlined />
        </ListItemIcon>
        <Box sx={classes.customerListItem}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={classes.customerName}>
              <Box component="span">{inputValue}</Box>
            </Box>
          </Box>
          <Box sx={classes.customerAddress}>
            <Box>{t("newCustomer")}</Box>
            <Box sx={{ marginLeft: "0.25rem" }}>({t("individual")})</Box>
          </Box>
        </Box>
      </MenuItem>
      <MenuItem
        classes={{ root: classes.root }}
        sx={classes.newCustomerItem}
        {...getItemProps({
          item: { name: inputValue, type: "BUSINESS" },
        })}
      >
        <ListItemIcon>
          <BusinessOutlined />
        </ListItemIcon>
        <Box sx={classes.customerListItem}>
          <Box sx={classes.customerName}>
            <span>{inputValue}</span>
          </Box>
          <Box sx={classes.customerAddress}>
            <Box>{t("newCustomer")}</Box>
            <Box sx={{ marginLeft: "0.25rem" }}>({t("business")})</Box>
          </Box>
        </Box>
      </MenuItem>
    </Paper>
  )
}

interface CustomerTypeaheadProps {
  readonly currentValue: { id: string; name: string }
  readonly hasError?: boolean
  readonly label: string
  readonly onBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  readonly onSelect: (customer: { id: string; name: string } | null) => void
  readonly required?: boolean
  readonly size?: "small" | "medium"
}

function CustomerTypeahead({
  currentValue,
  hasError,
  label,
  onBlur,
  onSelect,
  required,
  size = "medium",
}: CustomerTypeaheadProps) {
  return (
    <Downshift
      itemToString={(item) => (item ? capitalize.words(item.name, true) : "")}
      onInputValueChange={(inputValue, stateAndHelpers) => {
        if (inputValue == "" && stateAndHelpers.selectedItem) {
          stateAndHelpers.clearSelection()
        }
      }}
      onSelect={(item) => onSelect(item)}
      selectedItem={currentValue}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        inputValue = "",
        selectedItem,
        highlightedIndex,
        isOpen,
      }) => {
        return (
          <div>
            <FielderTextField
              InputLabelProps={getLabelProps()}
              InputProps={{
                ...getInputProps({ onBlur: onBlur, value: capitalize.words(inputValue, true) }),
              }}
              error={hasError}
              fullWidth
              inputProps={{ maxLength: 255 }}
              label={label}
              onBlur={onBlur}
              onFocus={(e) => e.target.select()}
              required={required}
              size={size}
            />
            {isOpen && inputValue.length >= 2 ? (
              <Suggestions
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
                inputValue={capitalize.words(inputValue, true)}
                selectedItem={selectedItem}
              />
            ) : null}
          </div>
        )
      }}
    </Downshift>
  )
}

const classes = {
  root: {
    borderBottom: "1px solid #ededed",
  },
  customerListItem: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    lineHeight: "normal",
  },
  customerName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
  },
  customerAddress: {
    display: "flex",
    alignItems: "center",
    lineHeight: "normal",
    margin: 0,
    padding: 0,
    fontSize: "0.75rem",
    color: "#212121",
  },
  newCustomerItem: {
    backgroundColor: "#fff5b1",
  },
} as const

export default React.memo(CustomerTypeahead)
