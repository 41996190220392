import gql from "graphql-tag"
import ChecklistFragment from "./fragments/checklist"

export const CREATE_CHECKLIST = gql`
  mutation CreateChecklist(
    $jobId: ID!
    $type: ChecklistType!
    $diagram: String
    $projectDescription: String
    $installerUserIds: [ID!]
    $installationEquipment: String
    $installationDate: LocalDateTime
    $lineItems: [ChecklistLineItemInput!]
  ) {
    createChecklist(
      input: {
        jobId: $jobId
        type: $type
        projectDescription: $projectDescription
        installerUserIds: $installerUserIds
        installationEquipment: $installationEquipment
        installationDate: $installationDate
        diagram: $diagram
        lineItems: $lineItems
      }
    ) {
      checklist {
        ...ChecklistFragment
      }
    }
  }
  ${ChecklistFragment}
`
