import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"
import ContactFragment from "./fragments/contact"

export const GET_JOB_BASIC_INFO = gql`
  query GetJobBasicInfo($id: ID!) {
    getJobById(id: $id) {
      id
      number
      notes {
        id
        text
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
      customer {
        id
        name
        email
        phoneNumber
        type
        flags {
          id
          name
          description
          colorCode
        }
        address {
          ...FullAddress
        }
      }
      categories {
        id
        name
      }
      workflow {
        id
        name
      }
      workflowStep {
        id
        isInitialStep
        boardPosition
        jobStatus {
          id
          name
          lightColor
          mediumColor
          darkColor
        }
      }
      description
      desiredCompletionDate
      address {
        ...FullAddress
      }
      siteContact {
        ...ContactFragment
      }
      organization {
        id
        tradeName
        phoneNumber
        email
        websiteURL
        address {
          ...FullAddress
        }
      }
    }
  }
  ${FullAddress}
  ${ContactFragment}
`
