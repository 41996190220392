import React from "react"
import { useTranslation } from "react-i18next"
import SelectorField from "./SelectorField"

const USER_STATUS_OPTIONS = ["ACTIVE", "LOCKED", "LOCKED_NEEDS_PASSWORD_CHANGE"]

interface UserStatusSelectProps {
  readonly defaultValue?: string
  readonly label: string
  readonly name: string
  readonly onChange: (value: any) => void
  readonly required?: boolean
}

function UserStatusSelect({
  defaultValue,
  label,
  name,
  onChange,
  required,
}: UserStatusSelectProps) {
  const { t } = useTranslation()

  const options = USER_STATUS_OPTIONS.map((o) => {
    return {
      id: o,
      name: t(`userStatus.${o}`),
    }
  })

  return (
    <SelectorField
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      required={required}
      value={defaultValue}
    />
  )
}

export default UserStatusSelect
