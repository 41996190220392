import gql from "graphql-tag"

export const ALL_USERS = gql`
  query AllUsers(
    $filter: String
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $roleNames: [String]
    $statuses: [UserStatus]
    $includeArchived: Boolean
  ) {
    allUsers(
      input: {
        filter: $filter
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        last: $last
        after: $after
        before: $before
      }
      userFilter: { 
        roleNames: $roleNames
        includeArchived: $includeArchived
        statuses: $statuses
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          email
          firstName
          lastName
          jobTitle
          mobilePhoneNumber
          languageCode
          status
          isArchived
          roles {
            id
            isStandard
            name
          }
          avatar {
            id
            signedUrl
            updatedAt
          }
          lastLogin
          createdAt
          updatedAt
          organization {
            id
            tradeName
            level
            status
            email
            phoneNumber
            websiteURL
            timeZone
            languageCode
            address {
              addressString
              latitude
              longitude
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
