import gql from "graphql-tag"
import OrganizationFragment from "./fragments/organization"

/**
 * Be careful not to ask for data that is not open to the public. 
 * I.e., do not ask for properties that require the user to be authorized, 
 * because at this point the user is not authenticated/authorized yet.
 */
export const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      authToken
      user {
        id
        email
        userHash
        firstName
        lastName
        jobTitle
        mobilePhoneNumber
        status
        languageCode
        roles {
          id
          name
          permissions {
            id
            group
          }
        }
        avatar {
          id
          signedUrl
          updatedAt
        }
        unreadNotificationCount
        lastLogin
        isOrganizationOwner
        organization {
          ...OrganizationFragment
        }
        createdAt
      }
    }
  }
  ${OrganizationFragment}
`
