import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import capitalize from "capitalize"
import { isBlank } from "../../../../util"
import FielderTextField from "../../../../components/FielderTextField"
import { FieldHelperText } from "../../../../components"
import { usePrompt } from "../../../../hooks/usePrompt"
import { JobWorkflow } from "../../../../types"
import SaveButton from "../../../../components/SaveButton"

interface JobWorkflowBasicInfoFormProps {
  workflow: JobWorkflow
  loading?: boolean
  onCancel: () => void
  onSave: (variables: { variables: { name: string; description: string } }) => void
}

function JobWorkflowBasicInfoForm({
  workflow,
  loading = false,
  onCancel,
  onSave,
}: JobWorkflowBasicInfoFormProps) {
  const { t } = useTranslation()
  const [name, setName] = useState<string>(() => workflow?.name ?? "")
  const [description, setDescription] = useState<string>(() => workflow?.description ?? "")
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [touched, setTouched] = useState(() => ({
    name: false,
    description: false,
  }))
  const [errors, setErrors] = useState(() => ({
    name: null,
    description: null,
  }))

  const handleBlurName = () => {
    setTouched((prev) => ({
      ...prev,
      name: true,
    }))
    if (isBlank(name)) {
      setErrors((prev) => ({
        ...prev,
        name: t("page.jobWorkflow.validation.name.required"),
      }))
    } else {
      setName(capitalize.words(name, true))
      setErrors((prev) => ({
        ...prev,
        name: null,
      }))
    }
  }

  const handleChangeName = useCallback((e) => {
    const value = e.target.value
    setName(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      name: null,
    }))
  }, [])

  const handleBlurDescription = () => {
    setTouched((prev) => ({
      ...prev,
      description: true,
    }))
    setErrors((prev) => ({
      ...prev,
      description: null,
    }))
  }

  const handleChangeDescription = useCallback((e) => {
    const value = e.target.value
    setDescription(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      description: null,
    }))
  }, [])

  function isValid() {
    return !errors.name && !isBlank(name)
  }

  function handleSubmit() {
    const variables = {
      id: workflow.id,
      name,
      description,
    }
    onSave?.({
      variables,
    })
  }

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  return (
    <Box>
      <Box sx={classes.fieldContainer}>
        <FielderTextField
          data-testid="name-Field"
          error={!!errors.name}
          fullWidth
          id="name"
          inputProps={{ maxLength: 255 }}
          label={t("workflowName")}
          name="name"
          onBlur={handleBlurName}
          onChange={handleChangeName}
          onFocus={(e) => e.target.select()}
          required
          value={name}
        />
        {errors.name && touched.name && (
          <FieldHelperText data-testid="name-Error" error message={errors.name} />
        )}
      </Box>
      <Box sx={classes.fieldContainer}>
        <FielderTextField
          data-testid="description-Field"
          error={!!errors.description}
          fullWidth
          id="description"
          inputProps={{ maxLength: 1000 }}
          label={t("description")}
          name="description"
          onBlur={handleBlurDescription}
          onChange={handleChangeDescription}
          type="description"
          value={description}
        />
        {errors.description && touched.description && (
          <FieldHelperText data-testid="description-Error" error message={errors.description} />
        )}
      </Box>
      <Box
        sx={{
          marginTop: "1.5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton disabled={!isValid()} loading={loading} onClick={handleSubmit} />
      </Box>
    </Box>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1.25rem",
    paddingBottom: "0.625rem",
  },
} as const

export default JobWorkflowBasicInfoForm
