import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, NavigateProps } from "react-router-dom"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import CircularProgress from "@mui/material/CircularProgress"
import { Seo } from "../components"
import SubscriptionPlanCard from "../components/SubscriptionPlanCard"
import { ALL_SUBSCRIPTION_OPTIONS } from "../queries/allSubscriptionOptions"
import { GET_ORGANIZATION_SUBSCRIPTION } from "../queries/getOrganizationSubscription"
import { BILLING_PERIOD_OPTIONS } from "../util"
import { useAuth } from "../context/AuthContext"
import {
  FielderSubscriptionProduct,
  FielderSubscriptionPricePoint,
  FielderSubscriptionPricePointInterval,
} from "../types"
import PublicAppBar from "../components/PublicAppBar"

/**
 * This page is intended for Franchisee owners rather than the public.
 * That is, a franchisor has already signed up with us and has created
 * an Organization record for the franchisee and a User account for the owner.
 * When the owner first logs in, they will have to choose their subscription plan and pay.
 * That's what this page is for.
 * In other words, it is branded for the franchisor.
 * TODO: Actually we should probably refactor so that the styling/branding is just a prop or something.
 */
function FranchiseePricing() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [setupFee, setSetupFee] = useState<FielderSubscriptionPricePoint>()
  const [subscriptionOptions, setSubscriptionOptions] = useState<FielderSubscriptionProduct[]>([])
  const [billingPeriodOption, setBillingPeriodOption] = useState<string>(
    BILLING_PERIOD_OPTIONS.MONTHLY.value
  )

  const { loading: allSubscriptionOptionsLoading } = useQuery(ALL_SUBSCRIPTION_OPTIONS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setSetupFee(
        data?.allSubscriptionOptions?.find((o: FielderSubscriptionProduct) => o.nameKey === "setup")
          ?.pricePoints?.[0]
      )

      const sortedPlans = data?.allSubscriptionOptions
        ?.filter((o) => o.nameKey !== "setup")
        ?.sort((a, b) => {
          const monthlyPriceA = a.pricePoints.find(
            (p) => p.interval === FielderSubscriptionPricePointInterval.MONTH
          )
          const monthlyPriceB = b.pricePoints.find(
            (p) => p.interval === FielderSubscriptionPricePointInterval.MONTH
          )
          if (monthlyPriceA.unitAmount < monthlyPriceB.unitAmount) return -1
          if (monthlyPriceA.unitAmount > monthlyPriceB.unitAmount) return 1
          return 0
        })
      setSubscriptionOptions(sortedPlans)
    },
  })

  useQuery(GET_ORGANIZATION_SUBSCRIPTION, {
    variables: {
      id: user?.organization?.id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const subscription = data?.getOrganizationById?.fielderSubscription
      if (
        subscription &&
        !["INCOMPLETE", "INCOMPLETE_EXPIRED", "CANCELED"].includes(subscription.status)
      ) {
        // user's Organization already has a subscription, so they shouldn't be coming here.
        // This page is only for setting up new subscriptions - not for adjusting existing subscriptions.
        // Since the user is already logged in, let's just send them to the dashboard
        setRedirectTo({ to: "/app/dashboard", replace: true })
      }
    },
  })

  function handleSelectPlan(selected) {
    const selectedTier = subscriptionOptions.find((p) => p.id === selected.id)
    const selectedPricePoint = selectedTier?.pricePoints.find(
      (p) => p.interval === billingPeriodOption
    )

    setRedirectTo({
      to: `/app/payment/${selectedTier.id}/${selectedPricePoint.id}`,
      state: {
        from: "/app/pricing",
      },
      replace: false,
    })
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  const loading = allSubscriptionOptionsLoading

  return (
    <>
      <Seo title={t("page.franchiseePricing.title")} />
      <PublicAppBar />
      <Box
        component="main"
        sx={{
          minWidth: "300px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "1rem",
          paddingTop: "0.625rem",
          "& h1": {
            textAlign: "center",
            fontSize: "2.2rem",
            marginBottom: "0.625rem",
            lineHeight: "3.125rem",
          },
        }}
      >
        <h1>{t("page.franchiseePricing.title")}</h1>
        <Box
          sx={{
            fontSize: "1.2rem",
          }}
        >
          {t("page.franchiseePricing.subTitle")}
        </Box>
        {loading ? (
          <Box sx={{ marginTop: "6.25rem" }}>
            <CircularProgress />
          </Box>
        ) : null}
        {!loading && subscriptionOptions ? (
          <>
            <RadioGroup
              aria-label="billing period"
              name="billingPeriod"
              onChange={(e) => setBillingPeriodOption(e.target.value)}
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1.875rem",
                "& label:first-of-type": {
                  marginRight: "3.125rem",
                },
                "& svg": {
                  // changes the size of the radio button
                  fontSize: "2.5rem",
                },
                "&.MuiButtonBase-root": {
                  color: "teal",
                  padding: "0.5rem",
                },
              }}
              value={billingPeriodOption}
            >
              <FormControlLabel
                control={
                  <Radio
                    sx={{
                      "&.MuiButtonBase-root": {
                        color: "#747474",
                        padding: "0.5625rem",
                      },
                      "&.Mui-checked": {
                        color: "#000",
                      },
                    }}
                  />
                }
                label={
                  <>
                    <Box
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        lineHeight: 1,
                      }}
                    >
                      {t(BILLING_PERIOD_OPTIONS.MONTHLY.titleKey)}
                    </Box>
                    <Box
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      {t(BILLING_PERIOD_OPTIONS.MONTHLY.subTitleKey)}
                    </Box>
                  </>
                }
                value={BILLING_PERIOD_OPTIONS.MONTHLY.value}
              />
              <FormControlLabel
                control={
                  <Radio
                    sx={{
                      "&.MuiButtonBase-root": {
                        color: "#747474",
                        padding: "0.5625rem",
                      },
                      "&.Mui-checked": {
                        color: "#000",
                      },
                    }}
                  />
                }
                label={
                  <>
                    <Box
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        lineHeight: 1,
                      }}
                    >
                      {t(BILLING_PERIOD_OPTIONS.ANNUALLY.titleKey)}
                    </Box>
                    <Box
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      {t(BILLING_PERIOD_OPTIONS.ANNUALLY.subTitleKey)}
                    </Box>
                  </>
                }
                value={BILLING_PERIOD_OPTIONS.ANNUALLY.value}
              />
            </RadioGroup>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "2.5rem",
              }}
            >
              {subscriptionOptions.map((p) => (
                <SubscriptionPlanCard
                  billingPeriod={billingPeriodOption}
                  key={p.name}
                  onClick={() => {
                    handleSelectPlan(p)
                  }}
                  plan={p}
                  setupFee={setupFee}
                />
              ))}
            </Box>
          </>
        ) : null}
      </Box>
      <Box
        sx={{
          width: "100%",
          color: "#212121",
          textAlign: "center",
          marginTop: "6.25rem",
          paddingBottom: "1.875rem",
        }}
      >
        <Box
          sx={{
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          {t("poweredBy")} Fielder &#8482;
        </Box>
        <Box>www.fielderapp.com</Box>
      </Box>
    </>
  )
}

export default FranchiseePricing
