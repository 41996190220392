import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"
import Select from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"

import { formatPersonName } from "~/util"
import FieldSpinner from "~/components/FieldSpinner"
import FielderBaseInput from "~/components/FielderBaseInput"
import { Contact } from "~/types"

const GET_CUSTOMER_CONTACTS = gql`
  query GetCustomerContacts($id: ID!) {
    getCustomerById(id: $id) {
      id
      contacts {
        id
        firstName
        lastName
        jobTitle
        phoneNumber
        email
        address {
          addressString
        }
        canBeJobSiteContact
        canBeBillingContact
        isArchived
      }
    }
  }
`

interface CustomerContactSelectProps {
  readonly customerId?: string
  readonly disabled?: boolean
  readonly error?: boolean
  readonly id?: string
  readonly label?: string
  readonly name: string
  readonly onBlur?: () => void
  readonly onChange: (value: any) => void
  readonly onlyShowBillingContacts?: boolean
  readonly onlyShowSiteContacts?: boolean
  readonly required?: boolean
  readonly selectedOption?: Partial<Contact> | null
  readonly showCreateContactOption?: boolean
  readonly size?: "small" | "medium"
  readonly sx?: SxProps<Theme>
}

function CustomerContactSelect({
  customerId,
  disabled,
  error: hasError,
  id,
  label,
  name,
  onBlur,
  onChange,
  onlyShowBillingContacts = false,
  onlyShowSiteContacts = false,
  required = false,
  selectedOption,
  showCreateContactOption = true,
  sx = {},
  size = "medium",
}: CustomerContactSelectProps) {
  const { t } = useTranslation()
  const [contactOptions, setContactOptions] = useState([])

  const { loading, error, data } = useQuery(GET_CUSTOMER_CONTACTS, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: customerId,
    },
    skip: !customerId,
  })

  useEffect(() => {
    let contacts = data?.getCustomerById?.contacts || []
    if (contacts && onlyShowBillingContacts) {
      contacts = contacts.filter((c) => c.canBeBillingContact)
    } else if (contacts && onlyShowSiteContacts) {
      contacts = contacts.filter((c) => c.canBeJobSiteContact)
    }
    const options = contacts
      ?.filter((c) => !c.isArchived)
      ?.map((c) => ({
        id: c.id,
        name: formatPersonName(c),
      }))

    if (selectedOption && options && !options.find((o) => o.id === selectedOption.id)) {
      options.push({
        id: selectedOption.id,
        name: formatPersonName(selectedOption),
      })
    }

    setContactOptions(options)

    if (required && !selectedOption && options?.length === 1) {
      const onlyContact = contacts.find((c) => c.id === options[0].id)
      onChange?.(onlyContact)
    }
  }, [data, onChange, onlyShowBillingContacts, onlyShowSiteContacts, required, selectedOption])

  if (error) return `Error! ${error.message}`

  return (
    <FormControl
      disabled={disabled}
      fullWidth
      required={required}
      size={size}
      sx={{
        marginTop: 0,
        marginBottom: 0,
        "& .MuiSelect-select.MuiInputBase-input": {
          paddingBottom: "8px",
        },
        "& label.Mui-focused": {
          color: (theme) => theme.fielderColors.black,
          transform: "translate(14px, 7px) scale(0.75)",
        },
        "& label.Mui-error": {
          color: (theme) => theme.fielderColors.error,
        },
        "& label.MuiInputLabel-shrink": {
          transform: "translate(14px, 7px) scale(0.75)",
        },
        "& div.Mui-error > .MuiSelect-outlined": {
          borderColor: (theme) => theme.fielderColors.error,
        },
        ...sx,
      }}
      variant="outlined"
    >
      {label ? <InputLabel id="customer-contact-select-label">{label}</InputLabel> : null}
      <Select
        error={hasError}
        id={id}
        input={label ? <FielderBaseInput /> : undefined}
        inputProps={{ name: name }}
        label={label}
        labelId="customer-contact-select-label"
        onBlur={onBlur}
        onChange={(event) => {
          const value = event.target.value
          if (value === "CREATE_NEW_CONTACT") {
            onChange?.(value)
          } else {
            const selectedContact = data?.getCustomerById?.contacts?.find(
              (c) => c.id === event.target.value
            )
            onChange?.(selectedContact)
          }
        }}
        required={required}
        value={!loading && contactOptions?.length > 0 && selectedOption ? selectedOption?.id : ""}
      >
        {loading ? <FieldSpinner message={t("loading")} /> : null}
        {!loading &&
          contactOptions?.map((v) => (
            <MenuItem key={v.id} value={v.id}>
              {v.name}
            </MenuItem>
          ))}
        {showCreateContactOption ? (
          <MenuItem
            sx={{
              backgroundColor: "#fff5b155",
            }}
            value="CREATE_NEW_CONTACT"
          >
            {t("createNewContact")}
          </MenuItem>
        ) : null}
      </Select>
    </FormControl>
  )
}

export default React.memo(CustomerContactSelect)
