import React from "react"
import MenuItem from "@mui/material/MenuItem"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { capitalize } from "../util"
import FieldSpinner from "./FieldSpinner"
import SelectorField from "./SelectorField"
import { JobWorkflowStep } from "../types"

const GET_JOB_WORKFLOW_BY_ID = gql`
  query GetJobWorkflowById($id: ID!) {
    getJobWorkflowById(id: $id) {
      id
      steps {
        id
        isInitialStep
        boardPosition
        jobStatus {
          id
          name
          lightColor
          mediumColor
          darkColor
        }
        destinationTransitions {
          id
          destination {
            id
            boardPosition
            jobStatus {
              id
              name
              lightColor
              mediumColor
              darkColor
            }
          }
        }
      }
    }
  }
`

interface JobWorkflowStepSelectProps {
  readonly label?: string
  readonly name?: string
  readonly onChange?: (value: any) => void
  readonly required?: boolean
  readonly selectedOption?: Partial<JobWorkflowStep> | null
  readonly size?: "small" | "medium"
  readonly sx?: SxProps<Theme>
  readonly variant?: "standard" | "outlined" | "filled"
  readonly workflowId?: string | null
  readonly workflowStepId?: string | null
}

const JobWorkflowStepSelect = ({
  label,
  name,
  onChange,
  required,
  selectedOption,
  size,
  sx,
  variant,
  workflowId, // the job's current workflow
  workflowStepId, // the job's current workflow step - will differ from selectedOption if user is changing the job's current step
}: JobWorkflowStepSelectProps) => {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(GET_JOB_WORKFLOW_BY_ID, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: workflowId,
    },
  })

  if (loading) {
    return <FieldSpinner message={t("loadingStatusOptions")} />
  }

  if (error) return <div>{`Error! ${error.message}`}</div>

  const sourceOption = data.getJobWorkflowById.steps.find((step) => {
    if (workflowStepId) {
      return step.id === workflowStepId
    } else {
      return step.isInitialStep
    }
  })
  const destinationOptions = sourceOption?.destinationTransitions?.map((transition) => {
    return transition.destination
  })
  const options = sourceOption
    ? [sourceOption]
        .concat(destinationOptions)
        .map((step) => {
          return {
            id: step.id,
            isInitialStep: step.isInitialStep,
            name: capitalize(step.jobStatus.name),
            mediumColor: step.jobStatus.mediumColor,
            boardPosition: step.boardPosition,
          }
        })
        .sort((a, b) => a.boardPosition - b.boardPosition)
    : []

  return (
    <SelectorField
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      renderOption={(o) => (
        <MenuItem
          key={o.id}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          value={o.id}
        >
          <span>{o.name}</span>
          <div
            style={{
              width: "1.875rem",
              height: "1.875rem",
              borderRadius: "6px",
              backgroundColor: o.mediumColor,
            }}
          />
        </MenuItem>
      )}
      renderValue={(value) => {
        const selected = options.find((o) => o.id === value)
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>{selected?.name}</div>
            {selected?.mediumColor ? (
              <div
                style={{
                  width: "1.875rem",
                  height: "1.875rem",
                  borderRadius: "6px",
                  backgroundColor: selected?.mediumColor,
                }}
              />
            ) : null}
          </div>
        )
      }}
      required={required}
      size={size}
      sx={sx}
      value={selectedOption?.id}
      variant={variant}
    />
  )
}

export default React.memo(JobWorkflowStepSelect)
