import React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import PluginCard from "../../../components/PluginCard"
import Seo from "../../../components/Seo"
import SnackbarMessage from "../../../components/SnackbarMessage"
import SectionHeader from "../../../components/SectionHeader"
import { GET_ORGANIZATION_BY_ID_WITH_PLUGINS } from "../../../queries/getOrganizationById"
import { PLUGINS, SETTINGS } from "../../../util"
import { useAuth } from "../../../context/AuthContext"

function PluginsHome() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAuth()
  const snack = location?.state?.snack
  const userOrgId = user?.organization?.id

  const { data } = useQuery(GET_ORGANIZATION_BY_ID_WITH_PLUGINS, {
    variables: {
      id: userOrgId,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  })

  const enabledPlugins = data?.getOrganizationById?.plugins

  return (
    <>
      {snack ? <SnackbarMessage snack={snack} /> : null}
      <Seo title={t("sectionTitle.settings")} />
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="plugins"
          />
          <SectionHeader
            sx={{
              fontWeight: "bold",
              fontSize: "0.9375rem",
              color: "#212121",
              maxWidth: "700px",
              marginBottom: "2.5rem",
            }}
          >
            {t("page.plugins.headline")}
          </SectionHeader>
          <Grid container spacing={3}>
            {PLUGINS.map((p) => {
              const isEnabled = enabledPlugins?.some(
                (e) => e.pluginProvider.id.toLowerCase() === p.id.toLowerCase()
              )
              return (
                <Grid item key={p.id}>
                  <PluginCard
                    description={t(p.shortDescriptionKey)}
                    enabled={isEnabled}
                    logo={p.logoPath}
                    onClick={() => {
                      if (isEnabled) {
                        navigate(`/app/settings/plugins/configure/${p.id}`)
                      } else {
                        navigate(`/app/settings/plugins/details/${p.id}`)
                      }
                    }}
                    testID={`Plugin-${p.id}`}
                    title={p.name}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </MainLayout>
    </>
  )
}

export default PluginsHome
