import React from "react"
import { styled } from "@mui/material/styles"
import Badge, { BadgeProps } from "@mui/material/Badge"

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    width: "14px",
    height: "14px",
    right: 6,
    top: 6,
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: "50%",
    backgroundColor: theme.fielderColors.brandPrimary,
  },
}))

export default StyledBadge
