import gql from "graphql-tag"

export const ALL_SUBSCRIPTION_OPTIONS = gql`
  query AllSubscriptionOptions {
    allSubscriptionOptions {
      id
      name
      nameKey
      monthlyJobLimit
      isFeatured
      pricePoints {
        id
        name
        type
        interval
        unitAmount
        effectiveMonthlyRate
      }
    }
  }
`
