import React from "react"
import { useTranslation } from "react-i18next"
import { Dayjs } from "dayjs"
import Box from "@mui/material/Box"
import { formatDate, formatPersonName } from "../util"
import { User } from "../types"

interface Props {
  user: Partial<User>
  date: string | Dayjs
  emphasizeUser?: boolean
  timeZone?: string
}

function WhoWhen({ user, date, emphasizeUser = true, timeZone }: Props) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "0.25rem",
        fontSize: "0.75rem",
        color: (theme) => theme.fielderColors.text,
      }}
    >
      <Box sx={{ fontWeight: emphasizeUser ? "600" : "500" }}>{formatPersonName(user)}</Box>
      <Box sx={{ fontWeight: "700" }}>&#11825;</Box>
      <Box sx={{ fontWeight: "400" }}>
        {formatDate(date, t("format:dateFormat.shortWithTime"), timeZone)}
      </Box>
    </Box>
  )
}

export default WhoWhen
