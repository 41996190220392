/* eslint-disable react/jsx-no-literals */
import React, { useState, useEffect } from "react"
import { Rnd } from "react-rnd"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { useTranslation } from "react-i18next"

import {
  DAY_MODE_INTERVAL_WIDTH,
  DAY_MODE_ROW_HEIGHT,
  WEEK_MODE_CELL_WIDTH,
  WEEK_MODE_INTERVAL_HEIGHT,
} from "./Constants"
import {
  TimeFrameOption,
  JobAssignmentStatus,
  JobAssignmentUserBlock,
  JobAssignmentBlockDimensions,
} from "~/types"
import { formatDate } from "~/util"
import {
  getRootBackgroundColor,
  getRootBorderColor,
  getRootTextColor,
} from "~/util/jobAssignmentColors"

function getAdjustedTextStyle(
  frame: { width: number; height: number },
  timeFrame: TimeFrameOption
): React.CSSProperties {
  if (frame.width < 130 && timeFrame !== TimeFrameOption.DAY) {
    return {
      fontSize: "0.625rem",
    }
  } else {
    return {
      marginTop: "0.25rem",
    }
  }
}

/**
 * This function helps to only fire the onDragEnd event if
 * the user has actually moved the element a significant amount.
 */
function exceedsMovementThreshold(
  timeFrame: TimeFrameOption,
  { x, y }: { x: number; y: number },
  state: JobAssignmentBlockDimensions
): boolean {
  const threshhold = 0.5
  const initialX = state.x
  const initialY = state.y
  const xSpace =
    timeFrame === TimeFrameOption.DAY
      ? DAY_MODE_INTERVAL_WIDTH
      : Math.min(state.width, WEEK_MODE_CELL_WIDTH)
  const ySpace = timeFrame === TimeFrameOption.DAY ? DAY_MODE_ROW_HEIGHT : WEEK_MODE_INTERVAL_HEIGHT

  const result =
    Math.abs(x - initialX) > xSpace * threshhold || Math.abs(y - initialY) > ySpace * threshhold

  return result
}

interface Props {
  readonly jobAssignmentUserBlock: JobAssignmentUserBlock
  readonly intervalLength: number
  readonly isAssignmentSelected: boolean
  readonly onDoubleClick: (jobAssignmentUserBlock: JobAssignmentUserBlock) => void
  readonly onMouseDown: (jobAssignmentUserBlock: JobAssignmentUserBlock) => void
  readonly onDrag: (
    jobAssignmentUserBlock: JobAssignmentUserBlock,
    dimensions: JobAssignmentBlockDimensions
  ) => void
  readonly onResize: (
    jobAssignmentUserBlock: JobAssignmentUserBlock,
    dimensions: JobAssignmentBlockDimensions
  ) => void
  readonly onUpdate: (
    jobAssignmentUserBlock: JobAssignmentUserBlock,
    dimensions: JobAssignmentBlockDimensions
  ) => void
  readonly timeFrame: TimeFrameOption
  readonly timeZone: string
}

function JobAssignment({
  jobAssignmentUserBlock,
  intervalLength,
  isAssignmentSelected,
  onDoubleClick,
  onMouseDown,
  onDrag,
  onResize,
  onUpdate,
  timeFrame,
  timeZone,
}: Props) {
  const { t } = useTranslation()
  const [state, setState] = useState<JobAssignmentBlockDimensions>({
    x: jobAssignmentUserBlock.frame.left,
    y: jobAssignmentUserBlock.frame.top,
    width: jobAssignmentUserBlock.frame.width,
    height: jobAssignmentUserBlock.frame.height,
    isDragging: false,
  })

  const rootBackgroundColor = getRootBackgroundColor(
    jobAssignmentUserBlock.assignment,
    isAssignmentSelected
  )
  const rootBorderColor = getRootBorderColor(jobAssignmentUserBlock.assignment)

  const rootTextColor = getRootTextColor(jobAssignmentUserBlock.assignment, isAssignmentSelected)

  useEffect(() => {
    setState((old) => {
      return {
        ...old,
        x: jobAssignmentUserBlock.frame.left,
        y: jobAssignmentUserBlock.frame.top,
        width: jobAssignmentUserBlock.frame.width,
        height: jobAssignmentUserBlock.frame.height,
      }
    })
  }, [
    jobAssignmentUserBlock.frame.left,
    jobAssignmentUserBlock.frame.top,
    jobAssignmentUserBlock.frame.width,
    jobAssignmentUserBlock.frame.height,
  ])

  return (
    <Rnd
      bounds=".gridCoordinateParent"
      className="job-assignment"
      disableDragging={jobAssignmentUserBlock.assignment.isLocked}
      enableResizing={{
        top: false,
        right: timeFrame === TimeFrameOption.DAY && !jobAssignmentUserBlock.assignment.isLocked,
        bottom: timeFrame === TimeFrameOption.WEEK && !jobAssignmentUserBlock.assignment.isLocked,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      onDrag={(e, d) => {
        onDrag?.(jobAssignmentUserBlock, {
          x: d.x,
          y: d.y,
          width: state.width,
          height: state.height,
          isDragging: state.isDragging,
        })
      }}
      onDragStop={(e, d) => {
        if (exceedsMovementThreshold(timeFrame, d, state)) {
          // don't adjust the x & y properties of the state here
          setState({
            ...state,
            isDragging: false,
          })
          const newState = {
            ...state,
            x: d.x,
            y: d.y,
            isDragging: false,
          }
          onUpdate?.(jobAssignmentUserBlock, newState)
        }
      }}
      onResize={(e, dir, ref, delta) => {
        onResize?.(jobAssignmentUserBlock, {
          x: state.x,
          y: state.y,
          width: state.width + delta.width,
          height: state.height + delta.height,
          isDragging: state.isDragging,
        })
      }}
      onResizeStop={(e, dir, ref, delta) => {
        if (timeFrame === TimeFrameOption.DAY) {
          const newWidth = state.width + delta.width
          if (newWidth !== state.width) {
            const newState = {
              ...state,
              width: newWidth,
            }
            setState(newState)
            onUpdate?.(jobAssignmentUserBlock, newState)
          }
        } else if (timeFrame === TimeFrameOption.WEEK) {
          const newHeight = state.height + delta.height
          if (newHeight !== state.height) {
            const newState = {
              ...state,
              height: newHeight,
            }
            setState(newState)
            onUpdate?.(jobAssignmentUserBlock, newState)
          }
        }
      }}
      position={{ x: jobAssignmentUserBlock.frame.left, y: jobAssignmentUserBlock.frame.top }}
      resizeGrid={timeFrame === TimeFrameOption.WEEK ? [0, intervalLength] : [intervalLength, 0]}
      resizeHandleComponent={{
        right:
          timeFrame === TimeFrameOption.DAY && !jobAssignmentUserBlock.assignment.isLocked ? (
            <div
              style={{
                backgroundColor: "#21212111",
                width: "10px",
                zIndex: 10,
                position: "absolute",
                top: 0,
                bottom: 0,
                cursor: "e-resize",
                right: "5px",
              }}
            />
          ) : null,
        bottom:
          timeFrame === TimeFrameOption.WEEK && !jobAssignmentUserBlock.assignment.isLocked ? (
            <div
              style={{
                backgroundColor: "#21212111",
                height: "10px",
                zIndex: 10,
                position: "absolute",
                cursor: "s-resize",
                bottom: "5px",
                left: 0,
                right: 0,
              }}
            />
          ) : null,
      }}
      size={{
        width: state.width - 1,
        height: state.height,
      }}
      style={{
        backgroundColor: state.isDragging ? "#C8D200" : "transparent",
      }}
    >
      <div
        key={jobAssignmentUserBlock.key}
        onDoubleClick={() => {
          onDoubleClick?.(jobAssignmentUserBlock)
        }}
        onMouseDown={() => {
          const newState = {
            ...state,
            isDragging: true,
          }
          setState(newState)
          onMouseDown?.(jobAssignmentUserBlock)
        }}
        onMouseUp={() => {
          const newState = {
            ...state,
            isDragging: false,
          }
          setState(newState)
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: rootBackgroundColor,
          borderLeft: timeFrame === TimeFrameOption.DAY ? `4px solid ${rootBorderColor}` : "none",
          borderTop: timeFrame === TimeFrameOption.WEEK ? `4px solid ${rootBorderColor}` : "none",
          backgroundImage:
            status === JobAssignmentStatus.TENTATIVE
              ? "repeating-linear-gradient(-45deg, transparent, transparent 6px, rgba(255,255,255,.4) 6px, rgba(255,255,255,.4) 12px)"
              : "none",
          paddingTop: "3px",
          paddingBottom: "3px",
          marginBottom: "1px",
          color: rootTextColor,
          lineHeight: 1,
          whiteSpace: "nowrap",
          boxSizing: "border-box",
          minWidth: 0,
          height: "100%",
          cursor: jobAssignmentUserBlock.assignment.isLocked
            ? "arrow"
            : state.isDragging
              ? "grabbing"
              : "grab",
        }}
      >
        <div
          style={{
            marginLeft: "10px",
            userSelect: "none",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            boxSizing: "border-box",
            width: "100%",
            fontFamily: "sans-serif",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                color: rootTextColor,
                fontWeight: "600",
                fontSize: "0.75rem",
                marginBottom: "2px",
                overflow: "hidden",
                textOverflow: "clip",
                whiteSpace: "nowrap",
                boxSizing: "border-box",
              }}
            >
              {formatDate(jobAssignmentUserBlock.assignment.startDate, "LT", timeZone)}
              {jobAssignmentUserBlock.assignment.isLocked ? (
                <LockOutlinedIcon
                  sx={{
                    color: rootTextColor,
                    fontSize: "12px",
                    marginLeft: "6px",
                    position: "relative",
                    top: "1px",
                  }}
                />
              ) : null}
            </div>
            <div
              style={{
                fontSize: "0.75rem",
                marginRight: timeFrame === TimeFrameOption.DAY ? "12px" : "8px",
              }}
            >
              {jobAssignmentUserBlock.frame.width > 60 && t("job")} #
              {jobAssignmentUserBlock.assignment.job.number}
            </div>
          </div>
          <div
            style={{
              color: rootTextColor,
              fontWeight: "500",
              fontSize: "0.75rem",
              userSelect: "none",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              boxSizing: "border-box",
              width: "90%",
              ...getAdjustedTextStyle(jobAssignmentUserBlock.frame, timeFrame),
            }}
          >
            {jobAssignmentUserBlock.assignment.job.address.locality} -{" "}
            {jobAssignmentUserBlock.assignment.job.customer.name}
          </div>
          <div
            style={{
              color: rootTextColor,
              fontWeight: "500",
              fontSize: "0.625rem",
              userSelect: "none",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              boxSizing: "border-box",
              width: "90%",
              ...getAdjustedTextStyle(jobAssignmentUserBlock.frame, timeFrame),
            }}
          >
            {jobAssignmentUserBlock.assignment.job.description}
          </div>
        </div>
      </div>
    </Rnd>
  )
}

export default React.memo(JobAssignment, (prevProps, nextProps) => {
  return (
    prevProps.jobAssignmentUserBlock.key === nextProps.jobAssignmentUserBlock.key &&
    prevProps.jobAssignmentUserBlock.assignment.isLocked ===
      nextProps.jobAssignmentUserBlock.assignment.isLocked &&
    prevProps.jobAssignmentUserBlock.frame.left === nextProps.jobAssignmentUserBlock.frame.left &&
    prevProps.jobAssignmentUserBlock.frame.top === nextProps.jobAssignmentUserBlock.frame.top &&
    prevProps.jobAssignmentUserBlock.frame.width === nextProps.jobAssignmentUserBlock.frame.width &&
    prevProps.jobAssignmentUserBlock.frame.height ===
      nextProps.jobAssignmentUserBlock.frame.height &&
    prevProps.onDoubleClick === nextProps.onDoubleClick &&
    prevProps.onMouseDown === nextProps.onMouseDown &&
    prevProps.onDrag === nextProps.onDrag &&
    prevProps.onResize === nextProps.onResize &&
    prevProps.onUpdate === nextProps.onUpdate
  )
})
