import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { MainLayout, PageHeader, Seo } from "../components"
import { CALENDAR } from "../util"

function CalendarPage() {
  const { t } = useTranslation()

  return (
    <>
      <Seo title={t("sectionTitle.calendar")} />
      <MainLayout activeSection={CALENDAR}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader icon={CALENDAR.icon} leafTitleKey={CALENDAR.titleKey} />
          This is the Calendar. It will be helpful to show staff schedules and other important
          events.
        </Box>
      </MainLayout>
    </>
  )
}

export default CalendarPage
