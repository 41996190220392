import gql from "graphql-tag"

export default gql`
  fragment FullItem on Item {
    id
    code
    name
    description
    isArchived
    isIntendedForResale
    isCancelable
    type
    stockType
    category
    msrp
    wholesalePrice
    wholesalePriceExpirationDate
    leadTimeDays
    palletQty
    packSize
    isPackBreakable
    region {
      id
      name
      code
      currencyCode
    }
    attachments {
      id
      contentType
      name
      signedUrl
      createdAt
    }
    isRestricted
    restrictedTo {
      id
      name      
    }
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    updatedAt
    updatedBy {
      id
      firstName
      lastName
    }
  }
`
