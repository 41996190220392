import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"

export const EDIT_JOB = gql`
  mutation EditJob(
    $id: ID!
    $workflowId: ID
    $workflowStepId: ID
    $existingCustomerId: ID
    $newCustomerName: String
    $newCustomerType: CustomerType
    $description: String
    $categoryIDs: [ID!]
    $desiredCompletionDate: LocalDateTime
    $address: AddressInput
    $siteContact: ContactInput
    $boardPosition: BigDecimal
  ) {
    editJob(
      input: {
        id: $id
        workflowId: $workflowId
        workflowStepId: $workflowStepId
        existingCustomerId: $existingCustomerId
        newCustomerName: $newCustomerName
        newCustomerType: $newCustomerType
        description: $description
        categoryIDs: $categoryIDs
        desiredCompletionDate: $desiredCompletionDate
        address: $address
        siteContact: $siteContact
        boardPosition: $boardPosition
      }
    ) {
      events {
        id
        displayKey
        name
        description
      }
      job {
        id
        number
        boardPosition
        desiredCompletionDate
        customer {
          id
          name
        }
        categories {
          id
          name
        }
        workflow {
          id
          name
        }
        workflowStep {
          id
          jobStatus {
            id
            name
          }
        }
        description
        address {
          ...FullAddress
        }
        siteContact {
          id
          firstName
          lastName
          phoneNumber
          address {
            addressString
            latitude
            longitude
          }
          email
        }
      }
    }
  }
  ${FullAddress}
`

