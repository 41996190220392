import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"
import TaxRateGroupDetails from "./fragments/taxRateGroupDetails"
import FullWorkOrderLineItemDetails from "./fragments/FullWorkOrderLineItemDetails"

export const ALL_WORK_ORDERS_FOR_JOB = gql`
  query AllWorkOrdersForJob($jobId: ID!, $includeArchived: Boolean) {
    getJobById(id: $jobId) {
      id
      number
      workOrders(filter: { includeArchived: $includeArchived }) {
        id
        number
        currencyCode
        description
        notes
        subTotal
        total
        discount {
          value
          type
        }
        billingContact {
          id
          firstName
          lastName
          email
          phoneNumber
          isArchived
          address {
            ...FullAddress
          }
        }
        job {
          id
          number
          customer {
            id
            name
            type
          }
          address {
            ...FullAddress
          }
        }
        jobAssignment {
          id
          assignees {
            id
            firstName
            lastName
          }
          status
          startDate
          endDate
        }
        lineItems {
          id
          number
          organizationItem {
            id
            code
            name
            type
          }
          description
          showDetails
          lineItemDetails {
            ...FullWorkOrderLineItemDetails
          }
          quantity
          unitPrice
          taxRateGroup {
            ...TaxRateGroupDetails
          }
          total
        }
        isArchived
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
  ${FullAddress}
  ${TaxRateGroupDetails}
  ${FullWorkOrderLineItemDetails}
`
