import gql from "graphql-tag"

export const EDIT_CUSTOMER_FLAG = gql`
  mutation EditCustomerFlag($id: ID!, $name: String!, $description: String, $colorCode: String) {
    editCustomerFlag(input: { id: $id, name: $name, description: $description, colorCode: $colorCode }) {
      customerFlag {
        id
        name
        description
        colorCode
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`
