import gql from "graphql-tag"

export const SYNC_SUBSCRIPTION = gql`
  mutation SyncSubscription($subscriptionId: String!) {
    syncSubscription(input: { subscriptionId: $subscriptionId }) {
      fielderSubscription {
        id
        status
        taxPercent
        latestInvoice {
          id
          paymentIntent {
            id
            status
            clientSecret
          }
        }
        organization {
          id
          tradeName
          legalName
          email
          status
        }
      }
    }
  }
`
