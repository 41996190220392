import gql from "graphql-tag"
import TaxRateGroupDetails from "./fragments/taxRateGroupDetails"
import FullInvoiceLineItemDetails from "./fragments/FullInvoiceLineItemDetails"

export const GET_INVOICE_BY_ID = gql`
  query GetInvoiceById($id: ID!) {
    getInvoiceById(id: $id) {
      id
      number
      status
      description
      issuedDate
      netTerms
      dueDate
      currencyCode
      billingContact {
        id
        firstName
        lastName
        email
        phoneNumber
        address {
          addressString
        }
      }
      job {
        id
        number
        description
        address {
          addressString
        }
        customer {
          id
          name
          type
        }
        organization {
          id
          tradeName
          address {
            addressString
          }
          email
          phoneNumber
          websiteURL
          timeZone
        }
      }
      lineItems {
        id
        number
        organizationItem {
          id
          code
          name
          type
        }
        description
        quantity
        unitPrice
        taxRateGroup {
          ...TaxRateGroupDetails
        }
        total
        showDetails
        lineItemDetails {
          ...FullInvoiceLineItemDetails
        }
      }
      subTotal
      discount {
        value
        type
      }
      taxSummary {
        total
        taxRateAmounts {
          amount
          taxRate {
            id
            name
            rate
          }
        }
      }
      total
      customerVisibility {
        showDueDate
        showLineItems
        showLineItemQuantity
        showLineItemUnitPrice
        showLineItemSubtotal
        showLineItemCode
        showLineItemName
        showLineItemDescription
        showLineItemBundleComponents
        showLineItemBundleComponentUnitPrice
        showLineItemBundleComponentQuantity
        showFooter
        showFooterSubtotal
        showFooterDiscount
        showFooterTaxes
        showFooterTotal
      }
      createdAt
      createdBy {
        id
      }
      updatedAt
      updatedBy {
        id
      }
    }
  }
  ${TaxRateGroupDetails}
  ${FullInvoiceLineItemDetails}
`