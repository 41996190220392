import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import RestoreIcon from "@mui/icons-material/RestoreFromTrashOutlined"
import type { User } from "../../../../types"
import { DefaultPermission, UserStatus } from "../../../../types"
import { useAuth } from "../../../../context/AuthContext"
import StaticField from "../../../../components/StaticField"
import { NOT_SPECIFIED } from "../../../../util/stringUtils"
import { createDayJS, formatPhoneNumber } from "../../../../util"

interface Props {
  readonly userObject: User
  readonly onDeactivate?: () => void
  readonly onReactivate?: () => void
}

function UserStatic({ userObject, onDeactivate, onReactivate }: Props) {
  const { t } = useTranslation()
  const { hasPermissions, user: currentUser } = useAuth()

  const lastLogin = userObject?.lastLogin
    ? createDayJS(userObject.lastLogin, currentUser?.organization?.timeZone, currentUser)
    : null

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "0.25rem",
        }}
      >
        <Box
          sx={{
            marginTop: "1rem",
            marginBottom: "0.5rem",
            fontSize: "1.0rem",
          }}
        >
          <Box sx={{ fontWeight: "bold" }}>{t("email")}</Box>
          <Box>{userObject.email}</Box>
        </Box>
        <Box
          sx={{
            marginTop: "1rem",
            marginBottom: "0.5rem",
            marginLeft: "0.75rem",
            fontSize: "1.0rem",
            textAlign: "right",
          }}
        >
          <Box sx={{ fontWeight: "bold" }}>{t("lastLogin")}</Box>
          <Box>{lastLogin?.format("llll")}</Box>
        </Box>
      </Box>
      <Divider />
      <StaticField
        label={t("firstName")}
        showBottomDivider
        value={
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Box>{userObject.firstName}</Box>
          </Box>
        }
      />
      <StaticField
        label={t("lastName")}
        showBottomDivider
        value={
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Box>{userObject.lastName}</Box>
          </Box>
        }
      />
      <StaticField
        label={t("securityRoles")}
        showBottomDivider
        value={
          <Box>
            {userObject.roles?.map((role) => (
              <Box key={role.id}>
                {role.isStandard ? t(`standardRoles.${role.name}.name`) : role.name}
              </Box>
            ))}
          </Box>
        }
      />
      <StaticField
        label={t("accountStatus")}
        showBottomDivider
        value={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box>{t(`userStatus.${userObject.status}`)}</Box>
            {hasPermissions?.([DefaultPermission.UpdateUser]) ? (
              <Box sx={{ position: "absolute", top: "-12px", right: 0 }}>
                {userObject?.status === UserStatus.DEACTIVATED ? (
                  <Button
                    onClick={onReactivate}
                    size="small"
                    startIcon={<RestoreIcon />}
                    sx={(theme) => ({
                      [theme.breakpoints.down("xs")]: {
                        marginLeft: 0,
                      },
                    })}
                    variant="outlined"
                  >
                    {t("reactivateUser")}
                  </Button>
                ) : (
                  <Button
                    onClick={onDeactivate}
                    size="small"
                    startIcon={<DeleteIcon />}
                    sx={(theme) => ({
                      color:
                        userObject.status === UserStatus.UNVERIFIED
                          ? theme.fielderColors.error
                          : theme.fielderColors.text,
                      borderColor:
                        userObject.status === UserStatus.UNVERIFIED
                          ? theme.fielderColors.error
                          : theme.fielderColors.text,
                      "&:hover": {
                        borderColor:
                          userObject.status === UserStatus.UNVERIFIED
                            ? theme.fielderColors.error
                            : theme.fielderColors.text,
                        backgroundColor:
                          userObject.status === UserStatus.UNVERIFIED
                            ? "hsla(360, 100%, 98%, 1)"
                            : theme.fielderColors.white,
                      },
                      [theme.breakpoints.down("xs")]: {
                        marginLeft: 0,
                      },
                    })}
                    variant="outlined"
                  >
                    {userObject.status === UserStatus.UNVERIFIED
                      ? t("deleteUser")
                      : t("deactivateUser")}
                  </Button>
                )}
              </Box>
            ) : null}
          </Box>
        }
      />
      <StaticField
        label={t("jobTitle")}
        showBottomDivider
        value={userObject.jobTitle ?? NOT_SPECIFIED}
      />
      <StaticField
        label={t("mobilePhone")}
        showBottomDivider
        value={
          userObject.mobilePhoneNumber
            ? formatPhoneNumber(userObject.mobilePhoneNumber)
            : NOT_SPECIFIED
        }
      />
      <StaticField
        label={t("language")}
        showBottomDivider={false}
        value={
          userObject.languageCode
            ? t(`languageCodeOptions.${userObject.languageCode}`)
            : userObject.organization?.languageCode
              ? t(`languageCodeOptions.${userObject.organization?.languageCode}`)
              : NOT_SPECIFIED
        }
      />
    </Box>
  )
}

export default UserStatic
