import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import SortOutlinedIcon from "@mui/icons-material/SortOutlined"
import { ALL_JOBS } from "~/queries/allJobs"
import useStore, {
  jobStatusSelectorValueSelector,
  setJobStatusSelectorValueSelector,
} from "~/store"
import useDebounce from "~/util/useDebounce"
import JobStatusSelect from "../JobStatusSelect"
import TablePaginationActions from "../TablePaginationActions"
import JobSelectorResult from "./JobSelectorResult"
import { Job } from "~/types"

const PAGE_SIZE = 10

interface JobSelectorProps {
  readonly onJobDragStart: (job: Job, initialPosition: { x: number; y: number }) => void
}

function JobSelector({ onJobDragStart }: JobSelectorProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const jobStatusSelectorValue = useStore(jobStatusSelectorValueSelector)
  const setJobStatusSelectorValue = useStore(setJobStatusSelectorValueSelector)

  const statusOptionALL = { id: "ALL", name: t("component.jobSelector.allJobs") }

  const [selectedStatus, setSelectedStatus] = useState(() => {
    const currentJobStatus = jobStatusSelectorValue
    const currentlySelectedJobStatus = currentJobStatus || statusOptionALL
    return currentlySelectedJobStatus
  })
  const [filter, setFilter] = useState<string>("")
  const [after, setAfter] = useState<number | null>()
  const [before, setBefore] = useState<number | null>()
  const [first, setFirst] = useState<number | null>(10)
  const [last, setLast] = useState<number | null>()
  const [sortDir, setSortDir] = useState<"DESC" | "ASC">("ASC")
  const debouncedSearchTerm = useDebounce(filter, 500)

  const { loading, error, data } = useQuery(ALL_JOBS, {
    variables: {
      filter: debouncedSearchTerm,
      after,
      before,
      first,
      last,
      sortBy: "number",
      sortDir: sortDir,
      jobStatusIds: selectedStatus.id === "ALL" ? [] : [selectedStatus.id],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    context: {
      debounceKey: "ALL_JOBS",
      debounceTimeout: 50,
    },
  })

  function onGoToFirstPage() {
    setAfter(null)
    setBefore(null)
    setFirst(PAGE_SIZE)
    setLast(null)
  }

  function onGoToLastPage() {
    setAfter(null)
    setBefore(null)
    setFirst(null)
    setLast(PAGE_SIZE)
  }

  function onGoToPrevious() {
    setAfter(null)
    setBefore(pageInfo.startCursor)
    setFirst(null)
    setLast(PAGE_SIZE)
  }

  function onGoToNext() {
    setAfter(pageInfo.endCursor)
    setBefore(null)
    setFirst(PAGE_SIZE)
    setLast(null)
  }

  const handleSearchChange = (val) => {
    setFilter(val)
    setAfter(null)
    setBefore(null)
    setFirst(PAGE_SIZE)
    setLast(null)
  }

  const pageInfo = data?.allJobs?.pageInfo || {}
  const jobs = data?.allJobs?.edges?.filter((edge) =>
    selectedStatus && selectedStatus.id !== "ALL"
      ? edge.node.workflowStep.jobStatus.id === selectedStatus.id
      : true
  )

  return (
    <Box
      sx={{
        paddingTop: "0.25rem",
        paddingBottom: 0,
        minWidth: "300px",
        flexShrink: 1,
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 200px)",
        fontSize: "0.875rem",
      }}
    >
      <Box sx={classes.formContainer}>
        <Box sx={classes.titleContainer}>
          <Box
            sx={(theme) => ({
              color: theme.fielderColors.mutedText,
              fontWeight: 600,
              fontSize: "1.3em",
              marginLeft: "1.25rem",
            })}
          >
            {t("jobs")}
          </Box>
          <IconButton
            aria-label="sort"
            onClick={() => setSortDir(sortDir === "ASC" ? "DESC" : "ASC")}
          >
            <SortOutlinedIcon />
          </IconButton>
        </Box>
        <Box sx={classes.controls}>
          <JobStatusSelect
            extraOptions={[statusOptionALL]}
            name="jobStatus"
            onChange={(v) => {
              setJobStatusSelectorValue(v) //save it to local storage so it can be defaulted later
              setSelectedStatus(v)
              setFirst(PAGE_SIZE)
              setLast(null)
              setAfter(null)
              setBefore(null)
            }}
            selectedOption={selectedStatus}
            size="small"
            variant="outlined"
          />
          <TextField
            onChange={(e) => handleSearchChange(e.target.value)}
            onFocus={(e) => e.target.select()}
            placeholder={t("searchJobs")}
            size="small"
            sx={classes.searchTextField}
            value={filter}
            variant="outlined"
          />
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          minHeight: 0,
          display: "flex",
          color: (theme) => theme.fielderColors.mutedText,
        }}
      >
        <Box sx={classes.jobListOverflowContainer}>
          <Box sx={classes.jobListOverflowContent}>
            {!error && !loading && jobs?.length === 0 && (
              <Box sx={classes.noResults}>
                <strong>{t("component.jobSelector.noResultsLabel")}</strong>
                <p>{t("component.jobSelector.noResultsDescription")}</p>
              </Box>
            )}
            {!error && loading ? (
              <Box
                sx={{
                  marginTop: "50px",
                  marginBottom: "50px",
                  alignSelf: "center",
                }}
              >
                <CircularProgress color="secondary" size={20} thickness={6.0} />
              </Box>
            ) : null}
            {!error &&
              !loading &&
              jobs?.length > 0 &&
              jobs?.map((edge) => {
                const job = edge.node
                return (
                  <Box
                    key={job.id}
                    onMouseDown={(e) => {
                      e.stopPropagation()
                      if (e.altKey || e.ctrlKey) {
                        navigate(`/app/jobs/edit/${job.id}`)
                      } else if (onJobDragStart) {
                        const { clientX, clientY } = e
                        onJobDragStart(job, {
                          x: clientX - 5,
                          y: clientY - 5,
                        })
                      }
                    }}
                    sx={(theme) => ({
                      width: "100%",
                      borderTop: `1px solid ${theme.fielderColors.mutedText}`,
                      "&:last-of-type": {
                        borderBottom: `1px solid ${theme.fielderColors.mutedText}`,
                      },
                    })}
                  >
                    <JobSelectorResult job={job} />
                  </Box>
                )
              })}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textDecoration: "underline",
          fontSize: "1.125rem",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        <TablePaginationActions
          hasNextPage={pageInfo.hasNextPage ?? false}
          hasPreviousPage={pageInfo.hasPreviousPage ?? false}
          onGoToFirstPage={onGoToFirstPage}
          onGoToLastPage={onGoToLastPage}
          onGoToNext={onGoToNext}
          onGoToPrevious={onGoToPrevious}
        />
      </Box>
    </Box>
  )
}

const classes = {
  formContainer: {
    borderBottom: `1px solid #cccccc`,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "1.25rem",
  },
  controls: {
    display: "flex",
    flexDirection: "column",
    padding: "1.25rem",
  },
  searchTextField: {
    marginTop: "0.625rem",
  },
  jobListOverflowContainer: {
    flexGrow: 1,
    overflowY: "auto",
  },
  jobListOverflowContent: {
    display: "flex",
    flexDirection: "column",
  },
  noResults: {
    alignSelf: "center",
    padding: "1.25rem",
  },
}

export default JobSelector
