import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import isNil from "lodash/isNil"
import { gql, useQuery, useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Popper from "@mui/material/Popper"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Divider from "@mui/material/Divider"
import Fade from "@mui/material/Fade"
import Paper from "@mui/material/Paper"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import IconButton from "@mui/material/IconButton"
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined"
import { useAuth } from "../context/AuthContext"
import { MessageChannel, GetUserNotificationsData } from "../types"
import { GET_USER_NOTIFICATIONS } from "../queries/getUserNotifications"
import { MARK_NOTIFICATIONS_AS_READ } from "../queries/markNotificationsAsRead"
import NotificationRow from "./NotificationRow"
import EmptyInbox from "./icons/EmptyInbox"
import useStore, {
  setUnreadNotificationCountSelector,
  unreadNotificationCountSelector,
  onlyShowUnreadNotificationsToggleSelector,
  setOnlyShowUnreadNotificationsToggleSelector,
} from "../store"
import StyledBadge from "./StyledBadge"

const GET_UNREAD_NOTIFICATION_COUNT = gql`
  query GetUnreadNotificationCount($id: ID!, $channel: MessageChannel) {
    getUserById(id: $id) {
      id
      unreadNotificationCount(channel: $channel)
    }
  }
`

function NotificationsButton() {
  const navigate = useNavigate()
  const { user } = useAuth()
  const setUnreadNotificationCount = useStore(setUnreadNotificationCountSelector)
  const unreadNotificationCount = useStore(unreadNotificationCountSelector)
  const onlyShowUnreadNotifications = useStore(onlyShowUnreadNotificationsToggleSelector)
  const setOnlyShowUnreadNotifications = useStore(setOnlyShowUnreadNotificationsToggleSelector)
  const { t } = useTranslation("common")
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<HTMLElement | null>(null)
  const openNotifications = Boolean(notificationsAnchorEl)

  const { refetch: refetchUnreadNotificationCount } = useQuery<GetUserNotificationsData>(
    GET_UNREAD_NOTIFICATION_COUNT,
    {
      variables: {
        id: user?.id,
        channel: MessageChannel.INBOX,
      },
      skip: !user?.id,
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setUnreadNotificationCount(data.getUserById?.unreadNotificationCount ?? 0)
      },
    }
  )

  const { data } = useQuery<GetUserNotificationsData>(GET_USER_NOTIFICATIONS, {
    variables: {
      id: user?.id,
      first: 20,
      channel: MessageChannel.INBOX,
    },
    skip: !user?.id,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setUnreadNotificationCount(data.getUserById?.unreadNotificationCount ?? 0)
    },
  })

  const [markNotificationsAsRead] = useMutation(MARK_NOTIFICATIONS_AS_READ, {
    onCompleted: () => {
      refetchUnreadNotificationCount() // reload the unreadNotificationCount
    },
    onError: (error) => {
      console.error({ error })
    },
  })

  const allNotifications = data?.getUserById?.notifications?.edges?.map((e) => e.node) ?? []
  const unreadNotifications = allNotifications?.filter((n) => isNil(n.dateRead))
  const displayedNotifications = onlyShowUnreadNotifications
    ? unreadNotifications
    : allNotifications

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickNotificationsButton = (event: any) => {
    setNotificationsAnchorEl(event.currentTarget)
  }

  const handleCloseNotifications = () => {
    setNotificationsAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-label={t("sectionTitle.notifications") as string}
        onClick={handleClickNotificationsButton}
      >
        <StyledBadge invisible={unreadNotificationCount === 0} variant="dot">
          <NotificationsIcon />
        </StyledBadge>
      </IconButton>
      <Popper
        anchorEl={notificationsAnchorEl}
        id="notifications-popper"
        open={openNotifications}
        placement="bottom-end"
        sx={{ zIndex: 1200, fontSize: "1rem" }}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleCloseNotifications}>
            <Fade {...TransitionProps} timeout={150}>
              <Paper
                sx={{
                  border: "none",
                  borderRadius: "4px",
                  bgcolor: "background.paper",
                  width: "30rem",
                  maxWidth: "400px",
                }}
              >
                <Box
                  id="notifications-popover-header"
                  sx={{
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ fontWeight: "600" }}>{t("notifications")}</Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={onlyShowUnreadNotifications}
                        color="success"
                        onChange={(event) => setOnlyShowUnreadNotifications(event.target.checked)}
                        size="small"
                      />
                    }
                    label={
                      <Box sx={{ fontSize: "0.875rem" }}>
                        {t("component.notificationsButton.onlyShowUnread")}
                      </Box>
                    }
                    labelPlacement="start"
                    value={onlyShowUnreadNotifications}
                  />
                </Box>
                <Divider />
                <Box
                  id="notifications-popover-body"
                  sx={{
                    height: "25rem",
                    maxHeight: "400px",
                    padding: "0.5rem 0",
                    overflowY: "scroll",
                  }}
                >
                  {displayedNotifications.length === 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        gap: "2rem",
                      }}
                    >
                      <EmptyInbox width="50%" />
                      <Box>{t("component.notificationsButton.noNotifications")}</Box>
                    </Box>
                  ) : (
                    displayedNotifications?.map((n) => {
                      return (
                        <NotificationRow
                          key={n.id}
                          notification={n}
                          onClick={() => {
                            if (!n.dateRead) {
                              markNotificationsAsRead({
                                variables: {
                                  ids: [n.id],
                                },
                              })
                            }

                            handleCloseNotifications()

                            if (n.callToActionUri) {
                              navigate(n.callToActionUri)
                            }
                          }}
                        />
                      )
                    })
                  )}
                </Box>
                <Divider />
                <Box
                  id="notifications-popover-footer"
                  sx={{
                    padding: "1rem 1.5rem",
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "0.875rem",
                  }}
                >
                  <Box
                    onClick={() => {
                      navigate(`/app/settings/profile`)
                      setNotificationsAnchorEl(null)
                    }}
                    sx={{
                      cursor: "pointer",
                      color: "#3B86FF",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {t("component.notificationsButton.changeNotificationPreferences")}
                  </Box>
                  {unreadNotifications.length > 0 ? (
                    <Box
                      onClick={() => {
                        if (unreadNotifications?.length > 0) {
                          const variables = {
                            ids: unreadNotifications.map((n) => n.id),
                          }
                          markNotificationsAsRead({ variables })
                        }
                      }}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: (theme) => theme.fielderColors.mutedText,
                        "&:hover": {
                          color: (theme) => theme.fielderColors.black,
                        },
                      }}
                    >
                      {t("component.notificationsButton.markAllAsRead")}
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}

export default NotificationsButton
