import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import SelectorField from "../../../components/SelectorField"
import FullEstimateTemplate from "../../../queries/fragments/estimateTemplate"
import { useAuth } from "../../../context/AuthContext"
import { EstimateTemplate } from "../../../types"

const ALL_ESTIMATE_TEMPLATES = gql`
  query GetOrganizationById($id: ID!) {
    getOrganizationById(id: $id) {
      id
      estimateTemplates {
        ...FullEstimateTemplate
      }
    }
  }
  ${FullEstimateTemplate}
`

interface Props {
  readonly onCancel: () => void
  readonly onSelectTemplate: (template: EstimateTemplate) => void
}

function LoadEstimateFromTemplate({ onCancel, onSelectTemplate }: Props) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [selectedTemplate, setSelectedTemplate] = useState<EstimateTemplate | null>(null)

  const { data: allEstimateTemplatesData, loading } = useQuery(ALL_ESTIMATE_TEMPLATES, {
    fetchPolicy: "cache-and-network",
    skip: !user?.organization?.id,
    variables: {
      id: user?.organization?.id,
    },
    onCompleted: (data) => {
      if (!selectedTemplate && data?.getOrganizationById?.estimateTemplates?.length >= 1) {
        setSelectedTemplate(data.getOrganizationById.estimateTemplates[0])
      }
    },
  })

  const templates = allEstimateTemplatesData?.getOrganizationById?.estimateTemplates || []

  function handleSelect() {
    selectedTemplate && onSelectTemplate(selectedTemplate)
  }

  return (
    <Dialog
      aria-labelledby="load-estimate-from-template-dialog"
      data-testid="LoadEstimateFromTemplate"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open
    >
      <DialogTitle
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {t("component.loadEstimateFromTemplateDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: dialogBackgroundColor,
            paddingBottom: 0,
            paddingTop: "1.5rem",
          }}
        >
          <Box
            sx={{
              color: (theme) => theme.fielderColors.mutedText,
              marginTop: "0.625rem",
              marginBottom: "0.625rem",
            }}
          >
            {t("component.loadEstimateFromTemplateDialog.description")}
          </Box>
          <Box
            sx={{
              marginTop: "1.25rem",
            }}
          >
            {templates.length === 0 ? (
              <Box
                sx={(theme) => ({
                  color: theme.fielderColors.mutedText,
                  border: `1px solid ${theme.fielderColors.mutedText}`,
                  borderRadius: "4px",
                  padding: "0.625rem",
                  backgroundColor: "#fff",
                })}
              >
                {t("component.loadEstimateFromTemplateDialog.noTemplates")}
              </Box>
            ) : (
              <SelectorField
                label={t("selectTemplate") as string}
                name="template"
                onChange={(selection) => {
                  const template = templates?.find((t) => t.id === selection.id)
                  setSelectedTemplate(template)
                }}
                options={templates}
                value={selectedTemplate?.id ?? ""}
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          marginTop: "1rem",
          px: "1.5rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          data-testid="loadButton"
          disabled={loading || !selectedTemplate}
          onClick={handleSelect}
          variant="contained"
        >
          {loading ? (
            <CircularProgress size={20} thickness={6.0} />
          ) : (
            <Box>{t("loadFromTemplate")}</Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const dialogBackgroundColor = "#FFFFFF"

export default LoadEstimateFromTemplate
