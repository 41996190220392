import React from "react"
import Box from "@mui/material/Box"
import { useTranslation } from "react-i18next"
import { MainLayout, Seo } from "../components"
import { NULL_SECTION } from "../util"

function NotFoundPage() {
  const { t } = useTranslation("common", { useSuspense: typeof window !== "undefined" })

  return (
    <MainLayout activeSection={NULL_SECTION}>
      <Seo title="404" />
      <Box sx={{ margin: "1.25rem" }}>
        <h1>{t("error.routeNotFound.title", "Not Found")}</h1>
        <p>
          {t(
            "error.routeNotFound.message",
            "You just tried to access a page that doesn&#39;t exist. Please make sure you've entered the correct URL in your browser's address bar"
          )}
        </p>
      </Box>
    </MainLayout>
  )
}

export default NotFoundPage
