import React, { useCallback, useState } from "react"
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import FormControl from "@mui/material/FormControl"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import FielderTextField from "../../../../../components/FielderTextField"
import FieldHelperText from "../../../../../components/FieldHelperText"
import LineItem, { BeautifulDndRowStyle, RowStyle } from "../../../../../components/LineItem"
import TransactionFooter from "../../../../../components/TransactionFooter"
import {
  asFloat,
  asInt,
  calculateLineSubtotal,
  formatDiscountValueForInput,
  isBlank,
  updateTransactionLineItem,
  createTransactionLineItemFormInput,
} from "../../../../../util"
import {
  CreateEstimateTemplateInput,
  EstimateTemplate,
  EstimateTemplateLineItemDetail,
  EstimateTemplateLineItemInput,
  Snack,
  TaxRateGroup,
  TransactionLineItemDetailFormInput,
  TransactionLineItemFormInput,
} from "../../../../../types"
import { usePrompt } from "../../../../../hooks/usePrompt"
import SnackbarMessage from "../../../../../components/SnackbarMessage"

function getListStyle(isDraggingOver: boolean) {
  return {
    background: isDraggingOver ? "#EFEFEF" : "#FFFFFF",
  }
}

function getRowStyle(isDragging: boolean, draggableStyle: BeautifulDndRowStyle): RowStyle {
  // some basic styles to make the items look a bit nicer
  return {
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "#F1F8FF" : "#FFFFFF",

    // styles we need to apply on draggables
    ...draggableStyle,
  }
}

function handleFocus(e) {
  e.target.select()
}

interface Props {
  readonly currencyCode: string
  readonly template?: EstimateTemplate
  readonly isSaving: boolean
  readonly onSave: (payload: CreateEstimateTemplateInput) => void
  readonly taxRateGroupOptions: TaxRateGroup[]
  readonly taxRateGroupOptionsLoading: boolean
}

function EstimateTemplateForm({
  currencyCode,
  template,
  isSaving,
  onSave,
  taxRateGroupOptions,
  taxRateGroupOptionsLoading,
}: Props) {
  const { t } = useTranslation()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [snack, setSnack] = useState<Snack>()
  const [name, setName] = useState<string>(template?.name ?? "")
  const [description, setDescription] = useState<string>(template?.description ?? "")
  const [notes, setNotes] = useState<string>(template?.notes ?? "")
  const [footerTitle, setFooterTitle] = useState<string>(template?.footerTitle ?? "")
  const [footerBody, setFooterBody] = useState<string>(template?.footerBody ?? "")
  const [discount, setDiscount] = useState<string>(
    template?.discount ? formatDiscountValueForInput(template.discount) : ""
  )
  const [discountType, setDiscountType] = useState<string>(template?.discount?.type ?? "PERCENTAGE")
  const [lineItems, setLineItems] = useState<TransactionLineItemFormInput[]>(() => {
    if (template?.lineItems) {
      return template.lineItems
        .map((li) => {
          return {
            id: li.id,
            number: li.number,
            key: li.id,
            organizationItem: li.organizationItem,
            organizationItemId: li.organizationItem.id,
            description: li.description,
            quantity: String(li.quantity ?? ""),
            unitPrice: String(li.unitPrice ?? ""),
            taxRateGroup: li.taxRateGroup,
            subTotal: calculateLineSubtotal(li.quantity, li.unitPrice),
            total: li.total,
            showDetails: li.showDetails,
            lineItemDetails: li.lineItemDetails?.map(
              (detail: EstimateTemplateLineItemDetail, index: number) => ({
                id: detail.id,
                organizationItem: detail.organizationItem,
                organizationItemId: detail.organizationItem?.id,
                quantity: String(detail.quantity ?? ""),
                unitPrice: String(detail.unitPrice ?? ""),
                number: detail.number > 0 ? detail.number : index + 1,
              })
            ),
            errors: {
              showErrors: false,
            },
          }
        })
        .sort((a, b) => (a.number ?? 0) - (b.number ?? 0))
    } else {
      return [createTransactionLineItemFormInput(1, taxRateGroupOptions?.[0], 1)]
    }
  })
  const [errors, setErrors] = useState<{
    name?: string | null
    footerTitle?: string | null
    footerBody?: string | null
    lineItems?: {
      title?: string | null
      message?: string | null
    } | null
  }>(() => ({
    name: null,
    footerTitle: null,
    footerBody: null,
    lineItems: null,
  }))

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  const addLineItem = useCallback(() => {
    setLineItems(
      lineItems.concat([
        createTransactionLineItemFormInput(lineItems.length + 1, taxRateGroupOptions?.[0], 1),
      ])
    )
    setIsDirty(true)
  }, [lineItems, taxRateGroupOptions])

  const updateLineItem = useCallback(
    (reason: string, payload: any): void => {
      setLineItems(updateTransactionLineItem(reason, payload, lineItems, "ESTIMATE"))
      setIsDirty(true)
    },
    [lineItems]
  )

  const removeLineItem = useCallback(
    (lineItem: TransactionLineItemFormInput): void => {
      const updatedLineItems = lineItems.filter((li) => li.number !== lineItem.number)
      updatedLineItems.forEach((item, idx) => {
        item.number = idx + 1
      })
      setLineItems(updatedLineItems)
      setIsDirty(true)
    },
    [lineItems]
  )

  /**
   * Validate all line items
   * A return value of TRUE means everthing looks good.
   */
  const validateLineItems = useCallback(() => {
    const hasInvalidItem = lineItems.some((li) => {
      return (
        isBlank(li.organizationItemId) ||
        isBlank(`${li.quantity}`) ||
        isBlank(`${li.unitPrice}`) ||
        (asInt(li.quantity) ?? 0) < 0 ||
        asFloat(li.unitPrice) < 0
      )
    })

    if (hasInvalidItem) {
      const validatedLineItems = lineItems.map((li) => {
        return {
          ...li,
          errors: {
            showErrors: true,
            organizationItemId: !li.organizationItemId ? "required" : null,
            quantity:
              isBlank(`${li.quantity}`) || (asInt(li.quantity) ?? 0) < 0 ? "required" : null,
            taxRateGroup: !li.taxRateGroup?.id ? "required" : null,
          },
        }
      })

      setLineItems(validatedLineItems)
    }

    return !hasInvalidItem
  }, [lineItems])

  const handleSave = () => {
    if (!lineItems || lineItems.length === 0) {
      setErrors({
        ...errors,
        lineItems: {
          title: t("component.estimateDialog.lineItems.required.title"),
          message: t("component.estimateDialog.lineItems.required.message"),
        },
      })
      setSnack({
        messageKey: "component.estimateDialog.lineItems.required.message",
        variant: "error",
      })
      return
    }

    if (!validateLineItems()) {
      setErrors({
        ...errors,
        lineItems: {
          title: t("component.estimateDialog.lineItems.invalid.title"),
          message: t("component.estimateDialog.lineItems.invalid.message"),
        },
      })
      setSnack({
        messageKey: "component.estimateDialog.lineItems.invalid.message",
        variant: "error",
      })
      return
    }

    setErrors({})

    const sanitizedLineItems =
      lineItems?.map((li: TransactionLineItemFormInput) => {
        const lineItem = {
          number: li.number,
          organizationItemId: li.organizationItem?.id,
          description: li.description,
          quantity: asInt(li.quantity ?? ""),
          taxRateGroupId: li.taxRateGroup?.id,
          showDetails: li.showDetails,
          lineItemDetails: li.lineItemDetails?.map(
            (d: TransactionLineItemDetailFormInput, index: number) => ({
              id: d.id,
              organizationItemId: d.organizationItemId ?? d.organizationItem?.id,
              quantity: asInt(d.quantity ?? ""),
              number: d.number > 0 ? d.number : index + 1,
            })
          ),
        } as EstimateTemplateLineItemInput
        if (li.id) {
          lineItem.id = li.id
        }
        return lineItem
      }) ?? []

    const payload = {
      name: name.trim().substring(0, 255),
      description: description.trim().substring(0, 1000),
      notes: notes.trim().substring(0, 1000),
      discount: {
        value: asFloat(discount) / (discountType === "PERCENTAGE" ? 100 : 1.0),
        type: discountType,
      },
      lineItems: sanitizedLineItems,
      footerTitle,
      footerBody,
    } as CreateEstimateTemplateInput

    onSave(payload)
    setIsDirty(false)
  }

  const handleRowDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const startIndex = result.source.index
    const endIndex = result.destination.index
    if (startIndex === endIndex) {
      return
    }

    const sourceLineItem = lineItems[startIndex]

    let sortedLines = Array.from(lineItems)
    sortedLines.splice(startIndex, 1)
    sortedLines.splice(endIndex, 0, sourceLineItem)
    sortedLines = sortedLines.map((item, idx) => ({
      ...item,
      number: idx + 1,
    }))

    setLineItems(sortedLines)
    setIsDirty(true)
  }

  const handleTemplateNameBlur = () => {
    if (isBlank(name)) {
      setErrors({
        ...errors,
        name: "page.settings.templates.common.validation.name.required",
      })
    } else if (errors.name) {
      setErrors({
        ...errors,
        name: null,
      })
    }
  }

  const handleTemplateNameChange = (e) => {
    const val = e.target.value
    if (isBlank(val)) {
      setErrors({
        ...errors,
        name: "page.settings.templates.common.validation.name.required",
      })
    } else if (errors.name) {
      setErrors({
        ...errors,
        name: null,
      })
    }
    setName(val)
    setIsDirty(true)
  }

  const handleChangeDescription = (e) => {
    setDescription(e.target.value)
    setIsDirty(true)
  }

  const handleChangeNotes = (e) => {
    setNotes(e.target.value)
    setIsDirty(true)
  }

  const handleChangeFooterTitle = (e) => {
    setFooterTitle(e.target.value)
    setIsDirty(true)
  }

  const handleChangeFooterBody = (e) => {
    setFooterBody(e.target.value)
    setIsDirty(true)
  }

  return (
    <>
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Paper sx={[classes.contentContainer, classes.headerControlsContainer]}>
        <Box sx={classes.nameAndTypeContainer}>
          <Box sx={classes.textFieldContainer}>
            <FielderTextField
              error={!!errors.name}
              fullWidth
              inputProps={{ maxLength: 255 }}
              label={t("templateName")}
              name="name"
              onBlur={handleTemplateNameBlur}
              onChange={handleTemplateNameChange}
              onFocus={handleFocus}
              required
              sx={{ marginTop: 0 }}
              value={name}
            />
            {errors.name ? (
              <FieldHelperText error message={t(errors.name)} />
            ) : (
              <FieldHelperText message={t("page.settings.templates.common.helperText.name")} />
            )}
          </Box>
        </Box>
        <Box sx={[classes.buttonContainer, { marginTop: 0 }]}>
          <Button
            color="primary"
            data-testid="saveTemplateButton"
            disabled={!name || isSaving}
            onClick={handleSave}
            style={{
              fontWeight: "bold",
              minWidth: "135px",
            }}
            variant="contained"
          >
            {isSaving ? (
              <CircularProgress color="secondary" size={20} thickness={6.0} />
            ) : (
              <span>{t("saveTemplate")}</span>
            )}
          </Button>
        </Box>
      </Paper>
      <Paper sx={classes.contentContainer}>
        <Box sx={classes.rowContainer}>
          <Box sx={classes.rowItem}>
            <FormControl fullWidth>
              <FielderTextField
                aria-label={t("description") as string}
                helperText={t("component.estimateDialog.descriptionHelperText") as string}
                id="description"
                inputProps={{ maxLength: 1000 }}
                label={t("description")}
                multiline
                name="description"
                onChange={handleChangeDescription}
                onFocus={handleFocus}
                placeholder={t("component.estimateDialog.descriptionPlaceholder") as string}
                rows={3}
                sx={{ marginTop: 0 }}
                value={description}
              />
            </FormControl>
          </Box>
          <Box sx={classes.rowItem}>
            <FormControl fullWidth>
              <FielderTextField
                aria-label={t("notes") as string}
                helperText={t("component.estimateDialog.notesHelperText") as string}
                id="notes"
                inputProps={{ maxLength: 1000 }}
                label={t("notes")}
                multiline
                name="notes"
                onChange={handleChangeNotes}
                onFocus={handleFocus}
                placeholder={t("component.estimateDialog.notesPlaceholder") as string}
                rows={3}
                sx={{ marginTop: 0 }}
                value={notes}
              />
            </FormControl>
          </Box>
        </Box>
        <Box sx={classes.rowContainer}>
          <Box sx={classes.rowItem}>
            <Paper elevation={0} sx={classes.lineItemTableContainer}>
              <DragDropContext onDragEnd={handleRowDragEnd}>
                <Box data-testid="lineItemsGrid">
                  <Box sx={[classes.lineItemGridRow, classes.lineItemHeader]}>
                    <div />
                    <label>{t("item")}</label>
                    <label>{t("description")}</label>
                    <label>{t("quantity")}</label>
                    <label
                      style={{ justifySelf: "end", textAlign: "right", paddingRight: "0.5rem" }}
                    >
                      {t("unitPrice")}
                    </label>
                    <label
                      style={{
                        justifySelf: "end",
                        paddingRight: "0.5rem",
                        textAlign: "right",
                      }}
                    >
                      {t("lineSubtotal")}
                    </label>
                    <label>{t("taxRate")}</label>
                    <div />
                  </Box>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {lineItems.map((li, index) => {
                          return (
                            <Draggable draggableId={li.key} index={index} key={li.key}>
                              {(provided, snapshot) => (
                                <LineItem
                                  css={classes.lineItemGridRow}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  currencyCode={currencyCode}
                                  lineItem={li}
                                  onChange={updateLineItem}
                                  onDelete={removeLineItem}
                                  style={getRowStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  taxRateGroupOptions={taxRateGroupOptions}
                                  taxRateGroupsOptionsLoading={taxRateGroupOptionsLoading}
                                  variant="ESTIMATE_TEMPLATE"
                                />
                              )}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Box sx={classes.addLineCell}>
                    <Button
                      data-testid="addLineItemButton"
                      fullWidth
                      onClick={addLineItem}
                      sx={classes.addLineButton}
                      variant="outlined"
                    >
                      {`+ ${t("addALine")}`}
                    </Button>
                  </Box>
                </Box>
              </DragDropContext>
              <TransactionFooter
                currencyCode={currencyCode}
                discount={discount}
                discountType={discountType}
                lineItems={lineItems}
                onChangeDiscount={(val) => {
                  setDiscount(val)
                  if (!isDirty) {
                    setIsDirty(true)
                  }
                }}
                onChangeDiscountType={(val) => {
                  setDiscountType(val)
                  if (!isDirty) {
                    setIsDirty(true)
                  }
                }}
              />
              {!isBlank(template?.footerTitle) || !isBlank(template?.footerBody) ? (
                <Box
                  sx={{
                    flexGrow: 1,
                    marginTop: "3rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <FormControl fullWidth>
                    <FielderTextField
                      aria-label={t("component.estimateDialog.footerTitle.title") as string}
                      helperText={t("component.estimateDialog.footerTitle.helperText") as string}
                      id="footerTitle"
                      inputProps={{ maxLength: 50 }}
                      label={t("component.estimateDialog.footerTitle.title")}
                      name="footerTitle"
                      onChange={handleChangeFooterTitle}
                      onFocus={handleFocus}
                      value={footerTitle}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FielderTextField
                      aria-label={t("component.estimateDialog.footerBody.title") as string}
                      helperText={t("component.estimateDialog.footerBody.helperText") as string}
                      id="notes"
                      inputProps={{ maxLength: 5000 }}
                      label={t("component.estimateDialog.footerBody.title") as string}
                      multiline
                      name="footerBody"
                      onChange={handleChangeFooterBody}
                      onFocus={handleFocus}
                      rows={5}
                      value={footerBody}
                    />
                  </FormControl>
                </Box>
              ) : null}
            </Paper>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

const classes = {
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "1161px",
    maxWidth: "1161px",
    marginBottom: "1rem",
    padding: "1.25rem",
  },
  headerControlsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nameAndTypeContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minWidth: "400px",
    maxWidth: "600px",
  },
  rowContainer: {
    display: "flex",
    gap: "0.625rem",
    marginBottom: "0.625rem",
    marginTop: "0.625rem",
    minWidth: "450px",
    width: "100%",
  },
  rowItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "50%",
  },
  textFieldContainer: {
    width: "50%",
    minWidth: "400px",
    maxWidth: "800px",
  },
  lineItemTableContainer: {
    marginTop: "0.625rem",
    paddingBottom: "1.25rem",
  },
  lineItemGridRow: {
    display: "grid",
    gridTemplateColumns:
      "50px minmax(100px, 4fr) minmax(100px, 4fr) repeat(3, minmax(50px, 1fr)) 2fr 50px",
    columnGap: "10px",
    paddingTop: "0.5rem",
    paddingBottom: "0.125rem",
    boxSizing: "border-box",
  },
  lineItemHeader: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderBottom: "1px solid #cdcdcd",
    alignItems: "end",
    "& label": {
      fontSize: "0.8rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "0.625rem",
    marginTop: "0.625rem",
  },
  addLineCell: {
    border: "none",
    marginTop: "1.5rem",
    paddingTop: "0.625rem",
    paddingRight: "1.25rem",
    paddingLeft: "1.25rem",
    textAlign: "center",
  },
  addLineButton: {
    border: "1px solid #288906",
    borderRadius: "4px",
    color: "#288906",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#F5FFF2",
      border: "1px solid #288906",
    },
  },
}

export default EstimateTemplateForm
