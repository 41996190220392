import gql from "graphql-tag"

export const VERIFY_PASSWORD_RESET_CODE = gql`
  mutation VerifyPasswordResetCode($code: String!) {
    verifyPasswordResetCode(input: { code: $code }) {
      authToken
      username
      firstName
      lastName
      languageCode
    }
  }
`
