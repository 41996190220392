import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"

export const CLONE_JOB = gql`
  mutation CloneJob($id: ID!) {
    cloneJob(input: { id: $id }) {
      job {
        id
        number
        customer {
          id
          name
        }
        categories {
          id
          name
        }
        workflowStep {
          id
          jobStatus {
            id
            name
          }
        }
        description
        address {
          ...FullAddress
        }
        siteContact {
          id
          firstName
          lastName
          email
          phoneNumber
          address {
            addressString
            latitude
            longitude
          }
        }
      }
    }
  }
  ${FullAddress}
`
