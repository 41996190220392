import React, { useState, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Job, TimeFrameOption } from "../../types"
import {
  DAY_MODE_ROW_HEIGHT,
  INTERVALS_PER_HOUR,
  DAY_MODE_INTERVAL_WIDTH,
  WEEK_MODE_INTERVAL_HEIGHT,
  WEEK_MODE_CELL_WIDTH,
} from "./Constants"

interface ClientCoordinate {
  clientX: number
  clientY: number
}

interface Point {
  x: number
  y: number
}

interface JobDragPreview {
  readonly job: Job
  readonly initialPosition: Point
  readonly onDrag: (translation: Point) => void
  readonly onDragEnd: (position: ClientCoordinate) => void
  readonly timeFrame: TimeFrameOption
}

function JobDragPreview({ job, initialPosition, onDrag, onDragEnd, timeFrame }: JobDragPreview) {
  const { t } = useTranslation()

  const [state, setState] = useState({
    origin: initialPosition,
    translation: initialPosition,
  })

  useEffect(() => {
    const handleMouseMove = ({ clientX, clientY }: ClientCoordinate) => {
      const translation = {
        x: clientX - 5,
        y: clientY - 5,
      }

      setState((state) => ({
        ...state,
        translation,
      }))

      onDrag?.(translation)
    }

    const handleMouseUp = ({ clientX, clientY }: ClientCoordinate) => {
      onDragEnd?.({ clientX, clientY })
    }

    window.addEventListener("mousemove", handleMouseMove)
    window.addEventListener("mouseup", handleMouseUp)

    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [onDrag, onDragEnd, state.origin.x, state.origin.y])

  const styles = useMemo(
    () => ({
      cursor: "arrow",
      position: "fixed",
      left: state.translation.x,
      top: state.translation.y,
      height:
        timeFrame === TimeFrameOption.WEEK
          ? WEEK_MODE_INTERVAL_HEIGHT * INTERVALS_PER_HOUR
          : DAY_MODE_ROW_HEIGHT,
      width:
        timeFrame === TimeFrameOption.DAY
          ? DAY_MODE_INTERVAL_WIDTH * INTERVALS_PER_HOUR
          : WEEK_MODE_CELL_WIDTH,
      zIndex: 2,
      color: "#727272",
      backgroundColor: "#FFF39B",
      borderColor: job.workflowStep?.jobStatus?.darkColor ?? "#D8B939",
      borderLeft: timeFrame === TimeFrameOption.DAY ? "4px solid" : "none",
      borderTop: timeFrame === TimeFrameOption.WEEK ? "4px solid" : "none",
      backgroundImage:
        "repeating-linear-gradient(-45deg, transparent, transparent 6px, rgba(255,255,255,.4) 6px, rgba(255,255,255,.4) 12px)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingTop: 3,
      paddingBottom: 3,
      marginBottom: 1,
      padding: "0.3125rem",
      paddingLeft: 15,
      paddingRight: 15,
      boxSizing: "border-box",
      fontSize: "0.75rem",
      lineHeight: 1,
      userSelect: "none",
    }),
    [state.translation.x, state.translation.y, timeFrame]
  )

  return (
    <div style={styles}>
      <div style={{ fontWeight: "bold" }}>
        {t("job")} #{job.number}
      </div>
      <div
        style={{
          whiteSpace: "nowrap",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {job.customer?.name}
      </div>
      <div
        style={{
          whiteSpace: "nowrap",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {job.address?.locality}
      </div>
    </div>
  )
}

export default JobDragPreview
