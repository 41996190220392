import gql from "graphql-tag"

export const CREATE_TASK = gql`
  mutation CreateTask(
    $description: String!
    $dueDate: LocalDateTime!
    $assigneeUserId: ID!
    $jobId: ID
    $status: TaskStatus!
    $notes: String
  ) {
    createTask(
      input: {
        description: $description
        dueDate: $dueDate
        assigneeUserId: $assigneeUserId
        jobId: $jobId
        status: $status
        notes: $notes
      }
    ) {
      task {
        id
        description
        dueDate
        assignee {
          id
          lastName
          firstName
        }
        job {
          id
          address {
            addressString
          }
        }
        status
        notes
        createdAt
        createdBy {
          id
          email
        }
        updatedAt
        updatedBy {
          id
          email
        }
      }
    }
  }
`

