import gql from "graphql-tag"

export const GET_MY_PROFILE = gql`
  query GetMyProfile {
    getMyProfile {
      id
      email
      firstName
      lastName
      jobTitle
      mobilePhoneNumber
      status
      languageCode
      roles {
        id
        name
      }
      organization {
        id
        tradeName
        timeZone
        languageCode
      }
      avatar {
        id
        signedUrl
        updatedAt
      }
      lastLogin
      availableNotificationTypes {
        id
        notificationCategory
        name
        allowEmail
        allowSms
        organizationLevel
      }
      notificationSettings {
        id
        notificationType {
          id
          name
          notificationCategory
        }
        emailEnabled
        smsEnabled
        inboxEnabled
      }
      createdAt
      createdBy {
        id
        email
      }
      updatedAt
      updatedBy {
        id
        email
      }
    }
  }
`
