import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization(
    $tradeName: String!
    $legalName: String
    $regionId: ID!
    $address: AddressInput!
    $accountManagerUserId: ID
    $accountNumber: String
    $phoneNumber: String
    $email: String
    $websiteURL: String
    $timeZone: String
    $languageCode: LanguageCode
    $ownerUserId: ID
    $ownerEmail: String
    $ownerFirstName: String
    $ownerLastName: String
    $ownerMobilePhoneNumber: String
    $shouldSendInvitation: Boolean
  ) {
    createOrganization(
      input: {
        tradeName: $tradeName
        legalName: $legalName
        regionId: $regionId
        address: $address
        accountNumber: $accountNumber
        accountManagerUserId: $accountManagerUserId
        phoneNumber: $phoneNumber
        email: $email
        websiteURL: $websiteURL
        timeZone: $timeZone
        languageCode: $languageCode
        ownerUserId: $ownerUserId
        ownerEmail: $ownerEmail
        ownerFirstName: $ownerFirstName
        ownerLastName: $ownerLastName
        ownerMobilePhoneNumber: $ownerMobilePhoneNumber
        shouldSendInvitation: $shouldSendInvitation
      }
    ) {
      organization {
        id
        tradeName
        legalName
        level
        status
        email
        phoneNumber
        currencyCode
        websiteURL
        timeZone
        languageCode
        accountNumber
        accountManager {
          id
          email
          firstName
          lastName
        }
        region {
          id
          name
          code
          currencyCode
        }
        address {
          ...FullAddress
        }
        owner {
          id
          firstName
          lastName
          email
          mobilePhoneNumber
          status
        }
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${FullAddress}
`
