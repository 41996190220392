import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import { MainLayout, PageHeader, Seo } from "../components"
import JobsMap from "../components/reports/JobsMap"
import { REPORTS, REPORTS_HQ } from "../util"
import { useAuth } from "../context/AuthContext"

const REPORT_TYPES = {
  FRANCHISEE_PERFORMANCE_LIST: "franchisee_performance_list",
  FRANCHISEE_SUBSCRIPTIONS: "franchisee_subscriptions",

  JOBS_COMPLETED_BY_FRANCHISEE: "jobs_completed_by_franchisee",
  JOBS_MAP: "jobs_map",

  PRODUCT_ORDERS_SUMMARY: "product_orders_summary",
}

function ReportsHQ() {
  const { t } = useTranslation()
  const [activeReport, setActiveReport] = useState(REPORT_TYPES.JOBS_MAP)
  const { user } = useAuth()

  function renderReport() {
    if (user?.organization?.id && user?.organization?.level === 1) {
      switch (activeReport) {
        case REPORT_TYPES.JOBS_MAP:
          return <JobsMap />
      }
    } else {
      return <Box>{t("You must be logged in as a HQ user to view reports")}</Box>
    }
  }

  return (
    <>
      <Seo title={t("sectionTitle.reports")} />
      <MainLayout activeSection={REPORTS_HQ}>
        <Box sx={classes.root}>
          <PageHeader icon={REPORTS_HQ.icon} leafTitleKey={REPORTS_HQ.titleKey} />
          <Grid container spacing={2} sx={classes.gridContainer} wrap="wrap-reverse">
            <Grid item xs={2}>
              <Paper sx={classes.paper}>
                <Box sx={classes.menuSectionContainer}>
                  <Box sx={classes.menuSectionHeader}>{t("page.report.franchiseePerformance")}</Box>
                  <Box sx={classes.menuOptionContainer}>
                    <Box
                      onClick={() => setActiveReport(REPORT_TYPES.FRANCHISEE_PERFORMANCE_LIST)}
                      sx={[
                        { ...classes.menuOption },
                        activeReport === REPORT_TYPES.FRANCHISEE_PERFORMANCE_LIST &&
                          classes.menuOptionActive,
                      ]}
                    >
                      {t("page.report.franchiseePerformanceList")}
                    </Box>
                    <Box
                      onClick={() => setActiveReport(REPORT_TYPES.FRANCHISEE_SUBSCRIPTIONS)}
                      sx={[
                        { ...classes.menuOption },
                        activeReport === REPORT_TYPES.FRANCHISEE_SUBSCRIPTIONS &&
                          classes.menuOptionActive,
                      ]}
                    >
                      {t("page.report.franchiseeSubscriptions")}
                    </Box>
                  </Box>
                </Box>
                <Box sx={classes.menuSectionContainer}>
                  <Box sx={classes.menuSectionHeader}>{t("page.report.jobs")}</Box>
                  <Box sx={classes.menuOptionContainer}>
                    <Box
                      onClick={() => setActiveReport(REPORT_TYPES.JOBS_COMPLETED_BY_FRANCHISEE)}
                      sx={[
                        { ...classes.menuOption },
                        activeReport === REPORT_TYPES.JOBS_COMPLETED_BY_FRANCHISEE &&
                          classes.menuOptionActive,
                      ]}
                    >
                      {t("page.report.jobsCompletedByFranchisee")}
                    </Box>
                    <Box
                      onClick={() => setActiveReport(REPORT_TYPES.JOBS_MAP)}
                      sx={[
                        { ...classes.menuOption },
                        activeReport === REPORT_TYPES.JOBS_MAP && classes.menuOptionActive,
                      ]}
                    >
                      {t("page.report.jobsMap")}
                    </Box>
                  </Box>
                </Box>
                <Box sx={classes.menuSectionContainer}>
                  <Box sx={classes.menuSectionHeader}>{t("page.report.productOrders")}</Box>
                  <Box sx={classes.menuOptionContainer}>
                    <Box
                      onClick={() => setActiveReport(REPORT_TYPES.PRODUCT_ORDERS_SUMMARY)}
                      sx={[
                        { ...classes.menuOption },
                        activeReport === REPORT_TYPES.PRODUCT_ORDERS_SUMMARY &&
                          classes.menuOptionActive,
                      ]}
                    >
                      {t("page.report.summary")}
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={10}>
              <Paper sx={classes.paper}>{renderReport()}</Paper>
            </Grid>
          </Grid>
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  pageHeader: {
    "& svg": {
      fontSize: "1.85rem",
      position: "relative",
      top: "0.3125rem",
    },
  },
  gridContainer: {
    flexGrow: 1,
    minHeight: "600px",
    minWidth: "820px",
    marginBottom: "2rem",
  },
  paper: {
    height: "100%",
  },
  menuSectionContainer: {
    paddingTop: "0.625rem",
  },
  menuSectionHeader: {
    fontWeight: "bold",
    fontSize: "1.1rem",
    paddingLeft: "1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  menuOptionContainer: {},
  menuOption: {
    paddingLeft: "2rem",
    paddingTop: "0.125rem",
    paddingBottom: "0.125rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FFC60111",
    },
  },
  menuOptionActive: {
    backgroundColor: "#FFC60133",
    "&:hover": {
      backgroundColor: "#FFC60133",
    },
  },
} as const

export default ReportsHQ
