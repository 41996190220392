import React from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import FielderBaseInput from "./FielderBaseInput"
import FieldSpinner from "./FieldSpinner"
import { Address, Organization, Region } from "../types"
import { Theme } from "@emotion/react"
import { SxProps } from "@mui/material"

const ALL_FRANCHISEES = gql`
  query AllFranchisees(
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $regionId: ID
    $includeArchived: Boolean
  ) {
    allOrganizations(
      input: { sortBy: $sortBy, sortDir: $sortDir, first: $first }
      organizationFilter: { regionId: $regionId, includeArchived: $includeArchived }
    ) {
      edges {
        node {
          id
          name
          address {
            addressString
            latitude
            longitude
          }
          region {
            id
            name
          }
        }
      }
    }
  }
`

const defaultId = "FranchiseeMultiSelect" + new Date().getTime()

type FranchiseeMultiSelectOption = {
  id: string
  name: string
  address?: Address
  region?: Region
}

interface FranchiseeMultiSelectProps {
  readonly ariaLabel?: string
  readonly id?: string
  readonly disabled?: boolean
  readonly error?: boolean
  readonly fullWidth?: boolean
  readonly includeArchived?: boolean
  readonly label: string
  readonly margin?: "none" | "dense" | undefined
  readonly name: string
  readonly onBlur?: () => void
  readonly onChange: (selectedFranchisees: Organization[] | null) => void
  readonly renderOptions?: (option: FranchiseeMultiSelectOption[]) => React.ReactNode
  readonly renderValue?: (value: string[]) => React.ReactNode
  readonly regionId?: string
  readonly required?: boolean
  readonly selectedFranchisees: Organization[]
  readonly size?: "small" | "medium"
  readonly style?: React.CSSProperties
  readonly sx?: SxProps<Theme>
}

function FranchiseeMultiSelect({
  ariaLabel,
  id = defaultId,
  disabled,
  error: hasError,
  fullWidth,
  includeArchived,
  label,
  margin,
  name,
  onBlur,
  onChange,
  renderOptions,
  renderValue,
  regionId,
  required,
  selectedFranchisees,
  size = "small",
  style,
  sx = {},
}: FranchiseeMultiSelectProps) {
  const { t } = useTranslation()

  const { loading, error, data } = useQuery(ALL_FRANCHISEES, {
    fetchPolicy: "cache-and-network",
    variables: {
      includeArchived,
      regionId,
    },
  })

  if (loading) {
    return (
      <FieldSpinner
        message={t("loadingOrganizations")}
        style={{
          border: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginTop: 0,
        }}
      />
    )
  } else if (error) {
    return <div>{`Error! ${error.message}`}</div>
  }

  const franchisees =
    data?.allOrganizations?.edges?.map((e: { node: Organization }) => e.node) || []

  const options: FranchiseeMultiSelectOption[] = franchisees?.map((o: Organization) => ({
    id: o.id,
    name: o.name,
  }))

  return (
    <FormControl
      aria-label={ariaLabel}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      margin={margin ?? "normal"}
      required={required}
      size={size}
      style={style}
      sx={{
        marginTop: 0,
        marginBottom: 0,
        "& label.Mui-focused": {
          color: (theme) => theme.fielderColors.black,
          transform: "translate(14px, 7px) scale(0.75)",
        },
        "& label.Mui-error": {
          color: (theme) => theme.fielderColors.error,
        },
        "& label.MuiInputLabel-shrink": {
          transform: "translate(14px, 7px) scale(0.75)",
        },
        "& div.Mui-error > .MuiSelect-outlined": {
          borderColor: (theme) => theme.fielderColors.error,
        },
        ...sx,
      }}
      variant="filled"
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        disabled={disabled}
        fullWidth={fullWidth}
        id={name}
        input={label ? <FielderBaseInput /> : undefined}
        inputProps={{ name: name }}
        label={t("component.franchiseeMultiSelect.label")}
        margin={margin}
        multiple
        onBlur={onBlur}
        onChange={(event) => {
          const currentSelections = event.target.value
          const selectedOptions = franchisees.filter(
            (o: Organization) => currentSelections.indexOf(o.id) > -1
          )
          onChange(selectedOptions)
        }}
        renderValue={(selectedValues) => {
          if (renderValue) {
            return renderValue(selectedValues)
          } else {
            const count = selectedValues.length
            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {count > 1
                  ? t("component.franchiseeMultiSelect.nSelected", { n: count })
                  : options.find((o) => o.id === selectedValues[0]).name}
              </Box>
            )
          }
        }}
        required={required}
        size={size}
        style={style}
        value={selectedFranchisees.map((s) => s.id)}
      >
        {renderOptions ? (
          renderOptions(options)
        ) : options?.length === 0 ? (
          <Box sx={{ padding: "0.5rem 1rem", color: (theme) => theme.fielderColors.mutedText }}>
            {t("component.franchiseeMultiSelect.noOptions")}
          </Box>
        ) : (
          options.map((v) => (
            <MenuItem key={v.id} value={v.id}>
              <Checkbox checked={selectedFranchisees.map((s) => s.id).indexOf(v.id) > -1} />
              <ListItemText primary={v.name} />
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  )
}

export default FranchiseeMultiSelect
