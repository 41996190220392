import gql from "graphql-tag"

export default gql`
  fragment FullWorkOrderTemplateLineItemDetails on WorkOrderTemplateLineItemDetail {
    id
    number
    quantity
    unitPrice
    organizationItem {
        id
        code
        name
    }
  }
`
