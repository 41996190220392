import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"

export const CREATE_JOB = gql`
  mutation CreateJob(
    $workflowId: ID!
    $existingCustomerId: ID
    $newCustomerName: String
    $newCustomerType: CustomerType
    $description: String
    $categoryIDs: [ID!]
    $desiredCompletionDate: LocalDateTime
    $address: AddressInput!
    $siteContact: ContactInput
  ) {
    createJob(
      input: {
        workflowId: $workflowId
        existingCustomerId: $existingCustomerId
        newCustomerName: $newCustomerName
        newCustomerType: $newCustomerType
        description: $description
        categoryIDs: $categoryIDs
        desiredCompletionDate: $desiredCompletionDate
        address: $address
        siteContact: $siteContact
      }
    ) {
      job {
        id
        number
        workflow {
          id
          name
        }
        customer {
          id
          name
        }
        categories {
          id
          name
        }
        workflowStep {
          id
          jobStatus {
            id
            name
          }
        }
        description
        address {
          ...FullAddress
        }
        siteContact {
          id
          firstName
          lastName
          email
          phoneNumber
          address {
            addressString
            latitude
            longitude
          }
        }
      }
    }
  }
  ${FullAddress}
`
