import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"

import ExpandMore from "~/components/ExpandMore"
import { OrganizationDefaultEstimateSettings } from "~/types"
import PositiveIntegerInput from "~/components/PositiveIntegerInput"
import CustomerViewSettingFormField from "./CustomerViewSettingFormField"
import useStore from "~/store"

interface Props {
  readonly defaultEstimateSettings: OrganizationDefaultEstimateSettings
  readonly onChange: (defaultEstimateSettings: OrganizationDefaultEstimateSettings) => void
  readonly showBottomDivider?: boolean
}

export default function DefaultEstimateSettingsForm({
  defaultEstimateSettings,
  showBottomDivider,
  onChange,
}: Props) {
  const { t } = useTranslation()
  const organizationDetailScreenSettings = useStore(
    (state) => state.organizationDetailScreenSettings
  )
  const setOrganizationDefaultSettings = useStore(
    (state) => state.setOrganizationDetailScreenSettings
  )

  return (
    <Box>
      <Box sx={{ paddingBottom: "0.5rem" }} />
      <Box
        onClick={() => {
          setOrganizationDefaultSettings({
            ...organizationDetailScreenSettings,
            isDefaultEstimateSettingsExpanded:
              !organizationDetailScreenSettings.isDefaultEstimateSettingsExpanded,
          })
        }}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Box
          component="label"
          sx={{
            cursor: "pointer",
            fontWeight: 700,
            marginBottom: "0.25rem",
            fontSize: "0.875rem",
          }}
        >
          {t("page.organizationDetails.defaultEstimateSettings.label")}
        </Box>
        <ExpandMore
          aria-expanded={organizationDetailScreenSettings.isDefaultEstimateSettingsExpanded}
          aria-label={t("page.organizationDetails.defaultEstimateSettings.label") as string}
          expand={organizationDetailScreenSettings.isDefaultEstimateSettingsExpanded}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>
      <Collapse in={organizationDetailScreenSettings.isDefaultEstimateSettingsExpanded}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.5rem",
            paddingRight: "1rem",
            backgroundColor: "#f5f5f5",
            borderRadius: "4px",
            margin: "0.25rem 0rem",
          }}
        >
          <Box sx={{ fontSize: "0.875rem", fontWeight: "600" }}>
            {t("page.organizationDetails.defaultEstimateSettings.expirationDays")}
          </Box>
          <Box>
            <PositiveIntegerInput
              aria-label={
                t("page.organizationDetails.defaultEstimateSettings.expirationDays") as string
              }
              customInput={TextField}
              defaultValue={30}
              name="expirationDays"
              onBlur={(e) => {
                onChange?.({
                  ...defaultEstimateSettings,
                  expirationDays: parseInt(e.target.value, 10),
                })
              }}
              onChange={(event) => {
                /* no-op */
              }}
              size="small"
              sx={{
                margin: 0,
                width: "5rem",
                backgroundColor: "#fff",
              }}
              value={defaultEstimateSettings.expirationDays}
              variant="outlined"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            padding: "0.5rem",
            paddingRight: "1rem",
          }}
        >
          <Box sx={{ fontSize: "0.875rem", fontWeight: "600" }}>
            {t("page.organizationDetails.customerViewSettings.label")}
          </Box>
          <Box sx={{ fontSize: "0.875rem", color: (theme) => theme.fielderColors.mutedText }}>
            {t("page.organizationDetails.defaultEstimateSettings.customerViewSettingsDescription")}
          </Box>
        </Box>
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showExpirationDate")}
          onChange={(value) => onChange({ ...defaultEstimateSettings, showExpirationDate: value })}
          value={Boolean(defaultEstimateSettings.showExpirationDate)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showContract")}
          onChange={(value) => onChange({ ...defaultEstimateSettings, showContract: value })}
          value={Boolean(defaultEstimateSettings.showContract)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItems")}
          onChange={(value) => onChange({ ...defaultEstimateSettings, showLineItems: value })}
          value={Boolean(defaultEstimateSettings.showLineItems)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemCode")}
          onChange={(value) => onChange({ ...defaultEstimateSettings, showLineItemCode: value })}
          value={Boolean(defaultEstimateSettings.showLineItemCode)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemName")}
          onChange={(value) => onChange({ ...defaultEstimateSettings, showLineItemName: value })}
          value={Boolean(defaultEstimateSettings.showLineItemName)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemDescription")}
          onChange={(value) =>
            onChange({ ...defaultEstimateSettings, showLineItemDescription: value })
          }
          value={Boolean(defaultEstimateSettings.showLineItemDescription)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemQuantity")}
          onChange={(value) =>
            onChange({ ...defaultEstimateSettings, showLineItemQuantity: value })
          }
          value={Boolean(defaultEstimateSettings.showLineItemQuantity)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemUnitPrice")}
          onChange={(value) =>
            onChange({ ...defaultEstimateSettings, showLineItemUnitPrice: value })
          }
          value={Boolean(defaultEstimateSettings.showLineItemUnitPrice)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemSubtotal")}
          onChange={(value) =>
            onChange({ ...defaultEstimateSettings, showLineItemSubtotal: value })
          }
          value={Boolean(defaultEstimateSettings.showLineItemSubtotal)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showLineItemBundleComponents")}
          onChange={(value) =>
            onChange({ ...defaultEstimateSettings, showLineItemBundleComponents: value })
          }
          value={Boolean(defaultEstimateSettings.showLineItemBundleComponents)}
        />
        <CustomerViewSettingFormField
          label={t(
            "page.organizationDetails.customerViewSettings.showLineItemBundleComponentQuantity"
          )}
          onChange={(value) =>
            onChange({ ...defaultEstimateSettings, showLineItemBundleComponentQuantity: value })
          }
          value={Boolean(defaultEstimateSettings.showLineItemBundleComponentQuantity)}
        />
        <CustomerViewSettingFormField
          label={t(
            "page.organizationDetails.customerViewSettings.showLineItemBundleComponentUnitPrice"
          )}
          onChange={(value) =>
            onChange({ ...defaultEstimateSettings, showLineItemBundleComponentUnitPrice: value })
          }
          value={Boolean(defaultEstimateSettings.showLineItemBundleComponentUnitPrice)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showFooter")}
          onChange={(value) => onChange({ ...defaultEstimateSettings, showFooter: value })}
          value={Boolean(defaultEstimateSettings.showFooter)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showFooterSubtotal")}
          onChange={(value) => onChange({ ...defaultEstimateSettings, showFooterSubtotal: value })}
          value={Boolean(defaultEstimateSettings.showFooterSubtotal)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showFooterDiscount")}
          onChange={(value) => onChange({ ...defaultEstimateSettings, showFooterDiscount: value })}
          value={Boolean(defaultEstimateSettings.showFooterDiscount)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showFooterTaxes")}
          onChange={(value) => onChange({ ...defaultEstimateSettings, showFooterTaxes: value })}
          value={Boolean(defaultEstimateSettings.showFooterTaxes)}
        />
        <CustomerViewSettingFormField
          label={t("page.organizationDetails.customerViewSettings.showFooterTotal")}
          onChange={(value) => onChange({ ...defaultEstimateSettings, showFooterTotal: value })}
          value={Boolean(defaultEstimateSettings.showFooterTotal)}
        />
        <Box sx={{ paddingBottom: "0.5rem" }} />
      </Collapse>
      <Box sx={{ paddingBottom: "0.5rem" }} />
      {showBottomDivider ? <Divider /> : null}
    </Box>
  )
}
