import React from "react"
import { useTranslation } from "react-i18next"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"
import MenuItem from "@mui/material/MenuItem"

import SelectorField, { SelectorOption } from "./SelectorField"
import { LanguageCode } from "~/types"

interface Props {
  readonly disabled?: boolean
  readonly error?: boolean
  readonly label?: string
  readonly name?: string
  readonly onBlur?: () => void
  readonly onChange: (value?: LanguageCode | null) => void
  readonly required?: boolean
  readonly sx?: SxProps<Theme>
  readonly value?: LanguageCode | undefined | null
}

function LanguageSelect({
  disabled,
  error: hasError,
  label,
  name,
  onBlur,
  onChange,
  required,
  sx,
  value,
}: Props) {
  const { t } = useTranslation()

  const languageCodeOptions: SelectorOption[] = [
    {
      id: LanguageCode.ENGLISH_CANADA,
      name: t("languageCodeOptions.ENGLISH_CANADA"),
    },
    {
      id: LanguageCode.ENGLISH_USA,
      name: t("languageCodeOptions.ENGLISH_USA"),
    },
    {
      id: LanguageCode.ENGLISH_UK,
      name: t("languageCodeOptions.ENGLISH_UK"),
    },
    {
      id: LanguageCode.FRENCH_CANADA,
      name: t("languageCodeOptions.FRENCH_CANADA"),
    },
    {
      id: LanguageCode.FRENCH_FRANCE,
      name: t("languageCodeOptions.FRENCH_FRANCE"),
    },
    {
      id: LanguageCode.GERMAN_GERMANY,
      name: t("languageCodeOptions.GERMAN_GERMANY"),
    },
    {
      id: LanguageCode.SPANISH_USA,
      name: t("languageCodeOptions.SPANISH_USA"),
    },
    {
      id: LanguageCode.DUTCH_BELGIUM,
      name: t("languageCodeOptions.DUTCH_BELGIUM"),
    },
    {
      id: LanguageCode.DUTCH_NETHERLANDS,
      name: t("languageCodeOptions.DUTCH_NETHERLANDS"),
    },
  ]

  return (
    <SelectorField
      disabled={disabled}
      error={hasError}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={(val) => {
        onChange(val?.id)
      }}
      options={languageCodeOptions}
      renderOption={(selectorOption: SelectorOption) => {
        return (
          <MenuItem key={selectorOption.id} value={selectorOption.id}>
            <div>{selectorOption.name}</div>
          </MenuItem>
        )
      }}
      renderValue={(selected) => {
        const val = languageCodeOptions.find((o: SelectorOption) => o.id === selected)
        if (!val) {
          return <div>{t("error.invalidOptionSelected")}</div>
        } else {
          return <div>{val.name}</div>
        }
      }}
      required={required}
      sx={sx}
      value={value ?? ""}
    />
  )
}

export default React.memo(LanguageSelect)
