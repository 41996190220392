import gql from "graphql-tag"

export default gql`
  fragment FullLineItem on ChecklistLineItem {
    id
    diagramId
    number
    requiredCompressionCapacity
    achievedCompressionCapacity
    requiredTorque
    achievedTorque
    pileUsed
    helixUsed
    totalPileLength
    cutOffElevation
    pileInclination
    achievedTensionCapacity
    notes {
      id
      text
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`
