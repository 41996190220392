import React from "react"
import Button from "@mui/material/Button"
import ArchiveIcon from "@mui/icons-material/ArchiveOutlined"
import { useTranslation } from "react-i18next"
import FielderIconButton from "./FielderIconButton"

interface Props {
  readonly onClick: () => void
  readonly disabled?: boolean
  readonly iconOnly?: boolean
}

function ArchiveButton({ onClick, disabled, iconOnly = false }: Props) {
  const { t } = useTranslation()

  return iconOnly ? (
    <FielderIconButton
      aria-label={t("archive") as string}
      data-testid="archiveButton"
      disabled={disabled}
      onClick={onClick}
      sx={{}}
      title={t("archive") as string}
    >
      <ArchiveIcon />
    </FielderIconButton>
  ) : (
    <Button
      disabled={disabled}
      onClick={onClick}
      size="small"
      startIcon={<ArchiveIcon />}
      sx={{
        marginRight: "1rem",
      }}
      variant="text"
    >
      <span>{t("archive")}</span>
    </Button>
  )
}

export default ArchiveButton
