import gql from "graphql-tag"

export const EDIT_MY_PROFILE = gql`
  mutation EditMyProfile(
    $firstName: String
    $lastName: String
    $mobilePhoneNumber: String
    $languageCode: LanguageCode
  ) {
    editMyProfile(
      input: {
        firstName: $firstName
        lastName: $lastName
        mobilePhoneNumber: $mobilePhoneNumber
        languageCode: $languageCode
      }
    ) {
      user {
        id
        email
        firstName
        lastName
        jobTitle
        mobilePhoneNumber
        status
        languageCode
        roles {
          id
          isStandard
          name
        }
        organization {
          id
          tradeName
        }
        avatar {
          id
          signedUrl
          updatedAt
        }
        lastLogin
        createdAt
        createdBy {
          id
          email
        }
        updatedAt
        updatedBy {
          id
          email
        }
      }
    }
  }
`
