import gql from "graphql-tag"

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($paymentMethodId: String!, $priceId: String!) {
    createSubscription(input: { paymentMethodId: $paymentMethodId, priceId: $priceId }) {
      fielderSubscription {
        id
        status
        taxPercent
        latestInvoice {
          id
          paymentIntent {
            id
            status
            clientSecret
          }
        }
        pricePoint {
          id
          name
          type
          interval
          unitAmount
          effectiveMonthlyRate
          product {
            id
            nameKey
            name
            monthlyJobLimit
            isFeatured
          }
        }
        organization {
          id
          tradeName
          legalName
          email
          status
        }
      }
    }
  }
`

