import React from "react"
import { styled } from "@mui/material/styles"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"

interface Props extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: Props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default ExpandMore
