import gql from "graphql-tag"
import FullProductOrder from "./fragments/FullProductOrder"

export const EDIT_PRODUCT_ORDER_LINE_ITEM = gql`
  mutation EditProductOrderLineItem(
    $id: ID!
    $productOrderId: ID!
    $number: Int
    $quantity: Int
    $unitPrice: BigDecimal
    $description: String
  ) {
    editProductOrderLineItem(
      input: {
        id: $id
        productOrderId: $productOrderId
        number: $number
        quantity: $quantity
        unitPrice: $unitPrice
        description: $description
      }
    ) {
      productOrder {
        ...FullProductOrder
      }
    }
  }
  ${FullProductOrder}
`
