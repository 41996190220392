import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery, gql } from "@apollo/client"
import Box from "@mui/material/Box"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import type { ContractTemplate, Estimate } from "../../../types/apiTypes"
import { DEFAULT_INITIAL_RTE_VALUE, flattenObject, resolveTemplate } from "../../../util"
import RichTextComposer from "../../../components/RichTextEditor/RichTextComposer"
import { Descendant } from "slate"

const GET_CONTRACT_TEMPLATES = gql`
  query GetContractTemplates($id: ID!) {
    getOrganizationById(id: $id) {
      id
      contractTemplates {
        id
        name
        body
      }
    }
  }
`

interface Props {
  readonly estimate: Estimate
  readonly onChangeContent: (content: Descendant[]) => void
}

export default function EstimateContract({ estimate, onChangeContent }: Props) {
  const { t } = useTranslation()

  const [content, setContent] = useState<Descendant[]>(
    estimate.contractJson ? JSON.parse(estimate.contractJson) : DEFAULT_INITIAL_RTE_VALUE
  )
  const [contractTemplateMenuAnchorEl, setContractTemplateMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const showContractTemplateMenu = Boolean(contractTemplateMenuAnchorEl)

  const { data: contractTemplateData } = useQuery(GET_CONTRACT_TEMPLATES, {
    variables: {
      id: estimate.job.organization.id,
    },
    fetchPolicy: "cache-and-network",
  })
  const contractTemplates = contractTemplateData?.getOrganizationById?.contractTemplates ?? []

  const renderRightToolbar = () => {
    if (!contractTemplates.length) {
      return null
    } else {
      return (
        <Box>
          <Button
            aria-controls={showContractTemplateMenu ? "contract-template-menu" : undefined}
            aria-expanded={showContractTemplateMenu ? "true" : undefined}
            aria-haspopup="true"
            disableElevation
            endIcon={<ArrowDropDownIcon />}
            id="contract-template-menu-button"
            onClick={(event) => {
              setContractTemplateMenuAnchorEl(event.currentTarget)
            }}
            variant="text"
          >
            {t("selectContractTemplate")}
          </Button>
          <Menu
            anchorEl={contractTemplateMenuAnchorEl}
            keepMounted
            onClose={() => setContractTemplateMenuAnchorEl(undefined)}
            open={showContractTemplateMenu}
          >
            {contractTemplates?.map((contractTemplateOption: ContractTemplate) => (
              <MenuItem
                key={contractTemplateOption.id}
                onMouseDown={() => {
                  const estimateData = flattenObject({
                    ...estimate,
                    organization: estimate?.job?.organization,
                  })
                  const content = JSON.parse(contractTemplateOption.body)
                  const resolved = resolveTemplate(content, estimateData, t)
                  setContent(resolved)
                  onChangeContent(resolved)
                  setContractTemplateMenuAnchorEl(undefined)
                }}
              >
                <span>{contractTemplateOption.name}</span>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )
    }
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: "3rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        minHeight: "256px",
        marginBottom: "0.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.25rem",
          marginLeft: "0.25rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            gap: "1rem",
          }}
        >
          <Box sx={{ fontSize: "1rem", fontWeight: "600" }}>{t("contract")}</Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.25rem",
              alignItems: "center",
              fontSize: "0.875rem",
            }}
          >
            <a
              href={t("page.settings.templates.contract.learnMoreUrl") as string}
              rel="noreferrer"
              style={{ color: "black" }}
              target="_blank"
            >
              {t("learnMore")}
            </a>
            <OpenInNewIcon sx={{ fontSize: "0.875rem" }} />
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: "0.7rem",
            color: (theme) => theme.fielderColors.mutedText,
          }}
        >
          {t("component.estimateContract.helperText")}
        </Box>
      </Box>
      <RichTextComposer
        autoFocus={false}
        hide={false}
        onChange={onChangeContent}
        placeholder={t("component.estimateContract.placeholder") as string}
        renderRightToolbar={renderRightToolbar}
        value={content}
      />
    </Box>
  )
}
