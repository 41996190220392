import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

function TaskIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 32">
      <path
        d="M16.0183043,0 L3.38921739,0 C1.54408696,0 0.0425217391,1.52553209 0.0425217391,3.39975155 L0.0425217391,28.543205 C0.0425217391,30.4174244 1.54408696,31.9430228 3.38921739,31.9430228 L20.6210217,31.9430228 C22.4649783,31.9430228 23.9666739,30.4174907 23.9666739,28.543205 L23.9666739,8.04631056 L16.0183043,0 Z M20.6209565,29.8599089 L3.38921739,29.8599089 C2.67476087,29.8599089 2.09302174,29.2699296 2.09302174,28.543205 L2.09302174,3.39975155 C2.09302174,2.67408696 2.67476087,2.08410766 3.38921739,2.08410766 L15.2516087,2.07165217 L15.2516087,6.52495238 C15.2516087,7.82701449 16.2907826,8.88380952 17.5723696,8.88380952 L21.8677826,8.87135404 L21.9161087,28.5433375 C21.9161087,29.2699296 21.3352826,29.8599089 20.6209565,29.8599089 Z"
        id="Shape1"
      />
      <path
        d="M4.63173913,10.6102195 L9.81319565,10.6102195 C10.2489783,10.6102195 10.602587,10.2510642 10.602587,9.80829814 L10.602587,4.54419876 C10.602587,4.10136646 10.2489783,3.74227743 9.81319565,3.74227743 L4.63173913,3.74227743 C4.19602174,3.74227743 3.84234783,4.10143271 3.84234783,4.54419876 L3.84234783,9.80829814 C3.84234783,10.2510642 4.19602174,10.6102195 4.63173913,10.6102195 Z M5.42113043,5.34605383 L9.02380435,5.34605383 L9.02380435,9.00631056 L5.42113043,9.00631056 L5.42113043,5.34605383 L5.42113043,5.34605383 Z"
        id="Shape2"
      />
      <path
        d="M4.63173913,19.4062443 L9.81319565,19.4062443 C10.2489783,19.4062443 10.602587,19.047089 10.602587,18.6043892 L10.602587,13.3402899 C10.602587,12.8974576 10.2489783,12.5383023 9.81319565,12.5383023 L4.63173913,12.5383023 C4.19602174,12.5383023 3.84234783,12.8974576 3.84234783,13.3402899 L3.84234783,18.6043892 C3.84234783,19.047089 4.19602174,19.4062443 4.63173913,19.4062443 Z M5.42113043,14.1421449 L9.02380435,14.1421449 L9.02380435,17.8024017 L5.42113043,17.8024017 L5.42113043,14.1421449 L5.42113043,14.1421449 Z"
        id="Shape3"
      />
      <path
        d="M7.14482609,26.5969027 L5.42113043,26.5969027 L5.42113043,22.9365797 L9.02380435,22.9365797 L9.02380435,23.9697888 L9.50230435,24.4558841 L10.602587,23.3380041 L10.602587,22.1347246 C10.602587,21.6919586 10.2489783,21.3328033 9.81319565,21.3328033 L4.63173913,21.3328033 C4.19602174,21.3328033 3.84234783,21.6919586 3.84234783,22.1347246 L3.84234783,27.398824 C3.84234783,27.8415901 4.19595652,28.2006791 4.63173913,28.2006791 L8.89421739,28.2006791 C8.71023913,28.1224348 8.53604348,28.0101366 8.38597826,27.8576894 L7.14482609,26.5969027 Z"
        id="Path1"
      />
      <path
        d="M12.5621739,22.4813582 L9.50230435,25.5897971 L8.2893913,24.3576977 C7.98097826,24.0443892 7.48147826,24.0443892 7.17313043,24.3576977 C6.86478261,24.6708737 6.86478261,25.1783685 7.17313043,25.4916108 L8.9441087,27.2906335 C9.09828261,27.4472547 9.30026087,27.5256977 9.50230435,27.5256977 C9.70428261,27.5256977 9.90613043,27.4472547 10.0603696,27.2906335 L13.6783696,23.6151387 C13.9867826,23.3019627 13.9867826,22.7944017 13.6783696,22.4812919 C13.370087,22.1681159 12.8705217,22.1681159 12.5621739,22.4813582 Z"
        id="Path2"
      />
    </SvgIcon>
  )
}

export default TaskIcon
