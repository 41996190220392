import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@apollo/client"
import { Theme } from "@mui/material/styles"
import { SystemStyleObject } from "@mui/system"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/EditOutlined"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"

import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import OrganizationBasicInfoForm from "./components/OrganizationBasicInfoForm"
import OrganizationBasicInfoStatic from "./components/OrganizationBasicInfoStatic"
import OrganizationPreferencesStatic from "./components/OrganizationPreferencesStatic"
import { EDIT_ORGANIZATION } from "~/queries/editOrganization"
import { GET_ORGANIZATION_BY_ID_WITHOUT_PLUGINS } from "~/queries/getOrganizationById"
import { SETTINGS, parseGraphQLErrorCode } from "~/util"
import { useAuth } from "~/context/AuthContext"
import {
  DefaultPermission,
  EditOrganizationInput,
  EditOrganizationResponse,
  Organization,
  OrganizationFormInput,
  Snack,
} from "~/types"
import OrganizationPreferencesForm from "./components/OrganizationPreferencesForm"

function EditOrganization() {
  const { t } = useTranslation()
  const { user, hasPermissions } = useAuth()
  const [inEditBasicInfoMode, setInEditBasicInfoMode] = useState(false)
  const [inEditPrefsMode, setInEditPrefsMode] = useState(false)
  const [snack, setSnack] = useState<Snack | undefined>()
  const [orgData, setOrgData] = useState<Organization>()
  const userOrgId = user?.organization?.id

  const [editOrganizationBasicInfo, { loading: editBasicInfoMutationLoading }] = useMutation<
    EditOrganizationResponse,
    EditOrganizationInput
  >(EDIT_ORGANIZATION, {
    onCompleted: (data) => {
      setOrgData(data.editOrganization.organization)
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      setInEditBasicInfoMode(false)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [editOrganizationPreferences, { loading: editPreferencesMutationLoading }] = useMutation<
    EditOrganizationResponse,
    EditOrganizationInput
  >(EDIT_ORGANIZATION, {
    onCompleted: (data) => {
      setOrgData(data.editOrganization.organization)
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      setInEditPrefsMode(false)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const { loading: queryLoading } = useQuery(GET_ORGANIZATION_BY_ID_WITHOUT_PLUGINS, {
    variables: { id: userOrgId },
    onCompleted: (data) => {
      setOrgData(data.getOrganizationById)
    },
  })

  const loading = queryLoading || editBasicInfoMutationLoading || editPreferencesMutationLoading

  function handleSaveBasicInfo(orgFormInput: OrganizationFormInput) {
    editOrganizationBasicInfo({ variables: orgFormInput as EditOrganizationInput })
  }

  function handleCancelEditBasicInfo() {
    setInEditBasicInfoMode(false)
  }

  function handleSavePreferences(orgFormInput: OrganizationFormInput) {
    editOrganizationPreferences({ variables: orgFormInput as EditOrganizationInput })
  }

  function handleCancelEditPreferences() {
    setInEditPrefsMode(false)
  }

  return (
    <>
      <Seo title={t(SETTINGS.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
            paddingBottom: 400,
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="organizationDetails"
          />
          {loading ? (
            <CircularProgress />
          ) : orgData ? (
            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
              <Grid item lg={6} xs={12}>
                <Paper>
                  <SectionHeader>
                    <label>{t("basicInfo")}</label>
                    {!inEditBasicInfoMode &&
                    !inEditPrefsMode &&
                    hasPermissions?.([DefaultPermission.UpdateOrganization]) ? (
                      <IconButton
                        aria-label="edit basic info"
                        data-testid="editJobBtnBasic"
                        onClick={() => {
                          setInEditBasicInfoMode(true)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    ) : null}
                  </SectionHeader>
                  <Divider />
                  <SectionContent
                    sx={(theme) => {
                      return {
                        [theme.breakpoints.up("md")]: {
                          padding: "2.5rem",
                          paddingTop: "1.5rem",
                          paddingBottom: "1.5rem",
                        },
                      } as SystemStyleObject<Theme>
                    }}
                  >
                    {!inEditBasicInfoMode ? (
                      <OrganizationBasicInfoStatic organization={orgData} />
                    ) : (
                      <OrganizationBasicInfoForm
                        loading={loading}
                        onCancel={handleCancelEditBasicInfo}
                        onSave={handleSaveBasicInfo}
                        organization={orgData}
                      />
                    )}
                  </SectionContent>
                </Paper>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Paper>
                  <SectionHeader>
                    <label>{t("page.organizationDetails.preferences")}</label>
                    {!inEditPrefsMode &&
                    !inEditBasicInfoMode &&
                    hasPermissions?.([DefaultPermission.UpdateOrganization]) ? (
                      <IconButton
                        aria-label="edit"
                        data-testid="editOrganizationPreferencesBtn"
                        onClick={() => {
                          setInEditPrefsMode(true)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    ) : null}
                  </SectionHeader>
                  <Divider />
                  <SectionContent
                    sx={(theme) => {
                      return {
                        [theme.breakpoints.up("md")]: {
                          padding: "2.5rem",
                          paddingTop: "1.5rem",
                          paddingBottom: "1.5rem",
                        },
                      } as SystemStyleObject<Theme>
                    }}
                  >
                    {!inEditPrefsMode ? (
                      <OrganizationPreferencesStatic organization={orgData} />
                    ) : (
                      <OrganizationPreferencesForm
                        loading={loading}
                        onCancel={handleCancelEditPreferences}
                        onSave={handleSavePreferences}
                        organization={orgData}
                      />
                    )}
                  </SectionContent>
                </Paper>
              </Grid>
            </Grid>
          ) : null}
        </Box>
      </MainLayout>
    </>
  )
}

export default EditOrganization
