import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import SelectorField from "../../../components/SelectorField"
import { ALL_ASSIGNMENTS_FOR_JOB } from "../../../queries/allAssignmentsForJob"
import { formatDate, formatPersonName } from "../../../util"
import type { JobAssignment, User, WorkOrder } from "../../../types"

interface AssignmentOptionProps {
  readonly assignment: JobAssignment
  readonly timeZone: string
}

function AssignmentOption({ assignment, timeZone }: AssignmentOptionProps) {
  const { t } = useTranslation()

  const fullDateFormat = t("format:dateFormat.fullDate")

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={classes.optionLeftDetail}>
        <Box sx={classes.optionLeftDetailTitle}>
          {formatDate(assignment.startDate, fullDateFormat, timeZone)}
        </Box>
        <Box sx={classes.optionLeftDetailSubtitle}>
          <span>{formatDate(assignment.startDate, t("format:dateFormat.time"), timeZone)}</span>
          <span>{" - "}</span>
          <span>{formatDate(assignment.endDate, t("format:dateFormat.time"), timeZone)}</span>
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: "right",
          alignSelf: "center",
        }}
      >
        {t("assignedTo")}
        {assignment.assignees?.map((a) => (
          <Box key={a.id} sx={classes.optionLeftDetailSubtitle}>
            {formatPersonName(a)}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

interface Props {
  readonly currentSelectedAssignment?: JobAssignment | null
  readonly filterOutLinkedToWorkOrder?: boolean
  readonly jobId: string
  readonly onCancel: () => void
  readonly onSelectJobAssignment: (assignment: JobAssignment | null) => void
  readonly user: User
  readonly workOrder: WorkOrder
}

function ChangeJobAssignmentDialog({
  currentSelectedAssignment,
  filterOutLinkedToWorkOrder = false,
  jobId,
  onCancel,
  onSelectJobAssignment,
  user,
  workOrder,
}: Props) {
  const { t } = useTranslation()
  const [selectedAssignment, setSelectedAssignment] = useState<JobAssignment | null>(() =>
    currentSelectedAssignment ? currentSelectedAssignment : null
  )

  const { loading, data } = useQuery(ALL_ASSIGNMENTS_FOR_JOB, {
    variables: {
      jobId,
    },
    fetchPolicy: "network-only",
  })

  const assignments = data?.getJobById?.assignments ?? []
  const filteredAssignments = filterOutLinkedToWorkOrder
    ? assignments.filter((ja) => !ja.workOrder || ja.workOrder?.id === workOrder.id)
    : assignments

  function handleSelect() {
    onSelectJobAssignment(selectedAssignment)
  }

  return (
    <Dialog
      aria-labelledby="change-job-assignment-dialog"
      data-testid="ChangeJobAssignmentDialog"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open
    >
      <DialogTitle
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {t("component.changeJobAssignmentDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: dialogBackgroundColor,
            paddingBottom: 0,
            px: "1rem",
          }}
        >
          <Box
            sx={{
              color: (theme) => theme.fielderColors.mutedText,
              marginBottom: "0.625rem",
            }}
          >
            <p>{t("component.changeJobAssignmentDialog.description1")}</p>
            <p>{t("component.changeJobAssignmentDialog.description2")}</p>
          </Box>
          <Box sx={classes.fieldContainer}>
            {assignments?.length === 0 ? (
              <Box
                sx={(theme) => ({
                  color: theme.fielderColors.mutedText,
                  border: `1px solid ${theme.fielderColors.mutedText}`,
                  borderRadius: "4px",
                  padding: "0.625rem",
                  backgroundColor: "#fff",
                })}
              >
                {t("component.changeJobAssignmentDialog.noResults")}
              </Box>
            ) : filteredAssignments?.length === 0 ? (
              <Box
                sx={(theme) => ({
                  color: theme.fielderColors.mutedText,
                  border: `1px solid ${theme.fielderColors.mutedText}`,
                  borderRadius: "4px",
                  padding: "0.625rem",
                  backgroundColor: "#fff",
                })}
              >
                {t("component.changeJobAssignmentDialog.noAvailableResults")}
              </Box>
            ) : (
              <SelectorField
                label={t("component.changeJobAssignmentDialog.selectLabel") as string}
                name="assignment"
                onChange={(selection) => {
                  const assignment = filteredAssignments?.find((t) => t.id === selection.id)
                  setSelectedAssignment(assignment)
                }}
                options={filteredAssignments}
                renderOption={(option) => {
                  const assignment = filteredAssignments?.find((t) => t.id === option.id)
                  return (
                    <MenuItem key={assignment.id} value={assignment.id}>
                      <AssignmentOption
                        assignment={assignment}
                        timeZone={user.organization.timeZone}
                      />
                    </MenuItem>
                  )
                }}
                renderValue={(assignmentId) => {
                  const assignment = filteredAssignments?.find((q) => q.id === assignmentId)
                  return (
                    <AssignmentOption
                      assignment={assignment}
                      timeZone={user.organization.timeZone}
                    />
                  )
                }}
                value={selectedAssignment?.id}
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: "1.5rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          data-testid="loadButton"
          disabled={loading || !selectedAssignment}
          onClick={handleSelect}
          variant="contained"
        >
          {loading ? (
            <CircularProgress size={20} thickness={6.0} />
          ) : (
            <Box>{t("component.changeJobAssignmentDialog.saveButtonLabel")}</Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const dialogBackgroundColor = "#FFFFFF"
const classes = {
  fieldContainer: {
    marginTop: "1.25rem",
  },
  optionLeftDetail: {
    display: "flex",
    flexDirection: "column",
  },
  optionLeftDetailTitle: {
    fontSize: "1rem",
  },
  optionLeftDetailSubtitle: {
    fontSize: "0.75rem",
  },
}

export default ChangeJobAssignmentDialog
