import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { formatMoney } from "../../../util"
import { Organization, ProductOrder, ProductOrderLineItem } from "../../../types"
import TextField from "@mui/material/TextField"

interface Props {
  order: ProductOrder
  orderTotal: number
  organization: Organization
  onCancel: () => void
  onSave: (containsMHI: boolean, specialInstructions: string) => void
}

export default function PlaceOrderConfirmationDialog({
  order,
  orderTotal,
  organization,
  onCancel,
  onSave,
}: Props) {
  const { t } = useTranslation()
  const [ncnrConsent, setNcnrConsent] = useState<boolean>(false)
  const [containsMHI, setContainsMHI] = useState<boolean>(false)
  const [specialInstructions, setSpecialInstructions] = useState<string>("")
  const showMhiCheckbox = organization.region?.code === "USA"

  const ncnrLineItems =
    order.lineItems?.filter((li) => li.organizationItem.isCancelable === false) || []

  return (
    <Dialog
      aria-describedby="confirm-place-order-dialog-description"
      aria-labelledby="confirm-place-order-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open
    >
      <DialogTitle id="confirm-place-order-dialog-title">
        {t("page.cart.confirmationDialog.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-place-order-dialog-description">
          {t("page.cart.confirmationDialog.prompt", {
            formattedAmount: formatMoney(order.currencyCode, orderTotal),
          })}
        </DialogContentText>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem", margin: "2rem 0" }}>
          <Box>
            <label htmlFor="special-instructions">
              {t("page.cart.confirmationDialog.specialInstructions")}
            </label>
            <TextField
              fullWidth
              id="special-instructions"
              inputProps={{
                maxLength: 500,
              }}
              multiline
              onChange={(e) => {
                setSpecialInstructions(e.target.value)
              }}
              placeholder={
                t("page.cart.confirmationDialog.specialInstructionsPlaceholder") as string
              }
              rows={2}
              value={specialInstructions}
            />
          </Box>
          {showMhiCheckbox ? (
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label={t("page.cart.confirmationDialog.mhiCheckboxLabel")}
                labelPlacement="end"
                onChange={(e) => setContainsMHI(e.target.checked)}
                value={containsMHI}
              />
            </Box>
          ) : null}

          {ncnrLineItems.length > 0 ? (
            <Box>
              <Box sx={{ fontWeight: "600", fontSize: "0.875rem", marginBottom: "0.5rem" }}>
                {t("page.cart.confirmationDialog.ncnrTerms.title")}
              </Box>
              <Box
                sx={{
                  fontSize: "0.75rem",
                  padding: "1rem",
                  maxHeight: "200px",
                  overflowY: "scroll",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Box>{t("page.cart.confirmationDialog.ncnrTerms.paragraph1")}</Box>
                <Box>{t("page.cart.confirmationDialog.ncnrTerms.paragraph2")}</Box>
                <Box>{t("page.cart.confirmationDialog.ncnrTerms.paragraph3")}</Box>
                <Box>{t("page.cart.confirmationDialog.ncnrTerms.paragraph4")}</Box>
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {t("page.cart.confirmationDialog.ncnrTerms.partNumber")}
                        </TableCell>
                        <TableCell>
                          {t("page.cart.confirmationDialog.ncnrTerms.quantity")}
                        </TableCell>
                        <TableCell>
                          {t("page.cart.confirmationDialog.ncnrTerms.description")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ncnrLineItems.map((li: ProductOrderLineItem) => (
                        <TableRow key={li.id}>
                          <TableCell>{li.organizationItem.code}</TableCell>
                          <TableCell>{li.quantity}</TableCell>
                          <TableCell>{li.organizationItem.name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
              <Box sx={{ paddingLeft: "1rem" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={t("page.cart.confirmationDialog.ncnrTerms.consentLabel")}
                  labelPlacement="end"
                  onChange={(e) => setNcnrConsent(e.target.checked)}
                  value={ncnrConsent}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          {t("cancel")}
        </Button>
        <Button
          autoFocus
          color="primary"
          disabled={ncnrLineItems?.length > 0 && !ncnrConsent}
          onClick={() => onSave(containsMHI, specialInstructions)}
          variant="contained"
        >
          {t("placeOrder")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
