import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { Divider } from "@mui/material"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import InputAdornment from "@mui/material/InputAdornment"
import Button from "@mui/material/Button"
import Note from "./Note"
import { ChecklistLineItemNote, User } from "../../types"
import { useAuth } from "../../context/AuthContext"

interface ChecklistLineNotesProps {
  readonly addNoteLoading?: boolean
  readonly deleteNoteLoadingIds?: string[]
  readonly editNoteLoadingIds?: string[]
  readonly notes: ChecklistLineItemNote[]
  readonly onAddNote: (note: string) => void
  readonly onEditNote?: (id: string, text: string) => void
  readonly onDeleteNote?: (id: string) => void
  readonly user: Partial<User>
}

function ChecklistLineNotes({
  addNoteLoading = false,
  deleteNoteLoadingIds = [],
  editNoteLoadingIds = [],
  notes,
  onAddNote,
  onEditNote,
  onDeleteNote,
  user,
}: ChecklistLineNotesProps) {
  const { t } = useTranslation()
  const { isImpersonating } = useAuth()
  const [note, setNote] = useState<string>("")

  return (
    <Box sx={classes.notesRootContainer}>
      <Box sx={classes.notesContentContainer}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {addNoteLoading ? <CircularProgress color="secondary" size={24} /> : null}
                </InputAdornment>
              ),
            }}
            disabled={addNoteLoading}
            fullWidth
            inputProps={{
              maxLength: 1000,
            }}
            maxRows="3"
            minRows="1"
            multiline
            name="note"
            onChange={(e: any) => setNote(e.target.value)}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault()
                onAddNote?.(note)
                setNote("")
              }
            }}
            placeholder={
              addNoteLoading
                ? `${t("saving")}...`
                : t("component.checklistTableRow.notePlaceholder")
            }
            sx={{ background: "#FFFFFF" }}
            value={note}
            variant="outlined"
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              disableElevation
              disabled={isImpersonating}
              onClick={() => {
                onAddNote?.(note)
                setNote("")
              }}
              sx={{ paddingLeft: "2rem", paddingRight: "2rem" }}
              variant="contained"
            >
              {t("save")}
            </Button>
          </Box>
        </Box>
        <Box sx={classes.noteThreadContainer}>
          {notes?.map((n: ChecklistLineItemNote, idx: number) => (
            <Box key={n.id}>
              <Note
                isAuthor={n.createdBy.id === user.id}
                isDeleting={deleteNoteLoadingIds.includes(n.id)}
                isUpdating={editNoteLoadingIds.includes(n.id)}
                key={n.id}
                note={n}
                onDelete={(id) => onDeleteNote?.(id)}
                onEdit={(id, text) => onEditNote?.(id, text)}
                timeZone={user.organization?.timeZone}
              />
              {idx !== notes.length - 1 && <Divider />}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

const classes = {
  notesRootContainer: {
    backgroundColor: "rgb(255, 200, 100, 0.10)",
    padding: "2.5rem",
    paddingTop: "1.5rem",
    paddingBottom: "0.625rem",
  },
  notesContentContainer: {
    width: "60%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
  },
  notesSectionHeader: {
    fontWeight: 500,
    fontSize: "0.9rem",
    color: "#747474",
    marginBottom: "0.625rem",
  },
  noteThreadContainer: {
    marginTop: "1rem",
  },
} as const

export default ChecklistLineNotes
