import React from "react"
import Box from "@mui/material/Box"
import type { PopoverProps } from "@mui/material/Popover"
import Button from "@mui/material/Button"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import CheckIcon from "@mui/icons-material/Check"
import Checkbox from "@mui/material/Checkbox"

export interface FilterMenuOption {
  value: string
  label: string
}

interface Props {
  readonly anchorEl?: PopoverProps["anchorEl"]
  readonly id: string
  readonly isOpen: boolean
  readonly label: string
  readonly mutuallyExclusive: boolean
  readonly onOpen: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  readonly onClickOption: (option: FilterMenuOption) => void
  readonly onClose: () => void
  readonly options: FilterMenuOption[]
  readonly selectedOptions: string[]
}

export default function FilterMenu({
  anchorEl,
  id,
  isOpen,
  label,
  mutuallyExclusive,
  onClickOption,
  onClose,
  onOpen,
  options,
  selectedOptions,
}: Props) {
  return (
    <>
      <Button
        aria-controls={isOpen ? `${id}-menu` : undefined}
        aria-expanded={isOpen ? "true" : undefined}
        aria-haspopup="true"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        id={`${id}-menu-button`}
        onClick={onOpen}
        variant="text"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Box>{label}</Box>
          {!mutuallyExclusive && selectedOptions.length > 0 ? (
            <Box
              component="span"
              sx={{
                display: "inline-block",
              }}
            >
              <Box
                component="span"
                sx={(theme) => {
                  return {
                    backgroundColor: theme.fielderColors.black,
                    boxSizing: "border-box",
                    color: theme.fielderColors.white,
                    fontWeight: "600",
                    fontSize: "0.75rem",
                    right: "8px",
                    textAlign: "center",
                    borderRadius: "8px",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0.10rem 0.375rem",
                  }
                }}
              >
                <Box
                  component="span"
                  sx={{
                    boxSizing: "border-box",
                    margin: 0,
                    padding: 0,
                    lineHeight: 1,
                    textAlign: "center",
                  }}
                >
                  {selectedOptions.length}
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id={`${id}-menu`}
        keepMounted
        onClose={onClose}
        open={isOpen}
        sx={{
          "& .MuiMenu-list": {
            minWidth: "8rem",
          },
          "& .MuiMenu-list .MuiCheckbox-root": {
            paddingLeft: 0,
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map((option: FilterMenuOption) => (
          <MenuItem key={option.value} onClick={() => onClickOption(option)} value={option.value}>
            {mutuallyExclusive ? (
              <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                <Box>
                  {selectedOptions.includes(option.value) ? (
                    <CheckIcon sx={{ fontSize: "1rem" }} />
                  ) : (
                    <Box sx={{ width: "1rem" }} />
                  )}
                </Box>
                <Box>{option.label}</Box>
              </Box>
            ) : (
              <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                <Checkbox checked={selectedOptions.includes(option.value)} sx={{ padding: 0 }} />
                <Box>{option.label}</Box>
              </Box>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
