import React from "react"
import UserAvatar from "../UserAvatar"
import { DispatchResource } from "../../types/appTypes"

interface Props {
  readonly resource: DispatchResource
}

function ResourceView({ resource }: Props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "0.625rem",
      }}
    >
      <UserAvatar size="24px" user={resource.user} />
      <span>{resource.name}</span>
    </div>
  )
}

export default ResourceView
