import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { formatPhoneNumber, NOT_SPECIFIED } from "../../../util"
import { User } from "../../../types"
import StaticField from "../../../components/StaticField"
import Button from "@mui/material/Button"

interface MyProfileBasicInfoStaticProps {
  userProfile: Partial<User>
  onClickChangePassword: () => void
}

function MyProfileBasicInfoStatic({
  userProfile,
  onClickChangePassword,
}: MyProfileBasicInfoStaticProps) {
  const { t } = useTranslation()

  return (
    <Box>
      <StaticField label={t("email")} showBottomDivider value={userProfile.email} />
      <StaticField
        label={t("firstName")}
        showBottomDivider
        value={userProfile.firstName || NOT_SPECIFIED}
      />
      <StaticField
        label={t("lastName")}
        showBottomDivider
        value={userProfile.lastName || NOT_SPECIFIED}
      />
      <StaticField
        label={t("mobilePhone")}
        showBottomDivider
        value={
          userProfile.mobilePhoneNumber
            ? formatPhoneNumber(userProfile.mobilePhoneNumber)
            : NOT_SPECIFIED
        }
      />
      <StaticField
        label={t("language")}
        value={
          userProfile.languageCode
            ? t(`languageCodeOptions.${userProfile.languageCode}`)
            : userProfile.organization?.languageCode
            ? t(`languageCodeOptions.${userProfile.organization.languageCode}`)
            : NOT_SPECIFIED
        }
      />
      <Button
        color="secondary"
        onClick={onClickChangePassword}
        sx={{ marginTop: "2rem" }}
        variant="outlined"
      >
        {t("changePassword")}
      </Button>
    </Box>
  )
}

export default MyProfileBasicInfoStatic
