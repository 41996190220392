import React from "react"
import { useTranslation } from "react-i18next"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"

function Spinner() {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        minHeight: "390px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="secondary" />
      <p>{t("loading")} ...</p>
    </Box>
  )
}

export default Spinner
