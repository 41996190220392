import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import MenuItem from "@mui/material/MenuItem"
import { DefaultPermission, SearchResultType } from "../../types"
import { useAuth } from "../../context/AuthContext"
import PERMISSION_REQUIREMENTS from "../../util/permissionRequirements"

interface SuggestedFiltersProps {
  onClickSuggestedFilter: (filter: string) => void
  searchTerm: string
}

export default function SuggestedFilters({
  onClickSuggestedFilter,
  searchTerm,
}: SuggestedFiltersProps) {
  const { t } = useTranslation()
  const { hasPermissions } = useAuth()

  let suggestedFilters = [
    hasPermissions?.(PERMISSION_REQUIREMENTS.customers.list)
      ? {
          id: SearchResultType.CUSTOMER,
          label: `${t("component.search.filter.customer.label")}:`,
          description: t("component.search.filter.customer.description"),
        }
      : null,
    hasPermissions?.([...PERMISSION_REQUIREMENTS.jobs.edit, DefaultPermission.ReadEstimate])
      ? {
          id: SearchResultType.ESTIMATE,
          label: `${t("component.search.filter.estimate.label")}:`,
          description: t("component.search.filter.estimate.description"),
        }
      : null,
    hasPermissions?.(PERMISSION_REQUIREMENTS.inventory.list)
      ? {
          id: SearchResultType.ITEM,
          label: `${t("component.search.filter.item.label")}:`,
          description: t("component.search.filter.item.description"),
        }
      : null,
    hasPermissions?.([...PERMISSION_REQUIREMENTS.jobs.edit, DefaultPermission.ReadInvoice])
      ? {
          id: SearchResultType.INVOICE,
          label: `${t("component.search.filter.invoice.label")}:`,
          description: t("component.search.filter.invoice.description"),
        }
      : null,
    hasPermissions?.(PERMISSION_REQUIREMENTS.jobs.edit)
      ? {
          id: SearchResultType.JOB,
          label: `${t("component.search.filter.job.label")}:`,
          description: t("component.search.filter.job.description"),
        }
      : null,
    hasPermissions?.(PERMISSION_REQUIREMENTS.productOrders)
      ? {
          id: SearchResultType.PRODUCT_ORDER,
          label: `${t("component.search.filter.order.label")}:`,
          description: t("component.search.filter.order.description"),
        }
      : null,
    hasPermissions?.(PERMISSION_REQUIREMENTS.tasks)
      ? {
          id: SearchResultType.TASK,
          label: `${t("component.search.filter.task.label")}:`,
          description: t("component.search.filter.task.description"),
        }
      : null,
  ].filter((s) => !!s)

  if (searchTerm.length > 0) {
    suggestedFilters = suggestedFilters.filter((f) => {
      return f.label.toLowerCase().includes(searchTerm.toLowerCase())
    })
  }

  if (suggestedFilters.length === 0) {
    return null
  }

  return (
    <>
      <Box
        sx={{
          padding: "0.5rem",
          paddingBottom: 0,
          paddingLeft: "1rem",
          textTransform: "uppercase",
          fontWeight: "600",
        }}
      >
        {t("component.search.suggestedFilters")}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          paddingTop: "0.25rem",
          paddingBottom: "0.25rem",
        }}
      >
        {suggestedFilters.map((s) => (
          <MenuItem
            key={s.label}
            onClick={() => onClickSuggestedFilter(s)}
            sx={{
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "normal",
              fontFamily: "inherit",
            }}
          >
            <Box
              sx={{
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "normal",
                fontFamily: "inherit",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#DBE0E4",
                  borderRadius: "4px",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  padding: "0.25rem 0.5rem",
                  color: "#000",
                }}
              >
                {s.label}
              </Box>
              <Box
                sx={{
                  fontSize: "0.9rem",
                }}
              >
                {s.description}
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Box>
    </>
  )
}
