import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { ProductOrderStatus } from "~/types"
import { getStatusBackgroundColor, getStatusForegroundColor } from "~/util/productOrderStatusColors"

interface Props {
  readonly status: ProductOrderStatus
}

function ProductOrderStatusPill({ status }: Props) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "600",
      }}
    >
      <span
        css={{
          padding: "0.15rem 0.45rem",
          borderRadius: "4px",
          backgroundColor: getStatusBackgroundColor(status),
          color: getStatusForegroundColor(status),
          fontSize: "12px",
          whiteSpace: "nowrap",
        }}
      >
        {t(`productOrderStatus.${status}`)}
      </span>
    </Box>
  )
}

export default ProductOrderStatusPill
