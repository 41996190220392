import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import MenuItem from "@mui/material/MenuItem"
import SearchResultItem from "./SearchResultItem"
import { SearchResult } from "../../types"
import SearchTipsItem from "./SearchTipsItem"
import NoResults from "./NoResults"
import SuggestedFilters from "./SuggestedFilters"

interface SearchResultsProps {
  getItemProps: (options: any) => any
  loading: boolean
  onClickSuggestedFilter: (filter: string) => void
  searchResults: SearchResult[]
  searchTerm: string
}

const ITEM_HEIGHT = 50

export default function SearchResults({
  getItemProps,
  loading,
  onClickSuggestedFilter,
  searchResults,
  searchTerm,
}: SearchResultsProps) {
  const { t } = useTranslation()

  return (
    <Paper
      sx={{
        width: "600px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          maxHeight: ITEM_HEIGHT * 8,
          overflowX: "scroll",
          overflowY: "scroll",
          flexGrow: 1,
        }}
      >
        <SuggestedFilters onClickSuggestedFilter={onClickSuggestedFilter} searchTerm={searchTerm} />
        {searchTerm.length > 0 && (
          <Box
            sx={{
              flexGrow: 1,
              flexShrink: 1,
              paddingTop: "0.25rem",
              paddingBottom: "0.25rem",
              minHeight: "1rem",
            }}
          >
            {searchResults.length > 0 ? (
              searchResults.map((searchResult) => (
                <MenuItem
                  key={searchResult.id}
                  sx={{
                    fontSize: "1rem",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontFamily: "inherit",
                  }}
                  {...getItemProps({ item: searchResult })}
                >
                  <SearchResultItem searchResult={searchResult} />
                </MenuItem>
              ))
            ) : !loading ? (
              <NoResults />
            ) : (
              <Box
                sx={{
                  fontSize: "0.8rem",
                  padding: "0.9rem",
                }}
              >
                {t("searching")}...
              </Box>
            )}
          </Box>
        )}
      </Box>
      <SearchTipsItem />
    </Paper>
  )
}
