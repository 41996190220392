import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import MoneyInput from "./MoneyInput"
import PositiveIntegerInput from "./PositiveIntegerInput"
import { asFloat, asInt, formatMoney } from "../util"
import { EstimateLineItemDetail, WorkOrderLineItemDetail, InvoiceLineItemDetail } from "../types"

interface Props {
  readonly allowBundleModifications: boolean
  readonly currencyCode: string
  readonly lineItemDetail: EstimateLineItemDetail | WorkOrderLineItemDetail | InvoiceLineItemDetail
  readonly onChangePrice: (price: number) => void
  readonly onChangeQuantity: (quantity: number) => void
  readonly onDelete: () => void
  readonly variant?: "ESTIMATE" | "ESTIMATE_TEMPLATE" | "WORK_ORDER" | "INVOICE"
}

function LineItemDetail({
  allowBundleModifications,
  currencyCode,
  lineItemDetail,
  onChangePrice,
  onChangeQuantity,
  onDelete,
  variant,
}: Props) {
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState<string>("")
  const [unitPrice, setUnitPrice] = useState<string>("")

  useEffect(() => {
    setQuantity(String(lineItemDetail.quantity ?? ""))
    setUnitPrice(String(lineItemDetail.unitPrice ?? ""))
  }, [lineItemDetail])

  return (
    <Box
      sx={[
        {
          display: "grid",
          gridTemplateColumns:
            variant === "WORK_ORDER"
              ? "50px minmax(100px, 3fr) minmax(100px, 3fr) minmax(50px, 1fr) 50px"
              : "50px minmax(100px, 4fr) minmax(100px, 4fr) repeat(3, minmax(50px, 1fr)) 2fr 50px",
          gap: "10px",
          paddingTop: "0.125rem",
          paddingBottom: "0.125rem",
          boxSizing: "border-box",
          minHeight: "2.5rem",
        },
      ]}
    >
      <Box sx={classes.detailLineItemCell} />
      <Box sx={[classes.detailLineItemCell, classes.productInfoCell]}>
        {lineItemDetail.organizationItem?.code ?? ""}
      </Box>
      <Box sx={[classes.detailLineItemCell, { paddingLeft: "0.5rem" }]}>
        {lineItemDetail.organizationItem?.name ?? ""}
      </Box>
      <Box
        sx={[
          classes.detailLineItemCell,
          classes.inputCell,
          !allowBundleModifications ? { justifyContent: "flex-end", paddingRight: "1rem" } : {},
        ]}
      >
        {allowBundleModifications ? (
          <PositiveIntegerInput
            aria-label={t("quantity") as string}
            customInput={TextField}
            inputProps={{ min: 1, maxLength: 12, style: { textAlign: "right" } }}
            onBlur={() => {
              onChangeQuantity(quantity)
            }}
            onChange={(e) => {
              setQuantity(e.target.value)
            }}
            placeholder={t("quantity") as string}
            required
            size="small"
            sx={[classes.noMargin, { textAlign: "right" }]}
            value={quantity}
            variant="outlined"
          />
        ) : (
          <Box>{quantity}</Box>
        )}
      </Box>
      {variant !== "WORK_ORDER" ? (
        <>
          {variant === "ESTIMATE_TEMPLATE" ? (
            <Box sx={[classes.detailLineItemCell, classes.calculatedValue]}>
              {formatMoney(currencyCode, asFloat(unitPrice) ?? 0)}
            </Box>
          ) : (
            <Box sx={[classes.detailLineItemCell, classes.inputCell]}>
              <MoneyInput
                aria-label={t("unitPrice")}
                currencyCode={currencyCode}
                customInput={TextField}
                max={9999999999}
                min={1}
                onBlur={() => {
                  onChangePrice(asFloat(unitPrice) ?? 0)
                }}
                onChange={(e) => {
                  setUnitPrice(e.target.value)
                }}
                placeholder={t("unitPrice")}
                required
                size="small"
                sx={[classes.noMargin, { textAlign: "right" }]}
                value={unitPrice}
                variant="outlined"
              />
            </Box>
          )}
          <Box sx={[classes.detailLineItemCell, classes.calculatedValue]}>
            {formatMoney(currencyCode, (asInt(quantity) ?? 0) * (asFloat(unitPrice) ?? 0))}
          </Box>
        </>
      ) : null}
      {variant !== "WORK_ORDER" && <Box />}
      <Box sx={[classes.detailLineItemCell, classes.actionCell]}>
        {allowBundleModifications ? (
          <IconButton
            aria-label="delete"
            onClick={() => onDelete(lineItemDetail.organizationItem.id)}
            sx={classes.deleteButton}
          >
            <DeleteIcon />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  )
}

const classes = {
  detailLineItemCell: {
    fontSize: "0.8rem",
    color: "theme.mutedText",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  productInfoCell: {
    borderLeft: "1px solid #cdcdcd",
    paddingLeft: "1rem",
    marginLeft: "0.5rem",
  },
  inputCell: {
    backgroundColor: "transparent",
    margin: 0,
  },
  actionCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.875rem",
  },
  deleteButton: {
    padding: "0.5rem",
  },
  noMargin: {
    margin: 0,
    width: "100%",
  },
  calculatedValue: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.85rem",
    fontWeight: 500,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "0.5rem",
  },
} as const

export default LineItemDetail
