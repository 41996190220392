/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from "react"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { User } from "../types"

/**
 * This component can handle uploading an avatar image for users.
 */

interface Props {
  readonly user?: Partial<User> | null
  readonly size: string
}

function UserAvatar({ user, size = "8rem" }: Props) {
  if (user?.avatar?.signedUrl) {
    return (
      <img
        src={user.avatar.signedUrl}
        style={{
          width: size,
          height: size,
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
    )
  } else {
    return <AccountCircleIcon sx={{ width: size, height: size, color: "#999" }} />
  }
}

export default UserAvatar
