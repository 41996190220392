import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Sidebar from "./Sidebar"
import { Item } from "../../../types"
import StaticField from "../../../components/StaticField"
import { formatDate, formatMoney, NOT_SPECIFIED } from "../../../util"

interface CatalogItemBasicInfoStaticProps {
  readonly item: Item
  readonly onImageUpload?: () => void
  readonly timeZone: string
}

function CatalogItemBasicInfoStatic({
  item,
  onImageUpload,
  timeZone,
}: CatalogItemBasicInfoStaticProps) {
  const { t } = useTranslation()

  return (
    <Box
      sx={(theme) => {
        return {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          flex: 1,
          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
          },
        }
      }}
    >
      <Sidebar item={item} onImageUpload={onImageUpload} />
      <Box
        sx={(theme) => {
          return {
            flex: 1,
            paddingLeft: "0",
            marginTop: "1rem",
            [theme.breakpoints.up("md")]: {
              padding: "0rem 1rem 1rem 2rem",
              marginTop: 0,
            },
          }
        }}
      >
        <StaticField label={t("region")} showBottomDivider value={item.region?.name} />
        {item.isRestricted ? (
          <StaticField
            label={t("restrictedTo")}
            showBottomDivider
            value={<Box>{item.restrictedTo?.map((o) => <Box>{o.name}</Box>)}</Box>}
          />
        ) : null}
        <StaticField label={t("itemCode")} showBottomDivider value={item.code} />
        <StaticField label={t("name")} showBottomDivider value={item.name} />
        <StaticField label={t("description")} showBottomDivider value={item.description} />
        <StaticField label={t("category")} showBottomDivider value={item.category} />
        <StaticField
          label={t("type")}
          showBottomDivider
          value={t(`itemTypeOptions.${item.type}`)}
        />
        <StaticField
          label={t("stockType")}
          showBottomDivider
          value={t(`stockTypeOptions.${item.stockType}`)}
        />
        <StaticField label={t("leadTimeDays")} showBottomDivider value={item.leadTimeDays} />
        <StaticField
          label={t("isIntendedForResale")}
          showBottomDivider
          value={item.isIntendedForResale ? t("yes") : t("no")}
        />
        <StaticField
          label={t("msrp")}
          showBottomDivider
          value={item.msrp ? formatMoney(item.region.currencyCode, item.msrp) : NOT_SPECIFIED}
        />
        <StaticField
          label={t("wholesalePrice")}
          showBottomDivider
          value={
            item.wholesalePrice
              ? formatMoney(item.region.currencyCode, item.wholesalePrice)
              : NOT_SPECIFIED
          }
        />
        <StaticField
          label={t("wholesalePriceExpirationDate")}
          showBottomDivider
          value={formatDate(
            item.wholesalePriceExpirationDate,
            t("format:dateFormat.short"),
            timeZone
          )}
        />
        <StaticField label={t("packSize")} showBottomDivider value={item.packSize} />
        <StaticField
          label={t("isPackBreakable")}
          showBottomDivider
          value={item.isPackBreakable ? t("yes") : t("no")}
        />
        <StaticField label={t("palletQty")} showBottomDivider value={item.palletQty} />
        <StaticField
          label={t("isCancelable")}
          showBottomDivider={false}
          value={item.isCancelable ? t("yes") : t("no")}
        />
      </Box>
    </Box>
  )
}

export default CatalogItemBasicInfoStatic
