import gql from "graphql-tag"

export default gql`
  fragment FullOrganizationItem on OrganizationItem {
    id
    code
    name
    description
    category
    isIntendedForResale
    isAvailable
    isActive
    isArchived
    isCancelable
    isTracked
    type
    stockType
    msrp
    wholesalePrice
    wholesalePriceExpirationDate
    averagePurchaseCost
    leadTimeDays
    palletQty
    packSize
    isPackBreakable
    qtyOnHand
    committedQty
    orderedQty
    reorderPoint
    reorderQty
    isDynamicallyPriced
    unitSalePrice
    unitSalePriceMarkup
    isTopLevel
    showComponentsOnTransactions
    components {
      id
      number
      organizationItem {
        id
        code
        name
        unitSalePrice
      }
      enableUnitSalePriceOverride
      quantity
      unitSalePrice
    }
    attachments {
      id
      contentType
      objectName
      name
      signedUrl
      createdAt
    }
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    updatedAt
    updatedBy {
      id
      firstName
      lastName
    }
  }
`
