import React from "react"
import { TimeFrameOption } from "../../types/appTypes"
import { WEEK_MODE_CELL_WIDTH } from "./Constants"

interface ResourcerCalendarRowProps {
  readonly height?: number
  readonly intervalDuration?: number
  readonly intervalLength?: number
  readonly mode: TimeFrameOption
}

function ResourceCalendarRow({
  height = 55,
  intervalDuration = 15,
  intervalLength = 25,
  mode,
}: ResourcerCalendarRowProps) {
  const numberOfIntervals = mode === TimeFrameOption.DAY ? 24 * (60 / intervalDuration) : 7
  const heightPx = `${height}px`
  const intervalLengthPx = `${intervalLength}px`

  function getCellStyle(i: number): React.CSSProperties {
    return mode === TimeFrameOption.DAY
      ? {
          height: heightPx,
          flexGrow: 1,
          width: intervalLengthPx,
          minWidth: intervalLengthPx,
          borderBottom: "2px solid #EFEFEF",
          borderRight: i % 4 === 3 ? "1px solid #D0D0D0" : "1px dotted #EFEFEF",
          boxSizing: "border-box",
        }
      : {
          height: heightPx,
          width: `${WEEK_MODE_CELL_WIDTH}px`,
          borderBottom: "2px solid #EFEFEF",
          borderRight: "1px solid #D0D0D0",
          boxSizing: "border-box",
        }
  }

  return (
    <div
      style={{
        height: heightPx,
        minHeight: heightPx,
        maxHeight: heightPx,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        boxSizing: "border-box",
      }}
    >
      {[...Array(numberOfIntervals).keys()].map((i) => (
        <div key={i} style={getCellStyle(i)} />
      ))}
    </div>
  )
}

export default ResourceCalendarRow
