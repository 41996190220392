import React from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import MenuItem from "@mui/material/MenuItem"
import FieldSpinner from "./FieldSpinner"
import SelectorField, { SelectorOption } from "./SelectorField"
import { Region } from "../types"

const ALL_REGIONS = gql`
  query AllRegions {
    allRegions {
      id
      name
      code
      currencyCode
      languageCode
    }
  }
`

interface RegionSelectProps {
  readonly disabled?: boolean
  readonly error?: boolean
  readonly label?: string
  readonly name?: string
  readonly onBlur?: () => void
  readonly onChange: (value: Region | null) => void
  readonly required?: boolean
  readonly sx?: SxProps<Theme>
  readonly value?: Region | undefined | null
}

function RegionSelect({
  disabled,
  error: hasError,
  label,
  name,
  onBlur,
  onChange,
  required,
  sx,
  value,
}: RegionSelectProps) {
  const { t } = useTranslation()

  const { loading, error, data } = useQuery(ALL_REGIONS, {
    fetchPolicy: "cache-and-network",
  })

  if (loading) {
    return (
      <Box sx={sx}>
        <FieldSpinner message={`${t("loadingRegions")}...`} />
      </Box>
    )
  }

  if (error) return <Box sx={sx}>{`Error! ${error.message}`}</Box>

  const regions = data.allRegions
  const regionOptions: SelectorOption[] = regions.map((n: Region) => ({
    id: n.id,
    name: n.name,
  })) as SelectorOption[]

  return (
    <SelectorField
      disabled={disabled}
      error={hasError}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={(val) => {
        if (val) {
          const region = regions.find((o: Region) => o.id === val.id)
          onChange(region)
        } else {
          onChange(null)
        }
      }}
      options={regionOptions}
      renderOption={(selectorOption: SelectorOption) => {
        return (
          <MenuItem key={selectorOption.id} value={selectorOption.id}>
            <div>{selectorOption.name}</div>
          </MenuItem>
        )
      }}
      renderValue={(selected) => {
        const val = regionOptions.find((o: SelectorOption) => o.id === selected)
        if (!val) {
          return <div>{t("error.invalidOptionSelected")}</div>
        } else {
          return <div>{val.name}</div>
        }
      }}
      required={required}
      sx={sx}
      value={value?.id ?? ""}
    />
  )
}

export default React.memo(RegionSelect)
