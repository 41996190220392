import Box from "@mui/material/Box"
import React from "react"

interface Props {
  title: string
  description: string
}

function CategoryHeader({ title, description }: Props) {
  return (
    <Box
      sx={(theme) => {
        return {
          gridColumn: "1 / -1",
          gridRow: "auto / span 2",
          [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 9",
            gridRow: "auto / span 2",
          },
        }
      }}
    >
      <Box sx={{ fontSize: "1rem", fontWeight: "600" }}>{title}</Box>
      <Box sx={{ fontSize: "0.8rem", color: (theme) => theme.fielderColors.mutedText }}>
        {description}
      </Box>
    </Box>
  )
}

export default CategoryHeader
