import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import BasicInfoStatic from "./BasicInfoStatic"
import StaticField from "../../../components/StaticField"
import Sidebar from "./Sidebar"
import { formatMoney } from "../../../util"
import { OrganizationItem } from "../../../types"
import HelperTextContainer from "./HelperTextContainer"

interface ServiceStaticProps {
  readonly currencyCode: string
  readonly item: OrganizationItem
  readonly onImageUpload?: () => void
}

function ServiceStatic({ currencyCode, item, onImageUpload }: ServiceStaticProps) {
  const { t } = useTranslation()

  return (
    <Box
      sx={(theme) => {
        return {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          flex: 1,
          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
          },
        }
      }}
    >
      <Sidebar item={item} onImageUpload={onImageUpload} />
      <Box
        sx={(theme) => {
          return {
            flex: 1,
            padding: "0rem 1rem 2rem 0",
            marginTop: "1rem",
            [theme.breakpoints.up("md")]: {
              paddingLeft: "2rem",
              paddingBottom: "1rem",
              marginTop: 0,
            },
          }
        }}
      >
        <BasicInfoStatic item={item} showBottomDivider />
        <Box>
          <StaticField
            label={t("status")}
            renderHelpText={() => {
              return (
                <HelperTextContainer>
                  <Box>{t(`itemStatus.helperText`)}</Box>
                </HelperTextContainer>
              )
            }}
            showBottomDivider
            value={t(`itemStatus.label.${item.isActive ? "ACTIVE" : "INACTIVE"}`)}
          />
        </Box>
        <StaticField
          label={t("unitSalePrice")}
          showBottomDivider={false}
          value={formatMoney(currencyCode, item.unitSalePrice)}
        />
      </Box>
    </Box>
  )
}

export default ServiceStatic
