import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IconButton from "@mui/material/IconButton"
import { DefaultPermission, OrganizationItemList } from "../../../types"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { useTranslation } from "react-i18next"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import CircularProgress from "@mui/material/CircularProgress"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import { useAuth } from "../../../context/AuthContext"
import CreateListDialog from "./CreateListDialog"
import EditListDialog from "./EditListDialog"

const DELETE_ORGANIZATION_ITEM_LIST = gql`
  mutation DeleteOrganizationItemList($id: ID!) {
    deleteOrganizationItemList(id: $id) {
      organizationItemList {
        id
        name
      }
    }
  }
`

interface Props {
  itemLists: OrganizationItemList[]
  onListCreated?: (list: OrganizationItemList) => void
  onListDeleted?: (list: OrganizationItemList) => void
  onListUpdated?: (list: OrganizationItemList) => void
  onSelectList: (listId: string) => void
  selectedList?: OrganizationItemList
}

export default function ItemLists({
  itemLists,
  onListCreated,
  onListDeleted,
  onListUpdated,
  onSelectList,
  selectedList,
}: Props) {
  const { t } = useTranslation()
  const { hasPermissions } = useAuth()
  const [activeList, setActiveList] = useState<OrganizationItemList>(itemLists[0])
  const [createListDialogOpen, setCreateListDialogOpen] = useState<boolean>(false)
  const [editListDialogOpen, setEditListDialogOpen] = useState<boolean>(false)
  const [deleteListConfirmationDialogOpen, setDeleteListConfirmationDialogOpen] =
    useState<boolean>(false)
  const [listActionsMenuAnchorEl, setListActionsMenuAnchorEl] = useState(null)
  const showListActionsMenu = Boolean(listActionsMenuAnchorEl)

  const [deleteOrganizationItemList, { loading: deleteListLoading }] = useMutation(
    DELETE_ORGANIZATION_ITEM_LIST,
    {
      onCompleted: (data) => {
        setDeleteListConfirmationDialogOpen(false)
        onListDeleted?.(data.deleteOrganizationItemList.organizationItemList)
      },
    }
  )

  return (
    <>
      <Box
        sx={(theme) => {
          return {
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            padding: "0.5rem",
            flex: "1",
            backgroundColor: "#fafafa",
            border: "1px solid #efefef",
            borderRadius: "4px",
            width: "300px",
            maxWidth: "300px",
            minWidth: "300px",
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ fontWeight: "600" }}>{t("itemLists")}</Box>
          {hasPermissions?.([DefaultPermission.UpdateOrganizationItemList]) ? (
            <Button
              aria-label={t("createItemList") as string}
              color="primary"
              data-testid="createItemListButton"
              onClick={() => {
                setCreateListDialogOpen(true)
              }}
              sx={{
                "& svg": {
                  fontSize: "1.0rem",
                },
              }}
              variant="text"
            >
              <AddIcon fontSize="small" />
              <div>{t("createItemList")}</div>
            </Button>
          ) : null}
        </Box>
        <Box sx={{ overflowY: "scroll" }}>
          {itemLists.map((list) => (
            <Box
              key={list.id}
              onClick={() => {
                setActiveList(list)
                onSelectList(list.id)
              }}
              sx={(theme) => {
                return {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderRadius: "4px",
                  padding: "0.625rem 0.5rem 0.625rem 1rem",
                  alignItems: "center",
                  height: "3rem",
                  backgroundColor:
                    selectedList?.id === list.id || (!selectedList?.id && list.id === "ALL")
                      ? theme.fielderColors.brandPrimaryFaded
                      : "transparent",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.fielderColors.lightGrey,
                  },
                }
              }}
            >
              <Box>{list.name}</Box>
              {list.id === activeList.id && list.id !== "ALL" ? (
                <IconButton
                  aria-label={t("component.organizationItemList.menuButtonLabel") as string}
                  onClick={(e) => setListActionsMenuAnchorEl(e.currentTarget)}
                >
                  <MoreVertIcon fontSize="inherit" />
                </IconButton>
              ) : null}
            </Box>
          ))}
        </Box>
      </Box>
      <Menu
        anchorEl={listActionsMenuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        elevation={2}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onClose={() => setListActionsMenuAnchorEl(null)}
        open={showListActionsMenu}
        sx={{
          "& .MuiPaper-root": {
            minWidth: 150,
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          aria-label={t(`component.organizationItemList.manageList`) as string}
          onMouseDown={() => {
            setListActionsMenuAnchorEl(null)
            setEditListDialogOpen(true)
          }}
          sx={(theme) => {
            return {
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              fontSize: "0.875rem",
              color: theme.fielderColors.text,
            }
          }}
        >
          <Box>{t(`component.organizationItemList.manageList`)}</Box>
        </MenuItem>
        <MenuItem
          aria-label={t(`component.organizationItemList.deleteList`) as string}
          onMouseDown={() => {
            setListActionsMenuAnchorEl(null)
            activeList.id !== "ALL" && setDeleteListConfirmationDialogOpen(true)
          }}
          sx={(theme) => {
            return {
              display: "flex",
              flexDirection: "row",
              fontSize: "0.875rem",
              color: theme.fielderColors.text,
            }
          }}
        >
          <Box>{t(`component.organizationItemList.deleteList`)}</Box>
        </MenuItem>
      </Menu>
      <CreateListDialog
        onCancel={() => setCreateListDialogOpen(false)}
        onClose={() => setCreateListDialogOpen(false)}
        onListCreated={(list) => {
          setCreateListDialogOpen(false)
          onListCreated?.(list)
        }}
        open={createListDialogOpen}
      />

      {deleteListConfirmationDialogOpen && activeList ? (
        <Dialog
          aria-describedby="delete-list-confirmation-dialog-description"
          aria-labelledby="delete-list-confirmation-dialog-title"
          onClose={() => setDeleteListConfirmationDialogOpen(false)}
          open={deleteListConfirmationDialogOpen}
        >
          <DialogTitle id="delete-list-confirmation-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-list-confirmation-dialog-description">
              {t("component.organizationItemList.deleteListConfirmationDialog.confirmationPrompt", {
                name: activeList.name,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={deleteListLoading}
              onClick={() => setDeleteListConfirmationDialogOpen(false)}
            >
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() =>
                deleteOrganizationItemList({
                  variables: { id: activeList.id },
                })
              }
            >
              {deleteListLoading ? (
                <CircularProgress color="secondary" size={20} thickness={6.0} />
              ) : (
                <Box>{t("yes")}</Box>
              )}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      {selectedList ? (
        <EditListDialog
          list={selectedList as OrganizationItemList}
          onCancel={() => setEditListDialogOpen(false)}
          onClose={() => setEditListDialogOpen(false)}
          onListUpdated={(list) => {
            setEditListDialogOpen(false)
            onListUpdated?.(list)
          }}
          open={editListDialogOpen}
        />
      ) : null}
    </>
  )
}
