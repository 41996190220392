import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import EditIcon from "@mui/icons-material/EditOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import CircularProgress from "@mui/material/CircularProgress"
import { formatDate, formatPersonName } from "../../util"
import { ChecklistLineItemNote } from "../../types"
import FielderIconButton from "../FielderIconButton"

interface NoteProps {
  isAuthor: boolean
  isDeleting: boolean
  isUpdating: boolean
  note: ChecklistLineItemNote
  onDelete: (id: string) => void
  onEdit: (id: string, text: string) => void
  timeZone?: string | null | undefined
}

function Note({
  isAuthor,
  isDeleting,
  isUpdating,
  note,
  onDelete,
  onEdit,
  timeZone = "Etc/UTC",
}: NoteProps) {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [noteText, setNoteText] = useState<string>(note.text)
  const deleteNoteLabel = t("component.checklistLineItemNote.deleteNote")
  const editNoteLabel = t("component.checklistLineItemNote.editNote")

  function renderActionButtons() {
    return (
      <Box sx={{ display: "flex", gap: "0.25rem" }}>
        {isDeleting || isUpdating ? (
          <CircularProgress
            color="secondary"
            size={14}
            sx={{ marginRight: "0.45rem" }}
            thickness={6.0}
          />
        ) : (
          <>
            <FielderIconButton
              aria-label={editNoteLabel}
              disabled={isUpdating}
              onClick={() => {
                setNoteText(note.text)
                setEditMode(true)
              }}
              size="small"
              title={editNoteLabel}
            >
              <EditIcon sx={classes.buttonIcon} />
            </FielderIconButton>
            <FielderIconButton
              aria-label={deleteNoteLabel}
              disabled={isUpdating}
              onClick={() => onDelete(note.id)}
              size="small"
              title={deleteNoteLabel}
            >
              <DeleteIcon sx={classes.buttonIcon} />
            </FielderIconButton>
          </>
        )}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {editMode || isUpdating ? (
        <Box sx={{ display: "flex", flexGrow: 1, gap: "1rem", padding: "0.625rem" }}>
          <TextField
            disabled={isUpdating}
            fullWidth
            inputProps={{
              maxLength: 1000,
            }}
            maxRows="3"
            minRows="1"
            multiline
            onChange={(e) => setNoteText(e.target.value)}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault()
                onEdit?.(note.id, noteText)
                // setNoteText("")
                setEditMode(false)
              }
            }}
            placeholder={t("component.checklistTableRow.notePlaceholder") as string}
            sx={{ backgroundColor: "#fff" }}
            value={noteText}
          />
          <Box sx={{ display: "flex", gap: "0.75rem", alignItems: "center" }}>
            <Button
              disabled={isUpdating}
              onClick={(e) => {
                e.preventDefault()
                setEditMode(false)
                // setNoteText("")
              }}
              size="small"
              sx={{ minHeight: "2rem" }}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disableElevation
              disabled={isUpdating || noteText.length === 0}
              onClick={(e) => {
                e.preventDefault()
                onEdit?.(note.id, noteText)
                // setNoteText("")
                setEditMode(false)
              }}
              size="small"
              sx={{ minHeight: "2rem" }}
              variant="contained"
            >
              {isUpdating ? (
                <CircularProgress color="secondary" size={14} thickness={6.0} />
              ) : (
                <Box>{t("save")}</Box>
              )}
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0.625rem",
            }}
          >
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "baseline",
                [theme.breakpoints.down(330)]: {
                  flexDirection: "column",
                  justifyContent: "flex-start",
                },
              })}
            >
              <Box
                sx={{
                  fontWeight: 700,
                  marginRight: "1rem",
                }}
              >
                {formatPersonName(note.createdBy)}
              </Box>
              <Box
                sx={{
                  fontSize: "0.75rem",
                }}
              >
                {formatDate(note.createdAt, t("format:dateFormat.shortWithTime"), timeZone)}
              </Box>
            </Box>
            <Box sx={{ fontSize: "0.875rem" }}>{note.text}</Box>
          </Box>
          {isAuthor ? renderActionButtons() : null}
        </>
      )}
    </Box>
  )
}

const classes = {
  buttonIcon: {
    fontSize: "1rem",
  },
} as const

export default Note
