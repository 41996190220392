import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { gql, useQuery, useMutation, ApolloError } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableHead from "@mui/material/TableHead"
import CircularProgress from "@mui/material/CircularProgress"
import AddIcon from "@mui/icons-material/Add"
import SyncIcon from "@mui/icons-material/Sync"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import SectionHeader from "../../../components/SectionHeader"
import SectionContent from "../../../components/SectionContent"
import Seo from "../../../components/Seo"
import SnackbarMessage from "../../../components/SnackbarMessage"
import TaxRateGroupDialog from "./components/TaxRateGroupDialog"
import TaxRateGroupRow from "./components/TaxRateGroupRow"
import { parseGraphQLErrorCode, SETTINGS } from "../../../util"
import { useAuth } from "../../../context/AuthContext"
import TaxRateGroupDetails from "../../../queries/fragments/taxRateGroupDetails"
import { GET_ORGANIZATION_BY_ID_WITH_PLUGINS } from "../../../queries/getOrganizationById"
import {
  DefaultPermission,
  OrganizationPlugin,
  PluginProviderCategory,
  Snack,
  TaxRateGroup,
} from "../../../types"

const ALL_TAX_RATE_GROUPS = gql`
  query AllTaxRateGroups {
    allTaxRateGroups {
      edges {
        node {
          ...TaxRateGroupDetails
        }
      }
    }
  }
  ${TaxRateGroupDetails}
`

const SYNC_TAX_RATE_GROUPS = gql`
  mutation SyncTaxRateGroups {
    syncTaxRateGroups {
      success
    }
  }
`

const CREATE_TAX_RATE_GROUP = gql`
  mutation CreateTaxRateGroup($name: String!, $taxRates: [TaxRateInput!]!) {
    createTaxRateGroup(input: { name: $name, taxRates: $taxRates }) {
      taxRateGroup {
        ...TaxRateGroupDetails
      }
    }
  }
  ${TaxRateGroupDetails}
`

const EDIT_TAX_RATE_GROUP = gql`
  mutation EditTaxRateGroup(
    $id: ID!
    $name: String
    $isArchived: Boolean
    $taxRates: [TaxRateInput]
  ) {
    editTaxRateGroup(
      input: { id: $id, name: $name, isArchived: $isArchived, taxRates: $taxRates }
    ) {
      taxRateGroup {
        ...TaxRateGroupDetails
      }
    }
  }
  ${TaxRateGroupDetails}
`

function TaxRateGroups() {
  const { t } = useTranslation()
  const [snack, setSnack] = useState<Snack>()
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [selectedTaxRateGroup, setSelectedTaxRateGroup] = useState<TaxRateGroup | null>(null)
  const [openArchiveAlertDialog, setOpenArchiveAlertDialog] = useState<boolean>(false)
  const { user, hasPermissions } = useAuth()
  const userOrgId = user?.organization?.id

  const { loading: orgLoading, data: orgData } = useQuery(GET_ORGANIZATION_BY_ID_WITH_PLUGINS, {
    variables: {
      id: userOrgId,
    },
    fetchPolicy: "cache-and-network",
  })

  const [syncTaxRateGroups, { loading: syncLoading }] = useMutation(SYNC_TAX_RATE_GROUPS, {
    onCompleted: () => {
      setSnack({
        messageKey: "component.taxRateGroupList.sync.success",
        variant: "success",
      })
      refetch()
    },
    onError: (error) => {
      Sentry.captureException(error)
      setSnack({
        messageKey: "component.taxRateGroupList.sync.error",
        variant: "error",
      })
    },
  })

  const {
    loading: groupsLoading,
    data: groupsData,
    refetch,
  } = useQuery(ALL_TAX_RATE_GROUPS, {
    fetchPolicy: "cache-and-network",
  })

  const mutationOptions = {
    onCompleted: () => {
      setInEditMode(false)
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      refetch()
    },
    onError: (error: ApolloError) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  }
  const [editTaxRateGroup, { loading: editTaxRateGroupLoading }] = useMutation(
    EDIT_TAX_RATE_GROUP,
    mutationOptions
  )
  const [createTaxRateGroup, { loading: createTaxRateGroupLoading }] = useMutation(
    CREATE_TAX_RATE_GROUP,
    mutationOptions
  )

  const accountingPlugin = orgData?.getOrganizationById?.plugins?.find(
    (p: OrganizationPlugin) => p.pluginProvider.category === PluginProviderCategory.ACCOUNTING
  )
  const taxRateGroups = groupsData?.allTaxRateGroups?.edges?.map((e) => e.node) || []
  const loading = groupsLoading || orgLoading

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="taxRateGroups"
          />
          <Paper sx={classes.widgetContainer}>
            <SectionHeader>
              <label>{t("taxRateGroups")}</label>
              {!accountingPlugin && hasPermissions?.([DefaultPermission.CreateTaxRateGroup]) ? (
                <Button
                  aria-label={t("addTaxRateGroup") as string}
                  color="primary"
                  data-testid="createTaxRateGroupButton"
                  onClick={() => {
                    setInEditMode(true)
                    setSelectedTaxRateGroup(null)
                  }}
                  sx={classes.primaryActionButton}
                  variant="contained"
                >
                  <AddIcon />
                  <Box>{t("addTaxRate")}</Box>
                </Button>
              ) : null}
              {accountingPlugin && hasPermissions?.([DefaultPermission.SyncTaxRateGroup]) ? (
                <Button
                  aria-label={t(`syncWithPlugin.${accountingPlugin.pluginProvider.id}`) as string}
                  color="primary"
                  data-testid="syncTaxRateGroupsButton"
                  disabled={syncLoading}
                  onClick={() => syncTaxRateGroups()}
                  sx={classes.primaryActionButton}
                  variant="contained"
                >
                  {syncLoading ? (
                    <CircularProgress color="secondary" size={20} thickness={6.0} />
                  ) : null}
                  {!syncLoading && (
                    <>
                      <SyncIcon />
                      <Box>{t(`syncWithPlugin.${accountingPlugin.pluginProvider.id}`)}</Box>
                    </>
                  )}
                </Button>
              ) : null}
            </SectionHeader>
            <Divider />
            <SectionContent>
              {loading ? (
                <Box sx={classes.spinnerContainer}>
                  <CircularProgress color="secondary" size={20} thickness={6.0} />
                </Box>
              ) : null}
              {!loading && taxRateGroups.length === 0 && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ border: "none" }}>
                    <Box
                      sx={{
                        color: (theme) => theme.fielderColors.mutedText,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "1.125rem",
                        weight: "bold",
                        padding: "2.5rem",
                      }}
                    >
                      {t("page.settings.taxRateGroups.zeroState")}
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {!loading && taxRateGroups.length > 0 && (
                <TableContainer>
                  <Table aria-label={t("taxRates")} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={classes.tableHeaderCell} />
                        <TableCell sx={classes.tableHeaderCell} width="50%">
                          {t("name")}
                        </TableCell>
                        <TableCell sx={classes.tableHeaderCell} width="45%">
                          {t("totalTaxRate")}
                        </TableCell>
                        <TableCell sx={classes.tableHeaderCell} width="5%" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {taxRateGroups?.map((taxRateGroup: TaxRateGroup) => {
                        return (
                          <TaxRateGroupRow
                            key={taxRateGroup.id}
                            onClickArchive={() => {
                              setOpenArchiveAlertDialog(true)
                              setSelectedTaxRateGroup(taxRateGroup)
                            }}
                            onClickEdit={() => {
                              setSelectedTaxRateGroup(taxRateGroup)
                              setInEditMode(true)
                            }}
                            taxRateGroup={taxRateGroup}
                          />
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </SectionContent>
          </Paper>
        </Box>
      </MainLayout>
      {inEditMode ? (
        <TaxRateGroupDialog
          loading={createTaxRateGroupLoading || editTaxRateGroupLoading}
          onCancel={() => {
            setInEditMode(false)
            setSelectedTaxRateGroup(null)
          }}
          onSave={(taxRateGroup) => {
            if (selectedTaxRateGroup?.id) {
              if (selectedTaxRateGroup.isLocked) {
                return
              }
              editTaxRateGroup({
                variables: {
                  ...taxRateGroup,
                  id: selectedTaxRateGroup.id,
                  isArchived: false, // don't allow archiving via the dialog
                },
              })
            } else {
              createTaxRateGroup({
                variables: {
                  ...taxRateGroup,
                },
              })
            }
          }}
          open={inEditMode}
          taxRateGroup={selectedTaxRateGroup}
        />
      ) : null}
      {openArchiveAlertDialog ? (
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={() => setOpenArchiveAlertDialog(false)}
          open={openArchiveAlertDialog}
        >
          <DialogTitle id="alert-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("component.taxRateGroupList.archiveConfirmationPrompt", {
                name: selectedTaxRateGroup?.name,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpenArchiveAlertDialog(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                setOpenArchiveAlertDialog(false)
                selectedTaxRateGroup &&
                  editTaxRateGroup({
                    variables: {
                      id: selectedTaxRateGroup.id,
                      name: selectedTaxRateGroup.name,
                      isArchived: true,
                      taxRates: selectedTaxRateGroup.taxRates.map((tr) => ({
                        id: tr.id,
                        name: tr.name,
                        rate: tr.rate,
                      })),
                    },
                  })
              }}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
  },
  spinnerContainer: {
    padding: "6.25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  primaryActionButton: {
    fontWeight: "bold",
    "& svg": {
      fontSize: "1.0rem",
    },
    "& div": {
      marginLeft: "0.625rem",
      marginRight: "0.625rem",
    },
  },
  widgetContainer: {
    maxWidth: "700px",
    marginBottom: "1.25rem",
    marginTop: "3.75rem",
  },
  tableHeaderCell: {
    backgroundColor: "#fff",
  },
}

export default TaxRateGroups
