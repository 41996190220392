import gql from "graphql-tag"
import FullAddress from "./fullAddress"

export default gql`
  fragment FullCustomer on Customer {
    id
    name
    type
    numberOfJobs
    totalSales
    address {
      ...FullAddress
    }
    languageCode
    email
    phoneNumber
    notes {
      id
      text
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      updatedAt
    }
    isArchived
    flags {
      id
      name
      description
      colorCode
    }
    jobs {
      id
      number
      createdAt
      address {
        addressString
      }
      workflowStep {
        id
        jobStatus {
          id
          name
          lightColor
          mediumColor
          darkColor
        }
      }
    }
    contacts(contactFilter: {
      includeArchived: false
    }) {
      id
      firstName
      lastName
      jobTitle
      phoneNumber
      email
      address {
        addressString
      }
      canBeJobSiteContact
      canBeBillingContact
      isArchived
      createdAt
      updatedAt
    }
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    updatedAt
    updatedBy {
      id
      firstName
      lastName
    }
  }
  ${FullAddress}
`
