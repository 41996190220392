import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import gql from "graphql-tag"
import dayjs, { type Dayjs } from "dayjs"
import { nanoid } from "nanoid"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Alert from "@mui/material/Alert"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import Paper from "@mui/material/Paper"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import AddIcon from "@mui/icons-material/Add"

import CustomerContactSelect from "./CustomerContactSelect"
import CreateContactDialog from "./CreateContactDialog"
import LoadFromWorkOrderDialog from "./LoadFromWorkOrderDialog"
import { CREATE_INVOICE } from "~/queries/createInvoice"
import { EDIT_INVOICE } from "~/queries/editInvoice"
import {
  asFloat,
  asInt,
  calculateLineSubtotal,
  formatAddress,
  parseGraphQLErrorCode,
  isBlank,
  createTransactionLineItemFormInput,
  updateTransactionLineItem,
  sanitizeBillingContact,
  sanitizeLineItems,
  getJobDocumentDisplayNumber,
  formatDiscountValueForInput,
  formatDate,
} from "~/util"
import FielderTextField from "~/components/FielderTextField"
import LineItem from "~/components/LineItem"
import SelectorField from "~/components/SelectorField"
import TransactionFooter from "~/components/TransactionFooter"
import {
  DefaultPermission,
  DiscountType,
  Estimate,
  EstimateLineItem,
  Invoice,
  InvoiceCustomerVisibility,
  InvoiceLineItem,
  NetTerms,
  User,
  WorkOrderLineItem,
} from "~/types"
import SaveButton from "~/components/SaveButton"
import { useAuth } from "~/context/AuthContext"
import LoadFromEstimateDialog from "./LoadFromEstimateDialog"
import FieldHelperText from "~/components/FieldHelperText"
import useGetUserOrganizationPreferences from "~/hooks/useGetUserOrganizationPreferences"
import InvoiceCustomerVisibilitySelect, {
  InvoiceCustomerVisibilitySelectOption,
} from "~/components/InvoiceCustomerVisibilitySelect"
import NetTermsSelect from "~/components/NetTermsSelect"

const TODAY = dayjs().startOf("day")
const dialogBackgroundColor = "#FFFFFF"

function getDaysFromNetTerms(netTerms: NetTerms): number {
  switch (netTerms) {
    case NetTerms.DUE_ON_RECEIPT:
      return 0
    case NetTerms.NET_15:
      return 15
    case NetTerms.NET_30:
      return 30
    case NetTerms.NET_60:
      return 60
    case NetTerms.NET_90:
      return 90
    default:
      return 0
  }
}

interface FieldValidationError {
  title: string
  message: string
}

interface ValidationErrors {
  dueDate: FieldValidationError | null
  lineItems: FieldValidationError | null
}

const GET_OPTIONS_AND_DEFAULTS = gql`
  query GetInvoiceOptionsAndDefaults($id: ID!) {
    getOrganizationById(id: $id) {
      id
      taxRateGroups {
        id
        name
        isLocked
        isArchived
        totalTaxRate
        taxRates {
          id
          name
          rate
        }
      }
      defaultInvoiceSettings {
        netTerms
        showDueDate
        showLineItems
        showLineItemQuantity
        showLineItemUnitPrice
        showLineItemSubtotal
        showLineItemCode
        showLineItemName
        showLineItemDescription
        showLineItemBundleComponents
        showLineItemBundleComponentUnitPrice
        showLineItemBundleComponentQuantity
        showFooter
        showFooterSubtotal
        showFooterDiscount
        showFooterTaxes
        showFooterTotal
      }
    }
  }
`

interface Props {
  readonly open?: boolean
  readonly onCancel: () => void
  readonly onSave: (invoice: Invoice) => void
  readonly invoice?: Invoice
  readonly user: User
}

function InvoiceDialog({ open, onCancel, onSave, invoice, user }: Props) {
  const { t } = useTranslation()
  const organizationPreferences = useGetUserOrganizationPreferences(user.organization.id)
  const [moreActionsMenuAnchorEl, setMoreActionsMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const showMoreActionsMenu = Boolean(moreActionsMenuAnchorEl)
  const statusOptions = [
    { id: "DRAFT", name: `${t("invoiceStatus.DRAFT")}` },
    { id: "APPROVED", name: `${t("invoiceStatus.APPROVED")}` },
  ]
  const { hasPermissions } = useAuth()
  const [loadFromEstimateDialogOpen, setLoadFromEstimateDialogOpen] = useState<boolean>(false)
  const [loadFromWorkOrderDialogOpen, setLoadFromWorkOrderDialogOpen] = useState<boolean>(false)
  const [description, setDescription] = useState<string>(() => invoice?.description ?? "")
  const [notes, setNotes] = useState<string>(() => invoice?.notes ?? "")
  const [billingContact, setBillingContact] = useState(() => invoice?.billingContact)
  const [createContactDialogOpen, setCreateContactDialogOpen] = useState(false)
  const [netTerms, setNetTerms] = useState<NetTerms>(invoice?.netTerms ?? NetTerms.NET_30)
  const [dueDate, setDueDate] = useState<Dayjs | null>(() => {
    return invoice?.dueDate ? dayjs(invoice.dueDate).startOf("day") : null
  })
  const [discount, setDiscount] = useState<string>(() =>
    invoice?.discount?.value ? `${invoice.discount.value}` : ""
  )
  const [discountType, setDiscountType] = useState<DiscountType>(
    () => invoice?.discount?.type ?? DiscountType.PERCENTAGE
  )
  const [status, setStatus] = useState<(typeof statusOptions)[number]>(() => {
    const statusId = invoice?.status ?? "DRAFT"
    return statusOptions.find((o) => o.id == statusId) ?? statusOptions[0]
  })
  const [lineItems, setLineItems] = useState<InvoiceLineItem[]>(() => invoice?.lineItems ?? [])
  const [customerVisibility, setCustomerVisibility] = useState<InvoiceCustomerVisibility>(() => {
    const { __typename, ...visibilitySettings } =
      invoice?.customerVisibility ?? ({} as InvoiceCustomerVisibility)
    return visibilitySettings
  })
  const [errors, setErrors] = useState<ValidationErrors>(() => ({
    dueDate: null,
    lineItems: null,
  }))
  const canEdit = hasPermissions ? hasPermissions([DefaultPermission.UpdateInvoice]) : false

  const { loading: optionsAndDefaultsLoading, data: optionsAndDefaultsData } = useQuery(
    GET_OPTIONS_AND_DEFAULTS,
    {
      variables: {
        id: user.organization.id,
      },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (!invoice?.id) {
          const netTerms = data.getOrganizationById.defaultInvoiceSettings.netTerms
          setNetTerms(netTerms)
          const defaultInvoiceSettings = data.getOrganizationById.defaultInvoiceSettings
          setCustomerVisibility({
            showDueDate: defaultInvoiceSettings.showDueDate,
            showLineItems: defaultInvoiceSettings.showLineItems,
            showLineItemQuantity: defaultInvoiceSettings.showLineItemQuantity,
            showLineItemUnitPrice: defaultInvoiceSettings.showLineItemUnitPrice,
            showLineItemSubtotal: defaultInvoiceSettings.showLineItemSubtotal,
            showLineItemCode: defaultInvoiceSettings.showLineItemCode,
            showLineItemName: defaultInvoiceSettings.showLineItemName,
            showLineItemDescription: defaultInvoiceSettings.showLineItemDescription,
            showLineItemBundleComponents: defaultInvoiceSettings.showLineItemBundleComponents,
            showLineItemBundleComponentUnitPrice:
              defaultInvoiceSettings.showLineItemBundleComponentUnitPrice,
            showLineItemBundleComponentQuantity:
              defaultInvoiceSettings.showLineItemBundleComponentQuantity,
            showFooter: defaultInvoiceSettings.showFooter,
            showFooterSubtotal: defaultInvoiceSettings.showFooterSubtotal,
            showFooterDiscount: defaultInvoiceSettings.showFooterDiscount,
            showFooterTaxes: defaultInvoiceSettings.showFooterTaxes,
            showFooterTotal: defaultInvoiceSettings.showFooterTotal,
          })
        }
      },
    }
  )

  const [editInvoice, { loading: editInvoiceLoading, error: editInvoiceError }] = useMutation(
    EDIT_INVOICE,
    {
      onCompleted: (data) => {
        onSave?.(data.editInvoice.invoice)
      },
      onError: (error) => {
        Sentry.captureException(error)
      },
      refetchQueries: () => {
        return ["AllInvoicesForJob"]
      },
    }
  )

  const [createInvoice, { loading: createInvoiceLoading, error: createInvoiceError }] = useMutation(
    CREATE_INVOICE,
    {
      onCompleted: (data) => {
        onSave?.(data.createInvoice.invoice)
      },
      onError: (error) => {
        Sentry.captureException(error)
      },
      refetchQueries: () => {
        return ["AllInvoicesForJob"]
      },
    }
  )

  const networkError = editInvoiceError || createInvoiceError
  const loading = editInvoiceLoading || createInvoiceLoading
  const editMode = !!invoice?.id

  const taxRateGroupOptions =
    optionsAndDefaultsData?.getOrganizationById?.taxRateGroups?.slice().sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    }) ?? []
  if (taxRateGroupOptions?.length) {
    lineItems?.forEach((li) => {
      if (!li.taxRateGroup || li.taxRateGroup.id === "") {
        li.taxRateGroup = taxRateGroupOptions[0]
      }
    })
  }

  function addLineItem() {
    const maxLineNumber =
      lineItems?.length > 0 ? Math.max(...lineItems.map((li) => li.number ?? 0)) : 0
    setLineItems(
      lineItems?.concat([
        createTransactionLineItemFormInput(maxLineNumber + 1, taxRateGroupOptions?.[0], 1),
      ])
    )
  }

  function updateLineItem(reason: string, payload: any) {
    const updatedLineItems = updateTransactionLineItem(reason, payload, lineItems, "INVOICE")

    if (areAllLineItemsValid(updatedLineItems)) {
      setErrors({
        ...errors,
        lineItems: null,
      })
    }

    setLineItems(updatedLineItems)
  }

  function removeLineItem(lineItem: InvoiceLineItem) {
    const updatedLineItems = lineItems?.filter((li) => li.number !== lineItem.number) ?? []
    updatedLineItems.forEach((item, idx) => {
      item.number = idx + 1
    })
    if (areAllLineItemsValid(updatedLineItems)) {
      setErrors({
        ...errors,
        lineItems: null,
      })
    }
    setLineItems(updatedLineItems)
  }

  function areAllLineItemsValid(lines: InvoiceLineItem[]): boolean {
    const hasInvalidLineItem = lines.some((li) => {
      return (
        isBlank(li.organizationItemId) ||
        isBlank(`${li.quantity}`) ||
        isBlank(`${li.unitPrice}`) ||
        asInt(li.quantity, true) < 0 ||
        asFloat(li.unitPrice, true) < 0 ||
        li.taxRateGroup === null
      )
    })

    return !hasInvalidLineItem
  }

  function handleRowDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const startIndex = result.source.index
    const endIndex = result.destination.index
    if (startIndex === endIndex) {
      return
    }

    const sourceLineItem = lineItems[startIndex]

    let sortedLines = Array.from(lineItems)
    sortedLines.splice(startIndex, 1)
    sortedLines.splice(endIndex, 0, sourceLineItem)
    sortedLines = sortedLines.map((item, idx) => ({
      ...item,
      number: idx + 1,
    }))

    setLineItems(sortedLines)
  }

  function getRowStyle(isDragging, draggableStyle) {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",

      // change background colour if dragging
      background: isDragging ? "#f1f8ff" : "#fff",

      // styles we need to apply on draggables
      ...draggableStyle,
    }
  }

  function getListStyle(isDraggingOver) {
    return {
      background: isDraggingOver ? "#efefef" : "#fff",
    }
  }

  function handleSave() {
    if (errors.dueDate) {
      return
    }

    const nonEmptyLineItems = lineItems.filter((li) => Boolean(li.organizationItemId))

    if (!nonEmptyLineItems || nonEmptyLineItems.length === 0) {
      setErrors({
        ...errors,
        lineItems: {
          title: t("component.invoiceDialog.lineItems.required.title"),
          message: t("component.invoiceDialog.lineItems.required.message"),
        },
      })
      return
    }

    if (!areAllLineItemsValid(nonEmptyLineItems)) {
      setErrors({
        ...errors,
        lineItems: {
          title: t("component.invoiceDialog.lineItems.invalid.title"),
          message: t("component.invoiceDialog.lineItems.invalid.message"),
        },
      })
      const validatedLineItems = nonEmptyLineItems.map((li) => {
        return {
          ...li,
          errors: {
            showErrors: true,
            organizationItemId: !li.organizationItemId ? "required" : null,
            quantity: isBlank(`${li.quantity}`) || asInt(li.quantity, true) < 0 ? "required" : null,
            unitPrice:
              isBlank(`${li.unitPrice}`) || asFloat(li.unitPrice, true) < 0 ? "required" : null,
            taxRateGroup: !li.taxRateGroup?.id ? "required" : null,
          },
        }
      })

      setLineItems(validatedLineItems)
      return
    }

    setErrors({
      dueDate: null,
      lineItems: null,
    })

    const variables = {
      id: invoice?.id,
      jobId: invoice?.job?.id || null,
      billingContact: billingContact ? sanitizeBillingContact(billingContact) : undefined,
      description: description?.trim()?.substring(0, 1000),
      notes: notes?.trim()?.substring(0, 1000),
      netTerms: netTerms,
      dueDate:
        dueDate && netTerms === NetTerms.CUSTOM
          ? dayjs(dueDate).startOf("day").utc().format()
          : null,
      discount: {
        value: asFloat(discount) / (discountType === "PERCENTAGE" ? 100 : 1.0),
        type: discountType,
      },
      lineItems: sanitizeLineItems(nonEmptyLineItems),
      status: status.id,
      customerVisibility,
    }

    editMode ? editInvoice({ variables }) : createInvoice({ variables })
  }

  const currencyCode = invoice?.currencyCode ?? user.organization.currencyCode

  return (
    <>
      <Dialog
        aria-labelledby="form-dialog-title"
        data-testid="InvoiceDialog"
        fullWidth
        maxWidth="xl"
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            onCancel?.()
          }
        }}
        open={open}
        sx={{
          marginBottom: "40px", // make room for the intercom bubble
        }}
      >
        <DialogTitle
          sx={{
            py: "0.625rem",
            px: "1.5rem",
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          {invoice?.id
            ? t("editInvoice", { number: getJobDocumentDisplayNumber(invoice) })
            : t("createInvoice")}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              backgroundColor: dialogBackgroundColor,
              paddingBottom: 0,
              paddingTop: "1.5rem",
              display: "flex",
              flexDirection: "column",
              minHeight: "390px",
            }}
          >
            {networkError ? (
              <Box sx={classes.errorContainer}>
                <Box sx={classes.errorTitle}>{t("error.general.title")}</Box>
                <Box sx={classes.errorMessage}>{t(parseGraphQLErrorCode(networkError))}</Box>
              </Box>
            ) : null}
            {!networkError && errors?.lineItems ? (
              <Box sx={classes.errorContainer}>
                <Box sx={classes.errorTitle}>{errors.lineItems.title}</Box>
                <Box sx={classes.errorMessage}>{errors.lineItems.message}</Box>
              </Box>
            ) : null}
            {loading ? (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  flex: 1,
                }}
              >
                <CircularProgress />
                <p>{t("saving")} ...</p>
              </Box>
            ) : (
              <Box
                sx={{
                  flex: 1,
                  minWidth: "88rem",
                  px: "1rem",
                }}
              >
                {invoice?.issuedDate ? (
                  <Box>
                    <Box sx={{ flex: 1 }}>
                      <Alert severity="info" sx={{ alignItems: "center" }}>
                        {t("component.invoiceDialog.issuedOn", {
                          issuedDate: formatDate(
                            invoice.issuedDate,
                            t("format:dateFormat.fullDate"),
                            user?.organization?.timeZone
                          ),
                          issuedTime: formatDate(
                            invoice.issuedDate,
                            t("format:dateFormat.time"),
                            user?.organization?.timeZone
                          ),
                        })}
                      </Alert>
                    </Box>
                  </Box>
                ) : null}
                <Box sx={classes.rowContainer}>
                  <Box sx={{ flex: 1 }}>
                    <Box
                      component="label"
                      sx={{
                        color: "rgba(0, 0, 0, 0.54)",
                        fontSize: "1rem",
                        fontWeight: 600,
                      }}
                    >
                      {t("jobAddress")}
                    </Box>
                    <Box sx={classes.staticFieldValue}>
                      {formatAddress(invoice?.job?.address?.addressString)}
                    </Box>
                  </Box>
                  <Box
                    sx={[
                      classes.rowItem,
                      { display: "flex", flexDirection: "row", justifyContent: "flex-end" },
                    ]}
                  >
                    <InvoiceCustomerVisibilitySelect
                      customerVisibility={customerVisibility}
                      onToggleOption={(option: InvoiceCustomerVisibilitySelectOption) => {
                        setCustomerVisibility({
                          ...customerVisibility,
                          [option.id]: !customerVisibility[option.id],
                        })
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={classes.rowContainer}>
                  <Box sx={{ flex: 1 }}>
                    <SelectorField
                      data-testid="invoiceDialogField_status"
                      label={t("component.invoiceDialog.status.label") as string}
                      name="status"
                      onChange={(selectedOption) => canEdit && setStatus(selectedOption)}
                      options={statusOptions}
                      value={status.id}
                    />
                    <FieldHelperText message={t(`component.invoiceDialog.status.helperText`)} />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <CustomerContactSelect
                      customerId={invoice?.job?.customer?.id}
                      label={t("billingContact") as string}
                      name="BillingContact"
                      onChange={(val) => {
                        if (val === "CREATE_NEW_CONTACT") {
                          setCreateContactDialogOpen(true)
                        } else {
                          setBillingContact(val)
                        }
                      }}
                      onlyShowBillingContacts
                      selectedOption={billingContact}
                      showCreateContactOption={invoice?.job?.customer?.type === "BUSINESS"}
                    />
                  </Box>
                  <FormControl sx={{ flex: 1 }}>
                    <NetTermsSelect
                      data-testid="invoiceDialogField_netTerms"
                      label={t("component.invoiceDialog.netTerms.label") as string}
                      name="netTerms"
                      onChange={(selectedOption: NetTerms) => {
                        if (canEdit) {
                          setNetTerms(selectedOption)
                          if (selectedOption !== NetTerms.CUSTOM && !invoice?.issuedDate) {
                            setDueDate(null)
                          } else if (invoice?.issuedDate) {
                            setDueDate(
                              invoice?.issuedDate
                                ? dayjs(invoice.issuedDate).add(
                                    getDaysFromNetTerms(selectedOption),
                                    "day"
                                  )
                                : null
                            )
                          }
                        }
                      }}
                      value={netTerms}
                    />
                    <FieldHelperText message={t(`component.invoiceDialog.netTerms.helperText`)} />
                  </FormControl>
                  <Box sx={{ flex: 1 }}>
                    {invoice?.issuedDate && netTerms !== NetTerms.CUSTOM ? (
                      <>
                        <Box
                          component="label"
                          sx={{ fontSize: "0.8rem", fontWeight: "600", lineHeight: "1.5rem" }}
                        >
                          {t("component.invoiceDialog.dueDate.label")}
                        </Box>
                        <Box>
                          {formatDate(
                            dueDate,
                            t("format:dateFormat.fullDate"),
                            user?.organization?.timeZone
                          )}
                        </Box>
                      </>
                    ) : netTerms === NetTerms.CUSTOM ? (
                      <DatePicker
                        aria-label={t("component.invoiceDialog.dueDate.label") as string}
                        format={t("format:dateFormat.short") as string}
                        label={t("component.invoiceDialog.dueDate.label")}
                        maxDate={TODAY.add(5, "year")}
                        onChange={(newValue) => {
                          if (!newValue || newValue?.isValid()) {
                            if (newValue?.isBefore(TODAY.add(5, "year"))) {
                              setErrors({
                                ...errors,
                                dueDate: null,
                              })
                            }
                          }
                          canEdit && setDueDate(newValue)
                        }}
                        onError={(reason, value) => {
                          if (reason === "minDate") {
                            setErrors({
                              ...errors,
                              dueDate: {
                                title: "",
                                message: t("component.invoiceDialog.dueDate.error.minDate"),
                              },
                            })
                          } else if (reason === "maxDate") {
                            setErrors({
                              ...errors,
                              dueDate: {
                                title: "",
                                message: t("component.invoiceDialog.dueDate.error.maxDate"),
                              },
                            })
                          } else if (reason === "invalidDate") {
                            setErrors({
                              ...errors,
                              dueDate: {
                                title: "",
                                message: t("component.invoiceDialog.dueDate.error.invalid"),
                              },
                            })
                          }
                        }}
                        slotProps={{
                          textField: {
                            InputProps: {
                              disableUnderline: true,
                            },
                            fullWidth: true,
                            required: false,
                            helperText: errors.dueDate
                              ? errors.dueDate.message
                              : t("component.invoiceDialog.dueDate.helperText"),
                          },
                        }}
                        slots={{
                          textField: FielderTextField,
                        }}
                        value={dueDate}
                      />
                    ) : null}
                  </Box>
                </Box>
                <Box sx={classes.rowContainer}>
                  <FormControl sx={{ flex: 1 }}>
                    <FielderTextField
                      aria-label={t("description") as string}
                      data-testid="invoiceDialogField_description"
                      helperText={t("component.invoiceDialog.descriptionHelperText")}
                      id="description"
                      inputProps={{ maxLength: 1000 }}
                      label={t("description")}
                      maxRows={6}
                      minRows={4}
                      multiline
                      name="description"
                      onChange={(e) => canEdit && setDescription(e.target.value)}
                      placeholder={t("component.invoiceDialog.descriptionPlaceholder") as string}
                      value={description}
                    />
                  </FormControl>
                  <FormControl sx={{ flex: 1 }}>
                    <FielderTextField
                      aria-label={t("component.invoiceDialog.internalNotes.label") as string}
                      data-testid="invoiceDialogField_notes"
                      fullWidth
                      helperText={t("component.invoiceDialog.internalNotes.helperText")}
                      id="internalNotes"
                      inputProps={{ maxLength: 1000 }}
                      label={t("component.invoiceDialog.internalNotes.label")}
                      maxRows={4}
                      minRows={4}
                      multiline
                      name="notes"
                      onChange={(e) => canEdit && setNotes(e.target.value)}
                      value={notes}
                    />
                  </FormControl>
                </Box>
                <Box sx={classes.rowContainer}>
                  <Box sx={classes.rowItem}>
                    <Paper elevation={0} sx={classes.lineItemTableContainer}>
                      <DragDropContext onDragEnd={handleRowDragEnd}>
                        <Box data-testid="invoiceDialogField_lineItemsGrid">
                          <Box sx={[classes.lineItemGridRow, classes.lineItemHeader]}>
                            <Box />
                            <label>{t("item")}</label>
                            <label>{t("description")}</label>
                            <label>{t("quantity")}</label>
                            <label>{t("unitPrice")}</label>
                            <label style={{ justifySelf: "end", paddingRight: "0.5rem" }}>
                              {t("lineSubtotal")}
                            </label>
                            <label>{t("taxRate")}</label>
                            <Box />
                          </Box>
                        </Box>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {lineItems.map((li, index) => {
                                return (
                                  <Draggable draggableId={li.key} index={index} key={li.key}>
                                    {(provided, snapshot) => (
                                      <LineItem
                                        canEdit={canEdit}
                                        css={classes.lineItemGridRow}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        allowBundleModifications={
                                          organizationPreferences?.allowBundleModsOnTxns
                                        }
                                        currencyCode={invoice.currencyCode}
                                        key={li.key}
                                        lineItem={li}
                                        onChange={updateLineItem}
                                        onDelete={(item) => removeLineItem(item)}
                                        style={getRowStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                        taxRateGroupOptions={taxRateGroupOptions}
                                        taxRateGroupsOptionsLoading={optionsAndDefaultsLoading}
                                        variant="INVOICE"
                                      />
                                    )}
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        {canEdit ? (
                          <Box sx={classes.addLineCell}>
                            <Button
                              data-testid="invoiceDialogField_addLineItemButton"
                              fullWidth
                              onClick={addLineItem}
                              sx={classes.addLineButton}
                              variant="outlined"
                            >
                              <AddIcon />
                              <Box>{t("addALine")}</Box>
                            </Button>
                          </Box>
                        ) : null}
                      </DragDropContext>
                      <Box
                        sx={[
                          classes.rowContainer,
                          { justifyContent: "space-between", marginTop: "2rem", gap: "1rem" },
                        ]}
                      >
                        <Box
                          sx={{
                            marginTop: "1rem",
                            flex: 3,
                            margin: "0.9375rem",
                            visibility: "hidden",
                          }}
                        />
                        <Box sx={{ flex: 2 }}>
                          <TransactionFooter
                            currencyCode={currencyCode}
                            discount={discount}
                            discountType={discountType}
                            lineItems={lineItems}
                            onChangeDiscount={(val) => setDiscount(val)}
                            onChangeDiscountType={(val) => setDiscountType(val)}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: dialogBackgroundColor,
            borderTop: "1px solid #cdcdcd",
            padding: "1.5rem",
            paddingTop: "0.75rem",
            paddingBottom: "0.75rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {canEdit ? (
            <Box
              sx={(theme) => {
                return {
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  [theme.breakpoints.up("sm")]: {
                    display: "none",
                  },
                }
              }}
            >
              <Button
                aria-controls={showMoreActionsMenu ? "more-actions-menu" : undefined}
                aria-expanded={showMoreActionsMenu ? "true" : undefined}
                aria-haspopup="true"
                disableElevation
                disabled={loading}
                endIcon={<KeyboardArrowDownIcon />}
                id="more-actions-menu-button"
                onClick={(event) => {
                  setMoreActionsMenuAnchorEl(event.currentTarget)
                }}
                sx={{ width: "100%" }}
                variant="outlined"
              >
                {t("moreActions")}
              </Button>
            </Box>
          ) : null}

          <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <Button
              color="secondary"
              data-testid="invoiceDialogField_cancelButton"
              disabled={loading}
              onClick={onCancel}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
            {canEdit ? (
              <Button
                aria-controls={showMoreActionsMenu ? "more-actions-menu" : undefined}
                aria-expanded={showMoreActionsMenu ? "true" : undefined}
                aria-haspopup="true"
                disableElevation
                disabled={loading}
                endIcon={<KeyboardArrowDownIcon />}
                id="more-actions-menu-button"
                onClick={(event) => {
                  setMoreActionsMenuAnchorEl(event.currentTarget)
                }}
                sx={(theme) => {
                  return {
                    display: "none",
                    [theme.breakpoints.up("sm")]: {
                      display: "inline-flex",
                    },
                  }
                }}
                variant="text"
              >
                {t("moreActions")}
              </Button>
            ) : null}
            {canEdit ? <SaveButton loading={loading} onClick={handleSave} /> : null}
          </Box>
        </DialogActions>
      </Dialog>
      {createContactDialogOpen ? (
        <CreateContactDialog
          canBeBillingContact
          canBeJobSiteContact
          customer={invoice?.job?.customer}
          onCancel={() => setCreateContactDialogOpen(false)}
          onSave={(contact) => {
            setBillingContact(contact)
            setCreateContactDialogOpen(false)
          }}
          showCanBeBillingContactToggle
          showCanBeJobSiteContactToggle={false}
          user={user}
        />
      ) : null}
      {loadFromWorkOrderDialogOpen ? (
        <LoadFromWorkOrderDialog
          jobId={invoice?.job?.id}
          onCancel={() => {
            setLoadFromWorkOrderDialogOpen(false)
          }}
          onSelectWorkOrder={(selectedWorkOrder) => {
            // The strategy is really just to bring over line items from the WorkOrder that
            // are not already in the Invoice. If the Invoice already has a line item for a given
            // WorkOrder line item, then we just skip it. We only bring over new line items that have a
            // non-null, non-zero quantity.
            // The imported line items are appended at the end of the list so the ordering of existing
            // line items is not mutated.
            const existingOrgItemIds = lineItems
              .map((li) => li.organizationItem?.id)
              ?.filter((id) => Boolean(id))

            const updatedExistingLineItems = lineItems
              .filter((li) => Boolean(li.organizationItemId) || Boolean(li.organizationItem?.id))
              .map((li: InvoiceLineItem) => {
                const matchingTemplateLine = selectedWorkOrder?.lineItems?.find(
                  (wli) => wli.organizationItem?.id === li.organizationItem?.id
                )
                return {
                  ...li,
                  quantity: Math.max(
                    Number(matchingTemplateLine?.quantity ?? 0),
                    Number(li.quantity)
                  ),
                  lineItemDetails: matchingTemplateLine?.lineItemDetails ?? li.lineItemDetails,
                  showDetails: Boolean(matchingTemplateLine?.showDetails ?? li.showDetails),
                }
              }) as InvoiceLineItem[]

            const newLines = (selectedWorkOrder?.lineItems
              ?.filter((li) => !existingOrgItemIds.includes(li.organizationItem?.id))
              ?.filter((li) => li.quantity && li.quantity > 0)
              ?.sort((a, b) => {
                return (a.number ?? 0) - (b.number ?? 0)
              })
              ?.map((li: WorkOrderLineItem, index: number) => {
                return {
                  id: undefined,
                  number: updatedExistingLineItems.length + index + 1,
                  organizationItemId: li.organizationItem?.id,
                  organizationItem: li.organizationItem,
                  description: li.description ?? li.organizationItem?.description,
                  quantity: li.quantity ?? 0,
                  unitPrice: li.unitPrice,
                  subTotal: calculateLineSubtotal(li.quantity, li.unitPrice),
                  taxRateGroup: li.taxRateGroup,
                  lineItemDetails: li.lineItemDetails,
                  showDetails: li.showDetails,
                  key: nanoid(),
                } as InvoiceLineItem
              }) ?? []) as InvoiceLineItem[]

            const allLines = updatedExistingLineItems
              .concat(newLines)
              .filter((li) => !isBlank(li.organizationItem?.id))
              .sort((a, b) => {
                return (a.number ?? 0) - (b.number ?? 0)
              })
              .map((li, idx) => ({
                ...li,
                number: idx + 1,
              }))

            setLineItems(allLines)

            if (selectedWorkOrder.discount && Boolean(selectedWorkOrder.discount.value)) {
              setDiscount(formatDiscountValueForInput(selectedWorkOrder?.discount))
              setDiscountType(
                selectedWorkOrder?.discount?.type ? selectedWorkOrder.discount.type : discountType
              )
            }

            if (selectedWorkOrder.billingContact) {
              setBillingContact(selectedWorkOrder?.billingContact)
            }

            setDescription((prev) => {
              let newDescription = prev?.trim() ?? ""
              if (newDescription.length > 0) {
                newDescription += "\n"
              }
              if (!newDescription.includes(selectedWorkOrder?.description ?? "")) {
                newDescription += selectedWorkOrder?.description ?? ""
              }
              return newDescription
            })

            setLoadFromWorkOrderDialogOpen(false)
          }}
          variant="INVOICE"
        />
      ) : null}
      {loadFromEstimateDialogOpen && invoice?.job?.id ? (
        <LoadFromEstimateDialog
          jobId={invoice.job.id}
          onCancel={() => {
            setLoadFromEstimateDialogOpen(false)
          }}
          onSelectEstimate={(selectedEstimate?: Estimate | null) => {
            if (!selectedEstimate) {
              return
            }

            if (selectedEstimate.discount) {
              setDiscount(formatDiscountValueForInput(selectedEstimate.discount))
              setDiscountType(selectedEstimate.discount.type)
            }

            if (selectedEstimate.billingContact) {
              setBillingContact({
                id: selectedEstimate?.billingContact?.id,
              })
            }

            setDescription((prev) => {
              let newDescription = prev?.trim() ?? ""
              if (newDescription.length > 0) {
                newDescription += "\n"
              }
              if (!newDescription.includes(selectedEstimate?.description ?? "")) {
                newDescription += selectedEstimate?.description ?? ""
              }
              return newDescription
            })

            // Merge the incoming line items with the existing line items
            const existingOrgItemIds = lineItems
              .map((li) => li.organizationItem?.id)
              ?.filter((id) => Boolean(id))

            const updatedExistingLineItems = lineItems
              .filter((li) => Boolean(li.organizationItemId) || Boolean(li.organizationItem?.id))
              .map((li: WorkOrderLineItem) => {
                const existingLine = selectedEstimate?.lineItems?.find(
                  (eli: EstimateLineItem) => eli.organizationItem?.id === li.organizationItem?.id
                ) as EstimateLineItem | undefined

                return {
                  ...li,
                  quantity:
                    existingLine?.quantity || li.quantity
                      ? Math.max(existingLine?.quantity ?? 0, li.quantity ?? 0)
                      : li.quantity,
                  lineItemDetails: existingLine?.lineItemDetails,
                  showDetails: Boolean(existingLine?.showDetails),
                } as InvoiceLineItem
              })

            const newLines = selectedEstimate?.lineItems
              ?.filter((li) => !existingOrgItemIds.includes(li.organizationItem?.id))
              ?.map((li: EstimateLineItem) => {
                return {
                  id: undefined,
                  number: updatedExistingLineItems.length + li.number,
                  organizationItemId: li.organizationItem?.id,
                  organizationItem: li.organizationItem,
                  lineItemDetails: li.lineItemDetails,
                  showDetails: li.showDetails,
                  description: li.description,
                  quantity: li.quantity,
                  unitPrice: li.unitPrice,
                  taxRateGroup: li.taxRateGroup,
                  total: li.total,
                  key: nanoid(),
                } as InvoiceLineItem
              }) as InvoiceLineItem[]

            const allLines = updatedExistingLineItems
              .concat(newLines)
              .filter((li) => !isBlank(li.organizationItem?.id))
              .sort((a, b) => {
                return a.number - b.number
              })
              .map((li, idx) => ({
                ...li,
                number: idx + 1,
              }))

            setLineItems(allLines)
            setLoadFromEstimateDialogOpen(false)
          }}
          targetType="invoice"
        />
      ) : null}
      <Menu
        anchorEl={moreActionsMenuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        elevation={1}
        onClose={() => {
          setMoreActionsMenuAnchorEl(undefined)
          setMoreActionsMenuAnchorEl(undefined)
        }}
        open={showMoreActionsMenu}
        sx={{
          "& .MuiMenu-list": {
            minWidth: "200px",
          },
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem
          disabled={loading}
          onMouseDown={() => {
            setMoreActionsMenuAnchorEl(undefined)
            setLoadFromWorkOrderDialogOpen(true)
          }}
        >
          {t("loadFromWorkOrder")}
        </MenuItem>
        {hasPermissions?.([DefaultPermission.ReadEstimate]) ? (
          <MenuItem
            disabled={loading}
            onMouseDown={() => {
              setMoreActionsMenuAnchorEl(undefined)
              setLoadFromEstimateDialogOpen(true)
            }}
          >
            {t("component.invoiceDialog.loadFromEstimate")}
          </MenuItem>
        ) : null}
      </Menu>
    </>
  )
}

const classes = {
  errorContainer: {
    backgroundColor: "#fef2f2",
    padding: "1.25rem",
    border: "1px solid #fee2e2",
    borderRadius: "4px",
    marginBottom: "1.25rem",
  },
  errorTitle: {
    color: "#991b1b",
    fontWeight: "600",
  },
  errorMessage: {
    color: "#7f1d1d",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "2rem",
    marginTop: "0.75rem",
    minWidth: "450px",
    width: "100%",
    gap: "1rem",
  },
  rowItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "50%",
    marginRight: "0.3125rem",
    marginLeft: "0.3125rem",
  },
  staticFieldValue: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.9rem",
  },
  lineItemTableContainer: {
    marginTop: "0.625rem",
    paddingBottom: "1.25rem",
  },
  lineItemGridRow: {
    display: "grid",
    gridTemplateColumns:
      "50px minmax(100px, 4fr) minmax(100px, 4fr) repeat(3, minmax(50px, 1fr)) 2fr 50px",
    columnGap: "10px",
    paddingTop: "0.5rem",
    paddingBottom: "0.125rem",
    boxSizing: "border-box",
  },
  lineItemHeader: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderBottom: "1px solid #cdcdcd",
    "& label": {
      fontSize: "0.8rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
    },
  },
  addLineCell: {
    border: "none",
    marginTop: "1.5rem",
    paddingTop: "0.625rem",
    paddingRight: "1.25rem",
    paddingLeft: "1.25rem",
    textAlign: "center",
  },
  addLineButton: {
    border: "1px solid #288906",
    borderRadius: "4px",
    color: "#288906",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#F5FFF2",
      border: "1px solid #288906",
    },
  },
} as const

export default InvoiceDialog
