import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { useDebounce, ItemType } from "../util"
import { OrganizationItem } from "../types"

const ORG_ITEM_TYPEAHEAD = gql`
  query OrgItemTypeahead(
    $filter: String
    $first: Int
    $sortBy: String
    $sortDir: SortDirection
    $organizationId: ID
    $onlyAvailableItems: Boolean
  ) {
    allOrganizationItems(
      input: { filter: $filter, first: $first, sortBy: $sortBy, sortDir: $sortDir }
      organizationItemFilter: {
        organizationId: $organizationId
        onlyAvailableItems: $onlyAvailableItems
      }
    ) {
      edges {
        node {
          id
          code
          name
          type
          description
          qtyOnHand
          unitSalePrice
          wholesalePrice
          showComponentsOnTransactions
          isAvailable
          reorderQty
          palletQty
          components {
            id
            number
            organizationItem {
              id
              code
              name
            }
            quantity
            unitSalePrice
          }
        }
      }
    }
  }
`

interface Props {
  readonly error?: string | null | undefined
  readonly onChange: (value: OrganizationItem | null, reason: string) => void
  readonly value?: Partial<OrganizationItem> | null | undefined
  readonly hideBundleItems?: boolean
  readonly hideServiceItems?: boolean
  readonly style?: React.CSSProperties
  readonly organizationId?: string // for level-1 users only
  readonly showQtyOnHand?: boolean
  readonly onlyAvailableItems?: boolean
}

function OrganizationItemSelect({
  error,
  onChange,
  value,
  hideBundleItems,
  hideServiceItems,
  style,
  organizationId,
  showQtyOnHand = true,
  onlyAvailableItems = false,
}: Props) {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<string>("")
  const debouncedSearchTerm = useDebounce(filter, 250)

  const MAX_OPTIONS = 30

  const {
    data,
    loading,
    error: queryError,
  } = useQuery(ORG_ITEM_TYPEAHEAD, {
    variables: {
      filter: debouncedSearchTerm,
      first: MAX_OPTIONS * 5,
      sortBy: "qtyOnHand",
      sortDir: "DESC",
      organizationId,
      onlyAvailableItems,
    },
    fetchPolicy: "no-cache", // the concatRelayStylePagination method setup on the allOrganizationItems "field" in client.js causes bad behavior for this component when trying to use caching.
    context: {
      debounceKey: "OrgItemTypeahead",
    },
  })

  if (queryError) return <div>{`Error! ${queryError.message}`}</div>

  const allItems =
    data?.allOrganizationItems?.edges
      .map((e) => e.node)
      ?.filter((n) => {
        if (hideBundleItems) {
          return n.type !== ItemType.BUNDLE
        } else {
          return true
        }
      })
      ?.filter((n) => {
        if (hideServiceItems) {
          return n.type !== ItemType.SERVICE
        } else {
          return true
        }
      }) ?? []

  const inStockOptions = allItems.filter((i) => i.type !== ItemType.PRODUCT || i.qtyOnHand > 0)
  const outOfStockOptions = allItems.filter((i) => i.type === ItemType.PRODUCT && i.qtyOnHand === 0)

  let options = inStockOptions.slice(0, MAX_OPTIONS)
  if (options.length < MAX_OPTIONS) {
    options = options.concat(outOfStockOptions.slice(0, MAX_OPTIONS - options.length))
  }

  return (
    <Autocomplete
      blurOnSelect
      fullWidth
      getOptionLabel={(option) => {
        return option?.id ? `${option.code} - ${option.name}` : ""
      }}
      id="item-autocomplete"
      isOptionEqualToValue={(option, value) => {
        return option.id === (value?.id ?? "")
      }}
      loading={loading}
      loadingText={t("loading")}
      noOptionsText={t("component.organizationItemSelect.noOptions")}
      onBlur={() => {
        setFilter("")
      }}
      onChange={(event, value, reason) => {
        setFilter("")
        onChange(value, reason)
      }}
      onInputChange={(event, value, reason) => {
        if (reason === "input") {
          setFilter(value)
        }

        if (reason === "clear") {
          setFilter("")
          onChange(null, reason)
        }
      }}
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
            }}
            error={!!error}
            fullWidth
            placeholder={t("component.organizationItemSelect.placeholder") as string}
            required
            size="small"
            sx={style}
            variant="outlined"
          />
        )
      }}
      renderOption={(props, item) => (
        <Box key={item.id} sx={classes.itemOptionContainer} {...props}>
          <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Box sx={classes.itemOptionCode}>
              <span>{item.code}</span>
            </Box>
            <Box sx={classes.itemOptionName}>{item.name}</Box>
          </Box>
          {item.type === ItemType.PRODUCT && showQtyOnHand ? (
            <Box sx={classes.itemOptionQtyOnHand}>
              <Box sx={{ fontSize: "0.625rem" }}>{t("inStock")}</Box>
              <Box sx={{ fontSize: "0.875rem" }}>{item.qtyOnHand}</Box>
            </Box>
          ) : null}
        </Box>
      )}
      value={value}
    />
  )
}

const classes = {
  itemOptionContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    paddingTop: "0.5rem",
    paddingBottom: "0.75rem",
    marginBottom: 0,
    borderBottom: "1px solid #ddd",
  },
  itemOptionCode: {
    fontSize: "0.64rem",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    marginRight: "0.75rem",
    wordBreak: "break-all",
  },
  itemOptionName: {
    flexGrow: 1,
    fontSize: "0.85rem",
  },
  itemOptionQtyOnHand: {
    alignSelf: "center",
    textAlign: "right",
    marginLeft: "0.75rem",
  },
} as const

export default OrganizationItemSelect
