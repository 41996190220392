import React, { useState } from "react"
import { Navigate, NavigateProps } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { useMutation } from "@apollo/client"
import { CREATE_JOB } from "../../queries/createJob"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import Hidden from "@mui/material/Hidden"
import JobBasicInfoForm from "./components/JobBasicInfoForm"
import GoogleMapView, { Coordinate } from "../../components/GoogleMapView"
import MainLayout from "../../components/MainLayout"
import PageHeader from "../../components/PageHeader"
import Seo from "../../components/Seo"
import SnackbarMessage from "../../components/SnackbarMessage"
import SectionHeader from "../../components/SectionHeader"
import SectionContent from "../../components/SectionContent"
import {
  JOBS,
  getAddressMarker,
  getDefaultMarkers,
  getUserHqMarker,
  parseGraphQLErrorCode,
} from "../../util"
import { useAuth } from "../../context/AuthContext"
import { Address, MapMarker, MapMarkerType, Snack } from "../../types"

function CreateJob() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [snack, setSnack] = useState<Snack>()
  const [jobAddressMarker, setJobAddressMarker] = useState<MapMarker | null>(null)
  const [markers, setMarkers] = useState<MapMarker[]>(() =>
    user ? getDefaultMarkers(user, null) : []
  )

  const [createJob, { loading }] = useMutation(CREATE_JOB, {
    onCompleted: (data) => {
      const id = data.createJob.job.id
      setRedirectTo({ to: `/app/jobs/edit/${id}`, replace: false })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: () => {
      return ["AllJobs"]
    },
  })

  function handleSave(payload) {
    createJob(payload)
  }

  function handleCancel() {
    setRedirectTo({ to: "/app/jobs", replace: true })
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t("sectionTitle.jobs")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={JOBS}>
        <Box
          sx={{
            margin: "0 1.25rem 1.25rem 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: JOBS.path, titleKey: JOBS.titleKey }]}
            icon={JOBS.icon}
            leafTitleKey="createJob"
          />
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item lg={6} xs={12}>
              <Paper>
                <SectionHeader>
                  <label>{t("basicInfo")}</label>
                </SectionHeader>
                <Divider />
                <SectionContent>
                  <JobBasicInfoForm
                    job={null}
                    loading={loading}
                    onCancel={handleCancel}
                    onChangeAddress={(address: Address) => {
                      if (address?.latitude && address?.longitude) {
                        const jobMarker = getAddressMarker(address, MapMarkerType.JOB)
                        setJobAddressMarker(jobMarker)

                        const hqMarker = getUserHqMarker(user)

                        const allMarkers = []
                        if (hqMarker) {
                          allMarkers.push(hqMarker)
                        }
                        if (jobMarker) {
                          allMarkers.push(jobMarker)
                        }

                        setMarkers(allMarkers)
                      }
                    }}
                    onSave={handleSave}
                    user={user}
                  />
                </SectionContent>
              </Paper>
            </Grid>
            <Hidden smDown>
              <Grid item sm={6}>
                <Box
                  sx={{
                    height: "100%",
                    maxHeight: "500px",
                    borderRadius: "4px",
                  }}
                >
                  <GoogleMapView
                    center={(jobAddressMarker as Coordinate) ?? getUserHqMarker(user)}
                    markers={markers}
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      borderRadius: "4px",
                      height: "100%",
                    }}
                    zoom={15}
                  />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Box>
      </MainLayout>
    </>
  )
}

export default CreateJob
