import React from "react"
import TableCell, { SortDirection } from "@mui/material/TableCell"
import { Theme } from "@mui/material/styles"
import TableSortLabel from "@mui/material/TableSortLabel"
import { SxProps } from "@mui/system"

type Order = "asc" | "desc"

interface Props {
  readonly align?: "left" | "right" | "center"
  readonly isActiveSort?: boolean
  readonly onClick?: () => void
  readonly sortDir?: Order | "ASC" | "DESC"
  readonly label?: string | null
  readonly width?: number | string | undefined
  readonly sx?: SxProps<Theme>
}

function SortableTableHeader({
  align = "left",
  sx,
  isActiveSort = false,
  onClick,
  sortDir = "asc",
  label,
  width,
}: Props) {
  const sortDirection = sortDir?.toLowerCase() ?? "asc"
  return (
    <TableCell
      align={align}
      onClick={onClick}
      scope="col"
      sortDirection={sortDirection as SortDirection}
      style={{ cursor: "pointer" }}
      sx={{
        backgroundColor: "#f9f9f9",
        zIndex: 1000,
        position: "sticky",
        top: 0,
        "& svg": {
          fontSize: "0.8rem",
        },
        // "&.MuiTableCell-root": {
        //   paddingLeft: "0",
        // },
        ...(sx ?? {}),
      }}
      variant="head"
      width={width}
    >
      <TableSortLabel active={isActiveSort} direction={sortDirection as Order} onClick={onClick}>
        {label}
      </TableSortLabel>
    </TableCell>
  )
}

export default SortableTableHeader
