import gql from "graphql-tag"

export const EDIT_JOB_CATEGORY = gql`
  mutation EditJobCategory($id: ID!, $name: String!, $isArchived: Boolean) {
    editJobCategory(input: { id: $id, name: $name, isArchived: $isArchived }) {
      jobCategory {
        id
        name
        isArchived
      }
    }
  }
`
