import gql from "graphql-tag"
import FullAddress from "./fullAddress"

export default gql`
  fragment OrganizationFragment on Organization {
    id
    tradeName
    legalName
    level
    currencyCode
    languageCode
    timeZone
    status
    address {
      ...FullAddress
    }
    email
    phoneNumber
    websiteURL
  }
  ${FullAddress}
`
