import gql from "graphql-tag"

export const ADD_PRODUCT_ORDER_COMMENT = gql`
  mutation CreateProductOrderComment($productOrderId: ID!, $comment: String!) {
    createProductOrderComment(input: { productOrderId: $productOrderId, comment: $comment }) {
      productOrderComment {
        id
        comment
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`
