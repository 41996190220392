import React from "react"
import { useTranslation } from "react-i18next"

function AppVersion() {
  const { t } = useTranslation()

  const version = `${process.env.GATSBY_APP_VERSION}`.split("-")[0]

  return (
    <div>
      {t("version")}: {version}
    </div>
  )
}

export default AppVersion
