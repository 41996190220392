/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-literals */
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import Box from "@mui/material/Box"
import { HeaderLogo, Seo } from "../components"

const styles = {
  subsectionHeader: {
    textDecoration: "underline",
  },
  definedTerm: {
    fontWeight: "bold",
  },
} as const

function TermsPage() {
  const { t } = useTranslation("common", { useSuspense: typeof window !== "undefined" })

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
      }}
    >
      <Seo title={t("termsOfService", "Terms of Service")} />
      <main>
        <Box
          sx={{
            backgroundColor: "#212121",
          }}
        >
          <Box
            sx={{
              width: "16.625rem",
              minWidth: 266,
              padding: "1rem",
              paddingBottom: "0.5rem",
            }}
          >
            <Link to="/">
              <HeaderLogo height="auto" width="100%" />
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              textAlign: "left",
              padding: "1.875rem",
              paddingTop: "3.125rem",
              maxWidth: 1000,
              letterSpacing: 0,
              fontSize: "1rem",
              lineHeight: "1.5rem",
              margin: "0 0 1rem",

              "& h1": {
                marginBottom: "2.5rem",
                fontSize: "1.5rem",
              },

              "& h2": {
                marginTop: "2.5rem",
              },

              "& h3": {
                marginTop: "1.875rem",
              },

              "& dd": {
                marginBottom: "0.75rem",
              },

              "& ul": {
                listStyle: "none",
                "& li": {
                  marginBottom: "0.5rem",
                },
              },
            }}
          >
            <h1>Terms of Service for GoliathTech Franchisees</h1>
            <section>
              <p>
                This Software-as-a-Service Agreement (“A​greement​”) is between CodeSwyft LLC, a
                Minnesota limited liability company, and the entity accepting the terms of this
                Agreement (“​Client​”), effective as of the date of such acceptance (“​Effective
                Date​”). Fielder is a service provided by CodeSwyft. The terms and conditions in the
                Agreement are subject to the execution of an Order Form by the parties, and the
                parties expressly acknowledge that the terms and conditions herein govern any Order
                Form(s) executed between the parties relating to the Subscription Services purchased
                therein. All capitalized terms used but not defined in any Order Form(s) or other
                documents expressly incorporating this Agreement shall have the meanings given in
                this Agreement.
              </p>
              <p>
                BY PURCHASING A SUBSCRIPTION OR USING OR INSTALLING ANY PART OF THE SUBSCRIPTION
                SERVICES (AS DEFINED BELOW), CLIENT EXPRESSLY AGREES TO BE BOUND BY ALL OF THE TERMS
                OF THIS AGREEMENT. IF CLIENT CHOOSES TO NOT ACCEPT ALL OF THE TERMS OF THIS
                AGREEMENT, THEN CLIENT MAY NOT ACCESS, USE, OR INSTALL ANY PART OF THE SUBSCRIPTION
                SERVICES.
              </p>
            </section>
            <section>
              <h2>Table of Contents</h2>
              <ul>
                <li>
                  <a href="#section1">1. Subscription Services</a>
                </li>
                <li>
                  <a href="#section2">2. Pricing, Payment Terms, Taxes</a>
                </li>
                <li>
                  <a href="#section3">3. Confidentiality</a>
                </li>
                <li>
                  <a href="#section4">4. Trademarks</a>
                </li>
                <li>
                  <a href="#section5">5. Ownership</a>
                </li>
                <li>
                  <a href="#section6">6. Warranties and Limitation of Liability</a>
                </li>
                <li>
                  <a href="#section7">7. Indemnification</a>
                </li>
                <li>
                  <a href="#section8">8. Term and Termination</a>
                </li>
                <li>
                  <a href="#section9">9. General Provisions</a>
                </li>
              </ul>
            </section>
            <section id="section1">
              <h2>1. SUBSCRIPTION SERVICES</h2>
              <h3>1.1 SUBSCRIPTION SERVICES</h3>
              <p>
                1.1.1 <span css={styles.subsectionHeader}>GENERAL</span> Subject to the terms and
                conditions of this Agreement and the execution of an Order Form, during the Term (as
                defined below) CodeSwyft grants to Client’s Permitted Users, on a
                software-as-a-service provider basis, a non-exclusive, non-transferable right: (i)
                to use its software-as-a-service offerings during the Term solely for Client’s
                internal business operations, all in accordance with the terms of this Agreement
                (collectively, the “​Subscription Services​”); (ii) to enter Client Data (as defined
                below) into the Subscription Services, (iii) to configure certain aspects of the
                Subscription Services, (iv) to use the Subscription Services via the internet for
                the Authorized Purpose; and (v) to download and use the CodeSwyft App on devices
                owned or controlled by Permitted Users.
              </p>
              <p>
                1.1.2 <span css={styles.subsectionHeader}>CERTAIN DEFINED TERMS</span>
              </p>
              <dl>
                <dt>
                  1.1.2.1. <span css={styles.definedTerm}>Authorized Purpose</span>
                </dt>
                <dd>
                  Client’s internal business purposes relating to or arising from field service
                  management, including, but not limited to, scheduling, allocating workflows,
                  invoicing, customer relationship management, and other field management tasks
                  based upon Client Data, and analyzing Client Data provided by the Permitted Users.
                </dd>
                <dt>
                  1.1.2.2. <span css={styles.definedTerm}>Client Data</span>
                </dt>
                <dd>
                  All data and information that Client or any Permitted User inputs into the
                  Subscription Services in connection with their use thereof including, but not
                  limited to, information relating to Client’s field service operations including,
                  but not limited to, service calls and service orders, activities, assignments and
                  tasks, jobs, customer and business partner needs, contracts, sales, equipment and
                  materials costs, needs, time capture (including project and assignment based time
                  tracking, productive and non-productive hours, travel time and absences), geo
                  location of Client’s employees, customer relationship management, invoicing,
                  scheduling, and calendar and availability data.
                </dd>
                <dt>
                  1.1.2.3. <span css={styles.definedTerm}>Fielder App</span>
                </dt>
                <dd>
                  CodeSwyft’s proprietary mobile application available for use in connection with
                  the Subscription Services.
                </dd>
                <dt>
                  1.1.2.4. <span css={styles.definedTerm}>Order Form</span>
                </dt>
                <dd>
                  Any agreement entered hereunder for CodeSwyft’s provision of the Subscription
                  Services and other services to Client, on the specified terms, conditions, and
                  fees set forth therein, as may be amended from time to time upon mutual agreement
                  by Client and CodeSwyft. The Order Form may be accessed on the Fielder website and
                  may be updated from time to time.
                </dd>
                <dt>
                  1.1.2.5. <span css={styles.definedTerm}>Permitted User</span>
                </dt>
                <dd>
                  Client’s employees, agents and representatives (and replacements thereto) who
                  individually have been registered by name to access Subscription Services on
                  behalf of Client. The number of Permitted Users is set forth on an executed Order
                  Form. Permitted User login information may not be shared. Permitted User login
                  information may not be shared. CodeSwyft may update and modify the Subscription
                  Services from time to time in its sole discretion.
                </dd>
              </dl>
              <h3>1.2 PRIVATE LABEL</h3>
              <p>
                Client may request that CodeSwyft re-brand the Subscription Services with Client’s
                legally compliant existing branding (“​Private Label​”); provided, however, at the
                request of CodeSwyft a tag line of “Powered by Fielder” or similar phrase will be
                displayed as reasonably determined by CodeSwyft in connection with the Subscription
                Services and shall be subject to CodeSwyft’s approval.
              </p>
              <h3>1.3 FIELDER APP</h3>
              <p>
                Each Permitted User shall have a non-transferable, non-exclusive, revocable license
                to download, install and use one copy of the Fielder App on a mobile device that
                such Permitted User owns or controls for such Permitted User’s use (the
                “​License​”); provided, however, if the Fielder App, or other application related to
                the Subscription Services, is accessed or downloaded from a third-party store such
                as the Apple App Store or Google Play (each, an “​App Store​”), such Permitted User
                agrees to comply with, and that the License is expressly conditioned upon, the
                Permitted User’s compliance with all applicable terms and policies governing the
                applicable App Store(s).
              </p>
              <h3>1.4 FRANCHISOR</h3>
              <p>
                “Franchisor” means GOLIATHTECH INC., a Canadian corporation, which Client
                acknowledges has entered into a separate Order Form, governed by a separate
                software-as-a-service agreement with CodeSwyft (the “CodeSwyft-Franchisor
                Agreement”), under which Franchisor has purchased substantially similar subscription
                services (“Franchisor Subscription Services”) as Client has under an applicable
                Order Form entered under this Agreement. As part of the Subscription Services,
                Client agrees that Franchisor will have access to and the ability to review,
                analyze, edit, and upload any Client Data, and any data of Franchisor (“Franchisor
                Data”), the Subscription Services, or any part thereof, or pursuant to the
                CodeSwyft-Franchisor Agreement. Client will not have access to any Franchisor Data,
                Franchisor Subscription Services, nor any data or information of any of Franchisor’s
                other franchisees, if any, unless specifically made available to Client by
                Franchisor.
              </p>
              <h3>1.5 RESTRICTIONS</h3>
              <p>
                Client is responsible for all activities of its suppliers, Permitted Users and End
                Users and their compliance with this Agreement. “​End User​” means any individual or
                entity that directly or indirectly through another user: (i) accesses or uses Client
                Data; or (ii) accesses or uses the Subscription Services under Client’s account or
                its Permitted User logins. Client shall not and shall not permit any third party to:
                (a) copy, translate, create a derivative work of, reverse engineer, reverse
                assemble, disassemble, or decompile the Subscription Services or any part thereof or
                otherwise attempt to discover any code (source or object) or modify the Subscription
                Services in any manner or form; (b) use the Subscription Services for the purposes
                of building a similar or competitive product or service; (c) use the Subscription
                Services in a manner that is contrary to applicable Law (as defined below) or in
                violation of the rights of any third party, including privacy or intellectual
                property rights; (d) publish, post, upload or otherwise transmit data that may
                contain viruses, trojan horses, worms, time bombs, corrupted files or other computer
                programming routines that damage, detrimentally interfere with, surreptitiously
                intercept or expropriate any systems, data, personal information or property of
                another; (e) use the Subscription Services to upload, collect, create or transmit
                any Protected Health Information “PHI” as defined in 45 C.F.R. 160.103 (or any
                replacement or similar Laws); (f) other than in connection with paying the Fees,
                submit or upload any credit card information, or any other information that would be
                regulated by the Payment Card Industry Data Security Standard, to or through the
                Subscription Services; or (g) use or knowingly permit the use of any security
                testing tools in order to probe, scan or attempt to penetrate or ascertain the
                security of the Subscription Services.
              </p>
              <h3>1.6 HARDWARE AND SERVICES</h3>
              <p>
                Client is responsible for any and all telecommunication services, internet access,
                hardware and third party software and services necessary or helpful for Client to
                access the Subscription Services.
              </p>
              <h3>1.7 INTEGRATION</h3>
              <p>
                As part of any Client-purchased Professional Services (defined herein) and to
                facilitate the Subscription Services, CodeSwyft is hereby authorized to (i)
                integrate its systems and information with suppliers to facilitate the exchange of
                Client Data with suppliers (“​Integration​”) and (ii) accept from and deliver to
                suppliers Client Data and other such information necessary or appropriate to
                facilitate the Subscription Services. Any Integration or other exchange of Client
                Data with suppliers is solely for the benefit of Client, therefore, under no
                circumstances is CodeSwyft responsible in any way, nor shall it be liable, for the
                exchange of Client Data or other information with suppliers, any acts or omissions
                of suppliers or any Integration.
              </p>
              <h3>1.8 PROFESSIONAL SERVICES</h3>
              <p>
                CodeSwyft will perform any services to configure its system to meet Client’s
                particular needs (the “​Professional Services​”) as agreed to in an executed Order
                Form including, but not limited to, Integration. All work product produced by
                CodeSwyft (“​Work Product​”) shall not be considered a “work for hire” under any
                applicable law, and shall be owned exclusively by CodeSwyft; provided, however, all
                Client owned and provided content (“​Client Content​”) shall remain the property of
                Client. CodeSwyft hereby grants to Client a non-exclusive, non-assignable, limited
                right to use the Work Product during the Term in connection with the Subscription
                Services. Changes within the scope of the Professional Services shall be made only
                in writing and executed by authorized representatives of both parties. CodeSwyft
                shall have no obligation to commence work in connection with any change until it is
                executed in writing.
              </p>
              <h3>1.9 SUPPORT SERVICES</h3>
              <p>
                CodeSwyft will perform maintenance and technical support services offered to Client
                as set forth in an executed Order Form (“​Support Services​”).
              </p>
              <h3>1.10 IMPLEMENTATION SERVICES</h3>
              <p>
                CodeSwyft will perform implementation and program support services offered to Client
                as set forth in an executed Order Form (“​Implementation Services​”).
              </p>
              <h3>1.11 CHANGES TO PLATFORM</h3>
              <p>
                CodeSwyft may, in its sole discretion, make any changes to Subscription Services
                that it deems necessary or useful to (i) maintain or enhance (a) the quality or
                delivery of CodeSwyft’s products or services to its customers, (b) the competitive
                strength of, or market for, CodeSwyft’s products or services, (c) such Subscription
                Service’s cost efficiency or performance, or (ii) to comply with applicable law.
              </p>
            </section>
            <section id="section2">
              <h2>2. PRICING, PAYMENT TERMS, TAXES</h2>
              <p>
                <strong>Short version: </strong>
                <em>
                  You are responsible for any fees associated with your use of Fielder. We are
                  responsible for communicating those fees to you clearly and accurately, and
                  letting you know well in advance if those prices change. You are responsible for
                  paying any sales taxes associated with your subscription. Your subscription has
                  monthly job limits. Exceeding your subscription's monthly job limit will result in
                  extra fees for each job over the limit.
                </em>
              </p>
              <h3>2.1 PRICING, PAYMENT TERMS, TAXES</h3>
              <p>
                The Order Form will present Client with a variety of plans ("Plan"), or options, to
                choose from. The plans are primarily differentiated by a monthly Job limit. (Plans
                with a lower monthly Job limit generally cost less, on an absolute basis, than plans
                with higher monthly Job limits). A plan may include a one-time setup fee. Client may
                choose to pay annually or monthly (hereafter referred as "Payment Interval"). The
                Order Form may offer a discounted fee for choosing the annual payment option. If
                Client creates more Jobs in any month than their selected Plan allows for, then
                CodeSwyft will invoice Client for an additional amount equal to a per-Job rate as
                specified on the Order Form for each excess Job.
              </p>
              <p>
                Client shall pay CodeSwyft the fees for the Subscription Services, and, if
                applicable, the Professional Services, Support Services and/or Implementation
                Services in accordance with the selected Plan and Payment Interval on the Order Form
                (collectively, the “​Fees​”). All Fees shall be in U.S. dollars. The Subscription
                Services, and other Services, as applicable, and additional terms of use may be set
                forth in the Order Form. Subscription Services will renew automatically at the same
                interval and level of service, unless Client requests a modification. Pricing for
                renewals shall be set at then current CodeSwyft pricing unless agreed upon by both
                parties prior to the expiration of the then current Term. All Fees due under this
                Agreement and all applicable Order Forms are non-cancelable, and sums paid are
                nonrefundable.
              </p>
              <h3>2.2 PAYMENT TERMS</h3>
              <p>
                All Fees are due and payable as set forth under the applicable Order Form. CodeSwyft
                reserves the right to charge interest at the rate of one and one-half percent (1½%)
                per month on overdue amounts commencing with the date payment was first due. In
                addition to any other remedies available, CodeSwyft may suspend all Services in the
                event of payment delinquency.
              </p>
              <h3>2.3 TAXES</h3>
              <p>
                Unless otherwise stated, the Fees do not include any direct or indirect local,
                state, federal or foreign taxes, levies, duties or similar governmental assessments
                of any nature, including value-added, use or withholding taxes (collectively,
                “​Taxes”​ ). Client is responsible for paying all Taxes associated with its
                purchases and use of the services hereunder, excluding taxes based on CodeSwyft’s
                net income or property. If CodeSwyft has the legal obligation to pay or collect
                Taxes for which Client is responsible, the appropriate amount shall be invoiced to
                and paid by Client, unless Client provides CodeSwyft with a valid tax exemption
                certificate authorized by the appropriate taxing entity.
              </p>
              <h3>2.4 PAYMENT DISPUTES</h3>
              <p>
                If Client believes that CodeSwyft has billed Client incorrectly, Client must contact
                CodeSwyft no later than thirty (30) days after the closing date on the first billing
                statement in which the believed error or problem appeared in order to receive an
                adjustment or credit. Inquiries should be directed to CodeSwyft’s customer support
                department or the applicable Account Manager.
              </p>
            </section>
            <section id="section3">
              <h2>3. CONFIDENTIALITY</h2>
              <h3>3.1 OBLIGATIONS</h3>
              <p>
                “​Confidential Information​” shall mean and include all proprietary and confidential
                information of and provided by the disclosing party to the receiving party pursuant
                to this Agreement, whether or not marked as confidential. All Confidential
                Information shall remain the sole property of the disclosing party, and the
                receiving party shall not use Confidential Information obtained from the disclosing
                party for any purpose other than performing its obligations or exercising its rights
                under this Agreement. Each party shall use reasonable care and discretion to avoid
                unintended disclosure, publication or dissemination of the other party’s
                Confidential Information. Client Data shall be deemed Confidential Information;
                provided, however, (i) CodeSwyft shall have the right to use and disclose Client
                Data in connection with the performance of its obligations and exercise of its
                rights under this Agreement, including disclosure to suppliers, and disclosure to
                third parties that CodeSwyft utilizes in connection with the performance of its
                obligations under this Agreement that are under confidentiality obligations similar
                to those contained herein and (ii) to the extent this Section conflicts with the
                Security Standards (defined below), the Security Standards shall control. The
                restrictions of this ​Section 3 shall not apply to Confidential Information to the
                extent it is required to be produced in compliance with applicable Law or a court
                order, and provided the receiving party first gives the disclosing party reasonable
                notice of such Law or order (if so permitted) to permit the disclosing party an
                opportunity to object to and/or attempt to limit such production.
              </p>
              <h3>3.2 EXCLUSIONS</h3>
              <p>
                Information will not be considered Confidential Information if it is: (i) already
                available to the public other than by a breach of this Agreement; (ii) rightfully
                received from a third party not in breach of any obligation of confidentiality;
                (iii) independently developed by personnel or agents of one party without access to
                the Confidential Information of the other; or (iv) proven to be already known to the
                recipient at the time of disclosure.
              </p>
              <h3>3.3 RETURN OF MATERIALS</h3>
              <p>
                Upon request of a disclosing party or termination or expiration of this Agreement
                for any reason, the receiving party shall return to the disclosing party all
                Confidential Information of the disclosing party. Notwithstanding the foregoing,
                either party may continue to possess certain Confidential Information of the other
                for any and all time periods as may be required for the party to comply with
                applicable Law and in connection with a party’s standard security or disaster
                recovery process; provided, in such event, this Section shall continue to apply to
                such Confidential Information.
              </p>
            </section>
            <section id="section4">
              <h2>4. TRADEMARKS</h2>
              <h3>4.1 CODESWYFT MARKS</h3>
              <p>
                The names and marks “CodeSwyft,” “CS,”, "Fielder", and any other names, marks or
                logos used by CodeSwyft to represent CodeSwyft, CodeSwyft’s products, services and
                platforms are trade names, trademarks or service marks of CodeSwyft, whether
                registered or not (collectively, the “​CS Marks​”). Client shall acquire no right to
                or interest in the CS Marks and will not use the CS Marks without CodeSwyft’s
                written consent. Client will not take any actions that may reduce, eliminate,
                jeopardize or affect the enforceability or ownership interest of CodeSwyft in the CS
                Marks or use any trademark, service mark, trade name, logo, internet domain name or
                design which is the same or substantially similar to the CS Marks. Client will not
                remove, obscure or alter CodeSwyft’s or its licensors’ proprietary notices,
                disclaimers, trademarks or other proprietary rights notices of any kind affixed or
                contained in the Subscription Services, or any written or electronic report, output
                or result generated in connection with the Subscription Services.
              </p>
            </section>
            <section id="section5">
              <h2>5. OWNERSHIP</h2>
              <h3>5.1 CODESWYFT PROPRIETARY RIGHTS</h3>
              <p>
                Other than Client Content, the Subscription Services (including the graphical user
                interfaces and the look and feel of the Subscription Services), CS Marks,
                CodeSwyft’s content, ideas, methods of operation, processes, aesthetic aspects,
                know-how, concepts, compilations, software, work product, documentation, sub-systems
                and modules used by CodeSwyft in connection with providing the Subscription
                Services, Professional Services, and any other services (collectively, the
                “Services”), and all modifications, enhancements, updates, customizations,
                derivative works and translations thereto, and all intellectual property rights
                associated therewith, are owned exclusively by CodeSwyft and/or its licensors at all
                times, and shall be deemed to be proprietary materials which contain valuable trade
                secrets of CodeSwyft and/or its licensors. All right, title and interest in and to
                any third-party content which may be accessed through use of the Services is the
                property of the respective content suppliers. If Client provides any suggestions to
                CodeSwyft or its affiliates, CodeSwyft and its affiliates will own and be entitled
                to use the suggestions without restriction. Client hereby irrevocably assigns to
                CodeSwyft all right, title, and interest in and to all suggestions and will provide
                any reasonable assistance CodeSwyft requires to document, perfect, and maintain
                CodeSwyft’s rights in such suggestions. Nothing shall be deemed to prevent or
                restrict CodeSwyft’s ability to provide the Services or other technology, including
                any features or functionality first developed for Client, to other parties.
                CodeSwyft may use Client’s name and logo in presentations, marketing or other
                promotional materials, client lists and web site listings. CodeSwyft will have the
                right to use anonymized Client Data for its own business purposes to the extent
                permitted by applicable law.
              </p>
              <h3>5.2 CLIENT PROPRIETARY RIGHTS</h3>
              <p>
                Client grants to CodeSwyft the non-exclusive, worldwide, right to use, copy, store,
                transmit and display Client Data in order for CodeSwyft to perform its obligations
                under this Agreement and in connection with sharing Client Data with suppliers and
                through one or more Integrations. Client represents, warrants and covenants that it
                has the right to possess and provide the Client Data to CodeSwyft and CodeSwyft has
                the right to use the Client Data for uses as contemplated by this Agreement.
                CodeSwyft may use Client’s name and logo in presentations, marketing materials,
                client lists and web site listings. CodeSwyft will have the right to use anonymized
                Client Data for its own business purposes to the extent permitted by applicable law.
              </p>
            </section>
            <section id="section6">
              <h2>6. WARRANTIES AND LIMITATION OF LIABILITY</h2>
              <h3>6.1 COMPLIANCE; LAWS</h3>
              <p>
                In connection with this Agreement, CodeSwyft and Client shall comply with their
                respective obligations related to all applicable international, federal, state and
                local laws, rules, regulations and orders (each a “​Law​” and collectively,
                “​Laws​”).
              </p>
              <h3>6.2 CLIENT REPRESENTATIONS AND WARRANTIES</h3>
              <p>
                Client represents and warrants as follows:
                <ul>
                  <li>
                    <strong>6.2.1.</strong> Client has and will not post or transmit on or through
                    the Services any libelous, obscene or otherwise unlawful information of any
                    kind.
                  </li>
                  <li>
                    <strong>6.2.2.</strong> Client has and will not engage in any conduct involving
                    the Services that would constitute a criminal offense or give rise to civil
                    liability under or violate any Law.
                  </li>
                  <li>
                    <strong>6.2.3.</strong> Client has and will not upload, post, reproduce or
                    distribute to or through the Services any material protected by copyright,
                    privacy, confidentiality, Law or other proprietary right without first obtaining
                    written consent from the owner thereof.
                  </li>
                  <li>
                    <strong>6.2.4.</strong> Client will cooperate with CodeSwyft in CodeSwyft’s
                    investigation and remediation of any security, unauthorized use or misuse
                    arising from or related to the Services.
                  </li>
                  <li>
                    <strong>6.2.5.</strong> All Permitted User names, passwords and information to
                    access the Subscription Services (collectively, “​Access Information​”) are the
                    responsibility of Client. Client shall prevent unauthorized access to the
                    Subscription Services. Access Information shall be requested by Client only for
                    Client personnel with a legitimate business need to use and access the
                    Subscription Services. If a Permitted User is no longer otherwise authorized by
                    Client to use the Subscription Services, any previously issued Access
                    Information will remain active and permit access to the Subscription Services
                    until that access is changed by Client. Client is fully liable for any and all
                    acts and omissions of its personnel, representatives and agents.
                  </li>
                </ul>
              </p>
              <h3>6.3 CODESWYFT REPRESENTATIONS AND WARRANTIES</h3>
              <p>
                CodeSwyft represents and warrants as follows:
                <ul>
                  <li>
                    <strong>6.3.1.</strong> CodeSwyft will ​<u>provide</u> the Services to Client on
                    a commercially reasonable efforts basis, consistent with generally accepted and
                    reasonable industry standards.
                  </li>
                  <li>
                    <strong>6.3.2.</strong> CodeSwyft has implemented the technical and
                    organizational measures substantially consistent with industry ​<u>standards</u>{" "}
                    designed to help secure Client Data in CodeSwyft’s possession from unauthorized
                    disclosure or accidental destruction; provided, however, Client is solely
                    responsible for and shall ensure the accuracy, quality, integrity, legality,
                    reliability and appropriateness of Client Data (<b>“​Security Standards​”</b>).
                    Client acknowledges and agrees that (i) no computer, network, storage or other
                    security is risk free or impenetrable, (ii) the Services should not be relied
                    upon as the sole repository of Client Data, (iii) Client is responsible for
                    maintaining back-up and archival copies of all Client Data, (iv) CodeSwyft is
                    not responsible for any unauthorized access to or the destruction of Client Data
                    and (v) the use of the Subscription Services will not be uninterrupted or
                    error-free. Client acknowledges that CodeSwyft is not in control of the transfer
                    of Client Data over communication facilities, including the internet, and that
                    the Services may be subject to limitations, delays, and other problems inherent
                    in the use of such communication facilities. Client acknowledges that CodeSwyft
                    is not responsible for any delays, delivery failures, or other damages resulting
                    from such problems, and that Client releases CodeSwyft from any and all
                    liability and damages resulting from such problems.
                  </li>
                  <li>
                    <strong>6.3.3.</strong> CodeSwyft has implemented a disaster recovery plan (the{" "}
                    <b>“​Disaster Recovery Plan​”</b>). The Disaster Recovery Plan will be in
                    substantial compliance with all requirements necessary to obtain ISO-9001
                    certification; provided, however, that CodeSwyft does not make any guarantees
                    that there will be no loss of Client Data and liability is expressly excluded
                    for any loss of Client Data no matter the cause.
                  </li>
                </ul>
              </p>
              <h3>6.4 WARRANTY DISCLAIMER</h3>
              <p>
                OTHER THAN CODESWYFT’S EXPRESS LIMITED WARRANTIES IN THIS SECTION 6, ALL SERVICES
                ARE PROVIDED “AS IS” AND CODESWYFT DISCLAIMS AND CLIENT WAIVES ALL OTHER
                REPRESENTATIONS, WARRANTIES, GUARANTEES, INDEMNITIES AND OBLIGATIONS, EXPRESS OR
                IMPLIED, ARISING BY LAW OR OTHERWISE, WITH RESPECT TO ANY PRODUCTS AND/OR SERVICES,
                CS MARKS, DATA, CODESWYFT OBLIGATION OR OTHER ITEMS DELIVERED OR MADE AVAILABLE BY
                OR ON BEHALF OF CODESWYFT UNDER OR RELATED TO THIS AGREEMENT, INCLUDING ANY IMPLIED
                WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR ARISING FROM COURSE
                OF PERFORMANCE, COURSE OF DEALING OR USAGE OF TRADE. EXCEPT AS SET FORTH HEREIN,
                CODESWYFT DOES NOT ASSUME ANY LIABILITY OR RESPONSIBILITY FOR THE SERVICES;
                CODESWYFT DOES NOT ASSUME ANY LIABILITY OR RESPONSIBILITY FOR ACTS OR OMISSIONS OF
                ANY THIRD PARTIES.
              </p>
              <h3>6.5 LIMITS ON TYPE OF DAMAGES</h3>
              <p>
                NOTWITHSTANDING ANY OTHER TERM OF THIS AGREEMENT, IN NO EVENT SHALL CODESWYFT, ITS
                LICENSORS OR SUPPLIERS, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
                SHAREHOLDERS, AGENTS OR REPRESENTATIVES, BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                PUNITIVE, EXEMPLARY, SPECIAL OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF PROFITS,
                REVENUE, GOODWILL, DATA, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION) INCURRED BY
                CLIENT, ANY AFFILIATE OF CLIENT OR ANY THIRD PARTY, IN ANY WAY RELATING TO THIS
                AGREEMENT OR RESULTING FROM THE USE OF OR INABILITY TO USE ANY SERVICES, OR THE
                PERFORMANCE OR NON-PERFORMANCE OF ANY OBLIGATION, INCLUDING THE FAILURE OF ESSENTIAL
                PURPOSE, EVEN IF CODESWYFT HAS BEEN NOTIFIED OF THE POSSIBILITY OR LIKELIHOOD OF
                SUCH DAMAGES.
              </p>
              <h3>6.6 CAP ON DAMAGES</h3>
              <p>
                WITHOUT LIMITING THE FOREGOING: (i) IN NO EVENT WILL CODESWYFT’S AGGREGATE LIABILITY
                UNDER THIS AGREEMENT FOR CLAIMS BY OR THROUGH CLIENT EXCEED THE GREATER OF (A) THE
                AMOUNT PAID OR PAYABLE TO CODESWYFT BY CLIENT IN THE SIX (6) MONTH PERIOD PRIOR TO
                THE EVENT GIVING RISE TO SUCH LIABILITY AND (B) THE ACTUAL INSURANCE PROCEEDS
                RECEIVED BY CODESWYFT IN CONNECTION WITH THE EVENT GIVING RISE TO SUCH CLAIM; AND
                (ii) ANY AND ALL CLAIMS THAT CLIENT HAS AGAINST CODESWYFT, WHETHER OR NOT CLIENT IS
                AWARE OF SUCH CLAIMS, MUST BE BROUGHT WITHIN THE SIX (6) MONTH PERIOD FOLLOWING THE
                DATE THAT SUCH CLAIM FIRST AROSE. THE LIMITATIONS AND EXCLUSIONS SET FORTH IN THIS
                AGREEMENT SHALL APPLY (I) TO ALL CAUSES OF ACTION IN THE AGGREGATE, WHETHER BASED IN
                CONTRACT, WARRANTY, TORT OR ANY OTHER LEGAL THEORY (INCLUDING STRICT LIABILITY) AND
                (II) REGARDLESS OF THE FORM OR SOURCE OF ACTION, AND REGARDLESS OF ANY OBLIGATIONS
                STATED UNDER THIS AGREEMENT. THE LIMITATIONS ON DAMAGES AS SET FORTH ABOVE WILL
                APPLY EVEN IN THE EVENT OF A FAILURE OF THE FUNDAMENTAL PURPOSE OF THIS AGREEMENT.
              </p>
            </section>
            <section id="section7">
              <h2>7. INDEMNIFICATION</h2>
              <h3>7.1 INDEMNIFICATION</h3>
              <p>
                Subject to the limitations and exceptions set forth in this Agreement, CodeSwyft and
                Client shall each defend and indemnify the other against all liability incurred by a
                party resulting from third-party claims directly resulting from or related to any
                breach of this Agreement by such indemnifying party. In addition, Client shall
                defend and indemnify CodeSwyft against all liability incurred by CodeSwyft from any
                claims related to or arising out of: (i) suppliers (or their acts or omissions),
                (ii) any disputes between Client or a supplier and any End User, (iii) use of the
                Services by End Users, (iv) Client Data, or (v) any Integration.
              </p>
              <h3>7.2 NOTICE</h3>
              <p>
                The indemnified party shall give the indemnifying party prompt written notice of any
                claim, however, failure or delay in giving notice does not diminish indemnity
                obligations unless and then only to the extent that the indemnifying party’s ability
                to defend the claim is adversely affected thereby. The indemnifying party shall
                promptly confirm in writing that it will assume and control the defense or
                settlement of the claim. The indemnified party shall reasonably cooperate at the
                indemnifying party’s expense. Any settlement that requires an admission or payment
                by the indemnified party is subject to its written approval. If the indemnified
                party wishes to retain separate counsel, it may do so at its own expense unless the
                written opinion of either party’s counsel confirms that use of the same counsel
                would be a conflict of interest. In that case, the indemnifying party shall pay all
                reasonable expenses and costs of the indemnified party’s retained counsel relating
                to the claim. If the indemnifying party fails to defend and/or indemnify promptly as
                provided above and the indemnified party is therefore required to defend or settle
                the claim itself, then (if the indemnified party successfully enforces these
                indemnity obligations against the indemnifying party) the indemnifying party shall
                reimburse the indemnified party for all reasonable expenses and costs (including
                without limitation attorneys’ fees and expenses) incurred in successfully enforcing
                these indemnity obligations and in defending or settling the claim.
              </p>
            </section>
            <section id="section8">
              <h2>8. TERM AND TERMINATION</h2>
              <h3>8.1 TERM</h3>
              <p>
                The term of this Agreement shall commence on the Effective Date and shall continue
                for a period of three (3) years (the “​Term”​). Thereafter, the subscription Term of
                the applicable Subscription Services shall automatically renew for successive one
                (1) year terms unless either party provides written notice of non-renewal to the
                other party at least thirty (30) days before such expiration. To the extent new
                Order Forms are executed following the Term, this Agreement shall be deemed revived
                and renewed.
              </p>
              <h3>8.2 TERMINATION RIGHTS</h3>
              <p>
                8.2.1 <span css={styles.subsectionHeader}>TERMINATION FOR CAUSE</span> This
                Agreement or any Order Form may be terminated by the non-breaching party as a result
                of a material breach of this Agreement by the other party, but only after the
                breaching party is given written notice of intent to terminate and such party fails
                to cure such breach within thirty (30) days of its receipt of such notice. If the
                material breach arises from Client’s failure to pay any Fees due and payable, or any
                other breach hereunder by Client, CodeSwyft may immediately suspend all Permitted
                User login information and access to or use of the Services by Client, Permitted
                Users, and/or End Users, unless and until such breach is cured within the timeframe
                set forth in this Section 8.2.1.
              </p>
              <p>
                8.2.2 <span css={styles.subsectionHeader}>CODESWYFT TERMINATION</span> Without
                limiting the applicability of any other provision in this Agreement, CodeSwyft may
                terminate this Agreement and/or any Order Form(s) or immediately suspend Client’s
                and all End Users’ access to the Services at any time upon notice if CodeSwyft
                determines: (a) Client’s or an End User’s use of the Services (i) poses a security
                risk to the Services or any third party, (ii) could adversely impact CodeSwyft’s
                systems, the Services or the systems or content of any other customer or supplier,
                (iii) could subject CodeSwyft, CodeSwyft’s affiliates or any third party to
                liability, or (iv) could be fraudulent for reasons including, but not limited to, an
                End User sharing its Permitted User login information; (b) Client or any End User is
                in breach of this Agreement; or (c) Client has ceased to operate in the ordinary
                course, made an assignment for the benefit of creditors or similar disposition of
                Client’s assets, or become the subject of any bankruptcy, reorganization,
                liquidation, dissolution or similar proceeding; provided, however, CodeSwyft will
                endeavor to provide notice to Client as long as no imminent harm or damages are, in
                CodeSwyft’s judgement, likely to occur. Client will not be entitled to any
                forgiveness or reduction in Fees nor will it have any other rights arising therefrom
                under this Agreement. All rights not expressly granted to Client are reserved by
                CodeSwyft and its licensors.
              </p>
              <h3>8.3 EFFECT OF TERMINATION</h3>
              <p>
                Upon the effective date of termination of this Agreement, all Order Forms entered
                hereunder shall terminate and CodeSwyft shall immediately cease providing all
                Services except as may be specifically approved in writing by both parties prior to
                the termination. During and following the Term, neither party shall intentionally
                act, nor cause a third party to act, in any way that will disparage or otherwise
                damage the reputation or goodwill of the other party or the other party’s products
                and services. Following termination, CodeSwyft shall have no obligation to retain
                any Client Data and may irretrievably delete all Client Data sixty (60) days
                following the effective date of Termination.
              </p>
            </section>
            <section id="section9">
              <h2>9. GENERAL PROVISIONS</h2>
              <h3>9.1 FORCE MAJEURE</h3>
              <p>
                Neither CodeSwyft nor its affiliates will be liable or responsible for any delay or
                failure to perform any obligation under this Agreement where the delay or failure
                results from any cause beyond their reasonable control, including acts of God, labor
                disputes or other industrial disturbances, electrical or power outages, utilities or
                other telecommunications failures, earthquake, storms or other elements of nature,
                pandemics or epidemics, blockages, embargoes, riots, civil unrest, acts or orders of
                government, acts of terrorism or war (each, a “​Force Majeure Event​”).
              </p>
              <h3>9.2 EXPORT COMPLIANCE</h3>
              <p>
                Aspects of the Subscription Services, and any derivatives therefrom, may be subject
                to export laws and regulations of the United States and other jurisdictions. Client
                agrees that such export control laws govern Client and any End User’s use of the
                Subscription Services and any other Services provided under this Agreement. Client
                will not permit any End User to access or use the Services in a U.S.- embargoed
                country or region or in violation of any applicable export law or regulation.
              </p>
              <h3>9.3 NOTICES</h3>
              <p>
                All notices or other communications required or permitted to be given under this
                Agreement shall be in writing and addressed to the other party at its principal
                place of business or as otherwise designated in writing, and shall be deemed
                effectively given on the earliest of: (i) when delivered, if personally delivered;
                (ii) on the third (3rd) business day following the date of mailing if delivered by
                certified or registered mail, return receipt requested; (iii) on the date of
                transmission, if delivered by facsimile or e-mail transmission; (iv) the second
                business day if delivered via national overnight delivery or (v) when received by
                the party to whom notice is intended or required to be given.
              </p>
              <h3>9.4 GOVERNING LAW; VENUE</h3>
              <p>
                This Agreement shall be governed by the substantive and procedural laws of the State
                of Minnesota, USA, and Client and CodeSwyft agree to submit to the exclusive
                jurisdiction of, and venue in, the United States District Court for the District or
                Minnesota or the state courts of Minnesota located in Hennepin County, Minnesota in
                any dispute arising out of, or relating to, this Agreement.
              </p>
              <h3>9.5 ATTORNEYS' FEES</h3>
              <p>
                In all disputes and matters whatsoever arising under, in connection with, or
                incident to this Agreement, the prevailing party will be entitled to recover its
                reasonable attorneys’ fees, court costs and other legal related expenses from the
                other party.
              </p>
              <h3>9.6 SEVERABILITY</h3>
              <p>
                The provisions of this Agreement are severable. If any provision of this Agreement
                is held to be invalid, illegal or unenforceable, the validity, legality or
                enforceability of the remaining provisions will in no way be affected or impaired
                thereby.
              </p>
              <h3>9.7 MODIFICATION AND WAIVER</h3>
              <p>
                No amendment to, change or discharge of, any provision for this Agreement shall be
                valid unless in writing and signed by an authorized representative of each of the
                parties. No failure or delay by either party in exercising any right, power or
                remedy shall operate as a waiver of such right, power or remedy, and no waiver shall
                be effective unless it is in writing and signed by the waiving party. If either
                party waives any right, power or remedy, such waiver shall not waive any successive
                or other right, power or remedy the party may have under this Agreement.
              </p>
              <h3>9.8 INTERPRETATION</h3>
              <p>
                Article and section headings are provided for convenience only and are not to be
                used to construe or interpret this Agreement. Whenever the words “include” or
                “including” are used in this Agreement, they will be deemed to be followed by the
                words “without limitation.” Any pre-printed terms and conditions on any materials
                that Client provides (e.g., purchase order forms or invoices) will be null and void
                and of no consequence whatsoever in interpreting the parties’ legal rights and
                responsibilities as they pertain to any of the Services.
              </p>
              <h3>9.9 ASSIGNMENT</h3>
              <p>
                Client may not assign its rights or obligations under this Agreement without prior
                written consent of CodeSwyft (which consent may be withheld for any reason in the
                sole discretion of the party for whom consent is sought), and any attempt at such
                assignment shall be null and void. Subject to the foregoing, this Agreement will be
                binding upon the parties hereto, and their successors and permitted assigns.
              </p>
              <h3>9.10 RELATIONSHIP</h3>
              <p>
                In its performance of this Agreement, CodeSwyft will at all times act as an
                independent contractor, and nothing contained herein may be construed to make
                CodeSwyft an agent, partner or joint venturer of Client.
              </p>
              <h3>9.11 NO THIRD-PARTY BENEFICIARIES</h3>
              <p>
                Nothing in this Agreement is intended to or shall create any third-party
                beneficiaries, whether intended or incidental, and neither party shall make any
                representations to the contrary.
              </p>
              <h3>9.12 SURVIVAL</h3>
              <p>
                Sections ​1.5, 5.2, 1.7, 2.3, 3, 5, 6, 8.3 and 9 shall survive the termination or
                expiration of this Agreement.
              </p>
              <h3>9.13 ENTIRE AGREEMENT​</h3>
              <p>
                This Agreement and any Order Form entered hereto constitutes the entire agreement
                and understanding between CodeSwyft and Client with respect to its subject matter
                and merges and supersedes all prior discussions, agreements and understandings of
                every kind and nature between the parties regarding the subject matter hereof.
              </p>
              <h3>9.14 COUNTERPARTS​</h3>
              <p>
                This ​Agreement may be executed in two or more counterparts, each of which will be
                deemed an original, and all of which ​together​ will constitute one instrument.
              </p>
            </section>
          </Box>
        </Box>
      </main>
    </Box>
  )
}

export default TermsPage
