import React from "react"
import countries from "../util/countries"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import CustomPhoneNumberInput from "./CustomPhoneNumberInput"
import { useTranslation } from "react-i18next"
import es from "react-phone-number-input/locale/es"
import en from "react-phone-number-input/locale/en"
import de from "react-phone-number-input/locale/de"
import fr from "react-phone-number-input/locale/fr"
import nl from "react-phone-number-input/locale/nl"

function getLabelsPackage(i18n: any) {
  switch (i18n?.language) {
    case "en":
    case "en-US":
    case "en-CA":
      return en
    case "de":
      return de
    case "nl-BE":
    case "nl":
    case "nl-NL":
      return nl
    case "es-US":
    case "es":
      return es
    case "fr-CA":
    case "fr":
      return fr
    default:
      return en
  }
}

interface PhoneNumberInputProps {
  readonly countryCallingCodeEditable?: boolean
  readonly defaultCountry?: string
  readonly disabled?: boolean
  readonly label: string
  readonly margin?: string
  onChange(value?: string): void
  onBlur?(event: React.FocusEvent<HTMLElement>): void
  readonly required?: boolean
  readonly value: string
}

function PhoneNumberInput({
  countryCallingCodeEditable = true,
  defaultCountry = "US",
  disabled = false,
  label,
  margin,
  onBlur,
  onChange,
  required = false,
  value,
}: PhoneNumberInputProps) {
  const { t, i18n } = useTranslation()

  const countryCode = countries[defaultCountry] ?? "US"

  return (
    <PhoneInput
      countryCallingCodeEditable={countryCallingCodeEditable}
      defaultCountry={countryCode?.toUpperCase()}
      disabled={disabled}
      inputComponent={CustomPhoneNumberInput}
      international
      label={label}
      labels={getLabelsPackage(i18n)}
      margin={margin}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={(e: any) => e.target.setSelectionRange(3, 20)}
      placeholder={t("component.phoneNumberInput.placeholder")}
      required={required}
      value={value}
    />
  )
}

export default React.memo(PhoneNumberInput)
