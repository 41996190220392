import gql from "graphql-tag"

export const ALL_JOB_ASSIGNMENTS = gql`
  query AllJobAssignments(
    $first: Int
    $startDate: LocalDateTime!
    $endDate: LocalDateTime!
    $assigneeUserId: ID
  ) {
    allJobAssignments(
      input: { first: $first }
      jobAssignmentFilter: {
        assigneeUserId: $assigneeUserId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          job {
            id
            number
            description
            customer {
              id
              name
            }
            workflowStep {
              id
              jobStatus {
                id
                name
              }
            }
            address {
              addressString
              streetNumber
              route
              locality
              latitude
              longitude
            }
          }
          assignees {
            id
            firstName
            lastName
          }
          status
          startDate
          endDate
          isLocked
          workOrder {
            id
          }
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
