import React from "react"

interface WorkOrderTemplateIconProps {
  color?: string
  size?: number
}

function WorkOrderTemplateIcon({ color = "#000", size = 24 }: WorkOrderTemplateIconProps) {
  return (
    <svg
      fill={color}
      height={size}
      viewBox="0 0 40 40"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Subtraction 2">
            <path d="M20.13 29.93a4.06 4.06 0 01-1-.13 4 4 0 01-1-.38 4.57 4.57 0 01-.88-.6 5.83 5.83 0 01-.78-.79 6.61 6.61 0 01-.64-1 7 7 0 01-.49-1.12 8.65 8.65 0 01-.34-1.22v-.52h-1.27a.27.27 0 01-.27-.27.26.26 0 01.27-.26h1.18v-.52c0-.31.05-1.22.11-1.95 0-.23 0-.39.08-.56a2.58 2.58 0 01.57-1.1 4.73 4.73 0 01.76-.86l.26-.2.09-.06c.11-.09.31-.22.49-.34v.3a.88.88 0 001.76 0V16.9l.37-.07a3.93 3.93 0 01.66-.06 1.81 1.81 0 01.71.18v1.3a.88.88 0 101.75 0V18l.64.37a5 5 0 011.15 1.28c.06.08.11.17.18.27a5 5 0 01.22.68c.05.16.09.32.13.45a5.28 5.28 0 01.14.82l-6.73.13a.84.84 0 100 1.68h8a.26.26 0 01.27.26.27.27 0 01-.27.27h-1v.25a2.51 2.51 0 010 .27 7.5 7.5 0 01-.31 1.24 7 7 0 01-.49 1.12 5.9 5.9 0 01-.64 1 6.41 6.41 0 01-.77.79 4.64 4.64 0 01-.89.6 4 4 0 01-1 .38 4.06 4.06 0 01-1.02.07zm-4.54-4.15a6.18 6.18 0 001.73 2.52 4.39 4.39 0 002.81 1.22 5 5 0 003-1.34 3.78 3.78 0 001.55-2.5c0-.64 0-1.31-1-1.53A7.81 7.81 0 0022 24h-3.9a.78.78 0 00-.49.42c-.06.12-.08.13-.12.14l-.55.09c-.64.09-1.14.17-1.34.52a.65.65 0 00-.01.61z" />
            <path d="M15.38 25c.27-.44.85-.53 1.51-.63l.47-.07a1.15 1.15 0 01.47-.47 1.12 1.12 0 01-.75-1.05 1.15 1.15 0 011.16-1.13l6.49-.07a1.91 1.91 0 00-.08-.45c0-.14-.09-.29-.13-.46-.06-.19-.15-.51-.19-.61l-.18-.27a4.71 4.71 0 00-1.08-1.21l-.2-.11a1.16 1.16 0 01-2.31-.2v-1.13a1.22 1.22 0 00-.42-.09 3.54 3.54 0 00-.62.06h-.13v1.16a1.15 1.15 0 01-1.16 1.13 1.17 1.17 0 01-1.14-.91h-.06l-.09.07-.24.18a5.41 5.41 0 00-.72.81 2.28 2.28 0 00-.52 1 3.28 3.28 0 00-.07.52c-.08.85-.13 1.65-.12 1.92a2.37 2.37 0 010 .24v.53l.06.5v.26c0 .08 0 .27.08.4m9.54-.15v-.25a2.34 2.34 0 000-.26l.06-.46H24a1.35 1.35 0 01.86.73 2.25 2.25 0 01.08.24m-4.79 5.33a4.69 4.69 0 01-1.15-.02 5 5 0 01-1-.4 4.81 4.81 0 01-.93-.64 5.86 5.86 0 01-.82-.83 7.18 7.18 0 01-.67-1 7.66 7.66 0 01-.52-1.21 7.85 7.85 0 01-.32-1.29v-.28h-1a.55.55 0 01-.55-.55.55.55 0 01.55-.55h.9a2 2 0 000-.21c0-.34.06-1.28.12-2a4.44 4.44 0 01.08-.59 2.91 2.91 0 01.63-1.22 5.62 5.62 0 01.79-.9 3.51 3.51 0 01.29-.22l.09-.07c.18-.14.59-.4.81-.53a1.1 1.1 0 00.14-.11v.83a.59.59 0 001.18 0v-1.63c.2-.06.41-.1.61-.14a4.52 4.52 0 01.71-.06 2.17 2.17 0 011 .29v1.48a.6.6 0 001.19 0v-.79l1 .61a5.49 5.49 0 011.23 1.35l.18.27a3.64 3.64 0 01.26.77c.05.16.09.31.13.43a8.32 8.32 0 01.18 1.15l-7.14.09a.57.57 0 00-.59.55.58.58 0 00.59.55h8a.55.55 0 01.55.55.55.55 0 01-.55.55h-.71a2.36 2.36 0 010 .28 7.77 7.77 0 01-.83 2.46 6.41 6.41 0 01-.67 1 5.8 5.8 0 01-.81.83 5.21 5.21 0 01-.94.64 5 5 0 01-1 .4 4.63 4.63 0 01-1.03.28zm-1.27-5.89h-.69a.54.54 0 00-.31.28.48.48 0 01-.32.28L17 25c-.54.08-1 .15-1.13.38a.36.36 0 000 .32 5.71 5.71 0 001.65 2.4 4.07 4.07 0 002.62 1.16A4.78 4.78 0 0023 28a3.63 3.63 0 001.45-2.29c0-.63 0-1.08-.75-1.26a8.14 8.14 0 00-1.7-.15H18.86z" />
          </g>
          <path d="M0 0h40v40H0z" fill="none" />
          <path d="M23.33 3.33H10a3.34 3.34 0 00-3.33 3.34v26.66A3.34 3.34 0 0010 36.67h20a3.34 3.34 0 003.33-3.34v-20zM10 33.33V6.67h11.67v6.66H30v20z" />
        </g>
      </g>
    </svg>
  )
}

export default WorkOrderTemplateIcon
