import React from "react"
import { NumericFormat } from "react-number-format"
import { getCurrencySymbol } from "../util"
import FielderTextField from "./FielderTextField"

interface MoneyInputProps {
  readonly onFocus?: (event: any) => void
  readonly onChange: (event: { target: { name: string; value: string } }) => void
  readonly name: string
  readonly currencyCode?: string | null
  readonly defaultValue?: number | string
  readonly value?: number | string
  readonly customInput?: React.ComponentType
}

function MoneyInput(props: MoneyInputProps) {
  const { name, defaultValue, onChange, onFocus, currencyCode, customInput, value, ...other } =
    props
  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      autoComplete="off"
      customInput={customInput ?? FielderTextField}
      decimalScale={2}
      defaultValue={defaultValue}
      fixedDecimalScale
      name={name}
      onFocus={(e) => {
        if (onFocus) {
          onFocus(e)
        } else {
          e.target.select()
        }
      }}
      onValueChange={(values, event) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        })
      }}
      prefix={`${getCurrencySymbol(currencyCode ?? "USD")} `}
      thousandSeparator
      type="text"
      value={value}
      valueIsNumericString
    />
  )
}

export default MoneyInput
