import gql from "graphql-tag"

export const ALL_ORGANIZATIONS = gql`
  query AllOrganizations(
    $filter: String
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $regionIds: [ID!]
    $accountManagerIds: [ID!]
    $statuses: [OrganizationStatus!]
  ) {
    allOrganizations(
      input: {
        filter: $filter
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        last: $last
        after: $after
        before: $before
      }
      organizationFilter: {
        regionIds: $regionIds
        accountManagerIds: $accountManagerIds
        statuses: $statuses
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          tradeName
          legalName
          level
          status
          email
          phoneNumber
          websiteURL
          accountManager {
            id
            email
            firstName
            lastName
            avatar {
              id
              signedUrl
            }
          }
          region {
            id
            name
            code
            currencyCode
          }
          address {
            addressString
            latitude
            longitude
          }
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
          owner {
            id
            firstName
            lastName
            email
            mobilePhoneNumber
          }
        }
      }
    }
  }
`
