import gql from "graphql-tag"

export const EDIT_ROLE = gql`
  mutation EditRole($id: ID!, $name: String!, $description: String, $permissionIds: [ID!]!) {
    editRole(
      input: { id: $id, name: $name, description: $description, permissionIds: $permissionIds }
    ) {
      role {
        id
        name
        description
        isStandard
        permissions {
          id
          group
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
        }
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`
