import { Organization, OrganizationStatus, UserStatus } from "~/types"

export function isDraft(franchisee?: Organization | null): boolean {
    return !franchisee || franchisee?.status === OrganizationStatus.DRAFT
}

export function isDraftOrPending(franchisee?: Organization | null): boolean {
    return isDraft(franchisee) || franchisee?.status === OrganizationStatus.PENDING
}

export function isPendingWithUnverifiedOwner(franchisee?: Organization | null): boolean {
    return (
        franchisee?.status === OrganizationStatus.PENDING &&
        franchisee?.owner?.status === UserStatus.UNVERIFIED
    )
}