import React, { useState, useRef, useEffect } from "react"
import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import { Link, Navigate, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { gql, useQuery, useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import CircularProgress from "@mui/material/CircularProgress"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import WarningIcon from "@mui/icons-material/Warning"
import ConfirmationDialog from "~/components/ConfirmationDialog"
import EmptyState from "~/components/EmptyState"
import SearchField from "~/components/SearchField"
import SnackbarMessage from "~/components/SnackbarMessage"
import { ALL_JOB_WORKFLOWS } from "~/queries/allJobWorkflows"
import { EDIT_JOB } from "~/queries/editJob"
import { SEND_JOB_EMAIL } from "~/queries/sendJobEmail"
import { isBlank, parseGraphQLErrorCode, MAX_INTEGER } from "~/util"
import useStore, { jobBoardWorkflowSelector, setJobBoardWorkflowSelector } from "~/store"
import { useAuth } from "~/context/AuthContext"
import CreateJobButton from "./CreateJobButton"
import JobEmailDialog from "./JobEmailDialog"
import JobBoard from "./JobBoard"
import { Job, JobWorkflow, Snack, DefaultPermission } from "~/types"

const GET_JOB_WORKFLOW_BY_ID = gql`
  query GetJobWorkflowById($id: ID!, $updatedSinceDate: LocalDateTime) {
    getJobWorkflowById(id: $id) {
      id
      name
      isArchived
      steps {
        id
        jobStatus {
          id
          name
          lightColor
          mediumColor
          darkColor
        }
        destinationTransitions {
          id
          destination {
            id
          }
        }
        boardPosition
        jobs(jobFilter: { includeArchived: false, updatedSinceDate: $updatedSinceDate }) {
          id
          number
          description
          boardPosition
          address {
            addressString
          }
          assignments {
            id
            status
            startDate
            endDate
          }
          customer {
            id
            name
            flags {
              id
              name
              colorCode
            }
          }
        }
      }
    }
  }
`

const NINETY_DAYS_AGO = dayjs().subtract(90, "days").toISOString()
const ONE_YEAR_AGO = dayjs().subtract(1, "years").toISOString()

function JobBoardContainer() {
  const { t } = useTranslation()
  const { hasPermissions, user } = useAuth()
  const jobsScreenSettings = useStore((state) => state.jobsScreenSettings)
  const setJobsScreenSettings = useStore((state) => state.setJobsScreenSettings)
  const jobBoardWorkflow = useStore(jobBoardWorkflowSelector)
  const setJobBoardWorkflow = useStore(setJobBoardWorkflowSelector)
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [filter, setFilter] = useState<string>(jobsScreenSettings.searchTerm)
  const [columns, setColumns] = useState([])
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [workflow, setWorkflow] = useState<JobWorkflow | null>()
  const [workflowMenuAnchorEl, setWorkflowMenuAnchorEl] = useState(null)
  const [notifyCustomerPromptIsOpen, setNotifyCustomerPromptIsOpen] = useState<boolean>(false)
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState<boolean>(false)
  const [editedJob, setEditedJob] = useState<Job>()
  const justDropped = useRef(false)

  useEffect(() => {
    if (filter !== jobsScreenSettings.searchTerm) {
      setJobsScreenSettings({
        ...jobsScreenSettings,
        searchTerm: filter,
      })
    }
    if (jobsScreenSettings.mode === "list") {
      setRedirectTo({ to: "/app/jobs/list", replace: false })
    }
  }, [filter, jobsScreenSettings, setJobsScreenSettings])

  const { data: allJobWorkflowsData } = useQuery(ALL_JOB_WORKFLOWS, {
    variables: {
      first: MAX_INTEGER,
      sortBy: "name",
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const allWorkflows = data?.allJobWorkflows
      const storedWorkflow = jobBoardWorkflow
      if (storedWorkflow?.id) {
        const match = allWorkflows.find((w: JobWorkflow) => w.id === storedWorkflow.id)
        if (match) {
          setWorkflow(match)
          return
        }
      }

      setJobBoardWorkflow(allWorkflows[0])
      setWorkflow(allWorkflows[0])
    },
  })

  const { networkStatus: getJobWorkflowByIdNetworkStatus, refetch: refetchWorkflow } = useQuery(
    GET_JOB_WORKFLOW_BY_ID,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        id: jobBoardWorkflow?.id,
        updatedSinceDate: filter?.length > 0 ? ONE_YEAR_AGO : NINETY_DAYS_AGO,
      },
      ssr: false,
      skip: !jobBoardWorkflow?.id,
      onCompleted: (data) => {
        if (!justDropped.current) {
          const cols = data?.getJobWorkflowById?.steps
            ?.map((s) => {
              return {
                id: s.id,
                boardPosition: s.boardPosition,
                jobStatus: s.jobStatus,
                jobs: s.jobs
                  .map((j) => ({ ...j }))
                  .sort((a, b) => a.boardPosition - b.boardPosition),
                destinationStepIds: s.destinationTransitions?.map((t) => t.destination.id) || [],
              }
            })
            ?.sort((a, b) => a.boardPosition - b.boardPosition)
          setColumns(cols)
        } else {
          justDropped.current = false
        }
      },
    }
  )

  const [editJob] = useMutation(EDIT_JOB, {
    onCompleted: (data) => {
      const { editJob } = data
      setEditedJob(editJob?.job)
      if (editJob?.events?.map((e) => e.id)?.includes("PROMPT_CUSTOMER_NOTIFICATION")) {
        setNotifyCustomerPromptIsOpen(true)
      }
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
      refresh()
    },
  })

  const [sendJobEmail, { loading: sendJobEmailLoading }] = useMutation(SEND_JOB_EMAIL, {
    onCompleted: () => {
      setIsEmailDialogOpen(false)
      setSnack({ messageKey: "messages.messageSent", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      setSnack({ messageKey: "messages.messageFailed", variant: "error" })
    },
  })

  function refresh() {
    refetchWorkflow()
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  const workflows = allJobWorkflowsData?.allJobWorkflows || []
  const loading = getJobWorkflowByIdNetworkStatus === 1
  const filteredColumns = filter
    ? columns.map((c) => {
        return {
          ...c,
          jobs: c.jobs.filter((j: Job) => {
            return (
              j.customer.name.toLowerCase().includes(filter.toLowerCase()) ||
              `${j.number}`.includes(filter) ||
              j.description?.toLowerCase()?.includes(filter.toLowerCase()) ||
              j.address?.addressString?.toLowerCase().includes(filter.toLowerCase())
            )
          }),
        }
      })
    : columns

  const allJobs = filteredColumns?.flatMap((c) => c.jobs) ?? []
  const showContent = !loading && allJobs.length > 0

  function renderZeroStateMessage() {
    if (!loading && (!workflows || workflows?.length === 0)) {
      return (
        <Box sx={{ paddingTop: "5rem", paddingBottom: "1.875rem" }}>
          <EmptyState title={t("page.jobBoard.noWorkflows.title")}>
            {hasPermissions?.([DefaultPermission.CreateJob]) ? (
              <Box>
                <Link style={{ color: "inherit" }} to="/app/settings/jobworkflows">
                  {t("page.jobBoard.noWorkflows.message")}
                </Link>
              </Box>
            ) : null}
          </EmptyState>
        </Box>
      )
    } else if (!loading && allJobs.length === 0) {
      return (
        <Box sx={{ paddingTop: "5rem", paddingBottom: "1.875rem" }}>
          {isBlank(filter) && (
            <EmptyState title={t("page.jobBoard.emptyState.title")}>
              {hasPermissions?.([DefaultPermission.CreateJob]) ? (
                <Box>{t("page.jobBoard.emptyState.message")}</Box>
              ) : null}
            </EmptyState>
          )}
          {!isBlank(filter) && (
            <EmptyState title={t("page.jobBoard.noMatchingResults.title")}>
              <Box sx={{ maxWidth: "32rem" }}>{t("page.jobBoard.noMatchingResults.message")}</Box>
            </EmptyState>
          )}
        </Box>
      )
    } else {
      return null
    }
  }

  return (
    <>
      {notifyCustomerPromptIsOpen ? (
        <ConfirmationDialog
          description={t("component.notifyCustomerDialog.message")}
          id="notify-customer-dialog"
          isLoading={false}
          onCancel={() => setNotifyCustomerPromptIsOpen(false)}
          onConfirm={() => {
            setIsEmailDialogOpen(true)
            setNotifyCustomerPromptIsOpen(false)
          }}
          title={t("component.notifyCustomerDialog.title")}
        />
      ) : null}
      {isEmailDialogOpen ? (
        <JobEmailDialog
          job={editedJob}
          loading={sendJobEmailLoading}
          onCancel={() => setIsEmailDialogOpen(false)}
          onSend={(payload) => {
            sendJobEmail({ variables: payload })
          }}
          user={user}
        />
      ) : null}
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Box
        sx={{
          margin: "1.25rem",
          marginTop: 0,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={(theme) => ({
            width: "25rem",
            [theme.breakpoints.down(820)]: {
              width: "100%",
            },
          })}
        >
          <SearchField
            onChange={(val) => {
              justDropped.current = false
              setFilter(val)
              refresh()
            }}
            placeholder={t("searchJobs")}
            term={filter}
          />
        </Box>
        {workflow?.isArchived ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "rgba(218, 21, 5, 0.1)",
              padding: "0.25rem 0.5rem",
              borderRadius: "4px",
              marginRight: "1.875rem",
            }}
          >
            <WarningIcon sx={{ fontSize: "1.125rem", color: "#DA1505", marginRight: "0.625rem" }} />
            <span>{t("page.jobWorkflow.workflow-is-archived")}</span>
          </Box>
        ) : null}
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            [theme.breakpoints.down(820)]: {
              marginTop: "0.625rem",
              alignContent: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            },
            [theme.breakpoints.down(400)]: {
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            },
          })}
        >
          <Box style={{ marginRight: "1.25rem" }}>
            {!workflows || workflows.length === 0 ? (
              <Button
                onClick={() => {
                  setJobsScreenSettings({
                    ...jobsScreenSettings,
                    mode: "list",
                  })
                }}
                style={{ marginRight: "1.25rem" }}
              >
                {t("switchToListView")}
              </Button>
            ) : (
              <>
                <Button
                  aria-haspopup="true"
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(event: any) => setWorkflowMenuAnchorEl(event.currentTarget)}
                >
                  {workflow?.name}
                </Button>
                <Menu
                  anchorEl={workflowMenuAnchorEl}
                  id="workflow-menu"
                  keepMounted
                  onClose={() => setWorkflowMenuAnchorEl(null)}
                  open={Boolean(workflowMenuAnchorEl)}
                >
                  <MenuItem
                    onClick={() =>
                      setJobsScreenSettings({
                        ...jobsScreenSettings,
                        mode: "list",
                      })
                    }
                  >
                    {t("switchToListView")}
                  </MenuItem>
                  {workflows.map((w: JobWorkflow) => (
                    <MenuItem
                      key={w.id}
                      onClick={() => {
                        justDropped.current = false
                        setJobBoardWorkflow(w) // saves selection to localStorage
                        setWorkflow(w)
                        setWorkflowMenuAnchorEl(null)
                      }}
                    >
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        {w.name}
                        {w.isArchived ? (
                          <Box style={{ fontSize: "0.6875rem", color: "#C70039" }}>
                            {t("archived")}
                          </Box>
                        ) : null}
                      </Box>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Box>
          {hasPermissions?.([DefaultPermission.CreateJob]) ? (
            <CreateJobButton disabled={!workflow || workflow?.isArchived} />
          ) : null}
        </Box>
      </Box>
      {loading ? (
        <Box
          sx={{
            padding: "6.25rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="secondary" size={40} thickness={6.0} />
        </Box>
      ) : null}
      {showContent ? (
        <JobBoard
          columns={filteredColumns}
          onJobDragEnd={(jobId, startStepId, endStepId, startIndex, endIndex) => {
            justDropped.current = true

            const startStep = filteredColumns.find((s) => s.id === startStepId)
            const endStep = filteredColumns.find((s) => s.id === endStepId)
            const job = startStep.jobs.find((j) => j.id === jobId)
            const startStepJobs = startStep?.jobs ? [...startStep.jobs] : []

            if (startStepId === endStepId) {
              // This callback doesn't seem to be invoked if the user drags a card around and drops it back where it was.
              // So I think we can assume that the position *has* changed.
              const topJob = startStepJobs[Math.max(endIndex - 1, 0)]
              const bottomJob = startStepJobs[Math.max(endIndex, 0)]
              const topPos = topJob?.boardPosition || 0
              const bottomPos = bottomJob?.boardPosition || 10000
              let boardPosition = 0

              if (endIndex === 0) {
                boardPosition = startStepJobs[0]?.boardPosition / 2 || 1
              } else {
                boardPosition =
                  (bottomPos - topPos) / 2 + (startIndex > endIndex ? topPos : bottomPos)
              }

              job.boardPosition = boardPosition

              startStepJobs.splice(startIndex, 1)
              startStepJobs.splice(endIndex, 0, job)

              const updatedStep = {
                ...startStep,
                jobs: startStepJobs,
              }

              const updatedColumns = filteredColumns.filter((c) => c.id !== startStep.id)
              updatedColumns.push(updatedStep)
              updatedColumns.sort((a, b) => a.boardPosition - b.boardPosition)

              setColumns(updatedColumns)

              editJob({
                variables: {
                  id: job.id,
                  boardPosition,
                },
              })
            } else {
              // move from one step to another
              const startJobIndex = startStepJobs.findIndex((j) => j.id === jobId)

              startStepJobs.splice(startJobIndex, 1)
              const updatedStartStep = {
                ...startStep,
                jobs: startStepJobs,
              }

              const endStepJobs = endStep?.jobs ? [...endStep.jobs] : []

              // figure out the new boardPosition for the job
              const topJob = endStepJobs[Math.max(endIndex - 1, 0)]
              const bottomJob = endStepJobs[Math.max(endIndex, 0)]
              const topPos = topJob?.boardPosition ?? 0
              const bottomPos = bottomJob?.boardPosition ?? 10000

              let boardPosition = 0
              if (endIndex === 0) {
                boardPosition = (endStepJobs[0]?.boardPosition ?? 1) / 2
              } else {
                boardPosition = (bottomPos - topPos) / 2 + topPos
              }

              // const endJobIndex = endStepJobs.findIndex((j) => j.id === jobId)
              endStepJobs.splice(endIndex, 0, job)
              const updatedEndStep = {
                ...endStep,
                jobs: endStepJobs,
              }

              const updatedColumns = filteredColumns.filter(
                (c) => c.id !== startStep.id && c.id !== endStep.id
              )
              updatedColumns.push(updatedStartStep)
              updatedColumns.push(updatedEndStep)
              updatedColumns.sort((a, b) => a.boardPosition - b.boardPosition)

              setColumns(updatedColumns)

              job.workflowStep = endStep
              job.boardPosition = boardPosition
              editJob({
                variables: {
                  id: job.id,
                  workflowStepId: endStep.id,
                  boardPosition,
                },
              })
            }
          }}
        />
      ) : null}
      {renderZeroStateMessage()}
    </>
  )
}

export default JobBoardContainer
