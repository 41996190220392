import React from "react"
import { useTranslation } from "react-i18next"
import BlockIcon from "@mui/icons-material/Block"
import Box from "@mui/material/Box"
import { MainLayout, Seo } from "../components"
import { NULL_SECTION } from "../util"

function Unauthorized() {
  const { t } = useTranslation()

  return (
    <>
      <Seo title={t("sectionTitle.helpCenter")} />
      <MainLayout activeSection={NULL_SECTION}>
        <Box
          sx={{
            minWidth: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#8B0A18",
            fontSize: "1.2rem",
            paddingTop: "3.125rem",

            "& h1": {
              textAlign: "center",
            },

            "& svg": {
              fontSize: "15rem",
            },
          }}
        >
          <h1>{t("page.unauthorized.headline")}</h1>
          <div>
            <BlockIcon />
          </div>
        </Box>
      </MainLayout>
    </>
  )
}

export default Unauthorized
