/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import Box from "@mui/material/Box"
import { SxProps, Theme } from "@mui/material/styles"
import { SystemStyleObject } from "@mui/system"

interface SectionContentProps {
  readonly sx?: SxProps<Theme>
}

const SectionContent: React.FC<SectionContentProps> = ({ children, sx = {} }) => {
  return (
    <Box
      sx={(theme) => {
        return {
          padding: "0.5rem 0.75rem",
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.up("md")]: {
            padding: "1.25rem",
          },
          ...(typeof sx === "function" ? sx(theme) : sx),
        } as SystemStyleObject<Theme>
      }}
    >
      {children}
    </Box>
  )
}

export default SectionContent
