import React from "react"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import LastPageIcon from "@mui/icons-material/LastPage"

interface TablePaginationActionsProps {
  hasNextPage: boolean
  hasPreviousPage: boolean
  onGoToFirstPage: () => void
  onGoToLastPage: () => void
  onGoToNext: () => void
  onGoToPrevious: () => void
}

function TablePaginationActions({
  hasNextPage,
  hasPreviousPage,
  onGoToFirstPage,
  onGoToLastPage,
  onGoToNext,
  onGoToPrevious,
}: TablePaginationActionsProps) {
  return (
    <Box
      sx={{
        flexShrink: 0,
        marginLeft: (theme) => theme.spacing(2.5),
      }}
    >
      <IconButton aria-label="first page" disabled={!hasPreviousPage} onClick={onGoToFirstPage}>
        <FirstPageIcon />
      </IconButton>
      <IconButton aria-label="previous page" disabled={!hasPreviousPage} onClick={onGoToPrevious}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton aria-label="next page" disabled={!hasNextPage} onClick={onGoToNext}>
        <KeyboardArrowRight />
      </IconButton>
      <IconButton aria-label="last page" disabled={!hasNextPage} onClick={onGoToLastPage}>
        <LastPageIcon />
      </IconButton>
    </Box>
  )
}

export default TablePaginationActions
