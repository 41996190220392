import React from "react"
import { useTranslation } from "react-i18next"

import { Organization } from "~/types"
import StaticField from "~/components/StaticField"
import DefaultEstimateSettingsStatic from "./DefaultEstimateSettingsStatic"
import DefaultInvoiceSettingsStatic from "./DefaultInvoiceSettingsStatic"

interface Props {
  readonly organization: Organization
}

function OrganizationPreferencesStatic({ organization }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <StaticField
        label={t("language") as string}
        showBottomDivider
        value={t(`languageCodeOptions.${organization.languageCode}`)}
      />
      {organization.level >= 2 ? (
        <>
          <StaticField
            label={t("page.organizationDetails.allowJobAssignmentOverlap") as string}
            showBottomDivider
            value={organization.allowJobAssignmentOverlap ? t("yes") : t("no")}
          />
          <StaticField
            label={t("page.organizationDetails.allowBundleModsOnTxns") as string}
            showBottomDivider
            value={organization.allowBundleModsOnTxns ? t("yes") : t("no")}
          />
        </>
      ) : null}
      <StaticField
        label={t("page.organizationDetails.bccJobEmailToOrganizationEmail") as string}
        showBottomDivider
        value={organization.bccJobEmailToOrganizationEmail ? t("yes") : t("no")}
      />
      <DefaultEstimateSettingsStatic organization={organization} showBottomDivider />
      <DefaultInvoiceSettingsStatic organization={organization} />
    </>
  )
}

export default OrganizationPreferencesStatic
