import gql from "graphql-tag"
import FullCustomer from "./fragments/FullCustomer"

export const EDIT_CUSTOMER = gql`
  mutation EditCustomer(
    $id: ID!
    $name: String!
    $email: String
    $phoneNumber: String
    $type: CustomerType!
    $address: AddressInput
    $languageCode: LanguageCode
  ) {
    editCustomer(
      input: {
        id: $id
        name: $name
        email: $email
        phoneNumber: $phoneNumber
        type: $type
        address: $address
        languageCode: $languageCode
      }
    ) {
      customer {
        ...FullCustomer  
      }
    }
  }
  ${FullCustomer}
`

