import React, { useState } from "react"
import { useSlate } from "slate-react"
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft"
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter"
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight"
import FormatBoldIcon from "@mui/icons-material/FormatBold"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import FormatSizeIcon from "@mui/icons-material/FormatSize"
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined"
import FormatColorTextIcon from "@mui/icons-material/FormatColorText"
import TemplateFieldIcon from "@mui/icons-material/Input"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import LinkIcon from "@mui/icons-material/Link"
import LinkOffIcon from "@mui/icons-material/LinkOff"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import Box from "@mui/material/Box"
import Popover from "@mui/material/Popover"
import Divider from "@mui/material/Divider"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputBase from "@mui/material/InputBase"
import { styled } from "@mui/material/styles"
import FontOptions from "./FontOptions"
import TextSizeOptions from "./TextSizeOptions"
import ToolbarButton from "./ToolbarButton"
import { useTranslation } from "react-i18next"
import { RichTextEditor } from "./RichTextEditor"
import ColorPicker from "../ColorPicker"
import { TemplateFieldOption, TextSizeOption, FontOption } from "../../types"

const SelectorSimpleInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: 0,
  },
  "& .MuiInputBase-input": {
    borderRadius: 0,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    fontSize: "1rem",
    padding: 0,
    "&:focus": {
      borderRadius: 0,
      border: "none",
      boxShadow: "none",
    },
  },
}))

interface ToolbarProps {
  readonly onAddLink?: (event: any) => void
  readonly onAddTemplateField?: (event: any, templateField: TemplateFieldOption) => void
  readonly onChangeAlignment?: (event: any, value: string) => void
  readonly onChangeFont?: (event: any, value: FontOption) => void
  readonly onChangeTextColor?: (value: string) => void
  readonly onChangeTextSize?: (event: any, value: TextSizeOption) => void
  readonly onToggleBlock?: (event: any, value: string) => void
  readonly onToggleFormat?: (event: any, value: string) => void
  readonly onRemoveLink?: (event: any) => void
  readonly renderRightToolbar?: () => React.ReactNode
  readonly templateFieldOptions?: TemplateFieldOption[]
}

function Toolbar({
  onAddLink,
  onAddTemplateField,
  onChangeAlignment,
  onChangeFont,
  onChangeTextColor,
  onChangeTextSize,
  onToggleBlock,
  onToggleFormat,
  onRemoveLink,
  renderRightToolbar,
  templateFieldOptions,
}: ToolbarProps) {
  const { t } = useTranslation()
  const editor = useSlate()
  const [textSizeAnchorEl, setTextSizeAnchorEl] = useState(null)
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = useState(null)
  const [templateFieldAnchorEl, setTemplateFieldAnchorEl] = useState(null)
  const [textColor, setTextColor] = useState<string>("#000000")
  const showTextSizeMenu = Boolean(textSizeAnchorEl)
  const showColorPicker = Boolean(colorPickerAnchorEl)
  const showTemplateFieldMenu = Boolean(templateFieldAnchorEl)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        flexWrap: "wrap",
        width: "100%",
      }}
    >
      <Select
        autoWidth
        id="font-select"
        input={<SelectorSimpleInput />}
        labelId="font-select-label"
        onChange={(event: any) => {
          const fontOptionId = event.target.value
          const fontOption = FontOptions.find((option) => option.id === fontOptionId)
          fontOption && onChangeFont?.(event, fontOption)
        }}
        renderValue={(selectedFont) => (
          <span style={{ fontFamily: selectedFont.fontFamily }}>
            {t(`component.richTextEditor.fontOptions.${selectedFont}`)}
          </span>
        )}
        size="small"
        sx={{
          marginLeft: "1rem",
          marginRight: "1rem",
          paddingBottom: 0,
          width: "6.25rem",
          minWidth: "150px",
          border: "none",

          "& .MuiSelect.MuiSelect-standard.MuiInput-input": {
            border: "1px solid green",
          },
        }}
        value={RichTextEditor.getActiveFont(editor).id}
        variant="standard"
      >
        {FontOptions.map((fontOption) => (
          <MenuItem key={fontOption.id} value={fontOption.id}>
            <span style={{ fontFamily: fontOption.fontFamily }}>
              {t(`component.richTextEditor.fontOptions.${fontOption.id}`)}
            </span>
          </MenuItem>
        ))}
      </Select>
      <Divider flexItem orientation="vertical" sx={classes.divider} />
      <Box sx={classes.buttonGroup}>
        <ToolbarButton
          aria-label={t("component.richTextEditor.toolbar.textSize")}
          onMouseDown={(event: any) => {
            event.preventDefault()
            setTextSizeAnchorEl(event.currentTarget)
          }}
          tooltip={t("component.richTextEditor.toolbar.textSize")}
        >
          <FormatSizeIcon />
          <ArrowDropDownIcon />
        </ToolbarButton>
      </Box>
      <Menu
        anchorEl={textSizeAnchorEl}
        keepMounted
        onClose={() => setTextSizeAnchorEl(null)}
        open={showTextSizeMenu}
      >
        {TextSizeOptions.map((sizeOption) => (
          <MenuItem
            key={sizeOption.id}
            onMouseDown={(event: any) => {
              onChangeTextSize?.(event, sizeOption)
              setTextSizeAnchorEl(null)
            }}
          >
            <span style={{ fontSize: sizeOption.fontSize }}>
              {t(`component.richTextEditor.textSizeOptions.${sizeOption.id}`)}
            </span>
          </MenuItem>
        ))}
      </Menu>
      <Divider flexItem orientation="vertical" sx={classes.divider} />
      <Box sx={classes.buttonGroup}>
        <ToolbarButton
          aria-label={t("component.richTextEditor.toolbar.alignLeft")}
          isActive={RichTextEditor.isAlignmentActive(editor, "left")}
          onMouseDown={(event: any) => {
            onChangeAlignment?.(event, "left")
          }}
          tooltip={t("component.richTextEditor.toolbar.alignLeft")}
        >
          <FormatAlignLeftIcon />
        </ToolbarButton>
        <ToolbarButton
          aria-label={t("component.richTextEditor.toolbar.alignCenter")}
          isActive={RichTextEditor.isAlignmentActive(editor, "center")}
          onMouseDown={(event: any) => {
            onChangeAlignment?.(event, "center")
          }}
          tooltip={t("component.richTextEditor.toolbar.alignCenter")}
        >
          <FormatAlignCenterIcon />
        </ToolbarButton>
        <ToolbarButton
          aria-label={t("component.richTextEditor.toolbar.alignRight")}
          isActive={RichTextEditor.isAlignmentActive(editor, "right")}
          onMouseDown={(event: any) => {
            onChangeAlignment?.(event, "right")
          }}
          tooltip={t("component.richTextEditor.toolbar.alignRight")}
        >
          <FormatAlignRightIcon />
        </ToolbarButton>
      </Box>
      <Divider flexItem orientation="vertical" sx={classes.divider} />
      <Box sx={classes.buttonGroup}>
        <ToolbarButton
          aria-label={t("component.richTextEditor.toolbar.formatBold")}
          isActive={RichTextEditor.isMarkActive(editor, "bold")}
          onMouseDown={(event: any) => {
            onToggleFormat?.(event, "bold")
          }}
          tooltip={t("component.richTextEditor.toolbar.formatBold")}
        >
          <FormatBoldIcon />
        </ToolbarButton>
        <ToolbarButton
          aria-label={t("component.richTextEditor.toolbar.formatItalic")}
          isActive={RichTextEditor.isMarkActive(editor, "italic")}
          onMouseDown={(event: any) => {
            onToggleFormat?.(event, "italic")
          }}
          tooltip={t("component.richTextEditor.toolbar.formatItalic")}
        >
          <FormatItalicIcon />
        </ToolbarButton>
        <ToolbarButton
          aria-label={t("component.richTextEditor.toolbar.formatUnderline")}
          isActive={RichTextEditor.isMarkActive(editor, "underline")}
          onMouseDown={(event: any) => {
            onToggleFormat?.(event, "underline")
          }}
          tooltip={t("component.richTextEditor.toolbar.formatUnderline")}
        >
          <FormatUnderlinedIcon />
        </ToolbarButton>
      </Box>
      <Divider flexItem orientation="vertical" sx={classes.divider} />
      <Box sx={classes.buttonGroup}>
        <ToolbarButton
          aria-label={t("component.richTextEditor.toolbar.textColor")}
          onMouseDown={(event: any) => {
            event.preventDefault()
            setColorPickerAnchorEl(event.currentTarget)
          }}
          tooltip={t("component.richTextEditor.toolbar.textColor")}
        >
          <FormatColorTextIcon style={{ color: RichTextEditor.getTextColor(editor) }} />
          <ArrowDropDownIcon />
        </ToolbarButton>
        <Popover
          PaperProps={{
            style: {
              background: "transparent",
              borderRadius: "8px",
            },
          }}
          anchorEl={colorPickerAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => {
            setColorPickerAnchorEl(null)
            onChangeTextColor?.(textColor)
          }}
          open={showColorPicker}
          placement="bottom"
        >
          <ColorPicker
            color={RichTextEditor.getTextColor(editor)}
            onChange={(colorCode) => setTextColor(colorCode)}
          />
        </Popover>
      </Box>
      <Divider flexItem orientation="vertical" sx={classes.divider} />
      <Box sx={classes.buttonGroup}>
        <ToolbarButton
          aria-label={t("component.richTextEditor.toolbar.numberedList")}
          isActive={RichTextEditor.isBlockActive(editor, "numbered-list")}
          onMouseDown={(event) => {
            onToggleBlock?.(event, "numbered-list")
          }}
          tooltip={t("component.richTextEditor.toolbar.numberedList")}
        >
          <FormatListNumberedIcon />
        </ToolbarButton>
        <ToolbarButton
          aria-label={t("component.richTextEditor.toolbar.bulletedList")}
          isActive={RichTextEditor.isBlockActive(editor, "bulleted-list")}
          onMouseDown={(event) => {
            onToggleBlock?.(event, "bulleted-list")
          }}
          tooltip={t("component.richTextEditor.toolbar.bulletedList")}
        >
          <FormatListBulletedIcon />
        </ToolbarButton>
      </Box>
      <Divider flexItem orientation="vertical" sx={classes.divider} />
      <Box sx={classes.buttonGroup}>
        {RichTextEditor.isLinkActive(editor) ? (
          <ToolbarButton
            aria-label={t("component.richTextEditor.toolbar.removeLink")}
            isActive
            onMouseDown={onRemoveLink}
            tooltip={t("component.richTextEditor.toolbar.removeLink")}
          >
            <LinkOffIcon />
          </ToolbarButton>
        ) : (
          <ToolbarButton
            aria-label={t("component.richTextEditor.toolbar.insertLink")}
            isActive={false}
            onMouseDown={onAddLink}
            tooltip={t("component.richTextEditor.toolbar.insertLink")}
          >
            <LinkIcon />
          </ToolbarButton>
        )}
      </Box>
      {templateFieldOptions && templateFieldOptions.length > 0 ? (
        <>
          <Divider flexItem orientation="vertical" sx={classes.divider} />
          <Box sx={classes.buttonGroup}>
            <ToolbarButton
              aria-label={t("component.richTextEditor.toolbar.insertTemplateField")}
              onMouseDown={(event: any) => {
                event.preventDefault()
                setTemplateFieldAnchorEl(event.currentTarget)
              }}
              tooltip={t("component.richTextEditor.toolbar.insertTemplateField")}
            >
              <TemplateFieldIcon />
              <ArrowDropDownIcon />
            </ToolbarButton>
          </Box>
          <Menu
            anchorEl={templateFieldAnchorEl}
            keepMounted
            onClose={() => setTemplateFieldAnchorEl(null)}
            open={showTemplateFieldMenu}
          >
            {templateFieldOptions.map((templateFieldOption) => (
              <MenuItem
                key={templateFieldOption.id}
                onMouseDown={(event: any) => {
                  onAddTemplateField?.(event, templateFieldOption)
                  setTemplateFieldAnchorEl(null)
                }}
              >
                <span>{templateFieldOption.displayName}</span>
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : null}
      <Box sx={{ justifySelf: "flex-end", marginLeft: "auto" }}>{renderRightToolbar?.()}</Box>
    </Box>
  )
}

const classes = {
  divider: {
    margin: "0.5rem 0.25rem",
  },
  buttonGroup: {
    margin: "0.25rem",
    border: "none",
    "&:not(:first-of-type)": {
      borderRadius: "4px",
    },
    "&:first-of-type": {
      borderRadius: "4px",
    },
  },
} as const

export default React.memo(Toolbar)
