import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"

export const CREATE_JOB_ASSIGNMENT = gql`
  mutation CreateJobAssignment(
    $jobId: ID!
    $assigneeUserIds: [ID!]!
    $status: JobAssignmentStatus!
    $isLocked: Boolean!
    $startDate: LocalDateTime!
    $endDate: LocalDateTime!
    $workOrderId: ID
  ) {
    createJobAssignment(
      input: {
        jobId: $jobId
        assigneeUserIds: $assigneeUserIds
        status: $status
        isLocked: $isLocked
        startDate: $startDate
        endDate: $endDate
        workOrderId: $workOrderId
      }
    ) {
      jobAssignment {
        id
        isLocked
        job {
          id
          customer {
            id
            name
          }
          address {
            ...FullAddress
          }
        }
        assignees {
          id
          email
          firstName
          lastName
        }
        status
        startDate
        endDate
        workOrder {
          id
          number
          description
        }
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${FullAddress}
`
