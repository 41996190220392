import React from "react"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import isNil from "lodash/isNil"
import { NOT_SPECIFIED } from "../util"

interface StaticFieldProps {
  readonly label: string
  readonly value?: string | number | null | JSX.Element
  readonly showBottomDivider?: boolean
  readonly style?: React.CSSProperties
  readonly renderHelpText?: () => void
}

function StaticField({ label, value, showBottomDivider, renderHelpText, style }: StaticFieldProps) {
  return (
    <Box
      sx={{
        padding: 0,
        paddingTop: "0.3125rem",
        "& label": {
          fontWeight: 700,
          marginBottom: "0.25rem",
          fontSize: "0.875rem",
        },
        "& hr": {
          marginTop: "0.625rem",
        },
        ...style,
      }}
    >
      <label>{label}</label>
      <Box
        sx={{
          minHeight: "20px",
          whiteSpace: "pre-wrap",
        }}
      >
        {isNil(value) ? NOT_SPECIFIED : value}
      </Box>
      {renderHelpText?.()}
      {showBottomDivider ? <Divider /> : null}
    </Box>
  )
}

export default StaticField
