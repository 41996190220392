import { useState, useEffect } from "react"

const useGoogleMaps = () => {
  const [googleMap, setGoogleMap] = useState(() => {
    return window?.google
  })

  useEffect(() => {
    if (!googleMap && !window?.google) {
      const intervalId = setInterval(() => {
        if (window?.google) {
          setGoogleMap(window.google)
          clearInterval(intervalId)
        }
      }, 1000)
    }
  }, [googleMap])

  return googleMap
}

export default useGoogleMaps
