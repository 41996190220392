import gql from "graphql-tag"

export const EDIT_JOB_STATUS = gql`
  mutation EditJobStatus(
    $id: ID!
    $parentStatusId: ID
    $name: String
    $description: String
    $lightColor: String
    $mediumColor: String
    $darkColor: String
    $isArchived: Boolean
  ) {
    editJobStatus(
      input: {
        id: $id
        parentStatusId: $parentStatusId
        name: $name
        description: $description
        lightColor: $lightColor
        mediumColor: $mediumColor
        darkColor: $darkColor
        isArchived: $isArchived
      }
    ) {
      jobStatus {
        id
        name
        description
        lightColor
        mediumColor
        darkColor
        isArchived
        parentStatus {
          id
          name
        }
      }
    }
  }
`
