import gql from "graphql-tag"
import FullProductOrder from "./fragments/FullProductOrder"

export const ARCHIVE_PRODUCT_ORDER = gql`
  mutation ArchiveProductOrder($id: ID!) {
    archiveProductOrder(id: $id) {
      productOrder {
        ...FullProductOrder
      }
    }
  }
  ${FullProductOrder}
`
