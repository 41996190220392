import gql from "graphql-tag"

export const ARCHIVE_CONTACT = gql`
  mutation ArchiveContact($id: ID!, $customerId: ID!) {
    archiveContact(id: $id, customerId: $customerId) {
      contact {
        id
        firstName
        lastName
        isArchived
      }
    }
  }
`
