import gql from "graphql-tag"
import FullNotification from "./fragments/FullNotification"

export const GET_USER_NOTIFICATIONS = gql`
  query GetUserNotifications($id: ID!, $first: Int, $channel: MessageChannel) {
    getUserById(id: $id) {
      id
      email
      unreadNotificationCount
      notifications(params: { first: $first }, notificationFilter: { channel: $channel }) {
        edges {
          cursor
          node {
            ...FullNotification
          }
        }
      }
    }
  }
  ${FullNotification}
`
