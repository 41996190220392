import gql from "graphql-tag"

export const ARCHIVE_JOB = gql`
  mutation ArchiveJob($id: ID!) {
    archiveJob(id: $id) {
      job {
        id
        number
        isArchived
      }
    }
  }
`
