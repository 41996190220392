import React, {
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { fabric } from "fabric"
import { nanoid } from "nanoid"
import { ChecklistTemplateLineItemFormInput } from "../../types"
import { Box } from "@mui/material"

const PROPS_TO_SERIALIZE = [
  "diagramId",
  "typeName",
  "pileNumber",
  "strokeUniform",
  "strokeWidth",
  "width",
  "height",
  "selectable",
  "hasBorders",
  "hasControls",
  "originX",
  "originY",
]

const Mode = {
  select: {
    cursor: "default",
    icon: "/icons/selectIcon.png",
    activeIcon: "/icons/selectIcon_active.png",
    name: "select",
  },
  screwPile: {
    cursor: "crosshair",
    icon: "/icons/screwPileIcon.png",
    activeIcon: "/icons/screwPileIcon_active.png",
    name: "screwPile",
  },
  circle: {
    cursor: "crosshair",
    icon: "/icons/circleIcon.png",
    activeIcon: "/icons/circleIcon_active.png",
    name: "circle",
  },
  rectangle: {
    cursor: "crosshair",
    icon: "/icons/rectangleIcon.png",
    activeIcon: "/icons/rectangleIcon_active.png",
    name: "rectangle",
  },
  line: {
    cursor: "crosshair",
    icon: "/icons/lineIcon.png",
    activeIcon: "/icons/lineIcon_active.png",
    name: "line",
  },
  text: {
    cursor: "text",
    icon: "/icons/textIcon.png",
    activeIcon: "/icons/textIcon_active.png",
    name: "text",
  },
}

interface Point {
  x: number
  y: number
}

/**
 * Find the shortest distance between a point C and a line formed by points A and B.
 * @param A - a point on a line in common with B
 * @param B - a point on a line in common with A
 * @param C - a point not on the line AB that we want to find the distance to the line AB
 * @returns - distance from point C to the line AB
 */
function distancePointToLine2D(A: Point, B: Point, C: Point) {
  // A, B, and C are objects representing 2D points with x, y coordinates

  // Vector AB
  const AB = { x: B.x - A.x, y: B.y - A.y }

  // Vector AC
  const AC = { x: C.x - A.x, y: C.y - A.y }

  // Cross product AB x AC (z-component is 0 in 2D)
  const crossProduct = AB.x * AC.y - AB.y * AC.x

  // Magnitude of AB
  const magnitudeAB = Math.sqrt(AB.x * AB.x + AB.y * AB.y)

  // Distance formula
  const distance = Math.abs(crossProduct) / magnitudeAB

  return distance
}

/**
 * Add control points to a Line element when it is activated
 */
function activateLineElement(line, canvas) {
  deactivateAllLines(canvas)

  line.hoverCursor = "default"
  line.stroke = "#457DD1"

  const existingP0 = canvas.getObjects().find((o) => o.typeName === "p0")
  const existingP1 = canvas.getObjects().find((o) => o.typeName === "p1")

  if (existingP0) {
    canvas.remove(existingP0)
  }

  if (existingP1) {
    canvas.remove(existingP1)
  }

  const ctrlCoords = getLineControlPointCoords(line)
  const p0 = makeControlPoint(ctrlCoords.p0.x, ctrlCoords.p0.y, "p0", line)
  const p1 = makeControlPoint(ctrlCoords.p1.x, ctrlCoords.p1.y, "p1", line)

  // setting line.p0 or line.p1 doesn't do any good because it gets wiped out
  // as soon as you serialize to json it seems, because I'm not serializing those things.
  // instead, we can make these unserialized control points point to each other.
  // This is needed in object:moving in order to set the line's start/end points.
  p0.sibling = p1
  p1.sibling = p0

  p0.on("moving", (event) => {
    line.set({ x1: p0.left, y1: p0.top, x2: p1.left, y2: p1.top })
    line.setCoords()
    canvas.renderAll()
  })

  p1.on("moving", (event) => {
    line.set({ x1: p0.left, y1: p0.top, x2: p1.left, y2: p1.top })
    line.setCoords()
    canvas.renderAll()
  })

  canvas.add(p0)
  canvas.add(p1)
  canvas.setActiveObject(line)
  canvas.requestRenderAll()
}

function deactivateLineElement(line, canvas) {
  line.stroke = "#000000"
  if (line.p0) {
    canvas.remove(line.p0)
    line.p0 = null
  }
  if (line.p1) {
    canvas.remove(line.p1)
    line.p1 = null
  }

  canvas.requestRenderAll()
}

function makeControlPoint(left, top, typeName, line) {
  const c = new fabric.Circle({
    left: left,
    top: top,
    strokeWidth: 1,
    radius: 6,
    padding: 2,
    fill: "rgb(255,255,255)",
    stroke: "rgb(0,0,0)",
    hasBorders: false,
    hasControls: false,
    originX: "center",
    originY: "center",
    excludeFromExport: true,
  })
  c.diagramId = "controlPoint-" + nanoid()
  c.typeName = typeName
  c.line = line
  line[typeName] = c

  return c
}

function getLineControlPointCoords(line) {
  const realPoints = line.calcLinePoints()
  const center = { x: line.oCoords.mb.x, y: line.oCoords.mr.y }

  return {
    p0: {
      x: center.x + realPoints.x1,
      y: center.y + realPoints.y1,
    },
    p1: {
      x: center.x + realPoints.x2,
      y: center.y + realPoints.y2,
    },
  }
}

function deactivateAllElements(canvas) {
  deactivateAllLines(canvas)
  deactivateAllTextboxes(canvas)
}

function deactivateAllLines(canvas) {
  canvas
    .getObjects()
    .filter((o) => o.typeName === Mode.line.name)
    .forEach((line) => {
      line.stroke = "#000000"
      deactivateLineElement(line, canvas)
    })
  canvas.requestRenderAll()
}

function deactivateAllTextboxes(canvas) {
  canvas
    .getObjects()
    .filter((o) => o.type === "textbox")
    .forEach((t) => {
      if (t.isEditing) {
        t.exitEditing()
      }
    })
  canvas.requestRenderAll()
}

interface ChecklistDiagramEditorProps {
  readonly loading?: boolean
  readonly onAddPile: (pile: ChecklistTemplateLineItemFormInput, diagramId: string) => void
  readonly onRemovePiles: (piles: any[]) => void
  readonly onUpdateDiagram: (diagramJson: any) => void
}

const ChecklistDiagramEditor = forwardRef(
  ({ loading, onAddPile, onRemovePiles, onUpdateDiagram }: ChecklistDiagramEditorProps, ref) => {
    const { t } = useTranslation()
    const [mode, setMode] = useState(() => Mode.select)
    const canvasContainerElRef = useRef(null) //ref to the <canvas> html element
    const canvasRef = useRef(null) // ref to the Fabric object
    const clipboard = useRef(null)

    const addScrewPile = useCallback(
      (position) => {
        const canvas = canvasRef.current
        const radius = position?.radius ?? 14
        const ellipse = new fabric.Circle({
          radius: radius,
          strokeWidth: 1,
          stroke: "rgb(0,0,0)",
          strokeUniform: true,
          fill: "rgb(255,255,255)",
          originX: "center",
          originY: "center",
        })
        const pileNumber =
          canvas.getObjects().filter((o) => o.typeName === Mode.screwPile.name).length + 1 //piles.length + 1
        const label = new fabric.Text(`${pileNumber}`, {
          fontSize: radius / 0.875,
          originX: "center",
          originY: "center",
        })
        const group = new fabric.Group([ellipse, label], {
          left: position.x - radius,
          top: position.y - radius,
          selectable: true,
          hasRotatingPoint: false,
          diagramId: nanoid(),
          typeName: Mode.screwPile.name,
          pileNumber,
        })
        canvas.add(group)
        canvas.renderAll()
        const json = canvasRef.current.toJSON(PROPS_TO_SERIALIZE)
        onAddPile?.({ number: pileNumber, diagramId: group.diagramId }, json)
      },
      [onAddPile]
    )

    const copy = useCallback(() => {
      canvasRef.current.getActiveObject().clone((cloned) => {
        clipboard.current = cloned
      }, PROPS_TO_SERIALIZE)
    }, [clipboard])

    const paste = useCallback(() => {
      clipboard?.current?.clone((clonedObj) => {
        const canvas = canvasRef.current
        canvas.discardActiveObject()

        if (clonedObj.typeName === Mode.screwPile.name) {
          const circle = clonedObj.getObjects("circle")?.[0]
          addScrewPile({
            radius: circle.radius * clonedObj.scaleX,
            x: clipboard.current.left + circle.width * clonedObj.scaleX,
            y: clipboard.current.top + circle.height * clonedObj.scaleX,
          })
        } else {
          clonedObj.left = clonedObj.left + 20
          clonedObj.top = clonedObj.top + 20
          clonedObj.evented = true

          if (clonedObj.type === "activeSelection") {
            // active selection needs a reference to the canvas.
            clonedObj.canvas = canvas
            clonedObj.forEachObject(function (obj) {
              canvas.add(obj)
            })
            // this should solve the unselectability
            clonedObj.setCoords()
          } else {
            canvas.add(clonedObj)
          }
          clipboard.current.top += 20
          clipboard.current.left += 20
          canvas.setActiveObject(clonedObj)
          canvas.requestRenderAll()
        }
      }, PROPS_TO_SERIALIZE)
    }, [addScrewPile, clipboard])

    const canCopyPaste = (object) => {
      return (
        (object.type === "text" ||
          object.type === "textbox" ||
          object.type === "rect" ||
          object.type === "circle" ||
          object.type === "line" ||
          object.typeName === Mode.screwPile.name) &&
        !object.isEditing
      )
    }

    const drawBackgroundGrid = () => {
      const canvas = canvasRef.current
      const width = canvas.width
      const height = canvas.height
      const majorTicks = 50
      const minorTicks = 10
      const majorTickColor = "rgb(221, 243, 250)"
      const minorTickColor = "rgb(235, 245, 248)"
      const options = {
        stroke: minorTickColor,
        selectable: false,
        excludeFromExport: true,
        evented: false,
        hoverCursor: "default",
        hasBorders: false,
        hasControls: false,
        lockMovementX: true,
        lockMovementY: true,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        lockSkewingX: true,
        lockSkewingY: true,
      }

      // draw the major vertical grid lines
      for (let i = 0; i < width / majorTicks; i++) {
        const xPos = i * majorTicks
        const line = new fabric.Line([xPos, 0, xPos, height], {
          ...options,
          stroke: majorTickColor,
        })
        canvas.add(line)
        canvas.sendToBack(line)
      }

      // draw the major horizontal grid lines
      for (let i = 0; i < height / majorTicks; i++) {
        const yPos = i * majorTicks
        const line = new fabric.Line([0, yPos, width, yPos], {
          ...options,
          stroke: majorTickColor,
        })
        canvas.add(line)
        canvas.sendToBack(line)
      }

      // draw the minor vertical grid lines
      for (let i = 0; i < width / minorTicks; i++) {
        const xPos = i * minorTicks
        if (xPos % majorTicks === 0) continue
        const line = new fabric.Line([xPos, 0, xPos, height], options)
        canvas.add(line)
        canvas.sendToBack(line)
      }

      // draw the minor horizontal grid lines
      for (let i = 0; i < height / minorTicks; i++) {
        const yPos = i * minorTicks
        if (yPos % majorTicks === 0) continue
        const line = new fabric.Line([0, yPos, width, yPos], options)
        canvas.add(line)
        canvas.sendToBack(line)
      }
    }

    // expose methods to the parent component, so that the parent can
    // imperatively request the SVG representation of the diagram, etc...
    useImperativeHandle(ref, () => {
      return {
        removePile(diagramId) {
          const canvas = canvasRef.current
          if (canvas) {
            const deletedPile = canvas.getObjects().find((o) => o.diagramId === diagramId)
            if (deletedPile) {
              canvas.remove(deletedPile)
              canvas
                .getObjects()
                .filter((o) => o.typeName === Mode.screwPile.name)
                .forEach((canvasPileObject) => {
                  if (canvasPileObject.pileNumber > deletedPile.pileNumber) {
                    canvasPileObject.pileNumber = parseInt(canvasPileObject.pileNumber, 10) - 1
                    const label = canvasPileObject.getObjects("text")?.[0]
                    label.set("text", `${canvasPileObject.pileNumber}`)
                  }
                })
              const json = canvasRef.current.toJSON(PROPS_TO_SERIALIZE)
              return JSON.stringify(json)
            }
          }
        },
        toJSON: () => {
          canvasRef.current.renderAll()
          const json = canvasRef.current.toJSON(PROPS_TO_SERIALIZE)
          return JSON.stringify(json)
        },
        fromJSON(json) {
          const jsonObj = JSON.parse(json)
          if (jsonObj) {
            canvasRef.current.loadFromJSON(
              jsonObj,
              canvasRef.current.renderAll.bind(canvasRef.current),
              (o, object, error) => {
                // `o` is the json object
                // `object` is the fabric.Object instance
                object.diagramId = o.diagramId
                object.pileNumber = o.pileNumber
              }
            )
            drawBackgroundGrid()
          }
        },
        clear() {
          const canvas = canvasRef.current
          canvas.clear()
          drawBackgroundGrid()
        },
        toPNG() {
          return canvasRef.current.toDataURL({ format: "png" })
        },
      }
    })

    useEffect(() => {
      const containerElement = canvasContainerElRef.current
      const width = containerElement.clientWidth
      const height = containerElement.clientHeight
      const canvas = new fabric.Canvas(containerElement, {
        preserveObjectStacking: true,
        defaultCursor: Mode.select.cursor,
        backgroundColor: "rgb(255,255,255)",
        width,
        height,
        includeDefaultValues: false, // don't serialize default values
      })

      canvas.on("selection:created", (event) => {
        const selected = event.selected?.[0]

        if (selected && selected.typeName === Mode.line.name) {
          activateLineElement(selected, canvas)
        }

        // If clicking away from any active textboxes, make sure they're pulled out of edit mode.
        if (
          !event.target ||
          event.target.type !== "textbox" ||
          (event.target.type === "textbox" && !event.target.isEditing)
        ) {
          deactivateAllTextboxes(canvas)
        }
      })

      canvas.on("selection:updated", (event) => {
        const selected = event.selected?.[0]

        if (selected?.typeName === Mode.line.name) {
          // we are shifting from one line to another
          activateLineElement(selected, canvas)
          canvas.requestRenderAll()
        }
      })

      canvas.on("selection:cleared", () => {
        deactivateAllElements(canvas)
        canvas.requestRenderAll()
      })

      canvas.on("object:moving", (event) => {
        // Note: Also see object:modified, which has similar logic to keep the object within the canvas.
        const obj = event.target
        if (obj) {
          obj.setCoords()
          const top = obj.top ?? 0
          const left = obj.left ?? 0
          const height = obj.canvas?.height ?? 0
          const width = obj.canvas?.width ?? 0
          const objectBoundingRect = obj.getBoundingRect()

          // If the object is moving above the top edge over below the bottom edge
          if (objectBoundingRect.top < 0) {
            obj.top = Math.max(top, top - objectBoundingRect.top)
          } else if (objectBoundingRect.top + objectBoundingRect.height > height) {
            obj.top = Math.min(
              top,
              height - objectBoundingRect.height + top - objectBoundingRect.top
            )
          }

          // if the object is moving to the left of the left edge or to the right of the right edge
          if (objectBoundingRect.left < 0) {
            obj.left = Math.max(left, left - objectBoundingRect.left)
          } else if (objectBoundingRect.left + objectBoundingRect.width > width) {
            obj.left = Math.min(
              left,
              width - objectBoundingRect.width + left - objectBoundingRect.left
            )
          }

          if (event.target?.typeName === Mode.line.name) {
            const line = event.target
            if (line.p0 && line.p1) {
              line.p0.left = line.p0.left + event.e.movementX
              line.p0.top = line.p0.top + event.e.movementY
              line.p1.left = line.p1.left + event.e.movementX
              line.p1.top = line.p1.top + event.e.movementY
              line.p0.isDirty = true
              line.p1.isDirty = true
              line.p0.setCoords()
              line.p1.setCoords()
            }
            line.setCoords()
            canvas.requestRenderAll()
          }
        }
      })

      canvas.on("mouse:move", (event) => {
        const activeObject = canvas.getActiveObject()
        const line = event.target?.typeName === Mode.line.name ? event.target : null
        if (line) {
          const lineCoords = getLineControlPointCoords(line)
          const A = lineCoords.p0
          const B = lineCoords.p1
          const distance = distancePointToLine2D(A, B, canvas.getPointer(event.e))
          line.stroke =
            distance < 15 || (activeObject && activeObject.diagramId === line.diagramId)
              ? "#457DD1"
              : "#000000"

          line.hoverCursor = "default"
          canvas.requestRenderAll()
        }
      })

      canvasRef.current = canvas
      drawBackgroundGrid()
    }, [canvasContainerElRef])

    useEffect(() => {
      const canvas = canvasRef.current
      canvas.defaultCursor = mode.cursor

      function exitTextEditing() {
        // Fire the text:editing:exited event for each
        // textbox on the diagram; see the handler for that event above
        canvas.forEachObject((o) => {
          if (o.type === "textbox" || o.type === "text") {
            if (o.isEditing) {
              handleUpdateDiagram()
              o.exitEditing()
            }
          }
        })
      }

      function handleUpdateDiagram() {
        if (onUpdateDiagram) {
          canvas.renderAll()
          const json = canvas.toJSON(PROPS_TO_SERIALIZE)
          onUpdateDiagram(json)
        }
      }

      canvas.off("text:editing:exited")
      canvas.on("text:editing:exited", (event) => {
        if (event.target && event.target.type === "textbox") {
          // make the textbox shrink to fit it's text
          event.target.width = event.target.calcTextWidth() + 20
          canvas.defaultCursor = mode.cursor // otherwise, the canvas hover cursor will be the 'text' cursor.
        }
      })

      canvas.off("object:scaled")
      canvas.on("object:scaled", () => {
        handleUpdateDiagram()
      })

      canvas.off("object:modified")
      canvas.on("object:modified", (event) => {
        // Note: Also see object:moving, which has similar logic to keep the object within the canvas.
        const target = event.target
        if (target) {
          const boundingBox = target.getBoundingRect()
          const margin = 2
          if (boundingBox.left < 0 || boundingBox.left > canvas.width - boundingBox.width) {
            let newLeft = Math.max(
              margin,
              Math.min(canvas.width - boundingBox.width - margin, boundingBox.left)
            )
            if (target.originX === "center") {
              newLeft += boundingBox.width / 2
            }
            target.set("left", newLeft)
            target.setCoords()
          }
          if (boundingBox.top < 0 || boundingBox.top > canvas.height - boundingBox.height) {
            let newTop = Math.max(
              margin,
              Math.min(canvas.height - boundingBox.height - margin, boundingBox.top)
            )
            if (target.originY === "center") {
              newTop += boundingBox.height / 2
            }
            target.set("top", newTop)
            target.setCoords()
          }

          canvas.requestRenderAll()
        }
        handleUpdateDiagram()
      })

      canvas.off("mouse:down")
      canvas.on("mouse:down", (event) => {
        if (loading) return // don't allow any edits while we're still saving previous edits

        let notifyOfUpdate = true

        const pointer = canvas.getPointer(event)

        exitTextEditing()

        if (mode === Mode.text) {
          if (!event.target || event.target.type !== "textbox") {
            canvas.discardActiveObject()
            const fontSize = 20
            const textbox = new fabric.Textbox("Enter text", {
              fontSize,
              left: pointer.x,
              top: pointer.y - fontSize / 2,
              width: 200,
              centeredScaling: false,
              lockScalingX: false,
              lockScalingY: false,
              lockScalingFlip: true,
              lockSkewingX: true,
              lockSkewingY: true,
            })
            canvas.add(textbox)
            textbox.enterEditing()
            textbox.setSelectionStart(0)
            textbox.setSelectionEnd(10)
            return
          } else if (event.target.type === "textbox") {
            const textbox = event.target
            textbox.enterEditing(event)
            textbox.selectAll()
            textbox.restartCursorIfNeeded()
            return
          }
        } else if (!event.target) {
          // user did not click on an existing diagram object/shape
          if (mode === Mode.screwPile) {
            addScrewPile(pointer)
            notifyOfUpdate = false // addScrewPile/onAddPile will take care of it
          } else if (mode === Mode.rectangle) {
            const rect = new fabric.Rect({
              left: pointer.x,
              top: pointer.y,
              width: 200,
              height: 50,
              stroke: "rgb(0,0,0)",
              strokeWidth: 1,
              strokeUniform: true,
              fill: "rgb(255,255,255)",
              originX: "center",
              originY: "center",
              selectable: true,
              typeName: Mode.rectangle.name,
            })
            canvas.add(rect)
          } else if (mode === Mode.circle) {
            const radius = 20
            const circle = new fabric.Circle({
              radius: radius,
              strokeWidth: 1,
              stroke: "rgb(0,0,0)",
              strokeUniform: true,
              fill: "rgb(255,255,255)",
              top: pointer.y,
              left: pointer.x,
              originX: "center",
              originY: "center",
              typeName: Mode.circle.name,
            })
            canvas.add(circle)
          } else if (mode === Mode.line) {
            const startX = pointer.x - 50
            const startY = pointer.y + 50
            const endX = pointer.x + 50
            const endY = pointer.y - 50
            const line = new fabric.Line([startX, startY, endX, endY], {
              diagramId: "Line-" + nanoid(),
              fill: "",
              stroke: "#000000",
              selectable: true,
              hasBorders: false,
              // padding: 4,
              hasControls: false,
              typeName: Mode.line.name,
              objectCaching: false,
            })
            canvas.add(line)
            activateLineElement(line, canvas)
          }
        }

        if (notifyOfUpdate) {
          handleUpdateDiagram()
        }
      })

      canvas.off("mouse:over")
      canvas.on("mouse:over", (event) => {
        if (loading) {
          return // don't allow any edits while we're still saving previous edits
        }

        if (event.target) {
          if (event.target.selectable && event.target.typeName !== Mode.line.name) {
            event.target.hoverCursor = "move"
          }

          if (mode === Mode.text) {
            event.target.hoverCursor = "text"
          }
        }
      })

      canvas.off("mouse:out")
      canvas.on("mouse:out", (event) => {
        // if the mouse is leaving the canvas
        if (!event.target) {
          canvas.discardActiveObject()
        }

        exitTextEditing()
      })

      const onKeyDown = (event) => {
        if (loading) {
          return
        }

        // This has to be defaulted to false here, otherwise you could trigger a render on *every* keystroke
        let notifyOfUpdate = false

        const activeObject = canvas.getActiveObject()
        if (activeObject) {
          if (
            (event.code === "Backspace" || event.code === "Delete") &&
            !(
              (activeObject.type === "text" || activeObject.type === "textbox") &&
              activeObject.isEditing
            )
          ) {
            event.stopPropagation()
            event.preventDefault()

            const nonPileObjects = canvas
              .getActiveObjects()
              .filter((o) => o.typeName !== Mode.screwPile.name)
            const pileObjects = canvas
              .getActiveObjects()
              .filter((o) => o.typeName === Mode.screwPile.name)

            nonPileObjects.forEach((o) => {
              if (o.typeName === Mode.line.name) {
                if (o.p0) {
                  canvas.remove(o.p0)
                }
                if (o.p1) {
                  canvas.remove(o.p1)
                }
              }
              canvas.remove(o)

              notifyOfUpdate = true
            })

            const pileDiagramIds = pileObjects.map((p) => p.diagramId)
            if (onRemovePiles && pileDiagramIds.length) {
              onRemovePiles?.(pileDiagramIds)
              notifyOfUpdate = false // the call to onRemovePiles should take care of updating the server
            }

            canvas.discardActiveObject()
          } else if (event.code === "BracketLeft" && event.metaKey) {
            // "CMD + ["  was pressed, indicating that the user wants to move the active object back
            event.stopPropagation()
            event.preventDefault()
            activeObject.sendBackwards(true)
            notifyOfUpdate = true
          } else if (event.code === "BracketRight" && event.metaKey) {
            // "CMD + ]"  was pressed, indicating that the user wants to move the active object back
            event.stopPropagation()
            event.preventDefault()
            activeObject.bringForward(true)
            notifyOfUpdate = true
          } else if (event.code === "KeyG" && event.metaKey) {
            event.stopPropagation()
            event.preventDefault() // prevent browser-based functionality from kicking in
            if (event.shiftKey && activeObject.type === "group") {
              // "SHIFT+CMD+G" was pressed, indicating that the user wants to ungroup the active object(s)
              const items = activeObject.getObjects()
              activeObject.destroy()
              canvas.remove(activeObject)
              items.forEach((item) => canvas.add(item))
              canvas.renderAll()
              notifyOfUpdate = true
            } else if (activeObject.type === "activeSelection") {
              // "CMD+G" was pressed, indicating that the user wants to create a Group from the active objects
              activeObject.toGroup()
              notifyOfUpdate = true
            }
          } else if (event.code === "KeyC" && event.metaKey && canCopyPaste(activeObject)) {
            copy()
            notifyOfUpdate = false
          } else if (event.code === "KeyV" && event.metaKey && canCopyPaste(activeObject)) {
            paste()
            notifyOfUpdate = true
          }
        }

        // Careful! This will trigger a re-render of the entire component tree.
        // Make sure you're not triggering a render on keystrokes that are not meant
        // for the diagram (i.e., keystrokes in the text fields in the table below)
        if (notifyOfUpdate) {
          handleUpdateDiagram()
        }
      }

      window.document.addEventListener("keydown", onKeyDown)
      return () => {
        window.document.removeEventListener("keydown", onKeyDown)
      }
    }, [addScrewPile, copy, loading, mode, onAddPile, onRemovePiles, onUpdateDiagram, paste])

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "0px",
          height: "400px",
          background: "rgb(255,255,255)",
        }}
        id="checklist-diagram"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0.9375rem",
            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            boxSizing: "border-box",
            background: "rgb(255,255,255)",
          }}
          id="checklistSidebar"
        >
          <div css={classes.sidebarItemContainer}>
            <img
              alt={t("component.checklistDiagram.selectionTool")}
              css={classes.icon}
              onClick={() => setMode(Mode.select)}
              src={mode === Mode.select ? Mode.select.activeIcon : Mode.select.icon}
              title={t("component.checklistDiagram.selectionTool")}
            />
          </div>
          <div css={classes.sidebarItemContainer}>
            <img
              alt={t("component.checklistDiagram.rectangleTool")}
              css={classes.icon}
              onClick={() => setMode(Mode.rectangle)}
              src={mode === Mode.rectangle ? Mode.rectangle.activeIcon : Mode.rectangle.icon}
              title={t("component.checklistDiagram.rectangleTool")}
            />
          </div>
          <div css={classes.sidebarItemContainer}>
            <img
              alt={t("component.checklistDiagram.screwPileTool")}
              css={classes.icon}
              onClick={() => setMode(Mode.screwPile)}
              src={mode === Mode.screwPile ? Mode.screwPile.activeIcon : Mode.screwPile.icon}
              title={t("component.checklistDiagram.screwPileTool")}
            />
          </div>
          <div css={classes.sidebarItemContainer}>
            <img
              alt={t("component.checklistDiagram.circleTool")}
              css={classes.icon}
              onClick={() => setMode(Mode.circle)}
              src={mode === Mode.circle ? Mode.circle.activeIcon : Mode.circle.icon}
              title={t("component.checklistDiagram.circleTool")}
            />
          </div>
          <div css={classes.sidebarItemContainer}>
            <img
              alt={t("component.checklistDiagram.lineTool")}
              css={classes.icon}
              onClick={() => setMode(Mode.line)}
              src={mode === Mode.line ? Mode.line.activeIcon : Mode.line.icon}
              title={t("component.checklistDiagram.lineTool")}
            />
          </div>
          <div css={classes.sidebarItemContainer}>
            <img
              alt={t("component.checklistDiagram.textTool")}
              css={classes.icon}
              onClick={() => setMode(Mode.text)}
              src={mode === Mode.text ? Mode.text.activeIcon : Mode.text.icon}
              title={t("component.checklistDiagram.textTool")}
            />
          </div>
          <div css={classes.sidebarItemContainer} />
        </Box>
        <canvas css={classes.canvas} ref={canvasContainerElRef} />
      </Box>
    )
  }
)

const classes = {
  canvas: {
    width: "1100px",
  },
  sidebarItemContainer: {},
  icon: {
    width: "1.875rem",
    marginTop: "0.625rem",
    marginBottom: "0.625rem",
  },
} as const

ChecklistDiagramEditor.displayName = "ChecklistDiagramEditor"

export default ChecklistDiagramEditor
