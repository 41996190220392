import gql from "graphql-tag"

export const ALL_ITEMS = gql`
  query AllItems(
    $filter: String
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $includeArchived: Boolean!
  ) {
    allItems(
      input: {
        filter: $filter
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        last: $last
        after: $after
        before: $before
      }
      itemFilter: { includeArchived: $includeArchived }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          code
          region {
            id
            name
            code
            currencyCode
          }
          name
          description
          isArchived
          isIntendedForResale
          type
          stockType
          category
          msrp
          wholesalePrice
          leadTimeDays
          palletQty
          createdAt
          createdBy {
            id
            email
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            email
            firstName
            lastName
          }
        }
      }
    }
  }
`
