import React from "react"
import { NumericFormat } from "react-number-format"
import FielderTextField from "./FielderTextField"

interface Props {
  readonly onBlur: (event: { target: { name: string; value: string } }) => void
  readonly onChange: (event: { target: { name: string; value: string } }) => void
  readonly onFocus?: (event: any) => void
  readonly name: string
  readonly min?: number
  readonly max?: number
  readonly defaultValue?: number | string
  readonly value?: number | string
  readonly customInput?: React.ComponentType
}

function PositiveIntegerInput(props: Props) {
  const { name, defaultValue, onBlur, onChange, onFocus, min, max, customInput, value, ...other } =
    props
  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      autoComplete="off"
      customInput={customInput ?? FielderTextField}
      decimalScale={0}
      defaultValue={defaultValue}
      isAllowed={(values) => {
        const { floatValue } = values
        if ("undefined" === typeof floatValue) {
          return true
        }
        let allowed = true
        if ("undefined" !== typeof min) {
          allowed = floatValue >= min
        }
        if ("undefined" !== typeof max) {
          allowed = allowed && floatValue <= max
        }
        return allowed
      }}
      max={max}
      min={min}
      name={name}
      onBlur={onBlur}
      onFocus={(e) => {
        if (onFocus) {
          onFocus(e)
        } else {
          e.target.select()
        }
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      type="text"
      value={String(value)}
      valueIsNumericString
    />
  )
}

export default PositiveIntegerInput
