import React from "react"
import { Box } from "@mui/material"

export interface JobStatusColorOption {
  name: string
  dark: string
  medium: string
  light: string
}

const palette: JobStatusColorOption[] = [
  {
    name: "Slate",
    dark: "#1e293b",
    medium: "#64748b",
    light: "#f1f5f9",
  },
  {
    name: "Stone",
    dark: "#292524",
    medium: "#78716c",
    light: "#f5f5f4",
  },
  {
    name: "Red",
    dark: "#991b1b",
    medium: "#ef4444",
    light: "#fee2e2",
  },
  {
    name: "Rose",
    dark: "#9f1239",
    medium: "#f43f5e",
    light: "#ffe4e6",
  },
  {
    name: "Orange",
    dark: "#9a3412",
    medium: "#f97316",
    light: "#fed7aa",
  },
  {
    name: "Green",
    dark: "#166534",
    medium: "#22c55e",
    light: "#f0fdf4",
  },

  {
    name: "Blue",
    dark: "#1e40af",
    medium: "#3b82f6",
    light: "#eff6ff",
  },
  {
    name: "Sky",
    dark: "#075985",
    medium: "#0ea5e9",
    light: "#f0f9ff",
  },
  {
    name: "Teal",
    dark: "#115e59",
    medium: "#14b8a6",
    light: "#f0fdfa",
  },
  {
    name: "Cyan",
    dark: "#155e75",
    medium: "#06b6d4",
    light: "#ecfeff",
  },
  {
    name: "Purple",
    dark: "#6b21a8",
    medium: "#a855f7",
    light: "#faf5ff",
  },
  {
    name: "Fuscia",
    dark: "#86198f",
    medium: "#d946ef",
    light: "#fdf4ff",
  },
  {
    name: "Yellow",
    dark: "#a16207",
    medium: "#facc15",
    light: "#fef9c3",
  },
  {
    name: "Kumera",
    dark: "#684f2b",
    medium: "#b29e40",
    light: "#f9f9ed",
  },
  {
    name: "Green Smoke",
    dark: "#42482c",
    medium: "#869151",
    light: "#f6f7ee",
  },
]

interface Props {
  readonly onChange: (colorOption: JobStatusColorOption) => void
}

export function JobStatusColorPicker({ onChange }: Props): JSX.Element {
  return (
    <Box
      sx={{
        background: "#fff",
        border: "0 solid rgba(0,0,0,0.25)",
        boxShadow: "0 1px 4px rgba(0,0,0,0.25)",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "0px",
          height: "0px",
          borderStyle: "solid",
          borderWidth: "0 9px 10px 9px",
          borderColor: "transparent transparent rgba(0,0,0,.1) transparent",
          position: "absolute",
        }}
      />
      <Box
        sx={{
          width: "0px",
          height: "0px",
          borderStyle: "solid",
          borderWidth: "0 9px 10px 9px",
          borderColor: "transparent transparent #fff transparent",
          position: "absolute",
        }}
      />
      <Box
        sx={{
          padding: "15px 9px 9px 15px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "6px",
          maxWidth: "228px",
        }}
      >
        {palette.map((c) => {
          return (
            <Box
              key={c.name}
              onClick={() => {
                onChange(c)
              }}
              sx={{
                width: "30px",
                height: "30px",
                float: "left",
                borderRadius: "4px",
                margin: "0 6px 6px 0",
                background: c.medium,
                "&:hover": {
                  cursor: "pointer",
                },
                "&:focus": {
                  boxShadow: `0 0 4px ${c.dark}`,
                },
              }}
            />
          )
        })}
      </Box>
    </Box>
  )
}
