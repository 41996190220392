import gql from "graphql-tag"
import FullWorkOrderTemplate from "./fragments/workOrderTemplate"

export const CREATE_WORK_ORDER_TEMPLATE = gql`
  mutation CreateWorkOrderTemplate(
    $name: String!
    $description: String
    $notes: String
    $lineItems: [WorkOrderTemplateLineItemInput!]
  ) {
    createWorkOrderTemplate(
      input: { name: $name, description: $description, notes: $notes, lineItems: $lineItems }
    ) {
      workOrderTemplate {
        ...FullWorkOrderTemplate
      }
    }
  }
  ${FullWorkOrderTemplate}
`

