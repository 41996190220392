import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"

export const SEARCH = gql`
  query Search($keyword: String!, $types: [SearchRecordType], $maxResults: Int) {
    search(searchRequest: { keyword: $keyword, types: $types, maxResults: $maxResults }) {
      keyword
      records {
        type
        id
        record {
          ... on Organization {
            id
            tradeName
            address {
              ...FullAddress
            }
          }
          ... on Job {
            id
            number
            description
            customer {
              id
              name
            }
            workflowStep {
              id
              jobStatus {
                id
                name
                lightColor
                mediumColor
                darkColor
              }
            }
            jobAddress: address {
              ...FullAddress
            }
          }
          ... on Customer {
            id
            name
            type
            email
            address {
              ...FullAddress
            }
          }
          ... on Estimate {
            id
            number
            description
            total
            job {
              id
              number
              address {
                ...FullAddress
              }
            }
          }
          ... on Invoice {
            id
            number
            description
            total
            job {
              id
              number
              address {
                ...FullAddress
              }
            }
          }
          ... on Task {
            id
            dueDate
            taskDescription: description
            status
            assignee {
              id
              firstName
              lastName
            }
          }
          ... on OrganizationItem {
            id
            code
            name
          }
        }
      }
    }
  }
  ${FullAddress}
`
