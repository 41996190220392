import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import ImageIcon from "@mui/icons-material/Image"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import { NOT_SPECIFIED, formatMoney, isNumeric } from "../../../util"
import PositiveIntegerInput from "../../../components/PositiveIntegerInput"
import { TransactionLineItemFormInput } from "../../../types"
import Collapse from "@mui/material/Collapse"
import ProductOrderItemNoRefundWarning from "../../../components/ProductOrderItemNoRefundWarning"

function ItemStatistic({ label, value }: { readonly label: string; readonly value: string }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "0.25rem",
        fontSize: "0.75rem",
        color: (theme) => theme.fielderColors.mutedText,
      }}
    >
      <Box sx={{ fontWeight: "600" }}>{label}:</Box>
      <Box>{value}</Box>
    </Box>
  )
}
interface Props {
  readonly currencyCode: string
  readonly isEditable?: boolean
  readonly lineItem: TransactionLineItemFormInput
  readonly onChange: (lineItem: TransactionLineItemFormInput) => void
  readonly onDelete: () => void
}

function CartLineItem({ currencyCode, isEditable, lineItem, onChange, onDelete }: Props) {
  const { t } = useTranslation()
  const [moreInfoOpen, setMoreInfoOpen] = useState<boolean>(false)
  const [quantity, setQuantity] = useState<string>(lineItem.quantity ?? "")

  return (
    <Box
      key={lineItem.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        color: (theme) =>
          lineItem.isLoading ? theme.fielderColors.mutedText : theme.fielderColors.text,
      }}
    >
      <Box
        sx={(theme) => {
          return {
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: "0.5rem",
            [theme.breakpoints.up("md")]: {
              gap: "1rem",
            },
          }
        }}
      >
        <Box
          sx={(theme) => {
            return {
              width: "32px",
              height: "32px",
              objectFit: "cover",
              "& img": {
                width: "100%",
                height: "100%",
              },
              [theme.breakpoints.up("md")]: {
                width: "4rem",
                height: "4rem",
              },
            }
          }}
        >
          {lineItem.organizationItem?.attachments?.[0] ? (
            <img src={lineItem.organizationItem.attachments?.[0]?.signedUrl} />
          ) : (
            <ImageIcon
              sx={{
                fontSize: "4rem",
                width: "100%",
                maxWidth: "4rem",
                color: "#747474",
                padding: 0,
                margin: 0,
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flex: 1,
          }}
        >
          <Box
            sx={{
              fontWeight: "600",
              fontSize: "1rem",
            }}
          >
            {lineItem.organizationItem?.code}
          </Box>
          <Box>{lineItem.organizationItem?.name}</Box>
          <Box
            onClick={() => setMoreInfoOpen((prev) => !prev)}
            sx={{
              marginTop: "0.5rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                textDecoration: "underline",
              }}
            >
              {t("moreInfo")}
            </Box>
            <KeyboardArrowDownIcon
              sx={{
                transition: "transform 0.5s",
                transform: moreInfoOpen ? "rotateX(180deg)" : "rotateX(0deg)",
              }}
            />
          </Box>
          <Collapse in={moreInfoOpen} timeout="auto" unmountOnExit>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
                marginTop: "0.5rem",
              }}
            >
              <ItemStatistic
                label={t("stockType")}
                value={t(`stockTypeOptions.${lineItem.organizationItem?.stockType}`)}
              />
              {"undefined" !== typeof lineItem.organizationItem?.leadTimeDays &&
              lineItem.organizationItem.leadTimeDays > 0 ? (
                <ItemStatistic
                  label={t("leadTime")}
                  value={`${lineItem.organizationItem?.leadTimeDays} ${t(
                    "businessDays"
                  ).toLowerCase()}`}
                />
              ) : null}
              <ItemStatistic
                label={t("currentQtyOnHand")}
                value={(lineItem.organizationItem?.qtyOnHand ?? "") as string}
              />
              <ItemStatistic
                label={t("palletQty")}
                value={(lineItem.organizationItem?.palletQty ?? "") as string}
              />
              {Number(lineItem.organizationItem?.packSize) > 1 ? (
                <ItemStatistic
                  label={t("packSize")}
                  value={`${lineItem.organizationItem?.packSize} ${
                    !lineItem.organizationItem?.isPackBreakable ? `(${t("notBreakable")})` : ""
                  }`}
                />
              ) : null}
              <ItemStatistic
                label={t("reorderPoint")}
                value={(lineItem.organizationItem?.reorderPoint ?? "") as string}
              />
              <ItemStatistic
                label={t("reorderQty")}
                value={(lineItem.organizationItem?.reorderQty ?? "") as string}
              />
            </Box>
          </Collapse>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifySelf: "flex-end",
            textAlign: "right",
          }}
        >
          <Box sx={{ fontSize: "1rem", fontWeight: "600" }}>
            {formatMoney(currencyCode, lineItem.total ?? 0)}
          </Box>
          <Box sx={{ fontSize: "0.75rem" }}>
            {isNumeric(lineItem.unitPrice)
              ? formatMoney(currencyCode, lineItem.unitPrice ?? 0)
              : NOT_SPECIFIED}{" "}
            {t("each").toLowerCase()}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          flex: 1,
        }}
      >
        {lineItem.organizationItem?.isCancelable === false && <ProductOrderItemNoRefundWarning />}
        {isEditable ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "2rem",
              justifyContent: "flex-end",
              flex: 1,
            }}
          >
            <Box
              onClick={() => !lineItem.isLoading && onDelete()}
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {t("remove")}
            </Box>
            <PositiveIntegerInput
              aria-label={t("quantity") as string}
              customInput={TextField}
              disabled={Boolean(lineItem.isLoading)}
              error={!!lineItem.errors?.quantity}
              fullWidth={false}
              inputProps={{ style: { textAlign: "right", width: "4rem" }, min: 1 }}
              margin="dense"
              name="quantity"
              onBlur={() => {
                let qty = Number(quantity)
                const currentQty = Number(lineItem.quantity)
                if (qty !== currentQty) {
                  const packSize = Number(lineItem.organizationItem?.packSize ?? 1)
                  if (
                    packSize > 1 &&
                    !lineItem.organizationItem?.isPackBreakable &&
                    qty % packSize !== 0
                  ) {
                    // round up qty to the nearest multiple of packSize
                    qty = Math.ceil(qty / packSize) * packSize
                  }
                  setQuantity(String(qty))
                  onChange({
                    ...lineItem,
                    quantity: String(qty),
                  })
                }
              }}
              onChange={(e) => {
                setQuantity(e.target.value)
              }}
              required
              size="small"
              value={quantity}
              variant="outlined"
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default CartLineItem
