import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import CircularProgress from "@mui/material/CircularProgress"

function CloneJobDialog() {
  const { t } = useTranslation()

  return (
    <Dialog aria-labelledby="clone-job-dialog" data-testid="CloneJobDialog" maxWidth="md" open>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "6.25rem",
        }}
      >
        <Box
          sx={{
            fontSize: "1.25rem",
            fontWeight: "bold",
          }}
        >
          {t("cloningJobInProgress")}...
        </Box>
        <Box
          sx={{
            marginTop: "3.75rem",
            marginBottom: "2.5rem",
          }}
        >
          <CircularProgress color="secondary" size={60} thickness={6.0} />
        </Box>
      </Box>
    </Dialog>
  )
}

export default CloneJobDialog
