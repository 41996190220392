import gql from "graphql-tag"

export const GET_LEVEL2_ORGANIZATION_DASHBOARD_METRICS = gql`
  query GetLevel2OrganizationDashboardMetrics($id: ID!, $start: LocalDateTime, $end: LocalDateTime) {
    getOrganizationById(id: $id) {
      id
      jobCounts(groupBy: DATE, breakdown: MONTH, start: $start, end: $end) {
        label
        data {
          label
          value
        }
      }
      sales(groupBy: DATE, breakdown: MONTH, start: $start, end: $end) {
        label
        data {
          label
          value
        }
      }
    }
  }
`
