import gql from "graphql-tag"
import FullNotification from "./fragments/FullNotification"

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkNotificationsAsRead($ids: [ID!]!) {
    markNotificationsAsRead(ids: $ids) {
      notifications {
        ...FullNotification
      }
    }
  }
  ${FullNotification}
`
