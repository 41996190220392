import gql from "graphql-tag"
import FullProductOrder from "./fragments/FullProductOrder"

export const UNARCHIVE_PRODUCT_ORDER = gql`
  mutation UnarchiveProductOrder($id: ID!) {
    unarchiveProductOrder(id: $id) {
      productOrder {
        ...FullProductOrder
      }
    }
  }
  ${FullProductOrder}
`
