import React from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { ALL_JOB_STATUSES } from "../queries/allJobStatuses"
import { capitalize } from "../util"
import FieldSpinner from "./FieldSpinner"
import SelectorField from "./SelectorField"
import MenuItem from "@mui/material/MenuItem"
import { JobStatus } from "../types"

interface JobStatusSelectProps {
  readonly error?: boolean
  readonly extraOptions?: Array<{ id: string; name: string }>
  readonly label?: string
  readonly name: string
  readonly onBlur?: () => void
  readonly onChange: (value: { id: string; name: string }) => void
  readonly onlyParents?: boolean
  readonly required?: boolean
  readonly selectedOption?: JobStatus
  readonly size?: "small" | "medium"
  readonly testID?: string
  readonly variant?: "outlined" | "filled"
}

function JobStatusSelect({
  error,
  extraOptions = [],
  label,
  name,
  onBlur,
  onChange,
  onlyParents,
  required,
  selectedOption,
  size = "small",
  testID,
  variant = "filled",
}: JobStatusSelectProps) {
  const { t } = useTranslation()
  const {
    loading,
    error: queryError,
    data,
  } = useQuery(ALL_JOB_STATUSES, {
    fetchPolicy: "cache-and-network",
    variables: {
      onlyParentStatuses: onlyParents,
    },
  })

  if (loading) {
    return <FieldSpinner message={t("loadingStatusOptions")} />
  }

  if (queryError) return <div>Error! {queryError.message}</div>

  let options = data.allJobStatuses.edges.map((v: any) => {
    return {
      id: v.node.id,
      name: capitalize(v.node.name),
      colorCode: v.node.mediumColor,
    }
  })

  if (extraOptions) {
    options = extraOptions.concat(options)
  }

  return (
    <SelectorField
      error={error}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      renderOption={(o) => (
        <MenuItem
          key={o.id}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          value={o.id}
        >
          <span>{o.name}</span>
          <div
            style={{
              width: "1.875rem",
              height: "1.875rem",
              borderRadius: "6px",
              backgroundColor: o.colorCode,
            }}
          />
        </MenuItem>
      )}
      renderValue={(value) => {
        const selected = options.find((o) => o.id === value) ?? options[0]
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>{selected.name}</div>
            {selected.colorCode ? (
              <div
                style={{
                  width: "1.875rem",
                  height: "1.875rem",
                  borderRadius: "6px",
                  backgroundColor: selected.colorCode,
                }}
              />
            ) : null}
          </div>
        )
      }}
      required={required}
      size={size}
      testID={testID}
      value={selectedOption?.id ?? ""}
      variant={variant}
    />
  )
}

export default React.memo(JobStatusSelect)
