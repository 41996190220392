import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Divider from "@mui/material/Divider"

import CustomerViewSettingStatic from "./CustomerViewSettingStatic"
import ExpandMore from "~/components/ExpandMore"
import { Organization } from "~/types"
import useStore from "~/store"

interface Props {
  readonly organization: Organization
  readonly showBottomDivider?: boolean
}

export default function DefaultInvoiceSettingsStatic({ organization, showBottomDivider }: Props) {
  const { t } = useTranslation()
  const organizationDetailScreenSettings = useStore(
    (state) => state.organizationDetailScreenSettings
  )
  const setOrganizationDefaultSettings = useStore(
    (state) => state.setOrganizationDetailScreenSettings
  )

  return (
    <Box>
      <Box sx={{ paddingBottom: "0.5rem" }} />
      <Box
        onClick={() => {
          setOrganizationDefaultSettings({
            ...organizationDetailScreenSettings,
            isDefaultInvoiceSettingsExpanded:
              !organizationDetailScreenSettings.isDefaultInvoiceSettingsExpanded,
          })
        }}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Box
          component="label"
          sx={{
            cursor: "pointer",
            fontWeight: 700,
            marginBottom: "0.25rem",
            fontSize: "0.875rem",
          }}
        >
          {t("page.organizationDetails.defaultInvoiceSettings.label")}
        </Box>
        <ExpandMore
          aria-expanded={organizationDetailScreenSettings.isDefaultInvoiceSettingsExpanded}
          aria-label={t("page.organizationDetails.defaultInvoiceSettings.label") as string}
          expand={organizationDetailScreenSettings.isDefaultInvoiceSettingsExpanded}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>
      <Collapse in={organizationDetailScreenSettings.isDefaultInvoiceSettingsExpanded}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "0.5rem",
            paddingRight: "1rem",
            backgroundColor: "#f5f5f5",
            borderRadius: "4px",
            margin: "0.25rem 0rem",
          }}
        >
          <Box sx={{ fontSize: "0.875rem", fontWeight: "600" }}>
            {t("page.organizationDetails.defaultInvoiceSettings.netTerms")}
          </Box>
          <Box>{t(`netTermsOptions.${organization.defaultInvoiceSettings?.netTerms}`)}</Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            padding: "0.5rem",
            paddingRight: "1rem",
          }}
        >
          <Box sx={{ fontSize: "0.875rem", fontWeight: "600" }}>
            {t("page.organizationDetails.customerViewSettings.label")}
          </Box>
          <Box sx={{ fontSize: "0.875rem", color: (theme) => theme.fielderColors.mutedText }}>
            {t("page.organizationDetails.defaultInvoiceSettings.customerViewSettingsDescription")}
          </Box>
        </Box>
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showDueDate")}
          value={Boolean(organization.defaultInvoiceSettings?.showDueDate)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showLineItems")}
          value={Boolean(organization.defaultInvoiceSettings?.showLineItems)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showLineItemCode")}
          value={Boolean(organization.defaultInvoiceSettings?.showLineItemCode)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showLineItemName")}
          value={Boolean(organization.defaultInvoiceSettings?.showLineItemName)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showLineItemDescription")}
          value={Boolean(organization.defaultInvoiceSettings?.showLineItemDescription)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showLineItemQuantity")}
          value={Boolean(organization.defaultInvoiceSettings?.showLineItemQuantity)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showLineItemUnitPrice")}
          value={Boolean(organization.defaultInvoiceSettings?.showLineItemUnitPrice)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showLineItemSubtotal")}
          value={Boolean(organization.defaultInvoiceSettings?.showLineItemSubtotal)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showLineItemBundleComponents")}
          value={Boolean(organization.defaultInvoiceSettings?.showLineItemBundleComponents)}
        />
        <CustomerViewSettingStatic
          label={t(
            "page.organizationDetails.customerViewSettings.showLineItemBundleComponentQuantity"
          )}
          value={Boolean(organization.defaultInvoiceSettings?.showLineItemBundleComponentQuantity)}
        />
        <CustomerViewSettingStatic
          label={t(
            "page.organizationDetails.customerViewSettings.showLineItemBundleComponentUnitPrice"
          )}
          value={Boolean(organization.defaultInvoiceSettings?.showLineItemBundleComponentUnitPrice)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showFooter")}
          value={Boolean(organization.defaultInvoiceSettings?.showFooter)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showFooterSubtotal")}
          value={Boolean(organization.defaultInvoiceSettings?.showFooterSubtotal)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showFooterDiscount")}
          value={Boolean(organization.defaultInvoiceSettings?.showFooterDiscount)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showFooterTaxes")}
          value={Boolean(organization.defaultInvoiceSettings?.showFooterTaxes)}
        />
        <CustomerViewSettingStatic
          label={t("page.organizationDetails.customerViewSettings.showFooterTotal")}
          value={Boolean(organization.defaultInvoiceSettings?.showFooterTotal)}
        />
        <Box sx={{ paddingBottom: "0.5rem" }} />
      </Collapse>
      <Box sx={{ paddingBottom: "0.5rem" }} />
      {showBottomDivider ? <Divider /> : null}
    </Box>
  )
}
