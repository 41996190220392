import gql from "graphql-tag"
import { useQuery } from "@apollo/client"

import { Organization } from "~/types"

export const GET_ORGANIZATION_PREFERENCES = gql`
  query GetOrganizationPreferences($id: ID!) {
    getOrganizationById(id: $id) {
      id
      name
      allowBundleModsOnTxns
      allowJobAssignmentOverlap
      bccJobEmailToOrganizationEmail
    }
  }
`

export default function useGetUserOrganizationPreferences(organizationId?: string): Organization {
  const { data } = useQuery(GET_ORGANIZATION_PREFERENCES, {
    variables: {
      id: organizationId,
    },
    skip: !organizationId,
  })
  const organization = data?.getOrganizationById

  return organization
}
