import gql from "graphql-tag"

export const CREATE_JOB_NOTE = gql`
  mutation CreateJobNote($jobId: ID!, $text: String!) {
    createJobNote(input: { jobId: $jobId, text: $text }) {
      jobNote {
        id
        text
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`
